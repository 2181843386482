import ROUTES from 'routes';
import Loader from 'components/layout/Loader';
import DataTable from 'components/shared/DataTable';
import useFeedback from 'hooks/useFeedback';
import {
  approveSalesOrder,
  fetchStatus,
  requestSalesOrderApproval,
  requestSalesOrderChanges,
} from 'services/api/sales-order';
import { NavLink, useParams } from 'react-router-dom';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { fetchCommssionSalesOrdersByPagination } from 'services/api/sales-order';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { SALES_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import useParseCustomResponse from './hooks/useParseCustomResponse';
import { formatISODate } from 'helpers/formatters';
import { format } from 'helpers/amount';
import useCommission from 'hooks/useCommission';
import SalesOrderIndexHeader from './components/SalesOrderIndexHeader';
import { Badge, Button } from 'reactstrap';
import ErrorMessage from 'components/shared/feedbacks/ErrorMessage';
import useAuth from 'hooks/useAuth';
import { Dialog } from 'primereact/dialog';
import RejectionNote from './components/RejectionNote';

const fetchInitialData = async (commissionId, request) => {
  const [salesOrdersResponse, statusResponse] = await Promise.all([
    fetchCommssionSalesOrdersByPagination(commissionId, request),
    fetchStatus(),
  ]);

  if (salesOrdersResponse.ok && statusResponse.ok) {
    return {
      salesOrdersData: mapToIndexData(salesOrdersResponse.data.orders),
      statusResponse: createStatusOptions(statusResponse.data.status),
      totRecords: salesOrdersResponse.data.totRecords,
    };
  } else {
    return {
      message:
        salesOrdersResponse.message ||
        statusResponse.message ||
        'Si è verificato un errore durante il recupero dei dati',
    };
  }
};

const mapToIndexData = (orders) => {
  return orders.map((order) => ({
    salesOrderId: order.salesOrderId,
    customerId: order.customerId,
    createdBy: order.createdBy,
    date: order.date,
    customerName: order.customerName,
    customerOrderNoReference: order.customerOrderNoReference,
    invoicedAmount: order.invoicedAmount,
    orderAmount: order.orderAmount,
    percentage: order.percentage,
    status: { id: order.statusId, description: order.status },
    totOrderLines: order.totOrderLines,
    commissionStatus: order.commissionStatus,
    approvable: order.approvable,
    approvalRequestable: order.approvalRequestable,
    settleable: order.settleable,
    editable: order.editable,
  }));
};

const retrieveData = async (commissionId, request) => {
  const [salesOrdersResponse] = await Promise.all([fetchCommssionSalesOrdersByPagination(commissionId, request)]);

  if (salesOrdersResponse.ok) {
    return {
      salesOrdersData: mapToIndexData(salesOrdersResponse.data?.orders),
      totRecords: salesOrdersResponse.data?.totRecords,
    };
  } else {
    return { message: salesOrdersResponse.message || 'Si è verificato un errore durante il recupero dei dati' };
  }
};

const STATUS_COLORS = {
  0: 'dark',
  1: 'warning',
  2: 'success',
  3: 'danger',
  4: 'primary',
  5: 'danger',
};

const STATUS_ICONS = {
  0: 'fa fa-pen-alt',
  1: 'fa fa-user-clock',
  2: 'fa fa-check',
  3: 'fa fa-pen-alt',
  4: 'fa fa-university',
  5: 'far fa-stop-circle',
};

// Però è più bello con questo stile: p-2 text-uppercase
const SalesOrderStatusBadge = ({ statusId, statusName }) => (
  <Badge color={STATUS_COLORS[statusId]}>
    <i className={STATUS_ICONS[statusId]} /> {statusName}
  </Badge>
);

// crea le options per input type select status
const createStatusOptions = (statuses) => {
  return statuses.map((data) => ({
    id: data.statusId,
    description: data.description,
  }));
};

const styles = {
  truncateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

//settings degli header dei campi
//le ultime due proprietà servono per specificare
//se un singolo campo può essere ordinabile o filtrabile
const DATA_TABLE_COLUMN = [
  {
    heading: 'Ordine',
    value: 'salesOrderId',
    type: 'text',
    filterable: true,
    sortable: true,
    cellTemplate: (val, _, context) => {
      return (
        <NavLink
          to={ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.HEADER.replace(':commissionId', context.commissionId).replace(
            ':salesOrderId',
            val
          )}
        >
          {val}
        </NavLink>
      );
    },
    style: { ...styles.truncateText, maxWidth: '200px' },
  },
  {
    heading: 'Rif nr ordine cliente',
    value: 'customerOrderNoReference',
    type: 'text',
    filterable: true,
    sortable: true,
    style: { ...styles.truncateText, maxWidth: '100px' },
    cellTemplate: (customerOrderReference) => <span title={customerOrderReference}>{customerOrderReference}</span>,
  },
  {
    heading: 'Cliente',
    value: 'customerName',
    type: 'text',
    filterable: true,
    sortable: true,
    style: { ...styles.truncateText, maxWidth: '200px' },
    cellTemplate: (customerName) => <span title={customerName}>{customerName}</span>,
  },
  {
    heading: 'Data',
    value: 'date',
    type: 'date',
    filterable: true,
    sortable: true,
    cellTemplate: (value) => formatISODate(value),
  },
  {
    heading: 'Importo (€)',
    value: 'orderAmount',
    type: 'number',
    filterable: false,
    sortable: false,
    style: { textAlign: 'right' },
    cellTemplate: (value) => format(value),
  },
  {
    heading: 'Fatturato (€)',
    value: 'invoicedAmount',
    type: 'number',
    filterable: false,
    sortable: false,
    style: { textAlign: 'right' },
    cellTemplate: (value) => format(value),
  },
  {
    heading: 'Fatturato (%)',
    value: 'percentage',
    type: 'number',
    filterable: false,
    sortable: false,
    style: { textAlign: 'right' },
    cellTemplate: (invoicedAmountPercentage) => format(invoicedAmountPercentage),
  },
  {
    heading: 'Stato',
    value: 'status',
    type: 'select',
    filterable: true,
    sortable: true,
    style: { width: '150px' },
    cellTemplate: (statusName, order) => <SalesOrderStatusBadge statusId={order.status.id} statusName={statusName} />,
  },
  {
    heading: '',
    value: 'buttons',
    type: 'text',
    filterable: false,
    sortable: false,
    cellTemplate: (_, item, context) => {
      return (
        <>
          {item.approvable && context.canApprovalReject && (
            <Button color="danger" className="ml-1 btn-sm mb-1" value={item.salesOrderId} onClick={context.onShowModal}>
              RICHIEDI MODIFICHE
            </Button>
          )}
          {((item.approvable && context.canApprovalReject) ||
            (item.approvalRequestable && context.canSelfApprove && context.userId === item.createdBy)) && (
            <Button color="success" className="ml-1 btn-sm" value={item.salesOrderId} onClick={context.handleOnApprove}>
              APPROVA
            </Button>
          )}
          {item.settleable && context.canSettleOrder && (
            <Button color="primary" className="ml-1 btn-sm" disabled>
              SALDA
            </Button>
          )}
          {item.approvalRequestable && context.canRequestApproval && (
            <Button
              color="warning"
              className="ml-1 btn-sm"
              value={item.salesOrderId}
              onClick={context.OnRequestApproval}
            >
              RICHIEDI APPROVAZIONE
            </Button>
          )}
        </>
      );
    },
  },
];

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];

const SalesOrderListPage = () => {
  const { error, success } = useFeedback();
  const { commissionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState({ column: '', direction: 'ASC' });
  const [sortedData, setSortedData] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [numRecords, setNumRecords] = useState(INITIAL_PAGE_SIZE);
  const [totRecords, setTotRecords] = useState(0);
  const [statusOrders, setStatusOrders] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [request, setRequest] = useState({});
  const [tableFilters, setTableFilters] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [requestChangesOrderId, setRequestChangesOrderId] = useState();

  const { userId } = useAuth();
  const { hasPermission } = useCommissionPermissions();
  const parseCustomResponse = useParseCustomResponse();
  const { isCommissionClosed } = useCommission();

  const canCreateOrder = hasPermission(SALES_ORDER_PERMISSIONS.CREATE_EDIT);
  const canSettleOrder = hasPermission(SALES_ORDER_PERMISSIONS.SETTLE);
  const canSelfApprove = hasPermission(SALES_ORDER_PERMISSIONS.SELF_APPROVE);
  const canRequestApproval = hasPermission(SALES_ORDER_PERMISSIONS.REQUEST_APPROVAL);
  const canApprovalReject = hasPermission(SALES_ORDER_PERMISSIONS.APPROVE_REJECT);

  const onShowModal = (event) => {
    console.log(event.target.value);
    setRequestChangesOrderId(event.target.value);
    setIsModalVisible(true);
  };

  const column = useMemo(() => {
    return DATA_TABLE_COLUMN.map((col) => (col.value !== 'status' ? col : { ...col, options: statusOrders }));
  }, [statusOrders]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    fetchInitialData(commissionId, { pageSize: numRecords })
      .then((response) => {
        if (response.message) {
          setErrorMessage(response.message);
        } else {
          setSortedData(response.salesOrdersData);
          setTotRecords(response.totRecords);
          setStatusOrders(response.statusResponse);
        }
      })
      .catch(() => {
        error('si è verificato un errore durante il caricamento iniziale dei dati', 'Errore');
      })
      .finally(() => setLoading(false));
  }, [commissionId, error, numRecords]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateOrder = (salesOrderId, updatedOrder) => {
    setSortedData((orders) =>
      orders.map((order) => (order.salesOrderId !== salesOrderId ? order : { ...order, ...updatedOrder }))
    );
  };

  const OnRequestApproval = async (event) => {
    setLoading(true);
    requestSalesOrderApproval(event.target.value)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          const resp = {
            ...parsedResponse.data,
            status: {
              id: parsedResponse.data.status.id,
              description: parsedResponse.data.status.name,
            },
          };
          updateOrder(event.target.value, resp);
          success('Abbiamo preso in carico la tua richiesta di approvazione.', 'Successo');
        } else {
          error(parsedResponse.message);
        }
      })
      .catch(() => error('Errore durante la richiesta di approvazione.', 'Errore'))
      .finally(() => setLoading(false));
  };

  const handleOnApprove = async (event) => {
    setLoading(true);
    approveSalesOrder(event.target.value)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          const resp = {
            ...parsedResponse.data,
            status: {
              id: parsedResponse.data.status.id,
              description: parsedResponse.data.status.name,
            },
          };
          updateOrder(event.target.value, resp);
          success("L'ordine è stato approvato con successo.", 'Successo');
        } else {
          error(parsedResponse.message);
        }
      })
      .catch(() => error("errore durante l'approvazione dell'ordine", 'Errore'))
      .finally(() => setLoading(false));
  };

  const handleOnRequestChanges = async (salesOrderId, request) => {
    setIsModalVisible(false);
    setLoading(true);
    requestSalesOrderChanges(salesOrderId, request)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          const resp = {
            ...parsedResponse.data,
            status: {
              id: parsedResponse.data.status.id,
              description: parsedResponse.data.status.name,
            },
          };
          updateOrder(salesOrderId, resp);
          success("Le modifiche sono state richieste per l'ordine selezionato.");
        } else {
          error(parsedResponse.message);
        }
      })
      .catch(() => error("errore durante l'approvazione dell'ordine", 'Errore'))
      .finally(() => setLoading(false));
  };

  const onHandlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setLoading(true);
    const updatedFilers = { ...request, currentPage: newPage, pageSize: numRecords };
    setRequest(updatedFilers);
    retrieveData(commissionId, updatedFilers)
      .then((response) => {
        if (response.message) {
          setErrorMessage(response.message);
        } else {
          setSortedData(response.salesOrdersData);
          setTotRecords(response.totRecords);
        }
      })
      .catch(() => error('Errore durante il cambio pagina', 'Errore'))
      .finally(() => setLoading(false));
  };

  const onHandleRecordsChange = (num, page) => {
    setLoading(true);
    setNumRecords(num);
    setCurrentPage(page);
    const updatedFilers = { ...request, currentPage: page, pageSize: num };
    setRequest(updatedFilers);
    retrieveData(commissionId, updatedFilers)
      .then((response) => {
        if (response.message) {
          setErrorMessage(response.message);
        } else {
          setSortedData(response.salesOrdersData);
          setTotRecords(response.totRecords);
        }
      })
      .catch(() => error('Errore durante il cambio del numero di elementi da visualizzare', 'Errore'))
      .finally(() => setLoading(false));
  };

  const onHandleSort = (columnName) => {
    let sortDirection = 'ASC';
    let sortColumn = undefined;

    if (columnName !== sort.column) {
      sortDirection = 'ASC';
      sortColumn = columnName;
    } else {
      sortDirection = sort.direction === undefined ? 'ASC' : sort.direction === 'ASC' ? 'DESC' : undefined;
      sortDirection === undefined ? (sortColumn = undefined) : (sortColumn = columnName);
    }
    setSort({ column: sortColumn, direction: sortDirection });

    const requestToSend = {
      ...request,
      sort: sortDirection,
      sortColumn: sortColumn,
    };

    setRequest(requestToSend);

    setLoading(true);
    retrieveData(commissionId, requestToSend)
      .then((response) => {
        if (response.message) {
          setErrorMessage(response.message);
        } else {
          setSortedData(response.salesOrdersData);
          setTotRecords(response.totRecords);
        }
      })
      .catch(() => error('Errore durante il sorting dei dati', 'Errore'))
      .finally(() => setLoading(false));
  };

  const onHandleSearch = (value, field, type, newPage) => {
    setLoading(true);
    const filters = { ...tableFilters };

    if (value === '') {
      delete filters[field];
    } else {
      filters[field] = type === 'select' ? { id: value } : value;
    }

    const requestToSend = { ...request, currentPage: newPage, filters };
    setTableFilters(filters);
    setRequest(requestToSend);
    setCurrentPage(newPage);
    retrieveData(commissionId, requestToSend)
      .then((response) => {
        if (response.message) {
          setErrorMessage(response.message);
        } else {
          setSortedData(response.salesOrdersData);
          setTotRecords(response.totRecords);
        }
      })
      .catch(() => error('Errore durante il sorting dei dati', 'Errore'))
      .finally(() => setLoading(false));
  };

  return (
    <section>
      {sortedData && totRecords === 0 && currentPage === 0 && !tableFilters && (
        <div className="mb-2">Non ci sono ordini da visualizzare per questa commessa.</div>
      )}
      <SalesOrderIndexHeader commissionId={commissionId} canCreateOrder={canCreateOrder && !isCommissionClosed} />
      {loading && <Loader />}
      <Dialog
        header="Richiesta Modifiche"
        visible={isModalVisible}
        style={{ width: '60vw' }}
        onHide={() => setIsModalVisible(false)}
      >
        <RejectionNote salesOrderId={requestChangesOrderId} onSubmit={handleOnRequestChanges} />
      </Dialog>
      {errorMessage && <ErrorMessage text={errorMessage} />}
      {sortedData && (totRecords > 0 || currentPage > 0 || tableFilters) && (
        <DataTable
          responsive
          data={sortedData}
          column={column}
          onHandleSort={onHandleSort}
          onHandleSearch={onHandleSearch}
          onHandlePageChange={onHandlePageChange}
          onHandleRecordsChange={onHandleRecordsChange}
          totRecords={totRecords}
          currentPage={currentPage}
          numRecords={numRecords}
          sort={sort}
          paginationValues={PAGES_SIZE}
          context={{
            commissionId,
            userId,
            canApprovalReject,
            canSelfApprove,
            canSettleOrder,
            canRequestApproval,
            onShowModal,
            handleOnApprove,
            OnRequestApproval,
          }}
          size="sm"
        />
      )}
    </section>
  );
};

export default SalesOrderListPage;
