const CustomDialog = (props) => {
    return (
      <div style={STYLES.tendina}>
        <form onSubmit={(e) => {
                  e.preventDefault();
                  props.OnClose({ success: true, formData: new FormData(e.currentTarget) }) } }>
            <div style={STYLES.dialogBody}>
              <header style={STYLES.title}>
                <h2 style={STYLES.titleText}>{props.Title}</h2>
                <button className="btn btn-danger mb-1" onClick={() => props.OnClose({ success: false })}><i className="fa fa-times"></i></button>
              </header>
              <div>
                <div style={STYLES.body}>
                  {props.children}
                </div>
                <footer style={STYLES.footer}>
                  { props.Ok && <button className="btn btn-primary ml-1 mt-1" style={{whiteSpace: 'nowrap'}} type="submit">{props.TextOk}</button> }
                  { !props.Ok && <span></span> }
                  { props.Cancel && <button type="button" className="btn btn-danger ml-1 mt-1" onClick={() => props.OnClose({ success: false })}>{props.TextCancel != null ? props.TextCancel : 'Annulla'}</button> }
                  { !props.Cancel && <span></span> }
                </footer>
              </div>
            </div>
          </form>
        </div>
    );
};

const STYLES = {
  tendina: {
    background: '#00000069',
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 99
  },
  dialogBody: {
    boxShadow: '5px 10px 8px #0000008c',
    minWidth: '230px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#f8f9fc',
    borderRadius: '2.5px',
    padding: '5px',
    border: '2px solid #e3e6f0',
    maxWidth: '90vw',
    maxHeight: '90vw'
  },
  title: {
    borderBottom: '2px solid #e3e6f0',
    display: 'grid',
    gridTemplateColumns: '1fr 48px'
  },
  footer: {
    borderTop: '2px solid #e3e6f0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  titleText: {
    lineHeight: '39px',
    marginRight: '5px'
  },
  body: {
    minHeight: '50px'
  }
};
export default CustomDialog;