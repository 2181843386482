const formatNumber = (n, nanFormat = '') =>
  !isNaN(n) ? n.toLocaleString('it-IT', { minimumFractionDigits: 2 }) : nanFormat;

// const formatStringInFloat = (n) => {
//   if (n == null || n == "") return NaN
//   if(n.toString().indexOf(',') > -1) return parseFloat(n.toString().substring(0, n.toString().indexOf(',')).replace(/\./g, ''));
//   return parseFloat(n.toString().replace(/\./g, ''))
//   // n != null && n != "" ? parseFloat(n.toString().substring(0, n.toString().indexOf(',')).replace(/\./g, '')) : NaN;
// }
// const formatStringInFloat = (n) => !isNaN(n) ? (parseFloat(n.toString()) * 100) / 100 : NaN;
// const formatStringInFloat = (n) => (n == null || n === "") ? 0 : (parseFloat(n.toString().indexOf(".") > -1 && n.toString().indexOf(",") > -1 ? n.toString().replace(/\./g, '').replace(/,/g, '.') : n.toString()) * 100) / 100
const formatStringInFloat = (n) => {
  if (n == null || n === "")
    return 0
  if(n.toString().indexOf(",") > -1)
    return parseFloat(n.toString().replace(/,/g, '.'))
  return parseFloat(n.toString())
}

const formatISODate = (isoDate) => {
  if(!isNaN(Date.parse(isoDate))) {
    return new Date(Date.parse(isoDate)).toLocaleDateString("it-IT", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
  }
  return null
};

// {
//   if((n == null || n == "")) {
//     return NaN;
//   }
//   return (parseFloat(n.toString()) * 100) / 100;
// }
  // if((n == null || n == "")) {
  //   return NaN;
  // }
  // if(n.toString().indexOf(',') > -1) {
  //   console.log(n);
  //   return parseFloat(n.toString().replace(/\./g, '').replace(/,/g, '.'));
  // }
//   return (parseFloat(n.toString()) * 100) / 100;
// } 
  // (n == null || n == "") ? NaN : (n.toString().indexOf(',') > -1) ? parseFloat(n.toString().replace(/\./g, '').replace(/,/g, '.')) : parseFloat(n.toString().replace(/\./g, ''))
  // return parseFloat(n.toString().replace(/\./g, ''))
  // n != null && n != "" ? parseFloat(n.toString().substring(0, n.toString().indexOf(',')).replace(/\./g, '')) : NaN;
// }

const formatDate = (date) => {
  if(!isNaN(Date.parse(date))) {
    let tempDate = new Date(date);
    return tempDate.getDate().toString().padStart(2, '0')
          + "/"
          + (tempDate.getMonth() + 1).toString().padStart(2, '0')
          + "/"
          + tempDate.getFullYear().toString()
          + " "
          + tempDate.getHours().toString().padStart(2, '0')
          + ":"
          + tempDate.getMinutes().toString().padStart(2, '0')
          + ":"
          + tempDate.getSeconds().toString().padStart(2, '0');
  }
  return null
}
// const formatDate = (date) => 
//   !isNaN(Date.parse(date)) ? ([ date.getDate().toString().padStart(2, '0'),
//       (date.getMonth() + 1).toString().padStart(2, '0'),
//       date.getFullYear().toString()].join("-")
//       + " "
//       [ date.getHours().toString().padStart(2, '0'),
//       date.getMinutes().toString().padStart(2, '0'),
//       date.getMinutes().getSeconds().padStart(2, '0') ].join(":"))
//    : null;

export { formatNumber, formatDate, formatStringInFloat, formatISODate };
