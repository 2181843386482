import Loader from "components/layout/Loader";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchSalesOrderWithId } from "services/api/sales-order";

import { FormText } from "reactstrap";
import SalesOrderManagementContext from "../../context/SalesOrderManagementContext";

const SalesOrderManagementProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [salesOrderData, setSalesOrderData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const { commissionId, salesOrderId } = useParams();

    const doFetchSalesOrder = useCallback(async () => {
        setLoading(true);

        const response = await fetchSalesOrderWithId(salesOrderId);

        if (response.success) {
            setSalesOrderData({ ...response.data, commissionId });
            setErrorMessage(false);
        } else {
            setErrorMessage(response.message);
            setSalesOrderData(false);
        }

        setLoading(false);
    }, [commissionId, salesOrderId]);


    useEffect(() => {
        doFetchSalesOrder();
      }, [doFetchSalesOrder]);

      
  return (
    <section>
      {loading && <Loader />}
      {salesOrderData && (
        <SalesOrderManagementContext.Provider value={{ data: salesOrderData, invalidate: doFetchSalesOrder }}>
          {children}
        </SalesOrderManagementContext.Provider>
      )}
      {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
    </section>
  );
};

export default SalesOrderManagementProvider;