import EditableBudgetTable from 'components/commission-management/budget/EditableBudgetTable';
import Loader from 'components/layout/Loader';
import { mapGetDataToFormData } from 'helpers/budget';
import useEnterEditMode from 'hooks/forms/useEnterEditMode';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ROUTES from 'routes';
import { fetchBudgetVersion, updateBudgetVersion } from 'services/api/budget';

const EditBudgetVersionPage = () => {
  const [budget, setBudget] = useState();
  const [loading, setLoading] = useState(true);

  const { commissionId, version } = useParams();
  const navigate = useNavigate();

  const { error } = useFeedback();

  useEnterEditMode();

  useEffect(() => {
    fetchBudgetVersion(commissionId, version, true).then((budget) => {
      setBudget(mapGetDataToFormData(budget));
      setLoading(false);
    });
  }, [commissionId, version]);

  const saveDraftClickHandler = (postData) => {
    updateBudgetVersion(commissionId, version, postData).then((response) => {
      if (response.ok) {
        const redirectUrl = ROUTES.COMMISSION_MANAGEMENT.VIEW_BUDGET.replace(':commissionId', commissionId).replace(
          ':version',
          version
        );
        navigate(redirectUrl);
      } else {
        error('Si è verificato un errore');
      }
    });
  };

  return loading ? <Loader /> : <EditableBudgetTable data={budget} onSaveDraft={saveDraftClickHandler} />;
};

export default EditBudgetVersionPage;
