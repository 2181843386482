import { Badge } from 'reactstrap';

const STATUS_COLORS = {
  0: 'dark',
  1: 'warning',
  2: 'success',
  3: 'danger',
};

const STATUS_ICONS = {
  0: 'fa fa-pen-alt',
  1: 'fa fa-user-clock',
  2: 'fa fa-check',
  3: 'fa fa-pen-alt',
};

const getStatusColor = (statusId) => STATUS_COLORS[statusId];
const getStatusIcon = (statusId) => STATUS_ICONS[statusId];

const PaymentCertificateStatusBadgeInner = ({ color, icon, statusName }) => (
  <Badge color={color}>
    <i className={icon} /> {statusName}
  </Badge>
);

const PaymentCertificateStatusBadge = ({ statusId, statusName }) => {
  const color = getStatusColor(statusId);
  const icon = getStatusIcon(statusId);
  return <PaymentCertificateStatusBadgeInner color={color} icon={icon} statusName={statusName} />;
};

export default PaymentCertificateStatusBadge;
