import Loader from 'components/layout/Loader';
import { useParams } from 'react-router';
import { useState } from 'react';
import { updateSalesOrder, voidSalesOrder } from 'services/api/sales-order';
import useFeedback from 'hooks/useFeedback';
import useFetchSalesOrderHeaderData from './hooks/useFetchSalesOrderHeaderData';
import { SALES_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import useInvalidateSalesOrder from './hooks/useInvalidateSalesOrder';
import useAuth from 'hooks/useAuth';
import SalesOrderForm from 'components/commission-management/sales-order/form';
import useUnsavedChanges from 'hooks/useUnsavedChanges';

const createPristineInvalidationKey = () => new Date().getTime();

const SalesOrderHeaderPage = () => {
  const [submitting, setSubmitting] = useState(false);
  const [pristineInvalidationKey, setPristineInvalidationKey] = useState(createPristineInvalidationKey());
  const { salesOrderId, commissionId } = useParams();
  const { loading, response, refreshSalesOrder } = useFetchSalesOrderHeaderData(commissionId, salesOrderId);
  const { error, success } = useFeedback();
  const { userId } = useAuth();
  const { hasPermission } = useCommissionPermissions();
  const invalidateSalesOrder = useInvalidateSalesOrder();
  const { unsavedChanges, notify: beginTrackUnsavedChanges, clear: endTrackUnsavedChanges } = useUnsavedChanges();

  // console.log(response?.salesOrderData)
  const canEdit =
    response?.salesOrderData.editable &&
    hasPermission(SALES_ORDER_PERMISSIONS.CREATE_EDIT) &&
    response?.salesOrderData.createdBy === userId;
  const canVoid = response?.salesOrderData.voidable && hasPermission(SALES_ORDER_PERMISSIONS.VOID);

  const handleOnFormSubmit = (form) => {
    setSubmitting(true);
    updateSalesOrder(salesOrderId, form)
      .then((response) => {
        if (response.success) {
          endTrackUnsavedChanges();
          setPristineInvalidationKey(createPristineInvalidationKey());
          success("L'ordine è stato aggiornato");
          invalidateSalesOrder();
        } else {
          error(response.message);
        }
      })
      .catch(() => {
        error('Errore durante il salvataggio dei dati');
      })
      .finally(() => setSubmitting(false));
  };

  const handleOnVoid = () => {
    const confirm = window.confirm('Sei davvero sicuro di voler annullare questo ordine?');

    if (confirm) {
      setSubmitting(true);
      voidSalesOrder(salesOrderId)
        .then((response) => {
          if (response.success) {
            endTrackUnsavedChanges();
            setPristineInvalidationKey(createPristineInvalidationKey());
            success("L'ordine è stato annullato");
            refreshSalesOrder(salesOrderId)
              .then(() => {
                invalidateSalesOrder();
              })
              .catch(() => {
                error('Si è verificato un errore durante il caricamento dati');
              })
              .finally(() => setSubmitting(false));
          } else {
            error(response.message);
          }
        })
        .catch(() => {
          error('Si è verificato un errore durante l annullamento dell ordine');
        })
        .finally(() => setSubmitting(false));
    }
  };

  const handleOnFormDirty = () => {
    console.debug('form dirty');
    beginTrackUnsavedChanges();
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {submitting && <Loader />}
          {!!response && (
            <SalesOrderForm
              pristineInvalidationKey={pristineInvalidationKey}
              commission={response.commissionData}
              salesOrderData={response.salesOrderData}
              currencies={response.currenciesData}
              paymentTermsData={response.paymentTermsData?.data}
              editable={canEdit}
              canVoid={canVoid && !unsavedChanges}
              onFormDirty={handleOnFormDirty}
              onSubmit={handleOnFormSubmit}
              onVoid={handleOnVoid}
            />
          )}
        </>
      )}
    </>
  );
};

export default SalesOrderHeaderPage;
