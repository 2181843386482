const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
      case 404:
        return {
          success: false,
          authenticated: true,
          authorized: true,
          message:"Il file richiesto non esiste o è stato rimosso"
        };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

const consumeHttp = async (endpointUrl, requestOptions) => {
  const response = await fetch(endpointUrl, requestOptions);

  // if(response.status === 401) {
  //   window.location = '/account/login';
  // }

  // return response.json();

  return response;
};

const postDataAsJsonAndFetch = async (endPointUrl, data) => {
  const response = await consumeHttp(endPointUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

const putDataAsJson = async (endPointUrl, data) => {
  const response = await consumeHttp(endPointUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
};

const patchJson = async (endPointUrl, data /*, fetchOutput*/) => {
  const httpResponse = await consumeHttp(endPointUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const response = { status: httpResponse.status, ok: httpResponse.ok };

  const contentType = httpResponse.headers.get('Content-Type');

  if (contentType && contentType.includes('json')) {
    response.data = await httpResponse.json();
  }

  return response;
};

const fetchResource = async (endpointUrl) => {
  const response = await consumeHttp(endpointUrl);

  if (response.ok) {
    return response.json();
  }

  throw new Error('Server responded: ' + response.status);
};

/*----Generic fetch for component select search-----*/
const fetchResourceHandleResponse = async (endpointUrl) => {
  const response = await fetch(`${endpointUrl}`);
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

export { postDataAsJsonAndFetch, putDataAsJson, fetchResource, patchJson, fetchResourceHandleResponse };
