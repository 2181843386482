import Loader from 'components/layout/Loader';
import { formatNumber } from 'helpers/formatters';
import { roundNumber } from 'helpers/numbers';
import useAuth from 'hooks/useAuth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Table, TabPane, TabContent, Button, Col, Row } from 'reactstrap';
import {
  fetchInvoiceableSalesOrderLines,
  fetchInvoiceableSalesOrders,
} from 'services/api/sales-invoices-management/invoiceable-sales-orders';
import {
  fetchSalesInvoice,
  markSalesInvoiceAsManaged,
  updateSalesInvoiceAssociations,
} from 'services/api/sales-invoices-management/sales-invoices';
import { DEFAULT_ACTIVE_TAB, TAB_ASSOCIATE, TAB_ASSOCIATED } from './constants/navbar-tabs';
import Navbar from './components/navbar';
import { Filters, SearchResult } from './components/search';
import PageTitle from './components/title/PageTitle';
import InvoiceAssociationList from './components/invoice-association';
import useFeedback from 'hooks/useFeedback';
import InvoiceStatusBadge from '../components/InvoiceStatusBadge';
import { dissociateInvoiceAssociation } from './commands';
import { subtract, sum, times } from 'helpers/amount';
import ErrorMessage from 'components/shared/feedbacks/ErrorMessage';

const BTN_SAVE_NAME = 'save';
const BTN_SAVE_AND_APPROVE_NAME = 'saveAndApprove';

const calculateOrderAmountAssociatedToOrderLine = (order, orderLineId) => {
  return order.paymentCertificates
    .filter((paymentCertificate) => !paymentCertificate.deleted)
    .reduce(
      (orderLineAssociatedAmount, paymentCertificate) =>
        sum(
          orderLineAssociatedAmount,
          calculatePaymentCertificateAmountAssociatedToOrderLine(paymentCertificate, orderLineId)
        ),
      0
    );
};

const calculatePaymentCertificateAmountAssociatedToOrderLine = (paymentCertificate, orderLineId) => {
  // console.debug('takePaymentCertificateAmountAssociatedToOrderLine');
  // console.debug(paymentCertificate);
  return paymentCertificate.lines
    .filter(
      (paymentCertificateLine) =>
        parseInt(paymentCertificateLine.lineId) === orderLineId &&
        // paymentCertificateLine.amount > 0 &&
        !paymentCertificateLine.deleted
    )
    .reduce(
      (paymentCertificateAssociatedAmount, paymentCertificateLine) =>
        sum(paymentCertificateAssociatedAmount, paymentCertificateLine.amount),
      0
    );
};

const COMPARE_COLORS = {
  '-1': '#FACD91',
  0: '#CAF982',
  1: '#FF4E36AA',
};

// const compareFloat = (n1, n2) => {
//   const roundedN1 = roundNumber(n1, 2);
//   const roundedN2 = roundNumber(n2, 2);
//   return roundedN1 < roundedN2 ? -1 : roundedN1 === roundedN2 ? 0 : 1;
// };

const compareAssociatedAndInvoiceAmount = (associatedAmount, invoiceAmount) => {
  const multiplier = invoiceAmount >= 0 ? 1 : -1;
  const compareAssociatedAmount = roundNumber(times(associatedAmount, multiplier), 2);
  const compareInvoiceAmount = roundNumber(times(invoiceAmount, multiplier), 2);

  // FUORI RANGE: 0 < Importo associato codice contabile < Importo codice contabile fattura
  if (compareAssociatedAmount < 0 || compareAssociatedAmount > compareInvoiceAmount) {
    return 1;
  }

  // MATCH: Importo associato codice contabile === Importo codice contabile fattura
  if (compareAssociatedAmount === compareInvoiceAmount) {
    return 0;
  }

  // IN RANGE: 0 >= Importo associato codice contabile < Importo codice contabile fattura
  return -1;
};

const WarningMessage = ({ text }) => {
  return (
    <div className="alert alert-warning border border-warning">
      <i className="fa fa-exclamation-triangle" /> {text}
    </div>
  );
};

const styles = {
  financialAccountTable: {
    associatedAmount: {
      color: '#333',
    },
  },
};

const SaveAndMarkAsManagedButton = ({ disabled }) => (
  <Button type="submit" color="success" className="ml-1" name={BTN_SAVE_AND_APPROVE_NAME} disabled={disabled}>
    <i className="fa fa-check" /> Salva e approva
  </Button>
);

const SaveButton = ({ disabled }) => (
  <Button type="submit" color="primary" className="ml-1" name={BTN_SAVE_NAME} disabled={disabled}>
    <i className="fa fa-save" /> Salva
  </Button>
);

const FinancialAccountTable = ({ data }) => {
  const totalInvoiceAmount = data.reduce((tot, ln) => (tot += ln.invoiceAmount), 0);
  const totalAssociatedAmount = data.reduce((tot, ln) => (tot += ln.associatedAmount), 0);
  return (
    <Table bordered responsive className="mb-0">
      <thead>
        <tr>
          <th className="text-uppercase">Codice contabile</th>
          <th className="text-uppercase">Descrizione</th>
          <th className="text-uppercase text-right">Importo in fattura (€)</th>
          <th className="text-uppercase text-right">Importo associato (€)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((ln) => (
          <tr key={ln.financialAccountId}>
            <td>{ln.code}</td>
            <td>
              {ln.description}
              {/* <pre className="d-block">{JSON.stringify(ln, null, '\t')}</pre> */}
            </td>
            <td className="text-right">{formatNumber(ln.invoiceAmount)}</td>
            <td
              className="text-right"
              style={{
                ...styles.financialAccountTable.associatedAmount,
                backgroundColor:
                  COMPARE_COLORS[compareAssociatedAndInvoiceAmount(ln.associatedAmount, ln.invoiceAmount)],
              }}
            >
              {formatNumber(ln.associatedAmount)}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th className="text-right">TOTALE</th>
          <th className="text-right">{formatNumber(totalInvoiceAmount)}</th>
          <th
            className="text-right"
            style={{
              ...styles.financialAccountTable.associatedAmount,
              backgroundColor:
                COMPARE_COLORS[compareAssociatedAndInvoiceAmount(totalAssociatedAmount, totalInvoiceAmount)],
            }}
          >
            {formatNumber(totalAssociatedAmount)}
          </th>
        </tr>
      </tfoot>
    </Table>
  );
};

const createEmptyAssociationLine = () => {
  return { lineId: '', financialAccountId: '', amount: '' };
};

const mapInvoiceApiResponseToInvoice = (response) => {
  const { number, date, customer, status, manageable, accountedAmounts, hasAttachment } =
    response;
  return {
    number,
    date,
    customer,
    status,
    manageable,
    accountedAmounts,
    hasAttachment
  };
};

const mapInvoiceApiResponseToInvoiceAssociations = (response) => {
  return response.commissions.map((commission) => ({
    ...commission,
    orders: commission.orders.map((order) => ({
      ...order,
      paymentCertificates: order.paymentCertificates.map((paymentCertificate) => ({
        ...paymentCertificate,
        deleted: false,
        lines: paymentCertificate.lines.map((line) => ({ ...line, deleted: false })),
      })),
    })),
  }));
};

const mapInvoiceApiResponseToInvoiceAccountedAmountsDictionary = (response) => {
  const financialAccounts = {};
  for (const accountedAmount of response.accountedAmounts) {
    financialAccounts[accountedAmount.financialAccount.id] = {
      financialAccountId: accountedAmount.financialAccount.id,
      code: accountedAmount.financialAccount.code,
      description: accountedAmount.financialAccount.description,
      invoiceAmount: accountedAmount.amount,
      associatedAmount: 0,
    };
  }
  return financialAccounts;
};

const mapInvoiceAssociationsToSaveInvoiceApiRequest = (invoiceAssociations) => {
  return {
    associations: invoiceAssociations.reduce(
      (associatedPaymentCertificates, commission) => [
        ...associatedPaymentCertificates,
        ...commission.orders.reduce((commissionPaymentCertificates, order) => {
          return [
            ...commissionPaymentCertificates,
            ...order.paymentCertificates.map((pc) => {
              return {
                salesInvoiceOrderId: pc.salesInvoiceOrderId,
                salesOrderId: order.id,
                paymentCertificateId: pc.number,
                deleted: pc.deleted,
                lines:
                  pc.lines?.map((ln) => ({
                    salesInvoiceOrderLineId: ln.salesInvoiceOrderLineId,
                    lineId: parseInt(ln.lineId),
                    financialAccountId: parseInt(ln.financialAccountId),
                    amount: ln.amount,
                    deleted: ln.deleted,
                  })) ?? [],
              };
            }),
          ];
        }, []),
      ],
      []
    ),
  };
};

const ManageSalesInvoicePage = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [invoice, setInvoice] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  const [invoiceAccountedAmountsDictionary, setInvoiceAccountedAmountsDictionary] = useState({});
  const [invoiceAssociations, setInvoiceAssociations] = useState([]);

  const [searchFilters, setSearchFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState();

  const { company } = useAuth();
  const { salesInvoiceId } = useParams();
  const { error, success } = useFeedback();

  const companyId = company.id;

  const filterInvoiceableSalesOrders = useCallback(
    async (filters) => {
      setSearchError();
      fetchInvoiceableSalesOrders({ companyId, ...filters }).then((response) => {
        if (response.ok) {
          setSearchResults(response.data.results);
        } else {
          setSearchResults([]);
          setSearchError(response.message);
        }
      });
    },
    [companyId]
  );

  useEffect(() => {
    setLoading(true);
    fetchSalesInvoice(salesInvoiceId, companyId)
      .then((response) => {
        if (response.ok) {
          const invoice = mapInvoiceApiResponseToInvoice(response.data);
          const invoiceAssociations = mapInvoiceApiResponseToInvoiceAssociations(response.data);
          const invoiceAccountedAmountsDictionary = mapInvoiceApiResponseToInvoiceAccountedAmountsDictionary(
            response.data
          );

          setInvoice(invoice);
          setInvoiceAssociations(invoiceAssociations);
          setInvoiceAccountedAmountsDictionary(invoiceAccountedAmountsDictionary);

          // set the customer of the invoice as the default search filter
          if (invoice.manageable) {
            const { id: customerId, displayName: customerDisplayName } = response.data.customer;
            setSearchFilters({ customer: { id: customerId, displayName: customerDisplayName } });
            filterInvoiceableSalesOrders({ customerId });
          }
        } else {
          setErrorMessage(response.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId, filterInvoiceableSalesOrders, salesInvoiceId]);

  const financialAccountOptions = useMemo(() => {
    if (!invoice) {
      return [];
    }
    return invoice.accountedAmounts.map((accountedAmount) => ({
      id: accountedAmount.financialAccount.id,
      label: `${accountedAmount.financialAccount.code} - ${accountedAmount.financialAccount.description}`,
    }));
  }, [invoice]);

  const invoiceAccountedAmounts = useMemo(() => {
    const associatedAmountDictionary = Object.keys(invoiceAccountedAmountsDictionary).reduce((obj, key) => {
      obj[key] = 0;
      return obj;
    }, {});
    for (const commission of invoiceAssociations) {
      for (const order of commission.orders) {
        for (const paymentCertificate of order.paymentCertificates) {
          if (paymentCertificate.deleted) {
            continue;
          }
          for (const associationLine of paymentCertificate.lines) {
            if (associationLine.deleted) {
              continue;
            }
            const financialAccountId = parseInt(associationLine.financialAccountId);
            const amount = parseFloat(associationLine.amount);
            if (isNaN(financialAccountId)) {
              continue;
            }
            if (isNaN(amount)) {
              continue;
            }
            associatedAmountDictionary[financialAccountId] =
              Math.round((associatedAmountDictionary[financialAccountId] + amount) * 100) / 100;
          }
        }
      }
    }
    return Object.keys(invoiceAccountedAmountsDictionary).map((key) => ({
      ...invoiceAccountedAmountsDictionary[key],
      associatedAmount: associatedAmountDictionary[key],
    }));
  }, [invoiceAccountedAmountsDictionary, invoiceAssociations]);

  const paymentCertificatesAlreadyAssociated = useMemo(() => {
    return invoiceAssociations.reduce((commissionOrders, commission) => {
      return {
        ...commissionOrders,
        ...commission.orders.reduce((orderPaymentCertificates, order) => {
          orderPaymentCertificates[order.id] = order.paymentCertificates.map((pc) => pc.number);
          return orderPaymentCertificates;
        }, {}),
      };
    }, {});
  }, [invoiceAssociations]);

  const handleCommissionFilterChange = ({ commissionId, commissionDisplayName }) => {
    setSearchFilters({ commission: { id: commissionId, displayName: commissionDisplayName } });
    filterInvoiceableSalesOrders({ commissionId });
  };
  const handleOrderFilterChange = ({ salesOrderId, salesOrderDisplayName }) => {
    setSearchFilters({ salesOrder: { id: salesOrderId, displayName: salesOrderDisplayName } });
    filterInvoiceableSalesOrders({ salesOrderId });
  };
  const handleCustomerFilterChange = ({ customerId, customerDisplayName }) => {
    setSearchFilters({ customer: { id: customerId, displayName: customerDisplayName } });
    filterInvoiceableSalesOrders({ customerId });
  };
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  const handleAddLine = ({ commissionId, salesOrderId, paymentCertificateNumber }) => {
    // console.debug(`${commissionId} > ${salesOrderId} > CDP nr. ${paymentCertificateNumber} > LINE ADDED`);
    setInvoiceAssociations((invoiceAssociations) =>
      invoiceAssociations.map((commission) =>
        commission.id === commissionId
          ? {
              ...commission,
              orders: commission.orders.map((order) =>
                order.id === salesOrderId
                  ? {
                      ...order,
                      paymentCertificates: order.paymentCertificates.map((paymentCertificate) =>
                        paymentCertificate.number === paymentCertificateNumber
                          ? {
                              ...paymentCertificate,
                              lines: [...paymentCertificate.lines, createEmptyAssociationLine()],
                            }
                          : paymentCertificate
                      ),
                    }
                  : order
              ),
            }
          : commission
      )
    );
  };
  const handleOrderLineChange = ({ commissionId, salesOrderId, paymentCertificateNumber, rowIndex, orderLineId }) => {
    // console.debug(
    //   `${commissionId} > ${salesOrderId} > CDP nr. ${paymentCertificateNumber} > ROWS[${rowIndex}] > ORDER LINE CHANGED TO nr. ${orderLineId}`
    // );
    setInvoiceAssociations((invoiceAssociations) =>
      invoiceAssociations.map((commission) =>
        commission.id === commissionId
          ? {
              ...commission,
              orders: commission.orders.map((order) =>
                order.id === salesOrderId
                  ? {
                      ...order,
                      paymentCertificates: order.paymentCertificates.map((paymentCertificate) =>
                        paymentCertificate.number === paymentCertificateNumber
                          ? {
                              ...paymentCertificate,
                              lines: paymentCertificate.lines.map((line, index) =>
                                index === rowIndex ? { ...line, lineId: orderLineId } : line
                              ),
                            }
                          : paymentCertificate
                      ),
                    }
                  : order
              ),
            }
          : commission
      )
    );
  };
  const handleFinancialAccountChange = ({
    commissionId,
    salesOrderId,
    paymentCertificateNumber,
    rowIndex,
    financialAccountId,
    previousFinancialAccountId,
  }) => {
    // console.debug(
    //   `${commissionId} > ${salesOrderId} > CDP nr. ${paymentCertificateNumber} > ROWS[${rowIndex}] > FINANCIAL ACCOUNT CHANGED FROM ${previousFinancialAccountId} TO ${financialAccountId}`
    // );
    setInvoiceAssociations((invoiceAssociations) =>
      invoiceAssociations.map((commission) =>
        commission.id === commissionId
          ? {
              ...commission,
              orders: commission.orders.map((order) =>
                order.id === salesOrderId
                  ? {
                      ...order,
                      paymentCertificates: order.paymentCertificates.map((paymentCertificate) =>
                        paymentCertificate.number === paymentCertificateNumber
                          ? {
                              ...paymentCertificate,
                              lines: paymentCertificate.lines.map((line, index) =>
                                index === rowIndex ? { ...line, financialAccountId: financialAccountId } : line
                              ),
                            }
                          : paymentCertificate
                      ),
                    }
                  : order
              ),
            }
          : commission
      )
    );
  };
  const handleAmountChange = ({
    commissionId,
    salesOrderId,
    paymentCertificateNumber,
    rowIndex,
    amount,
    previousAmount,
  }) => {
    // console.debug(
    //   `${commissionId} > ${salesOrderId} > CDP nr. ${paymentCertificateNumber} > ROWS[${rowIndex}] > AMOUNT CHANGED FROM ${previousAmount} TO ${amount}`
    // );
    setInvoiceAssociations((invoiceAssociations) =>
      invoiceAssociations.map((commission) =>
        commission.id === commissionId
          ? {
              ...commission,
              orders: commission.orders.map((order) =>
                order.id === salesOrderId
                  ? {
                      ...order,
                      paymentCertificates: order.paymentCertificates.map((paymentCertificate) =>
                        paymentCertificate.number === paymentCertificateNumber
                          ? {
                              ...paymentCertificate,
                              lines: paymentCertificate.lines.map((line, index) =>
                                index === rowIndex ? { ...line, amount: amount } : line
                              ),
                            }
                          : paymentCertificate
                      ),
                    }
                  : order
              ),
            }
          : commission
      )
    );
  };
  const handleLineRemove = ({ commissionId, salesOrderId, paymentCertificateNumber, rowIndex }) => {
    // console.debug(`${commissionId} > ${salesOrderId} > CDP nr. ${paymentCertificateNumber} > LINE ${rowIndex} REMOVED`);
    setInvoiceAssociations((invoiceAssociations) => {
      return invoiceAssociations.map((commission) =>
        commission.id === commissionId
          ? {
              ...commission,
              orders: commission.orders.map((order) =>
                order.id === salesOrderId
                  ? {
                      ...order,
                      paymentCertificates: order.paymentCertificates.map((paymentCertificate) => {
                        if (paymentCertificate.number !== paymentCertificateNumber) {
                          return paymentCertificate;
                        }
                        // the line didn't come from the server -> remove the line
                        if (isNaN(paymentCertificate.lines[rowIndex].salesInvoiceOrderLineId)) {
                          // console.debug("the line didn't come from the server -> remove the line");
                          return {
                            ...paymentCertificate,
                            lines: paymentCertificate.lines.filter((line, index) => index !== rowIndex),
                          };
                        }
                        // the line came from the server -> mark the line as deleted
                        // console.debug('the line came from the server -> mark the line as deleted');
                        return {
                          ...paymentCertificate,
                          lines: paymentCertificate.lines.map((line, index) =>
                            index !== rowIndex ? line : { ...line, deleted: true }
                          ),
                        };
                      }),
                    }
                  : order
              ),
            }
          : commission
      );
    });
  };
  // TODO: refactor this mess
  const handleAssociate = async ({ commission, salesOrder, paymentCertificate }) => {
    // console.debug(`associating ${commission.id} > ${salesOrder.id} > CDP nr. ${paymentCertificate.number}`);
    setSubmitting(true);
    const commissionIndex = invoiceAssociations.findIndex((association) => association.id === commission.id);
    const salesOrderIndex =
      commissionIndex === -1
        ? -1
        : invoiceAssociations[commissionIndex].orders.findIndex((order) => order.id === salesOrder.id);
    const lines = salesOrderIndex === -1 ? (await fetchInvoiceableSalesOrderLines(salesOrder.id)).data?.lines : [];
    // console.debug(lines);
    setInvoiceAssociations((associations) => {
      // console.debug(`commissionIndex: ${commissionIndex}`);
      if (commissionIndex === -1) {
        return [
          ...associations,
          {
            ...commission,
            orders: [
              {
                ...salesOrder,
                lines,
                paymentCertificates: [{ ...paymentCertificate, lines: [createEmptyAssociationLine()] }],
              },
            ],
          },
        ].sort((a, b) => a.id.localeCompare(b.id, 'en'));
      }
      // console.debug(`salesOrderIndex: ${salesOrderIndex}`);
      if (salesOrderIndex === -1) {
        return associations.map((commission, index) =>
          index === commissionIndex
            ? {
                ...commission,
                orders: [
                  ...commission.orders,
                  {
                    ...salesOrder,
                    lines,
                    paymentCertificates: [{ ...paymentCertificate, lines: [createEmptyAssociationLine()] }],
                  },
                ].sort((a, b) => a.id.localeCompare(b.id, 'en')),
              }
            : commission
        );
      }
      return associations.map((commission, index) =>
        index === commissionIndex
          ? {
              ...commission,
              orders: commission.orders.map((order, oIndex) =>
                oIndex === salesOrderIndex
                  ? {
                      ...order,
                      paymentCertificates: [
                        ...order.paymentCertificates,
                        { ...paymentCertificate, lines: [createEmptyAssociationLine()] },
                      ].sort((a, b) => (a.number > b.number ? 1 : -1)),
                    }
                  : order
              ),
            }
          : commission
      );
    });
    setSubmitting(false);
  };
  const handleDissociate = ({ commissionId, salesOrderId, paymentCertificateNumber }) => {
    const payload = { commissionId, salesOrderId, paymentCertificateNumber };
    setInvoiceAssociations((invoiceAssociations) => dissociateInvoiceAssociation(invoiceAssociations, payload));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    // save invoice's associations
    const updateInvoiceRequest = mapInvoiceAssociationsToSaveInvoiceApiRequest(invoiceAssociations);
    const updateInvoiceResponse = await updateSalesInvoiceAssociations(salesInvoiceId, updateInvoiceRequest);

    if (updateInvoiceResponse.ok) {
      let refreshInvoice = false;
      let successMessage;

      // mark invoice as managed if requested
      if (e.nativeEvent.submitter.name === BTN_SAVE_AND_APPROVE_NAME) {
        const markInvoiceAsManagedResponse = await markSalesInvoiceAsManaged(salesInvoiceId);
        if (markInvoiceAsManagedResponse.ok) {
          successMessage = 'La fattura è stata gestita';
          refreshInvoice = true;
        } else {
          error(
            `Non è stato possibile impostare questa fattura come "gestita": ${markInvoiceAsManagedResponse.message.toLowerCase()}`
          );
        }
      } else {
        successMessage = 'La fattura è stata salvata';
      }

      // update the UI...
      const salesInvoiceResponse = await fetchSalesInvoice(salesInvoiceId, companyId);
      if (salesInvoiceResponse.ok) {
        setInvoiceAssociations(mapInvoiceApiResponseToInvoiceAssociations(salesInvoiceResponse.data));
        if (refreshInvoice) {
          setInvoice(mapInvoiceApiResponseToInvoice(salesInvoiceResponse.data));
        }
        success(successMessage);
      } else {
        // ... or prevent the user to see stale data
        setInvoice();
        setInvoiceAccountedAmountsDictionary({});
        setInvoiceAssociations([]);
        setErrorMessage(
          `${successMessage} ma si è verificato un errore nell'aggiornamento dei dati: ${salesInvoiceResponse.message.toLowerCase()}. Si prega di ricaricare la pagina.`
        );
      }
    } else {
      error(updateInvoiceResponse.message);
    }
    setSubmitting(false);
  };

  const memoizedInvoiceAssociations = useMemo(() => {
    return invoiceAssociations.map((commission) => ({
      ...commission,
      orders: commission.orders.map((order) => ({
        ...order,
        lines: order.lines.map((orderLine) => {
          const lineAssociatedAmount = calculateOrderAmountAssociatedToOrderLine(order, orderLine.id);
          const remainder = subtract(orderLine.remainder, lineAssociatedAmount);
          // console.debug({ remainder: orderLine.remainder, lineAssociatedAmount });
          const remainderExceeded = remainder < 0;
          return { ...orderLine, remainder, remainderExceeded };
        }),
      })),
    }));
  }, [invoiceAssociations]);

  const atLeastOneOrderLineAmountExceeded = memoizedInvoiceAssociations.some((commission) =>
    commission.orders.some((order) => order.lines.some((orderLine) => orderLine.remainderExceeded))
  );

  const saveButtonDisabled = useMemo(() => {
    return (
      invoiceAccountedAmounts.some((x) => compareAssociatedAndInvoiceAmount(x.associatedAmount, x.invoiceAmount) > 0) ||
      atLeastOneOrderLineAmountExceeded
      // invoiceAccountedAmounts.some((x) => x.associatedAmount > x.invoiceAmount) || atLeastOneOrderLineAmountExceeded
    );
  }, [invoiceAccountedAmounts, atLeastOneOrderLineAmountExceeded]);

  const saveAndMarkAsManagedButtonDisabled = useMemo(() => {
    return (
      invoiceAccountedAmounts.some(
        (x) => compareAssociatedAndInvoiceAmount(x.associatedAmount, x.invoiceAmount) !== 0
      ) || atLeastOneOrderLineAmountExceeded
      // invoiceAccountedAmounts.some((x) => x.associatedAmount !== x.invoiceAmount) || atLeastOneOrderLineAmountExceeded
    );
  }, [invoiceAccountedAmounts, atLeastOneOrderLineAmountExceeded]);

  return (
    <>
      {(loading || submitting) && <Loader />}
      <PageTitle invoice={invoice} />
      {errorMessage && <ErrorMessage text={errorMessage} />}
      {invoice && (
        <>
          {/* InvoiceManagementHeader */}
          <header className="mb-2 rounded p-2" style={{ border: '1px solid rgb(194, 203, 229)' }}>
            <Row className="mb-1">
              <Col xs={9}>
                <div className="mr-3 d-inline-block">
                  Cliente: <b>{invoice.customer.displayName}</b>
                </div>
                <div className="d-inline-block">
                  P.IVA. <b>{invoice.customer.vatNumber}</b>
                </div>
              </Col>
              <Col xs={3} className="text-right">
                Stato: <InvoiceStatusBadge statusId={invoice.status.id} statusName={invoice.status.name} />
              </Col>
            </Row>
            <FinancialAccountTable data={invoiceAccountedAmounts} />
            {invoice.hasAttachment && (
              <div className="mt-2">
                <a
                  href={`/api/sales-invoices/${salesInvoiceId}/attachment`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary btn-sm"
                >
                  <i className="fa fa-file" /> Visualizza allegato
                </a>
              </div>
            )}
          </header>
          {/* TAB CONTAINER */}
          <>
            <Navbar activeTab={activeTab} onTabChange={handleTabChange} associateTabDisabled={!invoice.manageable} />
            <TabContent activeTab={activeTab} className="py-2">
              {/* Tab "associate commissions/orders" */}
              <TabPane tabId={TAB_ASSOCIATE}>
                <Filters
                  companyId={companyId}
                  filters={searchFilters}
                  onCommissionChange={handleCommissionFilterChange}
                  onOrderChange={handleOrderFilterChange}
                  onCustomerChange={handleCustomerFilterChange}
                />
                <div>
                  {searchError && <span className="text-danger">{searchError}</span>}
                  {searchResults.length > 0 ? (
                    searchResults.map((result) => (
                      <SearchResult
                        key={result.commission.id}
                        result={result}
                        paymentCertificatesAlreadyAssociated={paymentCertificatesAlreadyAssociated}
                        onAssociate={handleAssociate}
                      />
                    ))
                  ) : (
                    <WarningMessage text="La ricerca non ha prodotto nessun risultato." />
                  )}
                </div>
              </TabPane>
              {/* Tab "associated commissions/orders" */}
              <TabPane tabId={TAB_ASSOCIATED}>
                {invoiceAssociations.length > 0 ? (
                  <form method="PUT" onSubmit={handleSubmit}>
                    <InvoiceAssociationList
                      commissions={memoizedInvoiceAssociations}
                      financialAccounts={financialAccountOptions}
                      onAddLine={handleAddLine}
                      onAmountChange={handleAmountChange}
                      onFinancialAccountChange={handleFinancialAccountChange}
                      onOrderLineChange={handleOrderLineChange}
                      onLineRemove={handleLineRemove}
                      onDissociate={handleDissociate}
                      editable={invoice.manageable}
                    />
                    {invoice.manageable && (
                      <div className="mt-2 text-right">
                        <SaveAndMarkAsManagedButton disabled={saveAndMarkAsManagedButtonDisabled} />
                        <SaveButton disabled={saveButtonDisabled} />
                      </div>
                    )}
                  </form>
                ) : (
                  <WarningMessage text="Questa fattura non è ancora associata a nessun certificato di pagamento." />
                )}
              </TabPane>
            </TabContent>
          </>
        </>
      )}
    </>
  );
};

export default ManageSalesInvoicePage;
