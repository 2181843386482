import CustomInput from 'components/shared/forms/CustomInput';
import { ACCEPTED_DOCUMENT_TYPES } from 'config';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

const STYLES = {
    saveButton: {
        marginTop: '22px'
    }
};

const chosenPolicyType = ["Polizza posa e rimpiazzo", "Polizza assicurativa cantiere", "Polizza decennale postuma"]

const UploadOtherDocument = ({ onSubmit, formId, formName, inline, documentType, other, isFileRequired=true }) => {
    const [isFileUploaded, setisFileUploaded] = useState(false);
    const [isPolicyTypeChosen, setIsPolicyTypeChosen] = useState(isFileRequired ? false : true);

    const colSpace = inline ? 12 : 2;

    const canSubmit = isFileRequired? isFileUploaded && isPolicyTypeChosen : isPolicyTypeChosen;
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const docData = other ?{...e.target.getObjectData(), otherId: other.otherId} : e.target.getObjectData();
        console.log(e.target.getObjectData().policyType)
        onSubmit?.(formData, docData, chosenPolicyType[e.target.getObjectData().policyType - 1], documentType );
    }

    const handleUploadFile = (e) => {
        if (e.target.files[0])
            setisFileUploaded(true);
    }

    const handlePolicyTypeChange = (e) => {
        if (e.target.value !== "")
            setIsPolicyTypeChosen(true)
        else
            setIsPolicyTypeChosen(false)
    }

    return (
        <Form key={formName + formId} onSubmit={handleSubmit}>
            <Row>
                {/* IMPORTANT: IF CHANGE THIS OPTIONS REMEMBER TO CHANGE ALSO BACKEND DICTIONARY IN 'DocumentConstants' */}
                <Col lg={inline ? 12 : 3}>
                    <CustomInput
                        type="select"
                        label="Tipo Polizza"
                        name="policyType"
                        defaultValue={other?.policyType?.typeId}
                        onChange={handlePolicyTypeChange}
                        required
                    >
                        <option value="">--- Seleziona</option>
                        <option value={1}>Polizza posa e rimpiazzo</option>
                        <option value={2}>Polizza assicurativa cantiere</option>
                        <option value={3}>Polizza decennale postuma</option>
                    </CustomInput>
                </Col>
                <Col lg={colSpace}>
                    <CustomInput
                        type="text"
                        label="Numero Polizza"
                        name="policyNumber"
                        defaultValue={other?.policyNumber}
                    />
                </Col>
                <Col lg={colSpace}>
                    <CustomInput
                        type="date"
                        min="2000-01-01"
                        max="9999-12-31"
                        label="Data Scadenza"
                        name="endDate"
                        defaultValue={other?.endDate}
                    />
                </Col>
                <Col lg={colSpace}>
                    <CustomInput
                        type="file"
                        accept={ACCEPTED_DOCUMENT_TYPES}
                        label="Polizza"
                        name="file"
                        onChange={handleUploadFile}
                        required={isFileRequired}
                    />
                </Col>
                <Col lg={colSpace} style={STYLES.saveButton}>
                    <Button disabled={!canSubmit} color="primary"><i className="fa fa-save" /></Button>
                </Col>
            </Row>
        </Form>
    );
};

export default UploadOtherDocument;