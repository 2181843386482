import Loader from 'components/layout/Loader';
import ROLE_PERMISSIONS from 'constants/role-permissions';
import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useMemo, useState } from 'react';
import ROUTES from 'routes';
import { fetchCommissions } from 'services/api/commissions';
import { COLUMNS, DEFAULT_SORT, INITIAL_PAGE_SIZE, PAGE_SIZES, ALLOW_UNSORT } from './components/CommissionDataTable';
import DataTableV2, { useDataTable } from 'modules/DataTable';
import PageHeader, { PageButtonContainer, PageLink } from 'components/layout/PageHeader';
import useTerritoriesOptions from 'hooks/options/useTerritoriesOptions';
import useCommissionStatusesOptions from 'hooks/options/useCommissionStatusesOptions';
import useDataTableOverrides from 'hooks/tables/useDataTableOverrides';

const CommissionIndexPage = () => {
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState();

  const { territoriesOptions } = useTerritoriesOptions();
  const { commissionStatusesOptions } = useCommissionStatusesOptions();

  const { error } = useFeedback();
  const { permissions, company, applicationRoleId } = useAuth();
  const canCreateCommission = permissions.includes(ROLE_PERMISSIONS.CREATE_COMMISSION);

  const { overrides: columnsOverrides } = useDataTableOverrides('commission', applicationRoleId);

  const { filters, page, pageSize, sort, dataTableProps } = useDataTable({
    allowUnsort: ALLOW_UNSORT,
    initialPageSize: INITIAL_PAGE_SIZE,
    initialSort: DEFAULT_SORT,
  });

  // track grid items request changes
  const request = useMemo(
    () => ({
      filters: { ...filters, companyId: company.id },
      take: pageSize,
      skip: (page - 1) * pageSize,
      sort: {
        column: sort.column,
        direction: sort.direction,
      },
    }),
    [filters, company.id, page, pageSize, sort.column, sort.direction]
  );

  // fetch the grid items
  useEffect(() => {
    setLoading(true);
    fetchCommissions(request)
      .then(({ commissions, totRecords }) => {
        setItems(
          commissions.map((commission) => ({
            ...commission,
            expectedStartDate: new Date(commission.expectedStartDate),
            expectedEndDate: new Date(commission.expectedEndDate),
          }))
        );
        setItemsCount(totRecords);
      })
      .catch((err) => {
        setItems([]);
        error(err.message);
      })
      .finally(() => setLoading(false));
  }, [request, error]);

  // track columns' info changes
  const columns = useMemo(() => {
    return COLUMNS.map((column) => {
      const { columnName, ...columnOverride } = columnsOverrides?.find((co) => co.columnName === column.value) ?? {
        columnName: column.value,
      };
      switch (column.value) {
        case 'territory':
          columnOverride.options = territoriesOptions;
          break;
        case 'commissionStatus':
          columnOverride.options = commissionStatusesOptions;
          break;
        default:
          break;
      }
      return Object.keys(columnOverride).length > 0 ? { ...column, ...columnOverride } : column;
    });
  }, [commissionStatusesOptions, territoriesOptions, columnsOverrides]);

  return (
    <>
      <PageHeader title="Elenco Commesse">
        <PageButtonContainer>
          {canCreateCommission && (
            <PageLink to={ROUTES.COMMISSION_MANAGEMENT.NEW} iconName="fas fa-plus" text="Crea commessa" primary />
          )}
        </PageButtonContainer>
      </PageHeader>
      {loading && <Loader />}
      <div>
        {columnsOverrides && (
          <DataTableV2
            responsive
            fixedLayout
            size="sm"
            columns={columns}
            data={items}
            totalElements={itemsCount}
            pageSizes={PAGE_SIZES}
            {...dataTableProps}
          />
        )}
      </div>
    </>
  );
};

export default CommissionIndexPage;
