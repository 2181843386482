import {
  createEmptySuccessfulResponse,
  createErrorResponse,
  createSuccessfullResponseWithData,
} from 'helpers/api';

const fetchSalesInvoice = async (salesInvoiceId, companyId) => {
  try {
    const response = await fetch(`/api/sales-invoices/${salesInvoiceId}?companyId=${companyId}`);
    const responseBody = await response.json();
    if (response.ok) {
      return createSuccessfullResponseWithData({ ...responseBody, date: new Date(responseBody.date) });
    }
    return createErrorResponse(responseBody.detail);
  } catch (err) {
    console.error(err);
    return createErrorResponse();
  }
};

const updateSalesInvoiceAssociations = async (salesInvoiceId, invoiceAssociations) => {
  try {
    const response = await fetch(`/api/sales-invoices/${salesInvoiceId}/manage`, {
      method: 'PUT',
      body: JSON.stringify(invoiceAssociations),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return createEmptySuccessfulResponse();
    } else {
      const responseBody = await response.json();
      return createErrorResponse(responseBody.detail);
    }
  } catch {
    return createErrorResponse();
  }
};

const markSalesInvoiceAsManaged = async (salesInvoiceId) => {
  try {
    const response = await fetch(`/api/sales-invoices/${salesInvoiceId}/approve`, { method: 'PATCH' });
    if (response.ok) {
      return createEmptySuccessfulResponse();
    } else {
      const responseBody = await response.json();
      return createErrorResponse(responseBody.detail);
    }
  } catch {
    return createErrorResponse();
  }
};

export { fetchSalesInvoice, updateSalesInvoiceAssociations, markSalesInvoiceAsManaged };
