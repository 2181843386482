import { fetchJson2 } from 'helpers/api';

const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 404:
      return {
        success: false,
        message: 'nessun dato trovato',
      };
    case 422:
      return {
        success: false,
        message:
          response.detail ||
          response.title ||
          'La commessa non può essere cancellata in quanto ha una o più sotto commesse collegate.',
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

const fetchCommissions = ({ filters = {}, skip, take, sort }) => {
  const params = {
    ...filters,
    skip,
    take,
    sortDirection: sort.direction,
    sortBy: sort.column,
  };
  const searchParams = new URLSearchParams(JSON.parse(JSON.stringify(params)));
  return fetchJson2(`/api/commissions?${searchParams}`);
};

const createCommission = async (postData) => {
  const response = await fetch(`/api/commissions`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: postData,
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const fetchCommissionWithId = async (commissionId) => {
  const response = await fetch(`/api/commissions/${commissionId}`);
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const updateCommissionWithId = async (commissionId, postData) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: postData,
    });
    const responseBody = await response.json();

    return {
      success: response.ok,
      authenticated: true,
      authorized: true,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

const deleteCommissionWithId = async (commissionId) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}`, { method: 'DELETE' });
    const responseBody = await response.json();

    return {
      success: response.ok,
      authenticated: true,
      authorized: true,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

const updateCommissionStatusToClose = async (commissionId) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}/request-close`, {
      method: 'PATCH',
    });

    return {
      success: response.ok,
      authenticated: true,
      authorized: true,
      message: response.detail || response.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

const updateCommissionStatusToReOpened = async (commissionId) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}/request-reopened`, {
      method: 'PATCH',
    });

    return {
      success: response.ok,
      authenticated: true,
      authorized: true,
      message: response.detail || response.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

export {
  fetchCommissions,
  fetchCommissionWithId,
  updateCommissionWithId,
  deleteCommissionWithId,
  createCommission,
  updateCommissionStatusToClose,
  updateCommissionStatusToReOpened,
};
