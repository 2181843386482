import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import ROUTES from 'routes';
import { fetchBusinessLines } from 'services/api/businessLines';

const createBusinessLinesDictionary = (businessLines) => {
  return businessLines.reduce((businessLinesDictionary, businessLine) => {
    const { businessLineType } = businessLine;
    const groupKey = businessLineType.name;
    if (!businessLinesDictionary[groupKey]) {
      businessLinesDictionary[groupKey] = [];
    }
    businessLinesDictionary[groupKey].push(businessLine);
    return businessLinesDictionary;
  }, {});
};

const NewCommision = () => {
  const [businessLines, setBusinessLines] = useState([]);
  const {
    company: { id: companyId },
  } = useAuth();
  const [businessLineId, setBusinessLineId] = useState('');
  const navigate = useNavigate();
  const { error } = useFeedback();

  const isSubmitAvailable = Boolean(businessLineId);

  useEffect(() => {
    const cancellationTokenSource = new AbortController();
    fetchBusinessLines({ filters: { companyId }, cancellationToken: cancellationTokenSource.signal })
      .then(({ businessLines }) => setBusinessLines(createBusinessLinesDictionary(businessLines)))
      .catch((err) => {
        if (!cancellationTokenSource.signal.aborted) {
          error(err.message);
        }
      });
    return () => cancellationTokenSource.abort();
  }, [companyId, error]);

  const handleBusinessLineChange = (e) => {
    const selectedBusinessLineId = e.target.value;
    setBusinessLineId(selectedBusinessLineId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(
      ROUTES.COMMISSION_MANAGEMENT.NEW_DATA.replace(':companyId', companyId).replace(':businessLineId', businessLineId)
    );
  };

  return (
    <Form>
      <FormGroup className="mb-1">
        <Label htmlFor="businessLineId">Scegli linea di business</Label>
        <Input
          type="select"
          id="businessLineId"
          disabled={businessLines.length === 0}
          value={businessLineId}
          onChange={handleBusinessLineChange}
        >
          <option value="">-- Scegli una linea di business</option>
          {Object.keys(businessLines).map((groupKey, index) => (
            <optgroup key={index} label={groupKey}>
              {businessLines[groupKey].map((line) => (
                <option key={line.businessLineId} value={line.businessLineId}>
                  {line.businessLineId} - {line.description}
                </option>
              ))}
            </optgroup>
          ))}
        </Input>
      </FormGroup>
      <Button type="submit" color="primary" disabled={!isSubmitAvailable} onClick={handleSubmit}>
        Crea commessa
      </Button>
    </Form>
  );
};

export default NewCommision;
