const summarizeCommission = (commission) => ({
  id: commission.commissionId,
  customer: { name: commission.customer.businessName },
  description: commission.description,
  cig: commission.cig,
  cup: commission.cup,
});

const mapCommissionWorksiteToDeliveryPlace = (worksite) => {
  return {
    id: 0,
    displayName: 'Indirizzo cantiere',
    localityLines: [],
    addressLines: [worksite.address, worksite.location].filter(
      (addressLine) => addressLine?.trim().length > 0 ?? false
    ),
    municipality: makeMunicipalityLine(worksite.postalCode, worksite.city, worksite.municipality, worksite.country),
    frazione: null,
  };
};

const mapSiteToDeliveryPlace = (site) => {
  return {
    id: site.id,
    displayName: site.displayName,
    localityLines: [site.name],
    addressLines: [site.address],
    municipality: makeMunicipalityLine(site.postalCode, site.city, site.province, site.countryId),
    frazione: null,
  };
};

const makeMunicipalityLine = (postalCode, city, province, country) => {
  const parts = [];
  if (postalCode?.trim()) {
    parts.push(postalCode);
  }
  if (city?.trim()) {
    parts.push(city);
  }
  if (province?.trim()) {
    parts.push(`(${province})`);
  }

  if (parts.length > 0) {
    if (country?.trim()) {
      return parts.join(' ') + ' - ' + country;
    }
    return parts.join(' ');
  }

  return country || '';
};

const createDeadlinePeriods = (startDate, endDate) => {
  const end = {
    year: endDate.getFullYear(),
    month: endDate.getMonth() + 1,
  };

  let year = startDate.getFullYear();
  let month = startDate.getMonth() + 1;

  const months = [];

  while (year < end.year || (year === end.year && month <= end.month)) {
    months.push({ year, month });
    month = month === 12 ? 1 : month + 1;

    if (month === 1) {
      year = year + 1;
    }
  }

  return months;
};

const createDefaultDeadline = (creationDate, deliveryDate, amountToAllocate, defaultPaymentTermId) => {
  const orderPeriods = createDeadlinePeriods(creationDate, deliveryDate);
  return orderPeriods.map((period, index) =>
    index !== orderPeriods.length - 1
      ? {
          ...period,
          year: period.year,
          month: period.month,
          percentage: 0,
          amount: 0,
          paymentTermId: '',
        }
      : {
          ...period,
          year: period.year,
          month: period.month,
          percentage: 100,
          amount: amountToAllocate,
          paymentTermId: defaultPaymentTermId,
        }
  );
};

const createOtherDeliveryPlaceOption = () => ({ id: -1, displayName: 'Altro', addressLines: [], localityLines: [] });

export {
  summarizeCommission,
  mapCommissionWorksiteToDeliveryPlace,
  mapSiteToDeliveryPlace,
  makeMunicipalityLine,
  createDefaultDeadline,
  createOtherDeliveryPlaceOption,
};
