import CustomInput from 'components/shared/forms/CustomInput';
import { useState } from 'react';
import { Form, Button } from 'reactstrap';
import { ActionDDT } from '../constants';
import { ACCEPTED_DOCUMENT_TYPES } from 'config';

const DDTForm = ({ onEdit, onSubmit, onAssociate, purchaseOrders, document, modalAction, isFileRequired = true }) => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const canSubmit = !isFileRequired || isFileUploaded;
  const disablePurchaseOrderIdLabel = modalAction === ActionDDT.UPDATE || document?.purchaseOrderId;
  const canShow = modalAction !== ActionDDT.ASSOCIATE;
  console.log(modalAction);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.getObjectData());
    const formData = new FormData(e.target);
    switch (modalAction) {
      case ActionDDT.UPLOAD:
        onSubmit?.(formData);
        break;
      case ActionDDT.UPDATE:
        onEdit?.(formData);
        break;
      default:
        onAssociate?.(e.target.getObjectData().purchaseOrderId);
    }
  };

  const handleUploadFile = (e) => {
    setIsFileUploaded(Boolean(e.target.files[0]));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CustomInput
        type="select"
        label="Ordine di acquisto"
        name="purchaseOrderId"
        defaultValue={document?.purchaseOrderId}
        disabled={disablePurchaseOrderIdLabel}
      >
        <option value="">-- Seleziona</option>
        {purchaseOrders?.map((purchaseOrderId) => (
          <option key={`purchaseOrder-${purchaseOrderId}`} value={purchaseOrderId}>
            {purchaseOrderId}
          </option>
        ))}
      </CustomInput>
      {canShow && (
        <>
          <CustomInput
            type="file"
            accept={ACCEPTED_DOCUMENT_TYPES}
            label="Allegato"
            name="file"
            onChange={handleUploadFile}
            required={isFileRequired}
          />
          <CustomInput
            type="text"
            label="N° del documento"
            name="documentNumber"
            defaultValue={document?.documentNumber}
          />
          <CustomInput
            type="date"
            label="Data del documento"
            name="attachmentDate"
            defaultValue={document?.documentDate?.toISOString().slice(0, 10)}
          />
        </>
      )}
      {canShow && <CustomInput rows={4} type="textarea" label="Nota" name="note" defaultValue={document?.note} />}
      <Button disabled={!canSubmit} title="Salva il DDT" color="primary">
        <i className="fa fa-save" /> SALVA
      </Button>
    </Form>
  );
};

export default DDTForm;
