import { fetchJson2 } from 'helpers/api';

const createEmptySuccessfulResponse = () => {
  return {
    ok: true,
  };
};

const createErrorResponse = (errorMessage = 'Si è verificato un errore') => {
  return {
    ok: false,
    message: errorMessage,
  };
};

const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 404:
      return {
        success: false,
        authenticated: true,
        authorized: true,
        message: "L'ordine richiesto non esiste o è stato rimosso",
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

const fetchSalesOrderWithId = async (salesOrderId) => {
  try {
    const response = await fetch(`/api/sales-orders/${salesOrderId}`);
    const responseBody = await response.json();
    return {
      success: response.ok,
      authenticated: true,
      authorized: true,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

/**
 *
 * @deprecated
 */
const fetchCommssionSalesOrders = async (commissionId) => {
  const response = await fetch(`/api/sales-orders?commissionId=${commissionId}`);
  return await response.json();
};

const fetchTotRecords = async (commissionId) => {
  const response = await fetch(`/api/sales-orders/GetTotRecords?commissionId=${commissionId}`);
  return await response.json();
};

const fetchCommssionSalesOrdersByPagination = async (commissionId, request) => {
  const queryParams = {
    CommissionId: commissionId,
    SalesOrderId: request?.filters?.salesOrderId,
    CustomerOrderNoReference: request?.filters?.customerOrderNoReference,
    customerName: request?.filters?.customerName,
    DateFrom: request?.filters?.dateFrom,
    DateTo: request?.filters?.dateTo,
    StatusId: request?.filters?.status?.id,
    CurrentPage: request?.currentPage,
    NumRecords: request?.pageSize,
    Sort: request?.sort,
    SortColumn: request?.sortColumn,
  };

  const params = new URLSearchParams(JSON.parse(JSON.stringify(queryParams))).toString();

  try {
    const response = await fetch(`/api/sales-orders?${params}`);
    // console.log(await response.json())
    return {
      ok: response.ok,
      status: response.status,
      data: await response.json(),
    };
  } catch (err) {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const fetchSalesOrderBodyWithId = async (salesOrderId) => {
  return fetchJson2(`/api/sales-orders/${salesOrderId}?includeLines=${true}`);
};

const createSalesOrder = async (data) => {
  const response = await fetch('/api/sales-orders', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const updateSalesOrder = async (orderId, data) => {
  try {
    const response = await fetch(`/api/sales-orders/${orderId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();

    return {
      success: response.ok,
      authenticated: true,
      authorized: true,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

const voidSalesOrder = async (salesOrderId) => {
  try {
    const response = await fetch(`/api/sales-orders/${salesOrderId}/void`, { method: 'PATCH' });
    if (response.ok) {
      return {
        success: response.ok,
        authenticated: true,
        authorized: true,
      };
    } else {
      const responseBody = await response.json();
      return {
        ok: false,
        message: responseBody.detail || responseBody.title || 'Si è verificato un errore.',
      };
    }
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

const updateSalesOrderLines = async (orderId, data) => {
  const response = await fetch(`/api/sales-orders/${orderId}/lines`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
    };
  }
  return handleErrorResponse(response);
};

const fetchStatus = async () => {
  try {
    const response = await fetch(`/api/sales-orders/status`);
    return {
      ok: response.ok,
      status: response.status,
      data: await response.json(),
    };
  } catch (err) {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const requestSalesOrderApproval = async (salesOrderId) => {
  try {
    const response = await fetch(`/api/sales-orders/${salesOrderId}/request-approval`, { method: 'PATCH' });
    const responseBody = await response.json();

    return {
      success: response.ok,
      authenticated: true,
      authorized: true,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

const approveSalesOrder = async (salesOrderId) => {
  try {
    const response = await fetch(`/api/sales-orders/${salesOrderId}/approve`, { method: 'PATCH' });
    const responseBody = await response.json();

    return {
      success: response.ok,
      authenticated: true,
      authorized: true,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      success: false,
      message: 'Si è verificato un errore',
    };
  }
};

const requestSalesOrderChanges = async (salesOrderId, request) => {
  console.log(request);
  console.log(JSON.stringify(request));
  const response = await fetch(`/api/sales-orders/${salesOrderId}/request-changes`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const fetchSalesOrderPaymentCertificates = async (salesOrderId) => {
  try {
    const response = await fetch(`/api/sales-orders/${salesOrderId}/payment-certificates`);
    const data = await response.json();

    if (response.ok) {
      return {
        ok: true,
        data: {
          orderId: data.orderId,
          orderDate: new Date(Date.parse(data.orderDate)),
          amount: data.amount,
          invoicedAmountPercentage: data.invoicedAmountPercentage,
          customerName: data.customerName,
          guaranteeWithholdingId: data.guaranteeWithholdingId,
          guaranteeWithholdingPercentage: data.guaranteeWithholdingPercentage,
          guaranteeWithholdingHeaderText: data.guaranteeWithholdingHeaderText,
          guaranteeWithholdingTableHeaderText: data.guaranteeWithholdingTableHeaderText,
          amountRemainder: data.amountRemainder,
          guaranteeWithholdingAmountRemainder: data.guaranteeWithholdingAmountRemainder,
          advanceWithholdingAmountRemainder: data.advanceWithholdingAmountRemainder,
          canCreate: data.canCreate,
          paymentCertificates: data.paymentCertificates.map((paymentCertificate) => ({
            number: paymentCertificate.number,
            date: new Date(Date.parse(paymentCertificate.date)),
            amount: paymentCertificate.amount,
            guaranteeWithholdingValue: paymentCertificate.guaranteeWithholdingValue,
            redeterminationAmount: paymentCertificate.redeterminationAmount,
            advance: paymentCertificate.advance,
            advancePercentage: paymentCertificate.advancePercentage,
            viewable: true,
            downloadable: paymentCertificate.downloadable,
            editable: false,
            status: paymentCertificate.status,
          })),
        },
      };
    } else {
      return {
        ok: false,
        errorMessage: 'Si è verificato un errore nel caricare i certificati di pagamento',
      };
    }
  } catch {
    return {
      ok: false,
      errorMessage: 'Si è verificato un errore nel caricare i certificati di pagamento',
    };
  }
};

const fetchSalesOrderPaymentCertificate = async (salesOrderId, paymentCertificateId) => {
  try {
    const httpResponse = await fetch(`/api/sales-orders/${salesOrderId}/payment-certificates/${paymentCertificateId}`);
    const httpResponseBody = await httpResponse.json();
    return {
      ok: httpResponse.ok,
      data: httpResponseBody,
      message: httpResponse.ok
        ? undefined
        : httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const createPaymentCertificate = async (salesOrderId, data) => {
  try {
    const response = await fetch(`/api/sales-orders/${salesOrderId}/payment-certificates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return {
        ok: true,
        message: 'Il certificato di pagamento è stato creato con successo.',
      };
    } else {
      const responseBody = await response.json();
      return {
        ok: false,
        message:
          responseBody.detail ||
          responseBody.title ||
          'Si è verificato un errore nella creazione del certificato di pagamento.',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore nella creazione del certificato di pagamento.',
    };
  }
};

const editSalesOrderPaymentCertificate = async (salesOrderId, paymentCertificateId, paymentCertificateData) => {
  try {
    const httpResponse = await fetch(`/api/sales-orders/${salesOrderId}/payment-certificates/${paymentCertificateId}`, {
      method: 'PUT',
      body: JSON.stringify(paymentCertificateData),
      headers: { 'Content-Type': 'application/json' },
    });
    if (httpResponse.ok) {
      return { ok: true };
    } else {
      const httpResponseBody = await httpResponse.json();
      return {
        ok: false,
        message: httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const requestSalesOrderPaymentCertificateApproval = async (salesOrderId, paymentCertificateId) => {
  try {
    const httpResponse = await fetch(
      `/api/sales-orders/${salesOrderId}/payment-certificates/${paymentCertificateId}/request-approval`,
      { method: 'PATCH' }
    );
    const httpResponseBody = await httpResponse.json();
    if (httpResponse.ok) {
      return { ok: true, data: httpResponseBody };
    } else {
      return {
        ok: false,
        data: httpResponseBody,
        message: httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const approveSalesOrderPaymentCertificate = async (salesOrderId, paymentCertificateId) => {
  try {
    const httpResponse = await fetch(
      `/api/sales-orders/${salesOrderId}/payment-certificates/${paymentCertificateId}/approve`,
      { method: 'PATCH' }
    );
    const httpResponseBody = await httpResponse.json();
    if (httpResponse.ok) {
      return { ok: true, data: httpResponseBody };
    } else {
      return {
        ok: false,
        data: httpResponseBody,
        message: httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const fetchSalesOrderAssociatedInvoices = async (salesOrderId) => {
  try {
    const response = await fetch(`/api/sales-orders/${salesOrderId}/invoices`);
    const responseBody = await response.json();
    // console.debug(responseBody)
    if (response.ok) {
      return {
        ok: response.ok,
        data: {
          ...responseBody,
          items: responseBody.items.map((item) => ({
            ...item,
            invoiceDate: new Date(Date.parse(item.invoiceDate)),
            paymentCertificateDate: new Date(Date.parse(item.paymentCertificateDate)),
          })),
        },
      };
    }
    return {
      ok: response.ok,
      message: responseBody.detail || responseBody.message || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const settleSalesOrder = async (salesOrderId) => {
  try {
    const response = await fetch(`/api/sales-orders/${salesOrderId}/settle`, { method: 'PATCH' });
    if (response.ok) {
      return createEmptySuccessfulResponse();
    } else {
      const responseBody = await response.json();
      return createErrorResponse(responseBody.detail);
    }
  } catch {
    return createErrorResponse();
  }
};

export {
  fetchSalesOrderWithId,
  fetchCommssionSalesOrders,
  fetchCommssionSalesOrdersByPagination,
  fetchSalesOrderBodyWithId,
  fetchTotRecords,
  createSalesOrder,
  updateSalesOrder,
  updateSalesOrderLines,
  fetchStatus,
  requestSalesOrderApproval,
  approveSalesOrder,
  voidSalesOrder,
  requestSalesOrderChanges,
  fetchSalesOrderPaymentCertificates,
  createPaymentCertificate,
  fetchSalesOrderPaymentCertificate,
  editSalesOrderPaymentCertificate,
  requestSalesOrderPaymentCertificateApproval,
  approveSalesOrderPaymentCertificate,
  fetchSalesOrderAssociatedInvoices,
  settleSalesOrder,
};
