import { memo } from 'react';
import { Badge } from 'reactstrap';

const STATUS_TO_MANAGE = 0;
const STATUS_MANAGED = 2;

const COLORS = {
  [STATUS_TO_MANAGE]: 'dark',
  // 1: 'warning',
  [STATUS_MANAGED]: 'info',
  // 3: 'primary',
  // 4: 'success',
};

const ICONS = {
  [STATUS_TO_MANAGE]: 'fa fa-pen-alt',
  // 1: 'fa fa-exclamation-triangle',
  [STATUS_MANAGED]: 'fa fa-check',
  // 3: 'fa fa-print',
  // 4: 'fa fa-university',
};

const FONT_SIZES = {
  lg: '1rem',
};

const PurchaseInvoiceStatusBadge = ({ statusId, statusName, size = 'sm' }) => {
  const color = COLORS[statusId];
  const icon = ICONS[statusId];
  const fontSize = FONT_SIZES[size];
  return (
    <Badge color={color} style={fontSize && { fontSize }}>
      {icon && <i className={icon} />} {statusName}
    </Badge>
  );
};

export default memo(PurchaseInvoiceStatusBadge);
