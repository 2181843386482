import { fetchJson2, patch } from 'helpers/api';

const fetchUserNotifications = async (params = {}, cancellationToken) => {
  const searchParams = new URLSearchParams(params);
  const notifications = await fetchJson2(`/api/dashboard/user-notifications?${searchParams}`, cancellationToken);
  return notifications.map((n) => ({ ...n, date: new Date(n.dateTime) }));
};

const fetchUserNotificationsCount = async (params = {}, cancellationToken) => {
  const searchParams = new URLSearchParams(params);
  return fetchJson2(`/api/dashboard/user-notifications/count?${searchParams}`, cancellationToken);
};

const markUserNotificationAsRead = (userNotificationId) => {
  return patch(`/api/dashboard/user-notifications/${userNotificationId}/mark-as-read`);
};

export { fetchUserNotifications, fetchUserNotificationsCount, markUserNotificationAsRead };
