import CustomInput from 'components/shared/forms/CustomInput';
import { ACCEPTED_DOCUMENT_TYPES } from 'config';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

const STYLES = {
  saveButton: {
    marginTop: '22px',
  },
};

const UploadContractDocument = ({ onSubmit, formId, formName, inputLabelName, inline, documentType, contract, isFileRequired=true }) => {
  const [canSubmit, setCanSubmit] = useState(isFileRequired ? false : true);
  const colSpace = inline ? 12 : 3;

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    setCanSubmit(false);
    const docData = contract ?{...e.target.getObjectData(), contractId: contract.contractId} : e.target.getObjectData();
    onSubmit?.(formData, docData, documentType);
  };

  const handleOnUploadFileChange = (e) => {
    setCanSubmit(!!e.target.files[0]);
  };

  return (
    <Form key={formName + formId} onSubmit={handleSubmit}>
      <Row>
        <Col lg={colSpace}>
          <CustomInput
            type="file"
            accept={ACCEPTED_DOCUMENT_TYPES}
            label={inputLabelName}
            name="file"
            onChange={handleOnUploadFileChange}
            required={isFileRequired}
          // groupClassName="mb-0"
          />
        </Col>
        <Col lg={colSpace}>
          <CustomInput
            type="text"
            label="Note"
            name="note"
            defaultValue={contract?.note}
          // groupClassName="mb-0"
          />
        </Col>
        <Col lg={colSpace} style={STYLES.saveButton}>
          <Button disabled={!canSubmit} color="primary">
            <i className="fa fa-save" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UploadContractDocument;
