import useFeedback from 'hooks/useFeedback';
import { useCallback, useEffect, useState } from 'react';
import useParseCustomResponse from './useParseCustomResponse';
import { fetchCommissionWithId } from 'services/api/commissions';
import { fetchCurrencies } from 'services/api/currencies';
import { fetchPaymentTermsV2 } from 'services/api/paymentTerms';

const useFetchSalesOrderFormData = (commissionId) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const fetchData = useCallback(async () => {
    const [responseCommission, responseCurrencies, paymentTermsRawResponse] = await Promise.all([
      fetchCommissionWithId(commissionId),
      fetchCurrencies(),
      fetchPaymentTermsV2(),
    ]).catch(() => {
      error('Errore durante il download dei dati', 'Errore');
    });

    const commission = parseCustomResponse(responseCommission);

    if (commission.success) {
      const commissionData = {
        cig: commission.data.cig,
        cup: commission.data.cup,
        commissionId: commission.data.commissionId,
        customerBusinessName: commission.data.customer.businessName,
        customerId: commission.data.customer.id,
        description: commission.data.description,
        paymentTermId: commission.data.paymentTerm.id,
      };

      const salesOrderData = {
        shortDescription: '',
        customerOrderNoReference: '',
        customerId: commission.data.customer.id,
        customer: {
          id: commission.data.customer.id,
          name: commission.data.customer.displayName,
        },
        date: '',
        paymentTerm: {
          id: commission.data.paymentTerm.id,
          paymentTermModification: null,
        },
        currency: {
          id: 'EUR',
        },
        guaranteeWithholding: null,
        isSpecificCustomer: false,
      };

      return {
        commissionData,
        salesOrderData,
        currenciesData: responseCurrencies,
        paymentTermsData: parseCustomResponse(paymentTermsRawResponse),
      };
    }
  }, [parseCustomResponse, error, commissionId]);

  useEffect(() => {
    fetchData()
      .then(({ commissionData, salesOrderData, currenciesData, paymentTermsData }) => {
        setResponse({
          commissionData,
          salesOrderData,
          currenciesData,
          paymentTermsData,
        });
      })
      .finally(() => setLoading(false));
  }, [fetchData]);

  return { loading, response };
};

export default useFetchSalesOrderFormData;
