import ROLE_PERMISSIONS from 'constants/role-permissions';
import useAuth from 'hooks/useAuth';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import { useState } from 'react';
import { AlertDropdown, AlertDropdownToggle } from './Alerts';
import useConfirmSwitchCompany from 'hooks/useConfirmSwitchCompany';
import { useNavigate } from 'react-router';

const TopMenu = ({ alerts, onToggle }) => {
  const { authenticated, fullName, permissions, authenticationMode, company, logout } = useAuth();

  const [alertsDropdownExpanded, setAlertsDropdownExpanded] = useState(false);
  const [userDropdownExpanded, setUserDropdownExpanded] = useState(false);

  const canViewUsers = permissions.includes(ROLE_PERMISSIONS.VIEW_USERS);
  const canManageConfiguration = permissions.includes(ROLE_PERMISSIONS.MANAGE_CONFIGURATION);

  const navigate = useNavigate();
  const { openDialog } = useConfirmSwitchCompany();
  const { clear: clearUnsavedChanges, askConfirm } = useUnsavedChanges();

  const handleAlertsDropdownToggle = () => {
    setAlertsDropdownExpanded((prev) => !prev);
  };

  const redirectToAlertActionLink = (actionLink, forceReload) => {
    if (forceReload) {
      window.location.href = actionLink;
    } else {
      navigate(actionLink);
    }
  };

  const handleOnAlertClick = ({ isSpaLink, companyId, actionLink }) => {
    console.debug('alert clicked', companyId, actionLink, isSpaLink);
    if (company.id !== companyId) {
      openDialog(companyId, () => {
        console.debug(`company switched to ${companyId}`);
        redirectToAlertActionLink(actionLink, true);
      });
    } else {
      console.debug('no need to switch the company because it is the same');
      redirectToAlertActionLink(actionLink, !isSpaLink);
    }
    setAlertsDropdownExpanded(false);
  };

  const handleLogout = async (e) => {
    e.preventDefault();

    askConfirm(async () => {
      clearUnsavedChanges();
      const response = await fetch('/api/auth/logout', {
        method: 'POST',
      });

      if (response.ok) {
        logout();
      } else {
        alert('Si è verificato un errore durante il logout');
      }
    });
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-2 static-top shadow-sm">
      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={onToggle}>
        <i className="fa fa-bars"></i>
      </button>

      <div className="d-none d-sm-inline-block mr-auto ml-md-3 my-2 my-md-0 mw-100 ">
        <img src="/img/logogdweb.png" style={{ width: '125px' }} alt="Gruppo Dimensione" />
      </div>

      <ul className="navbar-nav ml-auto">
        {/* Notifiche */}
        <li id="nav_alerts" className="nav-item dropdown no-arrow mx-1">
          <AlertDropdownToggle
            alertsCount={alerts.length}
            dropdownCollapsed={!alertsDropdownExpanded}
            onClick={handleAlertsDropdownToggle}
          />
          {alerts.length > 0 && (
            <AlertDropdown alerts={alerts} collapsed={!alertsDropdownExpanded} onAlertClick={handleOnAlertClick} />
          )}
        </li>

        {authenticated && (
          <>
            {company && (
              <>
                <li className="topbar-divider d-none d-sm-block"></li>
                <li className="nav-item">
                  <a className="nav-link disabled text-muted small" href="/">
                    {company.name ?? 'Nessuna società impostata'}
                    {/* {company.name ? `${company.id} - ${company.name}` : 'Nessuna società impostata'} */}
                  </a>
                </li>
              </>
            )}

            <li className="topbar-divider d-none d-sm-block"></li>

            {/* <!-- Nav Item - User Information --> */}
            <li className={`nav-item dropdown no-arrow${userDropdownExpanded ? ' show' : ''}`}>
              <button
                className="nav-link dropdown-toggle"
                id="userDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setUserDropdownExpanded((prev) => !prev)}
                // onFocus={() => setUserDropdownExpanded(true)}
                // onBlur={() => setUserDropdownExpanded(false)}
              >
                <span className="mr-2 text-gray-600 small">{fullName}</span>
                <i className="chip-log fas fa-user fa-sm text-white-90"></i>
                {/*Capire se sti quattro span qui sotto sono utili o se possono essere tolti */}
                {/*<span id="user_id" data-id="@user_id" style={{ display: 'none' }}></span>*/}
                {/*<span id="user_role" data-role="@user_role" style={{ display: 'none' }}></span>*/}
                {/*<span id="user_esolver" data-esolver="@user_esolver" style={{ display: 'none' }}></span>*/}
                {/*<span id="user_dbgruppo" data-dbgruppo="@user_dbgruppo" style={{ display: 'none' }}></span>*/}
              </button>
              {/* <!-- Dropdown - User Information --> */}
              <div
                className={`dropdown-menu dropdown-menu-right shadow animated--grow-in${
                  userDropdownExpanded ? ' show' : ''
                }`}
                aria-labelledby="userDropdown"
              >
                {authenticationMode === 'form' && (
                  <a className="dropdown-item" href="/account/changepassword">
                    <i className="fas fa-lock fa-sm fa-fw mr-2 text-gray-400"></i>
                    Cambia Password
                  </a>
                )}

                <a className="dropdown-item" href="/account/impostasocieta">
                  <i className="fas fa-industry fa-sm fa-fw mr-2 text-gray-400"></i>
                  Imposta società
                </a>

                {canViewUsers && (
                  <a className="dropdown-item" href="/account/">
                    <i className="fas fa-users fa-sm fa-fw mr-2 text-gray-400"></i>
                    Utenti
                  </a>
                )}

                {canManageConfiguration && (
                  <a className="dropdown-item" href="/configurazione/">
                    <i className="fas fa-cog fa-sm fa-fw mr-2 text-gray-400"></i>
                    Configurazione
                  </a>
                )}

                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </button>
              </div>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default TopMenu;
