import { useMemo } from 'react';
// import { Table } from 'reactstrap';

import styles from 'components/commission-management/budget/BudgetTableStyles';
import BudgetTableHeader from 'components/commission-management/budget/BudgetTableHeader';
import BudgetTableColumns from 'components/commission-management/budget/BudgetTableColumns';

import { pivotPeriod } from 'helpers/budget';
import BudgetTableBody from 'components/commission-management/budget/BudgetTableBody';
import BudgetTableFooter from 'components/commission-management/budget/BudgetTableFooter';

const BudgetTable = ({
  activities,
  startPeriod,
  endPeriod,
  collapsed,
  readonly,
  displaySubactivityCostError = true,
  onCostChange,
  onRevenueChange,
  onPeriodCostChange,
  onPeriodRevenueChange,
  onSubactivityDescriptionChange,
  onAddSubactivity,
  onRemoveSubactivity,
  onCompleteSubactivity,
  canCompleteSubactivity,
}) => {
  const periods = useMemo(() => {
    return pivotPeriod(startPeriod, endPeriod);
  }, [startPeriod, endPeriod]);

  const tableClassName = styles['budget-table'] + (collapsed ? ' ' + styles['budget-table--collapsed'] : '');

  return (
    <div className={styles['table-container']}>
      <table className={tableClassName}>
        <BudgetTableColumns periods={periods} collapsed={collapsed} />
        <BudgetTableHeader periods={periods} collapsed={collapsed} />
        <BudgetTableBody
          activities={activities}
          periods={periods}
          collapsed={collapsed}
          readonly={readonly}
          displaySubactivityCostError={displaySubactivityCostError}
          onCostChange={onCostChange}
          onRevenueChange={onRevenueChange}
          onPeriodCostChange={onPeriodCostChange}
          onPeriodRevenueChange={onPeriodRevenueChange}
          onSubactivityDescriptionChange={onSubactivityDescriptionChange}
          onAddSubactivity={onAddSubactivity}
          onRemoveSubactivity={onRemoveSubactivity}
          onCompleteSubactivity={onCompleteSubactivity}
          canCompleteSubactivity={canCompleteSubactivity}
        />
        <BudgetTableFooter activities={activities} periods={periods} collapsed={collapsed} />
      </table>
    </div>
  );
};

// import { Table } from 'reactstrap';

// const BudgetTable = () => {
//   const cols = [...Array(19   )];
//   const rows = [...Array(10)];
//   return (
//     <Table responsive striped bordered>
//       <thead>
//         <tr>
//           <th>H1</th>
//           {cols.map((_, i) => (
//             <th key={`H${i}`}>H{i + 2}</th>
//           ))}
//         </tr>
//       </thead>
//       <tbody>
//         {rows.map((r, i) => (
//           <tr>
//             <td>Intestazione Fissa</td>
//             {cols.map((_, j) => (
//               <td key={`H${i}C${j}`}>
//                 <input type="text" className="form-control" />
//               </td>
//             ))}
//           </tr>
//         ))}
//       </tbody>
//     </Table>
//   );
// };

export default BudgetTable;
