import UnsavedChangesAwareNavLink from 'components/shared/navigation/UnsavedChangesAwareNavLink';
import { useParams } from 'react-router';
import { Nav, NavItem } from 'reactstrap';
import ROUTES from 'routes';

const CommissionNavItem = ({ to, end, children }) => {
  return (
    <NavItem>
      <UnsavedChangesAwareNavLink to={to} end={end}>
        {children}
      </UnsavedChangesAwareNavLink>
    </NavItem>
  );
};

const DEFAULT_TABS_VISIBILITY = Object.freeze({
  commission: true,
  documents: true,
  budget: true,
  approval: true,
  enabledUsers: true,
  linkedCommissions: true,
  purchaseOrders: true,
  salesOrders: true,
  purchaseInvoices: true,
  salesInvoices: true,
});

const CommissionNavbar = ({ tabsVisibilityOverrides }) => {
  const { commissionId } = useParams();

  const tabsVisibility = {
    ...DEFAULT_TABS_VISIBILITY,
    ...tabsVisibilityOverrides,
  };

  return (
    <Nav tabs>
      {tabsVisibility.commission && (
        <CommissionNavItem to={ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS.replace(':commissionId', commissionId)}>
          Commessa
        </CommissionNavItem>
      )}
      {tabsVisibility.documents && (
        <CommissionNavItem to={ROUTES.COMMISSION_MANAGEMENT.DOCUMENTS.replace(':commissionId', commissionId)}>
          Documenti
        </CommissionNavItem>
      )}
      {tabsVisibility.budget && (
        <CommissionNavItem to={ROUTES.COMMISSION_MANAGEMENT.BUDGET.replace(':commissionId', commissionId)}>
          Budget
        </CommissionNavItem>
      )}
      {tabsVisibility.approval && (
        <CommissionNavItem to={ROUTES.COMMISSION_MANAGEMENT.BUDGET_APPROVAL.replace(':commissionId', commissionId)}>
          Approvazione
        </CommissionNavItem>
      )}
      {tabsVisibility.enabledUsers && (
        <CommissionNavItem to={ROUTES.COMMISSION_MANAGEMENT.ENABLED_USERS.replace(':commissionId', commissionId)}>
          Utenti abilitati
        </CommissionNavItem>
      )}
      {tabsVisibility.linkedCommissions && (
        <CommissionNavItem to={'/commesse/:commissionId/commesse-collegate'.replace(':commissionId', commissionId)}>
          Commesse collegate
        </CommissionNavItem>
      )}
      {tabsVisibility.purchaseOrders && (
        <CommissionNavItem
          to={ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.INDEX.replace(':commissionId', commissionId)}
        >
          Ordini fornitori
        </CommissionNavItem>
      )}
      {tabsVisibility.salesOrders && (
        <CommissionNavItem to={ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.INDEX.replace(':commissionId', commissionId)}>
          Ordini clienti
        </CommissionNavItem>
      )}
      {tabsVisibility.purchaseInvoices && (
        <CommissionNavItem to={'/commesse/:commissionId/fatture-passive'.replace(':commissionId', commissionId)}>
          Fatture passive
        </CommissionNavItem>
      )}
      {tabsVisibility.salesInvoices && (
        <CommissionNavItem to={'/commesse/:commissionId/fatture-attive'.replace(':commissionId', commissionId)}>
          Fatture attive
        </CommissionNavItem>
      )}
    </Nav>
  );
};

export default CommissionNavbar;
