import Loader from 'components/layout/Loader';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Col, FormText, Row } from 'reactstrap';
import PaymentCertificateForm from '../components/form';
import PurchaseOrderSummary from '../components/PurchaseOrderSummary';
import PaymentCertificatesTable from '../components/list';
import { NavLink } from 'react-router-dom';
import { routeFactory } from 'routes';
import {
  approvePurchaseOrderPaymentCertificate,
  fetchPurchaseOrderPaymentCertificate,
  requestPurchaseOrderPaymentCertificateApproval,
  requestPurchaseOrderPaymentCertificateChanges,
} from 'services/api/purchase-orders';
import useFeedback from 'hooks/useFeedback';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import GUARANTEE_WITHHOLDING_TYPES from 'constants/guarantee-withholding-types';
import { roundNumber } from 'helpers/numbers';
import PaymentCertificateStatusBadge from 'components/commission-management/payment-certificates/status-badge';
import useAuth from 'hooks/useAuth';
import { Dialog } from 'primereact/dialog';
import RequestChangesRejectionNote from '../components/RequestChangesRejectionNote';
import useAlerts from 'hooks/useAlerts';


const WarningMessage = ({ text }) => {
  return (
    <div className="alert alert-warning border border-warning mb-3">
      <i className="fa fa-exclamation-triangle" /> {text}
    </div>
  );
};

const mapPaymentCertificateDetailToFormData = (detail) => ({
  type: detail.type,
  amount: detail.amount,
  date: detail.date,
  isRedetermination: detail.isRedetermination,
  redeterminationAmount: detail.redeterminationAmount,
  redeterminationNote: detail.redeterminationNote,
  advanceWithholdingPercentage: detail.advanceWithholdingPercentage,
  advanceWithholdingAmount: detail.advanceWithholdingAmount,
  note: detail.note,
  isRecoverTotalAdvance: !detail.advanceWithholdingPercentage && !!detail.advanceWithholdingAmount,
});

const mapInitialDataToPageModel = (initialData) => ({
  status: initialData.detail.status,
  editable: initialData.detail.editable,
  approvalRequestable: initialData.detail.approvalRequestable,
  approvable: initialData.detail.approvable,
  formData: mapPaymentCertificateDetailToFormData(initialData.detail),
  order: { ...initialData.order, orderDate: new Date(Date.parse(initialData.order.orderDate)) },
  creatorId: initialData.detail.creatorId,
  rejectionNote: initialData.detail.rejectionNote,
  showRejectionNote: initialData.detail.showRejectionNote,
  advanceWithholdingAmountRemainder: roundNumber(
    initialData.previousPaymentCertificates.reduce(
      (advanceWithholdingAmountRemainder, paymentCertificate) =>
        paymentCertificate.number !== initialData.detail.paymentCertificateId
          ? advanceWithholdingAmountRemainder + paymentCertificate.advance
          : advanceWithholdingAmountRemainder,
      0
    ),
    2
  ),
  guaranteeWithholdingAmountRemainder:
    initialData.order.guaranteeWithholdingId === GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE &&
    initialData.order.guaranteeWithholdingPercentage > 0
      ? roundNumber(
          initialData.previousPaymentCertificates.reduce(
            (remainder, paymentCertificate) => remainder + paymentCertificate.guaranteeWithholdingValue,
            0
          ),
          2
        )
      : 0,
  amountRemainder: roundNumber(
    initialData.order.amount -
      initialData.previousPaymentCertificates.reduce(
        (remainder, paymentCertificate) =>
          paymentCertificate.number !== initialData.detail.paymentCertificateId
            ? remainder + paymentCertificate.amount
            : remainder,
        0
      ),
    2
  ),
  previousPaymentCertificates: initialData.previousPaymentCertificates.map((pc) => ({
    ...pc,
    date: new Date(Date.parse(pc.date)),
  })),
});

const PurchaseOrderPaymentCertificateDetailPage = () => {
  const [paymentCertificateInfo, setPaymentCertificateInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { commissionId, purchaseOrderId, ...otherParams } = useParams();
  const paymentCertificateId = parseInt(otherParams.paymentCertificateId);

  const { success, error } = useFeedback();
  const { reloadAlerts } = useAlerts();
  const { hasPermission } = useCommissionPermissions();
  const { userId } = useAuth();

  const canEdit =
    hasPermission(PURCHASE_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.EDIT) && (paymentCertificateInfo?.editable ?? false) && userId === paymentCertificateInfo.creatorId;
  const canApprove =
    hasPermission(PURCHASE_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.APPROVE_REJECT) &&
    (paymentCertificateInfo?.approvable ?? false);
  const canSelfApprove =
    hasPermission(PURCHASE_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.SELF_APPROVE) 
    && ((paymentCertificateInfo?.approvable ?? false) || (paymentCertificateInfo?.approvalRequestable ?? false)) && userId === paymentCertificateInfo.creatorId;
  const canRequestApproval =
    hasPermission(PURCHASE_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.REQUEST_APPROVAL) &&
    !canSelfApprove &&
    (paymentCertificateInfo?.approvalRequestable ?? false) && userId === paymentCertificateInfo.creatorId;

  useEffect(() => {
    setLoading(true);
    fetchPurchaseOrderPaymentCertificate(purchaseOrderId, paymentCertificateId)
      .then((response) => {
        if (response.ok) {
          console.log(response.data)
          setPaymentCertificateInfo(mapInitialDataToPageModel(response.data));
        } else {
          setErrorMessage(response.message);
        }
      })
      .finally(() => setLoading(false));
  }, [paymentCertificateId, purchaseOrderId]);

  const handleOnRequestApprovalClick = async () => {
    setSubmitting(true);
    const response = await requestPurchaseOrderPaymentCertificateApproval(purchaseOrderId, paymentCertificateId);
    if (response.ok) {
      const { editable, approvalRequestable, approvable, status } = response.data;
      const updateData = { editable, approvalRequestable, approvable, status };
      // console.debug({ ...paymentCertificateInfo, ...updateData });
      reloadAlerts();
      setPaymentCertificateInfo((pc) => ({ ...pc, ...updateData }));
      success(
        `La richiesta di approvazione per il certificato di pagamento n° ${paymentCertificateId} dell'ordine ${purchaseOrderId} è stata presa in carico.`
      );
    } else {
      error(response.message);
    }
    setSubmitting(false);
  };

  const handleOnRequestChangesClick = async (request) => {
    setIsModalVisible(false);
    setSubmitting(true);
    const response = await requestPurchaseOrderPaymentCertificateChanges(purchaseOrderId, paymentCertificateId, request);
    if (response.ok) {
      const { editable, approvalRequestable, approvable, status } = response.data;
      const updateData = { editable, approvalRequestable, approvable, status, rejectionNote: request.rejectionNote };
      // console.debug({ ...paymentCertificateInfo, ...updateData });
      reloadAlerts();
      setPaymentCertificateInfo((pc) => ({ ...pc, ...updateData }));
      success(
        `La richiesta di modifica per il certificato di pagamento n° ${paymentCertificateId} dell'ordine ${purchaseOrderId} è stata presa in carico.`
      );
    } else {
      error(response.message);
    }
    setSubmitting(false);
  };

  const handleOnApproveClick = async () => {
    setSubmitting(true);
    const response = await approvePurchaseOrderPaymentCertificate(purchaseOrderId, paymentCertificateId);
    if (response.ok) {
      const { editable, approvalRequestable, approvable, status } = response.data;
      const updateData = { editable, approvalRequestable, approvable, status, showRejectionNote:false };
      // console.debug({ ...paymentCertificateInfo, ...updateData });
      reloadAlerts();
      setPaymentCertificateInfo((pc) => ({ ...pc, ...updateData }));
      success(
        `Il certificato di pagamento n° ${paymentCertificateId} dell'ordine ${purchaseOrderId} è stato approvato.`
      );
    } else {
      error(response.message);
    }
    setSubmitting(false);
  };

  return (
    <>
      {(loading || submitting) && <Loader />}
      {paymentCertificateInfo && (
        <>
          <Dialog
            header="Richiesta Modifiche"
            visible={isModalVisible}
            style={{ width: "60vw" }}
            onHide={() => setIsModalVisible(false)}
          >
            <RequestChangesRejectionNote
              onSubmit={handleOnRequestChangesClick}
            />
          </Dialog>
          
          <Row className="mb-4">
            <Col xs={10}>
              <h3>Certificato di pagamento n° {paymentCertificateId}</h3>
            </Col>
            <Col xs={2} className="text-right">
              <NavLink
                to={routeFactory.commissionManagement.purchaseOrders.paymentCertificates.index(
                  commissionId,
                  purchaseOrderId
                )}
                style={{ fontSize: "2rem" }}
                title={`Torna all'elenco dei certificati di pagamento dell'ordine ${purchaseOrderId}`}
                className="text-danger"
              >
                &times;
              </NavLink>
            </Col>
          </Row>
          <PurchaseOrderSummary order={paymentCertificateInfo.order} />
          {paymentCertificateInfo.previousPaymentCertificates.length > 0 && (
            <div className="mb-4">
              <h4 className="mb-3">Certificati emessi</h4>
              <PaymentCertificatesTable
                guaranteeWithholdingTableHeaderText={
                  paymentCertificateInfo.order
                    .guaranteeWithholdingTableHeaderText
                }
                paymentCertificates={
                  paymentCertificateInfo.previousPaymentCertificates
                }
              />
            </div>
          )}
          <div>
            <p>
              Stato:{" "}
              <PaymentCertificateStatusBadge
                statusId={paymentCertificateInfo.status.id}
                statusName={paymentCertificateInfo.status.name}
              />
            </p>
            {paymentCertificateInfo.showRejectionNote && paymentCertificateInfo.rejectionNote && <WarningMessage text={paymentCertificateInfo.rejectionNote} />}
            <PaymentCertificateForm
              initialData={mapPaymentCertificateDetailToFormData(
                paymentCertificateInfo.formData
              )}
              readOnly
              guaranteeWithholding={{
                typeId: paymentCertificateInfo.order.guaranteeWithholdingId,
                percentage:
                  paymentCertificateInfo.order.guaranteeWithholdingPercentage ||
                  0,
              }}
              advanceTypeAllowed={paymentCertificateId === 1}
              amountRemainder={paymentCertificateInfo.amountRemainder}
              advanceWithholdingAmountRemainder={
                paymentCertificateInfo.advanceWithholdingAmountRemainder
              }
              guaranteeWithholdingAmountRemainder={
                paymentCertificateInfo.guaranteeWithholdingAmountRemainder
              }
            />
            <div>
              {canEdit && (
                <NavLink
                  to={routeFactory.commissionManagement.purchaseOrders.paymentCertificates.edit(
                    commissionId,
                    purchaseOrderId,
                    paymentCertificateId
                  )}
                  className="btn btn-primary mr-2"
                >
                  Modifica
                </NavLink>
              )}
              {canRequestApproval && (
                <Button
                  type="button"
                  color="warning"
                  className="mr-2"
                  onClick={(handleOnRequestApprovalClick)}
                >
                  Richiedi approvazione
                </Button>
              )}
              {canApprove && (
                <Button
                  color="danger"
                  className="mr-2"
                  onClick={() => setIsModalVisible(true)}
                >
                  Richiedi modifiche
                </Button>
              )}
              {(canApprove || canSelfApprove) && (
                <Button
                  type="button"
                  color="success"
                  className="mr-2"
                  onClick={handleOnApproveClick}
                >
                  Approva
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
    </>
  );
};

export default PurchaseOrderPaymentCertificateDetailPage;
