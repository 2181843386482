import useFeedback from 'hooks/useFeedback';
import { useCallback, useEffect, useState } from 'react';
import useParseCustomResponse from './useParseCustomResponse';
import { fetchCommissionWithId } from 'services/api/commissions';
import { fetchSalesOrderWithId } from 'services/api/sales-order';
import { fetchCurrencies } from 'services/api/currencies';
import { fetchPaymentTermsV2 } from 'services/api/paymentTerms';

const useFetchSalesOrderHeaderData = (commissionId, salesOrderId) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const refreshSalesOrder = async (salesOrderId) => {
    fetchSalesOrderWithId(salesOrderId).then((data) => {
      const salesOrder = data;
      if (salesOrder.success) {
        const resp = { ...response, salesOrderData: salesOrder.data };
        setResponse(resp);
      } else {
        error(salesOrder.message);
      }
    });
  };

  const fetchData = useCallback(async () => {
    const [responseCommission, responseSalesOrder, responseCurrencies, paymentTermsRawResponse] = await Promise.all([
      fetchCommissionWithId(commissionId),
      fetchSalesOrderWithId(salesOrderId),
      fetchCurrencies(),
      fetchPaymentTermsV2(),
    ]).catch(() => {
      error('Errore durante il download dei dati', 'Errore');
    });

    const commission = parseCustomResponse(responseCommission);
    const salesOrder = parseCustomResponse(responseSalesOrder);

    if (commission.success && salesOrder.success) {
      const commissionData = {
        cig: commission.data.cig,
        cup: commission.data.cup,
        commissionId: commission.data.commissionId,
        customerBusinessName: commission.data.customer.businessName,
        customerId: commission.data.customer.id,
        description: commission.data.description,
        paymentTermId: commission.data.paymentTerm.id,
        isCommissionClosed: commission.data.isCommissionClosed,
      };

      const {
        customer: { customerId, name: customerName },
        ...partialSalesOrder
      } = salesOrder.data;

      const salesOrderData = {
        ...partialSalesOrder,
        customer: { id: customerId, name: customerName },
        isSpecificCustomer: salesOrder.data.customerId !== commissionData.customerId,
      };

      return {
        commissionData,
        salesOrderData,
        currenciesData: responseCurrencies,
        paymentTermsData: parseCustomResponse(paymentTermsRawResponse),
      };
    }
  }, [parseCustomResponse, error, commissionId, salesOrderId]);

  useEffect(() => {
    fetchData()
      .then(({ commissionData, salesOrderData, currenciesData, paymentTermsData }) => {
        setResponse({
          commissionData,
          salesOrderData,
          currenciesData,
          paymentTermsData,
        });
      })
      .finally(() => setLoading(false));
  }, [fetchData]);

  return { loading, response, refreshSalesOrder };
};

export default useFetchSalesOrderHeaderData;
