import { fetchJson2 } from 'helpers/api';

const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

/**
 *
 * @param {{ cancellationToken: AbortSignal }} params
 * @returns
 */
const fetchCommissionStatuses = ({ cancellationToken }) => {
  return fetchJson2('/api/commission-status', cancellationToken);
};

/** @deprecated use fetchCommissionStatuses */
const fetchCommissionStatus = async () => {
  const response = await fetch(`/api/commission-status`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

export { fetchCommissionStatus, fetchCommissionStatuses };
