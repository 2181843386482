import ReadOnlyDecimalNumber from 'components/utils/ReadOnlyDecimalNumber';
import { Row, Col } from 'reactstrap';

const BudgetHeaderTotals = ({ totalCost, revenue, margin }) => {
  return (
    <Row className="text-dark mb-1">
      <Col md={6} xl={3}>
        <span className="font-weight-bold">TOTALE COSTO:&nbsp;</span>
        <ReadOnlyDecimalNumber n={totalCost} />
        <span>€</span>
      </Col>
      <Col md={6} xl={3}>
        <span className="font-weight-bold">RICAVO:&nbsp;</span>
        <ReadOnlyDecimalNumber n={revenue} />
        <span>€</span>
      </Col>
      <Col md={6} xl={3}>
        <span className="font-weight-bold">MARGINE:&nbsp;</span>
        <ReadOnlyDecimalNumber n={margin.amount} />
        <span>€</span>
        {!isNaN(margin.percentage) && isFinite(margin.percentage) && <span> ({margin.percentage}%)</span>}
      </Col>
    </Row>
  );
};

export default BudgetHeaderTotals;
