import Loader from 'components/layout/Loader';
import DataTable from 'components/shared/DataTable';
import useFeedback from 'hooks/useFeedback';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { fetchPurchaseInvoices } from 'services/api/purchase-invoices';
import useParseCustomResponse from './shared/hooks/useParseCustomResponse';
import { NavLink } from 'react-router-dom';
import ROUTES from 'routes';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import { fetchPurchaseInvoiceOrderStatuses } from 'services/api/purchase-invoice-order-statuses';
import { Badge } from 'reactstrap';
import { format } from 'helpers/amount';

const STATUS_COLORS = {
  0: 'dark',
  1: 'warning',
  2: 'success',
  3: 'danger',
  4: 'secondary',
};

const formatDate = (date) =>
  date.toLocaleDateString('it-IT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

const displayInvoiceOrderAssociationName = (association) => {
  return `${association.purchaseOrderId}${association.cdp ? ` - CDP nr. ${association.cdp}` : ''}`;
};

//settings degli header dei campi
//le ultime due proprietà servono per specificare
//se un singolo campo può essere ordinabile o filtrabile
const DATA_TABLE_COLUMNS_DEFINITION = [
  {
    heading: 'Nr. Fatt.',
    value: 'purchaseNumberInvoice',
    type: 'text',
    filterable: false,
    sortable: false,
  },
  {
    heading: 'Data fattura',
    value: 'dateInvoice',
    type: 'date',
    filterable: true,
    sortable: false,
    cellTemplate: (val, item) => {
      return formatDate(new Date(item.dateInvoice));
    },
  },
  {
    heading: 'Rag. sociale',
    value: 'ragSociale',
    type: 'text',
    filterable: true,
    sortable: true,
    cellTemplate: (val, item) => {
      return `${item.supplier.businessName}(${item.supplier.code === null ? '' : item.supplier.code}${
        item.supplier.code === null || item.supplier.vatNumber === null ? '' : '-'
      }${item.supplier.vatNumber})`;
    },
  },
  {
    heading: 'Ordine-CDP',
    value: 'purchaseOrderId',
    type: 'text',
    filterable: false,
    sortable: false,
    cellTemplate: (val, item, context) => {
      return context.permission ? (
        <NavLink
          to={ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.INVOICES.replace(
            ':commissionId',
            context.commissionId
          ).replace(':purchaseOrderId', val)}
        >
          {displayInvoiceOrderAssociationName(item)}
        </NavLink>
      ) : (
        <div>{displayInvoiceOrderAssociationName(item)}</div>
      );
    },
  },
  {
    heading: 'Importo ordine €',
    value: 'orderAmount',
    type: 'number',
    filterable: false,
    sortable: false,
    cellTemplate: (val) => {
      return `${format(val)}`;
    },
  },
  {
    heading: 'Importo fattura €',
    value: 'totInvoiceAmount',
    type: 'number',
    filterable: false,
    sortable: false,
    cellTemplate: (val, item) => {
      return <div className={val !== item.chargedAmount ? 'text-danger' : undefined}>{format(val)}</div>;
    },
  },
  {
    heading: 'Importo di competenza €',
    value: 'chargedAmount',
    type: 'number',
    filterable: false,
    sortable: false,
    cellTemplate: (val, item) => {
      return <div className={val !== item.totInvoiceAmount ? 'text-danger' : undefined}>{format(val)}</div>;
    },
  },
  {
    heading: 'Data registrazione',
    value: 'registrationDate',
    type: 'date',
    filterable: true,
    sortable: true,
    cellTemplate: (val, item) => {
      return formatDate(new Date(item.registrationDate));
    },
  },
  // {
  //   heading: 'Stato',
  //   value: 'invoiceStatus',
  //   type: 'select',
  //   filterable: true,
  //   sortable: true,
  // },
  {
    heading: 'Stato',
    value: 'invoiceOrderStatusId',
    type: 'select',
    filterable: true,
    sortable: true,
    cellTemplate: (statusId, invoiceOrder) => (
      <Badge color={STATUS_COLORS[invoiceOrder.invoiceOrderStatusId]}>{invoiceOrder.invoiceOrderStatusName}</Badge>
    ),
  },
];

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];

const createInvoiceOrderStatusOptions = (invoiceOrderStatuses) => {
  return invoiceOrderStatuses.map((invoiceOrderStatus) => ({
    id: invoiceOrderStatus.id,
    description: invoiceOrderStatus.name,
  }));
};

const useRetrieveData = (commissionId) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [invoiceOrderStatusOptions, setInvoiceOrderStatusOptions] = useState([]);

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const retrieveDataWithFilters = useCallback(
    (filters, currentPage, numRecords) => {
      setLoading(true);
      fetchPurchaseInvoices(commissionId, filters, currentPage, numRecords)
        .then((PurchaseInvoicesRaw) => {
          const parsedInvoicesResponse = parseCustomResponse(PurchaseInvoicesRaw);
          if (parsedInvoicesResponse.success) {
            setResponse({
              purchaseInvoicesResponse: parsedInvoicesResponse.data.invoices,
              totRecords: parsedInvoicesResponse.data.totRecords,
            });
          } else {
            setErrorMessage(parsedInvoicesResponse.message);
          }
        })
        .catch((err) => {
          console.error(err);
          // alert(err);
          error('errore durante il recupero dei dati', 'Errore');
        })
        .finally(() => setLoading(false));
    },
    [commissionId, error, parseCustomResponse]
  );

  const fetchData = useCallback(async () => {
    const [PurchaseInvoicesRaw, invoiceOrderStatusesResponse] = await Promise.all([
      fetchPurchaseInvoices(commissionId, null, 0, INITIAL_PAGE_SIZE),
      fetchPurchaseInvoiceOrderStatuses({ isVisibleInCommissionManagement: true }),
    ]);

    return {
      PurchaseInvoicesRaw,
      invoiceOrderStatusesResponse,
    };
  }, [commissionId]);

  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(({ PurchaseInvoicesRaw, invoiceOrderStatusesResponse }) => {
        const parsedInvoicesResponse = parseCustomResponse(PurchaseInvoicesRaw);
        if (parsedInvoicesResponse.success) {
          setResponse({
            purchaseInvoicesResponse: parsedInvoicesResponse.data.invoices,
            totRecords: parsedInvoicesResponse.data.totRecords,
          });
        } else {
          setErrorMessage('Si è verificato un errore');
        }

        if (invoiceOrderStatusesResponse.ok) {
          const invoiceOrderStatusOptions = createInvoiceOrderStatusOptions(invoiceOrderStatusesResponse.data.statuses);
          setInvoiceOrderStatusOptions(invoiceOrderStatusOptions);
        }
      })
      .catch((err) => {
        console.error(err);
        error('errore durante il recupero dei dati', 'Errore');
      })
      .finally(() => setLoading(false));
  }, [fetchData, parseCustomResponse, error]);

  return { loading, response, invoiceOrderStatusOptions, errorMessage, retrieveDataWithFilters };
};

const PurchaseInvoiceIndexPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [numRecords, setNumRecords] = useState(INITIAL_PAGE_SIZE);
  const [searchTerms, setSearchTerms] = useState({});

  const { commissionId } = useParams();
  const { loading, response, invoiceOrderStatusOptions, retrieveDataWithFilters } = useRetrieveData(commissionId);
  const { hasPermission } = useCommissionPermissions();

  const tableColumnsDefinition = useMemo(() => {
    return DATA_TABLE_COLUMNS_DEFINITION.map((columnDefinition) =>
      columnDefinition.value !== 'invoiceOrderStatusId'
        ? columnDefinition
        : { ...columnDefinition, options: invoiceOrderStatusOptions }
    );
  }, [invoiceOrderStatusOptions]);

  const onHandleSearch = (value, field, type, newPage) => {
    const updatedSearchTerms = { ...searchTerms };
    if (value === '') {
      delete updatedSearchTerms[field];
    } else {
      updatedSearchTerms[field] = type === 'select' ? { id: value } : value;
    }
    console.debug(updatedSearchTerms);
    setSearchTerms(updatedSearchTerms);
    retrieveDataWithFilters(updatedSearchTerms, newPage, numRecords);
  };

  const onHandlePageChange = (newPage) => {
    setCurrentPage(newPage);
    retrieveDataWithFilters(searchTerms, newPage, numRecords);
  };

  const onHandleRecordsChange = (num, newPage) => {
    setCurrentPage(newPage);
    setNumRecords(num);
    retrieveDataWithFilters(searchTerms, newPage, num);
  };

  return (
    <>
      {loading && <Loader />}
      <>
        {/* <pre>{JSON.stringify(response, undefined, '\t')}</pre> */}
        {response && (
          <DataTable
            responsive={true}
            data={response.purchaseInvoicesResponse}
            column={tableColumnsDefinition}
            onHandlePageChange={onHandlePageChange}
            onHandleRecordsChange={onHandleRecordsChange}
            onHandleSearch={onHandleSearch}
            // {...(true && { onHandlePageChange })}
            // {...(true && { onHandleRecordsChange })}
            // {...(true && { onHandleSearch })}
            totRecords={response.totRecords}
            currentPage={currentPage}
            numRecords={numRecords}
            paginationValues={PAGES_SIZE}
            context={{
              commissionId,
              permission: hasPermission(PURCHASE_ORDER_PERMISSIONS.INVOICES.VIEW),
            }}
          />
        )}
      </>
    </>
  );
};

export default PurchaseInvoiceIndexPage;
