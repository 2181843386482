import { Dialog } from 'primereact/dialog';
import RequestChangeForm from './RequestChangeForm';

const DEFAULT_FN_ONSUBMIT = (purchaseOrderId, formData) => {};
const DEFAULT_FN_ONHIDE = () => {};

const RequestChangeModal = ({
  purchaseOrderId,
  visible,
  onSubmit = DEFAULT_FN_ONSUBMIT,
  onHide = DEFAULT_FN_ONHIDE,
}) => (
  <Dialog header={`Richiesta modifiche ${purchaseOrderId}`} visible={visible} style={{ width: '60vw' }} onHide={onHide}>
    <RequestChangeForm purchaseOrderId={purchaseOrderId} onSubmit={onSubmit} />
  </Dialog>
);
export default RequestChangeModal;
