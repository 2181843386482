import { useEffect, useState } from 'react';
import { fetchCustomerWithId } from 'services/api/customers';
import { Button, Card, CardBody, Col, Form, Row, FormGroup, Label, Input, CardHeader } from 'reactstrap';
import GUARANTEE_WITHHOLDING_TYPES from 'constants/guarantee-withholding-types';
import useCommission from 'hooks/useCommission';
import useFeedback from 'hooks/useFeedback';
import CustomInput from 'components/shared/forms/CustomInput';
import { InvoicingDetailsCard } from './InvoicingDetailsCard';
import { CommissionSummaryCard } from './CommissionSummaryCard';
import { CustomerSummaryCard } from './CustomerSummaryCard';

const DEF_CURRENCY_ID = 'EUR';

const guaranteeWithholdingHasPercentage = (guaranteeWithholdingId) => {
  return [GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE, GUARANTEE_WITHHOLDING_TYPES.INVOICEABLE].includes(
    guaranteeWithholdingId
  );
};

const fetchWithData = async (api) => {
  try {
    const response = await fetch(`${api}`);
    return {
      ok: response.ok,
      status: response.status,
      data: await response.json(),
    };
  } catch (err) {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const VoidButton = ({ onClick }) => (
  <Button type="button" onClick={onClick} color="danger" className="mr-1">
    <i className="far fa-stop-circle"></i> Annulla
  </Button>
);

const SubmitButton = () => (
  <Button type="submit" color="primary" className="mr-1">
    <i className="fa fa-save"></i> Salva
  </Button>
);

export const CommissionSummaryRow = ({ className, label, value }) => (
  <Row className={className}>
    <Col xs={4} style={{ lineHeight: 1 }}>
      {label}
    </Col>
    <Col xs={8} style={{ lineHeight: 1 }}>
      {value}
    </Col>
  </Row>
);

const SalesOrderForm = ({
  commission,
  salesOrderData: initialFormData,
  paymentTermsData,
  currencies,
  onSubmit,
  onVoid,
  editable = false,
  canVoid = false,
  onFormDirty,
  pristineInvalidationKey,
}) => {
  const [pristine, setPristine] = useState(true);
  const [customer, setCustomer] = useState({
    ...initialFormData.customer,
    customerId: initialFormData.customerId,
    displayName: initialFormData.customer?.name,
    paymentTerm: initialFormData.paymentTerm,
  });
  const [formData, setFormData] = useState(initialFormData);
  const [currentPaymentTerm, setCurrentPaymentTerm] = useState(formData?.paymentTerm);
  const [commissionCustomer, setCommissionCustomer] = useState();
  const commissionPaymentTerm = paymentTermsData?.find((x) => x.id === commission.paymentTermId);

  // radio button di modifica temporanea/strutturale condizioni di pagamento sono visibili...
  const paymentTermModificationVisible =
    currentPaymentTerm &&
    (customer?.customerId === commissionCustomer?.customerId // SE cliente selezionato = cliente commessa...
      ? currentPaymentTerm?.id !== commission?.paymentTermId // ... ALLORA condizioni di pagamento != condizioni di pagamento commessa
      : currentPaymentTerm?.id !== customer?.paymentTerm?.id); // ...OPPURE condizioni di pagamento != condizioni di pagamento cliente

  const currencyExchangeRateVisible = formData.currency?.id !== DEF_CURRENCY_ID;
  const guaranteeWithholdingPercentageVisible = [
    GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE,
    GUARANTEE_WITHHOLDING_TYPES.INVOICEABLE,
  ].includes(parseInt(formData.guaranteeWithholding?.id));

  const { companyId } = useCommission();
  const { error } = useFeedback();

  /*----------- utility -----------*/
  useEffect(() => {
    setPristine(true);
  }, [pristineInvalidationKey]);

  const changeFormDataProperties = (newProperties) => {
    if (pristine) {
      setPristine(false);
      onFormDirty?.();
    }
    setFormData((formData) => ({ ...formData, ...newProperties }));
  };

  const changeFormDataProperty = (propertyName, value) => {
    changeFormDataProperties({ [propertyName]: value });
  };

  /*----------- fetch data -----------*/
  useEffect(() => {
    if (!commissionCustomer) {
      fetchCustomerWithId(commission.customerId).then((data) => {
        setCommissionCustomer({
          ...data,
          customerId: data.id,
          displayName: data.displayName,
          paymentTerm: paymentTermsData?.find((x) => x.id === commission.paymentTermId),
        });
      });
    }

    if (!formData.customerId) {
      return;
    }

    fetchCustomerWithId(formData.customerId)
      .then((data) => {
        setCustomer({ ...data, customerId: data.id, displayName: data.displayName });
      })
      .catch(() => error('Errore durante il recupero dei dati del cliente', 'Errore'));
  }, [commission, commissionCustomer, error, formData.customerId, paymentTermsData]);

  /*----------- handlers -----------*/
  const handleCustomersSearch = async (searchTerm) => {
    const filters = {
      companyId: companyId,
      search: searchTerm,
    };
    const response = await fetchWithData(`/api/customers?${new URLSearchParams(filters)}`);
    if (response.ok) {
      return response.data.map((customer) => ({
        id: customer.id,
        displayName: customer.displayName,
      }));
    } else {
      error(`${response.message}`, 'Errore');
      return [];
    }
  };

  const handleCurrencyChange = (e) => {
    if (e.target.value === DEF_CURRENCY_ID) {
      changeFormDataProperty('currency', { id: e.target.value });
    } else {
      changeFormDataProperty('currency', { ...formData.currency, id: e.target.value });
    }
  };

  const handleExchangeRateChange = (e) => {
    changeFormDataProperty('currency', {
      ...formData.currency,
      exchangeRate: { ...formData.currency.exchangeRate, rate: e.value },
    });
  };

  const handleExchangeRateDateChange = (e) => {
    changeFormDataProperty('currency', {
      ...formData.currency,
      exchangeRate: { ...formData.currency.exchangeRate, date: e.target.value },
    });
  };

  const handleSpecificCustomerRadioChange = (isSpecificCustomer) => {
    // console.debug(`isSpecificCustomer: ${isSpecificCustomer ? 'Y' : 'N'}`);
    if (!isSpecificCustomer) {
      // console.debug('same customer as commission');
      // console.debug(commissionCustomer);
      setCustomer(commissionCustomer);
      setCurrentPaymentTerm(commissionPaymentTerm);
      changeFormDataProperties({
        isSpecificCustomer: false,
        paymentTerm: commissionPaymentTerm,
        customerId: commissionCustomer.id,
        customer: { id: commissionCustomer.id, name: commissionCustomer.displayName },
      });
    } else {
      changeFormDataProperty('isSpecificCustomer', true);
    }
  };

  const handleCustomerChange = async ({ value: selectedCustomerId }) => {
    const sameCustomerAsCommission = selectedCustomerId === commission.customerId;
    if (sameCustomerAsCommission) {
      setCustomer(commissionCustomer);
      setCurrentPaymentTerm(commissionPaymentTerm);
      changeFormDataProperties({
        isSpecificCustomer: false,
        customerId: selectedCustomerId,
        customer: {
          id: selectedCustomerId,
          name: commissionCustomer.name,
        },
        paymentTerm: commissionPaymentTerm,
      });
    } else {
      fetchCustomerWithId(selectedCustomerId).then((customer) => {
        const customerPaymentTerm = customer.paymentTerm
          ? { id: customer.paymentTerm.id, name: customer.paymentTerm.name }
          : undefined;
        setCustomer({
          ...customer,
          id: customer.id,
          displayName: customer.name,
          paymentTerm: customerPaymentTerm,
        });
        setCurrentPaymentTerm(customerPaymentTerm);
        changeFormDataProperties({
          isSpecificCustomer: true,
          customerId: selectedCustomerId,
          customer: {
            id: selectedCustomerId,
            name: customer.displayName,
          },
          paymentTerm: customerPaymentTerm,
        });
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitData = {
      customerId: formData.customerId,
      shortDescription: formData.shortDescription,
      customerOrderNoReference: formData.customerOrderNoReference,
      date: formData.date,
      paymentTerm: {
        id: formData.paymentTerm.id,
        paymentTermModification: formData.paymentTerm.paymentTermModification
          ? { id: formData.paymentTerm.paymentTermModification.id }
          : undefined,
      },
      currency: {
        id: formData.currency.id,
        exchangeRate: formData.currency.exchangeRate
          ? { rate: formData.currency.exchangeRate.rate, date: formData.currency.exchangeRate.date }
          : undefined,
      },
      guaranteeWithholding: {
        id: formData.guaranteeWithholding.id,
        percentage: formData.guaranteeWithholding.percentage,
      },
    };
    onSubmit(submitData);
  };

  const handleFormValueChange = (e) => {
    changeFormDataProperty(e.target.name, e.target.value);
  };

  const handleGuaranteeWithholdingTypeChange = (e) => {
    const guaranteeWithholdingId = parseInt(e.target.value);
    const guaranteeWithholdingPercentage = guaranteeWithholdingHasPercentage(guaranteeWithholdingId)
      ? formData.guaranteeWithholding?.percentage ?? null
      : null;
    changeFormDataProperty('guaranteeWithholding', {
      id: isNaN(guaranteeWithholdingId) ? '' : guaranteeWithholdingId,
      percentage: guaranteeWithholdingPercentage,
    });
  };

  const handleGuaranteeWithholdingPercentageChange = (e) => {
    changeFormDataProperty('guaranteeWithholding', {
      ...formData.guaranteeWithholding,
      percentage: Math.min(e.value, 100),
    });
  };

  const handlePaymentTermChange = (e) => {
    const paymentTerm = paymentTermsData.find((elem) => elem.id === parseInt(e.target.value));
    setCurrentPaymentTerm(paymentTerm);
    const paymentTermModification =
      paymentTerm &&
      (customer?.customerId === commissionCustomer?.customerId // SE cliente selezionato = cliente commessa...
        ? paymentTerm?.id !== commission?.paymentTermId // ... ALLORA condizioni di pagamento != condizioni di pagamento commessa
        : paymentTerm?.id !== customer?.paymentTerm?.id); // ...OPPURE condizioni di pagamento != condizioni di pagamento cliente

    changeFormDataProperty('paymentTerm', {
      id: '',
      name: '',
      ...paymentTerm,
      paymentTermModification: paymentTermModification ? { id: 1 } : null,
    });
  };

  const handlePaymentTermModificationChange = (e) => {
    changeFormDataProperty('paymentTerm', {
      ...formData.paymentTerm,
      paymentTermModification: { id: parseInt(e.target.value) },
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* <pre style={{ fontSize: '.55rem', lineHeight: 1.1 }}>{JSON.stringify(formData, undefined, '\t')}</pre> */}
      {/* main row */}
      <Row>
        {/* left main col */}
        <Col>
          <Card className="mb-2">
            <CardHeader>Ordine</CardHeader>
            <CardBody>
              {/* descrizione, rif nr ordine e data */}
              <CustomInput
                label="Descrizione breve"
                type="text"
                name="shortDescription"
                groupClassName="mb-2"
                defaultValue={formData.shortDescription}
                onChange={handleFormValueChange}
                disabled={!editable}
                required
              />
              <CustomInput
                label="Rifz. nr ordine cliente"
                type="text"
                name="customerOrderNoReference"
                groupClassName="mb-2"
                defaultValue={formData.customerOrderNoReference}
                onChange={handleFormValueChange}
                disabled={!editable}
                required
              />
              <CustomInput
                label="Data"
                type="date"
                name="date"
                min="2000-01-01"
                max="9999-12-31"
                groupClassName="mb-2"
                defaultValue={formData.date}
                onChange={handleFormValueChange}
                disabled={!editable}
                required
              />
              {/* end */}
              {/* valuta */}
              <Row>
                <Col>
                  <CustomInput
                    label="Valuta"
                    type="select"
                    name="currency.id"
                    groupClassName="mb-2"
                    defaultValue={formData.currency?.id}
                    disabled={!editable}
                    onChange={handleCurrencyChange}
                    required
                  >
                    {currencies?.map((currency) => (
                      <option key={`currency-${currency?.currencyId}`} value={currency?.currencyId}>
                        {currency.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
                {/* campi valuta */}
                {currencyExchangeRateVisible && (
                  <Col>
                    <CustomInput
                      label="Cambio 1€"
                      type="formattednumber"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      name="currency.exchangeRate.rate"
                      groupClassName="mb-2"
                      value={formData.currency.exchangeRate?.rate}
                      disabled={!editable}
                      onChange={handleExchangeRateChange}
                      required
                    />
                  </Col>
                )}
              </Row>
              {currencyExchangeRateVisible && (
                <CustomInput
                  label="Data valuta"
                  type="date"
                  min="2000-01-01"
                  max="9999-12-31"
                  name="currency.exchangeRate.date"
                  groupClassName="mb-2"
                  defaultValue={formData.currency.exchangeRate?.date}
                  disabled={!editable}
                  onChange={handleExchangeRateDateChange}
                  required
                />
              )}
              {/* end valuta */}
              {/* ritenuta di garanzia */}
              <Row>
                <Col>
                  <CustomInput
                    type="select"
                    label="Ritenuta di garanzia"
                    name="guaranteeWithholding.id"
                    groupClassName="mb-2"
                    disabled={!editable}
                    onChange={handleGuaranteeWithholdingTypeChange}
                    defaultValue={formData.guaranteeWithholding?.id}
                    required
                  >
                    <option value="">--Seleziona</option>
                    <option value={GUARANTEE_WITHHOLDING_TYPES.NOT_APPLICABLE}>Non applicabile</option>
                    <option value={GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE}>Non fatturabile</option>
                    <option value={GUARANTEE_WITHHOLDING_TYPES.INVOICEABLE}>Fatturabile</option>
                  </CustomInput>
                </Col>
                {/* percentuale */}
                {guaranteeWithholdingPercentageVisible && (
                  <Col>
                    <CustomInput
                      type="formattednumber"
                      label="%"
                      min={0}
                      max={100}
                      locale="en-US"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      name="guaranteeWithholding.percentage"
                      groupClassName="mb-2"
                      value={formData.guaranteeWithholding.percentage}
                      onChange={handleGuaranteeWithholdingPercentageChange}
                      disabled={!editable}
                      required
                    />
                  </Col>
                )}
              </Row>
              {/* condizioni di pagamento */}
              <Row>
                <Col>
                  <CustomInput
                    type="select"
                    value={formData.paymentTerm?.id ?? ''}
                    label="Condizioni di pagamento"
                    name="paymentTerm.id"
                    groupClassName="mb-1"
                    disabled={!editable}
                    onChange={handlePaymentTermChange}
                    required
                  >
                    <option value="">--Seleziona</option>
                    {paymentTermsData?.map((paymentTerm) => (
                      <option key={`paymentTerm-${paymentTerm.id}`} value={paymentTerm.id}>
                        {paymentTerm.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
                {/* modifica condizioni di pagamento */}
                {paymentTermModificationVisible && (
                  <Col className="align-content-end">
                    <FormGroup tag="fieldset" className="mb-0">
                      <FormGroup check>
                        <Input
                          type="radio"
                          name="paymentTerm.paymentTermModification.id"
                          checked={formData.paymentTerm?.paymentTermModification?.id !== 2}
                          value={1}
                          onChange={handlePaymentTermModificationChange}
                          disabled={!editable}
                        />
                        <Label check>Modifica Temporanea</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          type="radio"
                          name="paymentTerm.paymentTermModification.id"
                          checked={formData.paymentTerm?.paymentTermModification?.id === 2}
                          value={2}
                          onChange={handlePaymentTermModificationChange}
                          disabled={!editable}
                        />
                        <Label check>Modifica Strutturale</Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
          {/* end left main col */}
        </Col>
        {/* right main col */}
        <Col>
          <CommissionSummaryCard
            className="mb-2"
            commissionId={commission.commissionId}
            cig={commission.cig}
            cup={commission.cup}
            description={commission.description}
            customerName={commission.customerBusinessName}
          />
          <InvoicingDetailsCard
            className="mb-2"
            customer={{ id: formData.customer.id, displayName: formData.customer.name }}
            sameCustomerAsCommission={!formData.isSpecificCustomer}
            editable={editable}
            onCustomerChange={handleCustomerChange}
            onCustomerSearchChange={handleCustomersSearch}
            onSameCustomerAsCommissionChange={handleSpecificCustomerRadioChange}
          />
        </Col>
      </Row>
      {formData.isSpecificCustomer && customer?.id !== commission.customerId && (
        <CustomerSummaryCard
          className="mb-2"
          name={customer.name}
          address={customer.address}
          postalCode={customer.postalCode}
          municipality={customer.municipality}
          paymentTermName={customer.paymentTerm?.name}
          taxCode={customer.taxCode}
          vatNumber={customer.vatNumber}
        />
      )}
      <div>
        {editable && <SubmitButton />}
        {canVoid && <VoidButton onClick={onVoid} />}
      </div>
    </Form>
  );
};

export default SalesOrderForm;
