import ReadOnlyDecimalNumber from 'components/utils/ReadOnlyDecimalNumber';
import { Fragment } from 'react';

import styles from 'components/commission-management/budget/BudgetTableStyles';
import { calculateActivitiesTotal, stringifyPeriod } from 'helpers/budget';

const BudgetTableFooter = ({ activities, periods, collapsed }) => {
  const expectedTotalCost = calculateActivitiesTotal(activities, (s) => s.cost) || 0;
  const expectedTotalRevenue = calculateActivitiesTotal(activities, (s) => s.revenue) || 0;
  let residualTotalCost = expectedTotalCost;
  let residualTotalRevenue = expectedTotalRevenue;

  const costRevenuePeriods = periods.map((period) => {
    let totalBudgetCost = 0;
    let totalBudgetRevenue = 0;
    for (const activity of activities) {
      for (const subactivity of activity.subactivities) {
        for (const costRevenuePeriod of subactivity.costRevenueProspectus) {
          if (costRevenuePeriod.period.year === period.year && costRevenuePeriod.period.month === period.month) {
            if (!isNaN(costRevenuePeriod.cost)) {
              totalBudgetCost = totalBudgetCost + (costRevenuePeriod.cost || 0);
            }
            if (!isNaN(costRevenuePeriod.revenue)) {
              totalBudgetRevenue = totalBudgetRevenue + (costRevenuePeriod.revenue || 0);
            }
            break;
          }
        }
      }
    }
    residualTotalCost -= totalBudgetCost || 0;
    residualTotalRevenue -= totalBudgetRevenue || 0;
    return { cost: totalBudgetCost, revenue: totalBudgetRevenue };
  });

  return (
    <tfoot>
      <tr>
        <th className={styles['th-default']}></th>
        <th colSpan={2} className={styles['th-default']}></th>
        <th className={styles['th-default']}></th>
        {/* <th className={styles['th-default']}></th> */}
        {/* <th className={styles['th-default']}>TOTALE</th> */}
        <th className={styles['th-cost'] + ' ' + styles['td--numeric']}>
          <ReadOnlyDecimalNumber n={expectedTotalCost} />
        </th>
        <th className={styles['th-revenue'] + ' ' + styles['td--numeric']}>
          <ReadOnlyDecimalNumber n={expectedTotalRevenue} />
        </th>
        <th className={styles['th-residue'] + ' ' + styles['td--numeric']}>
          <ReadOnlyDecimalNumber n={residualTotalCost} />
        </th>
        <th className={styles['th-residue'] + ' ' + styles['td--numeric']}>
          <ReadOnlyDecimalNumber n={residualTotalRevenue} />
        </th>
        {!collapsed &&
          periods.map((period, i) => (
            <Fragment key={stringifyPeriod(period)}>
              <th className={styles['th-cost'] + ' ' + styles['td--numeric']}>
                <ReadOnlyDecimalNumber n={costRevenuePeriods[i].cost} />
              </th>
              <th className={styles['th-revenue'] + ' ' + styles['td--numeric']}>
                <ReadOnlyDecimalNumber n={costRevenuePeriods[i].revenue} />
              </th>
            </Fragment>
          ))}
      </tr>
    </tfoot>
  );
};

export default BudgetTableFooter;
