import { fetchJson2 } from 'helpers/api';

const API_BASEPATH = '/api/supplier-ddts';

const fetchSupplierDdtList = (params) => {
  const searchParams = new URLSearchParams();
  for (const key in params.filters) {
    searchParams.append(`${key}Filter`, params.filters[key]);
  }
  if (params.sort) {
    searchParams.append('sortBy', params.sort.column);
    searchParams.append('sortDirection', params.sort.direction);
  }
  searchParams.append('take', params.take);
  searchParams.append('skip', params.skip);

  return fetchJson2(`${API_BASEPATH}?${searchParams}`);
};

const fetchPurchaseOrders = (companyId) => {
  return fetchJson2(`${API_BASEPATH}/purchaseOrders/${companyId}`);
};

const createSupplierDdt = async (formData) => {
  let handled = false;
  try {
    const response = await fetch(API_BASEPATH, {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      const responseBody = await response.json();
      handled = true;
      throw new Error(responseBody.detail || responseBody.title);
    }
  } catch (err) {
    if (handled) {
      throw err;
    }
    throw new Error('Si è verificato un errore');
  }
};

const updateSupplierDdt = async (attachmentId, formData) => {
  let handled = false;
  try {
    const response = await fetch(`${API_BASEPATH}/${attachmentId}`, {
      method: 'PUT',
      body: formData,
    });
    if (!response.ok) {
      const responseBody = await response.json();
      handled = true;
      throw new Error(responseBody.detail || responseBody.title);
    }
  } catch (err) {
    if (handled) {
      throw err;
    }
    throw new Error('Si è verificato un errore');
  }
};

const associateSupplierDdt = async (attachmentId, purchaseOrderId) => {
  let handled = false;
  try {
    const response = await fetch(`${API_BASEPATH}/${attachmentId}/associate/${purchaseOrderId}`, {
      method: 'PATCH',
    });
    if (!response.ok) {
      const responseBody = await response.json();
      handled = true;
      throw new Error(responseBody.detail || responseBody.title);
    }
  } catch (err) {
    if (handled) {
      throw err;
    }
    throw new Error('Si è verificato un errore');
  }
};

export { fetchSupplierDdtList, fetchPurchaseOrders, createSupplierDdt, updateSupplierDdt, associateSupplierDdt };
