import Loader from 'components/layout/Loader';
import { displayDateTimeHourMinute } from 'helpers/date';
import useAuth from 'hooks/useAuth';
import useConfirmSwitchCompany from 'hooks/useConfirmSwitchCompany';
import useFeedback from 'hooks/useFeedback';
import { useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import {
  fetchUserNotificationsCount,
  fetchUserNotifications,
  markUserNotificationAsRead,
} from 'services/api/dashboard';

const TAKE_MAX_NOTIFICATIONS = 10;

const UserNotificationList = ({ children }) => <div className="list-group mb-2">{children}</div>;

UserNotificationList.Title = ({ text, notificationsCount = 0 }) => (
  <h6 className="text-dark">
    <i className={`fas fa-envelope mr-1 align-baseline`} />
    <span className="align-baseline">{text}</span>
    {notificationsCount > 0 && <span className="ml-1 badge badge-danger align-bottom">{notificationsCount}</span>}
  </h6>
);

UserNotificationList.LoadMore = ({ onClick }) => (
  <div className="text-center">
    <Button type="button" size="sm" color="secondary" onClick={onClick}>
      Carica notifiche precedenti
    </Button>
  </div>
);

const MarkAsReadButton = ({ submitting = false, onClick }) => (
  <Button color="success" outline size="sm" title="Segna come letta" onClick={onClick} disabled={submitting}>
    {submitting ? (
      <Spinner size="sm" color="success" style={{ verticalAlign: 'middle' }} />
    ) : (
      <i className="fas fa-check" style={{ verticalAlign: 'middle' }} />
    )}
  </Button>
);

const NOTIFICATION_ICON_SIZE = '2rem';

const NotificationIcon = ({ name, color, alternative: readNotification = false }) => {
  const containerClassName = `icon-circle ${readNotification ? 'border border-' : 'bg-'}${color}`;
  const containerStyle = {
    width: NOTIFICATION_ICON_SIZE,
    height: NOTIFICATION_ICON_SIZE,
    opacity: readNotification ? 0.4 : 1,
  };
  const iconClassName = `${name} ${readNotification ? `text-${color}` : 'text-white'}`;
  return (
    <div className={containerClassName} style={containerStyle}>
      <i className={iconClassName}></i>
    </div>
  );
};

const ViewButton = ({ onClick }) => (
  <Button color="primary" outline size="sm" title="Visualizza" onClick={onClick}>
    <i className="fa fa-external-link-alt"></i>
  </Button>
);

UserNotificationList.Item = ({
  id,
  date,
  icon,
  iconColor,
  categoryName,
  description,
  actorFullName,
  actionLink,
  submitting = false,
  read = false,
  companyId,
  onMarkAsRead = (notificationId) => {},
  onLinkClick = (e) => {},
}) => (
  <div
    className={`list-group-item d-flex align-items-center px-2 py-2${read ? ' bg-light' : ''}`}
    style={{ fontSize: '.8rem', lineHeight: '.8rem' }}
  >
    {/* {<div className="icon-circle bg-danger mr-2" style={{ width: '5px', height: '5px', opacity: read ? 0 : 1 }}></div>} */}
    <div className="mr-2">
      <NotificationIcon name={icon} color={iconColor} alternative={read} />
    </div>
    <div className="mr-2 flex-grow-1">
      <div className="text-uppercase mb-1" style={{ lineHeight: '1rem' }}>
        <b>{companyId}</b> - {displayDateTimeHourMinute(date)}
      </div>
      <div className="mb-0" style={{ lineHeight: '1rem' }} dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
    <div className="mr-1">
      {read ? (
        <div className="px-2" style={{ fontSize: '.875rem', marginRight: '1px' }} title="Notifica letta">
          <i className="fa fa-check text-success"></i>
        </div>
      ) : (
        <MarkAsReadButton submitting={submitting} onClick={() => onMarkAsRead(id)} />
      )}
    </div>
    <div>
      <ViewButton onClick={(e) => onLinkClick({ originalEvent: e, notificationId: id, link: actionLink, companyId })} />
    </div>
  </div>
);

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [readMore, setReadMore] = useState(true);

  const { error } = useFeedback();
  const { openDialog } = useConfirmSwitchCompany();

  const { company } = useAuth();
  const companyId = company.id;

  const doFetchUserNotifications = useCallback(async (params, cancellationToken) => {
    return fetchUserNotifications(params, cancellationToken).then((notifications) => {
      if (notifications.length > 0) {
        setNotifications((prevNotifications) =>
          prevNotifications.concat(notifications.map((n) => ({ ...n, submitting: false })))
        );
      }
      if (notifications.length < TAKE_MAX_NOTIFICATIONS) {
        setReadMore(false);
      }
    });
  }, []);

  useEffect(() => {
    const cancellationToken = new AbortController();
    setLoading(true);

    doFetchUserNotifications({ take: TAKE_MAX_NOTIFICATIONS }, cancellationToken.signal)
      .catch(() => {
        if (!cancellationToken.signal.aborted) {
          error('Si è verificato un errore nella lettura delle notifiche.');
        }
      })
      .finally(() => {
        if (!cancellationToken.signal.aborted) {
          setLoading(false);
        }
      });

    return () => {
      cancellationToken.abort();
      setNotifications([]);
      setReadMore(true);
      setLoading(false);
    };
  }, [doFetchUserNotifications, error]);

  useEffect(() => {
    const cancellationToken = new AbortController();
    fetchUserNotificationsCount({ read: false }, cancellationToken.signal)
      .then((notificationsCountResponse) => {
        setNotificationsCount(notificationsCountResponse.count);
      })
      .catch(() => {});
    return () => {
      cancellationToken.abort();
      setNotificationsCount(0);
    };
  }, []);

  const handleOnLoadMoreNotifications = () => {
    setLoading(true);

    // mount request params
    const params = { take: TAKE_MAX_NOTIFICATIONS };
    if (notifications.length > 0) {
      const lastNotificationId = notifications[notifications.length - 1].id;
      params.skipFrom = lastNotificationId;
    }

    doFetchUserNotifications(params)
      .catch(() => {
        error('Si è verificato un errore nel caricamento delle notifiche.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnNotificationLinkClick = ({ originalEvent, link, companyId: notificationCompanyId }) => {
    originalEvent.preventDefault();
    // console.debug(`notification link clicked: ${notificationId} - ${notificationCompanyId} - ${link}`);
    if (notificationCompanyId !== companyId) {
      openDialog(notificationCompanyId, () => {
        window.location.href = link;
      });
    } else {
      window.location.href = link;
    }
  };

  const handleOnMarkNotificationAsRead = (userNotificationId) => {
    setNotifications((notifications) =>
      notifications.map((n) => (n.id !== userNotificationId ? n : { ...n, submitting: true }))
    );
    markUserNotificationAsRead(userNotificationId)
      .then(() => {
        setNotifications((notifications) =>
          notifications.map((n) => (n.id !== userNotificationId ? n : { ...n, submitting: false, read: true }))
        );
        setNotificationsCount((count) => count - 1);
      })
      .catch((err) => {
        error(err.message);
        setNotifications((notifications) =>
          notifications.map((n) => (n.id !== userNotificationId ? n : { ...n, submitting: false }))
        );
      });
  };

  return (
    <>
      {/* <pre>{JSON.stringify({ loading })}</pre> */}
      {loading && <Loader />}
      <h1>Dashboard</h1>
      <section className="mb-2">
        <UserNotificationList.Title text="Le tue notifiche" notificationsCount={notificationsCount} />
        {notifications.length > 0 ? (
          <>
            <UserNotificationList>
              {notifications.map((n) => (
                <UserNotificationList.Item
                  key={n.id}
                  id={n.id}
                  date={n.date}
                  actorFullName={n.actorFullName}
                  categoryName={n.categoryName}
                  description={n.description}
                  icon={n.icon}
                  iconColor={n.iconColor}
                  actionLink={n.actionLink}
                  submitting={n.submitting}
                  read={n.read}
                  onMarkAsRead={handleOnMarkNotificationAsRead}
                  onLinkClick={handleOnNotificationLinkClick}
                  companyId={n.companyId}
                />
              ))}
            </UserNotificationList>
            {readMore && <UserNotificationList.LoadMore onClick={handleOnLoadMoreNotifications} />}
          </>
        ) : (
          <div className="alert alert-secondary border border-secondary px-2 py-2">
            <i className="fas fa-bell-slash" /> Nessuna notifica ricevuta.
          </div>
        )}
      </section>
    </>
  );
};

export default Dashboard;
