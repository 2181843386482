import CustomGroupBox from "components/shared/customGroupBox";
import { Badge, Button, Col, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem, Row } from "reactstrap";
import RowAttivita from "./rowAttivita";
import PurchaseOrderChecks from "./purchaseOrderChecks";
import CustomDialog from "components/shared/customDialog";
import { formatDate, formatStringInFloat } from "helpers/formatters";
import useFeedback from "hooks/useFeedback";
import { useState } from "react";
import { COLOR_INVALID } from "./styles";
import { confrontaImportoFattura, getTotaleParziale, importoValido } from "./commonFunctions";

const PurchaseOrder = (props) => {
    const { error } = useFeedback();
    const [showDialog, setShowDialog] = useState({ note: false, ripresa: false });

    const dialogNoteOpen = (purchaseOrder) => {
        setShowDialog({ ...showDialog, note: true, ripresa: false });
    };

    const riprendiFattura = () => {
        setShowDialog({ ...showDialog, note: false, ripresa: true });
    };
    
    const attivitaColor = (attivita, purchaseOrder) => {
        let cod = props.calculateTableCodiciContabili();
        let contoContabileId = purchaseOrder.elencoConti.find((v) => {
            return parseInt(v.id) === attivita.selectedConto;
        }).id;
        let selAttivita = purchaseOrder.elencoAttivita.find((a) => {
            return parseInt(a.id) === attivita.selectedAttivita;
        });

        let anomalie = []
        purchaseOrder.attivitaAssociate.map((a) => {
            if(cod.ccVett[a.selectedConto.toString()] !== undefined && cod.ccVett[a.selectedConto.toString()].ImportoAssociato !== undefined) {
              anomalie.push(a.isDeleted === false && confrontaImportoFattura(cod.ccVett[a.selectedConto.toString()].ImportoFattura, cod.ccVett[a.selectedConto.toString()].ImportoAssociato));
            }
            return null;
        });

        if (
            purchaseOrder.statusId === 0 &&
            ((selAttivita.remainder < getTotaleParziale(purchaseOrder, parseInt(selAttivita.id)) && !purchaseOrder.isFictitiousOrder) ||
            attivita.selectedAttivita < 0 ||
            attivita.selectedConto < 0 ||
            !props.isPurchaseOrderValid().totaleparzialeColor ||
            (attivita.importo ? formatStringInFloat(attivita.importo) : 0) === 0 ||
              !(!anomalie.some((a) => a === true) && (purchaseOrder.attivitaAssociate.length > 0)) || purchaseOrder.attivitaAssociate.length === 0 ||
              cod.ccVett[contoContabileId.toString()].ImportoAssociato === 0 ||
              purchaseOrder.attivitaAssociate.some((a) => {
                return cod.ccVett[a.selectedConto] != null &&
                    !importoValido(cod.ccVett[a.selectedConto].ImportoFattura, formatStringInFloat(a.importo)) &&
                    (a.isDeleted === false || a.isDeleted === undefined);
            }))
        ) {
            return COLOR_INVALID;
        }
        return '';
    };

    const dialogRiprendiFatturaClosed = (value, purchaseOrder) => {
        if (value.success) {
            if (parseInt(value.formData.get('approvatore')) === purchaseOrder.approverId && parseInt(value.formData.get('approvatore')) > -1) {
                error('Devi selezionare un approvatore diverso');
                return;
            }
            else if(parseInt(value.formData.get('approvatore')) === -1) {
              error('Devi selezionare un approvatore');
              return;
          }
            purchaseOrder.approverId = parseInt(value.formData.get('approvatore'));
            // props.UpdatePurchaseOrder(purchaseOrder);
            props.ripresaFattura(purchaseOrder);
        }
        setShowDialog({ ...showDialog, note: false, ripresa: false });
    };
    
    const dialogNoteClose = (value, purchaseOrder) => {
        if (value.success) {
            props.addPurchaseOrderNote(value.formData.get("nota"), purchaseOrder.paymentCertificateId, purchaseOrder.purchaseOrderId);
        }
        setShowDialog({ ...showDialog, note: false, ripresa: false });
    };

    return (
        <CustomGroupBox key={props.purchaseOrder.name} Title={props.purchaseOrder.name} isHeaderLink={!props.purchaseOrder.isFictitiousOrder} link={props.purchaseOrderLink}>
          <Row style={{ marginBottom: '5px' }}>
            {props.purchaseOrder.statusId > 0 && <Col style={{ background: '#facd91' }}>{props.purchaseOrder.status}</Col>}
          </Row>
          <Row>
            <Col sm="10">
              <InputGroup size="sm">
                <InputGroupText htmlFor="codCommessa">Approvatore</InputGroupText>
                <Input
                  disabled={props.purchaseOrder.statusId > 0}
                  type="select"
                  onChange={(value) => {
                    props.approvatoreChanged(props.purchaseOrder, value);
                  }}
                  value={props.purchaseOrder.approverId == null ? '' : props.purchaseOrder.approverId}>
                  {props.purchaseOrder.approvatori?.map((a, index) => {
                    return (
                      <option key={`approvatore-${a.id}-${index}`} value={a.id}>
                        {a.value}
                      </option>
                    );
                  })}
                </Input>
              </InputGroup>
            </Col>
            <Col>
              <Button color='primary' className="btn-sm" onClick={() => { dialogNoteOpen(props.purchaseOrder) }} data-toggle="tooltip" data-placement="top" title="Note">
                  <i className="fas fa-comment-alt"></i>
                  <Badge className='ml-1' color="secondary" style={{ lineHeight: '11px' }}>{props.purchaseOrder.notes?.length}</Badge>
              </Button>
              {
                /* nascondere questo bottone se lo stato non è in approvazione */
                props.purchaseOrder.statusId === 1 && (
                  <Button
                    color="light"
                    className="ml-1 btn-sm"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Ripresa fattura"
                    onClick={riprendiFattura}
                  >
                    <i className="fas fa-sync text-danger"></i>
                  </Button>
                )
              }
            </Col>
          </Row>
          {(props.purchaseOrder.attivitaAssociate?.length ?? 0) > 0 && (
            <>
              <Row>
                <Col sm="4">
                  <Label>Attività</Label>
                </Col>
                <Col sm="4">
                  <Label>Conto Contabile</Label>
                </Col>
                <Col sm="3">
                  <Label>Importo associato €</Label>
                </Col>
                <Col sm="0"></Col>
              </Row>
              {props.purchaseOrder.attivitaAssociate.map((a, index) => (
                <RowAttivita
                  key={`activity-${a.purchaseInvoiceOrderLineId}-${index}`}
                  Disabled={a.statusId > 0 && a.isSaved}
                  isDeleted={a.isDeleted}
                  OnChanged={props.attivitaChanged}
                  OnDeleted={(value) => props.attivitaDeleted(props.purchaseOrder, value)}
                  PurchaseOrder={props.purchaseOrder}
                  background={() => attivitaColor(a, props.purchaseOrder)}
                  Activity={a}
                  elencoAttivita={props.purchaseOrder.elencoAttivita}
                  elencoConti={props.purchaseOrder.elencoConti}
                />
              ))}
            </>
          )}
          <Row className="mt-1">
            <Col>
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  props.attivitaAdded({ PurchaseOrder: props.purchaseOrder.name });
                }}
                data-toggle="tooltip"
                data-placement="top"
                disabled={props.isPurchaseOrderValid().aggiungiDisabled}
                title="Aggiungi">
                <i className="fa fa-plus"></i>
              </Button>
              <Button
                size="sm"
                color="primary"
                data-toggle="tooltip"
                data-placement="top"
                className="ml-1"
                disabled={props.isPurchaseOrderValid().salvaDisabled}
                onClick={() => props.salvaBozzaClicked(props.purchaseOrder.name)}
                title="Salva">
                <i className="fa fa-save"></i> Salva
              </Button>
              <Button
                size="sm"
                color="primary"
                data-toggle="tooltip"
                data-placement="top"
                className="ml-1"
                disabled={props.isPurchaseOrderValid().inviaDisabled}
                onClick={props.inviaInApprovazione}
                // onClick={inviaInApprovazione}
                title="Salva e invia">
                <i className="fa fa-paper-plane"></i> Salva e invia
              </Button>
              <Button
                size="sm"
                color="danger"
                className="ml-1"
                disabled={props.isPurchaseOrderValid().disassociaDisabled}
                onClick={() => props.disassocia(props.purchaseOrder)}>
                <i className="fa fa-times"></i> Disassocia
              </Button>
              {props.purchaseOrder.statusId === 0 &&
                <PurchaseOrderChecks Validity={props.isPurchaseOrderValid()} /> }
            </Col>
          </Row>
          {showDialog.ripresa && (
            <CustomDialog
              Ok={true}
              TextOk={'Cambia approvatore'}
              Cancel={true}
              Title={'Seleziona un nuovo approvatore'}
              TextCancel={'Annulla'}
              OnClose={(value) => {
                dialogRiprendiFatturaClosed(value, props.purchaseOrder);
              }}
            >
              <Input disabled={props.purchaseOrder.statusId !== 1} type="select" name="approvatore">
                {props.purchaseOrder.approvatori?.map((a, index) => {
                  return (
                    <option key={`approvatore-${a.id}-${index}`} value={a.id} disabled={a.id === props.purchaseOrder.approverId}>
                      {a.value}
                    </option>
                  );
                })}
              </Input>
            </CustomDialog>
          )}
          {showDialog.note && (
            <CustomDialog
              Ok={true}
              TextOk={'Inserisci Nota'}
              Cancel={true}
              Title={'Note'}
              TextCancel={'Esci'}
              OnClose={(value) => {
                dialogNoteClose(value, props.purchaseOrder);
              }}>
              <ListGroup style={{ display: 'block', maxHeight: '400px', overflow: 'auto' }}>
                {props.purchaseOrder?.notes.map((n, index) => {
                  return (
                    <ListGroupItem className='nota-elem' key={index} action>
                      <div style={{ lineHeight: '30px', maxHeight: '200px', overflow: 'auto' }}>{n.note}</div>
                      <Badge color="light" className='message-badge'>
                        {n.username} {formatDate(n.creationDate)}
                      </Badge>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            <Input
              style={{ width: '100%', height: '100%', marginTop: '5px' }}
              id="nota"
              name="nota"
              type="textarea"
              required
            />
            </CustomDialog>
          )}
        </CustomGroupBox>
      );
};
export default PurchaseOrder;