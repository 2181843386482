import Loader from 'components/layout/Loader';
import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import {
  createSupplierDdt,
  fetchPurchaseOrders,
  updateSupplierDdt,
  associateSupplierDdt,
  fetchSupplierDdtList,
} from 'services/api/supplier-ddt-management/supplier-ddt';
import { Dialog } from 'primereact/dialog';
import DDTForm from './components/DDTForm';
import { ActionDDT } from './constants';
import { COLUMNS } from './components/DdtDataTable';
import PageHeader, { PageButton, PageButtonContainer } from 'components/layout/PageHeader';
import DataTableV2, { useDataTable } from 'modules/DataTable';
import { downloadPurchaseInvoiceAttachment } from 'services/api/purchase-invoices';

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];

const mapDataTableItems = (items) => {
  return items.map((item) => ({
    ...item,
    registrationDate: new Date(item.registrationDate),
    documentDate: item.documentDate && new Date(item.documentDate),
    invoiceDate: new Date(item.invoiceDate),
    supplierCode: item.supplier.code,
    supplierBusinessName: item.supplier.businessName,
    address: item.supplier.address,
    personalData: item.supplier.personalData,
    commissionId: item.commission.id,
    description: item.commission.description,
  }));
};

const fetchDdtDataTableItems = async (params) => {
  const { ddt, totRecords } = await fetchSupplierDdtList(params);
  return {
    items: mapDataTableItems(ddt),
    totalElements: totRecords,
  };
};

const HEADER_MODAL = ['Carica DDT', 'Modifica DDT', 'Associa DDT'];

const ManageDdtIndexPage = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [action, setAction] = useState(ActionDDT.UPLOAD);
  const [document, setDocument] = useState();

  const [items, setItems] = useState();
  const [totalItems, setTotalItems] = useState();

  const { company } = useAuth();
  const { error, success } = useFeedback();

  const { filters, sort, page, pageSize, dataTableProps } = useDataTable({
    initialFilters: { companyId: company.id },
    initialPageSize: INITIAL_PAGE_SIZE,
    initialSort: { column: 'registrationDate', direction: 'DESC' },
    allowUnsort: false,
  });

  const request = useMemo(
    () => ({
      filters,
      take: pageSize,
      skip: (page - 1) * pageSize,
      sort: {
        column: sort.column,
        direction: sort.direction,
      },
    }),
    [filters, page, pageSize, sort.column, sort.direction]
  );

  const tableColumns = useMemo(() => {
    return COLUMNS.map((column) => {
      if (column.value === 'purchaseOrderId') {
        const purchaseOrderOptions = purchaseOrders.map((purchaseOrderId) => ({
          id: purchaseOrderId,
          description: purchaseOrderId,
        }));
        return {
          ...column,
          options: column.options.concat(purchaseOrderOptions),
        };
      }
      return column;
    });
  }, [purchaseOrders]);

  useEffect(() => {
    setLoading(true);
    fetchDdtDataTableItems(request)
      .then(({ items, totalElements }) => {
        setItems(items);
        setTotalItems(totalElements);
      })
      .catch((err) => error(err.message))
      .finally(() => setLoading(false));
  }, [error, request]);

  useEffect(() => {
    fetchPurchaseOrders(company.id)
      .then((response) => setPurchaseOrders(response.purchaseOrders))
      .catch(() => {});
  }, [company.id]);

  const showModal = (action, id) => {
    //Caricare gli id degli ordini fornitori
    setIsModalVisible(true);
    setAction(action);

    if (action !== ActionDDT.UPLOAD) {
      setDocument(items.find((document) => document.attachmentId === id));
    } else {
      setDocument();
    }
  };

  const handleCreateDDTFormSubmit = (formData) => {
    setLoading(true);
    createSupplierDdt(formData)
      .then(() => {
        success('DDT caricato correttamente');
        setIsModalVisible(false);
        return fetchDdtDataTableItems(request);
      })
      .then(({ items, totalElements }) => {
        setItems(items);
        setTotalItems(totalElements);
      })
      .catch((err) => error(err.message))
      .finally(() => setLoading(false));
  };

  const handleUpdate = (formData) => {
    setLoading(true);
    updateSupplierDdt(document.attachmentId, formData)
      .then(() => {
        success('DDT aggiornato correttamente');
        setIsModalVisible(false);
        return fetchDdtDataTableItems(request);
      })
      .then(({ items, totalElements }) => {
        setItems(items);
        setTotalItems(totalElements);
      })
      .catch((err) => error(err.message))
      .finally(() => setLoading(false));
  };

  const handleAssociate = (purchaseOrderId) => {
    setLoading(true);
    associateSupplierDdt(document.attachmentId, purchaseOrderId)
      .then(() => {
        success(`DDT associato correttamente all'ordine ${purchaseOrderId}`);
        setIsModalVisible(false);
        return fetchDdtDataTableItems(request);
      })
      .then(({ items, totalElements }) => {
        setItems(items);
        setTotalItems(totalElements);
      })
      .catch((err) => error(err.message))
      .finally(() => setLoading(false));
  };

  const handleOnPurchaseInvoiceButtonClick = ({ purchaseInvoiceId }) => {
    setSubmitting(true);
    downloadPurchaseInvoiceAttachment(purchaseInvoiceId)
      .catch((err) => error(err.message))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <PageHeader title="Gestione DDT">
        <PageButtonContainer>
          <PageButton onClick={() => showModal(ActionDDT.UPLOAD)} text="Carica DDT" iconName="file-upload" primary />
        </PageButtonContainer>
      </PageHeader>
      {(loading || submitting) && <Loader />}
      <Dialog
        header={HEADER_MODAL[action]}
        visible={isModalVisible}
        style={{ width: '90%', maxWidth: '800px' }}
        onHide={() => setIsModalVisible(false)}
      >
        <DDTForm
          onAssociate={handleAssociate}
          onEdit={handleUpdate}
          onSubmit={handleCreateDDTFormSubmit}
          purchaseOrders={purchaseOrders}
          modalAction={action}
          isFileRequired={action === ActionDDT.UPLOAD}
          document={document}
        />
      </Dialog>
      <div>
        {!!items && (
          <DataTableV2
            columns={tableColumns}
            data={items}
            totalElements={totalItems}
            context={{ handleOnPurchaseInvoiceButtonClick, showModal }}
            responsive
            fixedLayout
            size="sm"
            pageSizes={PAGES_SIZE}
            {...dataTableProps}
          />
        )}
      </div>
    </>
  );
};

export default ManageDdtIndexPage;
