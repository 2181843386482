const promptWhileCanceledOrEmpty = (message) => {
  let enteredText = '';
  do {
    enteredText = window.prompt(message);
  } while (enteredText?.trim() === '');

  return {
    canceled: enteredText === null,
    enteredText: enteredText?.trim(),
  };
};

export { promptWhileCanceledOrEmpty };
