import PaymentCertificatesTable from 'components/commission-management/payment-certificates/list';
import { routeFactory } from 'routes';

const createDetailRoute = (commissionId, purchaseOrderId, paymentCertificateNumber) =>
  routeFactory.commissionManagement.purchaseOrders.paymentCertificates.detail(
    commissionId,
    purchaseOrderId,
    paymentCertificateNumber
  );

const PurchaseOrderPaymentCertificatesTable = ({
  commissionId,
  purchaseOrderId,
  paymentCertificates,
  guaranteeWithholdingTableHeaderText,
  onExportAsPdf,
  canDownload
}) => (
  <PaymentCertificatesTable
    paymentCertificates={paymentCertificates}
    guaranteeWithholdingTableHeaderText={guaranteeWithholdingTableHeaderText}
    detailRouteFactory={(paymentCertificateNumber) =>
      createDetailRoute(commissionId, purchaseOrderId, paymentCertificateNumber)
    }
    onExportAsPdf={onExportAsPdf}
    canDownload={canDownload}
  />
);

export default PurchaseOrderPaymentCertificatesTable;
