const fetchCommissionPermissions = async (commissionId) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}/permissions`);
    return {
      ok: response.ok,
      data: await response.json(),
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

export { fetchCommissionPermissions };
