const STYLES = {
  actionBar: {
    display: 'flex',
    gap: '15px'
  },
};

const BudgetActionBar = ({ children }) => {
  return <div className="budget-action-bar my-2" style={STYLES.actionBar}>{children}</div>;
};

export default BudgetActionBar;