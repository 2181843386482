import DataTable from 'components/shared/DataTable';

const DataTableV2 = ({
  page = 1,
  pageSizes = [20, 50, 100],
  pageSize = pageSizes[0],
  totalElements,
  columns,
  data,
  size,
  responsive = false,
  resizable = false,
  striped = false,
  fixedLayout = false,
  sort,
  context = {},
  onColumnFilterValueChange = (e) => {},
  onColumnSortChange = (e) => {},
  onPageChange = (e) => {},
  onPageSizeChange = (e) => {},
}) => {
  const handleColumnFilterValueChange = (filterValue, columnName, columnType, tablePageOffset) => {
    // ignore tablePageOffset for now because it does not need to be passed to the parent component (it is always 0)
    onColumnFilterValueChange({ columnName, value: filterValue, filterValue: filterValue, columnType });
  };
  const handlePageSizeChange = (pageSize, pageOffset) => {
    onPageSizeChange({ pageSize });
  };
  const handlePageOffsetChange = (pageOffset) => {
    onPageChange({ page: pageOffset + 1 });
  };
  const handleOnColumnSortChange = (columnName) => {
    onColumnSortChange({ columnName });
  };
  return (
    <DataTable
      currentPage={page - 1}
      totRecords={totalElements}
      pageSizes={pageSizes}
      pageSize={pageSize}
      column={columns}
      data={data}
      sort={sort}
      onHandleSearch={handleColumnFilterValueChange}
      onHandleRecordsChange={handlePageSizeChange}
      onHandlePageChange={handlePageOffsetChange}
      onHandleSort={handleOnColumnSortChange}
      size={size}
      resizable={resizable}
      responsive={responsive}
      fixedLayout={fixedLayout}
      context={context}
      striped={striped}
    />
  );
};

export default DataTableV2;
