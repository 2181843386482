import { displayDate } from 'helpers/date';
import { Badge, Col, Row } from 'reactstrap';

const BUDGET_STATUS_COLORS = ['dark', 'warning', 'success', 'danger'];

const BudgetHeaderInfo = ({ status, version, creationDate }) => {
  return (
    <Row className="mb-md-1 text-dark">
      <Col md={4} xl={3}>
        <b>STATO:</b>{' '}
        <Badge color={BUDGET_STATUS_COLORS[status.id]} className="px-3 py-2 text-uppercase">
          {status.description}
        </Badge>
        {/* <span className={`text-${BUDGET_STATUS_COLORS[status.id]}`}>{status.description}</span> */}
      </Col>
      <Col md={4} xl={3}>
        <b>N° VERSIONE:</b> {version}
      </Col>
      <Col md={4} xl={6}>
        <b>DATA CREAZIONE:</b> {displayDate(creationDate)}
      </Col>
    </Row>
  );
};

export default BudgetHeaderInfo;
