const formatter = new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

const format = (n) => {
    return formatter.format(n);
};

const round = (n) => {
    const rounded = Math.round(n * 100) / 100;
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#:~:text=Math.round(x)%20is,x%20%2B%200.5)%20returns%200.
    return Math.abs(rounded) === 0 ? 0 : rounded;
};

const sum = (n1, n2) => {
    return round(n1 + n2);
};

const subtract = (n1, n2) => {
    return round(n1 - n2);
};

const times = (n1, n2) => {
    return round(n1 * n2);
};

const divide = (n1, n2) => {
    return round(n1 / n2);
};

export { round, format, sum, subtract, times, divide };
