import { createContext } from 'react';

const AuthContext = createContext({
  userId: null,
  authenticated: false,
  fullName: null,
  applicationRoleId: null,
  authenticationMode: null,
  company: null,
  permissions: [],
  logout: () => {},
});

export default AuthContext;
