import SalesOrderManagementProvider from "../SalesOrderManagementProvider";
import useCommissionPermissions from "hooks/useCommissionPermissions";
import { Outlet } from "react-router";
import SalesOrderManagementSummary from "./summary";
import { SALES_ORDER_PERMISSIONS } from "constants/commission-management-permissions";
import SalesOrderManagementNavbar from "./navbar";
import useSalesOrderData from "pages/CommissionManagement/sales-order/hooks/useSalesOrderData";

const SalesOrderManagementContainerInner = () => {
  const order = useSalesOrderData();
  const { hasPermission } = useCommissionPermissions();

  return (
    <>
      {/* <pre>{JSON.stringify(order, undefined, '\t')}</pre> */}
      <SalesOrderManagementSummary
        commissionId={order.commissionId}
        salesOrderId={order.salesOrderId}
        orderDescription={order.shortDescription}
        customerName={order.customer.name}
        totalAmount={order.totalAmount}
        rejectionNote={order.rejectionNote}
        showRejectionNote={order.showRejectionNote}
      />
      <SalesOrderManagementNavbar
        commissionId={order.commissionId}
        salesOrderId={order.salesOrderId}
        tabsVisibilityOverrides={{
          header: true,
          body: true,
          attachments:
            order.attachmentManagementAvailable &&
            hasPermission(SALES_ORDER_PERMISSIONS.ATTACHMENTS.VIEW),
          paymentCertificates:
            order.paymentCertificatesManagementAvailable &&
            hasPermission(SALES_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.VIEW),
          invoices: order.invoicesManagementAvailable && hasPermission(SALES_ORDER_PERMISSIONS.INVOICES.VIEW),
        }}
      />
      <div className="py-2">
        <Outlet />
      </div>
    </>
  );
};

const SalesOrderManagementContainer = ({ children }) => {
  return (
    <SalesOrderManagementProvider>
      <SalesOrderManagementContainerInner />
    </SalesOrderManagementProvider>
  );
};

export default SalesOrderManagementContainer;
