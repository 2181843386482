import BudgetActionBar from 'components/commission-management/budget/BudgetActionBar';
import {
  CollapsePeriodsToggler,
  LatestApprovedVersionButton,
  EditDraftButton,
  LatestVersionButton,
  RequireApprovalButton,
  RevisionArchiveButton,
  RevisionButton,
} from 'components/commission-management/budget/BudgetActionButtons';
import BudgetHeaderInfo from 'components/commission-management/budget/BudgetHeaderInfo';
import BudgetHeaderTotals from 'components/commission-management/budget/BudgetHeaderTotals';
import BudgetTable from 'components/commission-management/budget/BudgetTable';
import Loader from 'components/layout/Loader';
import COMMISSION_MANAGEMENT_PERMISSIONS from 'constants/commission-management-permissions';
import { calculateActivitiesTotal, calculateMargin, mapGetDataToFormData } from 'helpers/budget';
import useAlerts from 'hooks/useAlerts';
import useCommission from 'hooks/useCommission';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { completeSubactivity, reduceBudget } from 'reducers/budget';
import ROUTES from 'routes';
import {
  completeApprovedSubactivity,
  createBudgetVersion,
  fetchBudgetVersion,
  requestApproval,
} from 'services/api/budget';

const BudgetVersionDetailsPage = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [budget, setBudget] = useState({ activities: [] });
  const [collapsed, setCollapsed] = useState(true);
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const { permissions } = useCommission();

  const canView = permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.VIEW);
  const canEdit = permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.CREATE_EDIT);
  const canReview = permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.CREATE_EDIT);
  const canRequestApproval = permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.REQUEST_APPROVAL);
  const canCompleteSubactivity = permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.BLOCK_ACTIVITY);

  const totalCost = calculateActivitiesTotal(budget.activities, (subactivity) => subactivity.cost);
  const revenue = calculateActivitiesTotal(budget.activities, (subactivity) => subactivity.revenue);
  const margin = { amount: revenue - totalCost, percentage: calculateMargin(totalCost, revenue) };

  const archiveEnabled = budget.moreRecentVersion > 1;

  const params = useParams();
  const commissionId = params.commissionId;
  const version = parseInt(params.version);

  const navigate = useNavigate();
  const { reloadAlerts } = useAlerts();
  const { success, error } = useFeedback();

  useEffect(() => {
    if (canView) {
      setInitialLoading(true);
      fetchBudgetVersion(commissionId, version, true).then((budget) => {
        setBudget(mapGetDataToFormData(budget));
        setInitialLoading(false);
      });
    } else {
      setInitialLoading(false);
    }
  }, [commissionId, version, canView]);

  const handleOnCompleteSubactivity = async (activityId, subactivityIndex) => {
    const subactivity = budget.activities.find((activity) => activity.id === activityId)?.subactivities[
      subactivityIndex
    ];

    const { approvedSubactivityId } = subactivity;

    if (approvedSubactivityId) {
      setSubmitting(true);

      const response = await completeApprovedSubactivity(commissionId, approvedSubactivityId);

      if (response.ok) {
        success(response.message);
        setBudget((budget) => reduceBudget(budget, completeSubactivity(activityId, subactivityIndex)));
      } else {
        error(response.message);
      }
      setSubmitting(false);
    } else {
      error("Si è verificato un errore. L'attività non è stata approvata?!?");
    }
  };

  const handleOnRequestApproval = async () => {
    const requestApprovalConfirmed = window.confirm(
      'La richiesta di approvazione di questa versione del budget disabiliterà la possibilità di modificarlo. Dopo essere stato approvato o rifiutato sarà possibile modificarlo nuovamente attraverso una revisione dello stesso. Vuoi procedere?'
    );

    if (requestApprovalConfirmed) {
      setSubmitting(true);

      try {
        const response = await requestApproval(commissionId, version, budget.concurrencyStamp);

        if (response.ok) {
          reloadAlerts();
          setBudget((budget) => ({ ...budget, ...response.updatedData }));
          success('La tua richiesta di approvazione è stata presa in carico.');
        } else if (response.errors) {
          setErrors(response.errors);
          error(
            'La tua richiesta di approvazione è stata respinta perché sono state riscontrate delle problematiche. Correggile per continuare.'
          );
        } else {
          error('Si è verificato un errore');
        }
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleOnRevision = async (commissionId, nextVersion) => {
    setSubmitting(true);
    const response = await createBudgetVersion(commissionId, nextVersion);
    if (response.ok) {
      const nextBudgetVersion = response.data;
      navigate(
        ROUTES.COMMISSION_MANAGEMENT.EDIT_BUDGET.replace(':commissionId', nextBudgetVersion.commissionId).replace(
          ':version',
          nextBudgetVersion.version
        )
      );
      setSubmitting(false);
    } else {
      error(response.message || 'Si è verificato un errore nel creare la revisione di questo budget');
    }
  };

  const togglePeriodsHandler = () => setCollapsed((c) => !c);

  return (
    <>
      {initialLoading ? (
        <Loader />
      ) : canView ? (
        <>
          {submitting && <Loader />}
          <BudgetActionBar>
            {budget.editable && canEdit && <EditDraftButton commissionId={commissionId} version={version} />}
            {budget.approvalRequireable && canRequestApproval && (
              <RequireApprovalButton onClick={handleOnRequestApproval} />
            )}
            {budget.revisionable && canReview && (
              <RevisionButton commissionId={commissionId} nextVersion={version + 1} onClick={handleOnRevision} />
            )}
            {archiveEnabled && <RevisionArchiveButton commissionId={commissionId} />}
            {version !== budget.moreRecentVersion && (
              <LatestVersionButton commissionId={commissionId} version={budget.moreRecentVersion} />
            )}
            {budget.currentVersion && version !== budget.currentVersion && (
              <LatestApprovedVersionButton commissionId={commissionId} version={budget.currentVersion} />
            )}
            <CollapsePeriodsToggler collapsed={collapsed} onToggle={togglePeriodsHandler} />
          </BudgetActionBar>
          <BudgetHeaderInfo version={budget.version} status={budget.status} creationDate={budget.creationDate} />
          <BudgetHeaderTotals totalCost={totalCost} revenue={revenue} margin={margin} />
          {errors.length > 0 && (
            <div className="alert alert-danger">
              <ul className="mb-0">
                {errors.map((errorText, index) => (
                  <li key={index}>{errorText}</li>
                ))}
              </ul>
            </div>
          )}
          <BudgetTable
            activities={budget.activities}
            startPeriod={budget.startPeriod}
            endPeriod={budget.endPeriod}
            collapsed={collapsed}
            readonly
            displaySubactivityCostError={
              budget.version === budget.moreRecentVersion || budget.version === budget.currentVersion
            }
            canCompleteSubactivity={canCompleteSubactivity}
            onCompleteSubactivity={handleOnCompleteSubactivity}
          />
        </>
      ) : (
        <p>Non sei autorizzato a vedere il budget per questa commessa.</p>
      )}
    </>
  );
};

export default BudgetVersionDetailsPage;
