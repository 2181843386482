import { NavLink } from 'react-router-dom';
import ROUTES from 'routes';

const PageTitle = ({ invoice }) => (
  <h2 className="mb-2">
    <NavLink to={ROUTES.SALES_INVOICES_MANAGEMENT.INDEX}>
      <i className="fas fa-chevron-left"></i>
      <i className="fas fa-ellipsis-h"></i>
    </NavLink>{' '}
    Gestione fattura cliente
    {invoice &&
      ` nr. ${invoice.number} del ${invoice.date.toLocaleDateString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })}`}
  </h2>
);

export default PageTitle;
