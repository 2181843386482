import UnsavedChangesAwareNavLink from 'components/shared/navigation/UnsavedChangesAwareNavLink';
import { Nav, NavItem } from 'reactstrap';
import ROUTES, { routeFactory } from 'routes';

const DEFAULT_TABS_VISIBILITY = {
  header: true,
  body: true,
  deadlines: true,
  attachments: true,
  paymentCertificates: true,
  invoices: true,
};

const createHeaderLink = (commissionId, purchaseOrderId) => {
  return ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.HEADER.replace(':commissionId', commissionId).replace(
    ':purchaseOrderId',
    purchaseOrderId
  );
};

const createBodyLink = (commissionId, purchaseOrderId) => {
  return ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.BODY.replace(':commissionId', commissionId).replace(
    ':purchaseOrderId',
    purchaseOrderId
  );
};

const createDeadlineLink = (commissionId, purchaseOrderId) => {
  return ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.DEADLINES.replace(':commissionId', commissionId).replace(
    ':purchaseOrderId',
    purchaseOrderId
  );
};

const createAttachmentsLink = (commissionId, purchaseOrderId) => {
  return ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.ATTACHMENTS.replace(':commissionId', commissionId).replace(
    ':purchaseOrderId',
    purchaseOrderId
  );
};

const createPaymentCertificatesLink = (commissionId, purchaseOrderId) => {
  return routeFactory.commissionManagement.purchaseOrders.paymentCertificates.index(commissionId, purchaseOrderId);
};

const createInvoicesLink = (commissionId, purchaseOrderId) => {
  return ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.INVOICES.replace(':commissionId', commissionId).replace(
    ':purchaseOrderId',
    purchaseOrderId
  );
};

const PurchaseOrderManagementNavbar = ({ commissionId, purchaseOrderId, tabsVisibilityOverrides = {} }) => {
  const tabsVisibility = { ...DEFAULT_TABS_VISIBILITY, ...tabsVisibilityOverrides };
  return (
    <Nav tabs>
      {tabsVisibility.header && (
        <NavItem>
          <UnsavedChangesAwareNavLink to={createHeaderLink(commissionId, purchaseOrderId)}>
            Generali
          </UnsavedChangesAwareNavLink>
        </NavItem>
      )}
      {tabsVisibility.body && (
        <NavItem>
          <UnsavedChangesAwareNavLink to={createBodyLink(commissionId, purchaseOrderId)}>
            Corpo
          </UnsavedChangesAwareNavLink>
        </NavItem>
      )}
      {tabsVisibility.deadlines && (
        <NavItem>
          <UnsavedChangesAwareNavLink to={createDeadlineLink(commissionId, purchaseOrderId)}>
            Scadenziario
          </UnsavedChangesAwareNavLink>
        </NavItem>
      )}
      {tabsVisibility.attachments && (
        <NavItem>
          <UnsavedChangesAwareNavLink to={createAttachmentsLink(commissionId, purchaseOrderId)}>
            Allegati
          </UnsavedChangesAwareNavLink>
        </NavItem>
      )}
      {tabsVisibility.paymentCertificates && (
        <NavItem>
          <UnsavedChangesAwareNavLink to={createPaymentCertificatesLink(commissionId, purchaseOrderId)}>
            Certificati pagamento
          </UnsavedChangesAwareNavLink>
        </NavItem>
      )}
      {tabsVisibility.invoices && (
        <NavItem>
          <UnsavedChangesAwareNavLink to={createInvoicesLink(commissionId, purchaseOrderId)}>
            Fatture
          </UnsavedChangesAwareNavLink>
        </NavItem>
      )}
    </Nav>
  );
};

export default PurchaseOrderManagementNavbar;
