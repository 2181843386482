import { format } from 'helpers/amount';
import { displayDate } from 'helpers/date';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { routeFactory } from 'routes';

const generatePaymentCertificateDetailLink = (commissionId, salesOrderId, number) => {
  return routeFactory.commissionManagement.salesOrders.paymentCertificates.detail(commissionId, salesOrderId, number);
};

const PaymentCertificateHeader = ({ number, date, note, taxableAmount, salesOrderId, commissionId, className }) => (
  <header className={className}>
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <span className="pr-2">
        <NavLink
          to={generatePaymentCertificateDetailLink(commissionId, salesOrderId, number)}
          target="_blank"
          className="text-dark"
        >
          CDP nr. {number}
        </NavLink>
        {date && <small className="text-muted"> del {displayDate(new Date(date))}</small>}
      </span>
      {taxableAmount && (
        <span className="pl-2 border-left small">
          <span className="d-md-none d-lg-inline">Totale fatturabile:&nbsp;</span>
          <span className={`font-weight-bold text-${taxableAmount >= 0 ? 'success' : 'danger'}`}>
            {format(taxableAmount)}€
          </span>
        </span>
      )}
    </div>
    {note && <div className="mt-1 small text-muted">{note}</div>}
  </header>
);

export default memo(PaymentCertificateHeader);
