import Loader from 'components/layout/Loader';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import { formatNumber } from 'helpers/formatters';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import useFeedback from 'hooks/useFeedback';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import { Button, Col, Form, Input, Row, Table } from 'reactstrap';
import { fetchBudgetActivities } from 'services/api/budget';
import {
  fetchPurchaseOrderLines,
  fetchPurchaseOrderWithId,
  updatePurchaseOrderLines,
} from 'services/api/purchase-orders';
import { fetchUnitsOfMeasure } from 'services/api/units-of-measure';
import useInvalidatePurchaseOrder from './shared/hooks/useInvalidatePurchaseOrder';
import { roundNumber } from 'helpers/numbers';
import { stripHtml } from 'helpers/text';
import ErrorMessage from 'components/shared/feedbacks/ErrorMessage';
import useAuth from 'hooks/useAuth';
import sanitize from 'sanitize-html';
import useUnsavedChanges from 'hooks/useUnsavedChanges';

const EMPTY_ORDER_LINE = Object.freeze({
  description: '',
  activityId: '',
  unitOfMeasureId: '',
  quantity: '',
  unitAmount: '',
});

const summarizePurchaseOrder = (purchaseOrder) => ({
  editable: purchaseOrder.editable,
  currency: {
    id: purchaseOrder.currency.id,
    exchangeRate: !!purchaseOrder.currency.exchangeRate && {
      amount: purchaseOrder.currency.exchangeRate.amount,
      date: new Date(Date.parse(purchaseOrder.currency.exchangeRate.date)),
    },
  },
  status: parseInt(purchaseOrder.status.id),
  ownerId: purchaseOrder.ownerId,
});

const mapPurchaseOrderLineToFrontEnd = (orderLine) => ({
  description: orderLine.description,
  activityId: orderLine.approvedBudgetSubactivityId || '',
  unitOfMeasureId: orderLine.unitOfMeasureId || '',
  quantity: orderLine.quantity,
  unitAmount: orderLine.unitAmount,
});

const createOrderLine = () => ({ ...EMPTY_ORDER_LINE });

const orderLineTotalAmountExceedsRemainder = (line, remainder) => {
  return !!line.quantity && !!line.unitAmount && roundNumber(line.quantity * line.unitAmount, 2) > remainder;
};

const mapBudgetActivityForOrderBody = (activity) => ({
  id: activity.id,
  displayName: activity.displayName,
  remainder: activity.remainder,
  completed: activity.completed,
});

const fetchInitialResources = async (commissionId, purchaseOrderId) => {
  let errorHandled = false;
  try {
    const [purchaseOrderResponse, budgetActivitiesResponse, unitsOfMeasure, purchaseOrderLinesResponse] =
      await Promise.all([
        fetchPurchaseOrderWithId(commissionId, purchaseOrderId),
        fetchBudgetActivities(commissionId),
        fetchUnitsOfMeasure(),
        fetchPurchaseOrderLines(purchaseOrderId),
      ]);
    if (!purchaseOrderResponse.ok || !purchaseOrderLinesResponse.ok) {
      errorHandled = true;
      throw new Error("Non è stato possibile ottenere i dati dell'ordine di acquisto.");
    }
    if (!budgetActivitiesResponse.ok) {
      errorHandled = true;
      throw new Error('Non è stato possibile ottenere le attività di budget.');
    }
    const purchaseOrder = summarizePurchaseOrder(purchaseOrderResponse.data);
    const purchaseOrderLines = purchaseOrderLinesResponse.data.map(mapPurchaseOrderLineToFrontEnd);

    const selectedBudgetSubactivityIds = purchaseOrderLines.map((ln) => ln.activityId);

    const budgetActivities = budgetActivitiesResponse.data
      .filter((activity) => activity.orderable || selectedBudgetSubactivityIds.includes(activity.id))
      .map(mapBudgetActivityForOrderBody);

    return {
      purchaseOrder,
      purchaseOrderLines,
      budgetActivities,
      unitsOfMeasure,
    };
  } catch (err) {
    if (errorHandled) {
      throw err;
    }
    throw new Error('Si è verificato un errore');
  }
};

const mapOrderLineForBackend = (line) => {
  const activityId = parseInt(line.activityId);
  const postOrderLine = { description: line.description };
  if (!isNaN(activityId)) {
    const { quantity, unitAmount, unitOfMeasureId } = line;
    postOrderLine.unitOfMeasureId = parseInt(unitOfMeasureId);
    postOrderLine.approvedBudgetSubactivityId = parseInt(activityId);
    postOrderLine.quantity = parseFloat(quantity);
    postOrderLine.unitAmount = parseFloat(unitAmount);
  }
  return postOrderLine;
};

const sanitizeOptions = {
  allowedTags: ['b', 'strong', 'p', 'div'],
  allowedAttributes: [],
  // nonTextTags: [ 'style', 'script', 'textarea', 'noscript' ]
};

const DescriptionDialog = ({
  rowIndex,
  descriptionInitialValue = '',
  editable = false,
  onHide = () => {},
  onConfirmDescription = (rowIndex, descriptionValue) => {},
}) => {
  const [descriptionValue, setDescriptionValue] = useState(descriptionInitialValue || '');

  useEffect(() => {
    setDescriptionValue(descriptionInitialValue || '');
  }, [rowIndex, descriptionInitialValue]);

  return (
    <Dialog
      header={`Descrizione della riga n° ${rowIndex + 1}`}
      visible={rowIndex > -1}
      style={{ width: '80vw', height: '80vh' }}
      onHide={onHide}
      footer={
        editable && (
          <div>
            <Button type="button" onClick={() => onConfirmDescription(rowIndex, descriptionValue)}>
              Conferma cambiamenti
            </Button>
          </div>
        )
      }
    >
      <EditorProvider>
        <Editor
          containerProps={{ className: 'rsw-editor full-height-editor' }}
          value={descriptionValue}
          onChange={(e) => setDescriptionValue(sanitize(e.target.value, sanitizeOptions))}
          disabled={!editable}
        >
          {editable && (
            <Toolbar>
              <BtnUndo title="Annulla" />
              <BtnRedo title="Ripristina" />
              <Separator />
              <BtnBold title="Grassetto" />
              <BtnItalic title="Corsivo" />
              <BtnUnderline title="Sottolineato" />
              <Separator />
              <BtnBulletList title="Elenco non ordinato" />
              <BtnNumberedList title="Elenco ordinato" />
            </Toolbar>
          )}
        </Editor>
      </EditorProvider>
    </Dialog>
  );
};

const PURCHASE_ORDER_STATUS = [2, 4];
const PurchaseOrderBodyPage = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [purchaseOrder, setPurchaseOrder] = useState(false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  const [budgetActivities, setBudgetActivities] = useState([]);
  const [orderLines, setOrderLines] = useState([]);
  const [descriptionDialogRowIndex, setDescriptionDialogRowIndex] = useState(-1);

  const { commissionId, purchaseOrderId } = useParams();
  const { userId } = useAuth();
  const { warning, error, success } = useFeedback();
  const { hasPermission } = useCommissionPermissions();
  const invalidatePurchaseOrder = useInvalidatePurchaseOrder();

  const { notify: notifyUnsavedChanges, clear: clearUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    setLoading(true);
    setErrorMessage('');
    fetchInitialResources(commissionId, purchaseOrderId)
      .then(({ purchaseOrder, purchaseOrderLines, budgetActivities, unitsOfMeasure }) => {
        console.log(purchaseOrder);
        setPurchaseOrder(purchaseOrder);
        setOrderLines(purchaseOrderLines);
        setBudgetActivities(budgetActivities);
        setUnitsOfMeasure(unitsOfMeasure);
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setLoading(false));
  }, [commissionId, purchaseOrderId]);

  const totalAmount = roundNumber(
    orderLines.reduce(
      (prevTotal, line) => prevTotal + (!!line.quantity && !!line.unitAmount ? line.quantity * line.unitAmount : 0),
      0
    ),
    2
  );

  const changeRowValue = (rowIndex, fieldName, fieldValue) => {
    notifyUnsavedChanges();
    setOrderLines((prev) => prev.map((ln, index) => (index !== rowIndex ? ln : { ...ln, [fieldName]: fieldValue })));
  };

  const handleRowChange = (e) => {
    const rowIndex = parseInt(e.target.dataset.rowIndex);
    const fieldName = e.target.dataset.fieldName;
    const fieldValue = e.target.value;
    changeRowValue(rowIndex, fieldName, fieldValue);
  };

  const handleOnActivityChange = (e) => {
    notifyUnsavedChanges();
    const rowIndex = parseInt(e.target.dataset.rowIndex);
    setOrderLines((prev) =>
      prev.map((ln, index) => (index !== rowIndex ? ln : { ...EMPTY_ORDER_LINE, activityId: e.target.value }))
    );
  };

  const handleAddOrderLineClick = () => {
    notifyUnsavedChanges();
    setOrderLines((prev) => [...prev, createOrderLine(prev.length + 1)]);
  };

  const handleRemoveOrderLineClick = (e) => {
    notifyUnsavedChanges();
    const rowIndex = parseInt(e.target.dataset.rowIndex);
    setOrderLines((prev) => prev.filter((ln, index) => index !== rowIndex));
  };

  const handleOnChangeDescriptionClick = (rowIndex) => {
    setDescriptionDialogRowIndex(rowIndex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (orderLines.length === 0) {
      warning('Aggiungi almeno una riga per continuare.');
    } else {
      setLoading(true);
      const backendOrderLines = orderLines.map(mapOrderLineForBackend);
      const response = await updatePurchaseOrderLines(purchaseOrderId, backendOrderLines);
      setLoading(false);

      if (response.ok) {
        clearUnsavedChanges();
        success(response.message);
        invalidatePurchaseOrder();
      } else {
        error(response.message);
      }
    }
  };

  const canEdit =
    hasPermission(PURCHASE_ORDER_PERMISSIONS.CREATE_EDIT) &&
    (purchaseOrder?.editable ?? false) &&
    userId === purchaseOrder.ownerId;

  return (
    <>
      {loading && <Loader />}
      {errorMessage && <ErrorMessage text={errorMessage} />}
      {purchaseOrder && (
        <>
          <DescriptionDialog
            editable={canEdit}
            rowIndex={descriptionDialogRowIndex}
            descriptionInitialValue={orderLines[descriptionDialogRowIndex]?.description ?? ''}
            onConfirmDescription={(rowIndex, descriptionValue) => {
              setDescriptionDialogRowIndex(-1);
              changeRowValue(rowIndex, 'description', descriptionValue);
            }}
            onHide={() => setDescriptionDialogRowIndex(-1)}
          />
          <Form onSubmit={handleSubmit}>
            <div className="table-responsive">
              <Table bordered style={{ tableLayout: 'fixed' }}>
                <colgroup>
                  <col style={{ width: '8%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '22%' }} />
                  <col span={3} style={{ width: '9%' }} />
                  <col style={{ width: '13%' }} />
                  {canEdit && <col style={{ width: 'calc(30px + 0.75rem * 2)' }} />}
                </colgroup>
                <thead>
                  <tr>
                    <th>N. riga</th>
                    <th>Codice attività</th>
                    <th>Rimanenza €</th>
                    <th>Descrizione</th>
                    <th>UdM</th>
                    <th>Qtà</th>
                    <th>
                      Importo
                      <br />
                      unitario
                    </th>
                    <th>
                      Importo totale
                      <br />
                      riga
                    </th>
                    {canEdit && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {orderLines.map((line, index) => {
                    const approvedBudgetActivity = budgetActivities.find(
                      (activity) => activity.id === parseInt(line.activityId)
                    );
                    const totalAmountExceedsRemainder =
                      approvedBudgetActivity &&
                      orderLineTotalAmountExceedsRemainder(line, approvedBudgetActivity.remainder);
                    const plainTextDescription = stripHtml(line.description);
                    return (
                      <tr key={`ln_${index}`}>
                        <td className="align-middle">{index + 1}</td>
                        <td className="align-middle">
                          <Input
                            type="select"
                            value={line.activityId}
                            data-row-index={index}
                            bsSize="sm"
                            onChange={handleOnActivityChange}
                            disabled={!canEdit}
                          >
                            <option value="">- no attività -</option>
                            {/* Mostra tutte le attività di budget che NON sia state completate, oppure quella selezionata */}
                            {budgetActivities
                              .filter((activity) => !activity.completed || parseInt(line.activityId) === activity.id)
                              .map((activity) => (
                                <option key={`${activity.id}`} value={`${activity.id}`} disabled={activity.completed}>
                                  {activity.displayName}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td
                          className={`align-middle text-center${
                            totalAmountExceedsRemainder ? ' text-danger font-weight-bold' : ''
                          }`}
                        >
                          {!PURCHASE_ORDER_STATUS.includes(purchaseOrder.status) &&
                            formatNumber(approvedBudgetActivity?.remainder)}
                        </td>
                        <td className="align-middle">
                          <Row>
                            <Col xs={6} xl={9}>
                              <div
                                style={{
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                title={plainTextDescription}
                              >
                                {plainTextDescription}
                              </div>
                              <Input
                                type="text"
                                style={{
                                  width: '0px',
                                  height: '0px',
                                  padding: '0px',
                                  margin: '0px',
                                }}
                                value={plainTextDescription}
                                required
                                onChange={() => {}}
                                tabIndex={-1}
                              />
                            </Col>
                            <Col xs={6} xl={3} className="text-right">
                              <Button
                                type="button"
                                color="link"
                                style={{ fontSize: '1rem' }}
                                className="p-0"
                                onClick={(e) => handleOnChangeDescriptionClick(index)}
                              >
                                {canEdit ? (
                                  <i className="fa fa-edit" title="Modifica descrizione"></i>
                                ) : (
                                  <i className="fa fa-eye" title="Visualizza descrizione"></i>
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </td>
                        <td className="align-middle">
                          {line.activityId && (
                            <Input
                              type="select"
                              value={line.unitOfMeasureId}
                              bsSize="sm"
                              data-row-index={index}
                              data-field-name="unitOfMeasureId"
                              onChange={handleRowChange}
                              required
                              disabled={!canEdit}
                            >
                              <option value="">...</option>
                              {unitsOfMeasure.map((um) => (
                                <option key={`UM_${um.id}`} value={um.id}>
                                  {um.name}
                                </option>
                              ))}
                            </Input>
                          )}
                        </td>
                        <td className="align-middle text-right">
                          {line.activityId && (
                            <InputNumber
                              value={line.quantity}
                              inputStyle={{
                                padding: '.25rem .5rem',
                                fontSize: '.875rem',
                                width: '100%',
                              }}
                              onChange={(e) => changeRowValue(index, 'quantity', e.value)}
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              required
                              disabled={!canEdit}
                              locale="en-US"
                            />
                          )}
                        </td>
                        {/* Unit amount */}
                        <td className="align-middle text-right">
                          {line.activityId && (
                            <InputNumber
                              value={line.unitAmount}
                              inputStyle={{
                                padding: '.25rem .5rem',
                                fontSize: '.875rem',
                                width: '100%',
                              }}
                              onChange={(e) => changeRowValue(index, 'unitAmount', e.value)}
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              required
                              disabled={!canEdit}
                              locale="en-US"
                            />
                          )}
                        </td>
                        {/* Total line amount */}
                        <td
                          className={`align-middle text-right${
                            totalAmountExceedsRemainder && !PURCHASE_ORDER_STATUS.includes(purchaseOrder.status)
                              ? ' text-danger font-weight-bold'
                              : ''
                          }`}
                        >
                          {formatNumber(roundNumber(line.quantity * line.unitAmount, 2))}
                        </td>
                        {/* Remove action, if available */}
                        {canEdit && (
                          <td className="align-middle text-center">
                            <Button
                              type="button"
                              color="outline-danger"
                              size="sm"
                              data-row-index={index}
                              onClick={handleRemoveOrderLineClick}
                            >
                              &times;
                            </Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
                {/* Table footer */}
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      {canEdit && (
                        <Button type="button" color="primary" size="sm" block onClick={handleAddOrderLineClick}>
                          Aggiungi una riga per questo ordine
                        </Button>
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <th colSpan={3} className="text-right align-middle">
                      Importo totale ordine (€)
                    </th>
                    <th className="text-right align-middle">{formatNumber(totalAmount)}</th>
                    {canEdit && <td></td>}
                  </tr>
                  {/* Change rate row */}
                  {purchaseOrder.currency.exchangeRate && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td colSpan={2} className="text-primary text-right align-middle">
                        Ordine in valuta {purchaseOrder.currency.id}. Cambio{' '}
                        {formatNumber(purchaseOrder.currency.exchangeRate.amount)} al{' '}
                        {purchaseOrder.currency.exchangeRate.date.toLocaleDateString('it-IT', {
                          dateStyle: 'short',
                        })}
                      </td>
                      <th colSpan={3} className="text-right align-middle">
                        Importo totale ordine ({purchaseOrder.currency.id})
                      </th>
                      <th className="text-right align-middle">
                        {formatNumber(roundNumber(totalAmount * purchaseOrder.currency.exchangeRate.amount, 2))}
                      </th>
                      {canEdit && <td></td>}
                    </tr>
                  )}
                </tfoot>
              </Table>
            </div>
            {canEdit && (
              <div className="text-right">
                <Button type="submit" color="primary" className="ml-auto">
                  Salva Ordine
                </Button>
              </div>
            )}
          </Form>
        </>
      )}
    </>
  );
};

export default PurchaseOrderBodyPage;
