import { NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import { format } from 'helpers/amount';
import PaymentCertificateStatusBadge from '../status-badge';
import { ExportAsPdfButton } from 'components/buttons';

const PaymentCertificatesTable = ({ paymentCertificates, detailRouteFactory, guaranteeWithholdingTableHeaderText, onExportAsPdf, canDownload }) => {
  return (
    <Table borderless>
      <thead>
        <tr>
          <th>N.</th>
          <th>Data</th>
          <th>Stato</th>
          <th className="text-right">Importo (€)</th>
          <th className="text-right">{guaranteeWithholdingTableHeaderText}</th>
          <th className="text-right">Rideterminazione</th>
          <th className="text-right">Anticipo</th>
          <th colSpan={3} />
        </tr>
      </thead>
      <tbody>
        {paymentCertificates.map((paymentCertificate, paymentCertificateIndex) => (
          <tr key={`payment-certificate-${paymentCertificate.number}`}>
            <td>
              {paymentCertificate.number}
              {paymentCertificate.advancePercentage > 0 &&
                ` (anticipo ${format(paymentCertificate.advancePercentage)}%)`}
            </td>
            <td>{paymentCertificate.date.toLocaleDateString('it-IT', { dateStyle: 'short' })}</td>
            <td>
              <PaymentCertificateStatusBadge
                statusId={paymentCertificate.status.id}
                statusName={paymentCertificate.status.name}
              />
            </td>
            <td className="text-right">{paymentCertificate.amount !== 0 && `${format(paymentCertificate.amount)}€`}</td>
            <td className="text-right">
              {paymentCertificate.guaranteeWithholdingValue !== 0 &&
                `${format(paymentCertificate.guaranteeWithholdingValue)}€`}
            </td>
            <td className="text-right">
              {paymentCertificate.redeterminationAmount !== 0 && `${format(paymentCertificate.redeterminationAmount)}€`}
            </td>
            <td className="text-right">
              {paymentCertificate.advance !== 0 && `${format(paymentCertificate.advance)}€`}
            </td>
            <td className="text-center">
              {paymentCertificate.viewable && (
                <NavLink to={detailRouteFactory(paymentCertificate.number)}>
                  <small>Visualizza</small>
                </NavLink>
              )}
            </td>
            <td className="text-center">
              {canDownload && paymentCertificate.downloadable && ( <ExportAsPdfButton size={'sm'} onClick={() => onExportAsPdf(paymentCertificate.number)} color="primary" className="mr-1" />)}
            </td>
            <td className="text-center">
              {/* {paymentCertificate.editable && <small style={{ textDecoration: 'line-through' }}>Modifica</small>} */}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2}></td>
          <th className="text-right">Totale</th>
          <td className="text-right border-top">
            {format(
              paymentCertificates.reduce(
                (totalAmount, paymentCertificate) => (totalAmount += paymentCertificate.amount),
                0
              )
            )}
            €
          </td>
          <td className="text-right border-top">
            {format(
              paymentCertificates.reduce(
                (totalGuaranteeWithholding, paymentCertificate) =>
                  (totalGuaranteeWithholding += paymentCertificate.guaranteeWithholdingValue),
                0
              )
            )}
            €
          </td>
          <td className="text-right border-top">
            {format(
              paymentCertificates.reduce(
                (totalRedetermination, paymentCertificate) =>
                  (totalRedetermination += paymentCertificate.redeterminationAmount),
                0
              )
            )}
            €
          </td>
          <td className="text-right border-top">
            {format(
              paymentCertificates.reduce(
                (totalAdvance, paymentCertificate) => (totalAdvance += paymentCertificate.advance),
                0
              )
            )}
            €
          </td>
          <td colSpan={3} />
        </tr>
      </tfoot>
    </Table>
  );
};

export default PaymentCertificatesTable;
