const handleErrorResponse = (response) => {
    switch (response.status) {
      case 401:
        return {
          success: false,
          authenticated: false,
        };
      case 403:
        return {
          success: false,
          authenticated: true,
          authorized: false,
        };
      case 404:
        return {
          success: false,
          message: 'nessun dato trovato',
        };
        case 422:
          return {
            success: false,
            message: response.detail || response.title || 'Si è verificato un errore',
          }
      case 500:
      case 504:
      default:
        return {
          success: false,
          message: 'Si è verificato un errore',
        };
    }
  };

  const getLinkedCommissions = async (commissionId) => {
    const response = await fetch(`/api/commissions/${commissionId}/linked-commissions`);
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
    return handleErrorResponse(response);
  };

  const linkCommission = async (commissionId, mainCommissionId) => {
    try {
      const response = await fetch(
        `/api/commissions/${commissionId}/linked-commissions?mainCommissionId=${encodeURIComponent(
          mainCommissionId
        )}`,
        {
          method: "PUT",
        }
      );
      if (response.ok) {
        return {
          ok: true,
          message: "La commessa è stata collegata con successo.",
        };
      } else {
        const responseBody = await response.json();
        return {
          ok: false,
          message:
            responseBody.detail ||
            responseBody.title ||
            "Si è verificato un errore",
        };
      }
    } catch {
      return {
        ok: false,
        message:
        "Si è verificato un errore",
      };
    }
  };

  const deleteMainCommission = async (commissionId) => {
      try {
        const response = await fetch(
          `/api/commissions/${commissionId}/linked-commissions`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.ok) {
          return {
            ok: true,
            message: "La commessa è stata scollegata con successo.",
          };
        } else {
          const responseBody = await response.json();
          return {
            ok: false,
            message:
              responseBody.detail ||
              responseBody.title ||
              "Si è verificato un errore",
          };
        }
      } catch {
        return {
          ok: false,
          message:
          "Si è verificato un errore",
        };
      }
  };

  export{
    getLinkedCommissions,
    linkCommission,
    deleteMainCommission
  }