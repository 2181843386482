/**
 * 
 * @param {*} filters
 * isVisibleInCommissionManagement: boolean
 * @returns 
 */
const fetchPurchaseInvoiceOrderStatuses = async (filters = {}) => {
  try {
    const response = await fetch(`/api/purchase-invoice-order-statuses?${new URLSearchParams(filters)}`);
    const responseBody = await response.json();
    if (response.ok) {
      return {
        ok: true,
        data: responseBody,
      };
    } else {
      return {
        ok: false,
        message: 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

export { fetchPurchaseInvoiceOrderStatuses };
