import { Card, CardBody, CardHeader, FormGroup, Label, Input, FormText } from 'reactstrap';
import RequiredFieldMarker from 'components/shared/RequiredFieldMarker';
import InputSearch from 'components/shared/forms/InputSearch';

export const InvoicingDetailsCard = ({
  className,
  editable,
  customer,
  sameCustomerAsCommission,
  onSameCustomerAsCommissionChange,
  onCustomerSearchChange,
  onCustomerChange,
}) => (
  <Card className={className}>
    <CardHeader>Fatturazione</CardHeader>
    <CardBody>
      {/* <pre>{JSON.stringify({ editable, customer, sameCustomerAsCommission }, undefined, '\t')}</pre> */}
      <FormGroup tag="fieldset" className="mb-2">
        <FormGroup check>
          <Input
            type="radio"
            name="isSpecificCustomer"
            checked={sameCustomerAsCommission}
            disabled={!editable}
            onChange={() => onSameCustomerAsCommissionChange(false)}
          />
          <Label check>Anagrafica commessa</Label>
        </FormGroup>
        <FormGroup check>
          <Input
            type="radio"
            name="isSpecificCustomer"
            checked={!sameCustomerAsCommission}
            disabled={!editable}
            onChange={() => onSameCustomerAsCommissionChange(true)}
          />
          <Label check>Soggetto particolare</Label>
        </FormGroup>
      </FormGroup>
      {!sameCustomerAsCommission && (
        <>
          <Label>
            Anagrafica di fatturazione <RequiredFieldMarker />
          </Label>
          <InputSearch
            searchable
            name="customerId"
            placeholder="Cerca un cliente..."
            valuePropertyName="id"
            labelPropertyName="displayName"
            onFetchData={onCustomerSearchChange}
            onChange={onCustomerChange}
            noOptionsMessage="Nessun cliente trovato..."
            disabled={!editable}
            cacheOptions
            value={customer}
            required
          />
          <FormText>Deve essere presente su E-Solver</FormText>
        </>
      )}
    </CardBody>
  </Card>
);
