import CustomInput from 'components/shared/forms/CustomInput';
import { ACCEPTED_DOCUMENT_TYPES } from 'config';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

const STYLES = {
    saveButton: {
        marginTop: '22px'
    }
};

const UploadFidejussioniDocument = ({ onSubmit, formId, formName, inputLabelName, inline, documentType, fidejussione, isFileRequired=true }) => {
    const [canSubmit, setCanSubmit] = useState(isFileRequired ? false : true);
    const colSpace = inline ? 12 : 3;

    const handleSubmit = (e) => {
        e.preventDefault();    
        const formData = new FormData(e.target);
        setCanSubmit(false);
        const docData = fidejussione ?{...e.target.getObjectData(), fidejussioniId: fidejussione.fidejussioniId} : e.target.getObjectData();
        onSubmit?.(formData, docData, documentType);
    }

    const handleUploadFile = (e) =>{
        if(e.target.files[0])
          setCanSubmit(true);
      }

    return (
        <Form key={formName + formId} onSubmit={handleSubmit}>
            <Row>
                <Col lg={colSpace}>
                    <CustomInput
                        type="file"
                        accept={ACCEPTED_DOCUMENT_TYPES}
                        label={inputLabelName}
                        name="file"
                        onChange={handleUploadFile}
                        required={isFileRequired}
                    ></CustomInput>
                </Col>
                <Col lg={colSpace}>
                    <CustomInput
                        type="text"
                        label="Importo €"
                        name="amount"
                        defaultValue={fidejussione?.amount}
                    />
                </Col>
                <Col lg={colSpace}>
                    <CustomInput
                        type="text"
                        label="Note"
                        name="note"
                        defaultValue={fidejussione?.note}
                    />
                </Col>
                <Col lg={colSpace} style={STYLES.saveButton}>
                    <Button disabled={!canSubmit} color="primary"><i className="fa fa-save" /></Button>
                </Col>
            </Row>
        </Form>
    );
};

export default UploadFidejussioniDocument;