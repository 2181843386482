import useUnsavedChanges from 'hooks/useUnsavedChanges';
import { NavLink, useNavigate } from 'react-router-dom';

const UnsavedChangesAwareNavLink = ({
  to,
  children,
  className = 'nav-link',
  onClick,
  runClickHandlerAsCallback = true,
  end,
  title,
  style,
}) => {
  const { unsavedChanges, askConfirm } = useUnsavedChanges();
  const navigate = useNavigate();

  const handleClick = (e) => {
    !runClickHandlerAsCallback && onClick?.(e);

    if (unsavedChanges) {
      e.preventDefault();
      askConfirm(() => {
        navigate(to);
        runClickHandlerAsCallback && onClick?.(e);
      });
    }
  };

  return (
    <NavLink to={to} end={end} className={className} style={style} title={title} onClick={handleClick}>
      {children}
    </NavLink>
  );
};

export default UnsavedChangesAwareNavLink;
