const createColumnFilterOption = (value, displayName) => ({ id: value, description: displayName });

class ColumnFilterOptionsBuilder {
  constructor() {
    this.options = [];
  }

  addOption(value, displayName) {
    this.options.push(createColumnFilterOption(value, displayName));
    return this;
  }

  build() {
    return this.options;
  }
}

export default ColumnFilterOptionsBuilder;
export { createColumnFilterOption };
