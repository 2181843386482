import InputSearch from 'components/shared/forms/InputSearch';
import { fetchJson } from 'helpers/api';
import { Col, Row } from 'reactstrap';

const fetchCommissionOptions = async (companyId, search) => {
  return fetchJson(`/api/invoiceable-sales-orders/options/commissions?${new URLSearchParams({ companyId, search })}`);
};

const fetchSalesOrderOptions = async (companyId, search) => {
  return fetchJson(`/api/invoiceable-sales-orders/options/sales-orders?${new URLSearchParams({ companyId, search })}`);
};

const fetchCustomerOptions = async (companyId, search) => {
  return fetchJson(`/api/invoiceable-sales-orders/options/customers?${new URLSearchParams({ companyId, search })}`);
};

const Filters = ({ companyId, filters, onCommissionChange, onOrderChange, onCustomerChange }) => {
  const handleCommissionChange = (commissionOption) => {
    console.debug(`commission ${JSON.stringify(commissionOption)} selected`);
    onCommissionChange({ commissionId: commissionOption.value, commissionDisplayName: commissionOption.label });
  };
  const handleFetchCommissions = async (search) => {
    const response = await fetchCommissionOptions(companyId, search);
    if (response.ok) {
      const commissionOptions = response.data.options;
      console.debug(commissionOptions);
      return commissionOptions;
    }
    return [];
  };
  const handleCustomerChange = (customerOption) => {
    console.debug(`customer ${customerOption.label} (${customerOption.value}) selected`);
    onCustomerChange({ customerId: customerOption.value, customerDisplayName: customerOption.label });
  };
  const handleFetchCustomers = async (search) => {
    const response = await fetchCustomerOptions(companyId, search);
    if (response.ok) {
      const customerOptions = response.data.options;
      console.debug(customerOptions);
      return customerOptions;
    }
    return [];
  };
  const handleSalesOrderChange = (salesOrderOption) => {
    console.debug(`salesOrder ${salesOrderOption.label} (${salesOrderOption.value}) selected`);
    onOrderChange({ salesOrderId: salesOrderOption.value, salesOrderDisplayName: salesOrderOption.label });
  };
  const handleFetchSalesOrders = async (search) => {
    const response = await fetchSalesOrderOptions(companyId, search);
    if (response.ok) {
      const salesOrderOptions = response.data.options;
      console.debug(salesOrderOptions);
      return salesOrderOptions;
    }
    return [];
  };
  return (
    <Row className="mb-2">
      <Col sm={4}>
        <InputSearch
          name="commissionId"
          disabled={false}
          valuePropertyName="id"
          labelPropertyName="displayName"
          noOptionsMessage="Nessuna commessa trovata"
          placeholder="Filtra per cod. commessa..."
          onChange={handleCommissionChange}
          onFetchData={handleFetchCommissions}
          value={filters.commission}
          key={filters.commission?.id ?? ''}
          cacheOptions
        />
      </Col>
      <Col sm={4}>
        <InputSearch
          name="salesOrderId"
          disabled={false}
          valuePropertyName="id"
          labelPropertyName="displayName"
          noOptionsMessage="Nessun ordine trovato"
          placeholder="Filtra per nr. ordine..."
          onChange={handleSalesOrderChange}
          onFetchData={handleFetchSalesOrders}
          value={filters.salesOrder}
          key={filters.salesOrder?.id ?? ''}
          cacheOptions
        />
      </Col>
      <Col sm={4}>
        <InputSearch
          name="customerId"
          disabled={false}
          valuePropertyName="id"
          labelPropertyName="displayName"
          noOptionsMessage="Nessun cliente trovato"
          placeholder="Filtra per cliente..."
          onChange={handleCustomerChange}
          onFetchData={handleFetchCustomers}
          value={filters.customer}
          key={filters.customer?.id ?? ''}
          cacheOptions
        />
      </Col>
    </Row>
  );
};

export default Filters;
