import Loader from 'components/layout/Loader';
import PageHeader, { PageButtonContainer, PageButton } from 'components/layout/PageHeader';
import UncontrolledPurchaseOrdersDataTable from 'components/purchase-orders/UncontrolledPurchaseOrdersDataTable';
import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import { useRef, useState } from 'react';
import { exportPurchaseOrders } from 'services/api/purchase-orders/purchase-orders';

const PAGE_SIZES = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGE_SIZES[0];
const TABLE_IDENTIFIER = 'purchase-orders-management';

const ExportPurchaseOrderButton = ({ onClick }) => (
  <PageButton iconName="download" text="Genera Excel" onClick={onClick} primary />
);

const PurchaseOrdersManagementIndexPage = () => {
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const tableStateRef = useRef();

  const { company } = useAuth();
  const companyId = company.id;

  const { error } = useFeedback();

  const handleDownload = () => {
    setDownloadingExcel(true);
    const { filters, sort } = tableStateRef.current;
    const params = { filters, sort };
    console.debug(params);
    exportPurchaseOrders(params)
      .catch((err) => error(err.message))
      .finally(() => setDownloadingExcel(false));
  };

  return (
    <>
      <PageHeader title="Elenco ordini">
        <PageButtonContainer>
          <ExportPurchaseOrderButton onClick={handleDownload} />
        </PageButtonContainer>
      </PageHeader>
      {downloadingExcel && <Loader />}
      <UncontrolledPurchaseOrdersDataTable
        key={companyId}
        tableIdentifier={TABLE_IDENTIFIER}
        initialFilters={{ companyId }}
        initialPageSize={INITIAL_PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        stateRef={tableStateRef}
      />
    </>
  );
};

export default PurchaseOrdersManagementIndexPage;
