const ToSendObject = (data) => {
  return {
    companyId: data.companyId,
    businessLineId: data.businessLineId,
    commissionStatusId: data.statusId,
    description: data.description,
    expectedStartDate: data.expectedStartDate,
    expectedEndDate: data.expectedEndDate,
    cig: data.cig === '' ? undefined : data.cig,
    cup: data.cup === '' ? undefined : data.cup,
    createdBy: data.createdBy,
    territoryId: data.territoryId,
    customerId: data.customerId,
    paymentTermId: data.paymentTermId,
    paymentTermModificationType: data.paymentTermModificationType,
    paymentTermModificationNote: data.paymentTermModificationNote,
    guaranteeWithholdingId: data.guaranteeWithholdingId === '' ? undefined : data.guaranteeWithholdingId,
    guaranteeWithholdingPercentage: data.guaranteeWithholdingPercentage,
    customerTechnicalReferent: data.technicalReferent,
    customerAdministrativeReferent: data.administrativeReferent,
    worksiteAddress: data.worksiteAddress,
    worksiteLocation: data.worksiteLocation,
    worksitePostalCode: data.worksitePostalCode,
    worksiteMunicipality: data.worksiteMunicipality,
    worksiteCountryId: data.worksiteCountryId,
    worksiteCity: data.worksiteCity,
  };
};

const ToReceiveObject = (data) => {
  data['customerId'] = data.customer.id;
  data['creationUserId'] = data.creator.id;
  data['creationName'] = data.creator.name;
  data['statusId'] = data.status.id;
  data['statusName'] = data.status.name;
  data['territoryId'] = data.territory.id;
  data['paymentTermId'] = data.paymentTerm.id;
  data['paymentTermModificationType'] = data.paymentTerm.modificationType;
  data['paymentTermModificationNote'] = data.paymentTerm.modificationNote;
  data['guaranteeWithholdingId'] = data.guaranteeWithholding?.id;
  data['guaranteeWithholdingPercentage'] = data.guaranteeWithholding.percentage;
  data['worksiteAddress'] = data.worksite.address;
  data['worksiteLocation'] = data.worksite.location;
  data['worksitePostalCode'] = data.worksite.postalCode;
  data['worksiteMunicipality'] = data.worksite.municipality;
  data['worksiteCountry'] = data.worksite.country;
  data['worksiteCity'] = data.worksite.city;
  return data;
};

export { ToSendObject, ToReceiveObject };
