import { Fragment } from 'react';
import ReadOnlyMonthYear from 'components/utils/ReadOnlyMonthYear';

import styles from 'components/commission-management/budget/BudgetTableStyles';

const createPeriodKey = (period) => `${period.year}-${period.month}`;

const BudgetTableHeader = ({ periods, collapsed }) => {
  return (
    <thead>
      <tr>
        <th className={styles['th-default']}>Cod. Att.</th>
        <th colSpan={2} className={styles['th-default']}>
          Cod. sottoatt.
        </th>
        <th className={styles['th-default']}>Descrizione Dimensione</th>
        {/* <th className={styles['th-default']}>Rif. SOA</th> */}
        {/* <th className={styles['th-default']}>Descrizione SOA</th> */}
        <th className={styles['th-cost'] + ' ' + styles['th--numeric']}>Costo (€)</th>
        <th className={styles['th-revenue'] + ' ' + styles['th--numeric']}>Ricavo (€)</th>
        <th className={styles['th-residue']}>Costo residuo (€)</th>
        <th className={styles['th-residue']}>Ricavo residuo (€)</th>
        {!collapsed &&
          periods.map((period) => {
            return (
              <Fragment key={createPeriodKey(period)}>
                <th className={styles['th-cost'] + ' ' + styles['th--numeric']}>
                  <span>Costo (€)</span>
                  <ReadOnlyMonthYear {...period} />
                </th>
                <th className={styles['th-revenue'] + ' ' + styles['th--numeric']}>
                  <span>Ricavo (€)</span>
                  <ReadOnlyMonthYear {...period} />
                </th>
              </Fragment>
            );
          })}
      </tr>
    </thead>
  );
};

export default BudgetTableHeader;
