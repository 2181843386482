import useAuth from 'hooks/useAuth';
import useFetchExportSalesOrders from '../hooks/useFetchExportSalesOrders';
import Loader from 'components/layout/Loader';
import DataTable from 'components/shared/DataTable';
import ROUTES from 'routes';
import { NavLink } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { formatISODate } from 'helpers/formatters';
import { format } from 'helpers/amount';
import { Badge, Button } from 'reactstrap';
import { downloadRemoteFile } from 'helpers/api';
import useFeedback from 'hooks/useFeedback';
import ErrorMessage from 'components/shared/feedbacks/ErrorMessage';

const STATUS_COLORS = {
  0: 'dark',
  1: 'warning',
  2: 'success',
  3: 'danger',
  4: 'primary',
  5: 'danger',
};

const STATUS_ICONS = {
  0: 'fa fa-pen-alt',
  1: 'fa fa-user-clock',
  2: 'fa fa-check',
  3: 'fa fa-pen-alt',
  4: 'fa fa-university',
  5: 'far fa-stop-circle',
};

const PageTitle = ({ text }) => <h1 className="h3 mb-0 text-gray-800">{text}</h1>;

// Però è più bello con questo stile: p-2 text-uppercase
const SalesOrderStatusBadge = ({ statusId, statusName }) => (
  <Badge color={STATUS_COLORS[statusId]}>
    <i className={STATUS_ICONS[statusId]} /> {statusName}
  </Badge>
);

const styles = {
  truncateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const DATA_TABLE_COLUMN = [
  {
    heading: 'Commessa',
    value: 'commissionId',
    type: 'text',
    filterable: true,
    sortable: true,
    cellTemplate: (val, item) => {
      return (
        <>
          <NavLink to={ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS.replace(':commissionId', val)}>{val}</NavLink> -{' '}
          {item.commissionDescription}
        </>
      );
    },
    style: { ...styles.truncateText, maxWidth: '200px' },
  },
  {
    heading: 'Ordine',
    value: 'salesOrderId',
    type: 'text',
    filterable: true,
    sortable: true,
    cellTemplate: (val, item) => {
      return (
        <>
          <NavLink
            to={ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.HEADER.replace(':commissionId', item.commissionId).replace(
              ':salesOrderId',
              val
            )}
          >
            {val}
          </NavLink>{' '}
          - {item.salesOrderDescription}
        </>
      );
    },
    style: { ...styles.truncateText, maxWidth: '200px' },
  },
  {
    heading: 'Data Ordine',
    value: 'orderDate',
    type: 'date',
    filterable: true,
    sortable: true,
    cellTemplate: (value) => formatISODate(value),
  },
  {
    heading: 'Rag. soc. Cliente',
    value: 'businessName',
    type: 'text',
    filterable: true,
    sortable: true,
    style: { ...styles.truncateText, maxWidth: '200px' },
  },
  {
    heading: 'P.IVA/C.F. Cliente',
    value: 'taxCode',
    type: 'text',
    filterable: true,
    sortable: true,
    style: { ...styles.truncateText, maxWidth: '200px' },
    cellTemplate: (value, item) => {
      return <span>{item.vatNumber || item.taxCode}</span>;
    },
  },
  {
    heading: 'Importo (€)',
    value: 'orderAmount',
    type: 'number',
    filterable: false,
    sortable: true,
    style: { textAlign: 'right' },
    cellTemplate: (value) => format(value),
  },
  {
    heading: 'Stato',
    value: 'statusId',
    type: 'select',
    filterable: true,
    sortable: true,
    style: { width: '150px' },
    cellTemplate: (statusId, order) => (
      <SalesOrderStatusBadge statusId={order.status.id} statusName={order.status.description} />
    ),
  },
];

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];

const ExportSalesOrdersPage = () => {
  const [sort, setSort] = useState({ column: '', direction: '' });
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [numRecords, setNumRecords] = useState(INITIAL_PAGE_SIZE);
  const { company } = useAuth();
  const { response, loading, responseStatuses, errorMessage, refreshSalesOrders } = useFetchExportSalesOrders(
    company.id,
    INITIAL_PAGE_SIZE
  );
  const [request, setRequest] = useState({
    companyId: company.id,
    numRecords: INITIAL_PAGE_SIZE,
    currentPage: 0,
  });
  const { error } = useFeedback();

  const column = useMemo(() => {
    return DATA_TABLE_COLUMN.map((col) => (col.value !== 'statusId' ? col : { ...col, options: responseStatuses }));
  }, [responseStatuses]);

  const linkRequest = useMemo(() => {
    const data = {
      CompanyId: request.companyId,
      CurrentPage: request.currentPage,
      NumRecords: request.numRecords,
      Sort: request.sort,
      SortColumn: request.sortColumn,
      CommissionFilter: request.commissionId,
      SalesOrderFilter: request.salesOrderId,
      BusinessNameFilter: request.businessName,
      TaxCodeFilter: request.taxCode,
      DateToFilter: request.orderDateTo,
      DateFromFIlter: request.orderDateFrom,
      StatusIdFilter: request.statusId?.id,
    };

    const validParams = {};
    for (const key in data) {
      if (data[key] !== undefined && data[key] !== null) {
        validParams[key] = data[key];
      }
    }

    console.log(new URLSearchParams(validParams).toString());
    return new URLSearchParams(validParams).toString();
  }, [request]);

  const onHandleSort = (columnName) => {
    let sortDirection = 'ASC';
    let sortColumn = undefined;

    if (columnName !== sort.column) {
      sortDirection = 'ASC';
      sortColumn = columnName;
    } else {
      sortDirection = sort.direction === undefined ? 'ASC' : sort.direction === 'ASC' ? 'DESC' : undefined;
      sortDirection === undefined ? (sortColumn = undefined) : (sortColumn = columnName);
    }
    setSort({ column: sortColumn, direction: sortDirection });

    const requestToSend = {
      ...request,
      sort: sortDirection,
      sortColumn: sortColumn,
    };

    setRequest(requestToSend);

    refreshSalesOrders(requestToSend);
  };

  const onHandleSearch = (value, field, type, newPage) => {
    const requestToSend = { ...request, currentPage: newPage };

    if (value === '') {
      delete requestToSend[field];
    } else {
      requestToSend[field] = type === 'select' ? { id: value } : value;
    }

    // setCurrentPage(newPage);
    setRequest(requestToSend);
    refreshSalesOrders(requestToSend);
  };

  const onHandlePageChange = (newPage) => {
    // setCurrentPage(newPage);
    const requestToSend = {
      ...request,
      currentPage: newPage,
    };
    setRequest(requestToSend);
    refreshSalesOrders(requestToSend);
  };

  const onHandleRecordsChange = (num, page) => {
    setNumRecords(num);
    // setCurrentPage(page);

    const requestToSend = {
      ...request,
      currentPage: page,
      numRecords: num,
    };

    setRequest(requestToSend);
    refreshSalesOrders(requestToSend);
  };

  const handleDownload = () => {
    setDownloadExcel(true);
    downloadRemoteFile(`api/sales-orders-export/export?${linkRequest}`)
      .catch((err) => error(err.message))
      .finally(() => setDownloadExcel(false));
  };

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-1">
        <PageTitle text="Elenco ordini clienti" />
        {response && (
          <div>
            <Button className="shadow-sm" size="sm" color="primary" style={{ float: 'right' }} onClick={handleDownload}>
              <i className="fas fa-download fa-sm text-white-50"></i> Genera Excel
            </Button>
          </div>
        )}
      </div>
      {(loading || downloadExcel) && <Loader />}
      {errorMessage && <ErrorMessage text={errorMessage} />}
      {response && (
        <DataTable
          responsive
          data={response.exportSalesOrders}
          column={column}
          resizable={false}
          onHandleSort={onHandleSort}
          onHandleSearch={onHandleSearch}
          onHandlePageChange={onHandlePageChange}
          onHandleRecordsChange={onHandleRecordsChange}
          totRecords={response.totRecords}
          currentPage={request.currentPage}
          pageSize={numRecords}
          sort={sort}
          pageSizes={PAGES_SIZE}
        />
      )}
    </>
  );
};

export default ExportSalesOrdersPage;
