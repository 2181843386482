import { sum } from "helpers/amount";
import { formatStringInFloat } from "helpers/formatters";

const confrontaImportoFattura = (importoFattura, importo) => {
    return (importo > importoFattura && importoFattura > 0) || (importo < importoFattura && importoFattura < 0);
}
const importoValido = (importoFattura, importo) => {
    return (importo > 0 && importoFattura > 0) || (importo < 0 && importoFattura < 0);
}
const getLink = (commission, purchaseOrder) => {
    if(purchaseOrder.isFictitiousOrder) {
      return '#';
    }
    if(purchaseOrder.paymentCertificateId != null) {
      return '/commesse/' + commission.commissionId + '/ordini-fornitori/' + purchaseOrder.purchaseOrderId + '/certificati-di-pagamento/visualizza/' + purchaseOrder.paymentCertificateId;
    }
    return '/commesse/' + commission.commissionId + '/ordini-fornitori/' + purchaseOrder.purchaseOrderId + '/generali';
};
const getColor = (importoFattura, importoAssociato) => {
    if((importoFattura > importoAssociato && importoFattura > 0) || (importoFattura < importoAssociato && importoFattura < 0)) return '#fa9191';
    if(importoFattura === importoAssociato) return '#8bc34a';
    if((importoFattura < importoAssociato && importoFattura > 0) || (importoFattura > importoAssociato && importoFattura < 0)) return '#facd91';
}
const getTotaleParziale = (purchaseOrder, attivitaId) => {
    let totaleParziale = 0;
    purchaseOrder.attivitaAssociate.forEach((a) => {
        a.selectedAttivita === attivitaId && !a.isDeleted ? totaleParziale += parseFloat(a.importo) : totaleParziale += 0;
    });
    return totaleParziale;
}
const calculateTableCodiciContabili = (invoice) => {
    let codiciContabiliTemp = { totale: 0, totaleDisplay: 0, totaleCalcolato: 0, ccVett: [] };
    let totale = 0;
    let totaleDisplay = 0;
    if (invoice == null || invoice.commissions == null) return codiciContabiliTemp;
    /// La definizione delle colonne è fissa
    invoice.codiciContabili.map((c) => {
        codiciContabiliTemp.ccVett[c.financialAccountId.toString()] = {
          FinancialAccountId: c.financialAccountId,
          COD: c.cod,
          Description: c.description,
          ImportoFattura: c.importoFattura,
          ImportoAssociato: 0
        };
        totale += c.importoFattura < 0 ? -1 * c.importoFattura : c.importoFattura;
        totaleDisplay += c.importoFattura;
        
        codiciContabiliTemp.totale = sum(codiciContabiliTemp.totale, totale);
        codiciContabiliTemp.totaleDisplay = sum(codiciContabiliTemp.totaleDisplay, totaleDisplay);
        totale = 0;
        totaleDisplay = 0;
        return null;
    });
    

    /// l'importo associato è calcolato
    invoice.commissions.map((c) =>
        c.purchaseOrders.map((o) =>
            o.attivitaAssociate?.map((a) => {
                if(!a.isDeleted) {
                    let elem = o.elencoConti.find((v) => {
                        return parseInt(v.id) === a.selectedConto;
                    });
                    if (elem != null && codiciContabiliTemp.ccVett[parseInt(elem.id)]) {
                        codiciContabiliTemp.ccVett[parseInt(elem.id)].ImportoAssociato = sum(codiciContabiliTemp.ccVett[parseInt(elem.id)].ImportoAssociato, formatStringInFloat(a.importo));
                        totale = sum(totale, formatStringInFloat(a.importo));
                    }
                }
                return null;
            })
        )
    );
    codiciContabiliTemp.totaleCalcolato = totale;
    return codiciContabiliTemp;
}
const ImportiResiduiErratiPerOrder = (invoice, purchaseOrder) => {
    if (invoice == null || invoice.commissions == null) return false;
    let result = false;
    let residuiAttivita = [];
    invoice.commissions.forEach((c) => {
        c.purchaseOrders.forEach((o) => {
            if(!o.isFictitiousOrder && (o.purchaseOrderId === purchaseOrder.purchaseOrderId) && o.statusId === 0) {
                o.elencoAttivita.forEach((e) => {
                    let found = o.attivitaAssociate.some((a) => {
                        return (a.selectedAttivita?.toString() === e.id && !a.isDeleted)
                    });
                    if(found) {
                        residuiAttivita.push(e);
                    }
                });
            }
        });
    });

    residuiAttivita.map((r) => {
        let tot = 0;
        invoice.commissions.forEach((c) => {
            c.purchaseOrders.forEach((o) => {
                if((!o.isFictitiousOrder) && (o.purchaseOrderId === purchaseOrder.purchaseOrderId) && o.statusId === 0) {
                    o.attivitaAssociate.forEach((a) => {
                        if(a.selectedAttivita?.toString() === r.id && !a.isDeleted) {
                            tot += formatStringInFloat(a.importo);
                        }
                    });
                }
            });
        });
        if(r.remainder < tot) {
            result = result || true;

        }
        else {
            result = result || false;

        }
        return null;
    });
    return result;
}
/// private function...
const getButtonDisabled = (invoice, purchaseOrder, cod, invia = false) => {
    // se l'ordine non risulta modificato ed è il pulsante invia
    if(!purchaseOrder.isChanged && !invia) return true;
    let result = false;
    result = result || purchaseOrder.statusId > 0;
    // se l'approver non è selezionato ed è il pulsante invia (nel pulsante salva non serve questo controllo)
    result = result || (purchaseOrder.approverId == null && invia) || (purchaseOrder.approverId === -1 && invia);
    // se non ci sono attività associate
    result = result || (purchaseOrder.attivitaAssociate.length === 0);
    // se ci sono attività associate, ma non è selezionata l'attività
    result = result || purchaseOrder.attivitaAssociate.some((a) => (a.selectedAttivita < 0));
    // se ci sono attività associate, ma non è selezionato il conto contabile
    result = result || purchaseOrder.attivitaAssociate.some((a) => (a.selectedConto < 0));
    // se ci sono attività associate con importo = a 0 che non sono flaggate come cancellate
    result = result || purchaseOrder.attivitaAssociate.some((a) => formatStringInFloat(a.importo) === 0 && (a.isDeleted === false || a.isDeleted === undefined));
    // se almeno uno delle attivita associiate supera l'importo del conto contabile e non è flaggata come cancellata
    result = result || (purchaseOrder.attivitaAssociate.some((p) => {
            return cod.ccVett[p.selectedConto] != null &&
                confrontaImportoFattura(cod.ccVett[p.selectedConto].ImportoFattura, cod.ccVett[p.selectedConto].ImportoAssociato) &&
                (p.isDeleted === false || p.isDeleted === undefined);
        }));
    // se almeno una delle attività associate ha un importo non valido e non è flaggata come cancellata
    result = result || (purchaseOrder.attivitaAssociate.some((p) => {
                    return cod.ccVett[p.selectedConto] != null &&
                        !importoValido(cod.ccVett[p.selectedConto].ImportoFattura, formatStringInFloat(p.importo)) &&
                        (p.isDeleted === false || p.isDeleted === undefined);
                }));
    if(!purchaseOrder.isFictitiousOrder) {
        result = result || ImportiResiduiErratiPerOrder(invoice, purchaseOrder);
    }
    // se non ci sono attività associate (o tutte le attività associate sono segnate come da cancellare)
    // ed è il pulsante invia (nel pulsante salva non serve questo controllo, perchè posso voler resettare tutte le righe un'atttività)
    result = result || (invia && (purchaseOrder.attivitaAssociate.length === 0 || purchaseOrder.attivitaAssociate.length === (purchaseOrder.attivitaAssociate.filter((a) => a.isDeleted)).length));
    return result;
}
const isPurchaseOrderValid = (invoice, purchaseOrder) => {
    if(invoice === undefined || invoice.commissions === undefined) return true;

    let cod = calculateTableCodiciContabili(invoice);

    let result = {
        attivitaLength: true,
        selectedAttivita: true,
        selectedConto: true,
        approvatore: true,
        importo: true,
        totaleparziale: 0,
        totaleparzialeColor: true,
        importoMaggioreDiZero: true,
        importoValido: true,
        disassociaDisabled: purchaseOrder.statusId > 0,
        salvaDisabled: getButtonDisabled(invoice, purchaseOrder, cod),
        aggiungiDisabled: purchaseOrder.statusId > 0,
        inviaDisabled:getButtonDisabled(invoice, purchaseOrder, cod, true)
    };

    // se non ci sono attività associate
    result.attivitaLength = !(purchaseOrder.attivitaAssociate.length === 0 || purchaseOrder.attivitaAssociate.filter((a) => !a.isDeleted).length === 0);
    // se ci sono attività associate, ma non è selezionata l'attività
    result.selectedAttivita = !purchaseOrder.attivitaAssociate.some((a) => (a.selectedAttivita < 0));
    // se ci sono attività associate, ma non è selezionato il conto contabile
    result.selectedConto = !purchaseOrder.attivitaAssociate.some((a) => (a.selectedConto < 0));
    
    let anomalie = []
    purchaseOrder.attivitaAssociate.map((a) => {
        if(cod.ccVett[a.selectedConto.toString()] !== undefined && cod.ccVett[a.selectedConto.toString()].ImportoAssociato !== undefined) {
            anomalie.push(confrontaImportoFattura(cod.ccVett[a.selectedConto.toString()].ImportoFattura, cod.ccVett[a.selectedConto.toString()].ImportoAssociato));
        }
        return null;
    });

    result.importo = (!anomalie.some((a) => a === true) && (purchaseOrder.attivitaAssociate.length > 0)) || purchaseOrder.attivitaAssociate.length === 0;
    result.importoMaggioreDiZero = !purchaseOrder.attivitaAssociate.some((a) => formatStringInFloat(a.importo) === 0);
    result.importoValido = !purchaseOrder.attivitaAssociate.some((a) => {
            return cod.ccVett[a.selectedConto] != null &&
                !importoValido(cod.ccVett[a.selectedConto].ImportoFattura, formatStringInFloat(a.importo)) &&
                (a.isDeleted === false || a.isDeleted === undefined);
        });

    result.totaleparzialeColor = !ImportiResiduiErratiPerOrder(invoice, purchaseOrder);
    /// se non è stato selezionato un approvatore
    if (purchaseOrder.approverId == null || purchaseOrder.approverId < 0) {
        result.approvatore = false;
    } else {
        result.approvatore = true;
    }
    return result;
}
export {
    confrontaImportoFattura,
    getLink,
    getColor,
    getTotaleParziale,
    importoValido,
    calculateTableCodiciContabili,
    ImportiResiduiErratiPerOrder,
    isPurchaseOrderValid
};