import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { routeFactory } from 'routes';

const styles = {
    textTruncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    orderId: {
        fontWeight: 700,
    },
};

const OrderGroupHeader = memo(({ commissionId, orderId, description, customerDisplayName }) => {
    return (
        <header>
            <h5 className="mb-1" style={styles.textTruncate}>
                <NavLink
                    to={routeFactory.commissionManagement.salesOrders.detail(commissionId, orderId)}
                    target="_blank"
                    className="text-primary"
                    style={styles.orderId}
                >
                    {orderId}
                </NavLink>
            </h5>
            <div className="small mb-1" style={styles.textTruncate}>
                <b className="pr-2 border-right">{customerDisplayName}</b><span className="pl-2">{description}</span>
            </div>
        </header>
    );
});

const OrderGroup = ({ commissionId, order, children }) => (
    <div className="mb-3 pr-3">
        <OrderGroupHeader
            commissionId={commissionId}
            orderId={order.id}
            description={order.description}
            customerDisplayName={order.customerDisplayName}
        />
        <div>{children}</div>
    </div>
);

export default OrderGroup;
