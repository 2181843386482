import ToastMessageAnimated from './ToastMessageAnimated';

const ToastContainer = ({ messages, onToastRemove }) => {
  return (
    <div id="toast-container" className="toast-top-right">
      {messages.map((message) => (
        <ToastMessageAnimated
          key={'msg-' + message.id}
          type={message.type}
          id={message.id}
          title={message.title}
          message={message.message}
          onRemove={onToastRemove}
        />
      ))}
    </div>
  );
};

export default ToastContainer;
