import { format } from 'helpers/amount';
import { Col, Row } from 'reactstrap';

const styles = {
  item: {
    value: {
      width: '90%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
};

const Item = ({ header, value }) => (
  <Col md={4} className="mb-3">
    <h6 className="mb-1">{header}</h6>
    <div style={styles.item.value}>{value}</div>
  </Col>
);

const formatDate = (date) => {
  return date.toLocaleDateString('it-IT', { dateStyle: 'short' });
};

const SalesOrderSummary = ({ order }) => {
  return (
    <div className="mb-4">
      <Row>
        <Item header="Ordine" value={order.orderId} />
        <Item header="Data ordine" value={formatDate(order.orderDate)} />
        <Item header="Importo (€)" value={format(order.amount)} />
      </Row>
      <Row>
        <Item header="Cliente" value={order.customerName.toUpperCase()} />
        <Item header="% fatturato" value={format(order.invoicedAmountPercentage)} />
        <Item header="Rit. Gar." value={order.guaranteeWithholdingHeaderText.toUpperCase()} />
      </Row>
    </div>
  );
};

export default SalesOrderSummary;
