import { Table } from 'reactstrap';


export const CustomerSummary = ({ address, postalCode, taxCode, municipality, paymentTermName, vatNumber }) => (
  <Table bordered responsive className="m-0">
    <thead>
      <tr>
        <th>Indirizzo</th>
        <th>Cap</th>
        <th>CF</th>
        <th>Provincia</th>
        <th>Modalità di pagamento</th>
        <th>P.IVA</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{address}</td>
        <td>{postalCode}</td>
        <td>{taxCode}</td>
        <td>{municipality}</td>
        <td>{paymentTermName}</td>
        <td>{vatNumber}</td>
      </tr>
    </tbody>
  </Table>
);
