import useFeedback from "hooks/useFeedback";
import { useCallback, useEffect, useState } from "react";
import useParseCustomResponse from "./useParseCustomResponse";
import { fetchSalesInvoiceOrderStatus, fetchSalesInvoices } from "services/api/sales-invoices";

const mapToIndexData = (response) => {
  return {
    salesOrderId: response.salesOrderId,
    invoiceNumber: response.invoiceNumber,
    dateInvoice: response.dateInvoice,
    businessName: response.customer.businessName,
    vatNumberOrTaxCode: response.customer.vatNumberOrTaxCode,
    orderCdp: `${response.salesOrderId} - CDP nr. ${response.cdp}`,
    orderAmount: response.orderAmount,
    invoiceAmount: response.invoiceAmount,
    associatedAmount: response.associatedAmount,
    registrationDate: response.registrationDate,
    statusId: response.status.id,
    status: response.status,
  };
};

const useRetrieveData = (commissionId, INITIAL_PAGE_SIZE) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const [invoiceStatuses, setInvoiceStatuses] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const retrieveDataWithFilters = (filters, currentPage, numRecords) => {
    setLoading(true);
    fetchSalesInvoices(commissionId, filters, currentPage, numRecords)
      .then((SalesInvoicesRaw) => {
        const parsedInvoicesResponse = parseCustomResponse(SalesInvoicesRaw);
        if (parsedInvoicesResponse.success) {
            setResponse({
                salesInvoicesResponse: parsedInvoicesResponse.data.invoices.map(
                  (salesInvoice) => mapToIndexData(salesInvoice)
                ),
                totRecords: parsedInvoicesResponse.data.totRecords
              });
        } else {
          setErrorMessage(parsedInvoicesResponse.message);
        }
      })
      .catch(() => {
        error("errore durante il recupero dei dati", "Errore");
      })
      .finally(() => setLoading(false));
  };

  const fetchData = useCallback(async () => {
    const [SalesInvoicesRaw, SalesInvoiceStatusRaw] = await Promise.all([
      fetchSalesInvoices(commissionId, null, 0, INITIAL_PAGE_SIZE),
      fetchSalesInvoiceOrderStatus(),
    ]);

    return {
      SalesInvoicesRaw,
      SalesInvoiceStatusRaw,
    };
  }, [INITIAL_PAGE_SIZE, commissionId]);

  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(({ SalesInvoicesRaw, SalesInvoiceStatusRaw }) => {
        const parsedInvoicesResponse = parseCustomResponse(SalesInvoicesRaw);
        const parsedInvoiceStatusResponse = parseCustomResponse(
          SalesInvoiceStatusRaw
        );

        if (
          parsedInvoicesResponse.success &&
          parsedInvoiceStatusResponse.success
        ) {
          setResponse({
            salesInvoicesResponse: parsedInvoicesResponse.data.invoices.map(
              (salesInvoice) => mapToIndexData(salesInvoice)
            ),
            totRecords: parsedInvoicesResponse.data.totRecords
          });

          setInvoiceStatuses(parsedInvoiceStatusResponse.data.statuses)
        } else {
          setErrorMessage(
            `${parsedInvoicesResponse.message}, \n ${parsedInvoiceStatusResponse.message}`
          );
        }
      })
      .catch(() => {
        error("errore durante il recupero dei dati", "Errore");
      })
      .finally(() => setLoading(false));
  }, [fetchData, parseCustomResponse, error]);

  return { loading, response, invoiceStatuses, errorMessage, retrieveDataWithFilters };
};

export default useRetrieveData;
