import { useState } from 'react';

const INITIAL_PAGE = 1;

const SORT_DIR_ASC = 'ASC';
const SORT_DIR_DESC = 'DESC';

const cloneWithoutProperty = (filters, columnName) => {
  return Object.keys(filters)
    .filter((f) => f !== columnName)
    .reduce((nFilters, fn) => {
      nFilters[fn] = filters[fn];
      return nFilters;
    }, {});
};

const useDataTable = ({
  initialFilters = {},
  initialSort,
  initialPage = INITIAL_PAGE,
  initialPageSize,
  allowUnsort = true,
}) => {
  const [filters, setFilters] = useState(initialFilters);
  const [sort, setSort] = useState(initialSort);
  const [page, setPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);

  // Gestisce l'ordinamento della tabella
  const handleColumnSortChange = ({ columnName }) => {
    setPage(INITIAL_PAGE);
    setSort(({ column: prevColumnName, direction: prevColumnDirection }) => {
      // Se la colonna è la stessa...
      if (columnName === prevColumnName) {
        // caso ASC → DESC
        if (prevColumnDirection === SORT_DIR_ASC) {
          return { column: columnName, direction: SORT_DIR_DESC };
        }
        // caso DESC → UNSORTED (se unsort è consentito)
        else if (prevColumnDirection === SORT_DIR_DESC && allowUnsort) {
          return {};
        }
      }
      return { column: columnName, direction: SORT_DIR_ASC };
      // caso UNSORTED → ASC o DESC → ASC
    });
  };

  // Gestisce il filtraggio della tabella
  const handleColumnFilterValueChange = ({ columnName, value }) => {
    setPage(INITIAL_PAGE);
    setFilters((filters) =>
      value === '' ? cloneWithoutProperty(filters, columnName) : { ...filters, [columnName]: value }
    );
  };

  // Gestisce il numero di record per pagina
  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    setPage(INITIAL_PAGE);
  };

  // Gestisce il cambio di pagina
  const handlePageChange = ({ page }) => {
    setPage(page);
  };

  return {
    filters,
    sort,
    page,
    pageSize,
    dataTableProps: {
      page,
      pageSize,
      sort,
      onColumnSortChange: handleColumnSortChange,
      onColumnFilterValueChange: handleColumnFilterValueChange,
      onPageSizeChange: handlePageSizeChange,
      onPageChange: handlePageChange,
    },
  };
};

export default useDataTable;
