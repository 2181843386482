import { Button, Table } from 'reactstrap';
import SalesOrderBodyTable from './SalesOrderBodyTable';

function SalesOrderDynamicTableRows({
  onAddRow,
  udM,
  rowsData,
  handleChange,
  deleteRow,
  canEdit,
  handleOnChangeDescriptionClick,
}) {
  return (
    <div className="table-responsive">
      <Table bordered style={{ tableLayout: 'fixed' }}>
        <colgroup>
          <col style={{ width: '8%' }} />
          <col style={{ width: '42%' }} />
          <col span={4} style={{ width: '12.5%' }} />
          {canEdit && <col style={{ width: 'calc(30px + 0.75rem * 2)' }} />}
        </colgroup>
        <thead>
          <tr>
            <th>N. riga</th>
            <th>Descrizione</th>
            <th>UdM</th>
            <th>Qtà</th>
            <th>
              Importo
              <br />
              unitario
            </th>
            <th>
              Importo
              <br />
              totale riga
            </th>
            {canEdit && (
              <th className="text-center align-middle">
                <Button type="button" color="primary" size="sm" onClick={onAddRow} title="Aggiungi una riga">
                  +
                </Button>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <SalesOrderBodyTable
            rowsData={rowsData}
            udM={udM}
            deleteRow={deleteRow}
            handleChange={handleChange}
            canEdit={canEdit}
            handleOnChangeDescriptionClick={handleOnChangeDescriptionClick}
          />
        </tbody>
      </Table>
    </div>
  );
}
export default SalesOrderDynamicTableRows;
