import CustomInput from 'components/shared/forms/CustomInput';
import { Button, Form } from 'reactstrap';

const convertFormDataToObject = (formData) => Object.fromEntries(formData.entries());

const DEFAULT_FN_ONSUBMIT = (purchaseOrderId, formData) => {};

const RequestChangeForm = ({ purchaseOrderId, onSubmit = DEFAULT_FN_ONSUBMIT }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = convertFormDataToObject(new FormData(e.target));
    onSubmit(purchaseOrderId, formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CustomInput type="textarea" label="Nota" name="rejectionNote" placeholder="Inserisci una nota" />
      <Button color="primary">
        <i className="fa fa-save" /> Salva
      </Button>
    </Form>
  );
};

export default RequestChangeForm;
