import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { routeFactory } from 'routes';

const CommissionGroupHeader = memo(({ id, description, customerDisplayName }) => (
  <div style={{ backgroundColor: '#eee', height: '100%' }} className="p-2">
    <div>
      <NavLink
        to={routeFactory.commissionManagement.commissions.detail(id)}
        target="_blank"
        className="text-danger font-weight-bold"
      >
        {id}
      </NavLink>
    </div>
    <div
      className="small font-weight-bold"
      style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {customerDisplayName}
    </div>
    <div className="small">{description}</div>
  </div>
));

const CommissionGroup = ({ commission, children }) => {
  return (
    <div className="border">
      <Row>
        <Col sm={3}>
          <CommissionGroupHeader
            id={commission.id}
            description={commission.description}
            customerDisplayName={commission.customerDisplayName}
          />
        </Col>
        <Col sm={9} className="pt-3">
          {children}
        </Col>
      </Row>
    </div>
  );
};

export default CommissionGroup;
