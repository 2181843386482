import Loader from "components/layout/Loader";
import CustomGroupBox from "components/shared/customGroupBox";
import useAuth from "hooks/useAuth";
import PurchaseInvoiceStatusBadge from "pages/supplier-invoices-management/components/purchase-invoice-status-badge";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ROUTES from "routes";
import { associaCommessa, associaFuoriOrdineApi, deleteDocument, deletepurchaseorderattachments, disassociaCommessa, disassociaFictitiousOrderApi, getInvoiceById, getInvoiceNote, getPurchaseOrderAttachments, getinvoicepurchaseorders, ripresaFatturaApi, setInvoiceNote, setpurchaseorderattachments, updateDocument, updateInvoicePurchaseOrder } from "services/api/invoices";
import FatturaHeader from "./fatturaHeader";
import CodiciContabiliTable from "./codiciContabili";
import { Badge, Button, Col, CustomInput, Input, ListGroup, ListGroupItem, Row, TabContent, TabPane } from "reactstrap";
import useFeedback from "hooks/useFeedback";
import { DEFAULT_ACTIVE_TAB, TAB_ASSOCIATE, TAB_ASSOCIATED, TAB_DDT } from "./constants/navbar-tabs";
import FilterCommesse from "./filterCommesse";
import RicercaItem from "./ricercaItem";
import PurchaseOrder from "./purchaseOrder";
import Navbar from "./navbar";
import CustomDialog from "components/shared/customDialog";
import { formatDate, formatStringInFloat } from "helpers/formatters";
import { calculateTableCodiciContabili, getLink, isPurchaseOrderValid } from "./commonFunctions";
import PurchaseOrderAttachment from "./purchaseOrderAttachment";
import { ACCEPTED_DOCUMENT_TYPES } from 'config';

const ManageSupplierInvoicePage = () => {
    const { purchaseInvoiceId } = useParams();
    const { company } = useAuth();
    const [loading, setLoading] = useState(true);
    const [purchaseOrderAttachments, setPurchaseOrderAttachments] = useState([]);
    const [associatedPurchaseOrderAttachments, setAssociatedPurchaseOrderAttachments] = useState([]);
    const [selectedPurchaseOrderAttachment, setSelectedPurchaseOrderAttachment] = useState("");
    const [invoice, setInvoice] = useState();
    const { error } = useFeedback();
    const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
    const [searchFilters, setSearchFilters] = useState({selectedCommission: '-1', selectedInvoicePurchaseOrder: '-1', selectedSuppliers: '-1'});
    const [searchResults, setSearchResults] = useState([]);
    const [documentazioneShowDialog, setDocumentazioneShowDialog] = useState(false);
    const [ddtWarningDialog, setDdtWarningDialog] = useState(false);
    const [ddtWarningFound, setDdtWarningFound] = useState([]);
    
    const loadData = useCallback((loadSupplier, changetTab = false) => {
        getInvoiceById(purchaseInvoiceId, company?.id)
            .then((result) => {
                if (result.ok) {
                    // console.log(result.data);
                    setInvoice({ ...result.data, showDialog: false });
                    // let attivitaRemainder = [];
                    // result.data.commissions.forEach((c) => {
                    //     c.purchaseOrders.forEach((o) => {
                    //         o.elencoAttivita.forEach((a) => {
                    //             attivitaRemainder.push(a);
                    //         });
                    //     });
                    // });
                    // // console.log(attivitaRemainder);
                    // setResiduiAttivita(attivitaRemainder);
                    let index = result.data.comboboxDataSource.suppliers.findIndex((s) => {
                        if(parseInt(s.id) === result.data.supplierId) {
                            return true;
                        }
                        return false;
                    });
                    if(index > -1 && loadSupplier) {
                        setSearchFilters(searchFilters => ({...searchFilters, selectedSuppliers: result.data.supplierId}));
                    }
                    if(changetTab) {
                        setActiveTab(TAB_ASSOCIATED);
                    }
                } else {
                    error(result.message);
                }
            });
    }, [purchaseInvoiceId, company?.id, error]);

    useEffect(() => {
        setLoading(true);
        loadData(true);
        setLoading(false);
    }, [company?.id, loadData, purchaseInvoiceId]);

    const dialogNoteOpen = () => {
        setLoading(true);
        getInvoiceNote(purchaseInvoiceId, null, null)
            .then((result) => {
                if (result.success) {
                    setInvoice((response) => ({ ...response, notes: result.data, showDialog: true }));
                } else {
                    error(result.message);
                }
            })
            .finally(() => setLoading(false));
    };

    const filterInvoiceableSalesOrders = useCallback(
        async (filters) => {

            if(filters.selectedCommission === "-1" && filters.selectedInvoicePurchaseOrder === "-1" && filters.selectedSuppliers === "-1") {
                setSearchResults([]);
                return;
            }
            
            /// Sezione dedicata al filtro delle commesse associabili
            const disableAssociatedItems = (searchResults) => {
                if (searchResults === undefined) return searchResults;
                if (invoice === undefined || invoice.commissions === undefined) return searchResults;
                searchResults.map((r) => {
                    if (invoice.commissions.some((c) => {
                            return c.commissionId === r.commissionId;
                        })) {
                        let commission = invoice.commissions.find((c) => {
                            return c.commissionId === r.commissionId;
                        });
                        r.purchaseOrders.map((pt) => {
                            let purchaseOrder = commission.purchaseOrders.some((p) => {
                                return pt.purchaseOrderId === p.purchaseOrderId && pt.paymentCertificateId === p.paymentCertificateId;
                            });
                            pt.Disabled = purchaseOrder;
                            return null;
                        });
                        let fictitiousOrder = commission.purchaseOrders.some((p) => {
                            return p.isFictitiousOrder;
                        });
                        if (fictitiousOrder) {
                            r.fictitiousOrder = fictitiousOrder;
                        }
                    }
                    return null;
                });
                return searchResults;
            };
            
            setLoading(true);
            getinvoicepurchaseorders(filters.selectedCommission, filters.selectedInvoicePurchaseOrder, filters.selectedSuppliers, company.id)
                .then((result) => {
                    if (result.success) {
                        setSearchResults(disableAssociatedItems(result.data));
                    } else {
                        setSearchResults([]);
                    }
                })
                .catch((err) => {
                    error(err);
                }).finally(() => setLoading(false));
        },
        [company.id, error, invoice]
    );

    const handleCommissionFilterChange = (commission) => {
        if(searchFilters.selectedCommission === commission.id) return;
        setSearchFilters({ ...searchFilters, selectedCommission: commission.id });
        filterInvoiceableSalesOrders({ ...searchFilters, selectedCommission: commission.id });
    };

    const handleOrderFilterChange = (salesOrder) => {
        if(searchFilters.selectedInvoicePurchaseOrder === salesOrder.id) return;
        setSearchFilters({ ...searchFilters, selectedInvoicePurchaseOrder: salesOrder.id });
        filterInvoiceableSalesOrders({ ...searchFilters, selectedInvoicePurchaseOrder: salesOrder.id });
    };
    
    const handleCustomerFilterChange = (customer) => {
        if(searchFilters.selectedSuppliers === customer.id) return;
        setSearchFilters({ ...searchFilters, selectedSuppliers: customer.id });
        filterInvoiceableSalesOrders({ ...searchFilters, selectedSuppliers: customer.id });
    };

    const handleAssociate = (PurchaseInvoiceId, purchaseOrderId, paymentCertificateId) => {
      setLoading(true);
    //   console.log(PurchaseInvoiceId, purchaseOrderId, paymentCertificateId);
      associaCommessa(PurchaseInvoiceId, purchaseOrderId, paymentCertificateId)
        .then(() => {
          /// ricaricare la fattura
            // invoice.statusId = 99;
            setLoading(true);
            loadData(false, true);
        })
        .catch((err) => {
          error(err);
        }).finally(() => {
            setLoading(false);
        });
    };
    
    const handleDisassociate = (purchaseOrder) => {
        // controlla se sono associati dei ddt, in caso mostro la dialog che chiede di mantenere o cancellare i ddt
        checkPurchaseOrderAttachments(purchaseOrder.purchaseOrderId).then((result) => {
            if(result !== undefined && result.length > 0 && (purchaseOrder.checkDDT === undefined || purchaseOrder.checkDDT)) {
                // ho trovato un ddt, apro la finestra e chiedo cosa vuole fare,
                // per comodità aggiungo la proprietà paymentCertificateId e invoicedPurchaseOrderId che mi serve nel disassociaCommessa
                result[0].invoicedPurchaseOrderId = purchaseOrder.invoicedPurchaseOrderId;
                result[0].paymentCertificateId = purchaseOrder.paymentCertificateId;
                setDdtWarningFound(result);
                setDdtWarningDialog(true);
            }
            else {

                setDdtWarningDialog(false);
                setLoading(true);
                disassociaCommessa(purchaseOrder.invoicedPurchaseOrderId, purchaseOrder.paymentCertificateId)
                    .then(() => {
                        /// ricaricare la fattura
                        loadData(false);
                    })
                    .catch((err) => {
                        error(err);
                    }).finally(() => setLoading(false));
            }
        })
    };
      
    const handleAssociateFictitiousOrder = (CommissionId) => {
        setLoading(true);
        associaFuoriOrdineApi(purchaseInvoiceId, CommissionId)
            .then(() => {
                /// ricariocare la fattura
                // invoice.statusId = 99;
                loadData(false, true);
            })
            .catch((err) => {
                error(err);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);

        if(tabId === TAB_ASSOCIATE) {
            filterInvoiceableSalesOrders({ ...searchFilters });
        }
        if(tabId === TAB_DDT) {
            loadPurchaseOrderAttachments();
        }
    };

    const UpdatePurchaseOrders = (commission, pOrders) => {
        setInvoice((response) => ({
            ...response,
            commissions: response.commissions.map((c) =>
              c.name !== commission.name ? c : {
                    ...c,
                    purchaseOrders: pOrders,
                }
            ),
          }));
    };

    const attivitaChanged = (commission, value) => {
        UpdatePurchaseOrders(
            commission,
            commission.purchaseOrders.map((o) =>
                o.name !== value.PurchaseOrder ? o : {
                    ...o,
                    attivitaAssociate: o.attivitaAssociate.map((a) =>
                        a.purchaseInvoiceOrderLineId !== value.purchaseInvoiceOrderLineId ? a : {
                                ...a,
                                selectedAttivita: value.selectedAttivita,
                                selectedConto: value.selectedConto,
                                importo: value.importo,
                                error: value.error,
                                isChanged: true
                            }
                    ),
                    isChanged: true,
                    // pristine: false,
                })
          );
    };

    const attivitaAdded = (commission, value) => {
        UpdatePurchaseOrders(
            commission,
            commission.purchaseOrders.map((o) =>
              o.name !== value.PurchaseOrder ? o : {
                    ...o,
                    attivitaAssociate: [
                        ...o.attivitaAssociate,
                        {
                            purchaseInvoiceOrderLineId: o.attivitaAssociate != null ? o.attivitaAssociate.length + 1 : 0,
                            selectedAttivita: -1,
                            selectedConto: -1,
                            importo: 0,
                            error: '',
                            isSaved: false,
                            isChanged: true
                        },
                    ],
                    isChanged: true,
                    // pristine: false,
                  }
            )
        );
    };

    const attivitaDeleted = (commission, purchaseOrder, value) => {
        let selectedAttivitaIndex = purchaseOrder.attivitaAssociate.findIndex((a) => {
            return a.purchaseInvoiceOrderLineId === value.purchaseInvoiceOrderLineId;
        });
        if (selectedAttivitaIndex > -1 &&
            purchaseOrder.attivitaAssociate[selectedAttivitaIndex].isSaved != null &&
            purchaseOrder.attivitaAssociate[selectedAttivitaIndex].isSaved) {
            UpdatePurchaseOrders(
                commission,
                commission.purchaseOrders.map((o) =>
                    o.name !== value.PurchaseOrder ? o : {
                            ...o,
                            attivitaAssociate: o.attivitaAssociate.map((a) =>
                                a.purchaseInvoiceOrderLineId !== value.purchaseInvoiceOrderLineId ? a : { ...a, isDeleted: true }
                            ),
                            // pristine: false,
                            isChanged: true
                        }
                    )
            );
        } else {
            UpdatePurchaseOrders(
                commission,
                commission.purchaseOrders.map((o) =>
                    o.name !== value.PurchaseOrder ? o : {
                        ...o,
                        attivitaAssociate: o.attivitaAssociate?.toSpliced(
                            o.attivitaAssociate?.findIndex((a) => {
                                return a.purchaseInvoiceOrderLineId === value.purchaseInvoiceOrderLineId;
                            }),
                            1
                        ),
                        // pristine: false,
            }));
        }
    };

    const approvatoreChanged = (commission, purchaseOrder, value) => {
        UpdatePurchaseOrders(commission, commission.purchaseOrders.map((o) =>
                o.name !== purchaseOrder.name ? o : { ...o, approverId: parseInt(value.target.value), /*pristine: false,*/ isChanged: true }
            ));
    };
    
    const addPurchaseOrderNote = (note, PaymentCertificateId, PurchaseOrderId, commission) => {
        setLoading(true);
        setInvoiceNote(parseInt(purchaseInvoiceId), note, PaymentCertificateId, PurchaseOrderId)
            .then((result) => {
                if (result.success) {
                    //ricarico le note
                    UpdatePurchaseOrders(commission, commission.purchaseOrders.map((o) =>
                            o.purchaseOrderId !== PurchaseOrderId || o.paymentCertificateId !== PaymentCertificateId ? o : { ...o, notes: result.data, showDialog: false }
                        ));
                } else {
                    error(result.message);
                }
            })
            .finally(() => setLoading(false));
    }
    
    const ripresaFattura = (purchaseOrder) => {
      setLoading(true);
      ripresaFatturaApi(purchaseOrder)
        .then((result) => {
            if(!result.success) {
                error(result.message);
            }
            loadData(false);
        })
        .catch((err) => {
          error(err);
        }).finally(() => setLoading(false));
    };

    /// Funzione che salva la bozza e invia
    const salvaBozzaClicked = (purchaseOrder, invia = false) => {
      purchaseOrder.Send = false;
      if (invia) {
        // Setto un flag o uno stato in modo da far capire al backend che deve mandarlo in approvazione
        // Ovviamente dovrò fare i controlli del caso
        purchaseOrder.statusId = 1;
        purchaseOrder.Send = true;
      }
      // return;
      purchaseOrder.PurchaseInvoiceId = parseInt(purchaseInvoiceId);
      setLoading(true);
      
      // sistemo l'importo, che se rimane stringa mi da errore 404
      let po = purchaseOrder;
      po.attivitaAssociate = po.attivitaAssociate.map((a) => { return {...a, importo: formatStringInFloat(a.importo)}; });

      updateInvoicePurchaseOrder(po)
        .then((result) => {
            if(!result.success) {
                error(result.message);
            }
            loadData(false);
        })
        .catch((err) => {
          error(err);
        }).finally(() => setLoading(false));
    };
    
    const isAllValidForSave = () => {
        let someChanges = invoice.commissions.some((c) => {
                return c.purchaseOrders.some((p) => { return p.isChanged })
            });
        if(invoice.commissions.length > 0 && someChanges) {
            let allValid = true;
            invoice.commissions.map((c) => {
                c.purchaseOrders.map((p) => {
                    if(p.statusId === 0 && p.isChanged) {
                        allValid = allValid && !isPurchaseOrderValid(invoice, p).salvaDisabled;
                    }
                    return null;
                });
                return null;
            });
            return allValid;
        }
        return false;
    }
    
    const isAllValidForSaveAndSend = () => {

        if(invoice.commissions.length > 0) {
            let allApproved = invoice.commissions.some((c) => c.purchaseOrders.some((p) => (p.statusId === 0)));
            if(!allApproved) {
                return false;
            }

            let allValid = true;
            invoice.commissions.map((c) => {
                c.purchaseOrders.map((p) => {
                    if(p.statusId === 0) {
                        allValid = allValid && !isPurchaseOrderValid(invoice, p).inviaDisabled;
                    }
                    return null;
                });
                return null;
            });
            return allValid;
        }
        return false;
        // return invoice.commissions.findIndex((c) => {
        //     return c.purchaseOrders.findIndex((p) => { return !isPurchaseOrderValid(p).inviaDisabled; })
        // }) === -1 && invoice.commissions.length > 0;
    }

    const dialogClosed = (value) => {
        if (!value.success) {
            setInvoice((response) => ({ ...response, showDialog: false }));
        } else {
            // salva la nota nel database
            setLoading(true);
            setInvoiceNote(purchaseInvoiceId, value.formData.get("nota"))
                .then((result) => {
                    if (result.success) {
                        //ricarico le note
                        setInvoice((response) => ({ ...response, notes: result.data, showDialog: false }));
                    } else {
                        error(result.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const saveAll = (invia = false) => {
        invoice.commissions.map((c) => {
            c.purchaseOrders.map((purchaseOrder) => {
                if (purchaseOrder.isChanged || (invia && !isPurchaseOrderValid(invoice, purchaseOrder).inviaDisabled)) {
                    purchaseOrder.Send = false;
                    if (invia) {
                        // Setto un flag o uno stato in modo da far capire al backend che deve mandarlo in approvazione
                        // Ovviamente dovrò fare i controlli del caso
                        purchaseOrder.statusId = 1;
                        purchaseOrder.Send = true;
                    }
                    // return;
                    purchaseOrder.PurchaseInvoiceId = parseInt(purchaseInvoiceId);
                    setLoading(true);
                    
                    // sistemo l'importo, che se rimane stringa mi da errore 404
                    let po = purchaseOrder;
                    po.attivitaAssociate = po.attivitaAssociate.map((a) => { return {...a, importo: formatStringInFloat(a.importo)}; });

                    updateInvoicePurchaseOrder(purchaseOrder)
                        .then((result) => {
                            if(!result.success) {
                                error(result.message);
                            }
                            loadData(false);
                        })
                        .catch((err) => {
                            error(err);
                        }).finally(() => setLoading(false));
                }
                return null;
            });
            return null;
        });
        // loadData();
    }

    const disassociaFictitiousOrder = (purchaseOrder) => {
        setDdtWarningDialog(false);
        setLoading(true);
        disassociaFictitiousOrderApi(purchaseInvoiceId, purchaseOrder.purchaseOrderId, purchaseOrder.invoicedPurchaseOrderId)
            .then(() => {
                /// ricaricare la fattura
                loadData(false);
            })
            .catch((err) => {
                error(err);
            }).finally(() => setLoading(false));
        // controlla se sono associati dei ddt, in caso mostro la dialog che chiede di mantenere o cancellare i ddt
        // checkPurchaseOrderAttachments(purchaseOrder.purchaseOrderId).then((result) => {
        //     if(result !== undefined && result.length > 0 && (purchaseOrder.checkDDT === undefined || purchaseOrder.checkDDT)) {
        //         // ho trovato un ddt, apro la finestra e chiedo cosa vuole fare,
        //         // per comodità aggiungo la proprietà invoicedPurchaseOrderId che mi serve nel disassociaFictitiousOrderApi
        //         // possono esistere più ddt associati ad uno stesso ordine!!
        //         result[0].invoicedPurchaseOrderId = purchaseOrder.invoicedPurchaseOrderId;
        //         result[0].isFictitiousOrder = true;
        //         setDdtWarningFound(result);
        //         setDdtWarningDialog(true);
        //     }
        //     else {
        //         setDdtWarningDialog(false);
        //         setLoading(true);
        //         disassociaFictitiousOrderApi(purchaseInvoiceId, purchaseOrder.purchaseOrderId, purchaseOrder.invoicedPurchaseOrderId)
        //             .then(() => {
        //                 /// ricaricare la fattura
        //                 loadData(false);
        //             })
        //             .catch((err) => {
        //                 error(err);
        //             }).finally(() => setLoading(false));
        //     }
        // })
        // setLoading(true);
        // disassociaFictitiousOrderApi(purchaseInvoiceId, purchaseOrder.purchaseOrderId, purchaseOrder.invoicedPurchaseOrderId)
        //     .then(() => {
        //         /// ricaricare la fattura
        //         loadData(false);
        //     })
        //     .catch((err) => {
        //         error(err);
        //     }).finally(() => setLoading(false));
    };

    const handleUploadFile = (e) => {
        setLoading(true);
        updateDocument(purchaseInvoiceId, e.target.files[0])
            .then((result) => {
                setInvoice((invoice) => ({ ...invoice, attachment: { name: result.data.name, editable: true }, showDialog: false }));
            })
            .catch((err) => {
                error(err);
            }).finally(() => setLoading(false));
    }
    
    const deleteFile = () => {
        setLoading(true);
        deleteDocument(purchaseInvoiceId, invoice.attachment.name)
            .then(() => {
                setInvoice((invoice) => ({ ...invoice, attachment: { name: "", editable: true }, showDialog: false }));
            })
            .catch((err) => {
                error(err);
            }).finally(() => setLoading(false));
    }

    const checkPurchaseOrderAttachments = async (purchaseOrderId) => {
        return getPurchaseOrderAttachments(purchaseInvoiceId).then((result) => {
            if (result.ok) {
                return result.data.filter((d) => d.purchaseInvoiceId !== null && d.purchaseInvoiceId.toString() === purchaseInvoiceId.toString() && d.purchaseOrderId === purchaseOrderId);
            } else {
                error(result.message);
                return undefined;
            }
        })
    }

    const loadPurchaseOrderAttachments = () => {
        setLoading(true);
        getPurchaseOrderAttachments(purchaseInvoiceId).then((result) => {
            if (result.ok) {
                setPurchaseOrderAttachments(result.data.filter((d) => d.purchaseInvoiceId === null || (d.purchaseInvoiceId !== null && d.purchaseInvoiceId.toString() !== purchaseInvoiceId.toString())));
                setAssociatedPurchaseOrderAttachments(result.data.filter((d) => d.purchaseInvoiceId !== null && d.purchaseInvoiceId.toString() === purchaseInvoiceId.toString()));
            } else {
                error(result.message);
            }
        })
        .finally(() => setLoading(false));
    }
    
    const associaDDT = (attachmentId) => {
        setpurchaseorderattachments(purchaseInvoiceId, attachmentId).then(() => {
            loadPurchaseOrderAttachments();
            setSelectedPurchaseOrderAttachment(undefined);
        })
    }

    const disassociaDDT = (attachmentId) => {
        deletepurchaseorderattachments(purchaseInvoiceId, "'" + attachmentId + "'").then(() => {
            loadPurchaseOrderAttachments();
            setSelectedPurchaseOrderAttachment(undefined);
        })
    }

    const handleDDTAssociation = (value) => {
        if(value.success) {
            disassociaDDT(ddtWarningFound.map((a) => a.attachmentId.toString()).join("', '"));
        }
        handleDisassociate({ invoicedPurchaseOrderId: ddtWarningFound[0].invoicedPurchaseOrderId, paymentCertificateId: ddtWarningFound[0].paymentCertificateId, checkDDT: value.success });
        
        // if(!ddtWarningFound[0].isFictitiousOrder) {
        //     // rilancio la handleDisassociate perchè tanto non dovrebbe trovare pià DDT associati
        // }
        // else {
        //     // rilancio la disassociaFictitiousOrder perchè tanto non dovrebbe trovare pià DDT associati
        //     disassociaFictitiousOrder({ purchaseOrderId: ddtWarningFound[0].purchaseOrderId, invoicedPurchaseOrderId: ddtWarningFound[0].invoicedPurchaseOrderId, checkDDT: value.success });
        // }
    }

    return (
        <div>
            {(loading) && <Loader />}
            <h2>
                <Link to={ROUTES.SUPPLIER_INVOICES_MANAGEMENT.INDEX}>
                <i className="fas fa-chevron-left"></i>
                <i className="fas fa-ellipsis-h"></i>
                </Link>{' '}
                Gestione fattura nr.
                {!!invoice &&
                ` ${invoice.fatturaFornitore} del ${new Date(invoice.dataFattura).toLocaleDateString('it-IT', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })}`}
            </h2>
            <div style={{ float: 'right', marginTop: '-20px' }}>
                Stato:{' '}
                <PurchaseInvoiceStatusBadge statusId={invoice?.statusId} statusName={invoice?.status} />
            </div>
            {!!invoice && (
                    <div>
                        <CustomGroupBox Title="Info generali">
                            <FatturaHeader
                                Fornitore={invoice.ragioneSociale}
                                PIVA={invoice.piva}
                                ModPagamento={invoice.modalitaPagamento} />
                            <CodiciContabiliTable CodiciContabili={calculateTableCodiciContabili(invoice)} />
                            <Row>
                                <Col>
                                    <Button
                                        color="primary"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        className="btn-sm"
                                        title="Note"
                                        onClick={() => setDocumentazioneShowDialog(true)}>
                                        <i className="far fa-file-pdf"></i> Visualizza documento
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        color="primary"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Note"
                                        className="btn-sm"
                                        onClick={() => { dialogNoteOpen() }}>
                                        <i className="fas fa-comment-alt"></i> 
                                        <Badge className='ml-1' color="secondary" style={{ top: '-2px', lineHeight: '11px' }}>{invoice.notes?.length}</Badge>
                                    </Button>
                                </Col>
                            </Row>
                        </CustomGroupBox>
                        <Navbar
                            activeTab={activeTab}
                            associateTabDisabled={invoice.statusId > 0}
                            onTabChange={handleTabChange} />
                        <TabContent activeTab={activeTab} className="py-2">
                            <TabPane tabId={TAB_ASSOCIATE}>
                                <FilterCommesse
                                    Commisions={invoice.comboboxDataSource.commissions}
                                    InvoicePurchaseOrders={invoice.comboboxDataSource.invoicePurchaseOrders}
                                    Suppliers={invoice.comboboxDataSource.suppliers}
                                    SelectedCommission={searchFilters.selectedCommission}
                                    SelectedInvoicePurchaseOrder={searchFilters.selectedInvoicePurchaseOrder}
                                    SelectedSuppliers={searchFilters.selectedSuppliers}
                                    OnCommissionFiltered={(value) => handleCommissionFilterChange(value)}
                                    OnInvoicePurchaseOrdersFiltered={(value) => handleOrderFilterChange(value)}
                                    Disabled={invoice.statusId > 0}
                                    OnSuppliersFiltered={(value) => handleCustomerFilterChange(value)} />
                                    {searchResults && (
                                        <div style={{ marginTop: '25px' }}>
                                            {searchResults.length > 0 ? (
                                                searchResults.map((r, index) => {
                                                    return (
                                                    <RicercaItem
                                                        key={r.commissionId + '-' + index}
                                                        Commission={r}
                                                        associa={(purchaseOrderId, paymentCertificateId) => {
                                                            handleAssociate(purchaseInvoiceId, purchaseOrderId, paymentCertificateId);
                                                        }}
                                                        disassocia={(purchaseOrderId, paymentCertificateId) => {
                                                            handleDisassociate(purchaseInvoiceId, purchaseOrderId, paymentCertificateId);
                                                        }}
                                                        associaFuoriOrdine={() => handleAssociateFictitiousOrder(r.commissionId)}
                                                    />
                                                    );
                                                })) : (
                                                    <div className="alert alert-warning border border-warning">
                                                        <i className="fa fa-exclamation-triangle" /> 
                                                        {(searchFilters.selectedCommission === "-1" && searchFilters.selectedInvoicePurchaseOrder === "-1" && searchFilters.selectedSuppliers === "-1") ? <span className="ml-1">Selezionare almeno un filtro.</span> : <span className="ml-1">La ricerca non ha prodotto nessun risultato.</span> }
                                                    </div>)
                                            }
                                        </div>
                                    )}
                            </TabPane>
                            <TabPane tabId={TAB_ASSOCIATED}>
                                {invoice.commissions?.map((c, index) => {
                                        return (
                                            <CustomGroupBox
                                                key={c.name + '-' + index}
                                                Title={`${c.commissionId} - ${c.name}`}
                                                isExpander={true}
                                                isExpanded={index === 0}>
                                                <Row>
                                                    <Col sm="3">
                                                        <CustomGroupBox Title={c.commissionId} isHeaderLink={true} link={'/commesse/' + c.commissionId + '/commessa'}>
                                                            <div style={STYLES.commissionBox.secondaryInfo}>Cliente: <b className="text-primary">{c.customerDisplayName}</b></div>
                                                            <div style={STYLES.commissionBox.secondaryInfo}>{c.name}</div>
                                                        </CustomGroupBox>
                                                    </Col>
                                                    <Col>
                                                        {
                                                            c.purchaseOrders.map((o, index) => {
                                                                return <PurchaseOrder
                                                                            key={o.purchaseOrderId + "-"+ index.toString()}
                                                                            purchaseOrder={o}
                                                                            purchaseOrderLink={getLink(c, o)}
                                                                            attivitaChanged={(value) => attivitaChanged(c, value)}
                                                                            attivitaAdded={(value) => attivitaAdded(c, value)}
                                                                            attivitaDeleted={(purchaseOrder, value) => attivitaDeleted(c, purchaseOrder, value)}
                                                                            isPurchaseOrderValid={() => { return isPurchaseOrderValid(invoice, o); }}
                                                                            calculateTableCodiciContabili={() => calculateTableCodiciContabili(invoice)}
                                                                            approvatoreChanged={(purchaseOrder, value) => approvatoreChanged(c, purchaseOrder, value)}
                                                                            addPurchaseOrderNote={(note, PaymentCertificateId, PurchaseOrderId) => addPurchaseOrderNote(note, PaymentCertificateId, PurchaseOrderId, c)}
                                                                            ripresaFattura={ripresaFattura}
                                                                            // disassocia={() => handleDisassociate(o)}
                                                                            disassocia={() => !o.isFictitiousOrder ? handleDisassociate(o) : disassociaFictitiousOrder(o) }
                                                                            salvaBozzaClicked={() => salvaBozzaClicked(o)}
                                                                            // salvaBozzaClicked={() => saveAll(false)}
                                                                            inviaInApprovazione={() => salvaBozzaClicked(o, true)} /> 
                                                            })
                                                        }
                                                    </Col>
                                                </Row>
                                            </CustomGroupBox>
                                        )
                                    })
                                }
                                <Row>
                                    <Col>
                                        <Button
                                            onClick={() => saveAll(false)}
                                            disabled={!isAllValidForSave()}
                                            color="primary"
                                            data-toggle="tooltip" title="Tooltip on top"
                                            className="mt-1 btn-sm">
                                            <i className="fa fa-save"></i> Salva tutto
                                        </Button>
                                        <Button
                                            onClick={() => saveAll(true)}
                                            disabled={!isAllValidForSaveAndSend()}
                                            color="primary"
                                            className="mt-1 ml-1 btn-sm">
                                            <i className="fa fa-paper-plane"></i> Salva tutto e invia
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="alert alert-warning border border-warning mt-1"><i className="fa fa-exclamation-triangle mr-1"></i>ATTENZIONE!<br/>I pulsanti globali ignorano gli ordini non validi e\o non modificati,<br/>se un ordine non è valido ma modificato al momento del salvataggio le modifiche potrebbero andare perse</p>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={TAB_DDT}>
                                <PurchaseOrderAttachment
                                    associatedPurchaseOrderAttachments={associatedPurchaseOrderAttachments}
                                    setSelectedPurchaseOrderAttachment={(p) => { console.log(p); setSelectedPurchaseOrderAttachment(p) } }
                                    purchaseOrderAttachments={purchaseOrderAttachments}
                                    selectedPurchaseOrderAttachment={selectedPurchaseOrderAttachment}
                                    associaDDT={() => associaDDT(selectedPurchaseOrderAttachment.attachmentId)}
                                    disassociaDDT={(attachmentId) => disassociaDDT(attachmentId)}></PurchaseOrderAttachment>
                            </TabPane>
                        </TabContent>
                        {ddtWarningDialog && <CustomDialog
                            Ok={true}
                            TextOk={'Disassocia l\'ordine e il DDT'}
                            Cancel={true}
                            Title={'Attenzione'}
                            TextCancel={'Disassocia l\'ordine, mantieni il DDT'}
                            OnClose={(value) => handleDDTAssociation(value)}
                            >
                                <h4 style={{ textAlign: 'center', padding: '20px' }}>Disassociare i seguenti DDT
                                <ul style={{ margin: '0px', listStyle: 'inside' }}>{ddtWarningFound.map((ddt) => <li key={ddt.attachmentId}>{ddt.attachmentIdDisplayName}</li>)}</ul>
                                relativi a questo ordine?</h4>
                            </CustomDialog>}
                    </div>
                )
            }
            {documentazioneShowDialog && (
                <CustomDialog
                    Ok={false}
                    Cancel={true}
                    Title={'Visualizza fattura'}
                    OnClose={() => {
                            setDocumentazioneShowDialog(false);
                        }}>
                    <h5>Gestisci il documento presente per la fattura</h5>
                    { invoice.attachment.name != null && invoice.attachment.name !== "" &&
                        <>
                            { invoice.attachment.editable ? <span className='mr-1'>{invoice.attachment.name}</span> : <span className='mr-1'>{invoice.attachment.name.split(/(\\|\/)/g).pop()}</span>}
                            <Button
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Elimina"
                                color="danger"
                                className="mr-1 btn-sm"
                                disabled={!invoice.attachment.editable}
                                onClick={deleteFile}>
                                <i className="fa fa-trash"></i>
                            </Button>
                            <Link
                                title="Visualizza"
                                className="mr-1 btn btn-primary btn-sm"
                                target='_blank'
                                to={`/api/invoice/getattachment?invoiceId=${purchaseInvoiceId}`}>
                                <i className="fa fa-eye"></i>
                            </Link>
                        </>
                    }
                    {
                        (invoice.attachment.name == null || invoice.attachment.name === "") &&
                        <CustomInput
                            type="file"
                            accept={ACCEPTED_DOCUMENT_TYPES}
                            label="Scegli il file"
                            name="file"
                            onChange={handleUploadFile}
                            required
                            id="itemAttach"
                        />
                    }
                    </CustomDialog>
                )}
            {!!invoice && invoice.showDialog &&  (
                <CustomDialog
                    Ok={true}
                    TextOk={'Inserisci Nota'}
                    Cancel={true}
                    TextCancel={'Esci'}
                    Title={'Note'}
                    OnClose={(value) => {
                        dialogClosed(value);
                    }}>
                        <ListGroup style={{ display: 'block', maxHeight: '400px', overflow: 'auto' }}>
                            {invoice.notes?.map((n, index) => {
                                return (
                                    <ListGroupItem className='nota-elem' key={index} action>
                                        <div style={{ lineHeight: '30px', maxHeight: '200px', overflow: 'auto' }}>{n.note}</div>
                                        <Badge color="light" className='message-badge' style={STYLES.messageBadge}>
                                            {n.username} {formatDate(n.creationDate)}
                                        </Badge>
                                    </ListGroupItem>
                                );
                            })}
                        </ListGroup>
                        <Input
                            style={{ width: '100%', height: '100%', marginTop: '5px' }}
                            id="nota"
                            name="nota"
                            type="textarea"
                            required />
                </CustomDialog>
            )}
        </div>
    );
};

const STYLES = {
    commissionBox: {
      secondaryInfo: {
        fontSize: '.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    bold: {
      fontWeight: 'bold',
    },
    relativeBadge: {
      margin: '-37px -20px 17px 20px',
      display: 'block',
    },
    table: {
      display: 'grid',
      gridTemplateColumns: '300px 1fr',
    },
    row: {},
};
  
export default ManageSupplierInvoicePage;