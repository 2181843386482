import UnsavedChangesAwareNavLink from 'components/shared/navigation/UnsavedChangesAwareNavLink';
import ROLE_PERMISSIONS from 'constants/role-permissions';
import useAuth from 'hooks/useAuth';
import { createContext, useContext, useState } from 'react';

import ROUTES from 'routes';

// NOTE: i componenti della sidebar sono stati sviluppati nel minor tempo possibile e le soluzioni sono vergognosamente grezze (ma funzionanti).
// In futuro magari verranno riviste

const TOGGLABLE_MENU_VOICES = {
  BI: 'BI',
  HOURS: 'HRS',
};

const CollapsableMenuContext = createContext({ close: () => {} });

const SidebarLink = ({ to, icon, text, onClick = () => {}, visible = true }) =>
  visible && (
    <li className="nav-item">
      <UnsavedChangesAwareNavLink to={to} onClick={onClick} runClickHandlerAsCallback={false}>
        <i className={`fas fa-${icon}`} /> <span>{text}</span>
      </UnsavedChangesAwareNavLink>
    </li>
  );

const SubmenuTitle = ({ text }) => <h6 className="collapse-header">{text}</h6>;

const CollapsableSidebarVoice = ({
  text,
  icon,
  submenuTitle,
  visible,
  toggled,
  onClick,
  parentId,
  children,
  menuId,
}) => {
  return (
    visible && (
      <CollapsableMenuContext.Provider value={{ close: () => onClick(menuId) }}>
        <li className="nav-item">
          <div
            className={`nav-link${toggled ? '' : ' collapsed'}`}
            data-toggle="collapse"
            data-target={'#' + menuId}
            aria-expanded={toggled}
            aria-controls={menuId}
            onClick={() => onClick(menuId)}
          >
            <i className={icon} /> <span>{text}</span>
          </div>
          <div
            id="collapseBI"
            className={`collapse${toggled ? ' show' : ''}`}
            aria-labelledby="headingBI"
            data-parent={'#' + parentId}
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {submenuTitle && <h6 className="collapse-header">{submenuTitle}</h6>}
              {children}
            </div>
          </div>
        </li>
      </CollapsableMenuContext.Provider>
    )
  );
};

const SubmenuVoiceSPALink = ({ to, icon, text, visible }) => {
  const { close } = useContext(CollapsableMenuContext);
  return (
    visible && (
      <UnsavedChangesAwareNavLink className="collapse-item" to={to} onClick={close} runClickHandlerAsCallback={false}>
        <i className={icon}></i> <span>{text}</span>
      </UnsavedChangesAwareNavLink>
    )
  );
};

const ACCORDION_SIDEBAR_ID = 'accordionSidebar';

const Sidebar = (props) => {
  const { toggled, onToggle } = props;

  const [toggledMenuVoice, setToggledMenuVoice] = useState();

  const { permissions } = useAuth();

  const canManageCommissions = permissions.includes(ROLE_PERMISSIONS.MANAGE_COMMISSIONS);
  const canManagePurchaseInvoices = permissions.includes(ROLE_PERMISSIONS.MANAGE_PURCHASE_INVOICES);
  const canManageSalesInvoices = permissions.includes(ROLE_PERMISSIONS.MANAGE_SALES_INVOICES);
  const canExportInvoiceableSalesOrders = permissions.includes(ROLE_PERMISSIONS.EXPORT_INVOICEABLE_SALES_ORDERS);
  const canConsultPurchaseOrders = permissions.includes(ROLE_PERMISSIONS.CONSULT_PURCHASE_ORDERS);
  const canConsultSalesOrders = permissions.includes(ROLE_PERMISSIONS.CONSULT_SALES_ORDERS);
  const canConsultPurchaseInvoices = permissions.includes(ROLE_PERMISSIONS.CONSULT_PURCHASE_INVOICES);
  const canConsultSalesInvoices = permissions.includes(ROLE_PERMISSIONS.CONSULT_SALES_INVOICES);
  const canManageDDt = permissions.includes(ROLE_PERMISSIONS.DDT_MANAGEMENT);

  const canViewBI = permissions.includes(ROLE_PERMISSIONS.VIEW_BI);

  const canApproveTimesheet = permissions.includes(ROLE_PERMISSIONS.APPROVE_TIMESHEET);
  const canBlockTimesheet = permissions.includes(ROLE_PERMISSIONS.BLOCK_TIMESHEET);

  const activeInvoicingSubmenuVisible =
    canManageSalesInvoices || canConsultSalesInvoices || canExportInvoiceableSalesOrders;
  const passiveInvoicingSubmenuVisible = canManagePurchaseInvoices || canConsultPurchaseInvoices;

  const invoicingMenuVisible = activeInvoicingSubmenuVisible || passiveInvoicingSubmenuVisible;

  const handleCollapsableVoiceClick = (menuId) => {
    setToggledMenuVoice((toggledMenuVoice) => (toggledMenuVoice === menuId ? undefined : menuId));
  };

  const handleCloseVoice = () => {
    setToggledMenuVoice(undefined);
  };

  return (
    <ul
      className={`navbar-nav bg-gradient-dark sidebar sidebar-dark accordion${toggled ? ' toggled' : ''}`}
      id={ACCORDION_SIDEBAR_ID}
    >
      <li className="nav-item">
        <UnsavedChangesAwareNavLink to="/" end className="nav-link" onClick={handleCloseVoice}>
          <i className="fas fa-fw fa-tachometer-alt" style={{ fontSize: '2.5em' }}></i> <span>Dashboard</span>
        </UnsavedChangesAwareNavLink>
      </li>
      <li>
        <hr className="sidebar-divider" />
      </li>
      {canViewBI && (
        <li className="nav-item">
          <div
            className={`nav-link${toggledMenuVoice === TOGGLABLE_MENU_VOICES.BI ? '' : ' collapsed'}`}
            data-toggle="collapse"
            data-target="#collapseBI"
            aria-expanded={toggledMenuVoice === TOGGLABLE_MENU_VOICES.BI}
            aria-controls="collapseBI"
            onClick={(e) => {
              e.preventDefault();
              setToggledMenuVoice((curr) => (curr !== TOGGLABLE_MENU_VOICES.BI ? TOGGLABLE_MENU_VOICES.BI : undefined));
            }}
          >
            <i className="fas fa-fw fa-tachometer-alt"></i> <span>BI</span>
          </div>
          <div
            id="collapseBI"
            className={`collapse${toggledMenuVoice === TOGGLABLE_MENU_VOICES.BI ? ' show' : ''}`}
            aria-labelledby="headingBI"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">REPORT:</h6>
              <a className="collapse-item" href="/mod75">
                <i className="fas fa-table"></i> <span>Mod. 75</span>
              </a>
              <a className="collapse-item" href="/stress">
                <i className="fas fa-calculator"></i> <span>Stress</span>
              </a>
              <a className="collapse-item" href="/cashflow">
                <i className="fas fa-chart-line"></i>{' '}
                <span title="Controllo Produzione &amp; Cash flow">Controllo Produzione &amp; Cash flow</span>
              </a>
              {/* <a className="collapse-item" href="/fatturedafare">
                <i className="fas fa-file-invoice"></i> <span title="Fatture da fare">Fatture da fare</span>
              </a>
              <a className="collapse-item" href="/schedacliente/fattureattive">
                <i className="fas fa-file-invoice-dollar"></i>{' '}
                <span title="Fatture da fare">Scheda clienti FT attive commessa</span>
              </a>
              <a className="collapse-item" href="/PlanningRisorse">
                <i className="fas fa-users"></i> <span>Planning Risorse</span>
              </a>
              <a className="collapse-item" href="/ControlloCommessa">
                <i className="fas fa-building"></i>{' '}
                <span title="Controllo ore per commessa">Controllo ore per commessa</span>
              </a>
              <a className="collapse-item" href="/LastUpdateAlert">
                <i className="fas fa-info-circle"></i> <span>Ultimi aggiornamenti</span>
              </a> */}
            </div>
          </div>
        </li>
      )}
      <li className="nav-item">
        <div
          className={`nav-link${toggledMenuVoice === TOGGLABLE_MENU_VOICES.HOURS ? '' : ' collapsed'}`}
          data-toggle="collapse"
          data-target="#collapseOre"
          aria-expanded={toggledMenuVoice === TOGGLABLE_MENU_VOICES.HOURS}
          aria-controls="collapseOre"
          onClick={(e) => {
            e.preventDefault();
            setToggledMenuVoice((curr) =>
              curr !== TOGGLABLE_MENU_VOICES.HOURS ? TOGGLABLE_MENU_VOICES.HOURS : undefined
            );
          }}
        >
          <i className="fas fa-business-time"></i> <span>Ore</span>
        </div>
        <div
          id="collapseOre"
          className={`collapse${toggledMenuVoice === TOGGLABLE_MENU_VOICES.HOURS ? ' show' : ''}`}
          aria-labelledby="headingOre"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">ATTIVITÀ:</h6>
            <a className="collapse-item" href="/ore">
              <i className="fas fa-user-clock"></i> <span>Consuntivo</span>
            </a>
            <a className="collapse-item" href="/ore/richieste">
              <i className="fas fa-user-edit"></i> <span>Richieste</span>
            </a>

            {canApproveTimesheet && (
              <>
                <a className="collapse-item" href="/ore/approvazione">
                  <i className="fas fa-calendar-check"></i>{' '}
                  <span title="Approvazione consuntivi">Approvazione consuntivi</span>
                </a>
                <a className="collapse-item" href="/ore/abilitazioni">
                  <i className="fas fa-users-cog"></i> <span>Abilitazione utenti</span>
                </a>
              </>
            )}

            {canBlockTimesheet && (
              <a className="collapse-item" href="/ore/blocco">
                <i className="fas fa-user-lock"></i> <span title="Blocco consuntivi">Blocco consuntivi</span>
              </a>
            )}
          </div>
        </div>
      </li>

      <SidebarLink
        to={ROUTES.COMMISSION_MANAGEMENT.INDEX}
        icon="sitemap"
        text="Gestione commesse"
        visible={canManageCommissions}
        onClick={handleCloseVoice}
      />

      <CollapsableSidebarVoice
        text="Gestione ordini"
        icon="fa fa-file-alt"
        menuId="orderManagement"
        parentId="accordionSidebar"
        visible={canConsultPurchaseOrders || canConsultSalesOrders}
        toggled={toggledMenuVoice === 'orderManagement'}
        onClick={handleCollapsableVoiceClick}
      >
        <SubmenuTitle text="Attività:" />
        <SubmenuVoiceSPALink
          to={ROUTES.PURCHASE_ORDERS_EXPORT.INDEX}
          icon="fa fa-file-alt"
          text="Elenco ordini fornitori"
          visible={canConsultPurchaseOrders}
        />
        <SubmenuVoiceSPALink
          to={ROUTES.SALES_ORDERS_EXPORT.INDEX}
          icon="fa fa-file-alt"
          text="Elenco ordini clienti"
          visible={canConsultSalesOrders}
        />
      </CollapsableSidebarVoice>

      <SidebarLink
        to={ROUTES.MANAGE_DDT}
        icon="truck-loading"
        text="Gestione DDT"
        visible={canManageDDt}
        onClick={handleCloseVoice}
      />

      <CollapsableSidebarVoice
        text="Contabilità"
        icon="fa fa-file-invoice"
        menuId="contabilita"
        parentId="accordionSidebar"
        visible={invoicingMenuVisible}
        toggled={toggledMenuVoice === 'contabilita'}
        onClick={handleCollapsableVoiceClick}
      >
        {passiveInvoicingSubmenuVisible && (
          <>
            <SubmenuTitle text="Ciclo passivo:" />
            <SubmenuVoiceSPALink
              icon="fa fa-file-invoice"
              text="Gestione fatture fornitori"
              visible={canManagePurchaseInvoices}
              to={ROUTES.SUPPLIER_INVOICES_MANAGEMENT.INDEX}
            />
            <SubmenuVoiceSPALink
              to={ROUTES.SUPPLIER_INVOICES_MANAGEMENT.INVOICE_CONSULTATION}
              icon="fas fa-search-dollar"
              text="Consultazione fatture fornitori"
              visible={canConsultPurchaseInvoices}
            />
          </>
        )}
        {activeInvoicingSubmenuVisible && (
          <>
            <SubmenuTitle text="Ciclo attivo:" />
            <SubmenuVoiceSPALink
              icon="fa fa-file-invoice"
              text="Gestione fatture clienti"
              visible={canManageSalesInvoices}
              to={ROUTES.SALES_INVOICES_MANAGEMENT.INDEX}
            />
            <SubmenuVoiceSPALink
              to={ROUTES.SALES_INVOICES_EXPORT.INDEX}
              icon="fas fa-search-dollar"
              text="Consultazione fatture clienti"
              visible={canConsultSalesInvoices}
            />
            <SubmenuVoiceSPALink
              to={ROUTES.EXPORT_INVOICEABLE_SALES_ORDERS.INDEX}
              icon="fa fa-file-export"
              text="Richieste fatturazione clienti"
              visible={canExportInvoiceableSalesOrders}
            />
          </>
        )}
      </CollapsableSidebarVoice>
      <li>
        <hr className="sidebar-divider d-none d-md-block" />
      </li>

      <li>
        <div className="text-center d-none d-md-block">
          <button className="rounded-circle border-0" id="sidebarToggle" onClick={onToggle}></button>
        </div>
      </li>
    </ul>
  );
};

export default Sidebar;
