const BudgetTableColumns = ({ periods, collapsed }) => {
  return (
    <colgroup>
      <col style={{ minWidth: '90px' }} />
      <col style={{ minWidth: '60px' }} />
      <col style={{ minWidth: '46px' }} />
      <col style={{ minWidth: '250px' }} />
      <col span={2} style={{ minWidth: '110px' }} />
      <col span={2 + (collapsed ? 0 : periods.length * 2)} style={{ minWidth: '110px', textAlign: 'center' }} />
    </colgroup>
  );
};

export default BudgetTableColumns;