import { useState } from 'react';
import { Card, CardBody, CardHeader, FormGroup, Input, Row, Col } from 'reactstrap';
import { OTHER_DELIVERY_PLACE_OPTION_VALUE } from './constants';
import CustomInput from 'components/shared/forms/CustomInput';

const DeliveryPlaceDetail = ({ label, value, className = 'mb-2' }) => (
  <Row className={className}>
    <Col xs={4} style={{ lineHeight: 1 }}>
      {label}
    </Col>
    <Col xs={8} style={{ lineHeight: 1 }}>
      {value}
    </Col>
  </Row>
);

const DeliveryPlaceFormSection = ({ readonly = false, deliveryPlaces, initialValues }) => {
  const { site, otherDeliveryPlace } = initialValues;
  const [selectedDeliveryPlace, setSelectedDeliveryPlace] = useState(deliveryPlaces.find((s) => s.id === site));

  const handleSiteChange = (e) => {
    const selectedDeliveryPlace = parseInt(e.target.value);
    setSelectedDeliveryPlace(deliveryPlaces.find((s) => s.id === selectedDeliveryPlace));
  };

  return (
    <Card className="mb-1">
      <fieldset>
        <CardHeader className="p-2">Luogo di consegna</CardHeader>
        <CardBody className="p-2">
          <FormGroup className="mb-2">
            <Input
              type="select"
              name="site"
              value={selectedDeliveryPlace.id}
              onChange={handleSiteChange}
              disabled={readonly}
            >
              {deliveryPlaces.map((site) => (
                <option key={`site-${site.id}`} value={site.id}>
                  {site.displayName}
                </option>
              ))}
            </Input>
          </FormGroup>

          {selectedDeliveryPlace.id === OTHER_DELIVERY_PLACE_OPTION_VALUE && (
            <CustomInput
              type="text"
              name="otherDeliveryPlace"
              label="Specifica il luogo di consegna"
              required
              defaultValue={otherDeliveryPlace}
              groupClassName="mb-0"
            />
          )}
          {selectedDeliveryPlace.localityLines.length > 0 && (
            <DeliveryPlaceDetail label="Luogo" value={selectedDeliveryPlace.localityLines[0]} />
          )}
          {selectedDeliveryPlace.localityLines.length > 1 &&
            selectedDeliveryPlace.localityLines
              .slice(1)
              .map((localityLine, index) => (
                <DeliveryPlaceDetail
                  key={`localityLine_${selectedDeliveryPlace.id}_${index + 1}`}
                  label=""
                  value={localityLine}
                />
              ))}

          {selectedDeliveryPlace.addressLines.length > 0 && (
            <DeliveryPlaceDetail label="Indirizzo" value={selectedDeliveryPlace.addressLines[0]} />
          )}
          {selectedDeliveryPlace.addressLines.length > 1 &&
            selectedDeliveryPlace.addressLines
              .slice(1)
              .map((addressLine, index) => (
                <DeliveryPlaceDetail
                  key={`addressLine_${selectedDeliveryPlace.id}_${index + 1}`}
                  label=""
                  value={addressLine}
                />
              ))}
          {selectedDeliveryPlace.municipality && (
            <DeliveryPlaceDetail label="Comune" value={selectedDeliveryPlace.municipality} />
          )}
          {selectedDeliveryPlace.frazione && (
            <DeliveryPlaceDetail label="Frazione" value={selectedDeliveryPlace.frazione} />
          )}
        </CardBody>
      </fieldset>
    </Card>
  );
};

export default DeliveryPlaceFormSection;
