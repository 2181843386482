import Loader from 'components/layout/Loader';
import { SALES_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import GUARANTEE_WITHHOLDING_TYPES from 'constants/guarantee-withholding-types';
import { roundNumber } from 'helpers/numbers';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Button, Col, FormText, Row } from 'reactstrap';
import { routeFactory } from 'routes';
import {
  approveSalesOrderPaymentCertificate,
  fetchSalesOrderPaymentCertificate,
  requestSalesOrderPaymentCertificateApproval,
} from 'services/api/sales-order';
import SalesOrderSummary from '../components/SalesOrderSummary';
import PaymentCertificatesTable from '../components/list';
import PaymentCertificateForm from '../components/form';
import PaymentCertificateStatusBadge from 'components/commission-management/payment-certificates/status-badge';

const mapPaymentCertificateDetailToFormData = (detail) => ({
  type: detail.type,
  amount: detail.amount,
  date: detail.date,
  isRedetermination: detail.isRedetermination,
  redeterminationAmount: detail.redeterminationAmount,
  redeterminationNote: detail.redeterminationNote,
  advanceWithholdingPercentage: detail.advanceWithholdingPercentage,
  advanceWithholdingAmount: detail.advanceWithholdingAmount,
  note: detail.note,
  isRecoverTotalAdvance: !detail.advanceWithholdingPercentage && !!detail.advanceWithholdingAmount,
});

const mapInitialDataToPageModel = (initialData) => ({
  status: initialData.detail.status,
  editable: initialData.detail.editable,
  approvalRequestable: initialData.detail.approvalRequestable,
  approvable: initialData.detail.approvable,
  formData: mapPaymentCertificateDetailToFormData(initialData.detail),
  order: {
    ...initialData.order,
    orderDate: new Date(Date.parse(initialData.order.orderDate)),
  },
  advanceWithholdingAmountRemainder: roundNumber(
    initialData.previousPaymentCertificates.reduce(
      (advanceWithholdingAmountRemainder, paymentCertificate) =>
        paymentCertificate.number !== initialData.detail.paymentCertificateId
          ? advanceWithholdingAmountRemainder + paymentCertificate.advance
          : advanceWithholdingAmountRemainder,
      0
    ),
    2
  ),
  guaranteeWithholdingAmountRemainder:
    initialData.order.guaranteeWithholdingId === GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE &&
    initialData.order.guaranteeWithholdingPercentage > 0
      ? roundNumber(
          initialData.previousPaymentCertificates.reduce(
            (remainder, paymentCertificate) => remainder + paymentCertificate.guaranteeWithholdingValue,
            0
          ),
          2
        )
      : 0,
  amountRemainder: roundNumber(
    initialData.order.amount -
      initialData.previousPaymentCertificates.reduce(
        (remainder, paymentCertificate) =>
          paymentCertificate.number !== initialData.detail.paymentCertificateId
            ? remainder + paymentCertificate.amount
            : remainder,
        0
      ),
    2
  ),
  previousPaymentCertificates: initialData.previousPaymentCertificates.map((pc) => ({
    ...pc,
    date: new Date(Date.parse(pc.date)),
  })),
});

const SalesOrderPaymentCertificateDetailPage = () => {
  const [paymentCertificateInfo, setPaymentCertificateInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { commissionId, salesOrderId, ...otherParams } = useParams();
  const paymentCertificateId = parseInt(otherParams.paymentCertificateId);

  const { success, error } = useFeedback();
  const { hasPermission } = useCommissionPermissions();

  const canEdit =
    hasPermission(SALES_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.EDIT) && (paymentCertificateInfo?.editable ?? false);
  const canApprove =
    hasPermission(SALES_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.APPROVE_REJECT) &&
    (paymentCertificateInfo?.approvable ?? false);
  const canSelfApprove =
    hasPermission(SALES_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.SELF_APPROVE) &&
    (paymentCertificateInfo?.approvalRequestable ?? false);
  const canRequestApproval =
    hasPermission(SALES_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.REQUEST_APPROVAL) &&
    !canSelfApprove &&
    (paymentCertificateInfo?.approvalRequestable ?? false);

  useEffect(() => {
    setLoading(true);
    console.log(salesOrderId);
    fetchSalesOrderPaymentCertificate(salesOrderId, paymentCertificateId)
      .then((response) => {
        console.log(response)
        if (response.ok) {
          setPaymentCertificateInfo(mapInitialDataToPageModel(response.data));
        } else {
          setErrorMessage(response.message);
        }
      })
      .finally(() => setLoading(false));
  }, [paymentCertificateId, salesOrderId]);

  const handleOnRequestApprovalClick = async () => {
    setSubmitting(true);
    const response = await requestSalesOrderPaymentCertificateApproval(salesOrderId, paymentCertificateId);
    if (response.ok) {
      const { editable, approvalRequestable, approvable, status } = response.data;
      const updateData = { editable, approvalRequestable, approvable, status };
      // console.debug({ ...paymentCertificateInfo, ...updateData });
      setPaymentCertificateInfo((pc) => ({ ...pc, ...updateData }));
      success(
        `La richiesta di approvazione per il certificato di pagamento n° ${paymentCertificateId} dell'ordine ${salesOrderId} è stata presa in carico.`
      );
    } else {
      error(response.message);
    }
    setSubmitting(false);
  };

  const handleOnApproveClick = async () => {
    setSubmitting(true);
    const response = await approveSalesOrderPaymentCertificate(salesOrderId, paymentCertificateId);
    if (response.ok) {
      const { editable, approvalRequestable, approvable, status } = response.data;
      const updateData = { editable, approvalRequestable, approvable, status };
      // console.debug({ ...paymentCertificateInfo, ...updateData });
      setPaymentCertificateInfo((pc) => ({ ...pc, ...updateData }));
      success(`Il certificato di pagamento n° ${paymentCertificateId} dell'ordine ${salesOrderId} è stato approvato.`);
    } else {
      error(response.message);
    }
    setSubmitting(false);
  };

  return (
    <>
      {(loading || submitting) && <Loader />}
      {paymentCertificateInfo && (
        <>
          <Row className="mb-4">
            <Col xs={10}>
              <h3>Certificato di pagamento n° {paymentCertificateId}</h3>
            </Col>
            <Col xs={2} className="text-right">
              <NavLink
                to={routeFactory.commissionManagement.salesOrders.paymentCertificates.index(commissionId, salesOrderId)}
                style={{ fontSize: '2rem' }}
                title={`Torna all'elenco dei certificati di pagamento dell'ordine ${salesOrderId}`}
                className="text-danger"
              >
                &times;
              </NavLink>
            </Col>
          </Row>
          <SalesOrderSummary order={paymentCertificateInfo.order} />
          {paymentCertificateInfo.previousPaymentCertificates.length > 0 && (
            <div className="mb-4">
              <h4 className="mb-3">Certificati emessi</h4>
              <PaymentCertificatesTable
                guaranteeWithholdingTableHeaderText={paymentCertificateInfo.order.guaranteeWithholdingTableHeaderText}
                paymentCertificates={paymentCertificateInfo.previousPaymentCertificates}
              />
            </div>
          )}
          <div>
            <p>
              Stato:{' '}
              <PaymentCertificateStatusBadge
                statusId={paymentCertificateInfo.status.id}
                statusName={paymentCertificateInfo.status.name}
              />
            </p>
            <PaymentCertificateForm
              initialData={mapPaymentCertificateDetailToFormData(paymentCertificateInfo.formData)}
              readOnly
              guaranteeWithholding={{
                typeId: paymentCertificateInfo.order.guaranteeWithholdingId,
                percentage: paymentCertificateInfo.order.guaranteeWithholdingPercentage || 0,
              }}
              advanceTypeAllowed={paymentCertificateId === 1}
              amountRemainder={paymentCertificateInfo.amountRemainder}
              advanceWithholdingAmountRemainder={paymentCertificateInfo.advanceWithholdingAmountRemainder}
              guaranteeWithholdingAmountRemainder={paymentCertificateInfo.guaranteeWithholdingAmountRemainder}
            />
            <div>
              {canEdit && (
                <NavLink
                  to={routeFactory.commissionManagement.salesOrders.paymentCertificates.edit(
                    commissionId,
                    salesOrderId,
                    paymentCertificateId
                  )}
                  className="btn btn-primary mr-2"
                >
                  Modifica
                </NavLink>
              )}
              {canRequestApproval && (
                <Button type="button" color="warning" className="mr-2" onClick={handleOnRequestApprovalClick}>
                  Richiedi approvazione
                </Button>
              )}
              {(canApprove || canSelfApprove) && (
                <Button type="button" color="success" className="mr-2" onClick={handleOnApproveClick}>
                  Approva
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
    </>
  );
};

export default SalesOrderPaymentCertificateDetailPage;
