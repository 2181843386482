import { downloadRemoteFile } from 'helpers/api';
import { parseDateFromFilter, parseDateToFilter, stripNullOrUndefinedProperties } from 'helpers/filters';

const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

const fetchPurchaseInvoices = async (commissionId, filters, currentPage, numRecords) => {
  const queryString = new URLSearchParams(
    stripNullOrUndefinedProperties({
      CurrentPage: currentPage,
      NumRecords: numRecords,
      BusinessNameFilter: filters?.ragSociale,
      InvoiceStatusFilter: filters?.invoiceStatus?.id,
      InvoiceOrderStatusFilter: filters?.invoiceOrderStatusId?.id,
      DateInvoiceFromFilter: parseDateFromFilter(filters?.dateInvoiceFrom),
      DateInvoiceToFilter: parseDateToFilter(filters?.dateInvoiceTo),
      RegistrationDateFromFilter: parseDateFromFilter(filters?.registrationDateFrom),
      RegistrationDateToFilter: parseDateToFilter(filters?.registrationDateTo),
    })
  );

  const response = await fetch(`/api/commissions/${commissionId}/purchase-invoices?${queryString}`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const fetchPurchaseInvoiceStatus = async () => {
  const response = await fetch(`/api/purchase-invoices/status`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const downloadPurchaseInvoiceAttachment = (purchaseInvoiceId) => {
  return downloadRemoteFile(`/api/invoice/getattachment?invoiceId=${purchaseInvoiceId}`);
};

export { fetchPurchaseInvoices, fetchPurchaseInvoiceStatus, downloadPurchaseInvoiceAttachment };
