import useUnsavedChanges from 'hooks/useUnsavedChanges';
import { useEffect } from 'react';

const useEnterEditMode = () => {
  const { clear, notify } = useUnsavedChanges();

  useEffect(() => {
    console.debug('enter edit mode');
    notify();
    return () => {
      console.debug('exit edit mode');
      clear();
    };
  }, [clear, notify]);
};

export default useEnterEditMode;
