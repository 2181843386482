const fetchUsers = async (companyId) => {
  //fetch('/api/documenti/:documentId', { method: 'GET', body: postData })
  const response = await fetch(`/api/users?${new URLSearchParams({ companyId })}`);
  if (response.ok) {
    const resources = await response.json();
    return resources;
  }
  throw new Error('Si è verificato un errore nella lettura delle risorse');
};

const fetchRoles = async () => {
  // fetch('/api/documenti/:documentId', { method: 'GET', body: postData })
  const response = await fetch(`/api/roles`);
  if (response.ok) {
    const resources = await response.json();
    return resources;
  }
  throw new Error('Si è verificato un errore nella lettura dei ruoli');
};

const fetchEnabledUsers = async (commissionId) => {
  //fetch('/api/documenti/:documentId', { method: 'GET', body: postData })
  const response = await fetch(`/api/commissions/${commissionId}/enabled-users`);
  if (response.ok) {
    const resources = await response.json();
    return resources;
  }
  throw new Error('Si è verificato un errore nella lettura delle risorse');
};

const enableCommissionResource = async (commissionId, resource) => {
  return await fetch(`/api/commissions/${commissionId}/enabled-users`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(resource),
  });
};

const disableCommissionResource = async (commissionId, enabledUserId) => {
  return await fetch(`/api/commissions/${commissionId}/enabled-users?UserId=${enabledUserId}`, { method: 'DELETE' });
};
export { fetchUsers, fetchRoles, fetchEnabledUsers, enableCommissionResource, disableCommissionResource };
