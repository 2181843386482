import { useReducer, useState } from 'react';
import BudgetActionBar from './BudgetActionBar';
import { CollapsePeriodsToggler, SaveDraftButton } from './BudgetActionButtons';
import BudgetTable from './BudgetTable';

import {
  addSubactivity,
  reduceBudget,
  removeSubactivity,
  setSubactivityCost,
  setSubactivityDescription,
  setSubactivityPeriodCost,
  setSubactivityPeriodRevenue,
  setSubactivityRevenue,
} from 'reducers/budget';
import BudgetHeaderTotals from './BudgetHeaderTotals';
import { calculateActivitiesTotal, calculateMargin, mapFormToPostData } from 'helpers/budget';
import BudgetHeaderInfo from './BudgetHeaderInfo';

const EditableBudgetTable = ({ data, onSaveDraft }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [budget, dispatch] = useReducer(reduceBudget, data);

  const totalCost = calculateActivitiesTotal(budget.activities, (subactivity) => subactivity.cost);
  const revenue = calculateActivitiesTotal(budget.activities, (subactivity) => subactivity.revenue);
  const margin = { amount: revenue - totalCost, percentage: calculateMargin(totalCost, revenue) };

  const togglePeriodsCollapseHandler = () => setCollapsed((c) => !c);

  const costChangeHandler = (activityId, subactivityIndex, newCost) => {
    dispatch(setSubactivityCost(activityId, subactivityIndex, newCost));
  };

  const revenueChangeHandler = (activityId, subactivityIndex, newRevenue) => {
    dispatch(setSubactivityRevenue(activityId, subactivityIndex, newRevenue));
  };

  const periodCostChangeHandler = (activityId, subactivityIndex, period, newCost) => {
    dispatch(setSubactivityPeriodCost(activityId, subactivityIndex, period, newCost));
  };

  const periodRevenueChangeHandler = (activityId, subactivityIndex, period, newRevenue) => {
    dispatch(setSubactivityPeriodRevenue(activityId, subactivityIndex, period, newRevenue));
  };

  const subactivityDescriptionChangeHandler = (activityId, subactivityIndex, newDescription) => {
    dispatch(setSubactivityDescription(activityId, subactivityIndex, newDescription));
  };

  const addSubactivityHandler = (activityId) => {
    dispatch(addSubactivity(activityId));
  };

  const removeSubactivityHandler = (activityId, subactivityIndex) => {
    dispatch(removeSubactivity(activityId, subactivityIndex));
  };

  const handleSaveDraftClick = () => {
    const postData = mapFormToPostData(budget);
    onSaveDraft?.(postData);
  };

  return (
    <div>
      <BudgetActionBar>
        <SaveDraftButton onClick={handleSaveDraftClick} />
        <CollapsePeriodsToggler collapsed={collapsed} onToggle={togglePeriodsCollapseHandler} />
      </BudgetActionBar>
      <BudgetHeaderInfo version={budget.version} status={budget.status} creationDate={budget.creationDate} />
      <BudgetHeaderTotals totalCost={totalCost} revenue={revenue} margin={margin} />
      <BudgetTable
        activities={budget.activities}
        startPeriod={budget.startPeriod}
        endPeriod={budget.endPeriod}
        collapsed={collapsed}
        onCostChange={costChangeHandler}
        onRevenueChange={revenueChangeHandler}
        onPeriodCostChange={periodCostChangeHandler}
        onPeriodRevenueChange={periodRevenueChangeHandler}
        onSubactivityDescriptionChange={subactivityDescriptionChangeHandler}
        onAddSubactivity={addSubactivityHandler}
        onRemoveSubactivity={removeSubactivityHandler}
      />
    </div>
  );
};

export default EditableBudgetTable;
