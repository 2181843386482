import { downloadRemoteFile, fetchJson2 } from 'helpers/api';

const fetchPurchaseOrderList = ({ filters, sort, skip, take, cancellationToken }) => {
  const { column: sortBy, direction: sortDirection } = sort;
  const params = {
    ...filters,
    sortBy,
    sortDirection,
    skip,
    take,
  };
  const searchParams = new URLSearchParams(JSON.parse(JSON.stringify(params)));
  return fetchJson2(`/api/purchase-orders?${searchParams}`, cancellationToken);
};

const exportPurchaseOrders = ({ filters, sort, skip, take }) => {
  const { column: sortBy, direction: sortDirection } = sort;
  const params = {
    ...filters,
    sortBy,
    sortDirection,
    skip,
    take,
  };
  const searchParams = new URLSearchParams(JSON.parse(JSON.stringify(params)));
  return downloadRemoteFile(`api/purchase-orders/export?${searchParams}`);
};

export { fetchPurchaseOrderList, exportPurchaseOrders };
