import Loader from 'components/layout/Loader';
import COMMISSION_MANAGEMENT_PERMISSIONS from 'constants/commission-management-permissions';
import { calculateActivitiesTotal, calculateMargin } from 'helpers/budget';
import { formatNumber } from 'helpers/formatters';
import useAlerts from 'hooks/useAlerts';
import useCommission from 'hooks/useCommission';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Row, Col, FormGroup, Label, Input, Button, Card, CardBody, CardHeader } from 'reactstrap';
import {
  approveBudgetVersion,
  fetchBudgetVersion,
  fetchCurrentBudgetInfo,
  rejectBudgetVersion,
} from 'services/api/budget';
import { fetchCommissionWithId } from 'services/api/commissions';
import { fetchCustomerWithId } from 'services/api/customers';

const mapBudgetVersionToProjectSummary = (budgetVersion) => {
  if (!budgetVersion) {
    return {
      budget: {
        approvable: false,
      },
    };
  }
  const totalCost = calculateActivitiesTotal(budgetVersion.activities, (subactivity) => subactivity.cost);
  const totalSold = calculateActivitiesTotal(budgetVersion.activities, (subactivity) => subactivity.sold);
  const margin = calculateMargin(totalCost, totalSold);
  return {
    budget: {
      version: budgetVersion.version,
      approvable: budgetVersion.approvable,
      concurrencyStamp: budgetVersion.concurrencyStamp,
      costs: totalCost,
      revenues: totalSold,
      margin:
        `${formatNumber(totalSold - totalCost)}€` +
        (!isNaN(margin) && isFinite(margin) ? ` (${formatNumber(margin)}%)` : ''),
      expectedMonthlyWork: NaN,
    },
  };
};

const fetchBudgetLatestVersionByCommissionId = async (commissionId) => {
  const budget = await fetchCurrentBudgetInfo(commissionId);
  const { latestVersion } = budget;
  if (latestVersion) {
    const budgetVersion = await fetchBudgetVersion(commissionId, latestVersion, true);
    return budgetVersion;
  }
  return null;
};

const mapCommissionToProjectSummary = (commission) => {
  return {
    commission: {
      extendedDescription: commission.description ?? '',
      startDate: new Date(Date.parse(commission.expectedStartDate)),
      endDate: new Date(Date.parse(commission.expectedEndDate)),
      worksite: [
        commission.worksite.address,
        commission.worksite.location,
        commission.worksite.postalCode,
        commission.worksite.city,
      ]
        .filter((s) => s?.trim())
        .join(' - '),
    },
    customer: {
      name: commission.customer?.businessName ?? '',
      phoneNumber: commission.customer?.phoneNumbers?.[0] ?? '',
      email: commission.customer?.email ?? '',
    },
    projectManager: { name: commission.creator?.name ?? '' },
  };
};

const fetchCommissionAndCustomerByCommissionId = async (commissionId) => {
  const commissionResponse = await fetchCommissionWithId(commissionId);
  if (commissionResponse.success) {
    const customer = await fetchCustomerWithId(commissionResponse.data.customer.id);
    const { data: commission } = commissionResponse;
    return {
      ok: true,
      data: {
        ...commission,
        ...{
          customer: {
            ...commission.customer,
            phoneNumbers: customer.phoneNumbers,
            email: customer.email,
          },
        },
      },
    };
  }
  return {
    ok: false,
    message: 'Si è verificato un errore',
  };
};

const fetchProjectSummary = async (commissionId) => {
  const [commissionResponse, budgetVersion] = await Promise.all([
    fetchCommissionAndCustomerByCommissionId(commissionId),
    fetchBudgetLatestVersionByCommissionId(commissionId),
  ]);

  if (commissionResponse.ok) {
    return {
      ok: true,
      data: {
        ...mapCommissionToProjectSummary(commissionResponse.data),
        ...mapBudgetVersionToProjectSummary(budgetVersion),
      },
    };
  } else {
    return { ok: false, message: 'Si è verificato un errore' };
  }
};

const ProjectSummary = ({ projectSummary }) => (
  <Card className="mb-4">
    <CardHeader className="text-dark">Riepilogo progetto</CardHeader>
    <CardBody>
      <Row>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Project Manager</Label>
            <Input disabled value={projectSummary.projectManager.name} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Cliente</Label>
            <Input disabled value={projectSummary.customer.name} />
          </FormGroup>
        </Col>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Indirizzo cantiere</Label>
            <Input disabled value={projectSummary.commission.worksite} />
          </FormGroup>
        </Col>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Telefono</Label>
            <Input disabled value={projectSummary.customer.phoneNumber} />
          </FormGroup>
        </Col>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Indirizzo e-mail</Label>
            <Input disabled value={projectSummary.customer.email} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Data inizio commessa</Label>
            <Input disabled value={projectSummary.commission.startDate.toLocaleDateString('it-IT')} />
          </FormGroup>
        </Col>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Data fine commessa</Label>
            <Input disabled value={projectSummary.commission.endDate.toLocaleDateString('it-IT')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6}>
          <FormGroup>
            <Label>Descrizione estesa commessa</Label>
            <Input type="textarea" disabled value={projectSummary.commission.extendedDescription} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Costi (€)</Label>
            <Input disabled value={formatNumber(projectSummary.budget.costs)} />
          </FormGroup>
        </Col>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Ricavi (€)</Label>
            <Input disabled value={formatNumber(projectSummary.budget.revenues)} />
          </FormGroup>
        </Col>
        <Col md={6} lg={3}>
          <FormGroup>
            <Label>Margine</Label>
            <Input disabled value={projectSummary.budget.margin} />
          </FormGroup>
        </Col>
        {!!projectSummary.budget.expectedMonthlyWork && (
          <Col md={6} lg={3}>
            <FormGroup>
              <Label>Lavorato mensile previsto (€)</Label>
              <Input disabled value={formatNumber(projectSummary.budget.expectedMonthlyWork)} />
            </FormGroup>
          </Col>
        )}
      </Row>
    </CardBody>
  </Card>
);

const ApproveBudgetPage = () => {
  const [loading, setLoading] = useState(true);
  const [projectSummary, setProjectSummary] = useState();
  const [submitting, setSubmitting] = useState(false);

  const { commissionId } = useParams();
  const { success, error, warning } = useFeedback();
  const { reloadAlerts } = useAlerts();

  const { permissions } = useCommission();

  const canApproveOrReject = permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.APPROVE_REJECT);

  useEffect(() => {
    if (canApproveOrReject) {
      setLoading(true);
      setProjectSummary(undefined);
      fetchProjectSummary(commissionId)
        .then((response) => {
          if (response.ok) {
            setProjectSummary(response.data);
          } else {
            error(response.message);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      setProjectSummary(undefined);
    }
  }, [commissionId, canApproveOrReject, error]);

  const handleOnReject = async () => {
    const rejectionNote = prompt('Inserire una nota (obbligatorio):')?.trim();
    if (!!rejectionNote) {
      const requestData = {
        note: rejectionNote,
        concurrencyStamp: projectSummary.budget.concurrencyStamp,
      };
      const versionId = projectSummary.budget.version;
      setSubmitting(true);
      const response = await rejectBudgetVersion(commissionId, versionId, requestData);
      if (response.ok) {
        success(response.message);
        setProjectSummary((projectSummary) => ({
          ...projectSummary,
          budget: { ...projectSummary.budget, ...response.data },
        }));
        reloadAlerts();
      } else {
        error(response.message);
      }
      setSubmitting(false);
    } else if (rejectionNote !== undefined) {
      warning('È obbligatorio inserire una nota per poter rifiutare il progetto.');
    }
  };

  const handleOnApprove = async () => {
    const approvalNote = prompt('Inserire una nota (obbligatorio):')?.trim();
    if (!!approvalNote) {
      const requestData = {
        note: approvalNote,
        concurrencyStamp: projectSummary.budget.concurrencyStamp,
      };
      const versionId = projectSummary.budget.version;
      setSubmitting(true);
      const response = await approveBudgetVersion(commissionId, versionId, requestData);
      if (response.ok) {
        success(response.message);
        setProjectSummary((projectSummary) => ({
          ...projectSummary,
          budget: { ...projectSummary.budget, ...response.data },
        }));
        reloadAlerts();
      } else {
        error(response.message);
      }
      setSubmitting(false);
    } else if (approvalNote !== undefined) {
      warning('È obbligatorio inserire una nota per poter approvare il progetto.');
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : canApproveOrReject ? (
        <>
          <h1 className="h2 mb-3">Approvazione progetto</h1>

          {submitting && <Loader />}

          {!!projectSummary ? (
            projectSummary.budget.approvable ? (
              <>
                <ProjectSummary projectSummary={projectSummary} />
                <Row>
                  <Col xs={6} lg={4} xl={3} className="text-right offset-lg-2 offset-xl-3">
                    <Button type="button" color="danger" block onClick={handleOnReject}>
                      Rifiuta
                    </Button>
                  </Col>
                  <Col xs={6} lg={4} xl={3}>
                    <Button type="button" color="success" block onClick={handleOnApprove}>
                      Approva
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <p>Nessuna richiesta di approvazione in corso per questo progetto.</p>
            )
          ) : (
            <p>Si è verificato un errore in fase di caricamento dei dati.</p>
          )}
        </>
      ) : (
        <p>Non sei autorizzato ad approvare questo progetto.</p>
      )}
    </>
  );
};

export default ApproveBudgetPage;
