const { default: AuthContext } = require("contexts/AuthContext");
const { default: useFeedback } = require("hooks/useFeedback");
const { useContext, useCallback } = require("react");

const useParseCustomResponse = () => {
    const { logout } = useContext(AuthContext);
    const { error } = useFeedback();
  
    const parseCustomResponse = useCallback(
      (response) => {
        if (response.success) {
          return { success: true, data: response.data };
        }
  
        if (response.authenticated === false) {
          logout();
        }
  
        if (response.authorized === false) {
          error('Non sei autorizzato a vedere questa pagina');
          return { success: false, message: 'Non sei autorizzato a vedere questa pagina' };
        }
  
        error(response.message);
        return { success: false, message: response.message };
      },
      [error, logout]
    );
  
    return parseCustomResponse;
  };

  export default useParseCustomResponse;