import FormInput from 'components/forms/FormInput';
import Loader from 'components/layout/Loader';
import COMMISSION_MANAGEMENT_PERMISSIONS from 'constants/commission-management-permissions';
import useCommission from 'hooks/useCommission';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Card, CardBody, Col, Form, Row, Table } from 'reactstrap';
import {
  disableCommissionResource,
  fetchEnabledUsers,
  fetchRoles,
  fetchUsers,
  enableCommissionResource,
} from 'services/enabledUsers';

const STYLES = {
  addButton: {
    marginTop: '23px',
  },
};

const retrieveData = async (companyId, commissionId) => {
  const fetchUsersPromise = fetchUsers(companyId);
  const fetchEnabledUsersPromise = fetchEnabledUsers(commissionId);
  const fetchRolesPromise = fetchRoles();
  const [usersResponse, enabledUsersResponse, rolesResponse] = await Promise.all([
    fetchUsersPromise,
    fetchEnabledUsersPromise,
    fetchRolesPromise,
  ]);
  return {
    usersData: usersResponse,
    rolesData: rolesResponse,
    enabledUsersData: enabledUsersResponse,
  };
};

const ResourceList = ({ resources, canDelete, onDeleteResource }) => {
  return (
    <div className="table-responsive">
      <Table bordered style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: 'calc(50% - 47.5px)' }}>Nome</th>
            <th style={{ width: 'calc(50% - 47.5px)' }}>Ruolo</th>
            <th style={{ width: '95px' }}></th>
          </tr>
        </thead>
        <tbody>
          {resources.map((resource) => (
            <tr key={`resource-${resource.userId}`}>
              <td style={{ verticalAlign: 'middle' }}>{resource.fullName}</td>
              <td style={{ verticalAlign: 'middle' }}>{resource.roleName}</td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                {canDelete && resource.deletable && (
                  <Button type="button" color="danger" onClick={() => onDeleteResource(resource)}>
                    Elimina
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

function setDataPush(e, users, roles) {
  const formData = e.target.getObjectData();
  const userId = parseInt(formData.userSelect);
  const user = users.find((user) => user.id === userId);
  const roleId = parseInt(formData.userRoleSelect);
  const role = roles.find((role) => role.roleId === roleId);
  return {
    data: { userId: user.id, fullName: user.name, roleId: role.roleId, roleName: role.name },
    dataToPush: { userId: user.id, roleId: role.roleId },
  };
}

const compareUser = (a, b) => a.fullName.toUpperCase().localeCompare(b.fullName.toUpperCase());

const EnabledUserPage = () => {
  const [roles, setRoles] = useState([]);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [enabledUsers, setEnabledUsers] = useState([]);

  const { commissionId } = useParams();
  const { companyId, isCommissionClosed } = useCommission();
  const { hasPermission } = useCommissionPermissions();
  const { success, error } = useFeedback();

  const isSubmitAvailable = selectedUserId === '' || selectedRoleId === '';
  const canEdit = hasPermission(COMMISSION_MANAGEMENT_PERMISSIONS.RESOURCE.EDIT);

  const internalReferentsRoles = useMemo(() => roles.filter((role) => role.roleTypeId === 2), [roles]);
  const othersRoles = useMemo(() => roles.filter((role) => role.roleTypeId === 3), [roles]);

  const internalReferents = useMemo(() => {
    const projectManagersCount = enabledUsers.filter((enabledUser) => enabledUser.roleId === 5).length;
    const internalReferentsRolesIds = internalReferentsRoles.map((role) => role.roleId);
    return enabledUsers
      .filter((enabledUser) => internalReferentsRolesIds.includes(enabledUser.roleId))
      .map((enabledUser) => ({ ...enabledUser, deletable: enabledUser.roleId !== 5 || projectManagersCount > 1 }))
      .sort(compareUser);
  }, [enabledUsers, internalReferentsRoles]);

  const others = useMemo(() => {
    const othersRolesIds = othersRoles.map((role) => role.roleId);
    return enabledUsers
      .filter((enabledUser) => othersRolesIds.includes(enabledUser.roleId))
      .map((enabledUser) => ({ ...enabledUser, deletable: true }))
      .sort(compareUser);
  }, [enabledUsers, othersRoles]);

  const selectableResources = useMemo(
    () => resources.filter((resource) => !enabledUsers.map((user) => user.userId).includes(resource.id)),
    [enabledUsers, resources]
  );

  const handleOnChangeResource = (e) => {
    const selectedUserId = parseInt(e.target.value);
    const userId = isNaN(selectedUserId) ? '' : selectedUserId;
    setSelectedUserId(userId);
    const roleId = resources.find((u) => u.id === userId)?.roleId;
    if (userId !== '' && roleId) {
      setSelectedRoleId(roleId);
    } else {
      setSelectedRoleId('');
    }
  };

  const handleOnChangeResourceRole = (e) => {
    const selectedRoleId = parseInt(e.target.value);
    const role = isNaN(selectedRoleId) ? '' : selectedRoleId;
    setSelectedRoleId(role);
  };

  function resetDropDown() {
    setSelectedUserId('');
    setSelectedRoleId('');
  }

  const handleOnSubmitAddUser = (e) => {
    e.preventDefault();
    const { data, dataToPush } = setDataPush(e, resources, roles);
    setSubmitting(true);
    enableCommissionResource(commissionId, dataToPush).then((response) => {
      setEnabledUsers((enabledUsers) => [...enabledUsers, data]);
      resetDropDown();
      setSubmitting(false);
      success(`Hai abilitato ${data.fullName}`);
    });
  };

  const handleOnDeleteResource = (resource) => {
    setSubmitting(true);
    disableCommissionResource(commissionId, resource.userId).then(() => {
      setEnabledUsers((enabledUsers) => enabledUsers.filter((enabledUser) => enabledUser.userId !== resource.userId));
      success(`Hai disabilitato ${resource.fullName}.`);
      setSubmitting(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    retrieveData(companyId, commissionId)
      .then(({ usersData, enabledUsersData, rolesData }) => {
        setResources(usersData);
        setRoles(rolesData);
        setEnabledUsers(enabledUsersData.enabledUsers);
      })
      .catch(() => error('Si è verificato un errore nel caricare i dati.'))
      .finally(() => setLoading(false));
  }, [commissionId, companyId, error]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {submitting && <Loader />}
          <Card>
            <CardBody>
              {canEdit && (
                <Form onSubmit={handleOnSubmitAddUser} className="mb-4">
                  <Row>
                    <Col md={4} xl={3}>
                      <FormInput
                        type="select"
                        label="Seleziona risorsa"
                        name="userSelect"
                        value={selectedUserId}
                        onChange={handleOnChangeResource}
                      >
                        <option value="">-- Scegli una risorsa</option>
                        {selectableResources.map((user) => (
                          <option key={`user-${user.id}`} value={user.id}>
                            {user.name}{' '}
                          </option>
                        ))}
                      </FormInput>
                    </Col>
                    <Col md={4} xl={3}>
                      <FormInput
                        type="select"
                        label="Seleziona ruolo risorsa"
                        name="userRoleSelect"
                        value={selectedRoleId}
                        disabled={selectedUserId === ''}
                        onChange={handleOnChangeResourceRole}
                      >
                        <option value="">-- Scegli un ruolo</option>
                        <optgroup label="Referenti interni commessa">
                          {roles
                            .filter((role) => role.roleTypeId === 2)
                            .map((role) => (
                              <option key={`role-${role.roleId}`} value={role.roleId}>
                                {role.name}
                              </option>
                            ))}
                        </optgroup>
                        <optgroup label="Altro">
                          {roles
                            .filter((role) => role.roleTypeId === 3)
                            .map((role) => (
                              <option key={`role-${role.roleId}`} value={role.roleId}>
                                {role.name}
                              </option>
                            ))}
                        </optgroup>
                      </FormInput>
                    </Col>
                    {!isCommissionClosed && (
                    <Col md={4} xl={3} style={STYLES.addButton}>
                      <Button type="submit" color="primary" disabled={isSubmitAvailable}>
                        Aggiungi
                      </Button>
                    </Col>
                    )}
                  </Row>
                </Form>
              )}
              {/* Referenti interni commessa */}
              <section className="mb-4">
                <Row>
                  <Col xl={6}>
                    <h5>Referenti interni commessa</h5>
                    {internalReferents.length > 0 ? (
                      <ResourceList
                        resources={internalReferents}
                        canDelete={canEdit}
                        onDeleteResource={handleOnDeleteResource}
                      />
                    ) : (
                      <span>Nessun referente interno selezionato per questa commessa.</span>
                    )}
                  </Col>
                </Row>
              </section>
              {/* Altro */}
              <section>
                <Row>
                  <Col xl={6}>
                    <h5>Altro</h5>
                    {others.length > 0 ? (
                      <ResourceList resources={others} canDelete={canEdit} onDeleteResource={handleOnDeleteResource} />
                    ) : (
                      <span>Nessuna risorsa di questo tipo selezionata per questa commessa.</span>
                    )}
                  </Col>
                </Row>
              </section>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};

export default EnabledUserPage;
