import UnsavedChangesContext from 'contexts/UnsavedChangesContext';
import { useCallback, useEffect, useState } from 'react';
// import { useLocation } from 'react-router';

const UnsavedChangesProvider = ({ children }) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const notify = useCallback(() => setUnsavedChanges(true), []);
  const clear = useCallback(() => setUnsavedChanges(false), []);

  // const { pathname } = useLocation();

  // always clear the unsaved changes on location change
  // useEffect(() => {
  //   console.debug(`location pathname changed to ${pathname}`);
  //   setUnsavedChanges(false);
  // }, [pathname]);

  useEffect(() => {
      console.debug(`unsavedChanges=${unsavedChanges ? 'true' : 'false'}`);
    if (!unsavedChanges) {
      return;
    }
    function handleOnBeforeUnload(e) {
      e.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      e.returnValue = false;
    }
    window.addEventListener('beforeunload', handleOnBeforeUnload);
    console.debug('beforeunload listener added');
    return () => window.removeEventListener('beforeunload', handleOnBeforeUnload);
  }, [unsavedChanges]);

  const context = {
    unsavedChanges,
    notify,
    clear,
    askConfirm: (onConfirm) => {
      const confirm = window.confirm(
        'Abbandonando la pagina corrente andranno perse tutte le modifiche apportate e non salvate e non ci sarà modo di recuperarle. Sei sicuro di voler continuare?'
      );
      if (confirm) {
        clear();
        onConfirm();
      }
    },
  };

  return <UnsavedChangesContext.Provider value={context}>{children}</UnsavedChangesContext.Provider>;
};

export default UnsavedChangesProvider;
