import { createContext } from 'react';

const CommissionContext = createContext({
  companyId: undefined,
  permissions: [],
  isCommissionClosed: undefined,
  invalidate: () => {},
});

export default CommissionContext;
