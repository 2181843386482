import { NavLink } from 'react-router-dom';
import ROUTES from 'routes';

const SalesOrderIndexHeader = ({ commissionId, canCreateOrder }) => {
  return (
    <header className="mb-2">
      {canCreateOrder && (
        <div className="text-left">
          <NavLink
            to={ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.CREATE.replace(
              ":commissionId",
              commissionId
            )}
            className="btn btn-primary px-4"
          >
            Crea nuovo ordine
          </NavLink>
        </div>
      )}
    </header>
  );
};

export default SalesOrderIndexHeader;