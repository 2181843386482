import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';
import { useState } from 'react';
import CustomInput from 'components/shared/forms/CustomInput';
import { NOT_APPLICABLE, NOT_INVOICEABLE, INVOICEABLE } from 'constants/guarantee-withholding-types';
import useUnsavedChanges from 'hooks/useUnsavedChanges';

const NoDurcDisplay = () => (
  <small>
    <i className="fa fa-ban text-danger"></i> DURC non presente
  </small>
);

const formatDate = (date) => {
  return date.toLocaleDateString('it-IT', { dateStyle: 'short' });
};

const ExpiredDurcDisplay = ({ expirationDate }) => (
  <small>
    <i className="fa fa-ban text-danger" /> Durc scaduto il {formatDate(expirationDate)}
  </small>
);

const ValidDurcDisplay = ({ expirationDate }) => (
  <small>
    <i className="fa fa-check text-success" /> Scadenza DURC {formatDate(expirationDate)}
  </small>
);

const DurcValidityDisplay = ({ durc }) => {
  if (!durc) {
    return <NoDurcDisplay />;
  }

  const expirationDate = new Date(Date.parse(durc.expirationDate));

  return durc.valid ? (
    <ValidDurcDisplay expirationDate={expirationDate} />
  ) : (
    <ExpiredDurcDisplay expirationDate={expirationDate} />
  );
};

const PurchaseOrderFormSection = ({ readonly = false, supplier, purchaseOrder, className }) => {
  const [showDurcValidity, setShowDurcValidity] = useState(purchaseOrder.type === 1 || purchaseOrder.type === 3);
  const [showConversionRate, setShowConversionRate] = useState(purchaseOrder.currency.id !== 'EUR');
  const [showGuaranteeWithholdingValue, setShowGuaranteeWithholdingValue] = useState(
    [NOT_INVOICEABLE, INVOICEABLE].includes(purchaseOrder.guaranteeWithholding?.id)
  );
  const { notify: notifyUnsavedChanges } = useUnsavedChanges();
  const handleTypeChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setShowDurcValidity(selectedValue === 1 || selectedValue === 3);
  };
  const handleCurrencyChange = (e) => {
    const selectedCurrency = e.target.value;
    setShowConversionRate(selectedCurrency !== 'EUR');
  };
  const handleGuaranteeWithholdingTypeChange = (e) => {
    const selectedGuaranteeWithholdingType = parseInt(e.target.value);
    setShowGuaranteeWithholdingValue([NOT_INVOICEABLE, INVOICEABLE].includes(selectedGuaranteeWithholdingType));
  };
  const handleOnInputNumberChange = () => {
    notifyUnsavedChanges();
  };
  return (
    <Card className={className} tag="fieldset">
      <CardHeader className="p-2">Ordine</CardHeader>
      <CardBody className="p-2">
        <CustomInput
          type="textarea"
          name="shortDescription"
          required
          defaultValue={purchaseOrder.shortDescription}
          label="Descrizione breve"
          disabled={readonly}
          maxLength={180}
          groupClassName="mb-2"
        />
        <CustomInput
          type="date"
          name="deliveryDate"
          min="2000-01-01"
          max="9999-12-31"
          required
          defaultValue={purchaseOrder.deliveryDate}
          label="Data di consegna"
          disabled={readonly}
          groupClassName="mb-2"
        />
        <Row>
          <Col xs={8}>
            <CustomInput
              type="select"
              name="typeId"
              required
              defaultValue={purchaseOrder.type}
              label="Posa/Fornitura"
              onChange={handleTypeChange}
              disabled={readonly}
              groupClassName="mb-2"
            >
              <option value="">...</option>
              <option value={1}>Posa</option>
              <option value={2}>Fornitura</option>
              <option value={3}>Posa + Fornitura</option>
            </CustomInput>
          </Col>
          {showDurcValidity && (
            <Col xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <DurcValidityDisplay durc={supplier.durc} />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={8}>
            <CustomInput
              type="select"
              label="Valuta"
              required
              name="currency"
              defaultValue={purchaseOrder.currency.id}
              onChange={handleCurrencyChange}
              disabled={readonly}
              groupClassName="mb-2"
            >
              <option value="EUR">Euro</option>
              <option value="CHF">Franchi svizzeri</option>
              <option value="USD">Dollari</option>
            </CustomInput>
          </Col>
          {showConversionRate && (
            <Col xs={4}>
              <CustomInput
                type="formattednumber"
                name="exchangeRateAmount"
                locale="it-IT"
                required
                min={0}
                minFractionDigits={2}
                maxFractionDigits={2}
                value={purchaseOrder.currency.exchangeRate?.amount}
                label="Cambio 1€ ="
                disabled={readonly}
                onChange={handleOnInputNumberChange}
                groupClassName="mb-2"
              />
            </Col>
          )}
        </Row>
        {showConversionRate && (
          <CustomInput
            type="date"
            min="2000-01-01"
            max="9999-12-31"
            name="exchangeRateDate"
            required
            defaultValue={purchaseOrder.currency.exchangeRate?.date}
            label="Data valuta"
            disabled={readonly}
            groupClassName="mb-2"
          />
        )}
        <Row>
          <Col xs={8}>
            <CustomInput
              type="select"
              name="guaranteeWithholdingType"
              defaultValue={purchaseOrder.guaranteeWithholding?.id}
              onChange={handleGuaranteeWithholdingTypeChange}
              label="Ritenuta di garanzia"
              disabled={readonly}
              required
              groupClassName="mb-2"
            >
              <option value="">---Seleziona</option>
              <option value={NOT_APPLICABLE}>Non applicabile</option>
              <option value={NOT_INVOICEABLE}>Non fatturabile</option>
              <option value={INVOICEABLE}>Fatturabile</option>
            </CustomInput>
          </Col>
          {showGuaranteeWithholdingValue && (
            <Col xs={4}>
              <CustomInput
                type="formattednumber"
                name="guaranteeWithholdingValue"
                locale="it-IT"
                value={purchaseOrder.guaranteeWithholding?.value}
                min={0}
                max={100}
                minFractionDigits={2}
                maxFractionDigits={2}
                label="%"
                required
                disabled={readonly}
                onChange={handleOnInputNumberChange}
                groupClassName="mb-2"
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={8}>
            <CustomInput
              type="select"
              label="Lingua PDF generato"
              required
              name="exportedFileLanguageId"
              defaultValue={purchaseOrder.exportedFileLanguageId}
              disabled={readonly}
              groupClassName="mb-0"
            >
              <option value="it">Italiano</option>
              <option value="en">Inglese</option>
              {/* <option value="fr">Francese</option> */}
            </CustomInput>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PurchaseOrderFormSection;
