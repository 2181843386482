import { promptWhileCanceledOrEmpty } from 'helpers/prompting';
import {
  createApprovalConfirmMessage,
  createRejectionConfirmMessage,
  createRejectionPromptMessage,
  createSuspensionConfirmMessage,
  createSuspensionPromptMessage,
  createResumeConfirmMessage,
} from './messages';

const promptRejectionNote = (invoiceAssociation) => {
  return promptWhileCanceledOrEmpty(createRejectionPromptMessage(invoiceAssociation));
};

const promptSuspensionNote = (invoiceAssociation) => {
  return promptWhileCanceledOrEmpty(createSuspensionPromptMessage(invoiceAssociation));
};

const askForApprovalConfirm = (invoiceAssociation) => {
  return window.confirm(createApprovalConfirmMessage(invoiceAssociation));
};

const askForRejectionConfirm = (invoiceAssociation) => {
  return window.confirm(createRejectionConfirmMessage(invoiceAssociation));
};

const askForSuspensionConfirm = (invoiceAssociation) => {
  return window.confirm(createSuspensionConfirmMessage(invoiceAssociation));
};

const askForResumeConfirm = (invoiceAssociation) => {
  return window.confirm(createResumeConfirmMessage(invoiceAssociation));
};

export {
  promptRejectionNote,
  askForApprovalConfirm,
  askForRejectionConfirm,
  promptSuspensionNote,
  askForSuspensionConfirm,
  askForResumeConfirm,
};
