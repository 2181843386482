import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import DataTable from 'components/shared/DataTable';
import useAuth from 'hooks/useAuth';
import Loader from 'components/layout/Loader';
import useParseCustomResponse from '../hooks/useParseCustomResponse';
import useFeedback from 'hooks/useFeedback';
import { fetchSalesInvoiceStatus, fetchSalesInvoices } from 'services/api/sales-invoices-management';
import InvoiceStatusBadge from '../components/InvoiceStatusBadge';
import { format } from 'helpers/amount';
import { displayDate } from 'helpers/date';

const formatISODate = (isoDate) => {
  return displayDate(new Date(isoDate));
};

const DATA_TABLE_COLUMN = [
  {
    heading: 'Nr. Fatt.',
    value: 'invoiceNumber',
    type: 'text',
    filterable: true,
    sortable: true,
  },
  {
    heading: 'Data fattura',
    value: 'emissionDate',
    type: 'date',
    filterable: true,
    sortable: true,
    cellTemplate: (value) => {
      return formatISODate(value);
    },
  },
  {
    heading: 'Cliente',
    value: 'businessName',
    type: 'text',
    filterable: true,
    sortable: true,
    cellTemplate: (value, item) => {
      const parenthesesInfo = [item.customer.code, item.customer.vatNumber ?? item.customer.taxCode].filter(
        (str) => !!str
      );
      const displayName = `${item.customer.businessName}${
        parenthesesInfo.length > 0 ? ` (${parenthesesInfo.join(' - ')})` : ''
      }`;
      return (
        <div
          style={{
            width: '100%',
            maxWidth: '250px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={displayName}
        >
          {displayName}
        </div>
      );
    },
  },
  {
    heading: 'Importo (€)',
    value: 'amount',
    type: 'number',
    filterable: false,
    sortable: true,
    style: { textAlign: 'right' },
    cellTemplate: (value) => {
      return format(value);
    },
  },
  {
    heading: 'Importo associato (€)',
    value: 'chargedAmount',
    type: 'number',
    filterable: false,
    sortable: false,
    style: { textAlign: 'right' },
    cellTemplate: (value) => {
      return format(value);
    },
  },
  {
    heading: 'Data registrazione',
    value: 'registrationDate',
    type: 'date',
    filterable: true,
    sortable: true,
    cellTemplate: (value) => {
      return formatISODate(value);
    },
  },
  {
    heading: 'Stato',
    value: 'statusId',
    type: 'select',
    filterable: true,
    sortable: true,
    cellTemplate: (value, { statusId: status }) => {
      return <InvoiceStatusBadge statusId={status.id} statusName={status.description} />;
    },
  },
  {
    heading: '',
    value: 'hasAttachment',
    type: '',
    filterable: false,
    sortable: false,
    style: { textAlign: 'center' },
    cellTemplate: (hasAttachment, invoice) =>
      hasAttachment && (
        <a href={`/api/sales-invoices/${invoice.salesInvoiceId}/attachment`} target="_blank" rel="noreferrer">
          <i className="fa fa-file" />
        </a>
      ),
  },
  {
    heading: '',
    value: '',
    type: 'text',
    filterable: false,
    sortable: false,
    style: { textAlign: 'center' },
    cellTemplate: (value, item) => {
      return (
        <NavLink to={`/gestione-fatture-clienti/${item.salesInvoiceId}`} className="small">
          Visualizza
        </NavLink>
      );
    },
  },
];

// crea le options per input type select status
const createStatusOptions = (statuses) => {
  return statuses.map((data) => ({
    id: data.statusId,
    description: data.name,
  }));
};

const useFetchSalesInvoice = (companyId, pageSize) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const [invoiceStatuses, setInvoiceStatuses] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const retrieveData = (params, filters) => {
    setLoading(true);
    fetchSalesInvoices(params, filters)
      .then((resp) => {
        const parsedResponse = parseCustomResponse(resp);
        if (parsedResponse.success) {
          setResponse({
            salesInvoicesResponse: ProcessResponsesForDatatable(parsedResponse.data.invoices, invoiceStatuses),
            totRecords: parsedResponse.data.totRecords,
          });
        } else {
          setErrorMessage(parsedResponse.message);
        }
      })
      .catch((err) => {
        error('Errore durante il recupero dei dati', 'Errore');
        setErrorMessage(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchData = useCallback(async () => {
    const [SalesInvoicesRaw, StatusRaw] = await Promise.all([
      fetchSalesInvoices({ CompanyId: companyId, NumRecords: pageSize }),
      fetchSalesInvoiceStatus(),
    ]);
    return {
      SalesInvoicesRaw,
      StatusRaw,
    };
  }, [companyId, pageSize]);

  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(({ SalesInvoicesRaw, StatusRaw }) => {
        const salesInvoicesParsedResponse = parseCustomResponse(SalesInvoicesRaw);
        const statusesParsedResponse = parseCustomResponse(StatusRaw);

        if (salesInvoicesParsedResponse.success && statusesParsedResponse.success) {
          const statuses = createStatusOptions(statusesParsedResponse.data.status);

          setResponse({
            salesInvoicesResponse: ProcessResponsesForDatatable(salesInvoicesParsedResponse.data.invoices, statuses),
            totRecords: salesInvoicesParsedResponse.data.totRecords,
          });

          setInvoiceStatuses(statuses);
        }
      })
      .catch((err) => {
        setErrorMessage(err);
        error('errore durante il recupero dei dati', 'Errore');
      })
      .finally(() => setLoading(false));
  }, [error, fetchData, parseCustomResponse]);

  return { loading, response, invoiceStatuses, errorMessage, retrieveData };
};

const ProcessResponsesForDatatable = (invoices, statuses) => {
  return invoices.map((invoice) => {
    const state = statuses.find((status) => status.id === invoice.statusId);
    invoice.statusId = {
      id: state ? state.id : '',
      description: state.description,
    };

    return invoice;
  });
};

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];

const SalesInvoicesManagementIndexPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [numRecords, setNumRecords] = useState(INITIAL_PAGE_SIZE);
  const [searchTerms, setSearchTerms] = useState({});
  const [sort, setSort] = useState({ column: '', direction: '' });
  const { company } = useAuth();
  const [params, setParams] = useState({ CompanyId: company.id });
  const { response, invoiceStatuses, loading, errorMessage, retrieveData } = useFetchSalesInvoice(
    company.id,
    INITIAL_PAGE_SIZE
  );

  const column = useMemo(() => {
    return DATA_TABLE_COLUMN.map((col) => (col.value !== 'statusId' ? col : { ...col, options: invoiceStatuses }));
  }, [invoiceStatuses]);

  const onHandleSort = (columnName) => {
    const newDirection = sort.direction === '' ? 'ASC' : sort.direction === 'ASC' ? 'DESC' : '';

    setSort({ column: columnName, direction: newDirection });

    const param = {
      ...params,
      CurrentPage: currentPage,
      NumRecords: numRecords,
      SortColumn: columnName,
      Sort: newDirection,
    };
    setParams(params);

    retrieveData(param, searchTerms);
  };

  const onHandlePageChange = (newPage) => {
    setCurrentPage(newPage);

    const param = {
      ...params,
      CurrentPage: newPage,
      NumRecords: numRecords,
    };

    setParams(param);
    retrieveData(param, searchTerms);
  };

  const onHandleRecordsChange = (num, newPage) => {
    setCurrentPage(newPage);
    setNumRecords(num);

    const param = {
      ...params,
      CurrentPage: newPage,
      NumRecords: num,
    };

    setParams(param);
    retrieveData(param, searchTerms);
  };

  const onHandleSearch = (value, field, type, newPage) => {
    const updatedSearchTerms = { ...searchTerms };

    if (value === '') {
      delete updatedSearchTerms[field];
    } else {
      updatedSearchTerms[field] = type === 'select' ? { id: value } : value;
    }
    setSearchTerms(updatedSearchTerms);
    console.log(updatedSearchTerms);
    setCurrentPage(newPage);

    const param = {
      ...params,
      CurrentPage: newPage,
      NumRecords: numRecords,
    };

    setParams(param);
    retrieveData(param, updatedSearchTerms);
  };

  return (
    <>
      <h2>Gestione fatture clienti</h2>
      {loading && <Loader />}
      {errorMessage && <span>{errorMessage}</span>}
      {!!response && (
        <DataTable
          responsive
          data={response.salesInvoicesResponse}
          column={column}
          resizable={false}
          onHandleSort={onHandleSort}
          onHandleSearch={onHandleSearch}
          onHandlePageChange={onHandlePageChange}
          onHandleRecordsChange={onHandleRecordsChange}
          totRecords={response.totRecords}
          currentPage={currentPage}
          pageSize={numRecords}
          sort={sort}
          pageSizes={PAGES_SIZE}
        />
      )}
    </>
  );
};

export default SalesInvoicesManagementIndexPage;
