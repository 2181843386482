import { formatNumber } from "helpers/formatters";
import { Table } from "reactstrap";
import { getColor } from "./commonFunctions";

const CodiciContabiliTable = (props) => {

    return (
        <Table bordered hover striped className="mt-1">
            <thead>
                <tr>
                    <th>CODICE CONTABILE</th>
                    <th>DESCRIZIONE</th>
                    <th>IMPORTO IN FATTURA €</th>
                    <th>IMPORTO ASSOCIATO €</th>
                </tr>
            </thead>
            <tbody>
                {props.CodiciContabili.ccVett.map((c) => (
                    <tr key={c.COD}>
                        <td>{c.COD}</td>
                        <td>{c.Description}</td>
                        <td>{formatNumber(c.ImportoFattura)}</td>
                        <td style={{ background: getColor(c.ImportoAssociato, c.ImportoFattura), color: 'black' }}>{formatNumber(c.ImportoAssociato)}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <th style={{textAlign: 'right'}}>Totale: {formatNumber(props.CodiciContabili?.totaleDisplay)}</th>
                    <th style={{textAlign: 'right'}}>Totale: {formatNumber(props.CodiciContabili?.totaleCalcolato)}</th>
                </tr>
            </tfoot>
        </Table>
    );
}

export default CodiciContabiliTable;