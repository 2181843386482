import { InputNumber } from 'primereact/inputnumber';
import { Button, Col, Input, ListGroup, ListGroupItem, Row } from 'reactstrap';
import React from 'react';
import CommissionGroup from '../CommissionGroup';
import OrderGroup from '../OrderGroup';
import PaymentCertificateHeader from '../PaymentCertificateHeader';
import { format } from 'helpers/amount';

const CustomSelect = ({ value, onChange, invalid, children, disabled }) => {
  const className = invalid ? 'border border-danger' : undefined;
  return (
    <Input
      type="select"
      required
      value={value}
      onChange={onChange}
      disabled={disabled}
      bsSize="sm"
      className={className}
    >
      {children}
    </Input>
  );
};

// regex pattern to ensure that the formatted number is different from 0,00
// const FORMATTED_NUMBER_GT_ZERO_REGEX_PATTERN = '^0,\\d[1-9]|[1-9]{1}[\\d\\.]*,\\d+$';

const CustomInputNumber = ({ value, onChange, invalid, disabled }) => {
  const className = `form-control form-control-sm p-inputtext-sm${invalid ? ' border border-danger' : ''}`;
  return (
    <InputNumber
      minFractionDigits={2}
      maxFractionDigits={2}
      required
      // pattern={FORMATTED_NUMBER_GT_ZERO_REGEX_PATTERN}
      // pt={{ input: { root: { title: 'Il valore deve essere maggiore di zero.' } } }}
      // min={0}
      value={value}
      onChange={onChange}
      disabled={disabled}
      inputStyle={{ padding: '.25rem .5rem', width: '100%', opacity: 0.9, textAlign: 'right' }}
      locale="en-US"
      inputClassName={className}
      style={{ width: '100%' }}
    />
  );
};

const AssociatedPaymentCertificate = ({
  number,
  note,
  date,
  taxableAmount,
  commissionId,
  salesOrderId,
  deleted,
  deletable,
  editable,
  children,
  onAddLineClick,
  onDissociate,
}) => {
  const handleAddLineClick = () => {
    onAddLineClick();
  };
  const handleDissociate = () => {
    onDissociate();
  };
  const dissociateButtonVisible = deletable;
  const addLineButtonVisible = editable;
  return (
    <div style={{ position: 'relative' }}>
      {deleted && <ItemDeletedDisplay size="lg" />}
      <PaymentCertificateHeader
        number={number}
        note={note}
        date={date}
        taxableAmount={taxableAmount}
        commissionId={commissionId}
        salesOrderId={salesOrderId}
        className="mb-1"
      />
      <div>{children}</div>
      <div>
        {addLineButtonVisible && (
          <Button
            type="button"
            color="primary"
            size="sm"
            onClick={handleAddLineClick}
            className="mr-1"
            title="Associa un altro importo"
            disabled={!editable}
          >
            +
          </Button>
        )}
        {dissociateButtonVisible && (
          <Button
            type="button"
            color="danger"
            size="sm"
            onClick={handleDissociate}
            disabled={!deletable}
            className="mr-1"
            title={`Disassocia il CDP nr. ${number} da questa fattura`}
          >
            &times; Disassocia
          </Button>
        )}
      </div>
    </div>
  );
};

const ItemDeletedDisplay = ({ size = undefined }) => {
  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: 'rgba(245, 245, 245, 0.64)',
        left: 0,
        right: 0,
        fontSize: size === 'lg' ? '1.5rem' : size === 'sm' ? '.875rem' : undefined,
        fontStyle: 'italic',
        fontWeight: 'bold',
        color: 'rgb(231, 74, 59)',
        transform: 'translateY(-50%)',
        top: '50%',
        textAlign: 'center',
        zIndex: 100,
        height: 'calc(100% - 1rem)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Elemento cancellato (salva per rendere effettivo)
    </div>
  );
};

const AssociatedPaymentCertificateLine = ({
  line,
  orderLines,
  financialAccounts,
  onOrderLineChange = (lineId) => {},
  onFinancialAccountChange = (financialAccountId, previousFinancialAccountId) => {},
  onAmountChange = (amount, previousAmount) => {},
  onRemove = () => {},
  editable = true,
  deletable = true,
}) => {
  const { lineId, financialAccountId, amount, deleted } = line;
  const selectedOrderLineRemainderExceeded =
    lineId && orderLines.find((orderLine) => orderLine.id === parseInt(lineId)).remainderExceeded;
  const errorTrackingOn = !deleted && editable;
  const inputDisabled = !editable || deleted;
  const lineInputInvalid = errorTrackingOn && selectedOrderLineRemainderExceeded;
  const financialAccountInputInvalid = errorTrackingOn && false;
  const amountInputInvalid = errorTrackingOn && (amount === 0 || selectedOrderLineRemainderExceeded);
  const removeButtonVisible = deletable && !deleted;
  return (
    <div style={{ position: 'relative' }}>
      {deleted && <ItemDeletedDisplay />}
      <Row className="mb-1">
        <Col sm={4} md={5}>
          <CustomSelect
            disabled={inputDisabled}
            value={lineId}
            onChange={(e) => {
              onOrderLineChange(e.target.value);
            }}
            invalid={lineInputInvalid}
          >
            <option value="">-- Seleziona un'attività</option>
            {orderLines.map((line) => (
              <option value={line.id} key={line.id}>
                {line.displayName}
                {editable && <> (Residuo: {format(line.remainder)}€)</>}
              </option>
            ))}
          </CustomSelect>
        </Col>
        <Col sm={4} md={4}>
          <CustomSelect
            disabled={inputDisabled}
            value={financialAccountId}
            onChange={(e) => {
              onFinancialAccountChange(e.target.value, financialAccountId);
            }}
            invalid={financialAccountInputInvalid}
          >
            <option value="">-- Seleziona un codice contabile</option>
            {financialAccounts.map((financialAccount) => (
              <option value={financialAccount.id} key={financialAccount.id}>
                {financialAccount.label}
              </option>
            ))}
          </CustomSelect>
        </Col>
        <Col sm={3} md={2}>
          <CustomInputNumber
            disabled={inputDisabled}
            value={amount}
            onChange={(e) => {
              onAmountChange(e.value, amount);
            }}
            invalid={amountInputInvalid}
          />
        </Col>
        {removeButtonVisible && (
          <Col sm={1}>
            <Button type="button" color="danger" size="sm" outline onClick={() => onRemove()}>
              &times;
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

const OrderLineAmountExceededErrorMessage = ({ displayName, remainder }) => (
  <div className="text-danger small mb-1">
    <i className="fa fa-exclamation-triangle" /> L'importo associato all'attività "{displayName}" supera l'importo
    residuo di {format(Math.abs(remainder))}€
  </div>
);

const OrderGroupErrors = ({ orderLines }) => (
  <>
    {orderLines.some((orderLine) => orderLine.remainderExceeded) && (
      <div>
        {orderLines
          .filter((orderLine) => orderLine.remainderExceeded)
          .map((orderLine) => (
            <OrderLineAmountExceededErrorMessage
              key={orderLine.id}
              displayName={orderLine.displayName}
              remainder={orderLine.remainder}
            />
          ))}
      </div>
    )}
  </>
);

const InvoiceAssociationList = ({
  commissions,
  financialAccounts,
  onAddLine,
  onOrderLineChange,
  onFinancialAccountChange,
  onAmountChange,
  onLineRemove,
  onDissociate,
  editable = true,
}) => {
  const handleAddLineClick = (commissionId, salesOrderId, paymentCertificateNumber) => {
    return () => {
      onAddLine({ commissionId, salesOrderId, paymentCertificateNumber });
    };
  };
  const handleOrderLineChange = (commissionId, salesOrderId, paymentCertificateNumber, rowIndex) => {
    return (orderLineId) => {
      onOrderLineChange({ commissionId, salesOrderId, paymentCertificateNumber, rowIndex, orderLineId });
    };
  };
  const handleFinancialAccountChange = (commissionId, salesOrderId, paymentCertificateNumber, rowIndex) => {
    return (financialAccountId, previousFinancialAccountId) => {
      onFinancialAccountChange({
        commissionId,
        salesOrderId,
        paymentCertificateNumber,
        rowIndex,
        financialAccountId,
        previousFinancialAccountId,
      });
    };
  };
  const handleAmountChange = (commissionId, salesOrderId, paymentCertificateNumber, rowIndex) => {
    return (amount, previousAmount) => {
      onAmountChange({ commissionId, salesOrderId, paymentCertificateNumber, rowIndex, amount, previousAmount });
    };
  };
  const handleLineRemove = (commissionId, salesOrderId, paymentCertificateNumber, rowIndex) => {
    return () => {
      onLineRemove({ commissionId, salesOrderId, paymentCertificateNumber, rowIndex });
    };
  };
  const handleDissociate = (commissionId, salesOrderId, paymentCertificateNumber) => {
    return () => {
      onDissociate({ commissionId, salesOrderId, paymentCertificateNumber });
    };
  };

  return (
    <div>
      {commissions.map((commission) => (
        <CommissionGroup
          key={commission.id}
          commission={{
            id: commission.id,
            description: commission.description,
            customerDisplayName: commission.customerDisplayName,
          }}
        >
          {commission.orders.map((order) => (
            <OrderGroup key={order.id} commissionId={commission.id} order={order}>
              {editable && <OrderGroupErrors orderLines={order.lines} />}
              {/* <pre>{JSON.stringify(order.lines, null, '\t')}</pre> */}
              <ListGroup>
                {order.paymentCertificates.map((paymentCertificate) => (
                  <ListGroupItem key={paymentCertificate.number} className="p-2">
                    <AssociatedPaymentCertificate
                      number={paymentCertificate.number}
                      note={paymentCertificate.note}
                      date={paymentCertificate.date}
                      taxableAmount={paymentCertificate.taxableAmount}
                      commissionId={commission.id}
                      salesOrderId={order.id}
                      deleted={paymentCertificate.deleted}
                      deletable={editable && !paymentCertificate.deleted}
                      editable={editable && !paymentCertificate.deleted}
                      onAddLineClick={handleAddLineClick(commission.id, order.id, paymentCertificate.number)}
                      onDissociate={handleDissociate(commission.id, order.id, paymentCertificate.number)}
                    >
                      {paymentCertificate.lines.map((line, index) => (
                        <AssociatedPaymentCertificateLine
                          key={index}
                          line={line}
                          orderLines={order.lines}
                          financialAccounts={financialAccounts}
                          deletable={
                            editable &&
                            !paymentCertificate.deleted &&
                            paymentCertificate.lines.filter((line) => !line.deleted).length > 1
                          }
                          editable={editable && !paymentCertificate.deleted}
                          onOrderLineChange={handleOrderLineChange(
                            commission.id,
                            order.id,
                            paymentCertificate.number,
                            index
                          )}
                          onFinancialAccountChange={handleFinancialAccountChange(
                            commission.id,
                            order.id,
                            paymentCertificate.number,
                            index
                          )}
                          onAmountChange={handleAmountChange(commission.id, order.id, paymentCertificate.number, index)}
                          onRemove={handleLineRemove(commission.id, order.id, paymentCertificate.number, index)}
                        />
                      ))}
                    </AssociatedPaymentCertificate>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </OrderGroup>
          ))}
        </CommissionGroup>
      ))}
    </div>
  );
};

export default InvoiceAssociationList;
