import useCommissionPermissions from 'hooks/useCommissionPermissions';
import usePurchaseOrderData from '../../hooks/usePurchaseOrderData';
import PurchaseOrderManagementProvider from '../PurchaseOrderManagementProvider';
import PurchaseOrderManagementSummary from './summary';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import PurchaseOrderManagementNavbar from './navbar';
import { Outlet } from 'react-router';

const PurchaseOrderManagementContainerInner = () => {
  const order = usePurchaseOrderData();
  console.debug(order);
  const { hasPermission } = useCommissionPermissions();
  return (
    <>
      {/* <pre>{JSON.stringify(order, undefined, '\t')}</pre> */}
      <PurchaseOrderManagementSummary
        commissionId={order.commissionId}
        orderId={order.id}
        orderDescription={order.shortDescription}
        supplierName={order.supplier.displayName}
        totalAmount={order.totalAmount}
        rejectionNote={order.rejectionNote}
        showRejectionNote={order.showRejectionNote}
      />
      <PurchaseOrderManagementNavbar
        commissionId={order.commissionId}
        purchaseOrderId={order.id}
        tabsVisibilityOverrides={{
          header: true,
          body: true,
          deadlines: order.deadLinesManagementAvailable && hasPermission(PURCHASE_ORDER_PERMISSIONS.DEADLINES.VIEW),
          attachments:
            order.attachmentManagementAvailable && hasPermission(PURCHASE_ORDER_PERMISSIONS.ATTACHMENTS.VIEW),
          paymentCertificates:
            order.paymentCertificatesManagementAvailable &&
            hasPermission(PURCHASE_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.VIEW),
          invoices: order.invoicesManagementAvailable && hasPermission(PURCHASE_ORDER_PERMISSIONS.INVOICES.VIEW),
        }}
      />
      <div className="py-2">
        <Outlet />
      </div>
    </>
  );
};

const PurchaseOrderManagementContainer = ({ children }) => {
  return (
    <PurchaseOrderManagementProvider>
      <PurchaseOrderManagementContainerInner />
    </PurchaseOrderManagementProvider>
  );
};

export default PurchaseOrderManagementContainer;
