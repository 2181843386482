import AsyncSelect from 'react-select/async';

const addLabelFields = (item, fields) => {
  return fields
    ?.map((field) => {
      return item[field];
    })
    .join(' - ');
};

const remapToValueFieldArray = (data, labelField, valueField) => {
  return data?.map((item) => ({
    value: item[valueField],
    label: addLabelFields(item, labelField),
  }));
};

const FormSelectSearchPlatinumEdition = ({
  id = '',
  name = '',
  placeholder = 'Cerca...',
  valuePropertyName = 'value',
  labelPropertyName = ['label'],
  searchInputLength = 1,
  loadingMessage = 'Caricamento...',
  noOptionsMessage = 'Nessun elemento trovato',
  disabled = false,
  cacheOptions = true,
  value = undefined,
  defaultValue = undefined,
  handleSelectedValue = (optionSelected) => {},
  onFetchData = async (inputValue) => {
    return [];
  },
}) => {
  const loadOptions = (inputValue, callback) => {
    if (inputValue.length < searchInputLength) return callback([]);

    onFetchData(inputValue).then((resp) => {
      callback(remapToValueFieldArray(resp, labelPropertyName, valuePropertyName));
    });
  };


  return (
    <AsyncSelect
      placeholder={placeholder}
      loadOptions={loadOptions}
      onChange={handleSelectedValue}
      id={id}
      name={name}
      loadingMessage={() => loadingMessage}
      noOptionsMessage={() => noOptionsMessage}
      cacheOptions={cacheOptions}
      isDisabled={disabled}
      value={value && { value: value[valuePropertyName], label: value[labelPropertyName] }}
      defaultValue={defaultValue && { value: defaultValue[valuePropertyName], label: defaultValue[labelPropertyName] }}
    />
  );
};

export default FormSelectSearchPlatinumEdition;
