const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 404:
      return {
        success: false,
        message: "nessun dato trovato",
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: "Si è verificato un errore",
      };
  }
};

const fetchSalesOrdersExport = async (requestData) => {
  if (!requestData)
    return {
      ok: false,
      message: "Errore paramentri assenti o non corretti",
    };

  const request = {
    CompanyId: requestData.companyId,
    CurrentPage: requestData.currentPage,
    NumRecords: requestData.numRecords,
    Sort: requestData.sort,
    SortColumn: requestData.sortColumn,
    CommissionFilter: requestData.commissionId,
    SalesOrderFilter: requestData.salesOrderId,
    BusinessNameFilter: requestData.businessName,
    TaxCodeFilter: requestData.taxCode,
    DateToFilter: requestData.orderDateTo,
    DateFromFIlter: requestData.orderDateFrom,
    StatusIdFilter: requestData.statusId?.id,
  };

  // rimuovere i parametri con valori undefined o null
  const validParams = {};
  for (const key in request) {
    if (request[key] !== undefined && request[key] !== null) {
      validParams[key] = request[key];
    }
  }

  const queryString = new URLSearchParams(validParams).toString();

  const response = await fetch(`/api/sales-orders-export?${queryString}`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const fetchSalesOrderStatus = async () => {
    const response = await fetch('/api/sales-orders/status');
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json()
      };
    }
      return handleErrorResponse(response);
  };

export {
    fetchSalesOrdersExport,
    fetchSalesOrderStatus,
}
