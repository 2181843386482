import SalesInvoiceAssociationItem from '../association-item';

const SalesInvoiceAssociationList = ({ invoiceAssociations }) => {
  return invoiceAssociations.length > 0 ? (
    invoiceAssociations.map((invoiceAssociation) => (
      <SalesInvoiceAssociationItem
        key={`invoiceAssociation-${invoiceAssociation.id}`}
        invoiceAssociation={invoiceAssociation}
      />
    ))
  ) : (
    <div>Nessuna fattura associata a quest'ordine.</div>
  );
};

export default SalesInvoiceAssociationList;
