import { createContext } from 'react';

const FeedbackContext = createContext({
  success: (message, title) => {},
  warning: (message, title) => {},
  info: (message, title) => {},
  error: (message, title) => {},
});

export default FeedbackContext;
