import Loader from 'components/layout/Loader';
import DataTable from 'components/shared/DataTable';
import useEnterEditMode from 'hooks/forms/useEnterEditMode';
import useAuth from 'hooks/useAuth';
import useCommission from 'hooks/useCommission';
import { DataTableBuilder } from 'modules/DataTable';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Button, Col, Form, FormGroup, FormText, Input, Row } from 'reactstrap';
import ROUTES from 'routes';
import { searchSuppliers } from 'services/api/suppliers';

const PAGE_SIZES = [20];

const STEP_2_BASE_ROUTE = ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.CREATE + '/step-2/:supplierId';

const SearchSupplierForm = ({ searchTextMinCharacters, onSearchSubmit }) => {
  const [searchText, setSearchText] = useState('');
  const [searchTextError, setSearchTextError] = useState(null);

  const handleOnSearchTextChange = (e) => {
    setSearchText(e.target.value);
    if (Boolean(searchTextError) && e.target.value.length >= searchTextMinCharacters) {
      setSearchTextError(null);
    }
  };

  const handleOnSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTextMinCharacters && searchText.length < searchTextMinCharacters) {
      setSearchTextError(`Sono richiesti almeno ${searchTextMinCharacters} caratteri per procedere.`);
    } else {
      onSearchSubmit?.(searchText);
    }
  };

  return (
    <div className="mb-2">
      <h4 className="text-dark">Cerca fornitore</h4>
      <Form onSubmit={handleOnSearchSubmit}>
        <Row>
          <Col md={10} lg={8} xl={6}>
            <FormGroup className="mb-0">
              <Input
                invalid={Boolean(searchTextError)}
                name="search"
                bsSize="sm"
                placeholder="Cerca per ragione sociale, P.IVA o codice fornitore..."
                value={searchText}
                onChange={handleOnSearchTextChange}
              />
              {searchTextError && <FormText color="danger">{searchTextError}</FormText>}
            </FormGroup>
          </Col>
          <Col md={2}>
            <Button type="submit" color="primary" size="sm" block>
              Cerca
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const useSearchSuppliers = () => {
  const [loading, setLoading] = useState(false);
  const [suppliersResult, setSuppliersResult] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { logout } = useAuth();

  const search = useCallback(
    async (companyId, searchText, offset, limit) => {
      setLoading(true);
      const { ok, status, data } = await searchSuppliers(companyId, searchText, offset, limit);
      if (ok) {
        setSuppliersResult(data);
        setErrorMessage(undefined);
      } else {
        let errorMessage;
        switch (status) {
          case 401:
            errorMessage = "Effettua l'accesso per continuare";
            logout();
            break;
          case 403:
            errorMessage = 'Non sei autorizzato a vedere questa pagina';
            break;
          default:
            errorMessage = 'Si è verificato un errore';
            break;
        }
        setSuppliersResult(undefined);
        setErrorMessage(errorMessage);
      }
      setLoading(false);
    },
    [logout]
  );

  return {
    search,
    loading,
    suppliers: suppliersResult?.suppliers,
    totalSuppliers: suppliersResult?.total ?? 0,
    errorMessage,
  };
};

const createStep2Link = (commissionId, supplierId) =>
  STEP_2_BASE_ROUTE.replace(':commissionId', commissionId).replace(':supplierId', supplierId);

const { columns: COLUMN_DEFINITIONS } = new DataTableBuilder()
  .addColumn('code', (config) => config.heading('Cod. E-Solver').type('number').size('sm'))
  .addColumn('businessName', (config) => config.heading('Ragione sociale').size('lg').truncate().tooltip())
  .addColumn('vatNumber', (config) => config.heading('P.IVA').truncate().tooltip())
  .addColumn('email', (config) => config.heading('E-mail').size('lg').truncate().tooltip())
  .addColumn('address', (config) => config.heading('Indirizzo').size('lg').truncate().tooltip())
  .addColumn('id', (config) => {
    config
      .heading('')
      .size('sm')
      .horizontalAlignment('center')
      .cellTemplate((supplierId, { businessName }, { commissionId }) => (
        <NavLink to={createStep2Link(commissionId, supplierId)} title={`Seleziona fornitore ${businessName}`}>
          Continua &raquo;
        </NavLink>
      ));
  })
  .build();

const SearchedSuppliersTable = ({ commissionId, suppliers, totalSuppliers, pageOffset, pageSize, onPageChange }) => (
  <DataTable
    data={suppliers}
    column={COLUMN_DEFINITIONS}
    context={{ commissionId }}
    responsive
    currentPage={pageOffset}
    pageSizes={PAGE_SIZES}
    pageSize={pageSize}
    totRecords={totalSuppliers}
    onHandlePageChange={onPageChange}
    size="sm"
    fixedLayout
  />
);

const SearchError = ({ children }) => {
  return <p className="text-danger">{children}</p>;
};

const ChooseSupplierStepPage = () => {
  const [searchText, setSearchText] = useState('');
  const [pageOffset, setPageOffset] = useState(0);
  const [pageSize /*, setPageSize*/] = useState(PAGE_SIZES[0]);
  const { search, loading: searchSupplierLoading, suppliers, totalSuppliers, errorMessage } = useSearchSuppliers();

  const { commissionId } = useParams();
  const { companyId } = useCommission();

  useEnterEditMode();

  useEffect(() => {
    search(companyId, searchText, pageOffset * pageSize, pageSize);
  }, [companyId, pageOffset, pageSize, searchText, search]);

  const handleOnSearchSupplierFormSubmit = async (searchText) => {
    setSearchText(searchText);
    setPageOffset(0);
  };

  const handleOnTablePageOffsetChange = async (pageOffset) => {
    console.debug(`pageOffset changed to ${pageOffset}`);
    setPageOffset(pageOffset);
  };

  return (
    <section>
      <SearchSupplierForm searchTextMinCharacters={0} onSearchSubmit={handleOnSearchSupplierFormSubmit} />
      {searchSupplierLoading && <Loader />}
      {suppliers && (
        <SearchedSuppliersTable
          commissionId={commissionId}
          suppliers={suppliers}
          totalSuppliers={totalSuppliers}
          onPageChange={handleOnTablePageOffsetChange}
          pageOffset={pageOffset}
          pageSize={pageSize}
        />
      )}
      {errorMessage && <SearchError>{errorMessage}</SearchError>}
    </section>
  );
};

export default ChooseSupplierStepPage;
