const dissociateInvoiceAssociation = (invoiceAssociations, payload) => {
    const { commissionId, salesOrderId, paymentCertificateNumber } = payload;
    console.debug(payload);
    let nextCommissions = invoiceAssociations;
    commissionLoop: for (const commission of invoiceAssociations) {
        if (commission.id !== commissionId) {
            continue;
        }
        for (const order of commission.orders) {
            if (order.id !== salesOrderId) {
                continue;
            }

            for (const certificate of order.paymentCertificates) {
                if (certificate.number !== paymentCertificateNumber) {
                    console.debug(
                        `certificate.number (${certificate.number}) !== paymentCertificateNumber (${paymentCertificateNumber}).`
                    );
                    continue;
                }
                // 1: the payment certificate has not been saved on the server yet
                if (isNaN(certificate.salesInvoiceOrderId)) {
                    console.debug('1');
                    // 1-A: the order only has 1 payment certificate associated to it
                    if (order.paymentCertificates.length === 1) {
                        console.debug('1-A');
                        // ... and the commission only has 1 order associated to it
                        // >>  eliminate the commission
                        if (commission.orders.length === 1) {
                            console.log('1-A / A');
                            nextCommissions = invoiceAssociations.filter((c) => c.id !== commissionId);
                        }
                        // ... but the commission has 1+ orders associated to it
                        // >>  eliminate the order
                        else {
                            console.log('1-A / B');
                            nextCommissions = invoiceAssociations.map((c) =>
                                c.id === commissionId
                                    ? { ...c, orders: c.orders.filter((o) => o.id !== salesOrderId) }
                                    : c
                            );
                        }
                    }
                    // 1-B: the order has 1+ payment certificates associated to it
                    // >>   eliminate the specified payment certificate
                    else {
                        console.debug('1-B');
                        nextCommissions = invoiceAssociations.map((c) =>
                            c.id === commissionId
                                ? {
                                      ...c,
                                      orders: c.orders.map((o) =>
                                          o.id === salesOrderId
                                              ? {
                                                    ...o,
                                                    paymentCertificates: o.paymentCertificates.filter(
                                                        (pc) => pc.number !== paymentCertificateNumber
                                                    ),
                                                }
                                              : o
                                      ),
                                  }
                                : c
                        );
                        console.debug(nextCommissions);
                    }
                }
                // 2: the payment certificate has been previously saved on the server...
                // >> mark the payment certificates as deleted and force its lines to have the deleted = false
                else {
                    console.debug('2');
                    nextCommissions = invoiceAssociations.map((c) =>
                        c.id === commissionId
                            ? {
                                  ...c,
                                  orders: c.orders.map((o) =>
                                      o.id === salesOrderId
                                          ? {
                                                ...o,
                                                paymentCertificates: o.paymentCertificates.map((pc) =>
                                                    pc.number === paymentCertificateNumber
                                                        ? {
                                                              ...pc,
                                                              deleted: true,
                                                              lines: pc.lines.map((pcl) =>
                                                                  pcl.deleted === false
                                                                      ? pcl
                                                                      : { ...pcl, deleted: false }
                                                              ),
                                                          }
                                                        : pc
                                                ),
                                            }
                                          : o
                                  ),
                              }
                            : c
                    );
                }
                break commissionLoop;
            }
        }
    }
    return nextCommissions;
};

export default dissociateInvoiceAssociation;
