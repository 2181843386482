import FormSelectSearchPlatinumEdition from 'components/forms/FormSelectSearch_platinumEdition';

const InputSearch = ({
  name = undefined,
  id = name,
  placeholder = 'Cerca...',
  valuePropertyName = 'value',
  labelPropertyName = 'label',
  searchInputLength = 1,
  loadingMessage = 'Caricamento...',
  noOptionsMessage = 'Nessun elemento trovato',
  disabled = false,
  cacheOptions = false,
  value = undefined,
  defaultValue = undefined,
  onChange = (optionSelected) => {},
  onFetchData = async (inputValue) => {
    return [];
  },
}) => {
  return (
    <FormSelectSearchPlatinumEdition
      name={name}
      id={id}
      placeholder={placeholder}
      valuePropertyName={valuePropertyName}
      labelPropertyName={[labelPropertyName]}
      searchInputLength={searchInputLength}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      disabled={disabled}
      handleSelectedValue={onChange}
      onFetchData={onFetchData}
      cacheOptions={cacheOptions}
      value={value}
      defaultValue={defaultValue}
    />
  );
};

export default InputSearch;
