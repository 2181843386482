import { Col, Row } from 'reactstrap';

const FatturaHeader = (props) => {
  return (
    <Row>
      <Col>
        <label style={STYLES.noWrap}>
          Fornitore: <span style={STYLES.bold}>{props?.Fornitore}</span>
        </label>
      </Col>
      <Col>
        <label style={STYLES.noWrap}>
          P.IVA.: <span style={STYLES.bold}>{props?.PIVA}</span>
        </label>
      </Col>
      {/* <Col>
        <label style={STYLES.noWrap}>
          Nr. Fatt. Fornitore: <span style={STYLES.bold}>{props?.FatturaFornitore}</span>
        </label>
      </Col>
      <Col>
        <label style={STYLES.noWrap}>
          del:{' '}
          <span style={STYLES.bold}>
            {props?.DataFattura &&
              new Date(props.DataFattura).toLocaleDateString('it-IT', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
          </span>
        </label>
      </Col> */}
      <Col>
        <label style={STYLES.noWrap}>
          Modalità di pagamento: <span style={STYLES.bold}>{props?.ModPagamento}</span>
        </label>
      </Col>
    </Row>
  );
};

const STYLES = {
  noWrap: {
    whiteSpace: 'nowrap',
  },
  bold: {
    fontWeight: 'bold',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridColumnGap: '5px',
  },
};
export default FatturaHeader;
