import { useCallback, useEffect, useState } from 'react';
import PurchaseOrderManagementContext from '../../contexts/PurchaseOrderManagementContext';
import { useParams } from 'react-router';
import { fetchPurchaseOrderWithId } from 'services/api/purchase-orders';
import Loader from 'components/layout/Loader';
import { FormText } from 'reactstrap';

const PurchaseOrderManagementProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [purchaseOrderData, setPurchaseOrderData] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { commissionId, purchaseOrderId } = useParams();

  const doFetchPurchaseOrder = useCallback(async () => {
    setLoading(true);

    const response = await fetchPurchaseOrderWithId(commissionId, purchaseOrderId);

    if (response.ok) {
      console.log(response.data)
      setPurchaseOrderData({ ...response.data, commissionId });
      setErrorMessage(false);
    } else {
      setErrorMessage(response.message);
      setPurchaseOrderData(false);
    }

    setLoading(false);
  }, [commissionId, purchaseOrderId]);

  useEffect(() => {
    doFetchPurchaseOrder();
  }, [doFetchPurchaseOrder]);

  return (
    <section>
      {loading && <Loader />}
      {purchaseOrderData && (
        <PurchaseOrderManagementContext.Provider value={{ data: purchaseOrderData, invalidate: doFetchPurchaseOrder }}>
          {children}
        </PurchaseOrderManagementContext.Provider>
      )}
      {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
    </section>
  );
};

export default PurchaseOrderManagementProvider;
