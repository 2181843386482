const COMMISSION_PERMISSIONS = Object.freeze({
  VIEW: 100,
  EDIT: 101,
  DELETE: 102,
  OPEN: 110,
  CLOSE: 111,
});

const DOCUMENT_PERMISSIONS = Object.freeze({
  VIEW: 200,
  EDIT: 201,
  DOWNLOAD: 202,
});

const BUDGET_PERMISSIONS = Object.freeze({
  CREATE_EDIT: 300,
  REQUEST_APPROVAL: 301,
  APPROVE_REJECT: 302,
  BLOCK_ACTIVITY: 303,
  VIEW: 304,
});

const RESOURCE_PERMISSIONS = Object.freeze({
  VIEW: 400,
  EDIT: 401,
});

const PURCHASE_ORDER_PERMISSIONS = Object.freeze({
  VIEW: 700,
  CREATE_EDIT: 701,
  SETTLE: 702,
  REQUEST_APPROVAL: 703,
  SELF_APPROVE: 704,
  APPROVE_REJECT: 705,
  VOID: 706,
  EXPORT_PDF_ALL: 710,
  EXPORT_PDF_OWN: 711,
  PAYMENT_CERTIFICATES: Object.freeze({
    VIEW: 720,
    CREATE: 721,
    EDIT: 722,
    REQUEST_APPROVAL: 723,
    APPROVE_REJECT: 724,
    SELF_APPROVE: 725,
    DOWNLOAD: 730
  }),
  DEADLINES: Object.freeze({
    VIEW: 740,
    EDIT: 741,
  }),
  ATTACHMENTS: Object.freeze({
    VIEW: 760,
    EDIT: 761,
    DOWNLOAD: 762,
  }),
  INVOICES: Object.freeze({
    VIEW: 780,
  }),
});

const PURCHASE_INVOICE_PERMISSIONS = Object.freeze({
  VIEW: 900,
});

const SALES_INVOICE_PERMISSIONS = Object.freeze({
  VIEW: 1000,
});

const SALES_ORDER_PERMISSIONS = Object.freeze({
  VIEW: 800,
  CREATE_EDIT: 801,
  SETTLE: 802,
  REQUEST_APPROVAL: 803,
  SELF_APPROVE: 804,
  APPROVE_REJECT: 805,
  VOID: 806,
  PAYMENT_CERTIFICATES: Object.freeze({
    VIEW: 820,
    CREATE: 821,
    EDIT: 822,
    REQUEST_APPROVAL: 823,
    APPROVE_REJECT: 824,
    SELF_APPROVE: 825,
  }),
  ATTACHMENTS: Object.freeze({
    VIEW: 860,
    EDIT: 861,
    DOWNLOAD: 862,
  }),
  INVOICES: Object.freeze({
    VIEW: 880
  })
});

const COMMISSION_MANAGEMENT_PERMISSIONS = Object.freeze({
  COMMISSION: COMMISSION_PERMISSIONS,
  DOCUMENT: DOCUMENT_PERMISSIONS,
  BUDGET: BUDGET_PERMISSIONS,
  RESOURCE: RESOURCE_PERMISSIONS,
  PURCHASE_ORDER: PURCHASE_ORDER_PERMISSIONS,
  SALES_ORDER: SALES_ORDER_PERMISSIONS,
  PURCHASE_INVOICE: PURCHASE_INVOICE_PERMISSIONS,
});

export default COMMISSION_MANAGEMENT_PERMISSIONS;

export {
  COMMISSION_PERMISSIONS,
  DOCUMENT_PERMISSIONS,
  BUDGET_PERMISSIONS,
  RESOURCE_PERMISSIONS,
  PURCHASE_ORDER_PERMISSIONS,
  SALES_ORDER_PERMISSIONS,
  PURCHASE_INVOICE_PERMISSIONS,
  SALES_INVOICE_PERMISSIONS
};
