import AuthContext from 'contexts/AuthContext';
import { useContext, useState } from 'react';
import { Navigate } from 'react-router';
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';

const validateLoginRequest = (loginRequest) => {
  const errors = {};

  if (!loginRequest.userName.trim()) {
    errors.userName = 'Campo obbligatorio';
  }

  if (!loginRequest.password.trim()) {
    errors.password = 'Campo obbligatorio';
  }

  return {
    valid: Object.keys(errors).length === 0,
    errors,
  };
};

const INITIAL_DATA = {
  userName: '',
  password: '',
};

const LoginPage = () => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [errors, setErrors] = useState({});

  const { authenticated } = useContext(AuthContext);

  const { userName, password } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));

    if (errors[name]) {
      setErrors((errors) => {
        const { [name]: _, ...errorsWithoutChangedInput } = errors;
        return errorsWithoutChangedInput;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const request = { ...formData };

    const { valid, errors } = validateLoginRequest(request);

    setErrors(errors);

    if (valid) {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        window.location.replace('/');
      } else {
        const data = await response.json();
        if (data.errors) {
          const errors = {};
          for (const propertyName in data.errors) {
            errors[propertyName] = data.errors[propertyName][0];
          }
          setErrors(errors);
        } else {
          setErrors({ '': 'Si è verificato un errore.' });
        }
      }
    }
  };

  return authenticated ? (
    <Navigate to="/" />
  ) : (
    <div className="bg-gradient-dark" cz-shortcut-listen="true" style={{ height: '100vh' }}>
      <Container className="h-100">
        <Row className="align-items-center h-100">
          <Col md={9} lg={12} xl={10} className="mx-auto">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <Row>
                  <Col lg={6} className="d-none d-lg-block bg-login-image"></Col>
                  <Col lg={6}>
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Bentornato</h1>
                      </div>
                      <Form className="user" method="post" autoComplete="off" onSubmit={handleSubmit}>
                        <FormGroup>
                          <Input
                            type="text"
                            className="form-control-user"
                            placeholder="Inserisci username"
                            id="userName"
                            name="userName"
                            value={userName}
                            onChange={handleChange}
                            valid={userName !== '' && !errors.userName}
                            invalid={!!errors.userName}
                            autoComplete="off"
                          />
                          <div className="invalid-feedback">{errors.userName}</div>
                        </FormGroup>

                        <FormGroup>
                          <Input
                            type="password"
                            className="form-control-user"
                            placeholder="Inserisci password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            valid={password !== '' && !errors.password}
                            invalid={!!errors.password}
                            autoComplete="off"
                          />
                          <div className="invalid-feedback">{errors.password}</div>
                        </FormGroup>
                        <button type="submit" className="btn btn-primary btn-user btn-block btn-login">
                          Login
                        </button>
                        <input
                          name="__RequestVerificationToken"
                          type="hidden"
                          value="CfDJ8NRSnm3MWoZOk0plbI6xqMEhNmmT2pmzISPw10gkxhQaDKCjD7qVCJFMFe6RcDWU5K1219YYp8H-xFbVTu3USr-hmEWlePHsHmH8awwqiy41eBM2EQCuVol6ENeUoe3yqz9Bd_aYz975ECKwezQbUrM"
                        />
                      </Form>
                      <h1 className="h6 text-danger text-center mt-4">{errors['']}</h1>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
