const SET_SUBACTIVITY_DESCRIPTION = 'SET_SUBACTIVITY_DESCRIPTION';
const SET_SUBACTIVITY_EXPECTED_COST = 'SET_SUBACTIVITY_EXPECTED_COST';
const SET_SUBACTIVITY_EXPECTED_REVENUE = 'SET_SUBACTIVITY_EXPECTED_REVENUE';
const SET_SUBACTIVITY_PERIOD_COST = 'SET_SUBACTIVITY_PERIOD_COST';
const SET_SUBACTIVITY_PERIOD_REVENUE = 'SET_SUBACTIVITY_PERIOD_REVENUE';
const SET_ACTIVITIES = 'SET_ACTIVITIES';
const ADD_SUBACTIVITY = 'ADD_SUBACTIVITY';
const REMOVE_SUBACTIVITY = 'REMOVE_SUBACTIVITY';
const COMPLETE_SUBACTIVITY = 'COMPLETE_SUBACTIVITY';

const reduceSubactivity = (subactivity, action) => {
  switch (action.type) {
    case SET_SUBACTIVITY_DESCRIPTION:
      return { ...subactivity, description: action.description };
    case SET_SUBACTIVITY_EXPECTED_COST:
      return { ...subactivity, cost: action.cost };
    case SET_SUBACTIVITY_EXPECTED_REVENUE:
      return { ...subactivity, revenue: action.revenue };
    default:
      return subactivity;
  }
};

const reduceSubactivities = (subactivities, action) => {
  switch (action.type) {
    case SET_SUBACTIVITY_DESCRIPTION:
      return subactivities.map((subactivity, index) =>
        index !== action.subactivityIndex
          ? subactivity
          : reduceSubactivity(subactivity, { type: SET_SUBACTIVITY_DESCRIPTION, description: action.description })
      );
    case SET_SUBACTIVITY_EXPECTED_COST:
      return subactivities.map((subactivity, index) =>
        index !== action.subactivityIndex
          ? subactivity
          : reduceSubactivity(subactivity, { type: SET_SUBACTIVITY_EXPECTED_COST, cost: action.cost })
      );
    case SET_SUBACTIVITY_EXPECTED_REVENUE:
      return subactivities.map((subactivity, index) =>
        index !== action.subactivityIndex
          ? subactivity
          : reduceSubactivity(subactivity, { type: SET_SUBACTIVITY_EXPECTED_REVENUE, revenue: action.revenue })
      );
    default:
      return subactivities;
  }
};

const reduceActivity = (activity, action) => {
  switch (action.type) {
    case SET_SUBACTIVITY_DESCRIPTION:
      return {
        ...activity,
        subactivities: reduceSubactivities(activity.subactivities, {
          type: SET_SUBACTIVITY_DESCRIPTION,
          subactivityIndex: action.subactivityIndex,
          description: action.description,
        }),
      };
    case SET_SUBACTIVITY_EXPECTED_COST:
      return {
        ...activity,
        subactivities: reduceSubactivities(activity.subactivities, {
          type: SET_SUBACTIVITY_EXPECTED_COST,
          subactivityIndex: action.subactivityIndex,
          cost: action.cost,
        }),
      };
    case SET_SUBACTIVITY_EXPECTED_REVENUE:
      return {
        ...activity,
        subactivities: reduceSubactivities(activity.subactivities, {
          type: SET_SUBACTIVITY_EXPECTED_REVENUE,
          subactivityIndex: action.subactivityIndex,
          revenue: action.revenue,
        }),
      };
    default:
      return activity;
  }
};

const reduceBudget = (budget, action) => {
  switch (action.type) {
    case SET_ACTIVITIES:
      return { ...budget, activities: action.activities };
    case SET_SUBACTIVITY_DESCRIPTION:
      return {
        ...budget,
        activities: budget.activities.map((activity) =>
          activity.id !== action.activityId
            ? activity
            : reduceActivity(activity, {
                type: SET_SUBACTIVITY_DESCRIPTION,
                subactivityIndex: action.subactivityIndex,
                description: action.description,
              })
        ),
      };
    case SET_SUBACTIVITY_EXPECTED_COST:
      return {
        ...budget,
        activities: budget.activities.map((activity) =>
          activity.id !== action.activityId
            ? activity
            : reduceActivity(activity, {
                type: SET_SUBACTIVITY_EXPECTED_COST,
                subactivityIndex: action.subactivityIndex,
                cost: action.cost,
              })
        ),
      };
    case SET_SUBACTIVITY_EXPECTED_REVENUE:
      return {
        ...budget,
        activities: budget.activities.map((activity) =>
          activity.id !== action.activityId
            ? activity
            : reduceActivity(activity, {
                type: SET_SUBACTIVITY_EXPECTED_REVENUE,
                subactivityIndex: action.subactivityIndex,
                revenue: action.revenue,
              })
        ),
      };
    case SET_SUBACTIVITY_PERIOD_COST:
      return {
        ...budget,
        activities: budget.activities.map((activity) =>
          activity.id !== action.activityId
            ? activity
            : {
                ...activity,
                subactivities: activity.subactivities.map((s, i) => {
                  if (i !== action.subactivityIndex) {
                    return s;
                  }

                  const costRevenueIndex = s.costRevenueProspectus.findIndex(
                    (cr) => cr.period.year === action.period.year && cr.period.month === action.period.month
                  );

                  // ... if not exists period, add period with cost
                  if (costRevenueIndex === -1) {
                    return {
                      ...s,
                      costRevenueProspectus: [
                        ...s.costRevenueProspectus,
                        { period: { ...action.period }, cost: action.cost },
                      ],
                    };
                  }

                  const updatedCostRevenueProspectus = [...s.costRevenueProspectus];

                  updatedCostRevenueProspectus[costRevenueIndex] = {
                    ...s.costRevenueProspectus[costRevenueIndex],
                    cost: action.cost,
                  };

                  return { ...s, costRevenueProspectus: updatedCostRevenueProspectus };
                }),
              }
        ),
      };
    case SET_SUBACTIVITY_PERIOD_REVENUE:
      return {
        ...budget,
        activities: budget.activities.map((activity) =>
          activity.id !== action.activityId
            ? activity
            : {
                ...activity,
                subactivities: activity.subactivities.map((s, i) => {
                  if (i !== action.subactivityIndex) {
                    return s;
                  }

                  const costRevenueIndex = s.costRevenueProspectus.findIndex(
                    (cr) => cr.period.year === action.period.year && cr.period.month === action.period.month
                  );

                  // ... if not exists period, add period with revenue
                  if (costRevenueIndex === -1) {
                    return {
                      ...s,
                      costRevenueProspectus: [
                        ...s.costRevenueProspectus,
                        { period: { ...action.period }, revenue: action.revenue },
                      ],
                    };
                  }

                  const updatedCostRevenueProspectus = [...s.costRevenueProspectus];

                  updatedCostRevenueProspectus[costRevenueIndex] = {
                    ...s.costRevenueProspectus[costRevenueIndex],
                    revenue: action.revenue,
                  };

                  return { ...s, costRevenueProspectus: updatedCostRevenueProspectus };
                }),
              }
        ),
      };
    case ADD_SUBACTIVITY:
      return {
        ...budget,
        activities: budget.activities.map((activity) =>
          activity.id !== action.activityId
            ? activity
            : {
                ...activity,
                subactivities: [
                  ...activity.subactivities,
                  {
                    id: activity.subactivities.length + 1, //activity.id + '.' + (activity.subactivities.length + 1),
                    activityId: activity.id,
                    description: '',
                    costRevenueProspectus: [],
                    completed: false,
                    readonly: false,
                  },
                ],
              }
        ),
      };
    case REMOVE_SUBACTIVITY:
      return {
        ...budget,
        activities: budget.activities.map((activity) =>
          activity.id !== action.activityId
            ? activity
            : {
                ...activity,
                subactivities: activity.subactivities
                  .filter((s, i) => i !== action.subactivityIndex)
                  .map((s, i) => (i < action.subactivityIndex ? s : { ...s, id: i + 1 })),
              }
        ),
      };
    case COMPLETE_SUBACTIVITY:
      return {
        ...budget,
        activities: budget.activities.map((activity) =>
          activity.id !== action.activityId
            ? activity
            : {
                ...activity,
                subactivities: activity.subactivities.map((s, i) =>
                  i !== action.subactivityIndex ? s : { ...s, completable: false, completed: true }
                ),
              }
        ),
      };

    default:
      return budget;
  }
};

const setSubactivityDescription = (activityId, subactivityIndex, description) => {
  return { type: SET_SUBACTIVITY_DESCRIPTION, activityId, subactivityIndex, description };
};

const setSubactivityCost = (activityId, subactivityIndex, cost) => {
  return { type: SET_SUBACTIVITY_EXPECTED_COST, activityId, subactivityIndex, cost };
};

const setSubactivityRevenue = (activityId, subactivityIndex, revenue) => {
  return { type: SET_SUBACTIVITY_EXPECTED_REVENUE, activityId, subactivityIndex, revenue };
};

const setSubactivityPeriodCost = (activityId, subactivityIndex, period, cost) => {
  return { type: SET_SUBACTIVITY_PERIOD_COST, activityId, subactivityIndex, period, cost };
};

const setSubactivityPeriodRevenue = (activityId, subactivityIndex, period, revenue) => {
  return { type: SET_SUBACTIVITY_PERIOD_REVENUE, activityId, subactivityIndex, period, revenue };
};

const setActivities = (activities) => {
  return { type: SET_ACTIVITIES, activities };
};

const addSubactivity = (activityId) => {
  return { type: ADD_SUBACTIVITY, activityId };
};

const removeSubactivity = (activityId, subactivityIndex) => {
  return { type: REMOVE_SUBACTIVITY, activityId, subactivityIndex };
};

const completeSubactivity = (activityId, subactivityIndex) => {
  return { type: COMPLETE_SUBACTIVITY, activityId, subactivityIndex };
};

export {
  reduceBudget,
  setActivities,
  setSubactivityDescription,
  setSubactivityCost,
  setSubactivityRevenue,
  setSubactivityPeriodCost,
  setSubactivityPeriodRevenue,
  addSubactivity,
  removeSubactivity,
  completeSubactivity,
};
