import { useCallback, useEffect, useState } from "react";
import useParseCustomResponse from "./useParseCustomResponse";
import useFeedback from "hooks/useFeedback";
import { fetchSalesInvoicesExport } from "services/api/sales-invoices-export/sales-invoices-export";

// crea le options per input type select status
// const createStatusOptions = (statuses) => {
//   return statuses.map((data) => ({
//     id: data.statusId,
//     description: data.description,
//   }));
// };

const mapToIndexData = (data) => {
  return {
    invoiceNumber: data.invoice.invoiceNumber,
    commissionId: data.commission.id,
    commissionDescription: data.commission.description,
    businessName: data.customer.businessName,
    invoiceAmount: data.invoice.invoiceAmount,
    associatedAmount: data.invoice.associatedAmount,
    emissionDate: data.invoice.emissionDate,
    registrationDate: data.invoice.registrationDate,
    salesOrderId: data.invoice.salesOrderId,
    paymentCertificateId: data.invoice.paymentCertificateId
    // statusId: data.status.id, // i due campi di stato servono per i filtri del DataTable
    // status: data.status,
  };
};

const useFetchExportSalesInvoices = (companyId, initialPageSize) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
//   const [responseStatuses, setResponseStatuses] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const refreshSalesInvoices = async (request) => {
    setLoading(true);
    fetchSalesInvoicesExport(request)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          const resp = {
            ...response,
            exportSalesInvoices: parsedResponse.data.salesInvoices.map(
              (salesInvoice) => mapToIndexData(salesInvoice)
            ),
            totRecords: parsedResponse.data.totRecords,
          };
          setResponse(resp);
        }
        else
        {
          error(parsedResponse.message)
          setErrorMessage(parsedResponse.message)
        }
      })
      .catch(() => {
        setErrorMessage();
      })
      .finally(() => setLoading(false));
  };

  const fetchData = useCallback(async () => {
    const [responseExportSalesInvoicesRaw] = 
      await Promise.all([
        fetchSalesInvoicesExport({
          companyId,
          currentPage: 0,
          numRecords: initialPageSize,
        }),
      ]).catch(() => {
        error("Errore durante il download dei dati", "Errore");
      });

    return {
      responseExportSalesInvoices: parseCustomResponse(
        responseExportSalesInvoicesRaw
      ),
    };
  }, [companyId, initialPageSize, parseCustomResponse, error]);

  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(({ responseExportSalesInvoices}) => {
        if(responseExportSalesInvoices.success)
        {
          setResponse({
            exportSalesInvoices:
              responseExportSalesInvoices.data.salesInvoices.map(
                (salesInvoice) => mapToIndexData(salesInvoice)
              ),
            totRecords: responseExportSalesInvoices.data.totRecords,
          });
        } else {
          error(responseExportSalesInvoices.message)
          setErrorMessage(responseExportSalesInvoices.message)
        }

      })
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => setLoading(false));
  }, [error, fetchData]);

  return {
    loading,
    response,
    // responseStatuses,
    errorMessage,
    refreshSalesInvoices,
  };
};

export default useFetchExportSalesInvoices;
