import { useParams } from 'react-router-dom';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import useCommission from 'hooks/useCommission';
import { routeFactory } from 'routes';
import PageHeader, { PageButtonContainer, PageLink } from 'components/layout/PageHeader';
import UncontrolledPurchaseOrdersDataTable from 'components/purchase-orders/UncontrolledPurchaseOrdersDataTable';

const TABLE_IDENTIFIER = 'purchase-orders';
const PAGE_SIZES = [];
const INITIAL_PAGE_SIZE = PAGE_SIZES[0];

const createCreatePurchaseOrderHref = (commissionId) =>
  `${routeFactory.commissionManagement.purchaseOrders.create(commissionId)}/step-1`;

const CreatePurchaseOrderButton = ({ commissionId }) => (
  <PageLink iconName="plus" text="Crea ordine" to={createCreatePurchaseOrderHref(commissionId)} primary />
);

const PurchaseOrderIndexPage = () => {
  const { commissionId } = useParams();

  const { isCommissionClosed } = useCommission();
  const { hasPermission } = useCommissionPermissions();

  const canCreateOrder = hasPermission(PURCHASE_ORDER_PERMISSIONS.CREATE_EDIT) && !isCommissionClosed;

  return (
    <>
      <PageHeader title={`Elenco ordini ${commissionId}`}>
        {canCreateOrder && (
          <PageButtonContainer>
            <CreatePurchaseOrderButton commissionId={commissionId} />
          </PageButtonContainer>
        )}
      </PageHeader>
      <UncontrolledPurchaseOrdersDataTable
        key={commissionId}
        tableIdentifier={TABLE_IDENTIFIER}
        initialFilters={{ commissionId }}
        initialPageSize={INITIAL_PAGE_SIZE}
        pageSizes={PAGE_SIZES}
      />
    </>
  );
};

export default PurchaseOrderIndexPage;
