const getInvoiceAssociationDisplayName = (invoiceAssociation) =>
  `l'associazione tra la fattura n° ${invoiceAssociation.invoiceNumber} e l'ordine ${
    invoiceAssociation.purchaseOrderId
  }${
    Boolean(invoiceAssociation.paymentCertificateNumber)
      ? ` (cdp nr. ${invoiceAssociation.paymentCertificateNumber})`
      : ''
  }`;

const createApprovalConfirmMessage = (invoiceAssociation) => {
  return `Sei sicuro di voler approvare ${getInvoiceAssociationDisplayName(
    invoiceAssociation
  )}? Se confermi, non potrai più annullare questa operazione.`;
};

const createRejectionPromptMessage = (invoiceAssociation) => {
  return `Perché vuoi restituire al mittente ${getInvoiceAssociationDisplayName(invoiceAssociation)}?`;
};

const createRejectionConfirmMessage = (invoiceAssociation) => {
  return `Sei sicuro di voler restituire al mittente ${getInvoiceAssociationDisplayName(
    invoiceAssociation
  )}? Se confermi, non potrai più annullare questa operazione.`;
};

const createSuspensionPromptMessage = (invoiceAssociation) => {
  return `Perché vuoi sospendere ${getInvoiceAssociationDisplayName(invoiceAssociation)}?`;
};

const createSuspensionConfirmMessage = (invoiceAssociation) => {
  return `Sei sicuro di voler sospendere ${getInvoiceAssociationDisplayName(invoiceAssociation)}?`;
};

const createResumeConfirmMessage = (invoiceAssociation) => {
  return `Sei sicuro di voler rimettere in approvazione ${getInvoiceAssociationDisplayName(invoiceAssociation)}?`;
};

export {
  createApprovalConfirmMessage,
  createRejectionPromptMessage,
  createRejectionConfirmMessage,
  createSuspensionPromptMessage,
  createSuspensionConfirmMessage,
  createResumeConfirmMessage,
};
