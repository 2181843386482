import { useCallback, useEffect, useState } from 'react';
import Loader from 'components/layout/Loader';
import { useNavigate, useParams } from 'react-router';
import ROUTES from 'routes';
import { createBudgetVersion, fetchCurrentBudgetInfo } from 'services/api/budget';
import useFeedback from 'hooks/useFeedback';
import { Button } from 'reactstrap';
import useCommission from 'hooks/useCommission';
import COMMISSION_MANAGEMENT_PERMISSIONS from 'constants/commission-management-permissions';

const BudgetCrossroadsPage = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { commissionId } = useParams();
  const navigate = useNavigate();

  const { error } = useFeedback();
  const { permissions, isCommissionClosed } = useCommission();

  const canCreateBudget = !isCommissionClosed && permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.CREATE_EDIT);

  const doFetchCurrentBudgetInfo = useCallback(async () => {
    setInitialLoading(true);
    const info = await fetchCurrentBudgetInfo(commissionId);
    if (info.latestApprovedVersion) {
      navigate(
        ROUTES.COMMISSION_MANAGEMENT.VIEW_BUDGET.replace(':commissionId', commissionId).replace(
          ':version',
          info.latestApprovedVersion
        ),
        { replace: true }
      );
    } else if (info.latestVersion) {
      navigate(
        ROUTES.COMMISSION_MANAGEMENT.VIEW_BUDGET.replace(':commissionId', commissionId).replace(
          ':version',
          info.latestVersion
        ),
        { replace: true }
      );
    }
    setInitialLoading(false);
  }, [commissionId, navigate]);

  useEffect(() => {
    doFetchCurrentBudgetInfo();
  }, [doFetchCurrentBudgetInfo]);

  const handleOnCreate = async () => {
    setSubmitting(true);
    const response = await createBudgetVersion(commissionId, 1);
    if (response.ok) {
      const createdBudgetData = response.data;
      navigate(
        ROUTES.COMMISSION_MANAGEMENT.EDIT_BUDGET.replace(':commissionId', commissionId).replace(
          ':version',
          createdBudgetData.version
        ),
        { replace: true }
      );
    } else {
      error(response.data?.detail || 'Si è verificato un errore');
    }
    setSubmitting(false);
  };

  return initialLoading ? (
    <Loader />
  ) : (
    <>
      {submitting && <Loader />}
      <>
        <p>Non è ancora stato creato un budget per questa commessa.{canCreateBudget && ' Vuoi crearne uno?'}</p>
        {canCreateBudget && (
          <Button type="button" color="primary" onClick={handleOnCreate}>
            CREA
          </Button>
        )}
      </>
    </>
  );
};

export default BudgetCrossroadsPage;
