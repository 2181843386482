import ColumnBuilder from './ColumnBuilder';

class DataTableBuilder {
  constructor() {
    this._columns = [];
  }
  /**
   * 
   * @param {string} columnName 
   * @param {(columnBuilder: ColumnBuilder) => {}} buildAction 
   * @returns {DataTableBuilder}
   */
  addColumn(columnName, buildAction) {
    const columnBuilder = new ColumnBuilder(columnName);
    if (typeof buildAction === 'function') {
      buildAction(columnBuilder);
    }
    this._columns.push(columnBuilder.column);
    return this;
    }
  /**
   * @deprecated use build method
   */
  get columns() {
    return this._columns;
  }
  build() {
    return { columns: [...this._columns] };
  }
}

export default DataTableBuilder;
