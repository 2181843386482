const handleErrorResponse = (response) => {
    switch (response.status) {
      case 401:
        return {
          success: false,
          authenticated: false,
        };
      case 403:
        return {
          success: false,
          authenticated: true,
          authorized: false,
        };
        case 404:
          return {
            success: false,
            authenticated: true,
            authorized: true,
            message:"Il file richiesto non esiste o è stato rimosso"
          };
      case 500:
      case 504:
      default:
        return {
          success: false,
          message: 'Si è verificato un errore',
        };
    }
  };

  const fetchSalesOrderAttachments = async (orderId) => {
    const response =  await fetch(`/api/sales-order/${orderId}/attachments`);
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
      return handleErrorResponse(response);
  };

  const insertSalesOrderAttachment = async (orderId, postData) => {
    const response =  await fetch(`/api/sales-order/${orderId}/attachments`, { method: 'POST', body: postData });
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
      return handleErrorResponse(response);
  };

  const updateSalesOrderAttachment = async (salesOrderId, attachmentId, postData) => {
    
    const response =  await fetch(`/api/sales-order/${salesOrderId}/attachments/update/${attachmentId}`, { method: 'PUT', body: postData });
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true
      };
    }
      return handleErrorResponse(response);
  };

  const deleteSalesOrderAttachment = async (orderId, attachmentId) => {
    const response = await fetch(`/api/sales-order/${orderId}/attachments/${attachmentId}`, { method: 'DELETE' })
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true
      };
    }
    return handleErrorResponse(response);
  };

  export {
    fetchSalesOrderAttachments,
    insertSalesOrderAttachment,
    updateSalesOrderAttachment,
    deleteSalesOrderAttachment
}