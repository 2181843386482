import { useEffect, useState } from 'react';
import { fetchTableColumnsOverrides } from 'services/api/configuration/table-overrides';

/**
 *
 * @param {string} tableName
 * @param {string} roleCode
 * @returns {{overrides: any[] | undefined}}
 */
const useDataTableOverrides = (tableName, roleCode) => {
  const [overrides, setOverrides] = useState();

  useEffect(() => {
    const cancellationTokenSource = new AbortController();
    fetchTableColumnsOverrides(tableName, roleCode, cancellationTokenSource.signal)
      .then(({ columns }) => {
        setOverrides(columns);
      })
      .catch(() => {
        // do nothing on failure for now
      });

    return () => cancellationTokenSource.abort();
  }, [roleCode, tableName]);

  return { overrides };
};

export default useDataTableOverrides;
