import { displayDate } from 'helpers/date';
import { ColumnFilterOptionsBuilder, DataTableBuilder } from 'modules/DataTable';
import { NavLink } from 'react-router-dom';
import { routeFactory } from 'routes';

const createCommissionHref = (commissionId) => routeFactory.commissionManagement.commissions.detail(commissionId);

const SUBCOMMISSION_FILTER_OPTIONS = new ColumnFilterOptionsBuilder()
  .addOption(true, 'Solo sottocommesse')
  .addOption(false, 'Solo commesse principali')
  .build();

const PAGE_SIZES = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGE_SIZES[0];

const DEFAULT_SORT = { column: 'creationDate', direction: 'DESC' };
const ALLOW_UNSORT = true;

const { columns } = new DataTableBuilder()
  .addColumn('commissionId', (config) => {
    config
      .heading('N° Commessa')
      .filterable(true)
      .sortable(true)
      .cellTemplate((commissionId) => <NavLink to={createCommissionHref(commissionId)}>{commissionId}</NavLink>);
  })
  .addColumn('isSubcommission', (config) => {
    config
      .heading('Sottocomm. (SI/NO)')
      .filterType('select')
      .filterOptions(SUBCOMMISSION_FILTER_OPTIONS)
      .filterable(true)
      .sortable(true)
      .cellTemplate((isSubcommission) => (isSubcommission ? 'Sì' : 'No'));
  })
  .addColumn('customerCode', (config) => {
    config.heading('Cod. cliente').filterable(true).sortable(true).horizontalAlignment('right');
  })
  // .addColumn('businessLineDescription', (config) => {
  //   config.heading('Linea di business').filterable(true).sortable(true).filterType('select').width(100).truncate(true);
  // })
  .addColumn('customerDisplayName', (config) => {
    config.heading('Rag. sociale cliente').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('customerVatNumber', (config) => {
    config.heading('P.IVA cliente').filterable(true).sortable(true);
  })
  .addColumn('commissionDescription', (config) => {
    config.heading('Descrizione comm.').filterable(true).sortable(true).truncate(true).size('xl').tooltip(true);
  })
  .addColumn('commissionStatus', (config) => {
    config.heading('Stato').filterType('select').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('worksiteAddressDisplayName', (config) => {
    config.heading('Indirizzo cantiere').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('territory', (config) =>
    config.heading('Territorialità').filterable(true).filterType('select').sortable(true).truncate(true)
  )
  .addColumn('expectedStartDate', (config) => {
    config
      .heading('Data inizio comm.')
      .type('date')
      .filterable(true)
      .sortable(true)
      .cellTemplate((expectedStartDate) => displayDate(expectedStartDate))
      .horizontalAlignment('right');
  })
  .addColumn('expectedEndDate', (config) => {
    config
      .heading('Data fine comm.')
      .type('date')
      .filterable(true)
      .sortable(true)
      .cellTemplate((expectedEndDate) => displayDate(expectedEndDate))
      .horizontalAlignment('right');
  })
  .addColumn('pm', (config) => {
    config.heading('PM').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .build();

export { columns as COLUMNS, PAGE_SIZES, INITIAL_PAGE_SIZE, DEFAULT_SORT, ALLOW_UNSORT };
