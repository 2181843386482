import styles from 'components/shared/DataTable.module.css';

const _mapCellToText = ({ cellValue }) => {
  // console.debug(cellValue);
  return cellValue;
};

class ColumnBuilder {
  constructor(columnName) {
    if (typeof columnName !== 'string') {
      throw new Error('columnName must be a string');
    }
    this._column = {
      value: columnName,
      heading: columnName,
      type: 'text',
      filterable: false,
      sortable: false,
      cellProps: {},
      truncate: false,
    };
  }
  type(type) {
    if (typeof type !== 'string') {
      throw new Error(`type property of column '${this._column.value}' must be a string`);
    }
    this._column.type = type;
    return this;
  }
  filterType(filterType) {
    if (typeof filterType !== 'string') {
      throw new Error(`filterType property of column '${this._column.value}' must be a string`);
    }
    this._column.filterType = filterType;
    return this;
  }
  filterOptions(options) {
    if (!Array.isArray(options)) {
      throw new Error(`filterOptions property of column '${this._column.value}' must be an array`);
    }
    this._column.options = options;
    return this;
  }
  heading(heading) {
    // if (typeof heading !== 'string') {
    //   throw new Error(`type property of column '${this._column.value}' must be a string`);
    // }
    this._column.heading = heading;
    return this;
  }
  sortable(sortable = true) {
    this._column.sortable = Boolean(sortable);
    return this;
  }
  filterable(filterable = true) {
    this._column.filterable = Boolean(filterable);
    return this;
  }
  cellTemplate(cellTemplate) {
    if (typeof cellTemplate !== 'function') {
      throw new Error(`cellTemplate property of column '${this._column.value}' must be a function`);
    }
    this._column.cellTemplate = cellTemplate;
    return this;
  }
  /**
   *
   * @deprecated
   * @param {*} cellProps function(cellProps: (value: any) => object) or object
   * @returns
   */
  cellProps(cellProps) {
    // console.debug(cellProps);
    // console.debug(typeof cellProps);
    // console.debug(Array.isArray(cellProps));
    // console.debug(cellProps === null);
    // console.debug(typeof cellProps === 'object' && !Array.isArray(cellProps) && cellProps !== null);
    if (
      typeof cellProps !== 'function' &&
      !(typeof cellProps === 'object' && !Array.isArray(cellProps) && cellProps !== null)
    ) {
      throw new Error(`cellProps property of column '${this._column.value}' must be a function or an object`);
    }
    this._column.cellProps = cellProps;
    return this;
  }
  horizontalAlignment(horizontalAlignment) {
    const { cellProps: cp } = this._column;
    this._column.cellProps = { ...cp, style: { ...cp.style, textAlign: horizontalAlignment } };
    return this;
  }
  size(size) {
    this._column.size = size;
    return this;
  }
  /** @deprecated use size() and tableLayout property of DataTable */
  width(cellWidthPx) {
    const { cellProps: cp } = this._column;
    this._column.cellProps = {
      ...cp,
      style: {
        ...cp.style,
        // width: `${cellWidthPx}px`,
        minWidth: `${cellWidthPx}px`,
        maxWidth: `${cellWidthPx}px`,
      },
    };
    // // this._column.headCellProps = {
    // //   ...this._column.headCellProps,
    // //   style: {
    // //     ...this._column.headCellProps.style,
    // //     // width: `${cellWidthPx}px`,
    // //     minWidth: `${cellWidthPx}px`,
    // //     maxWidth: `${cellWidthPx}px`,
    // //   },
    // // };
    return this;
  }
  truncate(truncate = true) {
    this._column.truncate = Boolean(truncate);
    return this;
  }
  tooltip(tooltip = true) {
    this._column.tooltip = Boolean(tooltip);
    return this;
  }
  /**
   *
   * @param {(cellValue:any,item:any,context:any) => string} tooltipTemplate
   */
  tooltipTemplate(tooltipTemplate) {
    this._column.tooltipTemplate = tooltipTemplate;
    return this;
  }
  /** @deprecated */
  applyTextTruncation(config = (columnTextTruncationBuilder) => {}) {
    const builder = new ColumnTextTruncationBuilder();
    config(builder);
    const { cellWidthPx, mapCellToText } = builder.props;
    this.width(cellWidthPx);
    this._column.cellTemplate = (value, row, context) => (
      <div className={styles['cell__text-truncation-wrapper']}>
        {mapCellToText({ cellValue: value, rowValue: row, dataTableContext: context })}
      </div>
    );
    return this;
  }
  style(style) {
    this._column.style = style;
    return this;
  }
  get column() {
    return this._column;
  }
}

class ColumnTextTruncationBuilder {
  constructor() {
    this._props = { cellWidthPx: 100, mapCellToText: _mapCellToText };
  }

  cellWidthPx(cellWidthPx) {
    this._props.cellWidthPx = cellWidthPx;
    return this;
  }

  mapCellToText(mapCellToText) {
    this._props.mapCellToText = mapCellToText;
    return this;
  }

  get props() {
    return this._props;
  }
}

export default ColumnBuilder;
