const calculateActivityTotal = (activity, subactivitySubtotalSelector) => {
  return activity.subactivities.some((subactivity) => !isNaN(subactivitySubtotalSelector(subactivity)))
    ? activity.subactivities
        .filter((subactivity) => !isNaN(subactivitySubtotalSelector(subactivity)))
        .reduce((tot, subactivity) => tot + subactivitySubtotalSelector(subactivity), 0)
    : NaN;
};

const calculateActivitiesTotal = (activities, subactivitySubtotalSelector) => {
  if (!activities.some((activity) => activity.subactivities.some((s) => !isNaN(subactivitySubtotalSelector(s))))) {
    return NaN;
  }

  return activities
    .filter((activity) => activity.subactivities.some((s) => !isNaN(subactivitySubtotalSelector(s))))
    .reduce((tot, activity) => tot + calculateActivityTotal(activity, subactivitySubtotalSelector), 0);
};

const calculateMargin = (totalCost, totalSold) => {
  const revenue = totalSold - totalCost;
  return Math.floor((revenue / totalSold) * 100 * 100) / 100; // * 100 in Math.floor function and / 100 after that is used to maintain a decimal part of 2-digits
};

const stringifyPeriod = (period) => `${period.year}-${period.month}`;

const pivotPeriod = (startPeriod, endPeriod) => {
  const { year: endYear, month: endMonth } = endPeriod;
  let { year, month } = startPeriod;
  const periods = [];
  while (year < endYear || (year === endYear && month <= endMonth)) {
    periods.push({ year, month });
    month = month === 12 ? 1 : month + 1;
    if (month === 1) {
      year++;
    }
  }
  return periods;
};

const mapFormToPostData = (formData) => {
  return {
    concurrencyStamp: formData.concurrencyStamp,
    activities: formData.activities.map((activity) => ({
      subactivities: activity.subactivities.map((subactivity) => ({
        description: subactivity.description,
        totalCost: subactivity.cost || 0,
        totalSold: subactivity.revenue || 0,
        forecasts: subactivity.costRevenueProspectus
          .filter(
            (periodForecast) =>
              (!isNaN(periodForecast.cost) && periodForecast.cost > 0) ||
              (!isNaN(periodForecast.revenue) && periodForecast.revenue > 0)
          )
          .map((periodForecast) => ({
            period: periodForecast.period,
            cost: periodForecast.cost,
            sold: periodForecast.revenue,
          })),
      })),
    })),
  };
};

const mapGetDataToFormData = (getData) => {
  return {
    concurrencyStamp: getData.concurrencyStamp,
    status: getData.status,
    startPeriod: getData.startPeriod,
    endPeriod: getData.endPeriod,
    editable: getData.editable,
    approvalRequireable: getData.approvalRequireable,
    approvable: getData.approvable,
    revisionable: getData.revisionable,
    version: getData.version,
    creationDate: new Date(getData.creationDate),
    moreRecentVersion: getData.latestVersion,
    currentVersion: getData.latestApprovedVersion,
    activities: getData.activities.map((activity) => ({
      id: activity.activityId,
      description: activity.description,
      subactivityAdditionEnabled: !activity.readOnlySubactivities,
      soaReference: activity.soaReference,
      /** @deprecated this field will be removed in future versions */
      soa: activity.soaReference && {
        reference: activity.soaReference,
      },
      subactivities: activity.subactivities.map((subactivity) => ({
        id: subactivity.subactivityId,
        approvedSubactivityId: subactivity.approvedSubactivityId,
        activityId: subactivity.activityId,
        description: subactivity.description,
        cost: subactivity.cost,
        revenue: subactivity.sold,
        readonly: subactivity.readOnly,
        completed: subactivity.completed,
        completable: subactivity.completable,
        minimumCost: subactivity.minimumCost,
        costRevenueProspectus: subactivity.periodForecasts.map((periodForecast) => ({
          period: periodForecast.period,
          cost: periodForecast.cost,
          revenue: periodForecast.sold,
        })),
      })),
    })),
  };
};

export {
  stringifyPeriod,
  calculateActivityTotal,
  calculateActivitiesTotal,
  pivotPeriod,
  mapGetDataToFormData,
  mapFormToPostData,
  calculateMargin,
};
