import { createContext } from 'react';

const UnsavedChangesContext = createContext({
  unsavedChanges: false,
  notify: () => {},
  clear: () => {},
  askConfirm: (onConfirm) => {},
});

export default UnsavedChangesContext;
