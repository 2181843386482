import Loader from 'components/layout/Loader';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { fetchCommissionWithId } from 'services/api/commissions';
import { fetchSupplierWithId } from 'services/api/suppliers';
import { createPurchaseOrder } from 'services/api/purchase-orders';
import ROUTES from 'routes';

import PurchaseOrderHeaderForm from 'components/commission-management/purchase-order/PurchaseOrderHeaderForm';
import {
  makeMunicipalityLine,
  mapCommissionWorksiteToDeliveryPlace,
  mapSiteToDeliveryPlace,
  createOtherDeliveryPlaceOption,
} from 'helpers/purchase-orders';
import { fetchPaymentTermsV2 } from 'services/api/paymentTerms';
import useFeedback from 'hooks/useFeedback';
import { fetchDeliveryPlaces } from 'services/api/delivery-places';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import useEnterEditMode from 'hooks/forms/useEnterEditMode';

const mapSupplierToFrontEnd = (backEndSupplier) => ({
  id: backEndSupplier.id,
  businessName: backEndSupplier.businessName,
  code: backEndSupplier.code,
  address: backEndSupplier.address.streetLines.join(', '),
  municipality: makeMunicipalityLine(
    backEndSupplier.address.postalCode,
    backEndSupplier.address.localityLines.join(' '),
    backEndSupplier.address.province,
    backEndSupplier.address.countryCode
  ),
  vatNumber: backEndSupplier.vatNumber,
  taxCode: backEndSupplier.taxCode,
  durc: backEndSupplier.durc,
  paymentTerms: backEndSupplier.paymentTerm?.id,
});

const fetchInitialResources = async (commissionId, supplierId) => {
  const [supplierResponse, commissionResponse, otherDeliveryPlacesResponse, paymentTermsResponse] = await Promise.all([
    fetchSupplierWithId(supplierId),
    fetchCommissionWithId(commissionId),
    fetchDeliveryPlaces(),
    fetchPaymentTermsV2(),
  ]);

  const otherDeliveryPlaces = otherDeliveryPlacesResponse.ok ? otherDeliveryPlacesResponse.data : [];

  const supplier = mapSupplierToFrontEnd(supplierResponse.data);

  const purchaseOrder = {
    paymentTerms: { id: supplier.paymentTerms },
    currency: { id: 'EUR' },
    site: 0,
    exportedFileLanguageId: 'it',
  };
  const worksite = mapCommissionWorksiteToDeliveryPlace(commissionResponse.data.worksite);
  const deliveryPlaces = [
    worksite,
    ...otherDeliveryPlaces.map(mapSiteToDeliveryPlace),
    createOtherDeliveryPlaceOption(),
  ];

  const paymentTerms = paymentTermsResponse.success ? paymentTermsResponse.data : [];

  return { supplier, purchaseOrder, paymentTerms, deliveryPlaces };
};

const OrderDataStepPage = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialData, setInitialData] = useState();
  const [submitting, setSubmitting] = useState(false);

  const { error } = useFeedback();

  const navigate = useNavigate();
  const { commissionId, supplierId } = useParams();

  const { clear: clearUnsavedChanges } = useUnsavedChanges();

  useEnterEditMode();

  useEffect(() => {
    fetchInitialResources(commissionId, supplierId).then((resources) => {
      setInitialData(resources);
      setInitialLoading(false);
    });
  }, [commissionId, supplierId]);

  const handleSubmit = async (formData) => {
    setSubmitting(true);
    const postData = { ...formData, commissionId, supplierId };
    const purchaseOrderResponse = await createPurchaseOrder(postData);
    if (purchaseOrderResponse.ok) {
      clearUnsavedChanges();
      const navigationRoute = ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.HEADER.replace(
        ':commissionId',
        commissionId
      ).replace(':purchaseOrderId', purchaseOrderResponse.data.id);
      navigate(navigationRoute);
    } else {
      error('Si è verificato un errore');
    }
    setSubmitting(false);
  };

  return (
    <div>
      {/* <h6>Ordine - Generali</h6> */}
      {initialLoading ? (
        <Loader />
      ) : (
        <section>
          {submitting && <Loader />}
          <PurchaseOrderHeaderForm
            defaultValues={initialData.purchaseOrder}
            supplier={initialData.supplier}
            sites={initialData.deliveryPlaces}
            paymentTerms={initialData.paymentTerms}
            onSubmit={handleSubmit}
          />
        </section>
      )}
    </div>
  );
};

export default OrderDataStepPage;
