// import { Button, Col, Input, InputGroup, Row, Table } from 'reactstrap';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import styles from './DataTable.module.css';
import { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript#answer-7616484
const hash = (str) => Array.from(str).reduce((hash, char) => (hash = ((hash << 5) - hash + char.charCodeAt(0)) | 0), 0);

const formatDate = (date) => {
  if (!isNaN(Date.parse(date))) {
    let tempDate = new Date(date);
    return (
      tempDate.getFullYear().toString() +
      '-' +
      (tempDate.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      tempDate.getDate().toString().padStart(2, '0')
    );
  }
  return null;
};

//Per lingua Calendar
addLocale('it', {
  firstDayOfWeek: 1,
  dayNames: ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'],
  dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  monthNames: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
  monthNamesShort: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
  today: 'Oggi',
  clear: 'Pulisci',
});

const InputTextFilter = ({ columnName, columnType, initialValue = '', onChange = (e) => {} }) => {
  const [previousValue, setPreviousValue] = useState(initialValue);
  const [value, setValue] = useState(initialValue);

  const filterName = columnName ? `filter${Math.abs(hash(columnName))}` : undefined;
  // the commented code below is problematic with autocomplete tools like Roboform in some cases
  // const filterName = columnName ? `filter${columnName.charAt(0).toUpperCase()}${columnName.slice(1)}` : undefined;

  useEffect(() => {
    // console.debug(
    //   `useEffect → value: '${value}', previousValue: '${previousValue}', columnName: '${columnName}', columnType: '${columnType}'`
    // );
    if (value === previousValue) {
      return;
    }

    const timeout = setTimeout(() => {
      // console.debug(`filter changed to ${value}`);
      onChange(value, columnName, columnType, 0);
      setPreviousValue(value);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, previousValue, onChange, columnName, columnType]);

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Input type="text" className={styles['filter-input']} name={filterName} value={value} onChange={handleOnChange} />
  );
};

function renderLabelAndInput(onChange, inputType, inputOptions, columnName) {
  // console.debug({ method: 'renderLabelAndInput', onChange, inputType, inputOptions, columnName });
  return (
    <div className={`${inputType === 'date' ? styles['dateInputContainer'] : styles['inputContainer']}`}>
      {inputType === 'text' ? (
        <InputTextFilter columnName={columnName} columnType={inputType} onChange={onChange} />
      ) : (
        <Input
          className={styles['filter-input']}
          type={inputType}
          onChange={onChange}
          {...(inputType === 'select' &&
            inputOptions && {
              children: [
                <option value="" key=""></option>,
                ...inputOptions?.map((option) => (
                  <option key={option?.id} value={option?.id}>
                    {option?.description}
                  </option>
                )),
              ],
            })}
        />
      )}
    </div>
  );
}

const PaginationButton = ({ onClick, disabled, children }) => (
  <Button
    size="sm"
    color="secondary"
    outline
    disabled={disabled}
    onClick={onClick}
    className={styles['btn-pagination']}
  >
    {children}
  </Button>
);

const DATE_STYLE = {
  maxWidth: '120px',
};

const DateFieldFilter = ({ onHandleSearch, fieldName }) => {
  const [date, setDate] = useState();
  const [date2, setDate2] = useState();

  return (
    <Row>
      <Col style={{ ...DATE_STYLE, paddingRight: '.125rem' }}>
        <Calendar
          inputClassName={styles['filter-input']}
          value={date}
          onChange={(e) => {
            onHandleSearch(e.target.value !== null ? formatDate(e.target.value) : '', fieldName + 'From', 'date', 0);
            setDate(e.target.value);
          }}
          minDate={new Date('1970-01-01')}
          maxDate={new Date('9999-12-31')}
          readOnlyInput
          placeholder={'da'}
          dateFormat="dd/mm/yy"
          locale="it"
          showButtonBar
        />
      </Col>
      <Col style={{ ...DATE_STYLE, paddingLeft: '.125rem' }}>
        <Calendar
          inputClassName={styles['filter-input']}
          value={date2}
          onChange={(e) => {
            onHandleSearch(e.target.value !== null ? formatDate(e.target.value) : '', fieldName + 'To', 'date', 0);
            setDate2(e.target.value);
          }}
          minDate={new Date('1970-01-01')}
          maxDate={new Date('9999-12-31')}
          readOnlyInput
          placeholder={'a'}
          dateFormat="dd/mm/yy"
          locale="it"
          showButtonBar
        />
      </Col>
    </Row>
  );
};

const ColumnHeadFilter = ({ col, onHandleSearch }) => {
  const filterType = col.filterType || col.type;
  return (
    <div className={styles['filter-container']}>
      {filterType === 'date' ? (
        <DateFieldFilter onHandleSearch={onHandleSearch} fieldName={col.value} />
      ) : filterType === 'text' ? (
        <InputTextFilter columnName={col.value} columnType={filterType} onChange={onHandleSearch} />
      ) : (
        renderLabelAndInput(
          (e) => onHandleSearch(e?.target.value, col.value, filterType, 0),
          filterType,
          col.options,
          col.value
        )
      )}
    </div>
  );
};

const DataTableHead = ({ column, resizable, renderSortOptions, filterable, onHandleSearch }) => (
  <thead className={styles['table__head']}>
    <tr>
      {column.map((col, index) => {
        const hasFilter = filterable && col.filterable;
        return (
          <th
            className={`${styles['heading-handler']} ${resizable ? styles['resize-handler'] : ''} ${
              hasFilter ? styles['head-with-filter'] : ''
            }`}
            key={index}
          >
            {renderSortOptions(col)}
            {hasFilter && <ColumnHeadFilter col={col} onHandleSearch={onHandleSearch} />}
          </th>
        );
      })}
    </tr>
  </thead>
);

const DataTableBody = ({ data, column, context }) => (
  <tbody>
    {data?.map((item, index) => (
      <tr key={`item-${index}`}>
        {column.map((columnItem, index) => {
          const cellValue = columnItem.type === 'select' ? item[columnItem.value].description : item[columnItem.value];
          const cellAttributes =
            typeof columnItem.cellProps === 'function'
              ? columnItem.cellProps({ value: cellValue })
              : columnItem.cellProps;
          
          if (columnItem.tooltip) {
            cellAttributes.title = columnItem.tooltipTemplate?.(cellValue, item, context) ?? cellValue;
          }
          return (
            <td key={`columnItem-${index}`} style={columnItem.style} {...cellAttributes}>
              <ColumnWrapper truncate={columnItem.truncate}>
                {typeof columnItem.cellTemplate === 'function'
                  ? columnItem.cellTemplate(cellValue, item, context)
                  : cellValue}
              </ColumnWrapper>
            </td>
          );
        })}
      </tr>
    ))}
  </tbody>
);

const ColumnWrapper = ({ truncate, children }) =>
  truncate ? <div className={styles['cell__text-truncation-wrapper']}>{children}</div> : children;

const DataTableFooter = ({
  column,
  pageable,
  currentPage,
  numPages,
  onHandlePageChange,
  totRecords,
  numRecords,
  paginationValues,
  onHandleRecordsChange,
}) => (
  <tfoot className={styles['table__footer']}>
    <tr>
      <td colSpan={column.length} style={{ backgroundColor: 'transparent' }}>
        <div className="d-flex align-items-center">
          {pageable && (
            // <div class="d-flex">
            <>
              <div>
                Pagina {currentPage + 1} di {numPages}
              </div>
              <div className="px-2">
                <PaginationButton onClick={() => onHandlePageChange(currentPage - 1)} disabled={currentPage === 0}>
                  <i className="fas fa-chevron-left"></i>
                </PaginationButton>
                <PaginationButton
                  onClick={() => onHandlePageChange(currentPage + 1)}
                  disabled={totRecords === 0 || currentPage + 1 >= numPages}
                >
                  <i className="fas fa-chevron-right"></i>
                </PaginationButton>
              </div>
              {paginationValues.length > 1 && (
                <div className="pl-5 pr-2 d-flex align-items-center">
                  <div className="pr-1">Visualizza</div>
                  <Input
                    type="select"
                    className={`border border-secondary ${styles['pagination-select']}`}
                    value={numRecords}
                    onChange={(e) => onHandleRecordsChange(parseInt(e.target.value), 0)}
                  >
                    {paginationValues.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Input>
                  <div className="pl-1">elementi</div>
                </div>
              )}
            </>
          )}
          <div className="ml-auto">Elementi ( {totRecords} )</div>
        </div>
      </td>
    </tr>
  </tfoot>
);

const DataTableColGroup = ({ columns, fixedLayout }) => (
  <colgroup>
    {columns.map((col, index) => (
      <col
        key={`${index}_${col.value}`}
        className={col.size ? styles[`w-${col.size}`] : fixedLayout ? styles['w-md'] : 'xxx'}
      />
    ))}
  </colgroup>
);

const SORT_DIRECTION_MAP = { ASC: '↑', DESC: '↓' };
const getSortSymbol = (direction) => SORT_DIRECTION_MAP[direction] || <span style={{ color: '#cfcfcf' }}>↑↓</span>;

/** @deprecated use DataTableV2 */
const DataTable = ({
  data,
  column = [],
  resizable = false,
  onHandleSort,
  onHandleSearch,
  onPageChange,
  /** @deprecated use onPageChange */
  onHandlePageChange = onPageChange,
  onHandleRecordsChange,
  sort,
  totRecords,
  currentPage,
  pageSizes = [20, 50, 100],
  /** @deprecated use pageSizes */
  paginationValues = pageSizes,
  pageSize = paginationValues[0],
  /** @deprecated use pageSize */
  numRecords = pageSize,
  context = {},
  responsive = false,
  size,
  striped = false,
  fixedLayout = false,
}) => {
  const numPages = totRecords > 0 && numRecords > 0 ? Math.ceil(totRecords / numRecords) : 1;
  const sortable = onHandleSort !== undefined;
  const pageable = onHandlePageChange !== undefined;
  const filterable = onHandleSearch !== undefined;

  function renderSortOptions(col) {
    const { heading, value } = col;
    const isCurrentSortColumn = sort != null ? value === sort.column : false;
    const currentDirection = isCurrentSortColumn ? sort.direction : null;

    return (
      <div className="d-flex">
        <div className="flex-grow-1">{heading}</div>
        {sortable && col.sortable && (
          <label role="button" className={styles['sort-btn'] + ' pl-1'} onClick={() => onHandleSort(value)}>
            {getSortSymbol(isCurrentSortColumn ? currentDirection : undefined)}
          </label>
        )}
      </div>
    );
  }

  const tableClassName = `${styles['table']} ${size ? styles[`table--${size}`] : ''} ${styles['table--bordered']} ${
    fixedLayout ? styles['table--fixed'] : ''
  }`;

  const visibleColumns = column.filter((c) => !c.hidden);

  return (
    <>
      <Table responsive={responsive} className={tableClassName} striped={striped}>
        <DataTableColGroup columns={visibleColumns} fixedLayout={fixedLayout} />
        <DataTableHead
          column={visibleColumns}
          filterable={filterable}
          onHandleSearch={onHandleSearch}
          renderSortOptions={renderSortOptions}
          resizable={resizable}
        />
        <DataTableBody column={visibleColumns} data={data} context={context} />
        <DataTableFooter
          column={visibleColumns}
          currentPage={currentPage}
          numPages={numPages}
          numRecords={numRecords}
          pageable={pageable}
          paginationValues={paginationValues}
          totRecords={totRecords}
          onHandlePageChange={onHandlePageChange}
          onHandleRecordsChange={onHandleRecordsChange}
        />
      </Table>
    </>
  );
};
export default DataTable;
