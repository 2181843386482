const { fetchResource } = require('services/api');

const searchCustomers = (searchText) => {
  return fetchResource(`/api/customers?search=${searchText}`);
};

const fetchCustomers = () => {
  return fetchResource(`/api/customers`);
};

const fetchCustomerWithId = async (customerId) => {
  return await fetchResource(`/api/customers/${customerId}`);
};

export { searchCustomers, fetchCustomerWithId, fetchCustomers };
