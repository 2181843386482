import { useCallback, useMemo, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row, CustomInput as BsCustomInput } from 'reactstrap';
import GUARANTEE_WITHHOLDING_TYPES from 'constants/guarantee-withholding-types';
import { ADVANCE, TOTAL_PRODUCTION, WARRANTY_RELEASE } from 'constants/payment-certificate-types';
import { round } from 'helpers/amount';
import { InputNumber } from 'primereact/inputnumber';

const LEFT_COLUMN_WIDTH = 8;
const RIGHT_COLUMN_WIDTH = 12 - LEFT_COLUMN_WIDTH;

const FORM_FIELDS = [
  { name: 'type', defaultValue: '' },
  { name: 'amount', defaultValue: 0 },
  { name: 'date', defaultValue: '' },
  { name: 'isRedetermination', defaultValue: false },
  { name: 'redeterminationAmount', defaultValue: 0 },
  { name: 'redeterminationNote', defaultValue: '' },
  { name: 'advanceWithholdingPercentage', defaultValue: undefined },
  { name: 'advanceWithholdingAmount', defaultValue: 0 },
  { name: 'note', defaultValue: '' },
  { name: 'isRecoverTotalAdvance', defaultValue: false },
];

const DEFAULT_VALUES = FORM_FIELDS.reduce((defaultValues, field) => {
  defaultValues[field.name] = field.defaultValue;
  return defaultValues;
}, {});

const getReadOnlyFields = (readOnly) => {
  if (readOnly === false) {
    return [];
  }
  if (readOnly === true) {
    return FORM_FIELDS.map((field) => field.name);
  }
  return readOnly;
};

const CustomInputNumber = ({
  suffix = undefined,
  minFractionDigits = 2,
  maxFractionDigits = 2,
  disabled = undefined,
  value = undefined,
  onChange = undefined,
  name = undefined,
  id = name,
  required = undefined,
  min = undefined,
  max = undefined,
}) => (
  <InputNumber
    inputId={id}
    name={name}
    locale="en-US"
    inputStyle={{ padding: '.375rem .75rem', width: '100%', opacity: 0.9 }}
    inputClassName="form-control text-right"
    style={{ width: '100%' }}
    suffix={suffix}
    minFractionDigits={minFractionDigits}
    maxFractionDigits={maxFractionDigits}
    value={value}
    disabled={disabled}
    onChange={onChange}
    required={required}
    min={min}
    max={max}
  />
);

const MinusSign = () => (
  <i className="fa fa-minus" style={{ padding: '0.375rem 0rem', lineHeight: '1.5', verticalAlign: 'text-top' }} />
);

const CalculationLineSeparator = () => <div style={{ border: '1px solid #858796' }}></div>;

const CalculationLineSeparatorRow = () => (
  <Row>
    <Col md={LEFT_COLUMN_WIDTH}>
      <Row className="mb-3">
        <Col xs={4} className="offset-8">
          <CalculationLineSeparator />
        </Col>
      </Row>
    </Col>
  </Row>
);

const PaymentCertificateTypeSelectOption = ({ selectable, value, selectedValue, label }) =>
  selectable || selectedValue === value ? (
    <option value={value} disabled={!selectable}>
      {label}
    </option>
  ) : null;

const PaymentCertificateForm = ({
  initialData = {},
  guaranteeWithholding = { typeId: GUARANTEE_WITHHOLDING_TYPES.NOT_APPLICABLE, percentage: undefined },
  readOnly = false,
  amountRemainder = 0,
  advanceWithholdingAmountRemainder = 0,
  guaranteeWithholdingAmountRemainder = 0,
  advanceTypeAllowed = true,
  canCreate = true,
  onSubmit = (postData) => {},
}) => {
  const [formData, setFormData] = useState({ ...DEFAULT_VALUES, ...initialData });

  const readOnlyFields = useMemo(() => getReadOnlyFields(readOnly), [readOnly]);
  const isFormFieldReadOnly = useCallback((fieldName) => readOnlyFields.includes(fieldName), [readOnlyFields]);

  const guaranteeWithholdingAmount = round(formData.amount * ((guaranteeWithholding?.percentage ?? 0) / 100));
  const guaranteeWithholdingInvoiceable = guaranteeWithholding.typeId === GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE;

  const guaranteeWithholdingVisible = formData.type === TOTAL_PRODUCTION && (guaranteeWithholding?.percentage ?? 0) > 0;

  const totalInvoiceableAmount =
    formData.amount -
    (guaranteeWithholdingInvoiceable && guaranteeWithholdingVisible ? guaranteeWithholdingAmount : 0) -
    formData.advanceWithholdingAmount -
    formData.redeterminationAmount;

  const totalPayableAmount =
    totalInvoiceableAmount -
    (guaranteeWithholdingVisible && !guaranteeWithholdingInvoiceable ? guaranteeWithholdingAmount : 0);

  const canChooseAdvanceType = advanceTypeAllowed;
  const canChooseTotalProductionType = amountRemainder > 0 || advanceWithholdingAmountRemainder > 0;
  const canChooseWarrentyReleaseType = !canChooseTotalProductionType && guaranteeWithholdingAmountRemainder > 0;

  const isInvalidTypeOptionSelected =
    (formData.type === ADVANCE && !canChooseAdvanceType) ||
    (formData.type === TOTAL_PRODUCTION && !canChooseTotalProductionType) ||
    (formData.type === WARRANTY_RELEASE && !canChooseWarrentyReleaseType);

  const changeFormFieldValue = (fieldName, fieldValue) => {
    // console.debug(`input ${fieldName}'s value changed to ${fieldValue} (${typeof fieldValue})`);
    setFormData((formData) => ({ ...formData, [fieldName]: fieldValue }));
  };

  const changeFormFieldValues = (fieldValues) => {
    // console.debug('changing fields');
    // console.debug(fieldValues);
    setFormData((formData) => ({ ...formData, ...fieldValues }));
  };

  const handleOnAmountChange = (amount) => {
    changeFormFieldValue('amount', amount);

    if (
      formData.type === TOTAL_PRODUCTION &&
      advanceWithholdingAmountRemainder > 0 &&
      !formData.isRecoverTotalAdvance
    ) {
      changeFormFieldValue(
        'advanceWithholdingAmount',
        round((amount ?? 0) * ((formData.advanceWithholdingPercentage ?? 0) / 100))
      );
    }
  };

  const handleOnAdvanceWithholdingPercentageChange = (advanceWithholdingPercentage) => {
    changeFormFieldValues({
      advanceWithholdingPercentage,
      advanceWithholdingAmount: round(formData.amount * (advanceWithholdingPercentage / 100)),
    });
  };

  const handleOnRecoverTotalAdvanceChange = (e) => {
    const initialAdvanceWithholdingPercentage =
      initialData.advanceWithholdingPercentage || DEFAULT_VALUES.advanceWithholdingPercentage;
    changeFormFieldValues({
      [e.target.name]: e.target.checked,
      advanceWithholdingPercentage: e.target.checked ? undefined : initialAdvanceWithholdingPercentage,
      advanceWithholdingAmount: e.target.checked
        ? advanceWithholdingAmountRemainder
        : round(((formData.amount ?? 0) * (initialAdvanceWithholdingPercentage ?? 0)) / 100),
    });
  };

  const handleOnTypeChange = (e) => {
    const type = parseInt(e.target.value);
    switch (type) {
      case ADVANCE:
      case WARRANTY_RELEASE:
        changeFormFieldValues({
          type,
          amount: type === initialData.type ? initialData.amount : 0,
          isRedetermination: false,
          redeterminationAmount: 0,
          redeterminationNote: '',
          advanceWithholdingPercentage: 0,
          advanceWithholdingAmount: 0,
          isRecoverTotalAdvance: false,
        });
        break;
      case TOTAL_PRODUCTION:
        changeFormFieldValues({
          type,
          amount: type === initialData.type ? initialData.amount : 0,
          advanceWithholdingPercentage:
            initialData.advanceWithholdingPercentage || DEFAULT_VALUES.advanceWithholdingPercentage,
          advanceWithholdingAmount:
            !!initialData.advanceWithholdingPercentage && !!initialData.advanceWithholdingAmount
              ? initialData.advanceWithholdingAmount
              : DEFAULT_VALUES.advanceWithholdingAmount,
          isRecoverTotalAdvance: initialData.isRecoverTotalAdvance || DEFAULT_VALUES.isRecoverTotalAdvance,
        });
        break;
      default:
        break;
    }
  };

  const handleOnFormSubmit = (e) => {
    e.preventDefault();
    const modifiedFormData = { ...formData };
    delete modifiedFormData.isRedetermination;
    if (!modifiedFormData.note?.trim()) {
      delete modifiedFormData.note;
    }
    if (!modifiedFormData.redeterminationNote?.trim()) {
      delete modifiedFormData.redeterminationNote;
    }
    console.debug('MODIFIED FORM DATA');
    console.debug(modifiedFormData);
    onSubmit(modifiedFormData);
  };

  return (
    <Form onSubmit={handleOnFormSubmit}>
      <Row>
        <Col md={LEFT_COLUMN_WIDTH}>
          <Row className="mb-3">
            <Col xs={4}>
              <BsCustomInput
                id="type"
                name="type"
                type="select"
                value={formData.type}
                onChange={handleOnTypeChange}
                required
                disabled={isFormFieldReadOnly('type')}
                invalid={isInvalidTypeOptionSelected}
              >
                <PaymentCertificateTypeSelectOption
                  value={ADVANCE}
                  selectedValue={formData.type}
                  selectable={canChooseAdvanceType}
                  label="Anticipo"
                />
                <PaymentCertificateTypeSelectOption
                  value={TOTAL_PRODUCTION}
                  selectedValue={formData.type}
                  selectable={canChooseTotalProductionType}
                  label="Totale produzione"
                />
                <PaymentCertificateTypeSelectOption
                  value={WARRANTY_RELEASE}
                  selectedValue={formData.type}
                  selectable={canChooseWarrentyReleaseType}
                  label="Svincolo di garanzia"
                />
              </BsCustomInput>
            </Col>
            <Col xs={4} className="offset-4">
              <CustomInputNumber
                suffix="€"
                name="amount"
                value={formData.amount}
                onChange={(e) => handleOnAmountChange(e.value)}
                required
                min={0}
                disabled={isFormFieldReadOnly('amount') || formData.type === WARRANTY_RELEASE}
              />
            </Col>
          </Row>
        </Col>
        <Col md={RIGHT_COLUMN_WIDTH}>
          <Row className="mb-3">
            <Col xs={6} className="text-right">
              <Label>Alla data</Label>
            </Col>
            <Col xs={6}>
              <Input
                type="date"
                name="date"
                value={formData.date}
                onChange={(e) => changeFormFieldValue(e.target.name, e.target.value)}
                min="2000-01-01"
                max="9999-12-31"
                required
                disabled={isFormFieldReadOnly('date')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Ritenute di garanzia: presenti nel calcolo del totale fatturabile solamente se la ritenuta di garanzia è NON FATTURABILE e se il certificato di pagamento è "totale produzione" */}
      {guaranteeWithholdingVisible && guaranteeWithholdingInvoiceable && (
        <Row>
          <Col md={LEFT_COLUMN_WIDTH}>
            <Row className="mb-3">
              <Col xs={4}>
                <Label>Ritenute di garanzia {guaranteeWithholding.percentage}%</Label>
              </Col>
              <Col xs={4} className="text-right">
                <MinusSign />
              </Col>
              <Col xs={4}>
                <CustomInputNumber suffix="€" disabled value={guaranteeWithholdingAmount} min={0} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {/* Ritenuta di anticipo */}
      {advanceWithholdingAmountRemainder > 0 && (
        <Row>
          <Col md={LEFT_COLUMN_WIDTH}>
            <Row className="mb-3">
              <Col xs={4}>
                <Label>Ritenuta di anticipo</Label>
              </Col>
              <Col xs={3}>
                <CustomInputNumber
                  name="advanceWithholdingPercentage"
                  suffix="%"
                  required
                  min={0}
                  max={100}
                  value={formData.advanceWithholdingPercentage}
                  onChange={(e) => handleOnAdvanceWithholdingPercentageChange(Math.min(e.value, 100))}
                  disabled={isFormFieldReadOnly('advanceWithholdingPercentage') || formData.isRecoverTotalAdvance}
                />
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="isRecoverTotalAdvance"
                      checked={formData.isRecoverTotalAdvance}
                      onChange={handleOnRecoverTotalAdvanceChange}
                      disabled={isFormFieldReadOnly('isRecoverTotalAdvance')}
                    />{' '}
                    Recupero totale anticipo
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={1} className="text-right">
                <MinusSign />
              </Col>
              <Col xs={4}>
                <CustomInputNumber
                  name="advanceWithholdingAmount"
                  suffix="€"
                  value={formData.advanceWithholdingAmount}
                  disabled
                  required
                  // max={advanceWithholdingAmountRemainder}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {/* Rideterminazione */}
      {formData.type === TOTAL_PRODUCTION && (
        <Row>
          <Col md={LEFT_COLUMN_WIDTH}>
            <Row className="mb-3">
              <Col xs={4}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="isRedetermination"
                      checked={formData.isRedetermination}
                      onChange={(e) =>
                        e.target.checked
                          ? changeFormFieldValue(e.target.name, e.target.checked)
                          : changeFormFieldValues({
                              [e.target.name]: e.target.checked,
                              redeterminationAmount: 0,
                              redeterminationNote: '',
                            })
                      }
                      disabled={isFormFieldReadOnly('isRedetermination')}
                    />{' '}
                    Rideterminazione
                  </Label>
                </FormGroup>
              </Col>
              {formData.isRedetermination && (
                <>
                  <Col xs={4} className="text-right">
                    <MinusSign />
                  </Col>
                  <Col xs={4}>
                    <CustomInputNumber
                      suffix="€"
                      name="redeterminationAmount"
                      value={formData.redeterminationAmount}
                      onChange={(e) => changeFormFieldValue(e.originalEvent.target.name, e.value)}
                      required
                      min={0}
                      disabled={isFormFieldReadOnly('redeterminationAmount')}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
          {formData.isRedetermination && (
            <Col md={RIGHT_COLUMN_WIDTH}>
              <FormGroup>
                <Input
                  type="textarea"
                  name="redeterminationNote"
                  value={formData.redeterminationNote}
                  onChange={(e) => changeFormFieldValue(e.target.name, e.target.value)}
                  disabled={isFormFieldReadOnly('redeterminationNote')}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
      )}
      <CalculationLineSeparatorRow />
      {/* Totale fatturabile */}
      <Row>
        <Col md={LEFT_COLUMN_WIDTH}>
          <Row className="mb-3">
            <Col xs={4}>
              <Label>Totale fatturabile</Label>
            </Col>
            <Col xs={4}></Col>
            <Col xs={4}>
              <CustomInputNumber suffix="€" disabled value={totalInvoiceableAmount} />
            </Col>
          </Row>
        </Col>
      </Row>
      <CalculationLineSeparatorRow />
      {/* Ritenute di garanzia: presenti nel calcolo del TOTALE PAGABILE solamente se la ritenuta di garanzia è FATTURABILE e se il certificato di pagamento è "totale produzione" */}
      {guaranteeWithholdingVisible && !guaranteeWithholdingInvoiceable && (
        <Row>
          <Col md={LEFT_COLUMN_WIDTH}>
            <Row className="mb-3">
              <Col xs={4}>
                <Label>Ritenute di garanzia {guaranteeWithholding.percentage}%</Label>
              </Col>
              <Col xs={4} className="text-right">
                <MinusSign />
              </Col>
              <Col xs={4}>
                <CustomInputNumber suffix="€" disabled value={guaranteeWithholdingAmount} min={0} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {/* Totale pagabile */}
      <Row>
        <Col md={LEFT_COLUMN_WIDTH}>
          <Row className="mb-3">
            <Col xs={4}>
              <Label>Totale pagabile</Label>
            </Col>
            <Col xs={4}></Col>
            <Col xs={4}>
              <CustomInputNumber suffix="€" disabled value={totalPayableAmount} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={LEFT_COLUMN_WIDTH}>
          <FormGroup>
            <Label htmlFor="note">Note</Label>
            <Input
              type="textarea"
              id="note"
              name="note"
              value={formData.note}
              onChange={(e) => changeFormFieldValue(e.target.name, e.target.value)}
              disabled={isFormFieldReadOnly('note')}
            />
          </FormGroup>
        </Col>
      </Row>
      {!readOnly && canCreate && (
        <Button type="submit" color="primary">
          Salva
        </Button>
      )}
    </Form>
  );
};

export default PaymentCertificateForm;
