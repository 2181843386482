import { useCallback, useEffect, useState } from 'react';
import ToastMessage from './ToastMessage';

// const defaultProps = {
//   className: 'toast',
//   showAnimation: 'animated bounceIn',
//   hideAnimation: 'animated bounceOut',
//   //
//   timeOut: 5000,
//   extendedTimeOut: 1000,
//   //
//   tapToDismiss: true,
//   //
//   onRemove: undefined,
//   onClick: () => {},
//   onCloseClick: () => {},
// };

const HIDE_TIMEOUT = 5000;
const DISMISS_TIMEOUT = 1000;

const TOAST_CLASSNAME = 'toast';
const SHOW_ANIMATION = 'animated bounceIn';
const HIDE_ANIMATION = 'animated bounceOut';

const ToastMessageAnimated = ({
  id,
  title = false,
  message = false,
  type,
  // className = 'toast',
  // showAnimation = 'animated bounceIn',
  // hideAnimation = 'animated bounceOut',
  // timeOut = 5000,
  onRemove,
  //   extendedTimeOut = 1000,
  //   tapToDismiss = true,
  //   onClick = () => {},
  //   onCloseClick = () => {},
}) => {
  const [animationClassName, setAnimationClassName] = useState(SHOW_ANIMATION);

  const handleOnHide = useCallback(() => {
    setAnimationClassName(HIDE_ANIMATION);
    setTimeout(() => onRemove(id), DISMISS_TIMEOUT);
  }, [id, onRemove]);

  useEffect(() => {
    const timeoutHiding = setTimeout(handleOnHide, HIDE_TIMEOUT);

    return () => clearTimeout(timeoutHiding);
  }, [handleOnHide, id, onRemove]);

  return (
    <ToastMessage
      type={type}
      title={title}
      message={message}
      className={TOAST_CLASSNAME + ' ' + animationClassName}
      onClick={handleOnHide}
    />
  );
};

export default ToastMessageAnimated;
