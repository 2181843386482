import { Button } from 'reactstrap';
import DataTableIcon from './DataTableIcon';

const DataTableButton = ({ onClick, title, iconName, disabled = false }) => (
  <Button color="link" className="p-0" onClick={onClick} title={title} disabled={disabled}>
    <DataTableIcon name={iconName} />
  </Button>
);

export default DataTableButton;
