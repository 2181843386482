import CustomInput from 'components/shared/forms/CustomInput';
import { ACCEPTED_DOCUMENT_TYPES } from 'config';
import { useState } from 'react';
import { Form, Button, Col, Row, Label } from 'reactstrap';

const UploadSalesOrderAttachment = ({
  onSubmit,
  formId,
  formName,
  attachmentTypes,
  document,
  inline,
  isFileRequired = true,
}) => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isAttachmentTypeChosen, setIsAttachmentTypeChosen] = useState(Boolean(document?.attachmentTypeId));

  const colSpace = inline ? 2 : 12;
  const canSubmit = isAttachmentTypeChosen && (!isFileRequired || isFileUploaded); //isFileRequired ? isFileUploaded && isAttachmentTypeChosen : true;

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const docData = document
      ? { ...e.target.getObjectData(), attachmentId: document.attachmentId }
      : e.target.getObjectData();
    onSubmit?.(formData, docData, attachmentTypes[e.target.getObjectData().attachmentTypeId - 1]);
  };

  const handleFileChange = (e) => {
    setIsFileUploaded(Boolean(e.target.files[0]));
  };

  const handleTypeChange = (e) => {
    setIsAttachmentTypeChosen(e.target.value !== '');
  };

  return (
    <Form key={formName + formId} onSubmit={handleSubmit}>
      <Row>
        <Col lg={colSpace}>
          <CustomInput
            type="select"
            label="Tipo Documento"
            name="attachmentTypeId"
            onChange={handleTypeChange}
            required
            defaultValue={
              document
                ? attachmentTypes.find((x) => x.description === document?.description).attachmentTypeId
                : undefined
            }
          >
            <option value="">--- Seleziona</option>
            {attachmentTypes?.map((type) => (
              <option key={`attachment-type-${type.attachmentTypeId}`} value={type.attachmentTypeId}>
                {type.description}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col lg={colSpace}>
          <CustomInput
            type="file"
            accept={ACCEPTED_DOCUMENT_TYPES}
            label="Allega documento"
            name="file"
            onChange={handleFileChange}
            required={isFileRequired}
          />
        </Col>
        <Col lg={inline ? 3 : 12}>
          <CustomInput rows={4} type="textarea" label="Nota" name="note" defaultValue={document?.note} />
        </Col>
        <Col lg={inline ? 2 : 12}>
          <Label className="d-block">&nbsp;</Label>
          <Button disabled={!canSubmit} title="Salva l'allegato" color="primary">
            <i className="fa fa-save" /> SALVA
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UploadSalesOrderAttachment;
