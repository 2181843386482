const PurchaseOrderChecks = (props) => {
  return (
    <ul style={{ padding: 0 }}>
      <span className={`ml-1 badge ${props.Validity.approvatore ? "badge-success" : "badge-danger"}`}>
        <i className={`fas ${props.Validity.approvatore ? "fa-check" : "fa-times"}`}></i> Approvatore selezionato
      </span >
      <span className={`ml-1 badge ${props.Validity.attivitaLength ? "badge-success" : "badge-danger"}`}>
        <i className={`fas ${props.Validity.attivitaLength ? "fa-check" : "fa-times"}`}></i> Almeno una attività inserita
      </span>
      <span className={`ml-1 badge ${props.Validity.selectedAttivita ? "badge-success" : "badge-danger"}`}>
        <i className={`fas ${props.Validity.selectedAttivita ? "fa-check" : "fa-times"}`}></i> Attività selezionata
      </span>
      <span className={`ml-1 badge ${props.Validity.selectedConto ? "badge-success" : "badge-danger"}`}>
        <i className={`fas ${props.Validity.selectedConto ? "fa-check" : "fa-times"}`}></i> Conto contabile selezionato
      </span>
      <span className={`ml-1 badge ${props.Validity.importo ? "badge-success" : "badge-danger"}`}>
        <i className={`fas ${props.Validity.importo ? "fa-check" : "fa-times"}`}></i> Residuo fattura rispettato
      </span>
      <span className={`ml-1 badge ${props.Validity.totaleparzialeColor ? "badge-success" : "badge-danger"}`}>
        <i className={`fas ${props.Validity.totaleparzialeColor ? "fa-check" : "fa-times"}`}></i> Residuo attività rispettato
      </span>
      <span className={`ml-1 badge ${props.Validity.importoMaggioreDiZero ? "badge-success" : "badge-danger"}`}>
        {/* <i className={`fas ${props.Validity.importoMaggioreDiZero ? "fa-check" : "fa-times"}`}></i> Tutti gli importi maggiori di zero */}
        <i className={`fas ${props.Validity.importoMaggioreDiZero ? "fa-check" : "fa-times"}`}></i> Importo uguale a zero
      </span>
      <span className={`ml-1 badge ${props.Validity.importoValido ? "badge-success" : "badge-danger"}`}>
        {/* <i className={`fas ${props.Validity.importoMaggioreDiZero ? "fa-check" : "fa-times"}`}></i> Tutti gli importi maggiori di zero */}
        <i className={`fas ${props.Validity.importoValido ? "fa-check" : "fa-times"}`}></i> Valore non valido
      </span>
    </ul>
  );
};

export default PurchaseOrderChecks;
