import { useEffect, useRef } from 'react';
import { useState } from 'react';
import './mystyle.css';
import Suggestions from './suggestions';

const AutoComplete = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [value, setValue] = useState('');
  const [onmount, setOnmnount] = useState(true);

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  const [nameInput, setInputFocus] = useFocus();

  const handleChange = (e) => {
    const query = e.target.value.toLowerCase();
    saveValue(query);
    if (onmount) {
      setOnmnount(false);
      saveValue(query);
    }
    if (query.length > 0) {
      const filterSuggestions = props.data.filter(
        (suggestion) => suggestion[props.labelField].toLowerCase().indexOf(query) > -1
      );
      setSuggestions(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };
  const saveValue = (val) => {
    setValue(val);
    let selectedItem = props.data.find(
      (suggestion) =>
        // (suggestion[props.labelField].toLowerCase().indexOf(val) > -1 && val !== "") ||
        suggestion[props.labelField] === val
    );
    if (selectedItem !== undefined) {
      props.ChangeValue(selectedItem);
    }
  };
  const handleLostFocus = (e) => {
    setTimeout(() => setSuggestionsActive(false), 500);
  };
  const handleDeleteClickButton = (e) => {
    saveValue('');
  };
  const handleClickButton = (e) => {
    const query = e.target.value === undefined ? '' : e.target.value.toLowerCase();
    setValue(query);
    const filterSuggestions = props.data.filter((suggestion) => suggestion.value.toLowerCase().indexOf(query) > -1);
    setSuggestions(filterSuggestions);
    setSuggestionsActive(true);
    setInputFocus();
  };

  const handleKeyDown = (e) => {
    // UP ARROW
    if (e.keyCode === 38) {
      if (suggestionIndex === 0) {
        return;
      }
      setSuggestionIndex(suggestionIndex - 1);
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (suggestionIndex - 1 === suggestions.length) {
        return;
      }
      setSuggestionIndex(suggestionIndex + 1);
    }
    // ENTER
    else if (e.keyCode === 13) {
      setValue(suggestions[suggestionIndex]);
      setSuggestionIndex(0);
      setSuggestionsActive(false);
    }
  };

  useEffect(() => {
    if (props.value !== '' && props.value !== undefined) {
      let selectedItem = props.data.find((suggestion) => {
        let temp =
          typeof suggestion[props.valueField] == 'string'
            ? suggestion[props.valueField]
            : suggestion[props.valueField].toString();
        let temp2 = typeof props.value == 'string' ? props.value : props.value.toString();
        return temp.toLowerCase().indexOf(temp2.toLowerCase()) > -1;
      });
      if (selectedItem === undefined) {
        selectedItem = props.data.find(
          (suggestion) =>
            suggestion[props.labelField]
              .toString()
              .toLowerCase()
              .indexOf((typeof props.value == 'string' ? props.value : props.value.toString()).toLowerCase()) > -1
        );
      } else if (selectedItem.value.toLowerCase() !== value.toLowerCase()) {
        // setValue(selectedItem.value);
        // const filterSuggestions = props.data.filter(
        //   (suggestion) => suggestion.value.toLowerCase().indexOf(selectedItem.value) > -1
        // );
        // setSuggestions(filterSuggestions);
        // setSuggestionsActive(true);
        // setInputFocus();
        // handleClickButton({target: { value: selectedItem.value}});
        if (onmount) {
          setValue(selectedItem.value);
          const filterSuggestions = props.data.filter(
            (suggestion) => suggestion.value.toLowerCase().indexOf(selectedItem.value) > -1
          );
          setSuggestions(filterSuggestions);
          setSuggestionsActive(false);
          setInputFocus();
          props.ChangeValue(selectedItem);
          setOnmnount(false);
        }
      }
      // if(selectedItem !== undefined) {
      //   setValue(selectedItem[props.labelField]);
      // }
    }
  }, [onmount, props, setInputFocus, value]);

  return (
    <div className="autocomplete" onBlur={handleLostFocus}>
      <header style={STYLES.header}>
        <input
          type="text"
          className="input"
          onFocus={handleClickButton}
          ref={nameInput}
          value={value}
          placeholder={props.placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {value != null && value !== '' && props.isClearable && (
          <>
            <hr className="hr"></hr>
            <span onClick={handleDeleteClickButton} className="svg">
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </span>
          </>
        )}
        <hr className="hr"></hr>
        <span className="svg" onClick={handleClickButton}>
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </span>
      </header>
      {suggestionsActive && (
        <Suggestions
          valueField={'id'}
          labelField={'value'}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          setValue={saveValue}
          setSuggestionsActive={setSuggestionsActive}
        />
      )}
    </div>
  );
};

const STYLES = {
  container: {
    display: 'grid',
    position: 'relative',
    border: '1px solid #cccccc',
    lineHeight: '33px',
    borderRadius: '4px',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto auto auto',
  },
  input: {
    border: '0px',
  },
};

export default AutoComplete;
