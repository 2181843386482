import { Col, Row, Table } from 'reactstrap';
import * as INVOICE_ASSOCIATION_STATUS from 'constants/invoice-association-statuses';
import * as INVOICE_ASSOCIATION_LINE_STATUS from 'constants/invoice-association-line-statuses';
import { format } from 'helpers/amount';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { displayDate, displayDateTime } from 'helpers/date';

const STYLES = {
  notesdiv: {
    display: 'inline-block',
    position: 'relative',
  },
  badgenote: {
    position: 'absolute',
    top: '0',
    right: '0',
    transform: 'translate(50%, -50%)',
  },
  noteicon: {
    margin: '0 !important',
    cursor: 'pointer',
  },
  textNote: {
    textAlign: 'end',
  },
  actionContainer: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translate(-50%, 0)',
    width: '100%',
  },
};

const AssociationItemLines = ({ associationId, lines }) => (
  <Table bordered>
    <thead>
      <tr>
        <th>Attività</th>
        <th>UdM</th>
        <th>Qta</th>
        <th>Prezzo unitario (€)</th>
        <th>Importo ordine (€)</th>
        <th>Importo fattura (€)</th>
      </tr>
    </thead>
    <tbody>
      {lines.map((invoiceAssociationLine, index) => (
        <tr
          key={`invoiceAssociationLine-${associationId}-${index}`}
          className={
            invoiceAssociationLine.statusId === INVOICE_ASSOCIATION_LINE_STATUS.APPROVED
              ? 'bg-success text-white'
              : undefined
          }
        >
          <td style={{ maxWidth: '350px' }}>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {invoiceAssociationLine.description}
            </div>
          </td>
          <td>{invoiceAssociationLine.unitOfMeasure}</td>
          <td className="text-right">{format(invoiceAssociationLine.quantity)}</td>
          <td className="text-right">{format(invoiceAssociationLine.unitAmount)}</td>
          <td className="text-right">
            {format(invoiceAssociationLine.orderAmount)}
            <div>
              <small>Rimanenza: {format(invoiceAssociationLine.orderAmountRemainder)}</small>
            </div>
          </td>
          <td
            className={
              'text-right' +
              ([INVOICE_ASSOCIATION_STATUS.TO_HANDLE, INVOICE_ASSOCIATION_STATUS.WAITING_FOR_APPROVAL].includes(
                invoiceAssociationLine.statusId
              ) && invoiceAssociationLine.invoiceAmount > invoiceAssociationLine.orderAmountRemainder
                ? ' text-danger'
                : '')
            }
          >
            {format(invoiceAssociationLine.invoiceAmount)}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const ActionButton = ({ iconType = 'fa', iconName, title, onClick }) => (
  <i
    className={`${iconType} fa-2x fa-${iconName} mr-3`}
    style={{ cursor: 'pointer' }}
    title={title}
    role="button"
    onClick={onClick}
  />
);

const ApproveActionButton = ({ onClick }) => (
  <ActionButton iconName="check" title="Approva l'associazione" onClick={onClick} />
);

const RejectActionButton = ({ onClick }) => (
  <ActionButton iconName="share" title="Restituisci al mittente" onClick={onClick} />
);

const SuspendActionButton = ({ onClick }) => (
  <ActionButton iconType="far" iconName="pause-circle" title="Sospendi l'approvazione" onClick={onClick} />
);

const ResumeActionButton = ({ onClick }) => (
  <ActionButton iconName="play" title="Rimetti in approvazione" onClick={onClick} />
);

const PurchaseInvoiceAssociationItem = ({
  invoiceAssociation,
  onApprove = (invoiceOrderAssociationId) => {},
  onSuspend = (invoiceOrderAssociationId) => {},
  onResume = (invoiceOrderAssociationId) => {},
  onReject = (invoiceOrderAssociationId) => {},
}) => {
  const [isNoteDialogVisible, setIsNoteDialogVisible] = useState(false);

  const handleClickNote = () => {
    setIsNoteDialogVisible(true);
  };

  return (
    <>
      <Dialog
        header="Note"
        visible={isNoteDialogVisible}
        style={{ width: '50vw' }}
        onHide={() => setIsNoteDialogVisible(false)}
      >
        <ul className="list-group">
          {invoiceAssociation.notes?.map((note, index) => (
            <li className="list-group-item" key={index}>
              <span>{note.text}</span>
              <div style={STYLES.textNote}>
                <span className="badge badge-light">
                  {note.displayName} {displayDateTime(new Date(note.creationDate))}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </Dialog>
      {/* <pre>{JSON.stringify(invoiceAssociation, undefined, '\t')}</pre> */}
      {/* 1ma riga intestazioni fattura: nr, data, fornitore + visualizza fattura */}
      <Row>
        <Col xs={9}>
          <span className="mr-2">
            Nr. Fatt. fornitore: <b>{invoiceAssociation.invoiceNumber}</b>
          </span>
          <span className="mr-2">
            del <b>{displayDate(invoiceAssociation.invoiceDate)}</b>
          </span>
          <span className="mr-2">
            Fornitore: <b>{invoiceAssociation.invoiceSupplierDisplayName}</b>
          </span>
          <span>
            P.IVA: <b>{invoiceAssociation.invoiceSupplierVatNumber}</b>
          </span>
        </Col>
        {invoiceAssociation.hasAttachment && (
          <Col xs={2} className="offset-1 text-center">
            <a
              href={`/api/invoice/getattachment?invoiceId=${invoiceAssociation.purchaseInvoiceId}`}
              target="_blank"
              rel="noreferrer"
            >
              Visualizza fattura
            </a>
          </Col>
        )}
      </Row>
      {/* 2nda riga intestazioni fattura: importi + alert + azioni */}
      <Row>
        <Col xs={9}>
          <div className="text-right">
            Importo fattura: <b>{format(invoiceAssociation.invoiceAmount)}€</b>
          </div>
          <div className="text-right">
            Importo addebitato di competenza: <b>{format(invoiceAssociation.amountAssociatedWithInvoice)}€</b>
          </div>
        </Col>
        <Col xs={1}>
          {invoiceAssociation.alert && (
            <i
              className="fa fa-2x fa-exclamation-triangle text-warning"
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            />
          )}
        </Col>
        <Col xs={2}>
          <div style={STYLES.actionContainer}>
            {invoiceAssociation.statusId === INVOICE_ASSOCIATION_STATUS.WAITING_FOR_APPROVAL &&
              invoiceAssociation.actionsAvailable && (
                <>
                  <ApproveActionButton onClick={() => onApprove(invoiceAssociation.id)} />
                  <SuspendActionButton onClick={() => onSuspend(invoiceAssociation.id)} />
                  <RejectActionButton onClick={() => onReject(invoiceAssociation.id)} />
                </>
              )}
            {invoiceAssociation.statusId === INVOICE_ASSOCIATION_STATUS.APPROVED && (
              <i className="fa fa-2x fa-check mr-3 text-success" title="Approvato" />
            )}
            {invoiceAssociation.statusId === INVOICE_ASSOCIATION_STATUS.SUSPENDED &&
              invoiceAssociation.actionsAvailable && (
                <ResumeActionButton onClick={() => onResume(invoiceAssociation.id)} />
              )}
            {invoiceAssociation.noteCount > 0 && (
              <div style={STYLES.notesdiv}>
                <i
                  size="sm"
                  className="far fa-2x fa-sticky-note"
                  title="Visualizza note"
                  style={STYLES.noteicon}
                  onClick={handleClickNote}
                />
                <span className="badge badge-pill badge-primary" style={STYLES.badgenote}>
                  {invoiceAssociation.noteCount}
                </span>
              </div>
            )}
            {/* </i> */}
          </div>
        </Col>
      </Row>
      {/* Informazioni CDP + tabella associazioni */}
      <Row>
        <Col xs={8} className="offset-1">
          {/* Informazioni CDP */}
          {invoiceAssociation.paymentCertificateNumber && (
            <div>
              CDP nr. {invoiceAssociation.paymentCertificateNumber} -{' '}
              {displayDate(invoiceAssociation.paymentCertificateDate)}
            </div>
          )}
          {/* Tabella associazioni */}
          <AssociationItemLines associationId={invoiceAssociation.id} lines={invoiceAssociation.lines} />
        </Col>
      </Row>
    </>
  );
};

export default PurchaseInvoiceAssociationItem;
