import FormInput from 'components/forms/FormInput';
import Loader from 'components/layout/Loader';
import AuthContext from 'contexts/AuthContext';
import useFeedback from 'hooks/useFeedback';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import { fetchCommissionWithId, fetchCommissions } from 'services/api/commissions';
import { deleteMainCommission, getLinkedCommissions, linkCommission } from 'services/api/linked-commissions';
import CommissionContext from 'contexts/CommissionContext';
import FormSelectSearchPlatinumEdition from 'components/forms/FormSelectSearch_platinumEdition';
import COMMISSION_MANAGEMENT_PERMISSIONS from 'constants/commission-management-permissions';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { NavLink } from 'react-router-dom';
import ROUTES from 'routes';
import { formatISODate } from 'helpers/formatters';

const useParseCustomResponse = () => {
  const { logout } = useContext(AuthContext);
  const { error } = useFeedback();

  const parseCustomResponse = useCallback(
    (response) => {
      if (response.success) {
        return { success: true, data: response.data };
      }

      if (response.authenticated === false) {
        logout();
      }

      if (response.authorized === false) {
        error('Non sei autorizzato a vedere questa pagina');
        return {
          success: false,
          message: 'Non sei autorizzato a vedere questa pagina',
        };
      }

      error(response.message);
      return { success: false, message: response.message };
    },
    [error, logout]
  );

  return parseCustomResponse;
};

const useRetrieveData = (commissionId) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [commissionRawResponse, linkedCommissionRawResponse] = await Promise.all([
        fetchCommissionWithId(commissionId),
        getLinkedCommissions(commissionId),
      ]);

      const commission = parseCustomResponse(commissionRawResponse);
      const linkedCommission = parseCustomResponse(linkedCommissionRawResponse);

      if (!commission.success || !linkedCommission.success) {
        setResponse();
      } else {
        setResponse({
          commissionResponse: commission,
          linkedCommissionResponse: linkedCommission,
        });
      }

      setLoading(false);
    } catch (err) {
      error('Errore durante il download dei dati: ', err);
      setLoading(false); // Handle the error state appropriately.
    }
  }, [commissionId, error, parseCustomResponse]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { loading, response };
};

const LinkedCommissionsIndexPage = () => {
  const [hasMainCommission, setHasMainCommission] = useState(false);
  const [commissions, setCommissions] = useState();
  const [selectedCommission, setSelectedCommission] = useState();
  const [mainCommissionId, setMainCommissionId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { commissionId } = useParams();
  const { loading, response } = useRetrieveData(commissionId);
  const { success } = useFeedback();
  const { companyId } = useContext(CommissionContext);
  const { hasPermission } = useCommissionPermissions();

  const canEdit =
    !response.commissionResponse?.data.isCommissionClosed &&
    hasPermission(COMMISSION_MANAGEMENT_PERMISSIONS.COMMISSION.EDIT);
  const canDelete =
    !response.commissionResponse?.data.isCommissionClosed &&
    hasPermission(COMMISSION_MANAGEMENT_PERMISSIONS.COMMISSION.DELETE);

  const deleteHandler = () => {
    setIsLoading(true);
    deleteMainCommission(commissionId)
      .then((response) => {
        if (response.ok) {
          success('Commessa scollegata correttamente', 'Successo');
          setMainCommissionId();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitCommissionToHandler = () => {
    setIsLoading(true);
    linkCommission(commissionId, selectedCommission)
      .then((response) => {
        if (response.ok) {
          success('Commessa collegata correttamente', 'Successo');
          setMainCommissionId(selectedCommission);
          setSelectedCommission();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (optionSelected) => {
    setSelectedCommission(optionSelected.value);
  };

  const fetchDataHandler = async (searchTerm) => {
    const params = {
      filters: { companyId, search: searchTerm },
      sort: { column: 'commissionId', direction: 'ASC' },
    };

    try {
      const { commissions } = await fetchCommissions(params);
      return commissions.filter((c) => c.commissionId !== commissionId && !c.isSubcommission);
    } catch (err) {
      console.error(err.message);
      return [];
    }
  };

  useEffect(() => {
    if (!response) return;
    if (!response.commissionResponse?.data.mainCommissionId) setHasMainCommission(false);
    else {
      setHasMainCommission(true);
      setMainCommissionId(response.commissionResponse?.data.mainCommissionId);
    }

    setCommissions(response.linkedCommissionResponse?.data.linkedCommissions);
  }, [response]);

  return (
    <>
      <h1 className="h2 mb-3">Commesse collegate</h1>

      {loading ? (
        <Loader />
      ) : !hasMainCommission && commissions?.length > 0 ? (
        <div className="table-responsive">
          <Table striped bordered>
            <thead>
              <tr>
                <th>Commessa</th>
                <th>Ragione sociale</th>
                <th>Descrizione</th>
                <th>Linea di business</th>
                <th>Data Inizio</th>
                <th>Data Fine</th>
                <th>Stato</th>
              </tr>
            </thead>
            <tbody>
              {commissions?.map((commission, index) => (
                <tr key={index}>
                  <td>
                    <NavLink
                      to={ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS.replace(
                        ':commissionId',
                        commission.commissionId
                      )}
                    >
                      {commission.commissionId}
                    </NavLink>
                  </td>
                  <td>{commission.businessName}</td>
                  <td>{commission.description}</td>
                  <td>{commission.businessLine}</td>
                  <td>{formatISODate(commission.expectedStartDate)}</td>
                  <td>{formatISODate(commission.expectedEndDate)}</td>
                  <td>{commission.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <>
          {isLoading && <Loader />}
          <Row>
            <Col lg={8}>
              <Label htmlFor="commission" className="d-block">
                Commessa principale
              </Label>
              <Row>
                <Col md={6}>
                  {mainCommissionId ? (
                    <FormInput id="commission" value={mainCommissionId} disabled />
                  ) : (
                    <FormSelectSearchPlatinumEdition
                      placeholder="Cerca per n° commessa, nome cliente, descrizione..."
                      valuePropertyName="commissionId"
                      labelPropertyName={['commissionId', 'customerDisplayName', 'commissionDescription']}
                      onFetchData={fetchDataHandler}
                      handleSelectedValue={handleChange}
                      noOptionsMessage="Nessuna commessa trovata..."
                      disabled={!canEdit}
                    />
                  )}
                </Col>
                {canEdit && (
                  <Col md={2}>
                    <Button
                      disabled={!selectedCommission}
                      onClick={submitCommissionToHandler}
                      block
                      type="button"
                      color="primary"
                    >
                      Associa
                    </Button>
                  </Col>
                )}
                {mainCommissionId && canDelete && (
                  <Col md={2} className="mb-3">
                    <Button block type="button" color="danger" onClick={deleteHandler}>
                      Elimina
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default LinkedCommissionsIndexPage;
