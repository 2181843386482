import { downloadRemoteFile } from 'helpers/api';
import { parseDateFromFilter, parseDateToFilter } from 'helpers/filters';

const createEmptySuccessfulResponse = () => {
  return {
    ok: true,
  };
};

const createErrorResponse = (errorMessage = 'Si è verificato un errore') => {
  return {
    ok: false,
    message: errorMessage,
  };
};

const fetchPurchaseOrders = async (commissionId, request) => {

  const queryParams = {
    CommissionId: commissionId,
    CurrentPage: request?.currentPage,
    NumRecords: request?.pageSize,
    Sort: request?.sort,
    SortColumn: request?.sortColumn,
    PurchaseOrderIdFilter: request?.filters?.purchaseOrderId,
    BusinessNameFilter: request?.filters?.supplier,
    TypeFilter: request?.filters?.type?.id,
    StatusFilter: request?.filters?.statusId?.id,
    FictitiousOrderFilter: request?.filters?.isFictitiousOrder?.id,
    DeliveryDateFromFilter: parseDateFromFilter(request?.filters?.deliveryDateFrom),
    DeliveryDateToFilter: parseDateToFilter(request?.filters?.deliveryDateTo),
    DurcValidityFilter: request?.filters?.durcValidity?.id,
    SignedFilter: request?.filters?.signed?.id
  };

  const params = new URLSearchParams(JSON.parse(JSON.stringify(queryParams))).toString();

  try {
    const response = await fetch(`/api/purchase-orders?${params}`);
    return {
      ok: response.ok,
      status: response.status,
      data: await response.json(),
    };
  } catch (err) {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const createPurchaseOrder = async (purchaseOrder) => {
  const response = await fetch(`/api/purchase-orders`, {
    method: 'POST',
    body: JSON.stringify(purchaseOrder),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return {
    ok: response.ok,
    status: response.status,
    data: await response.json(),
  };
};

const updatePurchaseOrder = async (orderId, data) => {
  try {
    const response = await fetch(`/api/purchase-orders/${orderId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return {
      ok: response.ok,
      status: response.status,
      message: response.ok ? 'Il tuo ordine è stato salvato' : "Si è verificato un errore nel salvataggio dell'ordine",
    };
  } catch {
    return {
      ok: false,
      message: "Si è verificato un errore nel salvataggio dell'ordine",
    };
  }
};

const fetchPurchaseOrderWithId = async (commissionId, purchaseOrderId) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}`);
    const data = await response.json();
    return {
      ok: response.ok,
      status: response.status,
      data,
      message: response.ok ? undefined : data?.detail || data?.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const fetchPurchaseOrderLines = async (purchaseOrderId) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/lines`);
    const data = await response.json();
    return {
      ok: response.ok,
      status: response.status,
      data,
      message: data?.detail || data?.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const updatePurchaseOrderLines = async (purchaseOrderId, postData) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/lines`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });

    return {
      ok: response.ok,
      message: response.ok ? 'Il tuo ordine è stato aggiornato con successo' : 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const fetchPurchaseOrderPaymentCertificates = async (purchaseOrderId) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/payment-certificates`);
    const data = await response.json();

    if (response.ok) {
      return {
        ok: true,
        data: {
          orderId: data.orderId,
          orderDate: new Date(Date.parse(data.orderDate)),
          amount: data.amount,
          invoicedAmountPercentage: data.invoicedAmountPercentage,
          supplierName: data.supplierName,
          guaranteeWithholdingId: data.guaranteeWithholdingId,
          guaranteeWithholdingPercentage: data.guaranteeWithholdingPercentage,
          guaranteeWithholdingHeaderText: data.guaranteeWithholdingHeaderText,
          guaranteeWithholdingTableHeaderText: data.guaranteeWithholdingTableHeaderText,
          amountRemainder: data.amountRemainder,
          guaranteeWithholdingAmountRemainder: data.guaranteeWithholdingAmountRemainder,
          advanceWithholdingAmountRemainder: data.advanceWithholdingAmountRemainder,
          isCommissionClosed: data.isCommissionClosed,
          paymentCertificates: data.paymentCertificates.map((paymentCertificate) => ({
            number: paymentCertificate.number,
            date: new Date(Date.parse(paymentCertificate.date)),
            amount: paymentCertificate.amount,
            guaranteeWithholdingValue: paymentCertificate.guaranteeWithholdingValue,
            redeterminationAmount: paymentCertificate.redeterminationAmount,
            advance: paymentCertificate.advance,
            advancePercentage: paymentCertificate.advancePercentage,
            viewable: true,
            downloadable: paymentCertificate.downloadable,
            editable: false,
            status: paymentCertificate.status,
          })),
        },
      };
    } else {
      return {
        ok: false,
        errorMessage: 'Si è verificato un errore nel caricare i certificati di pagamento',
      };
    }
  } catch {
    return {
      ok: false,
      errorMessage: 'Si è verificato un errore nel caricare i certificati di pagamento',
    };
  }
};

const fetchPurchaseOrderPaymentCertificate = async (purchaseOrderId, paymentCertificateId) => {
  try {
    const httpResponse = await fetch(
      `/api/purchase-orders/${purchaseOrderId}/payment-certificates/${paymentCertificateId}`
    );
    const httpResponseBody = await httpResponse.json();
    return {
      ok: httpResponse.ok,
      data: httpResponseBody,
      message: httpResponse.ok
        ? undefined
        : httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const createPaymentCertificate = async (purchaseOrderId, data) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/payment-certificates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return {
        ok: true,
        message: 'Il certificato di pagamento è stato creato con successo.',
      };
    } else {
      const responseBody = await response.json();
      return {
        ok: false,
        message:
          responseBody.detail ||
          responseBody.title ||
          'Si è verificato un errore nella creazione del certificato di pagamento.',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore nella creazione del certificato di pagamento.',
    };
  }
};

const editPurchaseOrderPaymentCertificate = async (purchaseOrderId, paymentCertificateId, paymentCertificateData) => {
  try {
    const httpResponse = await fetch(
      `/api/purchase-orders/${purchaseOrderId}/payment-certificates/${paymentCertificateId}`,
      { method: 'PUT', body: JSON.stringify(paymentCertificateData), headers: { 'Content-Type': 'application/json' } }
    );
    if (httpResponse.ok) {
      return { ok: true };
    } else {
      const httpResponseBody = await httpResponse.json();
      return {
        ok: false,
        message: httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const requestPurchaseOrderPaymentCertificateApproval = async (purchaseOrderId, paymentCertificateId) => {
  try {
    const httpResponse = await fetch(
      `/api/purchase-orders/${purchaseOrderId}/payment-certificates/${paymentCertificateId}/request-approval`,
      { method: 'PATCH' }
    );
    const httpResponseBody = await httpResponse.json();
    if (httpResponse.ok) {
      return { ok: true, data: httpResponseBody };
    } else {
      return {
        ok: false,
        data: httpResponseBody,
        message: httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const requestPurchaseOrderPaymentCertificateChanges = async (purchaseOrderId, paymentCertificateId, request) => {
  console.log(request)
  console.log(JSON.stringify(request))
  try {
    const httpResponse = await fetch(
      `/api/purchase-orders/${purchaseOrderId}/payment-certificates/${paymentCertificateId}/request-changes`,
      { 
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
      }
    );
    const httpResponseBody = await httpResponse.json();
    if (httpResponse.ok) {
      return { ok: true, data: httpResponseBody };
    } else {
      return {
        ok: false,
        data: httpResponseBody,
        message: httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const approvePurchaseOrderPaymentCertificate = async (purchaseOrderId, paymentCertificateId) => {
  try {
    const httpResponse = await fetch(
      `/api/purchase-orders/${purchaseOrderId}/payment-certificates/${paymentCertificateId}/approve`,
      { method: 'PATCH' }
    );
    const httpResponseBody = await httpResponse.json();
    if (httpResponse.ok) {
      return { ok: true, data: httpResponseBody };
    } else {
      return {
        ok: false,
        data: httpResponseBody,
        message: httpResponseBody.detail || httpResponseBody.title || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const fetchPurchaseOrderAssociatedInvoices = async (purchaseOrderId) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/invoices`);
    const responseBody = await response.json();
    if (response.ok) {
      return {
        ok: response.ok,
        data: {
          ...responseBody,
          items: responseBody.items.map((item) => ({
            ...item,
            invoiceDate: new Date(Date.parse(item.invoiceDate)),
            paymentCertificateDate: new Date(Date.parse(item.paymentCertificateDate)),
          })),
        },
      };
    }
    return {
      ok: response.ok,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const requestPurchaseOrderApproval = async (purchaseOrderId) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/request-approval`, { method: 'PATCH' });
    const responseBody = await response.json();
    return {
      ok: response.ok,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const approvePurchaseOrder = async (purchaseOrderId) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/approve`, { method: 'PATCH' });
    const responseBody = await response.json();
    return {
      ok: response.ok,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const requestPurchaseOrderChanges = async (orderId, request) => {
  console.log(request)
  console.log(JSON.stringify(request))
  try {
    const response = await fetch(`/api/purchase-orders/${orderId}/request-changes`, 
    { 
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
    const responseBody = await response.json();
    return {
      ok: response.ok,
      data: responseBody,
      message: responseBody.detail || responseBody.title || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const settlePurchaseOrder = async (purchaseOrderId) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/settle`, { method: 'PATCH' });
    if (response.ok) {
      return createEmptySuccessfulResponse();
    } else {
      const responseBody = await response.json();
      return createErrorResponse(responseBody.detail);
    }
  } catch {
    return createErrorResponse();
  }
};

const voidPurchaseOrder = async (purchaseOrderId) => {
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/void`, { method: 'PATCH' });
    if (response.ok) {
      return createEmptySuccessfulResponse();
    } else {
      const responseBody = await response.json();
      return createErrorResponse(responseBody.detail);
    }
  } catch {
    return createErrorResponse();
  }
};

const exportPurchaseOrderAsPdf = (purchaseOrderId, languageId = 'it') => {
  return downloadRemoteFile(
    `/api/purchase-orders/${purchaseOrderId}/export-as-pdf?languageId=${encodeURIComponent(languageId)}`
  );
};

export {
  createPurchaseOrder,
  updatePurchaseOrder,
  requestPurchaseOrderApproval,
  approvePurchaseOrder,
  requestPurchaseOrderChanges,
  fetchPurchaseOrderWithId,
  fetchPurchaseOrderLines,
  updatePurchaseOrderLines,
  fetchPurchaseOrders,
  fetchPurchaseOrderPaymentCertificates,
  fetchPurchaseOrderPaymentCertificate,
  createPaymentCertificate,
  editPurchaseOrderPaymentCertificate,
  requestPurchaseOrderPaymentCertificateApproval,
  requestPurchaseOrderPaymentCertificateChanges,
  approvePurchaseOrderPaymentCertificate,
  fetchPurchaseOrderAssociatedInvoices,
  settlePurchaseOrder,
  voidPurchaseOrder,
  exportPurchaseOrderAsPdf
};
