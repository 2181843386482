import CommissionForm from 'components/forms/commission/CommissionForm';
import FormInput from 'components/forms/FormInput';
import Loader from 'components/layout/Loader';
import CustomInput from 'components/shared/forms/CustomInput';
import { ToReceiveObject, ToSendObject } from 'helpers/commissionMap';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap';
import ROUTES from 'routes';
import { fetchCommissionWithId, updateCommissionWithId } from 'services/api/commissions';
import useFetchCommissionFormOptions from './hooks/useFetchCommissionFormOptions';
import useParseCustomResponse from './hooks/useParseCustomResponse';
import useCommission from 'hooks/useCommission';
import useFeedback from 'hooks/useFeedback';
import useUnsavedChanges from 'hooks/useUnsavedChanges';

const EditCommissionPage = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { commissionId } = useParams();
  const { loading, response } = useFetchCommissionFormOptions();
  const [data, setData] = useState();

  const parseCustomResponse = useParseCustomResponse();
  const { success, error } = useFeedback();

  const { notify, clear } = useUnsavedChanges();

  const navigate = useNavigate();
  const { invalidate, companyId } = useCommission();

  const submitHandler = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = e.target.getObjectData();
    const post = ToSendObject({ ...formData });
    post.companyId = companyId;
    post.businessLineId = data.businessLineId;
    updateCommissionWithId(commissionId, JSON.stringify(post))
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success('commessa modificata correttamente', 'Successo');
          invalidate();
          navigate(ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS.replace(':commissionId', commissionId));
        }
      })
      .catch(() => error('Si è verificato un errore', 'Errore'))
      .finally(() => setSubmitting(false));
  };

  // handle enter/exit edit from edit mode
  useEffect(() => {
    notify();
    return () => clear();
  }, [clear, notify]);

  useEffect(() => {
    setInitialLoading(true);

    fetchCommissionWithId(commissionId)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          const updatedData = ToReceiveObject(response.data);
          setData(updatedData);
        }
      })
      .catch(() => error('Si è verificato un errore', 'Errore'))
      .finally(() => setInitialLoading(false));
  }, [commissionId, error, parseCustomResponse]);

  return (
    <>
      <h1 className="h2 mb-3">Modifica commessa</h1>
      {loading || initialLoading ? (
        <Loader />
      ) : (
        <>
          {submitting && <Loader />}
          {data && (
            <>
              <Row>
                <Col md={4}>
                  <FormInput type="text" label="ID" name="commissionId" disabled value={commissionId}></FormInput>
                </Col>
                <Col md={3} className="offset-md-2">
                  <CustomInput label="Creata da" name="createdBy" disabled value={data.creationName} />
                </Col>
                <Col md={3}>
                  <CustomInput
                    type="date"
                    min="2000-01-01"
                    max="9999-12-31"
                    label="Data creazione"
                    name="creationDate"
                    disabled
                    value={data.creationDate}
                  />
                </Col>
              </Row>
              <CommissionForm commission={data} onSubmit={submitHandler} disabled={false} options={response} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditCommissionPage;
