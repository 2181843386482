import { memo } from 'react';
import { Button } from 'reactstrap';

const BTN_DEFAULT_STYLE = {
  // fontWeight: '600',
  // textTransform: 'uppercase',
};

const BaseButton = ({ type = 'button', color, children, onClick, className, style, size }) => (
  <Button
    type={type}
    color={color}
    onClick={onClick}
    className={className}
    style={{ ...BTN_DEFAULT_STYLE, ...style }}
    size={size}
  >
    {children}
  </Button>
);

export default memo(BaseButton);
