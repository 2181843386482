const handleErrorResponse = (response) => {
    switch (response.status) {
      case 401:
        return {
          success: false,
          authenticated: false,
        };
      case 403:
        return {
          success: false,
          authenticated: true,
          authorized: false,
        };
      case 404:
        return {
          success: false,
          message: "nessun dato trovato",
        };
        case 504:
            return {
                success: false,
                message: "server timeout, controlla la tua connessione ad internet",
              };
        case 500:
      default:
        return {
          success: false,
          message: "Si è verificato un errore",
        };
    }
  };

  const fetchSalesInvoicesExport = async (requestData) => {
    if (!requestData)
      return {
        ok: false,
        message: "Errore paramentri assenti o non corretti",
      };
  
    const request = {
      CompanyId: requestData.companyId,
      Offset: requestData.currentPage,
      Limit: requestData.numRecords,
      Sort: requestData.sort,
      SortColumn: requestData.sortColumn,
      CommissionFilter: requestData.commissionId,
      BusinessNameFilter: requestData.businessName,
      EmissionDateFromFilter: requestData.emissionDateFrom,
      EmissionDateToFilter: requestData.emissionDateTo,
      RegistrationDateFromFilter: requestData.registrationDateFrom,
      RegistrationDateToFilter: requestData.registrationDateTo,
      SalesOrderFilter: requestData.salesOrderId,
    //   StatusIdFilter: requestData.statusId?.id,
    };

    console.log(request)
    const queryString = new URLSearchParams(JSON.parse(JSON.stringify(request)));
    const response = await fetch(`/api/sales-invoices-export?${queryString}`);
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
    return handleErrorResponse(response);
  };


  export {
    fetchSalesInvoicesExport
  }