import UploadPurchaseOrderDocument from 'components/commission-management/purchase-order/UploadPurchaseOrderDocument';
import Loader from 'components/layout/Loader';
import useFeedback from 'hooks/useFeedback';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Table } from 'reactstrap';
import { fetchPurchaseOrderDocumentTypes } from 'services/api/purchase-order-attachments-types';
import {
  deletePurchaseOrderAttachment,
  fetchPurchaseOrderAttachments,
  insertPurchaseOrderAttachment,
  updatePurchaseOrderAttachment,
} from 'services/api/purchase-order-attachments';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import { Dialog } from 'primereact/dialog';
import { displayDate } from 'helpers/date';
import useAuth from 'hooks/useAuth';

const STYLES = {
  truncateCellText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

const useParseCustomResponse = () => {
  const { logout } = useAuth();
  const { error } = useFeedback();

  const parseCustomResponse = useCallback(
    (response) => {
      if (response.success) {
        return { success: true, data: response.data };
      }

      if (response.authenticated === false) {
        logout();
      }

      if (response.authorized === false) {
        error('Non sei autorizzato a vedere questa pagina');
        return { success: false, message: 'Non sei autorizzato a vedere questa pagina' };
      }

      error(response.message);
      return { success: false, message: response.message };
    },
    [error, logout]
  );

  return parseCustomResponse;
};

const useRetrieveData = (purchaseOrderId) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const fetchData = useCallback(async () => {
    const [DocumentRawTypes, AttachmentsRaw] = await Promise.all([
      fetchPurchaseOrderDocumentTypes(),
      fetchPurchaseOrderAttachments(purchaseOrderId),
    ]);

    return {
      DocumentRawTypes,
      AttachmentsRaw,
    };
  }, [purchaseOrderId]);

  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(({ DocumentRawTypes, AttachmentsRaw }) => {
        const attachmentParsedResponse = parseCustomResponse(AttachmentsRaw);
        setResponse({
          documentTypesResponse: parseCustomResponse(DocumentRawTypes),
          attachmentResponse: attachmentParsedResponse,
          editableResponse: attachmentParsedResponse.data.editable,
        });
      })
      .catch(() => {
        error('Errore durante il download dei dati');
      })
      .finally(() => setLoading(false));
  }, [fetchData, parseCustomResponse, error]);

  return { loading, response };
};

const PreviewDocuments = ({ documents, onDeleteDocument, canEdit, purchaseOrderId, canDownload, onShowModal }) => {
  return (
    <Table responsive bordered style={{ tableLayout: 'fixed' }}>
      <thead>
        <tr>
          <th className="align-middle">Tipo documento</th>
          <th className="align-middle">Nome del file</th>
          <th className="align-middle">Numero documento</th>
          <th className="align-middle">Data</th>
          <th className="align-middle">Nota</th>
          <th className="align-middle" style={{ width: '60px' }}></th>
          <th className="align-middle" style={{ width: '60px' }}></th>
          <th className="align-middle" style={{ width: '100px' }}></th>
        </tr>
      </thead>
      <tbody>
        {documents?.map((document) => (
          <PreviewDocument
            key={`document-${document.attachmentId}`}
            document={document}
            onDeleteDocument={onDeleteDocument}
            canEdit={canEdit}
            purchaseOrderId={purchaseOrderId}
            canDownload={canDownload}
            onShowModal={onShowModal}
          />
        ))}
      </tbody>
    </Table>
  );
};

const PreviewDocument = ({ document, onDeleteDocument, canEdit, purchaseOrderId, canDownload, onShowModal }) => {
  const deleteHandler = () => {
    onDeleteDocument(document.attachmentId);
  };

  const showModalHandler = () => {
    onShowModal?.(document.attachmentId);
  };

  return (
    <tr>
      <td className="align-middle" style={STYLES.truncateCellText}>
        {document.description}
      </td>
      <td className="align-middle" style={STYLES.truncateCellText}>
        {document.name}
      </td>
      <td className="align-middle" style={STYLES.truncateCellText}>
        {document.documentNumber}
      </td>
      <td className="align-middle" style={STYLES.truncateCellText}>
        {document.attachmentDate && displayDate(new Date(document.attachmentDate))}
      </td>
      <td className="align-middle" style={STYLES.truncateCellText} title={document.note}>
        {document.note}
      </td>
      <td className="align-middle text-center">
        {canEdit && (
          <Button
            color="primary"
            onClick={showModalHandler}
            size="sm"
            title={`Modifica ${document.description.toLowerCase()}`}
          >
            <i className="fa fa-pen" />
          </Button>
        )}
      </td>
      <td className="align-middle text-center">
        {canEdit && (
          <Button
            color="danger"
            onClick={deleteHandler}
            size="sm"
            title={`Elimina ${document.description.toLowerCase()}`}
          >
            <i className="fa fa-trash" />
          </Button>
        )}
      </td>
      <td className="align-middle text-center">
        {canDownload && (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/api/purchase-order/${purchaseOrderId}/attachments/download/${document.attachmentId}`}
            className="small"
            title={`Visualizza ${document.description.toLowerCase()}`}
          >
            Visualizza
          </a>
        )}
      </td>
    </tr>
  );
};

const PurchaseOrderAttachmentsPage = () => {
  const [formId, setFormId] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [editable, setEditable] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const parseCustomResponse = useParseCustomResponse();
  const { purchaseOrderId } = useParams();
  const { hasPermission } = useCommissionPermissions();
  const { success } = useFeedback();
  const { loading, response } = useRetrieveData(purchaseOrderId);
  const [document, setDocument] = useState();
  const [isModalContractVisible, setIsModalDocumentVisible] = useState(false);

  const canEdit = editable && hasPermission(PURCHASE_ORDER_PERMISSIONS.ATTACHMENTS.EDIT);
  const canDownload = hasPermission(PURCHASE_ORDER_PERMISSIONS.ATTACHMENTS.DOWNLOAD);

  const showDocumentModal = (attachmentId) => {
    setDocument(documents.find((x) => x.attachmentId === attachmentId));
    setIsModalDocumentVisible(true);
  };

  const handleOnDeleteAttachment = (id) => {
    if (window.confirm("Sei sicuro di voler eliminare l'allegato selezionato?")) {
      setIsLoading(true);
      deletePurchaseOrderAttachment(purchaseOrderId, id)
        .then((response) => {
          const parsedResponse = parseCustomResponse(response);
          if (parsedResponse.success) {
            success("L'allegato è stato eliminato");
            setDocuments((documents) => {
              const newDocuments = [...documents];
              return newDocuments.filter((doc) => doc.attachmentId !== id);
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleOnUpdateAttachmentFormSubmit = (formData, data, documentTypeDescription) => {
    console.log(documentTypeDescription)
    setIsModalDocumentVisible(false);
    setIsLoading(true);
    updatePurchaseOrderAttachment(purchaseOrderId, document.attachmentId, formData)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success("L'allegato è stato modificato");
          console.log(data)
          setDocuments((docs) =>
            docs.map((doc) =>
              doc.attachmentId === document.attachmentId
                ? {
                    ...doc,
                    description: documentTypeDescription.description,
                    name: data.file?.name ? data.file.name : doc.name,
                    documentNumber: data.documentNumber,
                    attachmentDate: data.attachmentDate,
                    note: data.note,
                  }
                : doc
            )
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleOnCreateAttachmentFormSubmit = (formData, data, documentTypeDescription) => {
    console.log(documentTypeDescription)
    setIsLoading(true);
    insertPurchaseOrderAttachment(purchaseOrderId, formData)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success("L'allegato è stato aggiunto");
          setDocuments((docs) => [
            ...docs,
            {
              attachmentId: parsedResponse.data.attachmentId,
              description: documentTypeDescription.description,
              documentTypeId: data.documentTypeId,
              name: data.file.name,
              documentNumber: data.documentNumber,
              attachmentDate: data.attachmentDate,
              note: data.note,
            },
          ]);
          setFormId((prevFormId) => prevFormId + 1);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!response) {
      return;
    }
    setEditable(response.attachmentResponse.data?.editable);
    setDocuments(response.attachmentResponse.data?.attachments);
  }, [response]);

  return (
    <>
      {(loading || isLoading) && <Loader />}
      {response && (
        <>
          <Dialog
            header="Modifica documento"
            visible={isModalContractVisible}
            style={{ width: '60vw' }}
            onHide={() => setIsModalDocumentVisible(false)}
          >
            <UploadPurchaseOrderDocument
              documentTypes={response?.documentTypesResponse.data?.types}
              formName="Document_"
              formId={formId}
              canEdit={canEdit}
              onSubmit={handleOnUpdateAttachmentFormSubmit}
              document={document}
              inline={false}
              isFileRequired={false}
            />
          </Dialog>
          {documents.length > 0 && (
            <section>
              <h2>Allegati caricati</h2>
              <PreviewDocuments
                documents={documents}
                canEdit={canEdit}
                purchaseOrderId={purchaseOrderId}
                canDownload={canDownload}
                onDeleteDocument={handleOnDeleteAttachment}
                onShowModal={showDocumentModal}
              />
            </section>
          )}
          {canEdit && (
            <section>
              <h2>Carica un nuovo allegato</h2>
              <UploadPurchaseOrderDocument
                documentTypes={response?.documentTypesResponse.data?.types}
                formName="Document_"
                formId={formId}
                canEdit={canEdit}
                inline
                onSubmit={handleOnCreateAttachmentFormSubmit}
              />
            </section>
          )}
          {!canEdit && documents.length === 0 && <p>Non ci sono allegati da visualizzare</p>}
        </>
      )}
    </>
  );
};

export default PurchaseOrderAttachmentsPage;
