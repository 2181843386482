const defaultIconClassNames = {
  error: 'toast-error',
  info: 'toast-info',
  success: 'toast-success',
  warning: 'toast-warning',
};

const ToastMessage = ({
  className = 'toast',
  type,
  iconClassNames = defaultIconClassNames,
  iconClassName = iconClassNames[type],
  closeButton = false,
  onClick = () => {},
  onCloseClick = () => {},
  title = false,
  titleClassName = 'toast-title',
  message = false,
  messageClassName = 'toast-message',
  ...restProps
}) => (
  <div {...restProps} className={[className, iconClassName].join(' ')} onClick={onClick}>
    {!!closeButton && (
      <button className="toast-close-button" onClick={onCloseClick}>
        &times;
      </button>
    )}
    {!!title && <div className={titleClassName}>{title}</div>}
    {!!message && <div className={messageClassName}>{message}</div>}
  </div>
);

export default ToastMessage;
