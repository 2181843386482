const formatMonthYear = (month, year) =>
  new Date(year, month - 1, 1).toLocaleDateString('it-IT', {
    month: 'long',
    year: 'numeric',
  });

const AlertIcon = ({ name, backgroundColor }) => (
  <div className={`icon-circle bg-${backgroundColor}`}>
    <i className={`fas fa-${name} text-white`} />
  </div>
);

const Alert = ({ alert, onClick = ({ isSpaLink, companyId, actionLink }) => {} }) => {
  const { actionLink, icon, year, month, firstName, lastName, displayName, typeDisplayName, companyId, isSpaLink } =
    alert;
  return (
    <div
      className="dropdown-item d-flex align-items-center centro-messaggi-item"
      onClick={() => onClick({ isSpaLink, companyId, actionLink })}
    >
      <div className="mr-3">
        <AlertIcon name={icon.iconName} backgroundColor={icon.backgroundColor} />
      </div>
      <div>
        <div className="small text-gray-700">
          <b>{companyId}</b> - {formatMonthYear(month, year)}, {lastName} {firstName}
        </div>
        <div className="small text-gray-700">{displayName}</div>
        <span>{typeDisplayName}</span>
      </div>
    </div>
  );
};

const AlertDropdownToggle = ({ alertsCount, dropdownCollapsed = true, onClick }) => (
  <button
    className="nav-link dropdown-toggle"
    aria-haspopup={alertsCount > 0}
    aria-expanded={!dropdownCollapsed}
    onClick={alertsCount > 0 ? onClick : undefined}
  >
    <i className={`fas fa-bell fa-fw${alertsCount === 0 ? '' : ' fa-bell-shake'}`}></i>
    {alertsCount > 0 && <span className="badge badge-danger badge-counter">{alertsCount}</span>}
  </button>
);

const AlertDropdown = ({
  alerts = [],
  collapsed = true,
  onAlertClick = ({ companyId, actionLink, isSpaLink }) => {},
}) => (
  <div
    className={`dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in${collapsed ? '' : ' show'}`}
  >
    <h6 className="dropdown-header">Centro avvisi</h6>
    {alerts.map((alert, i) => (
      <Alert key={`ALERT_K_${i}`} alert={alert} onClick={onAlertClick} />
    ))}
    <div className="dropdown-item"></div>
  </div>
);

export { AlertDropdownToggle, AlertDropdown, Alert, AlertIcon };
