const Suggestions = (props) => {
    const handleClick = (e) => {
        props.setSuggestions([]);
        props.setValue(e.target.innerText);
        props.setSuggestionsActive(false);
    };

    return (
      <ul className="suggestions">
        {props.suggestions.map((suggestion, index) => {
          return (
            <li
              className={index === props.suggestionIndex ? "sugg active" : "sugg"}
              key={index}
              onClick={handleClick}>
              {suggestion[props.labelField]}
            </li>
          );
        })}
      </ul>
    );
};


export default Suggestions;