import { useEffect, useState } from 'react';
import { fetchCommissionStatuses } from 'services/api/commissionStatus';

const useCommissionStatusesOptions = () => {
  const [commissionStatusesOptions, setCommissionStatusesOptions] = useState([]);

  // fetch commission statuses options
  useEffect(() => {
    const cancellationTokenSource = new AbortController();
    fetchCommissionStatuses({ cancellationToken: cancellationTokenSource.signal })
      .then((commissionStatuses) => {
        console.debug(commissionStatuses);
        setCommissionStatusesOptions(
          commissionStatuses.map((cs) => ({ id: cs.commissionStatusId, description: cs.name }))
        );
      })
      .catch(() => {
        // do nothing on failure
      });

    return () => cancellationTokenSource.abort();
  }, []);

  return { commissionStatusesOptions };
};

export default useCommissionStatusesOptions;
