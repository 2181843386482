export const ADVANCE = 0;
export const TOTAL_PRODUCTION = 1;
export const WARRANTY_RELEASE = 2;

const PAYMENT_CERTIFICATE_TYPES = Object.freeze({
  ADVANCE,
  TOTAL_PRODUCTION,
  WARRANTY_RELEASE,
});

export default PAYMENT_CERTIFICATE_TYPES;
