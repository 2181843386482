import { useContext } from 'react';
import SalesOrderManagementContext from '../shared/context/SalesOrderManagementContext';


const useInvalidateSalesOrder = () => {
  const { invalidate } = useContext(SalesOrderManagementContext);
  return invalidate;
};

export default useInvalidateSalesOrder;
