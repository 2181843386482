import ConfirmSwitchCompanyContext from 'contexts/ConfirmSwitchCompanyContext';
import useFeedback from 'hooks/useFeedback';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { Button } from 'reactstrap';

const switchCompany = async (companyId) => {
  try {
    const response = await fetch(`/api/profile/switch-company/${companyId}`, {
      method: 'POST',
    });
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message);
    }
  } catch {
    throw new Error('Si è verificato un errore durante il cambio di società.');
  }
};

const ConfirmSwitchCompanyDialog = ({ visible, onConfirm, onHide }) => (
  <Dialog
    position="top"
    draggable={false}
    header={<>Conferma cambio di società</>}
    footer={
      <div>
        <Button color="secondary" onClick={onHide}>
          Annulla
        </Button>
        <Button color="primary" onClick={onConfirm}>
          Conferma
        </Button>
      </div>
    }
    visible={visible}
    // style={{ width: '90%', maxWidth: '500px', marginTop: '1.75rem' }}
    onHide={onHide}
  >
    <p>
      La notifica fa riferimento ai dati di una <b>società diversa</b> da quella impostata. Cliccando su conferma verrà
      effettuato il cambio di società. Sei sicuro di voler continuare?
    </p>
  </Dialog>
);

const ConfirmSwitchCompanyProvider = ({ children }) => {
  const [data, setData] = useState({ visible: false });

  const { error } = useFeedback();

  const hideDialog = () => {
    setData({ visible: false });
  };

  const handleOnDialogOpen = (companyId, onSwitchComplete) => {
    setData({ visible: true, companyId, onSwitchComplete });
  };

  const handleOnSwitchCompanyConfirm = () => {
    switchCompany(data.companyId)
      .then(() => {
        data.onSwitchComplete(data.companyId);
        hideDialog();
      })
      .catch((err) => error(err.message));
  };

  const handleOnDialogHide = () => {
    hideDialog();
  };

  const value = {
    openDialog: handleOnDialogOpen,
  };

  return (
    <ConfirmSwitchCompanyContext.Provider value={value}>
      <ConfirmSwitchCompanyDialog
        visible={data.visible}
        onConfirm={handleOnSwitchCompanyConfirm}
        onHide={handleOnDialogHide}
      />
      {children}
    </ConfirmSwitchCompanyContext.Provider>
  );
};

export default ConfirmSwitchCompanyProvider;
