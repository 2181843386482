import { useEffect, useState } from 'react';
import { fetchPurchaseOrderStatuses } from 'services/api/purchase-orders/purchase-order-statuses';

const makePurchaseOrderStatusOption = (status) => ({
  id: status.statusId,
  description: status.description,
});

const usePurchaseOrderStatusesOptions = () => {
  const [orderStatusesOptions, setOrderStatusesOptions] = useState([]);

  useEffect(() => {
    const cancellationTokenSource = new AbortController();
    fetchPurchaseOrderStatuses({ cancellationToken: cancellationTokenSource.signal })
      .then(({ status: statuses }) => {
        setOrderStatusesOptions(statuses.map(makePurchaseOrderStatusOption));
      })
      .catch(() => {
        // do nothing for now
      });

    return () => cancellationTokenSource.abort();
  }, []);

  return { orderStatusesOptions };
};

export default usePurchaseOrderStatusesOptions;
