import { InputNumber } from 'primereact/inputnumber';

const CustomInputNumber = ({
  suffix = undefined,
  minFractionDigits = 2,
  maxFractionDigits = 2,
  disabled = undefined,
  value = undefined,
  onChange = undefined,
  name = undefined,
  id = name,
  required = undefined,
  min = undefined,
  max = undefined,
  size = undefined,
}) => (
  <InputNumber
    inputId={id}
    name={name}
    locale="en-US"
    inputStyle={{ padding: '.375rem .75rem', width: '100%', opacity: 0.9 }}
    inputClassName={'form-control text-right' + (size ? ` form-control-${size} p-inputtext-${size}` : '')}
    style={{ width: '100%' }}
    suffix={suffix}
    minFractionDigits={minFractionDigits}
    maxFractionDigits={maxFractionDigits}
    value={value}
    disabled={disabled}
    onChange={onChange}
    required={required}
    min={min}
    max={max}
  />
);

export default CustomInputNumber;
