import { FormGroup, Label, Input } from 'reactstrap';

const FormInput = (props) => {
  const { type, label, id, error, children, ...others } = props;
  return (
    <FormGroup>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input type={type} id={id} {...others}>
        {children}
      </Input>
      {error && <p className="text-danger mt-1">{error}</p>}
    </FormGroup>
  );
};

export default FormInput;
