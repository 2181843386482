import { useCallback, useEffect, useState } from "react";
import useParseCustomResponse from "./useParseCustomResponse";
import {
  fetchSalesOrderStatus,
  fetchSalesOrdersExport,
} from "services/api/sales-orders-export/sales-orders-export";

// crea le options per input type select status
const createStatusOptions = (statuses) => {
  return statuses.map((data) => ({
    id: data.statusId,
    description: data.description,
  }));
};

const mapToIndexData = (data) => {
  return {
    commissionId: data.commission.id,
    commissionDescription: data.commission.description,
    salesOrderId: data.salesOrder.id,
    salesOrderDescription: data.salesOrder.description,
    businessName: data.customer.businessName,
    taxCode: data.customer.taxCode,
    vatNumber: data.customer.vatNumber,
    orderDate: data.orderDate,
    orderAmount: data.amount,
    statusId: data.status.id, // i due campi di stato servono per i filtri del DataTable 
    status: data.status,
  };
};

const useFetchExportSalesOrders = (companyId, initialPageSize) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const [responseStatuses, setResponseStatuses] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const parseCustomResponse = useParseCustomResponse();

  const refreshSalesOrders = async (request) => {
    setLoading(true);
      fetchSalesOrdersExport(request)
        .then((response) => {
          const parsedResponse = parseCustomResponse(response);
          if (parsedResponse.success) {
            const resp = {
              ...response,
              exportSalesOrders: parsedResponse.data.salesOrders.map(
                (salesOrder) => mapToIndexData(salesOrder)
              ),
              totRecords: parsedResponse.data.totRecords,
            };
            setResponse(resp);
          } else {
            setErrorMessage("Errore durante il recupero dei dati");
          }
        })
        .finally(() => setLoading(false));
  };

  const fetchData = useCallback(async () => {
    const [responseExportSalesOrdersRaw, responseStatusRaw] = await Promise.all(
      [
        fetchSalesOrdersExport({ companyId, currentPage: 0, numRecords: initialPageSize }),
        fetchSalesOrderStatus(),
      ]
    );

    return {
      responseExportSalesOrders: parseCustomResponse(
        responseExportSalesOrdersRaw
      ),
      responseStatus: parseCustomResponse(responseStatusRaw),
    };
  }, [companyId, initialPageSize, parseCustomResponse]);

  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(({ responseExportSalesOrders, responseStatus }) => {

        if (!responseStatus.success || !responseExportSalesOrders.success) {
          setErrorMessage("Errore durante il recupero dei dati");
        } else {
          const statuses = {
            ...responseStatus,
            data: createStatusOptions(responseStatus.data.status),
          };

          setResponse({
            exportSalesOrders: responseExportSalesOrders.data.salesOrders.map(
              (salesOrder) => mapToIndexData(salesOrder)
            ),
            totRecords: responseExportSalesOrders.data.totRecords,
          });
  
          setResponseStatuses(statuses.data)
        }
      })
      .finally(() => setLoading(false));
  }, [fetchData]);

  return { loading, response, responseStatuses, errorMessage, refreshSalesOrders };
};

export default useFetchExportSalesOrders;
