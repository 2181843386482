const fetchDeliveryPlaces = async () => {
  try {
    const response = await fetch(`/api/delivery-places`);
    return {
      ok: response.ok,
      status: response.status,
      data: await response.json(),
    };
  } catch {
    return { ok: false, message: 'Si è verificato un errore' };
  }
};

export { fetchDeliveryPlaces };
