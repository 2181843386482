const ROLE_PERMISSIONS = Object.freeze({
  CREATE_COMMISSION: 1,
  MANAGE_COMMISSIONS: 2,
  MANAGE_PURCHASE_INVOICES: 3,
  MANAGE_SALES_INVOICES: 4,
  CONSULT_PURCHASE_INVOICES: 5,
  CONSULT_SALES_INVOICES: 6,
  EXPORT_INVOICEABLE_SALES_ORDERS: 7,
  CONSULT_PURCHASE_ORDERS: 8,
  CONSULT_SALES_ORDERS: 9,
  DDT_MANAGEMENT: 10,

  VIEW_USERS: 11,

  MANAGE_CONFIGURATION: 20,

  VIEW_BI: 30,
  APPROVE_TIMESHEET: 53,
  BLOCK_TIMESHEET: 54
});

export default ROLE_PERMISSIONS;