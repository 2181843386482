import FormInput from 'components/forms/FormInput';
import CustomInput from 'components/shared/forms/CustomInput';
import { ACCEPTED_DOCUMENT_TYPES } from 'config';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

const STYLES = {
    saveButton: {
        marginTop: '22px'
    },
    buttons:{
        marginLeft: '5px'
    }
};
const CarDocument = ({ onSubmit, onDelete, isCarUploaded, data, commissionId, documentType, inline, onShowModal, isFileRequired=true }) => {
    const [canSubmit, setCanSubmit] = useState(!isFileRequired);
    const [fileName, setFileName] = useState(data?.name);

    const colSpace = inline ? 12 : 2;
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        onSubmit?.(formData, e.target.getObjectData(), documentType);
    }

    const showModalHandler = () => {
      onShowModal?.(data.insuranceId);
    }

    const handleDelete = (e) => {
        e.preventDefault();
        setCanSubmit(false);
        setFileName();
        onDelete?.(data.insuranceId);
    }

    const handleUploadFile = (e) => {
        console.log(e.target.files[0])
        if (e.target.files[0]){
            setCanSubmit(true)
            setFileName(e.target.files[0].name)
        }
        else {
            setCanSubmit(false)
            setFileName()
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                {!inline && (<Col lg={colSpace}>
                    <br />
                    <span>CAR</span>
                </Col>)}
                <Col lg={colSpace}>
                    <FormInput
                        type="text"
                        label="Numero polizza"
                        name="policyNumber"
                        defaultValue={data?.policyNumber}
                        disabled={isCarUploaded}
                    ></FormInput>
                </Col>
                <Col lg={colSpace}>
                    <FormInput
                        type="date"
                        label="Data scadenza"
                        name="endDate"
                        min="2000-01-01"
                        max="9999-12-31"
                        defaultValue={data?.endDate}
                        disabled={isCarUploaded}
                    ></FormInput>
                </Col>
                {isCarUploaded ?
                    <Col lg={colSpace}>
                        <FormInput
                            type="text"
                            label="File"
                            name="name"
                            defaultValue={fileName ? fileName : data.name}
                            disabled={isCarUploaded}
                        ></FormInput>
                    </Col> :
                    <Col lg={colSpace}>
                        <CustomInput
                            type="file"
                            accept={ACCEPTED_DOCUMENT_TYPES}
                            label="Allegato"
                            name="file"
                            onChange={handleUploadFile}
                            disabled={isCarUploaded}
                            required={isFileRequired}
                        ></CustomInput>
                    </Col>
                }
                {isCarUploaded ?
                    <Col lg={colSpace} style={STYLES.saveButton}>
                        <Button style={STYLES.buttons} color="primary" value={document.insuranceId} onClick={showModalHandler}><i className="fa fa-pen" /></Button>
                        <Button style={STYLES.buttons} color="danger" onClick={handleDelete}><i className="fa fa-trash" /></Button>
                        <a style={STYLES.buttons} target="_blank" rel='noreferrer' href={`/api/commissions/${commissionId}/documents/insurance/download/${data?.insuranceId}`}>Visualizza</a>
                    </Col> :
                    <Col lg={colSpace} style={STYLES.saveButton}>
                        <Button disabled={!canSubmit} color="primary"><i className="fa fa-save" /></Button>
                    </Col>
                }
            </Row>
        </Form>
    );
};

export default CarDocument;