import UnsavedChangesAwareNavLink from 'components/shared/navigation/UnsavedChangesAwareNavLink';
import { Nav, NavItem } from 'reactstrap';
import { routeFactory } from 'routes';

const DEFAULT_TABS_VISIBILITY = {
  header: true,
  body: true,
  attachments: true,
  paymentCertificates: true,
  invoices: true,
};

const salesOrdersRouteFactory = routeFactory.commissionManagement.salesOrders;

const createHeaderLink = (commissionId, salesOrderId) => salesOrdersRouteFactory.detail(commissionId, salesOrderId);

const createBodyLink = (commissionId, salesOrderId) => salesOrdersRouteFactory.body(commissionId, salesOrderId);

const createAttachmentsLink = (commissionId, salesOrderId) =>
  salesOrdersRouteFactory.attachments(commissionId, salesOrderId);

const createPaymentCertificatesLink = (commissionId, salesOrderId) =>
  salesOrdersRouteFactory.paymentCertificates.index(commissionId, salesOrderId);

const createInvoicesLink = (commissionId, salesOrderId) => salesOrdersRouteFactory.invoices(commissionId, salesOrderId);

const NavLink = ({ title, to, visible }) =>
  visible && (
    <NavItem>
      <UnsavedChangesAwareNavLink to={to}>{title}</UnsavedChangesAwareNavLink>
    </NavItem>
  );

const SalesOrderManagementNavbar = ({ commissionId, salesOrderId, tabsVisibilityOverrides = {} }) => {
  const tabsVisibility = { ...DEFAULT_TABS_VISIBILITY, ...tabsVisibilityOverrides };
  return (
    <Nav tabs>
      <NavLink title="Generali" to={createHeaderLink(commissionId, salesOrderId)} visible={tabsVisibility.header} />
      <NavLink title="Corpo" to={createBodyLink(commissionId, salesOrderId)} visible={tabsVisibility.body} />
      <NavLink
        title="Allegati"
        to={createAttachmentsLink(commissionId, salesOrderId)}
        visible={tabsVisibility.attachments}
      />
      <NavLink
        title="Certificati pagamento"
        to={createPaymentCertificatesLink(commissionId, salesOrderId)}
        visible={tabsVisibility.paymentCertificates}
      />
      <NavLink title="Fatture" to={createInvoicesLink(commissionId, salesOrderId)} visible={tabsVisibility.invoices} />
    </Nav>
  );
};

export default SalesOrderManagementNavbar;
