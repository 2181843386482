const MONTH_YEAR_FORMAT = new Intl.DateTimeFormat('it-IT', { month: 'short', year: 'numeric' });

const displayDate = (date) => {
  return date.toLocaleDateString('it-IT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

const displayDateTime = (date) => {
  return date.toLocaleDateString('it-IT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

const displayDateTimeHourMinute = (date) => {
  return date.toLocaleDateString('it-IT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const displayMonthYear = (month, year) => {
  const monthYearAsDate = new Date(`${year}-${month}-01`);
  return MONTH_YEAR_FORMAT.format(monthYearAsDate).toUpperCase();
};

const displayDateFromString = (date) => {
  //eg: 15/01/2024 dd/MM/yyyy
  const parts = date.split('/');
  return `${parts[2]}-${parts[1]}-${parts[0]}`
}

export { displayDate, displayDateTime, displayDateTimeHourMinute, displayMonthYear, displayDateFromString };
