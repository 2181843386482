import Loader from 'components/layout/Loader';
import PurchaseOrdersDataTable from '../PurchaseOrdersDataTable/PurchaseOrdersDataTable';
import {
  approvePurchaseOrder,
  exportPurchaseOrderAsPdf,
  requestPurchaseOrderApproval,
  requestPurchaseOrderChanges,
} from 'services/api/purchase-orders';
import { useEffect, useState } from 'react';
import { usePurchaseOrderStatusesOptions } from 'hooks/options';
import { useCurrenciesOptions } from 'hooks/options/useCurrenciesOptions';
import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import useAlerts from 'hooks/useAlerts';
import { useDataTable } from 'modules/DataTable';
import useDataTableOverrides from 'hooks/tables/useDataTableOverrides';
import { fetchPurchaseOrderList } from 'services/api/purchase-orders/purchase-orders';
import RequestChangeModal from '../RequestChangeModal';
import {
  mapApproveResponseToListItem,
  mapPurchaseOrdersToDataTableItems,
  mapRequestApprovalResponseToListItem,
  mapRequestChangeResponseToListItem,
} from './mappers';

const UncontrolledPurchaseOrdersDataTable = ({
  tableIdentifier,
  pageSizes = [],
  initialFilters,
  initialPageSize,
  stateRef,
}) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState();
  const [ordersCount, setOrdersCount] = useState();

  const { orderStatusesOptions } = usePurchaseOrderStatusesOptions();
  const { currenciesOptions } = useCurrenciesOptions();

  const [submitting, setSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [requestChangesOrderId, setRequestChangesOrderId] = useState();

  const { applicationRoleId } = useAuth();
  const { success, error } = useFeedback();
  const { reloadAlerts } = useAlerts();

  const { filters, sort, page, pageSize, dataTableProps } = useDataTable({
    initialFilters: initialFilters,
    initialSort: { column: 'creationDate', direction: 'DESC' },
    initialPageSize: initialPageSize,
    allowUnsort: false,
  });

  const { overrides } = useDataTableOverrides(tableIdentifier, applicationRoleId);

  useEffect(() => {
    setLoading(true);

    if (stateRef) {
      stateRef.current = { filters, sort, page, pageSize };
    }

    const cancellationTokenSource = new AbortController();
    fetchPurchaseOrderList({
      filters,
      sort,
      skip: pageSize && (page - 1) * pageSize,
      take: pageSize,
      cancellationToken: cancellationTokenSource.signal,
    })
      .then(({ items, count }) => {
        setOrders(mapPurchaseOrdersToDataTableItems(items));
        setOrdersCount(count);
      })
      .catch((err) => {
        if (!cancellationTokenSource.signal.aborted) {
          error(err.message);
        }
      })
      .finally(() => {
        if (!cancellationTokenSource.signal.aborted) {
          setLoading(false);
        }
      });
    return () => cancellationTokenSource.abort();
  }, [error, filters, sort, page, pageSize, stateRef]);

  const updateOrder = (purchaseOrderId, order) => {
    setOrders((orders) => orders.map((o) => (o.purchaseOrderId === purchaseOrderId ? { ...o, ...order } : o)));
  };

  const handleRequestChangesModalShow = (orderId) => {
    setRequestChangesOrderId(orderId);
    setIsModalVisible(true);
  };

  const handleRequestChanges = async (orderId, request) => {
    setIsModalVisible(false);
    setSubmitting(true);
    const requestChangesResponse = await requestPurchaseOrderChanges(orderId, request);
    if (requestChangesResponse.ok) {
      updateOrder(orderId, mapRequestChangeResponseToListItem(requestChangesResponse.data));
      success("Le modifiche sono state richieste per l'ordine selezionato.");
      reloadAlerts();
    } else {
      error(requestChangesResponse.message);
    }
    setSubmitting(false);
  };

  const handleRequestApproval = async (orderId) => {
    setSubmitting(true);
    const requestApprovalResponse = await requestPurchaseOrderApproval(orderId);
    if (requestApprovalResponse.ok) {
      updateOrder(orderId, mapRequestApprovalResponseToListItem(requestApprovalResponse.data));
      success('Abbiamo preso in carico la tua richiesta di approvazione.');
      reloadAlerts();
    } else {
      error(requestApprovalResponse.message);
    }
    setSubmitting(false);
  };

  const handleApprove = async (orderId) => {
    setSubmitting(true);
    const approveResponse = await approvePurchaseOrder(orderId);
    if (approveResponse.ok) {
      updateOrder(orderId, mapApproveResponseToListItem(approveResponse.data));
      success("L'ordine è stato approvato con successo.");
      reloadAlerts();
    } else {
      error(approveResponse.message);
    }
    setSubmitting(false);
  };

  const handleExportAsPdf = (purchaseOrderId, exportLanguageId) => {
    setSubmitting(true);
    exportPurchaseOrderAsPdf(purchaseOrderId, exportLanguageId)
      .catch((err) => error(err.message))
      .finally(() => setSubmitting(false));
  };

  const dataTableContext = {
    handleRequestChangesModalShow,
    handleApprove,
    handleRequestApproval,
    handleExportAsPdf,
  };

  return (
    <>
      {(loading || submitting) && <Loader />}
      <RequestChangeModal
        visible={isModalVisible}
        purchaseOrderId={requestChangesOrderId}
        onHide={() => setIsModalVisible(false)}
        onSubmit={handleRequestChanges}
      />
      {orders && overrides && (
        <PurchaseOrdersDataTable
          items={orders}
          itemsCount={ordersCount}
          context={dataTableContext}
          currenciesOptions={currenciesOptions}
          orderStatusesOptions={orderStatusesOptions}
          overrides={overrides}
          pageSizes={pageSizes}
          {...dataTableProps}
        />
      )}
    </>
  );
};

export default UncontrolledPurchaseOrdersDataTable;
