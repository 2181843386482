import { fetchJson2 } from 'helpers/api';

const { fetchResource } = require('services/api');

/**
 * @deprecated use fetchCurrenciesV2
 */
const fetchCurrencies = async () => {
  return await fetchResource('/mocks/currencies/index.json');
};

/**
 *
 * @param {{ cancellationToken: AbortSignal }} params
 * @returns {Promise<[{ id: string, name: string }]>}
 */
const fetchCurrenciesV2 = ({ cancellationToken }) => {
  return fetchJson2('/api/currencies', cancellationToken);
};

export { fetchCurrencies, fetchCurrenciesV2 };
