import { fetchJson2 } from 'helpers/api';

const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

/** @deprecated use fetchTerritoriesV2 */
const fetchTerritories = async () => {
  const response = await fetch(`/api/territory`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const fetchTerritoriesV2 = () => fetchJson2('/api/territory');

export { fetchTerritories, fetchTerritoriesV2 };
