import useFeedback from 'hooks/useFeedback';
import { useCallback, useEffect, useState } from 'react';
import { fetchCommissionStatus } from 'services/api/commissionStatus';
import { fetchCountries } from 'services/api/country';
import { fetchPaymentTermsV2 } from 'services/api/paymentTerms';
import { fetchTerritories } from 'services/api/territory';
import useParseCustomResponse from './useParseCustomResponse';


const useFetchCommissionFormOptions = () => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [countryRawResponse, territoryRawResponse, commissionStatusRawResponse, paymentTermsRawResponse] =
        await Promise.all([fetchCountries(), fetchTerritories(), fetchCommissionStatus(), fetchPaymentTermsV2()]);

       
      setResponse({
        countryResponse: parseCustomResponse(countryRawResponse),
        territoryResponse: parseCustomResponse(territoryRawResponse),
        commissionStatusResponse: parseCustomResponse(commissionStatusRawResponse),
        paymentTermsResponse: parseCustomResponse(paymentTermsRawResponse),
      });

      setLoading(false);
    } catch (err) {
      error('Errore durante il download dei dati: ', err);
      setLoading(false); // Handle the error state appropriately.
    }
  }, [error, parseCustomResponse]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { loading, response };
};

export default useFetchCommissionFormOptions;
