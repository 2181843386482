import SalesOrderDynamicTableRows from 'components/commission-management/sales-order/SalesOrderDynamicTableRows';
import { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Row, Col, Card, Form, Button } from 'reactstrap';
import { fetchUnitsOfMeasure } from 'services/api/units-of-measure';
import { formatNumber } from 'helpers/formatters';
import { fetchSalesOrderBodyWithId } from 'services/api/sales-order';
import Loader from 'components/layout/Loader';
import { updateSalesOrderLines } from 'services/api/sales-order';
import { useParams } from 'react-router';
import useFeedback from 'hooks/useFeedback';
import AuthContext from 'contexts/AuthContext';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import { SALES_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { roundNumber } from 'helpers/numbers';
import useInvalidateSalesOrder from './hooks/useInvalidateSalesOrder';
import sanitize from 'sanitize-html';
import useAuth from 'hooks/useAuth';
import useUnsavedChanges from 'hooks/useUnsavedChanges';

/* ----------- grid model ----------- */

function bindOrderLine(data) {
  return {
    lineId: data.lineId,
    description: data.description,
    unitOfMeasureId: data.unitOfMeasureId,
    quantity: data.quantity,
    unitAmount: data.unitAmount,
  };
}

function createOrderLine() {
  return {
    lineId: 1,
    description: '',
    unitOfMeasureId: 1,
    quantity: '',
    unitAmount: '',
  };
}

const useParseCustomResponse = () => {
  const { logout } = useContext(AuthContext);
  const { error } = useFeedback();

  const parseCustomResponse = useCallback(
    (response) => {
      if (response.success) {
        return { success: true, data: response.data };
      }

      if (response.authenticated === false) {
        logout();
      }

      if (response.authorized === false) {
        error('Non sei autorizzato a vedere questa pagina');
        return { success: false, message: 'Non sei autorizzato a vedere questa pagina' };
      }

      error(response.message);
      return { success: false, message: response.message };
    },
    [error, logout]
  );

  return parseCustomResponse;
};

const sanitizeOptions = {
  allowedTags: ['b', 'strong', 'p', 'div'],
  allowedAttributes: [],
  // nonTextTags: [ 'style', 'script', 'textarea', 'noscript' ]
};

/* ----------- */

const SalesOrderBodyPage = () => {
  const [pristine, setPristine] = useState(true);
  const [salesOrder, setSalesOrder] = useState();
  const [lines, setLines] = useState([]);
  const [currencyData, setCurrencyData] = useState({ currency: '', valCurrency: 0, datCurrency: '' });
  const [unitOfMeasure, setUnitOfMeasure] = useState([{ id: 0, name: '' }]);
  const [loading, setLoading] = useState(true);
  const [descriptionDialogRowIndex, setDescriptionDialogRowIndex] = useState(-1);
  const { salesOrderId } = useParams();
  const { success, error } = useFeedback();
  const { userId } = useAuth();
  const parseCustomResponse = useParseCustomResponse();
  const { hasPermission } = useCommissionPermissions();
  const invalidateSalesOrder = useInvalidateSalesOrder();
  const { notify: beginTrackUnsavedChanges, clear: endTrackUnsavedChanges } = useUnsavedChanges();

  /* ----------- Calculate TotalAmount ----------- */
  const totalOrderAmount = roundNumber(
    lines.reduce((total, orderline) => total + orderline.quantity * orderline.unitAmount, 0),
    2
  );

  const canEdit =
    (salesOrder?.editable ?? false) &&
    hasPermission(SALES_ORDER_PERMISSIONS.CREATE_EDIT) &&
    salesOrder?.createdBy === userId;

  const beginTrackUnsavedChangesIfRequired = () => {
    if (pristine) {
      beginTrackUnsavedChanges();
      setPristine(false);
    }
  };

  /* ----------- grid commands ----------- */
  const handleAddLine = () => {
    beginTrackUnsavedChangesIfRequired();
    setLines((prev) => [...prev, createOrderLine()]);
  };

  const handleDeleteLine = (index) => {
    beginTrackUnsavedChangesIfRequired();
    const rows = [...lines];
    rows.splice(index, 1);
    setLines(rows);
  };

  const handleLineValueChange = (index, evnt) => {
    const event = {
      name: '',
      value: '',
    };

    // handler input number event (unitAmount, quantity)
    if (evnt.originalEvent !== undefined) {
      event.name = evnt.originalEvent.target.name;
      event.value = evnt.value;
    }
    // handle other inputs event
    else {
      event.name = evnt.target.name;
      event.value = evnt.target.value;
    }

    changeLinePropertyValue(index, event.name, event.value);
  };
  /* ----------- initialize data ----------- */

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitData = lines.map((row) => ({
      description: row.description,
      unitOfMeasureId: parseInt(row.unitOfMeasureId),
      quantity: row.quantity,
      unitAmount: row.unitAmount,
    }));

    updateSalesOrderLines(salesOrderId, submitData).then((response) => {
      const parsedResponse = parseCustomResponse(response);
      if (parsedResponse.success) {
        success("Corpo dell'ordine aggiornato");
        invalidateSalesOrder();
        endTrackUnsavedChanges();
        setPristine(true);
      }
    });
  };

  const changeLinePropertyValue = (rowIndex, fieldName, fieldValue) => {
    // console.debug(`lines[${rowIndex}].${fieldName} = ${fieldValue}`);
    setLines((prev) => prev.map((ln, index) => (index !== rowIndex ? ln : { ...ln, [fieldName]: fieldValue })));
    beginTrackUnsavedChangesIfRequired();
  };

  const handleOnChangeDescriptionClick = (rowIndex) => {
    setDescriptionDialogRowIndex(rowIndex);
  };

  /* ----------- fetch data ----------- */
  useEffect(() => {
    fetchUnitsOfMeasure().then((response) => {
      setUnitOfMeasure(response);
    });
    fetchSalesOrderBodyWithId(salesOrderId)
      .then((salesOrder) => {
        setSalesOrder(salesOrder);
        setLines(salesOrder.lines.map(bindOrderLine));
        setCurrencyData(salesOrder.currency);
      })
      .catch((err) => error(err.message))
      .finally(() => setLoading(false));
  }, [error, salesOrderId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <DescriptionDialog
            editable={canEdit}
            rowIndex={descriptionDialogRowIndex}
            descriptionInitialValue={lines[descriptionDialogRowIndex]?.description ?? ''}
            onConfirmDescription={(rowIndex, descriptionValue) => {
              setDescriptionDialogRowIndex(-1);
              changeLinePropertyValue(rowIndex, 'description', descriptionValue);
            }}
            onHide={() => setDescriptionDialogRowIndex(-1)}
          />
          <Form onSubmit={handleSubmit}>
            <SalesOrderDynamicTableRows
              onAddRow={handleAddLine}
              rowsData={lines}
              udM={unitOfMeasure}
              handleChange={handleLineValueChange}
              deleteRow={handleDeleteLine}
              canEdit={canEdit}
              handleOnChangeDescriptionClick={handleOnChangeDescriptionClick}
            />
            <Card className="rounded-0 p-2 mt-0">
              <Row className="">
                <Col className="text-right col-lg-10 mt-2">Importo totale ordine (€)</Col>
                <Col>
                  <input type="text" disabled value={formatNumber(totalOrderAmount)} className="form-control" />
                </Col>
              </Row>
            </Card>
            {currencyData.id !== 'EUR' ? (
              <Card className="rounded-0 p-2 mt-n1">
                <Row className="">
                  <Col className="text-right col-lg-10 mt-2">
                    Ordine in valuta {currencyData.id}. Cambio {currencyData?.exchangeRate?.rate} al{' '}
                    {currencyData?.exchangeRate?.date}. Importo totale ordine
                  </Col>
                  <Col>
                    <input
                      type="text"
                      disabled
                      value={formatNumber(roundNumber(totalOrderAmount * currencyData?.exchangeRate?.rate, 2))}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </Card>
            ) : (
              <></>
            )}
            <br></br>
            {canEdit && (
              <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                <button type="submit" className="btn btn-primary" disabled={pristine}>
                  Salva
                </button>
              </div>
            )}
          </Form>
        </>
      )}
    </>
  );
};

const DescriptionDialog = ({
  rowIndex,
  descriptionInitialValue = '',
  editable = false,
  onHide = () => {},
  onConfirmDescription = (rowIndex, descriptionValue) => {},
}) => {
  const [descriptionValue, setDescriptionValue] = useState(descriptionInitialValue || '');

  useEffect(() => {
    setDescriptionValue(descriptionInitialValue || '');
  }, [rowIndex, descriptionInitialValue]);

  return (
    <Dialog
      header={`Descrizione della riga n° ${rowIndex + 1}`}
      visible={rowIndex > -1}
      style={{ width: '80vw', height: '80vh' }}
      onHide={onHide}
      footer={
        editable && (
          <div>
            <Button type="button" onClick={() => onConfirmDescription(rowIndex, descriptionValue)}>
              Conferma cambiamenti
            </Button>
          </div>
        )
      }
    >
      <EditorProvider>
        <Editor
          containerProps={{ className: 'rsw-editor full-height-editor' }}
          value={descriptionValue}
          onChange={(e) => setDescriptionValue(sanitize(e.target.value, sanitizeOptions))}
          // onPaste={(e) => handlePasteText(e)}
          // onBlur={}
          disabled={!editable}
        >
          {editable && (
            <Toolbar>
              <BtnUndo title="Annulla" />
              <BtnRedo title="Ripristina" />
              <Separator />
              <BtnBold title="Grassetto" />
              <BtnItalic title="Corsivo" />
              <BtnUnderline title="Sottolineato" />
              <Separator />
              <BtnBulletList title="Elenco non ordinato" />
              <BtnNumberedList title="Elenco ordinato" />
            </Toolbar>
          )}
        </Editor>
      </EditorProvider>
    </Dialog>
  );
};

export default SalesOrderBodyPage;
