import { Button, Col, Input, Row } from 'reactstrap';
import { formatNumber } from 'helpers/formatters';
import { InputNumber } from 'primereact/inputnumber';
import { roundNumber } from 'helpers/numbers';
import { stripHtml } from 'helpers/text';

const TableInputNumber = ({ name, value, onChange, disabled }) => (
  <InputNumber
    name={name}
    value={value}
    inputStyle={{
      padding: '.25rem .5rem',
      width: '100%',
      opacity: 0.9,
      fontSize: '.875rem',
      borderRadius: '0.2rem',
      // height: 'calc(1.5em + .5rem + 2px)',
      textAlign: 'right',
    }}
    inputClassName="form-control form-control-sm"
    className="w-100"
    onChange={onChange}
    minFractionDigits={2}
    required
    disabled={disabled}
    locale="en-US"
  />
);

function SalesOrderBodyTable({ rowsData, udM, deleteRow, handleChange, canEdit, handleOnChangeDescriptionClick }) {
  return rowsData.map((data, index) => {
    const plainTextDescription = stripHtml(data.description);
    return (
      <tr key={index}>
        <td className="align-middle">{index + 1}</td>
        <td style={{ width: '20%', verticalAlign: 'middle' }}>
          <Row>
            <Col xs={6} xl={9}>
              <div
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                title={plainTextDescription}
              >
                {plainTextDescription}
              </div>
              <Input
                type="text"
                style={{ width: '0px', height: '0px', padding: '0px', margin: '0px' }}
                value={plainTextDescription}
                required
                onChange={() => {}}
                tabIndex={-1}
              />
            </Col>
            <Col xs={6} xl={3} className="text-right">
              <Button
                type="button"
                color="link"
                style={{ fontSize: '1rem' }}
                className="p-0"
                onClick={(e) => handleOnChangeDescriptionClick(index)}
              >
                {canEdit ? (
                  <i className="fa fa-edit" title="Modifica descrizione"></i>
                ) : (
                  <i className="fa fa-eye" title="Visualizza descrizione"></i>
                )}
              </Button>
            </Col>
          </Row>
        </td>
        <td>
          <Input
            type="select"
            name="unitOfMeasureId"
            value={data.unitOfMeasureId}
            disabled={!canEdit}
            onChange={(evnt) => handleChange(index, evnt)}
            bsSize="sm"
          >
            {udM.map((val) => (
              <option key={val.id} value={val.id}>
                {val.name}
              </option>
            ))}
          </Input>
        </td>
        <td>
          <TableInputNumber
            name="quantity"
            value={data.quantity}
            onChange={(e) => handleChange(index, e)}
            disabled={!canEdit}
          />
        </td>
        <td>
          <TableInputNumber
            name="unitAmount"
            value={data.unitAmount}
            onChange={(e) => handleChange(index, e)}
            disabled={!canEdit}
          />
        </td>
        <td>
          <Input
            type="text"
            value={formatNumber(roundNumber(data.unitAmount * data.quantity, 2))}
            style={{ textAlign: 'right' }}
            disabled
            bsSize="sm"
          />
        </td>
        {canEdit && (
          <td className="align-middle text-center">
            <Button type="button" color="danger" size="sm" outline onClick={() => deleteRow(index)}>
              &times;
            </Button>
          </td>
        )}
      </tr>
    );
  });
}

export default SalesOrderBodyTable;
