import CommissionForm from 'components/forms/commission/CommissionForm';
import Loader from 'components/layout/Loader';
import { ToSendObject } from 'helpers/commissionMap';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ROUTES from 'routes';
import { createCommission } from 'services/api/commissions';
import AuthContext from 'contexts/AuthContext';
import useFeedback from 'hooks/useFeedback';
import { fetchTerritories } from 'services/api/territory';
import { fetchCountries } from 'services/api/country';
import { fetchCommissionStatus } from 'services/api/commissionStatus';
import { fetchPaymentTermsV2 } from 'services/api/paymentTerms';

const useParseCustomResponse = () => {
  const { logout } = useContext(AuthContext);
  const { error } = useFeedback();

  const parseCustomResponse = useCallback(
    (response) => {
      if (response.success) {
        return { success: true, data: response.data };
      }

      if (response.authenticated === false) {
        logout();
      }

      if (response.authorized === false) {
        error('Non sei autorizzato a vedere questa pagina');
        return { success: false, message: 'Non sei autorizzato a vedere questa pagina' };
      }

      error(response.message);
      return { success: false, message: response.message };
    },
    [error, logout]
  );

  return parseCustomResponse;
};

const useRetrieveData = () => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [countryRawResponse, territoryRawResponse, commissionStatusRawResponse, paymentTermsRawResponse] =
        await Promise.all([fetchCountries(), fetchTerritories(), fetchCommissionStatus(), fetchPaymentTermsV2()]);
      setResponse({
        countryResponse: parseCustomResponse(countryRawResponse),
        territoryResponse: parseCustomResponse(territoryRawResponse),
        commissionStatusResponse: parseCustomResponse(commissionStatusRawResponse),
        paymentTermsResponse: parseCustomResponse(paymentTermsRawResponse),
      });

      setLoading(false);
    } catch (err) {
      error('Errore durante il download dei dati: ', err);
      setLoading(false); // Handle the error state appropriately.
    }
  }, [error, parseCustomResponse]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { loading, response };
};

const NewCommissionData = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { loading, response } = useRetrieveData();
  const params = useParams();
  const parseCustomResponse = useParseCustomResponse();
  const { success, error } = useFeedback();
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const formData = e.target.getObjectData();
    const post = ToSendObject({ ...formData, ...params });

    createCommission(JSON.stringify(post))
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success('Commessa creata con successo', 'Successo');
          if (response.data.commissionId) {
            navigate(
              ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS.replace(':commissionId', response.data.commissionId)
            );
          }
        }

        setSubmitLoading(false);
      })
      .catch(() => {
        error('Si è verificato un errore', 'Errore');
      })
      .finally(() => setSubmitLoading(false));
  };

  return (
    <>
      <h1 className="h2 mb-3">Anagrafica nuova commessa</h1>
      {loading ? <Loader /> : <CommissionForm commission={{}} onSubmit={submitHandler} options={response} />}
      {submitLoading && <Loader />}
    </>
  );
};

export default NewCommissionData;
