import { fetchJson, downloadRemoteFile } from 'helpers/api';

/**
 *
 * @param {{
 *  companyId: string,
 *  paymentCertificateDateFrom: string?,
 *  paymentCertificateDateTo: string?,
 *  commissionSearchText: string?,
 *  customerSearchText: string?,
 *  exported: boolean?
 * }} filters
 */
const fetchSalesInvoicingRequests = async (filters, offset, limit) => {
  const queryString = new URLSearchParams(JSON.parse(JSON.stringify({ ...filters, offset, limit })));
  const response = await fetchJson(`/api/sales-invoicing-requests?${queryString}`);
  return response;
};

/**
 *
 * @param {{
 *  companyId: string,
 *  paymentCertificateDateFrom: string?,
 *  paymentCertificateDateTo: string?,
 *  commissionSearchText: string?,
 *  customerSearchText: string?,
 *  exported: boolean?
 * }} filters
 */
const exportSalesInvoicingRequests = (filters, markAsExported = false) => {
  const queryString = new URLSearchParams(JSON.parse(JSON.stringify({ ...filters, markAsExported })));
  return downloadRemoteFile(`/api/sales-invoicing-requests/export?${queryString}`);
};

export { fetchSalesInvoicingRequests, exportSalesInvoicingRequests };
