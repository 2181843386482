import Loader from 'components/layout/Loader';
import GUARANTEE_WITHHOLDING_TYPES from 'constants/guarantee-withholding-types';
import { ADVANCE, TOTAL_PRODUCTION } from 'constants/payment-certificate-types';
import { formatNumber } from 'helpers/formatters';
import { roundNumber } from 'helpers/numbers';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Col, FormText, Row } from 'reactstrap';
import { routeFactory } from 'routes';
import { editSalesOrderPaymentCertificate, fetchSalesOrderPaymentCertificate } from 'services/api/sales-order';
import SalesOrderSummary from '../components/SalesOrderSummary';
import PaymentCertificatesTable from '../components/list';
import PaymentCertificateForm from '../components/form';
import PaymentCertificateStatusBadge from 'components/commission-management/payment-certificates/status-badge';
import useEnterEditMode from 'hooks/forms/useEnterEditMode';

const mapPaymentCertificateDetailToFormData = (detail) => ({
  type: detail.type,
  amount: detail.amount,
  date: detail.date,
  isRedetermination: detail.isRedetermination,
  redeterminationAmount: detail.redeterminationAmount,
  redeterminationNote: detail.redeterminationNote,
  advanceWithholdingPercentage: detail.advanceWithholdingPercentage,
  advanceWithholdingAmount: detail.advanceWithholdingAmount,
  note: detail.note,
  isRecoverTotalAdvance: !detail.advanceWithholdingPercentage && !!detail.advanceWithholdingAmount,
});

const mapInitialDataToPageModel = (initialData) => ({
  status: initialData.detail.status,
  editable: initialData.detail.editable,
  approvalRequestable: initialData.detail.approvalRequestable,
  formData: mapPaymentCertificateDetailToFormData(initialData.detail),
  order: { ...initialData.order, orderDate: new Date(Date.parse(initialData.order.orderDate)) },
  advanceWithholdingAmountRemainder: roundNumber(
    initialData.previousPaymentCertificates.reduce(
      (advanceWithholdingAmountRemainder, paymentCertificate) =>
        paymentCertificate.number !== initialData.detail.paymentCertificateId
          ? advanceWithholdingAmountRemainder + paymentCertificate.advance
          : advanceWithholdingAmountRemainder,
      0
    ),
    2
  ),
  amountRemainder: roundNumber(
    initialData.order.amount -
      initialData.previousPaymentCertificates.reduce(
        (remainder, paymentCertificate) =>
          paymentCertificate.number !== initialData.detail.paymentCertificateId
            ? remainder + paymentCertificate.amount
            : remainder,
        0
      ),
    2
  ),
  guaranteeWithholdingAmountRemainder:
    initialData.order.guaranteeWithholdingId === GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE &&
    initialData.order.guaranteeWithholdingPercentage > 0
      ? roundNumber(
          initialData.previousPaymentCertificates.reduce(
            (remainder, paymentCertificate) => remainder + paymentCertificate.guaranteeWithholdingValue,
            0
          ),
          2
        )
      : 0,
  previousPaymentCertificates: initialData.previousPaymentCertificates.map((pc) => ({
    ...pc,
    date: new Date(Date.parse(pc.date)),
  })),
});

const SalesOrderPaymentCertificateEditPage = () => {
  const [paymentCertificateInfo, setPaymentCertificateInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { commissionId, salesOrderId, ...otherParams } = useParams();
  const paymentCertificateId = parseInt(otherParams.paymentCertificateId);
  const { error, success, warning } = useFeedback();

  const navigate = useNavigate();

  useEnterEditMode();

  useEffect(() => {
    setLoading(true);
    fetchSalesOrderPaymentCertificate(salesOrderId, paymentCertificateId)
      .then((response) => {
        if (response.ok) {
          console.log(response)
          setPaymentCertificateInfo(mapInitialDataToPageModel(response.data));
        } else {
          setErrorMessage(response.message);
        }
      })
      .finally(() => setLoading(false));
  }, [paymentCertificateId, salesOrderId]);

  const handleOnFormSubmit = async (formData) => {
    // console.debug(formData);

    if (
      [ADVANCE, TOTAL_PRODUCTION].includes(formData.type) &&
      formData.amount > paymentCertificateInfo.amountRemainder
    ) {
      warning(
        `${
          formData.type === ADVANCE ? "L'anticipo" : 'Il totale produzione'
        } non può superare l'importo dell'ordine. Max. ${formatNumber(paymentCertificateInfo.amountRemainder)}€`
      );
      return;
    }

    if (
      formData.type === TOTAL_PRODUCTION &&
      formData.advanceWithholdingAmount > paymentCertificateInfo.advanceWithholdingAmountRemainder
    ) {
      warning(
        `Le ritenute di anticipo non possono superare l'anticipo. Max. ${formatNumber(
          paymentCertificateInfo.advanceWithholdingAmountRemainder
        )}€`
      );
      return;
    }
    const response = await editSalesOrderPaymentCertificate(salesOrderId, paymentCertificateId, formData);
    if (response.ok) {
      success(
        `Il certificato di pagamento n° ${paymentCertificateId} dell'ordine ${salesOrderId} è stato modificato correttamente.`
      );
      navigate(routeFactory.commissionManagement.salesOrders.paymentCertificates.index(commissionId, salesOrderId));
    } else {
      error(response.message);
    }
  };

  console.log(paymentCertificateInfo?.editable)
  return (
    <>
      {loading && <Loader />}
      {paymentCertificateInfo && (
        <>
          <Row className="mb-4">
            <Col xs={10}>
              <h3>Certificato di pagamento n° {paymentCertificateId}</h3>
            </Col>
            <Col xs={2} className="text-right">
              <NavLink
                to={routeFactory.commissionManagement.salesOrders.paymentCertificates.index(commissionId, salesOrderId)}
                style={{ fontSize: '2rem' }}
                title={`Torna all'elenco dei certificati di pagamento dell'ordine ${salesOrderId}`}
                className="text-danger"
              >
                &times;
              </NavLink>
            </Col>
          </Row>
          <SalesOrderSummary order={paymentCertificateInfo.order} />
          {paymentCertificateInfo.previousPaymentCertificates.length > 0 && (
            <div className="mb-4">
              <h4 className="mb-3">Certificati emessi</h4>
              <PaymentCertificatesTable
                guaranteeWithholdingTableHeaderText={paymentCertificateInfo.order.guaranteeWithholdingTableHeaderText}
                paymentCertificates={paymentCertificateInfo.previousPaymentCertificates}
              />
            </div>
          )}
          <div>
            <p>
              Stato:{' '}
              <PaymentCertificateStatusBadge
                statusId={paymentCertificateInfo.status.id}
                statusName={paymentCertificateInfo.status.name}
              />
            </p>
            <PaymentCertificateForm
              advanceTypeAllowed={paymentCertificateId === 1}
              advanceWithholdingAmountRemainder={paymentCertificateInfo.advanceWithholdingAmountRemainder}
              amountRemainder={paymentCertificateInfo.amountRemainder}
              guaranteeWithholdingAmountRemainder={paymentCertificateInfo.guaranteeWithholdingAmountRemainder}
              initialData={mapPaymentCertificateDetailToFormData(paymentCertificateInfo.formData)}
              readOnly={!paymentCertificateInfo.editable}
              guaranteeWithholding={{
                typeId: paymentCertificateInfo.order.guaranteeWithholdingId,
                percentage: paymentCertificateInfo.order.guaranteeWithholdingPercentage || 0,
              }}
              onSubmit={handleOnFormSubmit}
            />
          </div>
        </>
      )}
      {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
    </>
  );
};

export default SalesOrderPaymentCertificateEditPage;
