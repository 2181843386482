import PaymentCertificatesTable from 'components/commission-management/payment-certificates/list';
import { routeFactory } from 'routes';

const createDetailRoute = (commissionId, salesOrderId, paymentCertificateNumber) =>
  routeFactory.commissionManagement.salesOrders.paymentCertificates.detail(
    commissionId,
    salesOrderId,
    paymentCertificateNumber
  );

const SalesOrderPaymentCertificatesTable = ({
  commissionId,
  salesOrderId,
  paymentCertificates,
  guaranteeWithholdingTableHeaderText,
}) => (
  <PaymentCertificatesTable
    paymentCertificates={paymentCertificates}
    guaranteeWithholdingTableHeaderText={guaranteeWithholdingTableHeaderText}
    detailRouteFactory={(paymentCertificateNumber) =>
      createDetailRoute(commissionId, salesOrderId, paymentCertificateNumber)
    }
  />
);

export default SalesOrderPaymentCertificatesTable;
