import { Input, FormGroup, Label } from 'reactstrap';
import RequiredFieldMarker from 'components/shared/RequiredFieldMarker';
import { InputNumber } from 'primereact/inputnumber';

const CustomInput = ({
  name,
  id = name,
  className,
  labelClassName = 'text-dark',
  groupClassName,
  required = false,
  label,
  type = 'text',
  children,
  style,
  locale = 'en-US',
  ...otherProps
}) => {
  return (
    <FormGroup className={groupClassName}>
      <Label htmlFor={id} className={labelClassName}>
        {label}
        {required && <RequiredFieldMarker />}
      </Label>
      {type === 'select' ? (
        <Input
          type="select"
          className={className}
          name={name}
          id={id}
          required={required}
          style={style}
          {...otherProps}
        >
          {children}
        </Input>
      ) : type === 'formattednumber' ? (
        <InputNumber
          inputId={id}
          name={name}
          required={required}
          inputStyle={{ ...{ padding: '.375rem .75rem', width: '100%', opacity: .9 }, ...style }}
          locale={locale}
          inputClassName={className + ' form-control'}
          {...otherProps}
          style={{ width: '100%' }}
        />
      ) : (
        <Input
          type={type}
          className={className}
          name={name}
          id={id}
          required={required}
          style={style}
          {...otherProps}
        />
      )}
    </FormGroup>
  );
};

export default CustomInput;
