import UploadContractDocument from 'components/documents/UploadContractDocument';
import InailDocument from 'components/documents/InailDocument';
import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Table } from 'reactstrap';
import { format } from "helpers/amount";
import {
  addContract,
  addInsuranceDoc,
  clearContractsForm,
  deleteContract,
  addOtherDocs,
  clearOtherDocsForm,
  deleteOtherDocs,
  deleteFidejussioniDoc,
  addFidejussioni,
  clearFidejussioniForm,
  reduceDocuments,
  isLoading,
  setContractsInitialState,
  setInsuranceInitialState,
  setFidejussioniInitialState,
  setOtherDocsInitialState,
  deleteInsuranceDoc,
  editContract,
  updateInsuranceDoc,
  updateOtherDocs,
  updateFidejussioniDoc,
} from 'reducers/documents';
import CarDocument from 'components/documents/CarDocument';
import UploadOtherDocuments from 'components/documents/UploadOtherDocument';
import UploadFidejussioniDocument from 'components/documents/UploadFidejussioniDocument';
import Loader from 'components/layout/Loader';
import {
  deleteContractApi,
  deleteFidejussioni,
  deleteInsurance,
  deleteOther,
  fetchContractsByCommissionId,
  fetchFidejussioniByCommissionId,
  fetchInsuranceByCommissionId,
  fetchOthersByCommissionId,
  insertContract,
  insertFidejussioni,
  insertInsurance,
  insertOther,
  updateContract,
  updateFidejussioni,
  updateInsurance,
  updateOther,
} from 'services/api/documents';
import AuthContext from 'contexts/AuthContext';
import useFeedback from 'hooks/useFeedback';
import { fetchDocumentTypes } from 'services/api/documentTypes';
import { Dialog } from 'primereact/dialog';
import UploadConstructionFund from 'components/documents/UploadConstructionFund';
import UpdateInsuranceModal from 'components/documents/UpdateInsuranceModal';

const PreviewContracts = ({ commissionId, contracts, onDeleteContract, onShowModal }) => {
  return (
    <Table style={{ tableLayout: 'fixed' }} bordered>
      <thead>
        <tr>
          <th>Nome del file</th>
          <th>Nota</th>
          <th style={{ width: '60px' }}></th>
          <th style={{ width: '60px' }}></th>
          <th style={{ width: '100px' }}></th>
        </tr>
      </thead>
      <tbody>
        {contracts.map((contract) => (
          <PreviewContractsItem
            key={`contract-${contract.contractId}`}
            document={contract}
            onDelete={onDeleteContract}
            commissionId={commissionId}
            onShowModal={onShowModal}
          />
        ))}
      </tbody>
    </Table>
  );
};

const PreviewContractsItem = ({ document, onDelete, commissionId, onShowModal }) => {
  const deleteHandler = () => {
    onDelete(document.contractId);
  };

  const showModalHandler = () => {
    // console.log(document.contractId)
    onShowModal?.(document.contractId)
  }

  return (
      <tr>
        <td>{document.name}</td>
        <td>{document.note}</td>
        <td style={{ textAlign: "center" }}>
          <Button color="primary" value={document.contractId} onClick={showModalHandler} size="sm">
            <i className="fa fa-pen" />
          </Button>
        </td>
        <td style={{ textAlign: "center" }}>
          <Button color="danger" onClick={deleteHandler} size="sm">
            <i className="fa fa-trash" />
          </Button>
        </td>
        <td style={{ textAlign: "center" }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/api/commissions/${commissionId}/documents/contracts/download/${document.contractId}`}
          >
            Visualizza
          </a>
        </td>
      </tr>
  );
};

const PreviewOtherDocuments = ({ commissionId, documents, onDeleteDocument, onShowModal }) => {
  return (
    <div className="table-responsive">
      <Table bordered style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>Tipo polizza</th>
            <th>Numero polizza</th>
            <th>Data scadenza</th>
            <th>Nome del file</th>
            <th style={{ width: '60px' }}></th>
            <th style={{ width: '60px' }}></th>
            <th style={{ width: '100px' }}></th>
          </tr>
        </thead>
        <tbody>
          {documents.map((document) => (
            <PreviewOtherDocument
              key={`other-document-${document.otherId}`}
              commissionId={commissionId}
              document={document}
              onDelete={onDeleteDocument}
              onShowModal={onShowModal}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const PreviewOtherDocument = ({ document, onDelete, commissionId, onShowModal }) => {
  const deleteHandler = () => {
    onDelete(document.otherId);
  };

  const showModalHandler = () => {
    onShowModal(document.otherId)
  }

  return (
    <>
    <tr>
      <td>{document.policyType.typeDescription}</td>
      <td>{document.policyNumber}</td>
      <td>
        {document.endDate && new Date(Date.parse(document.endDate)).toLocaleDateString('it-IT', { style: 'short' })}
      </td>
      <td>{document.name}</td>
      <td style={{ textAlign: "center" }}>
          <Button color="primary" value={document.otherId} onClick={showModalHandler} size="sm">
            <i className="fa fa-pen" />
          </Button>
      </td>
      <td style={{ textAlign: "center" }}>
        <Button className="mb3"  color="danger" onClick={deleteHandler} size="sm">
          <i className="fa fa-trash" />
        </Button>
      </td>
      <td style={{ textAlign: "center" }}>
        <a
          target="_blank"
          rel="noreferrer"
          href={`/api/commissions/${commissionId}/documents/other/download/${document.otherId}`}
        >
          Visualizza
        </a>
      </td>
    </tr>
    </>
  );
};

const PreviewFidejussioniDocuments = ({ commissionId, documents, onDeleteDocument, onShowModal }) => {
  return (
    <div className="table-responsive">
      <Table bordered style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>Nome del file</th>
            <th>Importo (€)</th>
            <th>Note</th>
            <th style={{ width: '60px' }}></th>
            <th style={{ width: '60px' }}></th>
            <th style={{ width: '100px' }}></th>
          </tr>
        </thead>
        <tbody>
          {documents.map((document) => (
            <PreviewFidejussioniDocument
              key={`fidejussioni-${document.fidejussioniId}`}
              commissionId={commissionId}
              document={document}
              onDelete={onDeleteDocument}
              onShowModal={onShowModal}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const PreviewFidejussioniDocument = ({ document, onDelete, commissionId, onShowModal }) => {
  const deleteHandler = () => {
    onDelete(document.fidejussioniId);
  };

  const showModalHandler = () => {
    onShowModal?.(document.fidejussioniId)
  }

  return (
    <tr>
      <td>{document.name}</td>
      <td>{format(document.amount)}</td>
      <td>{document.note}</td>
      <td style={{ textAlign: "center" }}>
          <Button color="primary" value={document.contractId} onClick={showModalHandler} size="sm">
            <i className="fa fa-pen" />
          </Button>
      </td>
      <td style={{ textAlign: "center" }}>
        <Button className="mb3" color="danger" onClick={deleteHandler} size='sm'>
          <i className="fa fa-trash" />
        </Button>
      </td>
      <td style={{ textAlign: "center" }}>
        <a
          target="_blank"
          rel="noreferrer"
          href={`/api/commissions/${commissionId}/documents/fidejussioni/download/${document.fidejussioniId}`}
        >
          Visualizza
        </a>
      </td>
    </tr>
  );
};

const useParseCustomResponse = () => {
  const { logout } = useContext(AuthContext);
  const { error } = useFeedback();

  const parseCustomResponse = useCallback(
    (response) => {
      if (response.success) {
        return { success: true, data: response.data };
      }

      if (response.authenticated === false) {
        logout();
      }

      if (response.authorized === false) {
        error('Non sei autorizzato a vedere questa pagina');
        return { success: false, message: 'Non sei autorizzato a vedere questa pagina' };
      }

      error(response.message);
      return { success: false, message: response.message };
    },
    [error, logout]
  );

  return parseCustomResponse;
};

const useRetrieveData = (commissionId) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();

  const { error } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  const fetchData = useCallback(async () => {
    try {
      const [DocumentRawTypes, ContractsRawByCommissionId, InsuranceRaw, OthersRaw, FidejussioniRaw] =
        await Promise.all([
          fetchDocumentTypes(),
          fetchContractsByCommissionId(commissionId),
          fetchInsuranceByCommissionId(commissionId),
          fetchOthersByCommissionId(commissionId),
          fetchFidejussioniByCommissionId(commissionId),
        ]);

      return {
        DocumentRawTypes,
        ContractsRawByCommissionId,
        InsuranceRaw,
        OthersRaw,
        FidejussioniRaw,
      };
    } catch (err) {
      error('Errore durante il download dei dati: ', err);
      setLoading(false); // Handle the error state appropriately.
    }
  }, [commissionId, error]);

  useEffect(() => {
    setLoading(true);
    fetchData().then(({ DocumentRawTypes, ContractsRawByCommissionId, InsuranceRaw, OthersRaw, FidejussioniRaw }) => {
      // console.log(parseCustomResponse(InsuranceRaw))
      setResponse({
        documentTypesResponse: parseCustomResponse(DocumentRawTypes),
        constractsResponse: parseCustomResponse(ContractsRawByCommissionId),
        insuranceResponse: parseCustomResponse(InsuranceRaw),
        othersResponse: parseCustomResponse(OthersRaw),
        fidejussioniResponse: parseCustomResponse(FidejussioniRaw),
      });

      setLoading(false);
    });
  }, [fetchData, parseCustomResponse]);

  return { loading, response };
};

const INITIAL_STATE = {
  formContractId: 0,
  formAttachmentsId: 0,
  formAddendumId: 0,
  formGenericDocsId: 0,
  formOtherDocsId: 0,
  formFidejussioniId: 0,

  isLoading: false,

  uploadedAllContractsType: [],
  uploadedAllInsuranceType: [],
  uploadedAllOthersType: [],
  uploadedAllFidejussioniType: [],
};

const DOCUMENT_TYPE = {
  contract: 'Contratto',
  attachments: 'Allegato al contratto',
  addendum: 'Addendum al contratto',
  genericDoc: 'Documento generico',
  inail: 'Inail',
  constructionFund: 'Cassa Edile',
  car: 'CAR',
  otherDoc: 'Altro',
  fidejussioni: 'Fidejussioni',
};

const CommissionDocuments = () => {
  const [state, dispatch] = useReducer(reduceDocuments, INITIAL_STATE);
  const parseCustomResponse = useParseCustomResponse();
  const params = useParams();
  const { loading, response } = useRetrieveData(params.commissionId);
  const { success, error } = useFeedback();

  const [contract, setContract] = useState();
  const [isModalContractVisible, setIsModalContractVisible] = useState(false);

  const [insurance, setInsurance] = useState();
  const [isModalInsuranceVisible, setIsModalInsuranceVisible] = useState(false);

  const [other, setOther] = useState();
  const [isModalOtherVisible, setIsModalOtherVisible] = useState(false);

  const [fidejussione, setFidejussione] = useState();
  const [isModalFidejussioneVisible, setIsModalFidejussioneVisible] = useState(false);

  const showContractModal = (contractId) => {
    setContract(state.uploadedAllContractsType.find((x) => x.contractId === contractId))
    setIsModalContractVisible(true);
  }

  const showInsuranceModal = (insuranceId) => {
    console.log(insuranceId)
    setInsurance(state.uploadedAllInsuranceType.find((x) => x.insuranceId === insuranceId))
    setIsModalInsuranceVisible(true);
  }

  const showOtherModal = (otherId) => {
    setOther(state.uploadedAllOthersType.find((x) => x.otherId === otherId))
    setIsModalOtherVisible(true);
  }

  const showFidejussioneModal = (fidejussioneId) => {
    console.log(fidejussioneId)
    setFidejussione(state.uploadedAllFidejussioniType.find((x) => x.fidejussioniId === fidejussioneId))
    setIsModalFidejussioneVisible(true);
  }

  useEffect(() => {
    if (!response) {
      return;
    }
    dispatch(setContractsInitialState(response.constractsResponse.data));
    dispatch(setInsuranceInitialState(response.insuranceResponse.data));
    dispatch(setOtherDocsInitialState(response.othersResponse.data));
    dispatch(setFidejussioniInitialState(response.fidejussioniResponse.data));
  }, [response]);

  const getDocumentTypeId = (documentType) => {
    return response.documentTypesResponse.data.find(
      (x) => x.description === documentType
    ).documentTypeId
  }

  const submitContractHandler = (formData, data, documentType) => {
    dispatch(isLoading(true));
    const documentTypeId = getDocumentTypeId(documentType)
    formData.append('documentTypeId', documentTypeId);

    insertContract(params.commissionId, formData)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success('Contratto inserito', 'Successo');
          const previewContract = { contractId: parsedResponse.data.contractId, name: data.file.name, note: data.note, documentTypeId: documentTypeId};
          dispatch(addContract(previewContract));
          dispatch(clearContractsForm());
          // console.log(state.uploadedAllContractsType)
        }
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  const editContractHandler = (formData, data, documentType) => {
    setIsModalContractVisible(!isModalContractVisible);
    dispatch(isLoading(true));
    updateContract(params.commissionId, data.contractId, formData).then((response) => {
      if(response.success) {
        success('Contratto modificato con successo', 'Successo');
        console.log(data.file.name)
        console.log(contract)
        const previewContract = { contractId: data.contractId, name: data.file.name ? data.file.name : contract.name, note: data.note };
        dispatch(editContract(previewContract));
        setContract();
      } else {
        error("Non è stato possibile aggiornare il file");
      }
    }).catch((err) => error(err, "Errore"))
    .finally(() => dispatch(isLoading(false)))
  }

  const submitInsuranceHandler = (formData, data, documentType) => {
    dispatch(isLoading(true));
    const documentTypeId = getDocumentTypeId(documentType);
    formData.append('documentTypeId', documentTypeId);

    insertInsurance(params.commissionId, formData)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        console.log(parsedResponse.data.insuranceId)
        if (parsedResponse.success) {
          success('Documento inserito con successo', 'Successo');
          const insuranceData = {
            insuranceId: parsedResponse.data.insuranceId,
            protocolNumber: data.protocolNumber,
            startDate: data.startDate,
            endDate: data.endDate,
            name: data.file?.name,
            policyNumber: data.policyNumber,
            constructionFundNumber: data.constructionFundNumber,
            documentTypeId: documentTypeId
          };
          dispatch(addInsuranceDoc(insuranceData));
        }
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  const editInsuranceHandler = (formData, data) => {
    console.log(data)
    setIsModalInsuranceVisible(!isModalInsuranceVisible);

    dispatch(isLoading(true));
    updateInsurance(params.commissionId, data.insuranceId, formData).then((response) => {
      if (response.success) {
        success("Contratto modificato con successo", "Successo");
        const insuranceData = {
          insuranceId: data.insuranceId,
          protocolNumber: data.protocolNumber,
          startDate: data.startDate,
          endDate: data.endDate,
          name: data.file?.name ? data.file?.name : insurance.name,
          policyNumber: data.policyNumber,
          constructionFundNumber: data.constructionFundNumber,
          documentTypeId: data.documentTypeId,
        };
        dispatch(updateInsuranceDoc(insuranceData));
        setInsurance();
      } else {
        error("Non è stato possibile aggiornare il documento");
      }
    }).catch((err) => error(err, "Errore"))
    .finally(() => dispatch(isLoading(false)))
  }

  const submitOtherDocsHandler = (formData, data, typeDescription, documentType) => {
    dispatch(isLoading(true));
    const documentTypeId = getDocumentTypeId(documentType)
    formData.append('documentTypeId', documentTypeId);

    insertOther(params.commissionId, formData)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success('Polizza inserita', 'Successo');
          const dataOther = {
            otherId: parsedResponse.data.otherId,
            policyType: { typeDescription: typeDescription, typeId: data.policyType },
            policyNumber: data.policyNumber,
            endDate: data.endDate,
            name: data.file?.name,
            documentTypeId: documentTypeId
          };
          dispatch(addOtherDocs(dataOther));
          dispatch(clearOtherDocsForm(dataOther));
        }
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  const editOtherHandler = (formData, data, typeDescription ) => {
    setIsModalOtherVisible(!isModalOtherVisible);
    console.log(data)
    console.log(typeDescription)
   
    dispatch(isLoading(true));
    updateOther(params.commissionId, data.otherId, formData).then((response) => {
      if(response.success) {
        success('Contratto modificato con successo', 'Successo');
       const dataOther = {
          otherId: data.otherId,
          policyType: { typeDescription: typeDescription, typeId: data.policyType },
          policyNumber: data.policyNumber,
          endDate: data.endDate,
          name: data.file.name ? data.file.name : other.name,
        };
        // console.log(dataOther)
        dispatch(updateOtherDocs(dataOther));
        setOther();
      } else {
        error("Non è stato possibile aggiornare il file");
      }
    }).catch((err) => error(err, "Errore"))
    .finally(() => dispatch(isLoading(false)))
  }

  const submitFidejussioniHandler = (formData, data, documentType) => {
    dispatch(isLoading(true));
    const documentTypeId = getDocumentTypeId(documentType)
    formData.append('documentTypeId', documentTypeId);

    insertFidejussioni(params.commissionId, formData)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);

        if (parsedResponse.success) {
          success('Fidejussioni inserito', 'Successo');
          const dataFide = {
            fidejussioniId: parsedResponse.data.fidejussioniId,
            name: data.file.name,
            amount: data.amount,
            note: data.note,
            documentTypeId: documentTypeId
          };
          dispatch(addFidejussioni(dataFide));
          dispatch(clearFidejussioniForm(dataFide));
        }
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  const editFidejussioniHandler = (formData, data) => {
    console.log(data)
    setIsModalFidejussioneVisible(!isModalFidejussioneVisible);
    dispatch(isLoading(true));
    updateFidejussioni(params.commissionId, data.fidejussioniId, formData).then((response) => {
      if(response.success) {
        success('Contratto modificato con successo', 'Successo');
        const previewFidejussione = { fidejussioniId: data.fidejussioniId, name: data.file.name ? data.file.name : fidejussione.name, note: data.note, amount: data.amount };
        dispatch(updateFidejussioniDoc(previewFidejussione));
        setFidejussione();
      } else {
        error("Non è stato possibile aggiornare il file");
      }
    }).catch((err) => error(err, "Errore"))
    .finally(() => dispatch(isLoading(false)))
  }

  const deleteContractHandler = (id) => {
    dispatch(isLoading(true));
    deleteContractApi(params.commissionId, id)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        // console.log(parsedResponse);
        if (parsedResponse.success) {
          success('Contratto eliminato', 'Successo');
          dispatch(deleteContract(id));
        }
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  const deleteInsuranceHandler = (id) => {
    dispatch(isLoading(true));
    deleteInsurance(params.commissionId, id)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success('Documento eliminato con successo', 'Successo');
          dispatch(deleteInsuranceDoc(id));
        }
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  const deleteOtherDocsHandler = (id) => {
    dispatch(isLoading(true));
    deleteOther(params.commissionId, id)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success('Polizza eliminata', 'Successo');
          dispatch(deleteOtherDocs(id));
        }
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  const deleteFidejussioniHandler = (id) => {
    dispatch(isLoading(true));
    deleteFidejussioni(params.commissionId, id)
      .then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          success('Fidejussioni eliminato', 'Successo');
          dispatch(deleteFidejussioniDoc(id));
        }
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  return (
    <>
      {(state.isLoading || loading) ? <Loader /> : (
        <>
      <Dialog
        header="Modifica documento"
        visible={isModalContractVisible}
        style={{ width: "60vw" }}
        onHide={() => setIsModalContractVisible(false)}
      >
        <UploadContractDocument
          onSubmit={editContractHandler}
          formName={'Contract_'}
          inputLabelName={'Allega documento'}
          contract={contract}
          inline={true}
          isFileRequired={false}
        />
      </Dialog>
      <Dialog
        header="Modifica documento"
        visible={isModalInsuranceVisible}
        style={{ width: "60vw" }}
        onHide={() => setIsModalInsuranceVisible(false)}
      >
        <UpdateInsuranceModal
          onSubmit={editInsuranceHandler}
          insurance={insurance}
          commissionId={params.commissionId}
          inline={true}
          isFileRequired={false}
        />
      </Dialog>
      <Dialog
        header="Modifica documento"
        visible={isModalOtherVisible}
        style={{ width: "60vw" }}
        onHide={() => setIsModalOtherVisible(false)}
      >
      <UploadOtherDocuments 
        onSubmit={editOtherHandler} 
        formName={'Other_'} 
        formId={state.formOtherDocsId} 
        inline={true}
        documentType={DOCUMENT_TYPE.otherDoc}
        other={other}
        isFileRequired={false}
      />
      </Dialog>
      <Dialog
        header="Modifica documento"
        visible={isModalFidejussioneVisible}
        style={{ width: "60vw" }}
        onHide={() => setIsModalFidejussioneVisible(false)}
      >
          <UploadFidejussioniDocument
          onSubmit={editFidejussioniHandler}
          formName={'Fidejussioni_'}
          formId={state.formFidejussioniId}
          inputLabelName={'Allega File'}
          inline={true}
          fidejussione={fidejussione}
          documentType={DOCUMENT_TYPE.fidejussioni}
          isFileRequired={false}
        />
      </Dialog>
      <div className="mb-1">
        <h1 className="h2 mb-3">Contratto</h1>
        {state.uploadedAllContractsType.filter((contract) => contract.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.contract)).length > 0 && (
          <PreviewContracts
            commissionId={params.commissionId}
            contracts={state.uploadedAllContractsType.filter((contract) => contract.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.contract))}
            onDeleteContract={deleteContractHandler}
            onShowModal={showContractModal}
          />
        )}
        <UploadContractDocument
          onSubmit={submitContractHandler}
          formName={'Contract_'}
          formId={state.formContractId}
          inputLabelName={'Allega contratto'}
          inline={false}
          documentType={DOCUMENT_TYPE.contract}
        />
      </div>
      <div className="mb-1">
        <h1 className="h2 mb-3">Allegati al contratto</h1>
        {state.uploadedAllContractsType.filter((contract) => contract.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.attachments)).length > 0 && (
          <PreviewContracts
            commissionId={params.commissionId}
            contracts={state.uploadedAllContractsType.filter((x) => x.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.attachments))}
            onDeleteContract={deleteContractHandler}
            onShowModal={showContractModal}
          />
        )}
        <UploadContractDocument
          onSubmit={submitContractHandler}
          formName={'Attachments_'}
          formId={state.formAttachmentsId}
          inputLabelName={'Allega allegati al contratto'}
          inline={false}
          documentType={DOCUMENT_TYPE.attachments}
        />
      </div>
      <div className="mb-1">
        <h1 className="h2 mb-3">Addendum contratto</h1>
        {state.uploadedAllContractsType.filter((x) => x.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.addendum)).length > 0 && (
          <PreviewContracts
            commissionId={params.commissionId}
            contracts={state.uploadedAllContractsType.filter((x) => x.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.addendum))}
            onDeleteContract={deleteContractHandler}
            onShowModal={showContractModal}
          />
        )}
        <UploadContractDocument
          onSubmit={submitContractHandler}
          formName={'Addendum_'}
          formId={state.formAddendumId}
          inputLabelName={'Allega addendum contratto'}
          inline={false}
          documentType={DOCUMENT_TYPE.addendum}
        />
      </div>
      <div className="mb-1">
        <h1 className="h2 mb-3">Documenti generici</h1>
        {state.uploadedAllContractsType.filter((x) => x.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.genericDoc)).length > 0 && (
          <PreviewContracts
            commissionId={params.commissionId}
            contracts={state.uploadedAllContractsType.filter((x) => x.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.genericDoc))}
            onDeleteContract={deleteContractHandler}
            onShowModal={showContractModal}
          />
        )}
        <UploadContractDocument
          onSubmit={submitContractHandler}
          formName={'GenericDocs_'}
          formId={state.formGenericDocsId}
          inputLabelName={'Documenti generici'}
          inline={false}
          documentType={DOCUMENT_TYPE.genericDoc}
        />
      </div>
      <hr />
      <h1 className="h2 mb-3">Assicurazioni</h1>
      <InailDocument
        onSubmit={submitInsuranceHandler}
        onDelete={deleteInsuranceHandler}
        isInailUploaded={state.uploadedAllInsuranceType.find((insurance) => insurance.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.inail))}
        data={state.uploadedAllInsuranceType.find((insurance) => insurance.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.inail))}
        commissionId={params.commissionId}
        inline={false}
        documentType={DOCUMENT_TYPE.inail}
        onShowModal={showInsuranceModal}
      />
      <UploadConstructionFund
        onSubmit={submitInsuranceHandler}
        onDelete={deleteInsuranceHandler}
        isConstructionFundUploaded={state.uploadedAllInsuranceType.find((insurance) => insurance.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.constructionFund))}
        data={state.uploadedAllInsuranceType.find((insurance) => insurance.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.constructionFund))}
        documentType={DOCUMENT_TYPE.constructionFund}
        inline={false}
        onShowModal={showInsuranceModal}
      />
      <CarDocument
        onSubmit={submitInsuranceHandler}
        onDelete={deleteInsuranceHandler}
        isCarUploaded={state.uploadedAllInsuranceType.find((insurance) => insurance.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.car))}
        data={state.uploadedAllInsuranceType.find((insurance) => insurance.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.car))}
        commissionId={params.commissionId}
        documentType={DOCUMENT_TYPE.car}
        inline={false}
        onShowModal={showInsuranceModal}
      />
      <hr />
      <h1 className="h2 mb-3">Altro</h1>
      {state.uploadedAllOthersType.filter((other) => other.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.otherDoc)).length > 0 && (
        <PreviewOtherDocuments
          commissionId={params.commissionId}
          documents={state.uploadedAllOthersType.filter((other) => other.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.otherDoc))}
          onDeleteDocument={deleteOtherDocsHandler}
          onShowModal={showOtherModal}
        />
      )}
      <UploadOtherDocuments 
        onSubmit={submitOtherDocsHandler} 
        formName={'Other_'} 
        formId={state.formOtherDocsId} 
        inline={false}
        documentType={DOCUMENT_TYPE.otherDoc}
      />
      <hr />
      <h1 className="h2 mb-3">Fidejussioni</h1>
      {state.uploadedAllFidejussioniType.filter((fidejussione) => fidejussione.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.fidejussioni)).length > 0 && (
        <PreviewFidejussioniDocuments
          commissionId={params.commissionId}
          documents={state.uploadedAllFidejussioniType.filter((fidejussione) => fidejussione.documentTypeId === getDocumentTypeId(DOCUMENT_TYPE.fidejussioni))}
          onDeleteDocument={deleteFidejussioniHandler}
          onShowModal={showFidejussioneModal}
        />
      )}
      <UploadFidejussioniDocument
        onSubmit={submitFidejussioniHandler}
        formName={'Fidejussioni_'}
        formId={state.formFidejussioniId}
        inputLabelName={'Allega File'}
        inline={false}
        documentType={DOCUMENT_TYPE.fidejussioni}
      />
        </>
      )}
    </>
  );
};

export default CommissionDocuments;
