import CustomInput from 'components/shared/forms/CustomInput';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

const STYLES = {
    saveButton: {
        marginTop: '22px'
    },
    buttons:{
      marginLeft: '5px'
  }
};

const UploadConstructionFund = ({ onSubmit, onDelete, onShowModal, isConstructionFundUploaded, data, inline, documentType, canSave=true }) => {
    const [canSubmit, setCanSubmit] = useState(!canSave);
    const colSpace = inline ? 12 : 2;

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        onSubmit?.(formData, e.target.getObjectData(), documentType);
    }

    const handleDelete = (e) => {
        e.preventDefault();
        setCanSubmit(false);
        onDelete?.(data.insuranceId);
    }

    const showModalHandler = () => {
      onShowModal?.(data.insuranceId)
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
         {!inline && (<Col lg={colSpace}>
            <br />
            <span>Cassa Edile</span>
          </Col>)}
          <Col lg={colSpace}>
            <CustomInput
              type="date"
              label="Data scadenza"
              name="endDate"
              min="2000-01-01"
              max="9999-12-31"
              defaultValue={data?.endDate}
              disabled={isConstructionFundUploaded}
              onChange={(e) => e.target.value ? setCanSubmit(true) : setCanSubmit(false)}
              required={canSave}
            ></CustomInput>
          </Col>
          <Col lg={colSpace}>
            <CustomInput
              maxLength={16}
              title="Il Nr. cassa edile è un codice alfanumerico di 16 caratteri"
              type="text"
              label="Nr cassa edile"
              name="constructionFundNumber"
              defaultValue={data?.constructionFundNumber}
              disabled={isConstructionFundUploaded}
            ></CustomInput>
          </Col>
          {isConstructionFundUploaded ? (
            <Col lg={colSpace} style={STYLES.saveButton}>
              <Button color="primary" style={STYLES.buttons} value={data.insuranceId} onClick={showModalHandler}><i className="fa fa-pen" /></Button>
              <Button color="danger" style={STYLES.buttons} onClick={handleDelete}>
                <i className="fa fa-trash" />
              </Button>
            </Col>
          ) : (
            <Col lg={colSpace} style={STYLES.saveButton}>
              <Button disabled={!canSubmit} color="primary">
                <i className="fa fa-save" />
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
};

export default UploadConstructionFund;