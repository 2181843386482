import { displayDate, displayDateTimeHourMinute } from 'helpers/date';
import { DataTableBuilder, DataTableButton, DataTableLink, InfoColumnHeader } from 'modules/DataTable';
import { ActionDDT } from 'pages/ddt-management/constants';
import { NavLink } from 'react-router-dom';
import { routeFactory } from 'routes';

const INVOICE_ICON_NAME = 'file-alt';

const createCommissionHref = (commissionId) => {
  return routeFactory.commissionManagement.commissions.detail(commissionId);
};

const createPurchaseOrderHref = (commissionId, purchaseOrderId) => {
  return routeFactory.commissionManagement.purchaseOrders.detail(commissionId, purchaseOrderId);
};

const CommissionCellTemplate = ({ commissionId }) => {
  return (
    commissionId && (
      <NavLink to={createCommissionHref(commissionId)} target="_blank">
        {commissionId}
      </NavLink>
    )
  );
};

const PurchaseOrderCellTemplate = ({ commissionId, purchaseOrderId }) => {
  return (
    purchaseOrderId && (
      <NavLink to={createPurchaseOrderHref(commissionId, purchaseOrderId)} target="_blank">
        {purchaseOrderId}
      </NavLink>
    )
  );
};

const ViewDdtAttachmentCellTemplate = ({ attachmentId, attachmentIdDisplayName }) => (
  <DataTableLink
    to={`/api/supplier-ddts/${attachmentId}/download`}
    iconName="paperclip"
    title={`Visualizza allegato ${attachmentIdDisplayName}`}
    target="_blank"
    rel="noreferrer"
  />
);

const LinkedDocumentListCellTemplate = ({
  purchaseInvoiceId,
  invoiceNumber,
  invoiceDate,
  onPurchaseInvoiceButtonClick = ({ purchaseInvoiceId }) => {},
}) => {
  return (
    <DataTableButton
      onClick={() => onPurchaseInvoiceButtonClick({ purchaseInvoiceId })}
      iconName={INVOICE_ICON_NAME}
      target="_blank"
      rel="noreferrer"
      title={`Visualizza la fattura n° ${invoiceNumber} del ${displayDate(invoiceDate)}`}
      disabled={!purchaseInvoiceId}
    />
  );
};

const { columns: COLUMNS } = new DataTableBuilder()
  .addColumn('attachmentIdDisplayName', (columnBuilder) => {
    columnBuilder.heading('N° progr. registr. DDT').filterable(true).sortable(true).truncate(true);
  })
  .addColumn('registrationDate', (columnBuilder) => {
    columnBuilder
      .type('date')
      .heading('Data registr.')
      .filterable(true)
      .sortable(true)
      .horizontalAlignment('right')
      .size('md-1')
      .cellTemplate((registrationDate) => displayDateTimeHourMinute(registrationDate));
  })
  .addColumn('documentNumber', (columnBuilder) => {
    columnBuilder.heading('N° DDT').filterable(true).sortable(true).truncate(true);
  })
  .addColumn('documentDate', (columnBuilder) => {
    columnBuilder
      .type('date')
      .heading('Data DDT')
      .filterable(true)
      .sortable(true)
      .horizontalAlignment('right')
      .cellTemplate((documentDate) => documentDate && displayDate(documentDate));
  })
  .addColumn('supplierCode', (columnBuilder) => {
    columnBuilder.heading('Cod. fornitore').filterable(true).sortable(true).horizontalAlignment('right');
  })
  .addColumn('supplierBusinessName', (columnBuilder) => {
    columnBuilder.heading('Rag. sociale fornitore').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('personalData', (columnBuilder) => {
    columnBuilder.heading('P.IVA Fornitore').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('address', (columnBuilder) => {
    columnBuilder.heading('Indirizzo fornitore').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('commissionId', (columnBuilder) => {
    columnBuilder
      .heading('N° Comm.')
      .filterable(true)
      .sortable(true)
      .truncate(true)
      .cellTemplate((commissionId) => <CommissionCellTemplate {...{ commissionId }} />);
  })
  .addColumn('purchaseOrderId', (columnBuilder) => {
    columnBuilder
      .heading('N° Ordine')
      .filterType('select')
      .filterOptions([{ id: '-1', description: 'Nessun ordine associato' }])
      .filterable(true)
      .sortable(true)
      .truncate(true)
      .cellTemplate((purchaseOrderId, { commissionId }) => (
        <PurchaseOrderCellTemplate {...{ commissionId, purchaseOrderId }} />
      ));
  })
  .addColumn('customerBusinessName', (columnBuilder) => {
    columnBuilder.heading('Rag. Sociale Cliente').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('description', (columnBuilder) => {
    columnBuilder.heading('Descriz. Comm.').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('note', (columnBuilder) => {
    columnBuilder.heading('Note').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('createdByDisplayName', (columnBuilder) => {
    columnBuilder.heading('Registrato da').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('attachmentId', (columnBuilder) => {
    columnBuilder
      .heading(<InfoColumnHeader title="Visualizz. docum." />)
      .horizontalAlignment('center')
      .size('xxs')
      .cellTemplate((attachmentId, { attachmentIdDisplayName }) => (
        <ViewDdtAttachmentCellTemplate {...{ attachmentId, attachmentIdDisplayName }} />
      ));
  })
  .addColumn('', (columnBuilder) => {
    columnBuilder
      .heading(<InfoColumnHeader title="Modif. docum." />)
      .horizontalAlignment('center')
      .size('xxs')
      .cellTemplate((_, { attachmentId, attachmentIdDisplayName }, { showModal }) => (
        <DataTableButton
          onClick={() => showModal(ActionDDT.UPDATE, attachmentId)}
          title={`Modifica ${attachmentIdDisplayName}`}
          iconName="pen"
        />
      ));
  })
  .addColumn('', (columnBuilder) => {
    columnBuilder
      .heading(<InfoColumnHeader title="Assoc. docum." />)
      .horizontalAlignment('center')
      .size('xxs')
      .cellTemplate((_, { purchaseOrderId, attachmentId, attachmentIdDisplayName }, { showModal }) => (
        <DataTableButton
          onClick={() => showModal(ActionDDT.ASSOCIATE, attachmentId)}
          title={`Associa ${attachmentIdDisplayName}`}
          iconName="link"
          disabled={!!purchaseOrderId}
        />
      ));
  })
  .addColumn('purchaseInvoiceId', (columnBuilder) => {
    columnBuilder
      .heading(<InfoColumnHeader title="Elenco doc. collegati" />)
      .horizontalAlignment('center')
      .size('xxs')
      .truncate(true)
      .cellTemplate((purchaseInvoiceId, { invoiceNumber, invoiceDate }, { handleOnPurchaseInvoiceButtonClick }) => (
        <LinkedDocumentListCellTemplate
          purchaseInvoiceId={purchaseInvoiceId}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          onPurchaseInvoiceButtonClick={handleOnPurchaseInvoiceButtonClick}
        />
      ));
  })
  .build();

export { COLUMNS };
