import BaseButton from './BaseButton';

const ExportAsPdfButton = ({
  type = 'button',
  color = 'primary',
  text = 'Esporta',
  onClick,
  className,
  style,
  size,
}) => (
  <BaseButton type={type} color={color} onClick={onClick} className={className} style={style} size={size}>
    <i className="far fa-file-pdf" /> {text}
  </BaseButton>
);

export default ExportAsPdfButton;
