import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import ROUTES from 'routes';
/*
const STYLES = {
  button: {
    textTransform: 'uppercase',
    marginRight: '1rem',
    fontWeight: '600',
    lineHeight: '1.4em',
    padding: '0.75rem 1.5rem',
    marginBottom: '1rem',
  },
  buttonCollapsePeriods: {
    marginBottom: '1rem',
    verticalAlign: 'bottom',
    marginLeft: 'auto',
  },
};
*/
const STYLES = {
  button: {
    textTransform: 'uppercase',
    fontWeight: '600'
  },
  buttonCollapsePeriods: {
  }
};

const BudgetActionButton = ({ children, color, style, ...otherProps }) => {
  return (
    <Button color={color || 'primary'} style={STYLES.button} {...otherProps}>
      {children}
    </Button>
  );
};

const NavLinkBudgetActionButton = ({ children, color, style, to, ...otherProps /* commissionId, version*/ }) => {
  return (
    <NavLink to={to} className={`btn btn-${color || 'primary'}`} style={STYLES.button} {...otherProps}>
      {children}
    </NavLink>
  );
};
/*
const SaveDraftButton = (props) => {
  return (
    <BudgetActionButton {...props}>
      Salva
      <br />
      Bozza
    </BudgetActionButton>
  );
};

const EditDraftButton = ({ to, commissionId, version, ...otherProps }) => {
  return (
    <NavLinkBudgetActionButton
      to={ROUTES.COMMISSION_MANAGEMENT.EDIT_BUDGET.replace(':commissionId', commissionId).replace(':version', version)}
      {...otherProps}
    >
      Modifica
      <br />
      Bozza
    </NavLinkBudgetActionButton>
  );
};

const RequireApprovalButton = (props) => {
  return (
    <BudgetActionButton {...props} color="warning">
      Richiedi
      <br />
      Approvazione
    </BudgetActionButton>
  );
};

const RevisionButton = ({ commissionId, nextVersion, onClick, ...otherProps }) => {
  const handleClick = () => {
    onClick?.(commissionId, nextVersion);
  };
  return (
    <BudgetActionButton onClick={handleClick} {...otherProps}>
      Revisione
      <br />
      Budget
    </BudgetActionButton>
  );
};

const RevisionArchiveButton = ({ to, commissionId, ...otherProps }) => {
  return (
    <NavLinkBudgetActionButton
      to={ROUTES.COMMISSION_MANAGEMENT.BUDGET_REVISION_ARCHIVE.replace(':commissionId', commissionId)}
      color="secondary"
      {...otherProps}
    >
      Archivio
      <br />
      Revisioni
    </NavLinkBudgetActionButton>
  );
};

const LatestVersionButton = ({ to, commissionId, version, ...otherProps }) => {
  return (
    <NavLinkBudgetActionButton
      to={ROUTES.COMMISSION_MANAGEMENT.VIEW_BUDGET.replace(':commissionId', commissionId).replace(':version', version)}
      color="info"
      {...otherProps}
    >
      Ultima
      <br />
      Versione
    </NavLinkBudgetActionButton>
  );
};

const LatestApprovedVersionButton = ({ to, commissionId, version, ...otherProps }) => {
  return (
    <NavLinkBudgetActionButton
      to={ROUTES.COMMISSION_MANAGEMENT.VIEW_BUDGET.replace(':commissionId', commissionId).replace(':version', version)}
      color="success"
      {...otherProps}
    >
      Ultima Versione
      <br />
      Approvata
    </NavLinkBudgetActionButton>
  );
};
*/
const SaveDraftButton = (props) => {
  return (
    <BudgetActionButton {...props}>
      Salva Bozza
    </BudgetActionButton>
  );
};

const EditDraftButton = ({ to, commissionId, version, ...otherProps }) => {
  return (
    <NavLinkBudgetActionButton
      to={ROUTES.COMMISSION_MANAGEMENT.EDIT_BUDGET.replace(':commissionId', commissionId).replace(':version', version)}
      {...otherProps}
    >
      Modifica Bozza
    </NavLinkBudgetActionButton>
  );
};

const RequireApprovalButton = (props) => {
  return (
    <BudgetActionButton {...props} color="warning">
      Richiedi Approvazione
    </BudgetActionButton>
  );
};

const RevisionButton = ({ commissionId, nextVersion, onClick, ...otherProps }) => {
  const handleClick = () => {
    onClick?.(commissionId, nextVersion);
  };
  return (
    <BudgetActionButton onClick={handleClick} {...otherProps}>
      Revisione Budget
    </BudgetActionButton>
  );
};

const RevisionArchiveButton = ({ to, commissionId, ...otherProps }) => {
  return (
    <NavLinkBudgetActionButton
      to={ROUTES.COMMISSION_MANAGEMENT.BUDGET_REVISION_ARCHIVE.replace(':commissionId', commissionId)}
      color="secondary"
      {...otherProps}
    >
      Archivio Revisioni
    </NavLinkBudgetActionButton>
  );
};

const LatestVersionButton = ({ to, commissionId, version, ...otherProps }) => {
  return (
    <NavLinkBudgetActionButton
      to={ROUTES.COMMISSION_MANAGEMENT.VIEW_BUDGET.replace(':commissionId', commissionId).replace(':version', version)}
      color="info"
      {...otherProps}
    >
      Ultima Versione
    </NavLinkBudgetActionButton>
  );
};

const LatestApprovedVersionButton = ({ to, commissionId, version, ...otherProps }) => {
  return (
    <NavLinkBudgetActionButton
      to={ROUTES.COMMISSION_MANAGEMENT.VIEW_BUDGET.replace(':commissionId', commissionId).replace(':version', version)}
      color="success"
      {...otherProps}
    >
      Ultima Versione Approvata
    </NavLinkBudgetActionButton>
  );
};

const CollapsePeriodsToggler = ({ collapsed, onToggle }) => {
  return (
    <Button color="primary" onClick={onToggle} style={STYLES.buttonCollapsePeriods}>
      {collapsed ? 'Sviluppa periodi »' : '« Chiudi periodi'}
    </Button>
  );
};

export {
  SaveDraftButton,
  EditDraftButton,
  CollapsePeriodsToggler,
  RequireApprovalButton,
  RevisionButton,
  RevisionArchiveButton,
  LatestVersionButton,
  LatestApprovedVersionButton,
};
