import Loader from 'components/layout/Loader';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Badge, Table } from 'reactstrap';
import ROUTES from 'routes';
import { fetchBudgetRevisionArchive } from 'services/api/budget';

const BUDGET_STATUSES = [
  { name: 'Bozza', color: 'dark' },
  { name: 'In attesa di approvazione', color: 'warning' },
  { name: 'Approvato', color: 'success' },
  { name: 'Rifiutato', color: 'danger' },
];

const BudgetRevisionArchivePage = () => {
  const [loading, setLoading] = useState(true);
  const [archive, setArchive] = useState([]);

  const { commissionId } = useParams();

  useEffect(() => {
    fetchBudgetRevisionArchive(commissionId).then((archive) => {
      setArchive(
        archive.map((budgetVersion) => ({
          ...budgetVersion,
          creationDate: new Date(Date.parse(budgetVersion.creationDate)),
          statusDate:
            budgetVersion.approvalOrRejectionDate && new Date(Date.parse(budgetVersion.approvalOrRejectionDate)),
        }))
      );
      setLoading(false);
    });
  }, [commissionId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h1 className="h2 mb-3">Archivio revisioni {commissionId}</h1>
          <div className="table-responsive">
            <Table bordered>
              <colgroup></colgroup>
              <thead>
                <tr>
                  <th>Versione</th>
                  <th>Data creazione</th>
                  <th>Stato</th>
                  <th>Corrente</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {archive.map((budgetVersion) => (
                  <tr key={`${commissionId}/v${budgetVersion.version}`}>
                    <td style={{ verticalAlign: 'middle' }}>{budgetVersion.version}</td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {budgetVersion.creationDate.toLocaleDateString('it-IT', { dateStyle: 'short' })}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Badge
                        color={BUDGET_STATUSES[budgetVersion.status.id].color}
                        className="px-3 py-2 text-uppercase"
                      >
                        {budgetVersion.status.description}
                      </Badge>
                      {budgetVersion.statusDate && (
                        <span className="ml-2">
                          in data {budgetVersion.statusDate.toLocaleDateString('it-IT', { dateStyle: 'short' })}
                        </span>
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <i
                        className={`fa fa-check-circle${budgetVersion.current ? ' text-success' : ''}`}
                        style={{ opacity: budgetVersion.current ? 1 : 0.2 }}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                      <NavLink
                        to={ROUTES.COMMISSION_MANAGEMENT.VIEW_BUDGET.replace(':commissionId', commissionId).replace(
                          ':version',
                          budgetVersion.version
                        )}
                        className="btn btn-outline-primary"
                      >
                        {/* <i className="fa fa-eye" />
                        <br /> */}
                        Visualizza
                      </NavLink>
                      {budgetVersion.editable && (
                        <NavLink
                          to={ROUTES.COMMISSION_MANAGEMENT.EDIT_BUDGET.replace(':commissionId', commissionId).replace(
                            ':version',
                            budgetVersion.version
                          )}
                          className="btn btn-outline-secondary ml-2"
                        >
                          Modifica
                        </NavLink>
                      )}
                    </td>
                    {/* <td>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default BudgetRevisionArchivePage;
