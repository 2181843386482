const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

const getInvoicesWithFilters = async (newPage, numRecords, sort, sortColumn, filter, companyId) => {
  // TO DO: gestire la paginazione, l'ordinamento e i filtri
  let parameter = {
    UserId: -1, //mi sa che non mi serve...forse
    CurrentPage: newPage,
    NumRecords: numRecords,
    CompanyId: companyId !== null ? companyId : 'none',
    Sort: sort != null ? sort : '',
    SortColumn: sortColumn != null ? sortColumn : '',
    Filter: filter
  };

  const response = await fetch(`/api/invoice?`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(parameter),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const getInvoiceNote = async (PurchaseInvoiceId, PurchaseOrderId, PaymentCertificateId) => {
  // TO DO: gestire la paginazione, l'ordinamento e i filtri
  let parameter = {
    UserId: -1, //mi sa che non mi serve...forse
    PurchaseInvoiceId: PurchaseInvoiceId,
    PurchaseOrderId: PurchaseOrderId === null ? "-1" : PurchaseOrderId,
    PaymentCertificateId: PaymentCertificateId === null ? -1 : PaymentCertificateId,
  };

  const queryString = new URLSearchParams(parameter).toString();

  // const response = await fetch(`/api/invoice?${queryString}`, { method: 'GET' });
  // console.log(filterValue);
  const response = await fetch(`/api/invoice/getinvoicenote?${queryString}`, { method: 'GET' });

  try {
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
  }
  catch(exc) {
    console.error(exc);
  }
  return handleErrorResponse(response);
  // console.log(queryString);
  // const response = await fetchResource('/mocks/purchase-orders/index.json');
  // return response;
};

const setInvoiceNote = async (PurchaseInvoiceId, note, PaymentCertificateId = -1, PurchaseOrderId = '-1') => {
  const response = await fetch(`/api/invoice/insertinvoicenote`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      PurchaseInvoiceId: PurchaseInvoiceId,
      Note: note,
      PaymentCertificateId: PaymentCertificateId,
      PurchaseOrderId: PurchaseOrderId
    }),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const getInvoiceById = async (id, companyId) => {
  const queryParameters = {
    UserId: -1, //mi sa che non mi serve...forse
    InvoiceId: id,
    CompanyId: companyId ?? 'none',
  };
  const queryString = new URLSearchParams(queryParameters).toString();

  try {
    const response = await fetch(`/api/invoice/getinvoicebyid?${queryString}`, { method: 'GET' });
    const responseBody = await response.json();
    if (response.ok) {
      return {
        ok: true,
        data: responseBody,
      };
    } else {
      return {
        ok: false,
        message: responseBody.detail || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const getPurchaseOrderAttachments = async (purchaseInvoiceId) => {
  const queryParameters = {
    PurchaseInvoiceId: purchaseInvoiceId
  };
  const queryString = new URLSearchParams(queryParameters).toString();
  try {
    const response = await fetch(`/api/invoice/getpurchaseorderattachments?${queryString}`, { method: 'GET' });
    const responseBody = await response.json();
    if (response.ok) {
      return {
        ok: true,
        data: responseBody,
      };
    } else {
      return {
        ok: false,
        message: responseBody.detail || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const setpurchaseorderattachments = async (purchaseInvoiceId, attachmentId) => {
  const queryParameters = {
    PurchaseInvoiceId: purchaseInvoiceId,
    attachmentId: attachmentId
  };
  const queryString = new URLSearchParams(queryParameters).toString();
  try {
    const response = await fetch(`/api/invoice/setpurchaseorderattachments?${queryString}`, { method: 'GET' });
    const responseBody = await response.json();
    if (response.ok) {
      return {
        ok: true,
        data: responseBody,
      };
    } else {
      return {
        ok: false,
        message: responseBody.detail || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const deletepurchaseorderattachments = async (purchaseInvoiceId, attachmentId) => {
  try {
    const response = await fetch(`/api/invoice/deletepurchaseorderattachments`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'attachmentId': attachmentId,
        'purchaseInvoiceId': purchaseInvoiceId
      }),
    });
    const responseBody = await response.json();
    if (response.ok) {
      return {
        ok: true,
        data: responseBody,
      };
    } else {
      return {
        ok: false,
        message: responseBody.detail || 'Si è verificato un errore',
      };
    }
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const getOptions = async (table, companyId) => {
  let parameter = {
    Table: table,
    CommissionId: -1,
    CompanyId: companyId !== null ? companyId : 'none',
    UserId: -1,
  };

  const queryString = new URLSearchParams(parameter).toString();
  const response = await fetch(`/api/invoice/getselectoptions?${queryString}`, { method: 'GET' });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const getinvoicepurchaseorders = async (CommissionId, PurchaseOrderId, SupplierId, companyId) => {
  let parameter = {
    UserId: -1,
    CommissionId: CommissionId === undefined || CommissionId === "-1" ? "" : CommissionId,
    PurchaseOrderId: PurchaseOrderId === undefined || PurchaseOrderId === "-1" ? "" : PurchaseOrderId,
    CompanyId: companyId !== null ? companyId : 'none',
    SupplierId: SupplierId === undefined || SupplierId === "-1" ? "" : SupplierId
  };

  const queryString = new URLSearchParams(parameter).toString();

  const response = await fetch(`/api/invoice/getinvoicepurchaseorders?${queryString}`, { method: 'GET' });
  // console.log(filterValue);
  // const response = await fetch(`/api/invoice/getinvoicepurchaseorders`, { method: 'POST' });

  // const response = await fetch(`/api/invoice/getinvoicepurchaseorders`, {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({
  //     UserId: -1,
  //     CommissionId: CommissionId,
  //     idInvoicePurchaseOrder: idInvoicePurchaseOrder,
  //     CompanyId: (companyId !== null ? companyId : "none"),
  //     SupplierId: SupplierId,
  //     Type: Type
  //   }),
  // });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const associaCommessa = async (PurchaseInvoiceId, PurchaseOrderId, PaymentCertificateId) => {
  const response = await fetch(`/api/invoice/associatecommission`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      PurchaseInvoiceId: PurchaseInvoiceId,
      PurchaseOrderId: PurchaseOrderId,
      PaymentCertificateId: PaymentCertificateId,
    }),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
    };
  }
  return handleErrorResponse(response);
};

const associaFuoriOrdineApi = async (PurchaseInvoiceId, CommissionId) => {
  const response = await fetch(`/api/invoice/associatefictitiousorder`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ PurchaseInvoiceId: PurchaseInvoiceId, CommissionId: CommissionId }),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
    };
  }
  return handleErrorResponse(response);
};

const disassociaCommessa = async (invoicedPurchaseOrderId, paymentCertificateId) => {
  const response = await fetch(`/api/invoice/disassociatecommission`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      InvoicedPurchaseOrderId: invoicedPurchaseOrderId,
      PaymentCertificateId: paymentCertificateId,
    }),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
    };
  }
  return handleErrorResponse(response);
};

const disassociaFictitiousOrderApi = async (PurchaseInvoiceId, PurchaseOrderId, InvoicedPurchaseOrderId) => {

  console.log(PurchaseInvoiceId, PurchaseOrderId, InvoicedPurchaseOrderId);

  const response = await fetch(`/api/invoice/disassociatefictitiousorder`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ PurchaseInvoiceId: PurchaseInvoiceId, PurchaseOrderId: PurchaseOrderId, InvoicedPurchaseOrderId: InvoicedPurchaseOrderId }),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
    };
  }
  return handleErrorResponse(response);
};

const ripresaFatturaApi = async (purchaseOrder) => {
  const response = await fetch(`/api/invoice/aggiornapprovatore`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      InvoicedPurchaseOrderId: purchaseOrder.invoicedPurchaseOrderId,
      ApproverId: purchaseOrder.approverId,
    }),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
    };
  }
  else if (response.status === 400) {
    const reader = await response.json();
    return {
      success: false,
      authenticated: true,
      message: reader.message
    };
  }
  return handleErrorResponse(response);
};

const updateInvoicePurchaseOrder = async (purchaseOrder) => {
  const response = await fetch(`/api/invoice/updateinvoicepurchaseorderline`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(purchaseOrder),
  });
  // var r = await reader.read();
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
    };
  }
  else if (response.status === 400) {
    const reader = await response.json();
    return {
      success: false,
      authenticated: true,
      message: reader.message
    };
  }
  return handleErrorResponse(response);
};

const updateDocument = async (purchaseInvoiceId, file) => {
  let formData = new FormData();
  formData.append("file", file);
  const response = await fetch(`/api/invoice/setattachment/` + purchaseInvoiceId, {
    method: 'POST',
    // headers: { 'Content-Type': 'application/json' },
    body: formData,
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const deleteDocument = async (purchaseInvoiceId, fileName) => {

  const queryString = new URLSearchParams({"invoiceId" : purchaseInvoiceId, "fileName" : fileName}).toString();

  const response = await fetch(`/api/invoice/deleteattachment?${queryString}`, {
    method: 'DELETE'
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
    };
  }
  return handleErrorResponse(response);
};

const getDocument = async (purchaseInvoiceId) => {

  const queryString = new URLSearchParams({"invoiceId" : purchaseInvoiceId.toString()}).toString();

  const response = await fetch(`/api/invoice/getattachment?${queryString}`, {
    method: 'GET'
  });
  if (response.ok) {
    console.log(response);
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const getInvoicesForCondsultation = async (newPage, numRecords, sort, sortColumn, filter, companyId) => {
  // TO DO: gestire la paginazione, l'ordinamento e i filtri
  let parameter = {
    UserId: -1, //mi sa che non mi serve...forse
    CurrentPage: newPage,
    NumRecords: numRecords,
    CompanyId: companyId !== null ? companyId : 'none',
    Sort: sort != null ? sort : '',
    SortColumn: sortColumn != null ? sortColumn : '',
    Filter: filter
  };

  const response = await fetch(`/api/invoice/getinvoicesforconsultation`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(parameter),
  });
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

export {
  getInvoicesWithFilters,
  getInvoiceById,
  getOptions,
  associaCommessa,
  getinvoicepurchaseorders,
  updateInvoicePurchaseOrder,
  disassociaCommessa,
  associaFuoriOrdineApi,
  ripresaFatturaApi,
  disassociaFictitiousOrderApi,
  getInvoiceNote,
  setInvoiceNote,
  updateDocument,
  deleteDocument,
  getDocument,
  getInvoicesForCondsultation,
  getPurchaseOrderAttachments,
  setpurchaseorderattachments,
  deletepurchaseorderattachments
};
