const PURCHASE_ORDERS = {
  INDEX: '/commesse/:commissionId/ordini-fornitori',
  CREATE: '/commesse/:commissionId/ordini-fornitori/crea',
  HEADER: '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/generali',
  BODY: '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/corpo',
  DEADLINES: '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/scadenziario',
  ATTACHMENTS: '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/allegati',
  PAYMENT_CERTIFICATES: {
    INDEX: '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/certificati-di-pagamento',
    NEW: '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/certificati-di-pagamento/crea/:paymentCertificateId',
    EDIT: '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/certificati-di-pagamento/modifica/:paymentCertificateId',
    DETAIL:
      '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/certificati-di-pagamento/visualizza/:paymentCertificateId',
  },
  INVOICES: '/commesse/:commissionId/ordini-fornitori/:purchaseOrderId/fatture',
  DETAILS: '/commesse/:commissionId/ordini-fornitori/dettagli',
};

const SALES_ORDER = {
  INDEX: '/commesse/:commissionId/ordini-clienti',
  CREATE: '/commesse/:commissionId/ordini-clienti/crea',
  HEADER: '/commesse/:commissionId/ordini-clienti/:salesOrderId/generali',
  // VIEW: '/commesse/:commissionId/ordini-clienti/:salesOrderId/visualizza',
  BODY: '/commesse/:commissionId/ordini-clienti/:salesOrderId/corpo',
  ATTACHMENTS: '/commesse/:commissionId/ordini-clienti/:salesOrderId/allegati',
  PAYMENT_CERTIFICATES: {
    INDEX: '/commesse/:commissionId/ordini-clienti/:salesOrderId/certificati-di-pagamento',
    NEW: '/commesse/:commissionId/ordini-clienti/:salesOrderId/certificati-di-pagamento/crea/:paymentCertificateId',
    EDIT: '/commesse/:commissionId/ordini-clienti/:salesOrderId/certificati-di-pagamento/modifica/:paymentCertificateId',
    DETAIL:
      '/commesse/:commissionId/ordini-clienti/:salesOrderId/certificati-di-pagamento/visualizza/:paymentCertificateId',
  },
  INVOICES: '/commesse/:commissionId/ordini-clienti/:salesOrderId/fatture',
};

const ERRORS = {
  NOT_FOUND: '/404',
};

const AUTH = {
  LOGIN: '/account/login',
};

const ROUTES = {
  COMMISSION_MANAGEMENT: {
    COMMISSIONS: {
      DETAILS: '/commesse/:commissionId/commessa',
    },
    INDEX: '/commesse',
    NEW: '/commesse/nuova',
    NEW_DATA: '/commesse/nuova/:companyId/:businessLineId',
    EDIT: '/commesse/:commissionId/commessa/modifica',
    DOCUMENTS: '/commesse/:commissionId/documenti',
    BUDGET: '/commesse/:commissionId/budget',
    CREATE_BUDGET: '/commesse/:commissionId/budget/:version/crea',
    EDIT_BUDGET: '/commesse/:commissionId/budget/:version/modifica',
    VIEW_BUDGET: '/commesse/:commissionId/budget/:version',
    BUDGET_REVISION_ARCHIVE: '/commesse/:commissionId/budget/archivio',
    BUDGET_APPROVAL: '/commesse/:commissionId/approvazione-budget',
    ENABLED_USERS: '/commesse/:commissionId/utenti-abilitati',
    PURCHASE_ORDERS,
    SALES_ORDER,
  },
  SUPPLIER_INVOICES_MANAGEMENT: {
    INDEX: '/gestione-fatture-fornitori',
    MANAGE: '/gestione-fatture-fornitori/:purchaseInvoiceId',
    INVOICE_CONSULTATION: '/consultazione-fatture-fornitori',
  },
  SALES_INVOICES_MANAGEMENT: {
    INDEX: '/gestione-fatture-clienti',
    MANAGE: '/gestione-fatture-clienti/:salesInvoiceId',
  },
  SALES_ORDERS_EXPORT: {
    INDEX: '/elenco-ordini-clienti',
  },
  PURCHASE_ORDERS_EXPORT: {
    INDEX: '/elenco-ordini-fornitori',
  },
  EXPORT_INVOICEABLE_SALES_ORDERS: {
    INDEX: '/esporta-richieste-fatturazione-clienti',
  },
  SALES_INVOICES_EXPORT: {
    INDEX: '/consultazione-fatture-clienti',
  },
  MANAGE_DDT: '/gestione-ddt',
  ERRORS,
  AUTH,
};

export default ROUTES;

const replaceUrlTokens = (route, tokens = {}) => {
  return Object.keys(tokens).reduce((currentRoute, token) => currentRoute.replace(':' + token, tokens[token]), route);
};

export const routeFactory = {
  commissionManagement: {
    commissions: {
      detail: (commissionId) => replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS, { commissionId }),
    },
    purchaseOrders: {
      create: (commissionId) => replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.CREATE, { commissionId }),
      detail: (commissionId, purchaseOrderId) =>
        replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.HEADER, {
          commissionId,
          purchaseOrderId,
        }),
      attachments: (commissionId, purchaseOrderId) =>
        replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.ATTACHMENTS, { commissionId, purchaseOrderId }),
      paymentCertificates: {
        index: (commissionId, purchaseOrderId) =>
          replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.PAYMENT_CERTIFICATES.INDEX, {
            commissionId,
            purchaseOrderId,
          }),
        create: (commissionId, purchaseOrderId, paymentCertificateId) =>
          replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.PAYMENT_CERTIFICATES.NEW, {
            commissionId,
            purchaseOrderId,
            paymentCertificateId,
          }),
        detail: (commissionId, purchaseOrderId, paymentCertificateId) =>
          replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.PAYMENT_CERTIFICATES.DETAIL, {
            commissionId,
            purchaseOrderId,
            paymentCertificateId,
          }),
        edit: (commissionId, purchaseOrderId, paymentCertificateId) =>
          replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.PURCHASE_ORDERS.PAYMENT_CERTIFICATES.EDIT, {
            commissionId,
            purchaseOrderId,
            paymentCertificateId,
          }),
      },
    },
    salesOrders: {
      detail: (commissionId, salesOrderId) =>
        replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.HEADER, { commissionId, salesOrderId }),
      body: (commissionId, salesOrderId) =>
        replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.BODY, { commissionId, salesOrderId }),
      attachments: (commissionId, salesOrderId) =>
        replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.ATTACHMENTS, { commissionId, salesOrderId }),
      paymentCertificates: {
        index: (commissionId, salesOrderId) =>
          replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.PAYMENT_CERTIFICATES.INDEX, {
            commissionId,
            salesOrderId,
          }),
        create: (commissionId, salesOrderId, paymentCertificateId) =>
          replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.PAYMENT_CERTIFICATES.NEW, {
            commissionId,
            salesOrderId,
            paymentCertificateId,
          }),
        detail: (commissionId, salesOrderId, paymentCertificateId) =>
          replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.PAYMENT_CERTIFICATES.DETAIL, {
            commissionId,
            salesOrderId,
            paymentCertificateId,
          }),
        edit: (commissionId, salesOrderId, paymentCertificateId) =>
          replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.PAYMENT_CERTIFICATES.EDIT, {
            commissionId,
            salesOrderId,
            paymentCertificateId,
          }),
      },
      invoices: (commissionId, salesOrderId) =>
        replaceUrlTokens(ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.INVOICES, { commissionId, salesOrderId }),
    },
  },
  supplierInvoicesManagement: {
    manage: (purchaseInvoiceId) => replaceUrlTokens(ROUTES.SUPPLIER_INVOICES_MANAGEMENT.MANAGE, { purchaseInvoiceId }),
  },
};
