import PurchaseInvoiceAssociationItem from '../association-item';

const PurchaseInvoiceAssociationList = ({
  invoiceAssociations,
  onApprove = (invoiceOrderAssociationId) => {},
  onSuspend = (invoiceOrderAssociationId) => {},
  onResume = (invoiceOrderAssociationId) => {},
  onReject = (invoiceOrderAssociationId) => {},
}) => {
  return invoiceAssociations.length > 0 ? (
    invoiceAssociations.map((invoiceAssociation) => (
      <PurchaseInvoiceAssociationItem
        key={`invoiceAssociation-${invoiceAssociation.id}`}
        invoiceAssociation={invoiceAssociation}
        onApprove={onApprove}
        onSuspend={onSuspend}
        onResume={onResume}
        onReject={onReject}
      />
    ))
  ) : (
    <div>Nessuna fattura associata a quest'ordine.</div>
  );
};

export default PurchaseInvoiceAssociationList;
