import CommissionForm from 'components/forms/commission/CommissionForm';
import FormInput from 'components/forms/FormInput';
import Loader from 'components/layout/Loader';
import CustomInput from 'components/shared/forms/CustomInput';
import { ToReceiveObject } from 'helpers/commissionMap';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import ROUTES from 'routes';
import { deleteCommissionWithId, fetchCommissionWithId, updateCommissionStatusToClose, updateCommissionStatusToReOpened } from 'services/api/commissions';
import useFetchCommissionFormOptions from './hooks/useFetchCommissionFormOptions';
import useParseCustomResponse from './hooks/useParseCustomResponse';
import useFeedback from 'hooks/useFeedback';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { COMMISSION_PERMISSIONS } from 'constants/commission-management-permissions';
import useCommission from 'hooks/useCommission';

const DetailsCommission = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [data, setData] = useState();
  const { commissionId } = useParams();

  const { loading, response } = useFetchCommissionFormOptions();
  const parseCustomResponse = useParseCustomResponse();
  const { success, error } = useFeedback();
  const { hasPermission } = useCommissionPermissions();
  const navigate = useNavigate();
  const { invalidate } = useCommission();

  const canEdit = hasPermission(COMMISSION_PERMISSIONS.EDIT);
  const canDelete = hasPermission(COMMISSION_PERMISSIONS.DELETE);
  const canClose = hasPermission(COMMISSION_PERMISSIONS.CLOSE);
  const canReopen = hasPermission(COMMISSION_PERMISSIONS.OPEN);

  const deleteHandler = async () => {
    const confirm = window.confirm('Sei davvero sicuro di voler eliminare questa commessa?');
    if (confirm) {
      const response = await deleteCommissionWithId(commissionId)
      // const parsedResponse = parseCustomResponse(response);
      if (response.success) {
        success(`La commessa ${commissionId} è stata eliminata`);
        navigate(ROUTES.COMMISSION_MANAGEMENT.INDEX);
      } else {
        error(response.message);
      }
    }
  };

  const closeHandler = async () => {
    const confirm = window.confirm('Sei davvero sicuro di voler chiudere questa commessa?');
    if (confirm) {
      updateCommissionStatusToClose(commissionId).then((response) => {
        console.log(response)
      if (response.success) {
        success('commessa chiusa correttamente', 'Successo');
        invalidate();
        navigate(ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS.replace(':commissionId', commissionId));
      }
      else {
        error(`${response.message}`)
      }
    }).catch(() => error('Si è verificato un errore', 'Errore'));

    }
  };

  const reopenHandler = async () => {
    const confirm = window.confirm('Sei davvero sicuro di voler ri-aprire questa commessa?');
    if (confirm) {

      updateCommissionStatusToReOpened(commissionId).then((response) => {
        if (response.success) {
          success('commessa riaperta correttamente', 'Successo');
          invalidate();
           navigate(ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS.replace(':commissionId', commissionId));
        }
      }).catch(() => error('Si è verificato un errore', 'Errore'));
    }
  };

  useEffect(() => {
    try {
      fetchCommissionWithId(commissionId).then((response) => {
        const parsedResponse = parseCustomResponse(response);
        if (parsedResponse.success) {
          const updatedData = ToReceiveObject(response.data);
          setInitialLoading(false);
          setData(updatedData);
        }
      });
    } catch {
      alert('Si è verificato un errore');
      setInitialLoading(false);
    }
  }, [commissionId, parseCustomResponse]);

  return (
    <>
      <h1 className="h2 mb-3">Dettaglio commessa</h1>
      {loading || initialLoading ? (
        <Loader />
      ) : (
        <>
          {data && (
            <>
              <Row>
                <Col md={4}>
                  <FormInput
                    type="text"
                    label="ID"
                    name="commissionId"
                    disabled
                    value={commissionId}
                  ></FormInput>
                </Col>
                <Col md={3} className="offset-md-2">
                  <CustomInput
                    label="Creata da"
                    name="createdBy"
                    disabled
                    value={data.creationName}
                  />
                </Col>
                <Col md={3}>
                  <CustomInput
                    type="date"
                    min="2000-01-01"
                    max="9999-12-31"
                    label="Data creazione"
                    name="creationDate"
                    disabled
                    value={data.creationDate}
                  />
                </Col>
              </Row>
              <CommissionForm commission={data} disabled options={response} />
              <Row>
                <Col lg={6} className="offset-lg-6">
                  <Row>
                    {data.statusId !== 8 && (
                      <>
                        {canEdit && (
                          <Col xs={6} xl={3}>
                            <NavLink
                              to={ROUTES.COMMISSION_MANAGEMENT.EDIT.replace(
                                ":commissionId",
                                commissionId
                              )}
                              className="btn btn-primary btn-block"
                            >
                              Modifica
                            </NavLink>
                          </Col>
                        )}
                        {canDelete && (
                          <Col xs={6} xl={3}>
                            <Button
                              block
                              type="button"
                              color="danger"
                              onClick={deleteHandler}
                            >
                              Elimina
                            </Button>
                          </Col>
                        )}
                        {/* Se la commessa è in uno stato diverso da "Chiusa" e si hanno i permessi allora si può chiudere la commessa */}
                        {canClose && (
                          <Col xs={6} xl={3}>
                            <Button
                              block
                              type="button"
                              color="danger"
                              onClick={closeHandler}
                            >
                              Chiudi
                            </Button>
                          </Col>
                        )}
                      </>
                    )}
                    {/* Se la commessa è nello stato "Chiusa" e si hanno i permessi allora si può riaprire la commessa */}
                    {data.statusId === 8 && canReopen && (
                      <Col xs={6} xl={3}>
                        <Button
                          block
                          type="button"
                          color="success"
                          onClick={reopenHandler}
                        >
                          Riapri
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DetailsCommission;
