import { useState } from "react";
import { NavLink } from "react-router-dom";

const CustomGroupBox = (props) => {
    const [isExpanded, setExpanded] = useState((((props.isExpander) && (props.isExpanded != null) && (props.isExpanded)) || (!props.isExpander)) ? true : false);
    const expand = () => {
      if (props.isExpander) {
        setExpanded(!isExpanded);
      }
    };
    return (
        <div style={STYLES.header}>
          {
            (((props.isExpander != null) && (!props.isExpander)) || (props.isExpander == null)) && (props.isHeaderLink != null ? props.isHeaderLink : false) &&
            <NavLink target="_blank" style={STYLES.headerLabel} to={props.link != null ? props.link : '#'}>{props.Title}</NavLink>
          }
          {
            (((props.isExpander != null) && (!props.isExpander)) || (props.isExpander == null)) && !(props.isHeaderLink != null ? props.isHeaderLink : false) &&
            <label style={STYLES.headerLabel}>{props.Title}</label>
          }
          {
            ((props.isExpander != null) && (props.isExpander) && (!isExpanded)) &&
            <label style={STYLES.headerLabelExpander} onClick={() => { expand() }}><i className="fa fa-chevron-right mr-1"></i>{props.Title}</label>
          }
          {
            ((props.isExpander != null) && (props.isExpander) && (isExpanded)) &&
            <label style={STYLES.headerLabelExpander} onClick={() => { expand() }}><i className="fa fa-chevron-down mr-1"></i>{props.Title}</label>
          }
          {
            isExpanded &&
            <div style={{background: 'white', borderRadius: '.35rem', border: '1px solid #00000020', padding: '7.5px', marginTop: '5px'}}>
              {props.children}
            </div>
          }
        </div>
    );
};

const STYLES = {
  header: {
    border: '1px solid #c2cbe5',
    borderRadius: '2.5px',
    position: 'relative',
    padding: '15px 5px 5px 5px',
    marginTop: '15px',
    background: '#f8f9fc'
  },
  headerLabel: {
    position: 'absolute',
    top: '-12px',
    left: '5px',
    border: '1px solid #c2cbe5',
    background: '#e6eaf4',
    borderRadius: '2.5px',
    padding: '0 5px',
    fontWeight: 'bold'
  },
  headerLabelExpander: {
    border: '1px solid #c2cbe5',
    background: '#e6eaf4',
    borderRadius: '2.5px',
    padding: '0 5px',
    fontWeight: 'bold',
    marginTop: '-16px',
    marginLeft: '-6px',
    display: 'block',
    cursor: 'pointer'
  },
  iconExpander: {
    cursor: 'pointer'
  }
};
export default CustomGroupBox;