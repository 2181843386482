import Loader from 'components/layout/Loader';
import DataTable from 'components/shared/DataTable';
import { downloadFile } from 'helpers/api';
import { formatISODate, formatNumber } from 'helpers/formatters';
import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import InvoiceAssociationStatusBadge from 'components/invoices/InvoiceAssociationStatusBadge';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { Button } from 'reactstrap';
import { getInvoicesForCondsultation, getOptions } from 'services/api/invoices';

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];

const InvoiceConsultationIndexPage = () => {
  const [sort, setSort] = useState({ column: '', direction: '' });
  const [numRecords, setNumRecords] = useState(INITIAL_PAGE_SIZE);
  const { company } = useAuth();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({
    FilterInvoiceNumber: false,
    FilterInvoiceNumberValue: '',
    FilterSupplier: false,
    FilterSupplierValue: '',
    FilterCommissionId: false,
    FilterCommissionIdValue: '',
    FilterPurchaseOrder: false,
    FilterPurchaseOrderValue: '',
    FilterStatus: false,
    FilterStatusValue: 0,
    FilterDataFatturaFromStatus: false,
    FilterDataFatturaFromStatusValue: '',
    FilterDataFatturaToStatus: false,
    FilterDataFatturaToStatusValue: '',
    FilterDataRegistrazioneFromStatus: false,
    FilterDataRegistrazioneFromStatusValue: '',
    FilterDataRegistrazioneToStatus: false,
    FilterDataRegistrazioneToStatusValue: '',
  });
  const { error } = useFeedback();
  const [response, setResponse] = useState();
  const [statusList, setStatusList] = useState([]);

  const styles = {
    cell: {
      verticalAlign: 'middle',
    },
    amountCell: {
      textAlign: 'right',
    },
    textTruncatedCell: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };

  const headerDatagrid = [
    {
      heading: 'Nr. fattura',
      value: 'invoiceNumber',
      type: 'text',
      filterable: true,
      sortable: true,
      style: { ...styles.cell, ...styles.textTruncatedCell, maxWidth: '50px' },
    },
    {
      heading: 'Data fattura',
      value: 'dataFattura',
      type: 'date',
      filterable: true,
      sortable: true,
      style: styles.cell,
      cellTemplate: (val) => {
        return formatISODate(val);
      },
    },
    {
      heading: 'Rag. soc. fornitore',
      value: 'supplier',
      type: 'text',
      filterable: true,
      sortable: true,
      options: null,
      style: { ...styles.cell, ...styles.textTruncatedCell, maxWidth: '100px' },
      cellTemplate: (val) => <span title={val}>{val}</span>,
    },
    {
      heading: 'Commessa',
      value: 'commission',
      type: 'text',
      filterable: true,
      sortable: true,
      options: null,
      style: { ...styles.cell, ...styles.textTruncatedCell, maxWidth: '100px' },
      cellTemplate: (val) => <span title={val}>{val}</span>,
    },
    {
      heading: 'Ordine/CDP',
      value: 'purchaseOrder',
      type: 'text',
      filterable: true,
      sortable: true,
      options: null,
      style: { ...styles.cell, ...styles.textTruncatedCell, maxWidth: '150px' },
      cellTemplate: (val) => <span title={val}>{val}</span>,
    },
    {
      heading: 'Tot. fattura',
      value: 'importo',
      type: 'text',
      filterable: false,
      sortable: true,
      options: null,
      style: { ...styles.cell, ...styles.amountCell },
      cellTemplate: (val) => formatNumber(val),
    },
    {
      heading: 'Tot. associato',
      value: 'importoFattura',
      type: 'text',
      filterable: false,
      sortable: true,
      options: null,
      style: { ...styles.cell, ...styles.amountCell },
      cellTemplate: (val) => formatNumber(val),
    },
    {
      heading: 'Data creazione',
      value: 'creationDate',
      type: 'date',
      filterable: true,
      sortable: true,
      options: null,
      style: styles.cell,
      cellTemplate: (val) => formatISODate(val),
    },
    {
      heading: 'Stato',
      value: 'status',
      type: 'select',
      filterable: true,
      sortable: true,
      options: statusList,
      style: styles.cell,
      cellTemplate: (_val, row) => (
        <InvoiceAssociationStatusBadge statusId={row.status.id} statusName={row.status.description} />
      ),
    },
  ];

  const loadStatus = useCallback(() => {
    getOptions('INVOICEDPURCHASEORDERSTATUS', company.id)
      .then((result) => {
        if (result.success && result.authenticated && result.authorized && result.data != null) {
          var res = [];
          result.data.forEach((r) => {
            if (parseInt(r.id) !== -1) {
              res.push({ id: parseInt(r.id), description: r.value });
            }
          });
          setStatusList(res);
        } else {
          error('Error in ajax call');
        }
      })
      .catch((err) => {
        setLoading(false);
        error(err);
      });
  }, [company.id, error]);

  useEffect(() => {
    setLoading(true);

    loadStatus();

    console.log(filters);
    getInvoicesForCondsultation(currentPage, numRecords, sort.direction, sort.column, filters, company.id)
      .then((result) => {
        setLoading(false);
        if (result.success && result.authenticated && result.authorized && result.data != null) {
          setResponse(result);
          console.log(result);
        } else {
          error('Error in ajax call');
        }
      })
      .catch((err) => {
        setLoading(false);
        error(err);
      });
  }, [numRecords, currentPage, sort.column, sort.direction, filters, error, company.id, loadStatus]);

  //Gestisce l'ordinamento
  const onHandleSort = (columnName) => {
    let newDirection = sort.direction === '' ? 'ASC' : sort.direction === 'ASC' ? 'DESC' : '';
    setSort({ column: columnName, direction: newDirection });
    setCurrentPage(0);
  };

  //Gestisce la ricerca
  const onHandleSearch = (value, field, type, newPage) => {
    switch (field) {
      case 'invoiceNumber':
        setFilters({
          ...filters,
          FilterInvoiceNumber: value !== '',
          FilterInvoiceNumberValue: value,
        });
        break;
      case 'supplier':
        setFilters({
          ...filters,
          FilterSupplier: value !== '',
          FilterSupplierValue: value,
        });
        break;
      case 'commission':
        setFilters({
          ...filters,
          FilterCommissionId: value !== '',
          FilterCommissionIdValue: value,
        });
        break;
      case 'purchaseOrder':
        setFilters({
          ...filters,
          FilterPurchaseOrder: value !== '',
          FilterPurchaseOrderValue: value,
        });
        break;
      case 'dataFatturaFrom':
        setFilters({
          ...filters,
          FilterDataFatturaFromStatus: value !== '',
          FilterDataFatturaFromStatusValue: value,
        });
        break;
      case 'dataFatturaTo':
        setFilters({
          ...filters,
          FilterDataFatturaToStatus: value !== '',
          FilterDataFatturaToStatusValue: value,
        });
        break;
      case 'creationDateFrom':
        setFilters({
          ...filters,
          FilterDataRegistrazioneFromStatus: value !== '',
          FilterDataRegistrazioneFromStatusValue: value,
        });
        break;
      case 'creationDateTo':
        setFilters({
          ...filters,
          FilterDataRegistrazioneToStatus: value !== '',
          FilterDataRegistrazioneToStatusValue: value,
        });
        break;
      case 'status':
        setFilters({
          ...filters,
          FilterStatus: value !== '',
          FilterStatusValue: value === '' ? 0 : parseInt(value),
        });
        break;
      default:
        console.log(field);
        setFilters({
          FilterInvoiceNumber: false,
          FilterInvoiceNumberValue: '',
          FilterSupplier: false,
          FilterSupplierValue: '',
          FilterCommissionId: false,
          FilterCommissionIdValue: '',
          FilterPurchaseOrder: false,
          FilterPurchaseOrderValue: '',
          FilterStatus: false,
          FilterStatusValue: 0,
        });
        break;
    }
    setCurrentPage(0);
  };

  //Gestisce il numero di record per pagina
  const onHandleRecordsChange = (num, newPage) => {
    setNumRecords(num);
    setCurrentPage(newPage);
  };

  //Gestisce il cambio di pagina
  const onHandlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const downloadExcel = async () => {
    setLoading(true);

    try {
      // /api/invoice/getexportinvoicestoexcel
      const response = await fetch('/api/invoice/getexportinvoicestoexcel', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          CompanyId: company.id,
          Sort: sort.direction,
          SortColumn: sort.column,
          Filter: filters,
        }),
      });
      const file = await response.blob();

      const contentDispositionHeaderValue = response.headers.get('Content-Disposition');
      const fileName = contentDispositionHeaderValue.split(';')[1].split('=')[1];

      await downloadFile(file, fileName);
    } catch (ex) {
      // error(ex.message)
      error('Si è verificato un errore');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-1">
        <h1 className="h3 mb-0 text-gray-800">Consultazione fatture fornitori</h1>
        <div>
          {/* <a className="btn btn-primary btn-sm shadow-sm"
                        target="_blank"
                        style={{ float: "right" }}
                        rel="noreferrer"
                        href={`../api/invoice/getexportinvoicestoexcel?${linkRequest}`}>
                        <i className="fas fa-download fa-sm text-white-50"></i> Genera Excel
                    </a> */}
          <Button
            color="primary"
            // style={{ float: "right" }}
            size="sm"
            onClick={downloadExcel}
            className="shadow-sm"
          >
            <i className="fas fa-download fa-sm text-white-50"></i> Genera Excel
          </Button>
        </div>
      </div>
      {loading && <Loader />}
      {response?.success && (
        <DataTable
          responsive
          size="sm"
          data={response.data.invoices}
          column={headerDatagrid}
          resizable={false}
          onHandleSort={onHandleSort}
          onHandleSearch={onHandleSearch}
          onHandlePageChange={onHandlePageChange}
          onHandleRecordsChange={onHandleRecordsChange}
          totRecords={response.data.totRecords}
          currentPage={currentPage}
          numRecords={numRecords}
          sort={sort}
          paginationValues={PAGES_SIZE}
        />
      )}
    </>
  );
};

export default InvoiceConsultationIndexPage;
