import Loader from "components/layout/Loader";
import { SALES_ORDER_PERMISSIONS } from "constants/commission-management-permissions";
import useCommissionPermissions from "hooks/useCommissionPermissions";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { routeFactory } from "routes";
import { fetchSalesOrderPaymentCertificates } from "services/api/sales-order";
import SalesOrderSummary from "../components/SalesOrderSummary";
import PaymentCertificatesTable from "../components/list";

const fetchInitialData = (salesOrderId) => {
    return fetchSalesOrderPaymentCertificates(salesOrderId);
  };

const SalesOrderPaymentCertificatesPage = () => {
    const [loading, setLoading] = useState(false);
    const [pageModel, setPageModel] = useState();
    const [errorMessage, setErrorMessage] = useState('');
  
    const { commissionId, salesOrderId } = useParams();
    const { hasPermission } = useCommissionPermissions();
  
    const canCreate = 
      hasPermission(SALES_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.CREATE) &&
      pageModel &&
      pageModel.canCreate &&
      (pageModel.amountRemainder > 0 ||
        pageModel.guaranteeWithholdingAmountRemainder > 0 ||
        pageModel.advanceWithholdingAmountRemainder > 0);
  
    useEffect(() => {
      setLoading(true);
      fetchInitialData(salesOrderId)
        .then((response) => {
          if (response.ok) {
            setPageModel(response.data);
            console.log(response.data)
          } else {
            setErrorMessage(response.errorMessage);
          }
        })
        .finally(() => setLoading(false));
    }, [salesOrderId]);
  
    return (
      <>
        {loading && <Loader />}
        {pageModel && (
          <>
            <h3 className="mb-4">Certificati di pagamento</h3>
            
            <SalesOrderSummary order={pageModel} />
            <div className="mb-4">
              <h4 className="mb-3">Certificati emessi</h4>
              {pageModel.paymentCertificates.length > 0 ? (
                <PaymentCertificatesTable
                  commissionId={commissionId}
                  salesOrderId={salesOrderId}
                  paymentCertificates={pageModel.paymentCertificates}
                  guaranteeWithholdingTableHeaderText={pageModel.guaranteeWithholdingTableHeaderText}
                />
              ) : (
                <p>Non sono ancora stati creati certificati di pagamento per quest'ordine.</p>
              )}
            </div>
            {canCreate && (
              <Row>
                <Col className="text-right">
                  <NavLink
                    to={routeFactory.commissionManagement.salesOrders.paymentCertificates.create(
                      commissionId,
                      salesOrderId,
                      pageModel.paymentCertificates.length + 1
                    )}
                    className="btn btn-primary"
                  >
                    Crea nuovo CDP
                  </NavLink>
                </Col>
              </Row>
            )}
          </>
        )}
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </>
    );
  };
  
  export default SalesOrderPaymentCertificatesPage;