import {
  CustomInput as BsCustomInput,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  FormText,
} from 'reactstrap';
import { useState } from 'react';
import CustomInput from 'components/shared/forms/CustomInput';

const SupplierDetail = ({ label, value, className = 'mb-2' }) => (
  <Row className={className}>
    <Col xs={4} style={{ lineHeight: 1 }}>
      {label}
    </Col>
    <Col xs={8} style={{ lineHeight: 1 }}>
      {value}
    </Col>
  </Row>
);

const SupplierFormSectionData = ({ readonly = false, supplier, purchaseOrder, paymentTerms }) => {
  const [paymentTermsChanged, setPaymentTermsChanged] = useState(
    purchaseOrder.paymentTerms.id !== supplier.paymentTerms
  );
  const handlePaymentTermsChange = (e) => {
    const paymentTermId = parseInt(e.target.value);
    setPaymentTermsChanged(!isNaN(paymentTermId) && paymentTermId !== supplier.paymentTerms);
  };
  return (
    // <fieldset className="mb-1">
    <Card tag="fieldset" className="mb-1">
      <CardHeader className="p-2">Anagrafica fornitore</CardHeader>
      <CardBody className="p-2">
        <SupplierDetail label="Ragione sociale" value={supplier.businessName} />
        <SupplierDetail label="Cod. E-solver" value={supplier.code} />
        <SupplierDetail label="Indirizzo" value={supplier.address} />
        <SupplierDetail label="Comune" value={supplier.municipality} />
        <SupplierDetail label="P.IVA" value={supplier.vatNumber} />
        <SupplierDetail label="Cod. fiscale" value={supplier.taxCode} className="mb-4" />
        <CustomInput
          name="paymentTerms"
          type="select"
          label="Condizioni di pagamento"
          groupClassName="mb-0"
          defaultValue={purchaseOrder.paymentTerms.id}
          onChange={handlePaymentTermsChange}
          disabled={readonly}
          required
        >
          <option value="">...</option>
          {paymentTerms.map((paymentTerm) => (
            <option key={`paymentTerm_${paymentTerm.id}`} value={paymentTerm.id}>
              {paymentTerm.name}
            </option>
          ))}
        </CustomInput>
        {paymentTermsChanged && (
          <>
            <FormText className="mb-2" color="danger">
              ATTENZIONE: la modifica delle condizioni di pagamento verrà valutata da amministrazione...
            </FormText>
            <Row>
              <Col xl={4} className="my-2">
                <label className="d-none d-xl-block">&nbsp;</label>
                <Row>
                  <Col md={'auto'} xl={12}>
                    <BsCustomInput
                      type="radio"
                      id="paymentTermsModificationTemporary"
                      name="paymentTermsModificationType"
                      className="mb-2"
                      value={1}
                      required
                      defaultChecked={
                        purchaseOrder.paymentTerms.modificationType === 1 ||
                        purchaseOrder.paymentTerms.modificationType !== 2
                      }
                      label="Modifica temporanea"
                      disabled={readonly}
                    />
                  </Col>
                  <Col md={'auto'} xl={12}>
                    <BsCustomInput
                      type="radio"
                      id="paymentTermsModificationStructural"
                      name="paymentTermsModificationType"
                      value={2}
                      required
                      defaultChecked={purchaseOrder.paymentTerms.modificationType === 2}
                      label="Modifica strutturale"
                      disabled={readonly}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={8}>
                <FormGroup>
                  <Label>Note per amministrazione</Label>
                  <Input
                    type="textarea"
                    name="paymentTermsModificationNote"
                    defaultValue={purchaseOrder.paymentTerms.note}
                    disabled={readonly}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
    // </fieldset>
  );
};

export default SupplierFormSectionData;
