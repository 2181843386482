import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import AutoComplete from './autocomplete';

const FilterCommesse = (props) => {
  const [inputValue] = useState({
    SelectedCommission: props.SelectedCommission,
    SelectedInvoicePurchaseOrder: props.SelectedInvoicePurchaseOrder,
    SelectedSuppliers: props.SelectedSuppliers,
  });

  // const OnCommissionTextChanged = (event) => {
  //     if ((event.value == null) || ((event.value != null) && (event.value.length < 3))) return;
  //     const comm = props.Commisions.find((s) => {
  //         return (s.value === event.value);
  //     });
  //     props.OnCommissionFiltered(comm);
  // }
  // const OnInvoicePurchaseOrdersTextChanged = (event) => {
  //     if ((event.value == null) || ((event.value != null) && (event.value.length < 3))) return;
  //     const invoice = props.InvoicePurchaseOrders.find((s) => {
  //         return (s.value === event.value);
  //     });
  //     props.OnInvoicePurchaseOrdersFiltered(invoice);
  // }
  // const OnSuppliersTextChanged = (event) => {
  //     // if ((event.value == null) || ((event.value != null) && (event.value.length < 3))) {
  //     //     props.OnSuppliersFiltered({ id: -1, value: "" });
  //     //     return;
  //     // }
  //     if ((event.value == null) || ((event.value != null) && (event.value.length < 3))) return;
  //     const supp = props.Suppliers.find((s) => {
  //         return (s.value === event.value);
  //     });
  //     props.OnSuppliersFiltered(supp);
  // }
  const OnCommissionChanged = useCallback(
    async (event) => {
      // if(event === null) return;
      // const comm = props.Commisions.find((s) => {
      //     return (s.id === event.value);
      // });
      props.OnCommissionFiltered(event);
    },
    [props]
  );
  const OnInvoicePurchaseOrdersChanged = useCallback(
    async (event) => {
      // if(event === null) return;
      // if(props.SelectedInvoicePurchaseOrder == event.value) return;
      // const invoice = props.InvoicePurchaseOrders.find((s) => {
      //     return (s.id === event.value);
      // });
      props.OnInvoicePurchaseOrdersFiltered(event);
    },
    [props]
  );
  const OnSuppliersChanged = useCallback(
    async (event) => {
      props.OnSuppliersFiltered(event);
    },
    [props]
  );
  useEffect(() => {
    let index = props.Commisions?.findIndex((val) => {
      if (val.value === props.SelectedCommission) {
        return true;
      }
      return false;
    });
    if (index > -1) {
      OnCommissionChanged(props.Commisions[index]);
    }
    index = props.InvoicePurchaseOrders?.findIndex((val) => {
      if (val.value === props.SelectedInvoicePurchaseOrder) {
        return true;
      }
      return false;
    });
    if (index > -1) {
      OnInvoicePurchaseOrdersChanged(props.InvoicePurchaseOrders[index]);
    }
    index = props?.Suppliers?.findIndex((val) => {
      if (val.value === props.SelectedSuppliers) {
        return true;
      }
      return false;
    });
    if (index > -1) {
      OnSuppliersChanged(props.Suppliers[index]);
    }
    // }, [OnCommissionChanged, OnInvoicePurchaseOrdersChanged, OnSuppliersChanged, inputValue, props.Commisions, props.InvoicePurchaseOrders, props.SelectedCommission, props.SelectedInvoicePurchaseOrder, props.SelectedSuppliers, props.Suppliers]);
  }, [
    OnCommissionChanged,
    OnInvoicePurchaseOrdersChanged,
    OnSuppliersChanged,
    inputValue,
    props.Commisions,
    props.InvoicePurchaseOrders,
    props.SelectedCommission,
    props.SelectedInvoicePurchaseOrder,
    props.SelectedSuppliers,
    props.Suppliers,
  ]);

  return (
    <Row>
      {/* fare tutto con l'autocomplete */}
      <Col sm="4">
        {/* nel dato della fattura avrò sempre il dato di un'azienda, che utilizzerò ne4i filtri */}
        {/* è un like */}
        <AutoComplete
          data={props.Commisions}
          isClearable={true}
          valueField={'id'}
          labelField={'value'}
          placeholder={'Filtra per codice commessa...'}
          value={props.SelectedCommission}
          ChangeValue={OnCommissionChanged}
        />
        {/* <FormSelectSearchNew
                    className="basic-single"
                    searchable={true}
                    isClearable={true}
                    // isLoading={isSelectSearchLoading}
                    isLoading={false}
                    placeholder={'Filtra per codice commessa...'}
                    // inputValue={inputValue.SelectedCommission}
                    defaultValue={props.SelectedCommission}
                    data={props.Commisions}
                    valueField={'id'}
                    labelField={['value']}
                    onChangeInput={OnCommissionTextChanged}
                    onSelect={OnCommissionChanged}></FormSelectSearchNew> */}
        {/* <InputGroup>
                    <InputGroupText htmlFor={'codCommessa'}>Cod. Commessa</InputGroupText>
                    <Input type={'text'} name={'codCommessa'} id={'codCommessa'} placeholder={'Cod. Commessa'} aria-describedby="lbl-cod-commessa" onChange={values}/>
                </InputGroup> */}
      </Col>
      <Col sm="4">
        {/* è un like cominciano sempre con "FO-" */}
        <AutoComplete
          data={props.InvoicePurchaseOrders}
          isClearable={true}
          valueField={'id'}
          labelField={'value'}
          placeholder={'Filtra per numero ordine...'}
          value={props.SelectedInvoicePurchaseOrder}
          ChangeValue={OnInvoicePurchaseOrdersChanged}
        />
        {/* <FormSelectSearchNew
                    className="basic-single"
                    searchable={true}
                    isClearable={true}
                    // isLoading={isSelectSearchLoading}
                    isLoading={false}
                    placeholder={'Filtra per numero ordine...'}
                    // inputValue={inputValue.SelectedInvoicePurchaseOrder}
                    defaultValue={props.SelectedInvoicePurchaseOrder}
                    data={props.InvoicePurchaseOrders}
                    valueField={'id'}
                    labelField={['value']}
                    onChangeInput={OnInvoicePurchaseOrdersTextChanged}
                    onSelect={OnInvoicePurchaseOrdersChanged}></FormSelectSearchNew> */}
        {/* <InputGroup>
                    <InputGroupText htmlFor={'nrOrdine'}>Nr. Ordine</InputGroupText>
                    <Input type={'text'} name={'nrOrdine'} id={'nrOrdine'} placeholder={'Nr. Ordine'} aria-describedby="lbl-nrOrdine" onChange={values}/>
                </InputGroup> */}
      </Col>
      {/* <Col sm="2">
                <InputGroup>
                    <InputGroupText htmlFor={'cdp'}>CDP</InputGroupText>
                    <Input type={'text'} name={'cdp'} id={'cdp'} placeholder={'CDP'} aria-describedby="lbl-CDP"/>
                </InputGroup>
            </Col> */}
      <Col sm="4">
        {/* {props.SelectedSuppliers} */}
        <AutoComplete
          data={props.Suppliers}
          isClearable={true}
          valueField={'id'}
          labelField={'value'}
          placeholder={'Filtra per fornitore...'}
          value={props.SelectedSuppliers}
          ChangeValue={OnSuppliersChanged}
        />
        {/* <FormSelectSearchNew
                    className="basic-single"
                    id={'supplier'}
                    searchable={true}
                    isClearable={true}
                    // isLoading={isSelectSearchLoading}
                    isLoading={false}
                    placeholder={'Filtra per fornitore...'}
                    // inputValue={inputValue.SelectedSuppliers}
                    value={props.SelectedSuppliers}
                    data={props.Suppliers}
                    valueField={'id'}
                    labelField={['value']}
                    onChangeInput={OnSuppliersTextChanged}
                    onSelect={OnSuppliersChanged}></FormSelectSearchNew> */}
        {/* <InputGroup>
                    <InputGroupText htmlFor={'fornitore'}>Fornitore</InputGroupText>
                    <Input type={'text'} name={'fornitore'} id={'fornitore'} placeholder={'Fornitore'} aria-describedby="lbl-fornitore" onChange={values}/>
                </InputGroup> */}
      </Col>
      {/* <Col sm="0">
                <Button color="primary" data-toggle="tooltip" data-placement="top" title="Cerca" onClick={search}><i className="fa fa-search"></i></Button>
            </Col> */}
      {/* <Col sm="0" className="ml-1">
                <Button color="secondary" ><span style={STYLES.noWrap}>Vedi commesse associate</span></Button>
            </Col> */}
      {props.Disabled && (
        <div
          style={{
            position: 'absolute',
            top: '5px',
            left: '5px',
            right: '5px',
            bottom: '5px',
            background: '#dddddd96',
          }}
        ></div>
      )}
    </Row>
  );
};

// const STYLES = {
//   noWrap: {
//     whiteSpace: 'nowrap'
//   }
// };

export default FilterCommesse;
