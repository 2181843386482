import Loader from 'components/layout/Loader';
import DataTable from 'components/shared/DataTable';
import ErrorMessage from 'components/shared/feedbacks/ErrorMessage';
import { format } from 'helpers/amount';
import { displayDate } from 'helpers/date';
import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { routeFactory } from 'routes';
import {
  exportSalesInvoicingRequests,
  fetchSalesInvoicingRequests,
} from 'services/api/sales-invoicing-requests/sales-invoicing-requests';

// La funzionalità renderà disponibile una maschera di ricerca con filtro per:
// •	Da/a data di emissione CDP
// •	Cliente
// •	Commessa
// •	Esportato ( con due opzioni: da esportare / esportato)

// Il risultato sarà la visualizzazione di una tabella che rappresenta i CDP emessi con le seguenti colonne:

// •	Commessa
// •	Ordine
// •	Nr CDP
// •	Tipo (Anticipo / SAL / svincolo garanzia)
// •	Cliente (PIVA)
// •	Data di emissione
// •	Importo (o acconto nel caso di anticipo, o svincolo gar.)
// •	Rit. Gar.
// •	Rideterminazione
// •	Esportato (da esportare / esportato)
// •	Link al dettaglio CDP : da cui l’operativo amministrativo può dedurre tutti i dettagli di righe ordine e importi necessari alla fatturazione

// Sulla maschera sarà presente un bottone “Esporta” che:

// •	chiederà se impostare come “esportate” le righe in oggetto del filtro (questa opzione è utile se si vogliono fare estrazioni a fini statistici senza impattare sull’operatività amministrativa)
// •	esporterà in Excel le righe come descritte sopra

const COMMISSION_DISPLAY_NAME_FIELD = 'commissionDisplayName';
const CUSTOMER_DISPLAY_NAME_FIELD = 'customerDisplayName';

const styles = {
  tableCell: {
    verticalAlign: 'middle',
  },
  amountTableCell: {
    textAlign: 'right',
  },
  textTruncatedTableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const COLUMNS = [
  {
    heading: 'Commessa',
    value: COMMISSION_DISPLAY_NAME_FIELD,
    type: 'text',
    style: { ...styles.tableCell, ...styles.textTruncatedTableCell, maxWidth: '150px' },
    filterable: true,
    sortable: false,
    cellTemplate: (commissionDisplayName, row) => <span title={commissionDisplayName}>{row.commissionId}</span>,
  },
  {
    heading: 'Ordine',
    value: 'salesOrderId',
    type: 'text',
    style: { ...styles.tableCell, ...styles.textTruncatedTableCell, maxWidth: '150px' },
    filterable: false,
    sortable: false,
    cellTemplate: (orderDisplayName) => <span title={orderDisplayName}>{orderDisplayName}</span>,
  },
  {
    heading: 'Nr. CDP',
    value: 'paymentCertificateNumber',
    type: 'text',
    style: { ...styles.tableCell, textAlign: 'right' },
    filterable: false,
    sortable: false,
  },
  {
    heading: 'Tipo',
    value: 'paymentCertificateTypeId',
    type: 'text',
    style: { ...styles.tableCell, ...styles.textTruncatedTableCell, maxWidth: '150px', minWidth: '150px' },
    filterable: false,
    sortable: false,
    cellTemplate: (paymentCertificateTypeDisplayName) => (
      <span title={paymentCertificateTypeDisplayName}>{paymentCertificateTypeDisplayName}</span>
    ),
  },
  {
    heading: 'Cliente',
    value: CUSTOMER_DISPLAY_NAME_FIELD,
    type: 'text',
    style: { ...styles.tableCell, ...styles.textTruncatedTableCell, maxWidth: '150px' },
    filterable: true,
    sortable: false,
    cellTemplate: (customerDisplayName) => <span title={customerDisplayName}>{customerDisplayName}</span>,
  },
  {
    heading: 'Data emissione',
    value: 'paymentCertificateDate',
    type: 'date',
    style: { ...styles.tableCell },
    filterable: true,
    sortable: false,
    cellTemplate: (paymentCertificateDate) => displayDate(paymentCertificateDate),
  },
  {
    heading: 'Importo (€)',
    value: 'amount',
    type: 'number',
    style: { ...styles.tableCell, ...styles.amountTableCell },
    filterable: false,
    sortable: false,
    cellTemplate: (amount) => format(amount),
  },
  {
    heading: 'Rit. garanzia (€)',
    value: 'guaranteeWithholdingAmount',
    type: 'number',
    style: { ...styles.tableCell, ...styles.amountTableCell },
    filterable: false,
    sortable: false,
    cellTemplate: (guaranteeWithholdingAmount) => format(guaranteeWithholdingAmount),
  },
  {
    heading: 'Rideterminazione (€)',
    value: 'redeterminationAmount',
    type: 'number',
    style: { ...styles.tableCell, ...styles.amountTableCell },
    filterable: false,
    sortable: false,
    cellTemplate: (redeterminationAmount) => format(redeterminationAmount),
  },
  {
    heading: 'Anticipo (€)',
    value: 'advanceAmount',
    type: 'number',
    style: { ...styles.tableCell, ...styles.amountTableCell },
    filterable: false,
    sortable: false,
    cellTemplate: (advanceAmount) => format(advanceAmount),
  },
  {
    heading: 'Esportato',
    value: 'exported',
    type: 'select',
    options: [
      { id: 'false', description: 'Non esportati' },
      { id: 'true', description: 'Già esportati' },
    ],
    style: { ...styles.tableCell, textAlign: 'center' },
    filterable: true,
    sortable: false,
    cellTemplate: (exported) => (exported ? <i className="fa fa-check text-success" /> : null),
  },
  {
    heading: '',
    value: 'paymentCertificateId',
    // type: 'text',
    style: { ...styles.tableCell, textAlign: 'center' },
    filterable: false,
    sortable: false,
    cellTemplate: (_, row) => (
      <NavLink
        to={routeFactory.commissionManagement.salesOrders.paymentCertificates.detail(
          row.commissionId,
          row.salesOrderId,
          row.paymentCertificateNumber
        )}
        target="_blank"
        className="small text-uppercase"
        title={`Visualizza CDP nr. ${row.paymentCertificateNumber} dell'ordine ${row.salesOrderId}`}
      >
        Visualizza
      </NavLink>
    ),
  },
];

const TABLE_COLUMNS_FILTER_NAMES_MAPPING = {
  [CUSTOMER_DISPLAY_NAME_FIELD]: 'customerSearchText',
  [COMMISSION_DISPLAY_NAME_FIELD]: 'commissionSearchText',
};

const PAGE_SIZES = [20 /*, 50, 100*/];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[0];

const mapSalesInvoicingRequest = (item) => ({
  ...item,
  paymentCertificateDate: new Date(item.paymentCertificateDate),
  exported: { description: item.exported }, // ciocco per bypassare il DataTable
});

const PageTitle = ({ text }) => <h1 className="h3 mb-0 text-gray-800">{text}</h1>;

const ExportButton = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen((isOpen) => !isOpen)} direction="down">
      <DropdownToggle caret={false} color="primary" size="sm" className="shadow-sm">
        <i className="fas fa-download fa-sm text-white-50"></i> Genera Excel
      </DropdownToggle>
      <DropdownMenu className="shadow-sm">
        <DropdownItem onClick={() => onClick({ markAsExported: false })} className="px-3">
          <i className="fa fa-download" /> Scarica
        </DropdownItem>
        <DropdownItem onClick={() => onClick({ markAsExported: true })} className="px-3">
          <i className="fas fa-check-double" /> Scarica e imposta come esportati
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const SalesInvoicingRequestsIndexPage = () => {
  const [tableRows, setTableRows] = useState();
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const { error } = useFeedback();

  const { company } = useAuth();
  const companyId = company.id;

  const [tableOptions, setTableOptions] = useState({ filters: { companyId }, offset: 0, limit: DEFAULT_PAGE_SIZE });
  const currentPageIndex = tableOptions.offset / tableOptions.limit;

  useEffect(() => {
    setLoading(true);
    const { filters, offset, limit } = tableOptions;
    fetchSalesInvoicingRequests(filters, offset, limit)
      .then((response) => {
        if (response.ok) {
          const { items, count } = response.data;
          setTableRows(items.map(mapSalesInvoicingRequest));
          setTotalRowsCount(count);
          setErrorMessage();
        } else {
          setErrorMessage(response.message);
        }
      })
      .finally(() => setLoading(false));
  }, [tableOptions]);

  const handlePageChange = (pageIndex) => {
    setTableOptions((options) => ({ ...options, offset: pageIndex * options.limit }));
  };

  const handleFilterChange = (value, fieldName, fieldType) => {
    setTableOptions((options) => {
      const filterName = TABLE_COLUMNS_FILTER_NAMES_MAPPING[fieldName] || fieldName;
      const currentValue = options.filters[filterName];

      let newValue;
      switch (fieldType) {
        case 'text':
          newValue = value?.trim() || undefined;
          break;
        case 'select':
        case 'date':
          newValue = value || undefined;
          break;
        default:
          newValue = currentValue;
      }

      console.debug({ eventType: 'FILTER_CHANGE', fieldType, filterName, currentValue, value, newValue });

      if (newValue === currentValue) {
        return options;
      }
      return {
        ...options,
        offset: 0,
        filters: newValue
          ? { ...options.filters, [filterName]: newValue }
          : Object.keys(options.filters)
              .filter((k) => k !== filterName)
              .reduce((filters, k) => {
                filters[k] = options.filters[k];
                return filters;
              }, {}),
      };
    });
  };

  const handleExportClick = ({ markAsExported }) => {
    const { filters } = tableOptions;
    setLoading(true);
    exportSalesInvoicingRequests(filters, markAsExported)
      .then(() => {
        if (markAsExported) {
          setTableRows((rows) =>
            rows.map((row) => (row.exported.description ? row : { ...row, exported: { description: true } }))
          );
        }
      })
      .catch((err) => error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div className="d-sm-flex align-items-center justify-content-between mb-1">
        <PageTitle text="Richieste di fatturazione clienti" />
        <div>
          <ExportButton onClick={handleExportClick} />
        </div>
      </div>
      {loading && <Loader />}
      {errorMessage && <ErrorMessage text={errorMessage} />}
      {tableRows && (
        <DataTable
          column={COLUMNS}
          data={tableRows}
          currentPage={currentPageIndex}
          totRecords={totalRowsCount}
          pageSizes={PAGE_SIZES}
          pageSize={tableOptions.limit}
          onPageChange={handlePageChange}
          onHandleSearch={handleFilterChange}
          responsive
          size="sm"
        />
      )}
    </div>
  );
};

export default SalesInvoicingRequestsIndexPage;
