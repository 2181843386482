import Loader from 'components/layout/Loader';
import { ADVANCE, TOTAL_PRODUCTION, WARRANTY_RELEASE } from 'constants/payment-certificate-types';
import { formatNumber } from 'helpers/formatters';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { routeFactory } from 'routes';
import { createPaymentCertificate, fetchSalesOrderPaymentCertificates } from 'services/api/sales-order';
import SalesOrderSummary from '../components/SalesOrderSummary';
import PaymentCertificatesTable from '../components/list';
import { roundNumber } from 'helpers/numbers';
import PaymentCertificateForm from '../components/form';
import useInvalidateSalesOrder from '../../hooks/useInvalidateSalesOrder';
import useEnterEditMode from 'hooks/forms/useEnterEditMode';

const fetchInitialData = (salesOrderId) => {
  return fetchSalesOrderPaymentCertificates(salesOrderId);
};

const SalesOrderNewPaymentCertificatePage = () => {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const invalidate = useInvalidateSalesOrder();

  const { commissionId, salesOrderId, ...otherParams } = useParams();
  const paymentCertificateId = parseInt(otherParams.paymentCertificateId);

  const navigate = useNavigate();
  const { success, error, warning } = useFeedback();

  useEnterEditMode();

  const handleOnFormSubmit = async (formData) => {
    if ([ADVANCE, TOTAL_PRODUCTION].includes(formData.type) && formData.amount > model.amountRemainder) {
      warning(
        `${
          formData.type === ADVANCE ? "L'anticipo" : 'Il totale produzione'
        } non può superare l'importo dell'ordine. Max. ${formatNumber(model.amountRemainder)}€`
      );
      return;
    }

    if (
      formData.type === TOTAL_PRODUCTION &&
      formData.advanceWithholdingAmount > model.advanceWithholdingAmountRemainder
    ) {
      warning(
        `Le ritenute di anticipo non possono superare l'anticipo. Max. ${formatNumber(
          model.advanceWithholdingAmountRemainder
        )}€`
      );
      return;
    }

    const postData = {
      ...formData,
      paymentCertificateId,
    };
    const response = await createPaymentCertificate(salesOrderId, postData);
    if (response.ok) {
      invalidate();
      success(response.message);
      navigate(routeFactory.commissionManagement.salesOrders.paymentCertificates.index(commissionId, salesOrderId));
    } else {
      error(response.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchInitialData(salesOrderId)
      .then((response) => {
        console.log(response);
        if (response.ok) {
          setModel({
            ...response.data,
            paymentCertificates: response.data.paymentCertificates.map((pc) => ({ ...pc, viewable: false })),
          });
        } else {
          setErrorMessage(response.errorMessage);
        }
      })
      .finally(() => setLoading(false));
  }, [salesOrderId]);

  return (
    <>
      {loading && <Loader />}
      {model && (
        <>
          <Row className="mb-4">
            <Col xs={10}>
              <h3>Certificato di pagamento n° {paymentCertificateId}</h3>
            </Col>
            <Col xs={2} className="text-right">
              <NavLink
                to={routeFactory.commissionManagement.salesOrders.paymentCertificates.index(commissionId, salesOrderId)}
                style={{ fontSize: '2rem' }}
                title={`Torna all'elenco dei certificati di pagamento dell'ordine ${salesOrderId}`}
                className="text-danger"
              >
                &times;
              </NavLink>
            </Col>
          </Row>
          <SalesOrderSummary order={model} />
          <div className="mb-4">
            <h4 className="mb-3">Certificati emessi</h4>
            {model.paymentCertificates.length > 0 ? (
              <PaymentCertificatesTable
                paymentCertificates={model.paymentCertificates}
                guaranteeWithholdingTableHeaderText={model.guaranteeWithholdingTableHeaderText}
              />
            ) : (
              <p>Non sono ancora stati creati certificati di pagamento per quest'ordine.</p>
            )}
          </div>
          <PaymentCertificateForm
            initialData={{
              type:
                model.amountRemainder > 0 || model.advanceWithholdingAmountRemainder > 0
                  ? TOTAL_PRODUCTION
                  : WARRANTY_RELEASE,
              amount:
                model.amountRemainder > 0 || model.advanceWithholdingAmountRemainder > 0
                  ? model.amountRemainder
                  : model.guaranteeWithholdingAmountRemainder,
              advanceWithholdingPercentage:
                model.advanceWithholdingAmountRemainder > 0
                  ? model.paymentCertificates[0]?.advancePercentage ?? 0
                  : undefined,
              advanceWithholdingAmount:
                model.advanceWithholdingAmountRemainder > 0
                  ? roundNumber(
                      model.amountRemainder * ((model.paymentCertificates[0]?.advancePercentage ?? 0) / 100),
                      2
                    )
                  : 0,
            }}
            advanceTypeAllowed={paymentCertificateId === 1}
            amountRemainder={model.amountRemainder}
            guaranteeWithholdingAmountRemainder={model.guaranteeWithholdingAmountRemainder}
            advanceWithholdingAmountRemainder={model.advanceWithholdingAmountRemainder}
            guaranteeWithholding={{
              typeId: model.guaranteeWithholdingId,
              percentage: model.guaranteeWithholdingPercentage,
            }}
            canCreate={model.canCreate}
            onSubmit={handleOnFormSubmit}
          />
        </>
      )}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </>
  );
};

export default SalesOrderNewPaymentCertificatePage;
