import { formatNumber } from 'helpers/formatters';
import { Col, Row, Table } from 'reactstrap';
import * as INVOICE_ASSOCIATION_STATUS from 'constants/invoice-association-statuses';
import * as INVOICE_ASSOCIATION_LINE_STATUS from 'constants/invoice-association-line-statuses';
import { stripHtml } from 'helpers/text';

// https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript#answer-17743990
const formatDate = (date) => date.toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' });

const AssociationItemLines = ({ associationId, lines }) => (
  <Table bordered>
    <thead>
      <tr>
        <th>Attività</th>
        <th>UdM</th>
        <th>Qta</th>
        <th>Prezzo unitario (€)</th>
        <th>Importo ordine (€)</th>
        <th>Importo fattura (€)</th>
      </tr>
    </thead>
    <tbody>
      {lines.map((invoiceAssociationLine, index) => (
        <tr
          key={`invoiceAssociationLine-${associationId}-${index}`}
          className={
            invoiceAssociationLine.statusId === INVOICE_ASSOCIATION_LINE_STATUS.APPROVED
              ? 'bg-success text-white'
              : undefined
          }
        >
          <td style={{ maxWidth: '350px' }}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {stripHtml(invoiceAssociationLine.description)}
            </div>
          </td>
          <td>{invoiceAssociationLine.unitOfMeasure}</td>
          <td className="text-right">{formatNumber(invoiceAssociationLine.quantity)}</td>
          <td className="text-right">{formatNumber(invoiceAssociationLine.unitAmount)}</td>
          <td className="text-right">
            {formatNumber(invoiceAssociationLine.orderAmount)}
            {/* <div>
              <small>Rimanenza: {formatNumber(invoiceAssociationLine.orderAmountRemainder)}</small>
            </div> */}
          </td>
          <td
            className={
              'text-right' +
              ([INVOICE_ASSOCIATION_STATUS.TO_HANDLE, INVOICE_ASSOCIATION_STATUS.WAITING_FOR_APPROVAL].includes(
                invoiceAssociationLine.statusId
              ) && invoiceAssociationLine.invoiceAmount > invoiceAssociationLine.orderAmountRemainder
                ? '' //' text-danger'
                : '')
            }
          >
            {formatNumber(invoiceAssociationLine.invoiceAmount)}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const SalesInvoiceAssociationItem = ({ invoiceAssociation }) => {
  return (
    <>
      {/* <pre>{JSON.stringify(invoiceAssociation, undefined, '\t')}</pre> */}
      {/* 1ma riga intestazioni fattura: nr, data, cliente + visualizza fattura */}
      <Row>
        <Col xs={9}>
          <span className="mr-2">
            Nr. Fatt. cliente: <b>{invoiceAssociation.invoiceNumber}</b>
          </span>
          <span className="mr-2">
            del <b>{formatDate(invoiceAssociation.invoiceDate)}</b>
          </span>
          <span className="mr-2">
            Cliente: <b>{invoiceAssociation.invoiceCustomerDisplayName}</b>
          </span>
          <span>
            P.IVA: <b>{invoiceAssociation.invoiceCustomerVatNumber}</b>
          </span>
        </Col>
        {invoiceAssociation.hasAttachment && (
          <Col xs={2} className="offset-1 text-center">
            <a
              href={`/api/sales-invoices/${invoiceAssociation.salesInvoiceId}/attachment`}
              target="_blank"
              rel="noreferrer"
            >
              Visualizza fattura
            </a>
          </Col>
        )}
      </Row>
      {/* 2nda riga intestazioni fattura: importi + alert + azioni */}
      <Row>
        <Col xs={9}>
          <div className="text-right">
            Importo fattura: <b>{formatNumber(invoiceAssociation.invoiceAmount)}€</b>
          </div>
          <div className="text-right">
            Importo associato: <b>{formatNumber(invoiceAssociation.amountAssociatedWithInvoice)}€</b>
          </div>
        </Col>
        <Col xs={1}>
          {invoiceAssociation.alert && (
            <i
              className="fa fa-2x fa-exclamation-triangle text-warning"
              style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
            />
          )}
        </Col>
        <Col xs={2}>
          <div style={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translate(-50%,0)' }}>
            {invoiceAssociation.statusId === INVOICE_ASSOCIATION_STATUS.APPROVED && (
              <i className="fa fa-2x fa-check mr-3 text-success" title="Approvato" />
            )}
          </div>
        </Col>
      </Row>
      {/* Informazioni CDP + tabella associazioni */}
      <Row>
        <Col xs={8} className="offset-1">
          {/* Informazioni CDP */}
          {invoiceAssociation.paymentCertificateNumber && (
            <div>
              CDP nr {invoiceAssociation.paymentCertificateNumber} -{' '}
              {formatDate(invoiceAssociation.paymentCertificateDate)}
            </div>
          )}
          {/* Tabella associazioni */}
          <AssociationItemLines associationId={invoiceAssociation.id} lines={invoiceAssociation.lines} />
        </Col>
      </Row>
    </>
  );
};

export default SalesInvoiceAssociationItem;
