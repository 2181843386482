import { parseDateFromFilter, parseDateToFilter } from "helpers/filters";

const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: "Si è verificato un errore",
      };
  }
};

const fetchSalesInvoices = async (params, filters) => {
  
  const queryParams = {
    CompanyId: params.CompanyId,
    CurrentPage: params.CurrentPage,
    NumRecords: params.NumRecords,
    SortColumn: params.SortColumn,
    Sort: params.Sort,
    BusinessNameFilter: filters?.businessName,
    InvoiceStatusFilter: filters?.statusId?.id,
    InvoiceNumberFilter: filters?.invoiceNumber,
    EmissionDateInvoiceFromFilter: parseDateFromFilter(filters?.emissionDateFrom),
    EmissionDateInvoiceToFilter: parseDateToFilter(filters?.emissionDateTo),
    RegistrationDateFromFilter: parseDateFromFilter(filters?.registrationDateFrom),
    RegistrationDateToFilter: parseDateToFilter(filters?.registrationDateTo),
  };
  console.log(queryParams)
  // rimuovere i parametri con valori undefined o null
  const validParams = {};
  for (const key in queryParams) {
    if (queryParams[key] !== undefined && queryParams[key] !== null) {
      validParams[key] = queryParams[key];
    }
  }

  // console.log(validParams)
  const queryString = new URLSearchParams(validParams).toString();
  const response =  await fetch(`/api/sales-invoices?${queryString}`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
    return handleErrorResponse(response);
};

const fetchSalesInvoiceStatus = async () => {
    const response =  await fetch(`/api/sales-invoices/status`);
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
      return handleErrorResponse(response);
  };

  export {
    fetchSalesInvoices,
    fetchSalesInvoiceStatus
  }
