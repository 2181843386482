import { useState } from 'react';
import CurrencyInput, { formatValue } from "react-currency-input-field";

const CustomCurrencyInput = (props) => {  
    const [customValue, setCustomValue] = useState(props.value);
    
    const onKeyDown = (event) => {
        if(event.key === "." && event.target.value.indexOf(",") < 0) {
            event.preventDefault();
            valueChange(event.target.value.replaceAll(".", "") + ",", formatValue({ value: event.target.value + ",", groupSeparator: ',', decimalSeparator: '.', disableGroupSeparators: false, prefix: '' }));
        }
    }
    const valueChange = (value, name, _values) => {
        // verifica anche la formattazione possibile del valore
        // if((customValue !== undefined) && (value === customValue || value === customValue + ",00" || value === customValue.toString().replace(".", ","))) return;
        setCustomValue(value);
        if(props.onValueChange !== undefined)
            props.onValueChange(value, name, _values);
    }
    return (<CurrencyInput {...props} onKeyDown={onKeyDown} onValueChange={valueChange} value={customValue} />);
};

export default CustomCurrencyInput;