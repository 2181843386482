import ReadOnlyDecimalNumber from 'components/utils/ReadOnlyDecimalNumber';
import { stringifyPeriod } from 'helpers/budget';
import { Button, Input } from 'reactstrap';
import { Fragment } from 'react';

import styles from 'components/commission-management/budget/BudgetTableStyles';
import BudgetTableNumberInput from './BudgetTableNumberInput';
import ReadOnlyLock from 'components/utils/ReadOnlyLock';

const BudgetTableSubactivityRow = ({
  subactivity,
  periods,
  collapsed,
  readonly,
  displayCostError = true,
  onCostChange,
  onRevenueChange,
  onPeriodCostChange,
  onPeriodRevenueChange,
  onDescriptionChange,
  onRemove,
  onComplete,
  canComplete = false,
}) => {
  const budgetedCost = subactivity.costRevenueProspectus
    .filter(
      (p) =>
        periods.some((period) => period.year === p.period.year && period.month === p.period.month) && !isNaN(p.cost)
    )
    .map((p) => p.cost)
    .reduce((tot, cost) => tot + cost, 0);
  const budgetedRevenue = subactivity.costRevenueProspectus
    .filter(
      (p) =>
        periods.some((period) => period.year === p.period.year && period.month === p.period.month) && !isNaN(p.revenue)
    )
    .map((p) => p.revenue)
    .reduce((tot, revenue) => tot + revenue, 0);
  const residualCost = (isNaN(subactivity.cost) ? 0 : subactivity.cost) - budgetedCost;
  const residualRevenue = (isNaN(subactivity.revenue) ? 0 : subactivity.revenue) - budgetedRevenue;

  const descriptionChangeHandler = (e) => {
    onDescriptionChange?.(e.target.value);
  };

  const handleOnCostChange = (e) => {
    onCostChange?.(e.value);
  };

  const handleOnRevenueChange = (e) => {
    onRevenueChange?.(e.value);
  };

  const removeEnabled = !readonly && !subactivity.readonly;
  const descriptionChangeEnabled = !readonly && !subactivity.readonly;
  const costRevenueChangeEnabled = !readonly && !subactivity.completed;
  const canCompleteSubactivity = readonly && subactivity.completable && canComplete;

  const expenseExceedsCost =
    !isNaN(subactivity.minimumCost) && (isNaN(subactivity.cost) ? 0 : subactivity.cost) < subactivity.minimumCost;

  const handleFocusToAdjustScroll = (e) => {
    const table = document.getElementsByTagName('table')[0];
    const container = table.parentNode;
    const inputCell = e.nativeEvent.target.parentNode.parentNode;
    
    const offsetStart = table
      .getElementsByTagName('thead')[0]
      .getElementsByTagName('tr')[0].children[7].offsetLeft;

    const delta = inputCell.offsetLeft - offsetStart;

    if (delta < container.scrollLeft) {
      container.scrollLeft = delta;
    }
  };

  return (
    <tr
      className={
        styles['subactivity-row'] +
        ' ' +
        styles['subactivity-row--completed'] +
        ' ' +
        (subactivity.readonly ? ' ' + styles['subactivity-row--readonly'] : '')
      }
    >
      <td className="text-center">
        {removeEnabled && (
          <Button color="outline-danger" size="sm" block onClick={(e) => onRemove()}>
            Rimuovi
          </Button>
        )}
      </td>
      <td className={styles['subactivity-code-cell']}>
        {subactivity.activityId}.{subactivity.id}
      </td>
      <td className={styles['subactivity-lock-cell']}>
        {canCompleteSubactivity ? (
          <Button type="button" size="sm" color="outline-success" onClick={() => onComplete()}>
            <i className="fa fa-unlock"></i>
          </Button>
        ) : (
          <ReadOnlyLock locked={subactivity.completed} />
        )}
      </td>
      <td className={styles['description-cell']}>
        {descriptionChangeEnabled ? (
          <Input type="text" bsSize="sm" value={subactivity.description} onChange={descriptionChangeHandler} />
        ) : (
          subactivity.description
        )}
      </td>
      {/* <td></td> */}
      <td
        className={`${styles['td-cost']} ${styles['td--numeric']}${
          displayCostError && expenseExceedsCost ? ' ' + styles['td-cost--invalid'] : ''
        }`}
      >
        {costRevenueChangeEnabled ? (
          <>
            <BudgetTableNumberInput
              value={subactivity.cost}
              onChange={handleOnCostChange}
              invalid={displayCostError && expenseExceedsCost}
              tooltip={
                displayCostError &&
                expenseExceedsCost &&
                `Le spese previste o effettive per questa attività ammontano a ${subactivity.minimumCost}€. Non è possibile impostare un importo minore.`
              }
            />
          </>
        ) : (
          <ReadOnlyDecimalNumber n={subactivity.cost} />
        )}
      </td>
      <td className={styles['td-revenue'] + ' ' + styles['td--numeric']}>
        {costRevenueChangeEnabled ? (
          <BudgetTableNumberInput value={subactivity.revenue} onChange={handleOnRevenueChange} />
        ) : (
          <ReadOnlyDecimalNumber n={subactivity.revenue} />
        )}
      </td>
      <td className={styles['td-residue'] + ' ' + styles['td--numeric']}>
        <ReadOnlyDecimalNumber n={residualCost} />
      </td>
      <td className={styles['td-residue'] + ' ' + styles['td--numeric']}>
        <ReadOnlyDecimalNumber n={residualRevenue} />
      </td>
      {!collapsed &&
        periods.map((period) => {
          const prospectusPeriod = subactivity.costRevenueProspectus.find(
            (p) => p.period.year === period.year && p.period.month === period.month
          );
          return (
            <Fragment key={stringifyPeriod(period)}>
              <td className={styles['td-cost'] + ' ' + styles['td--numeric']}>
                {costRevenueChangeEnabled ? (
                  <BudgetTableNumberInput
                    value={prospectusPeriod?.cost}
                    onChange={(e) => onPeriodCostChange?.(period, e.value)}
                    onFocus={handleFocusToAdjustScroll}
                  />
                ) : (
                  <ReadOnlyDecimalNumber n={prospectusPeriod?.cost} />
                )}
              </td>
              <td className={styles['td-revenue'] + ' ' + styles['td--numeric']}>
                {costRevenueChangeEnabled ? (
                  <BudgetTableNumberInput
                    value={prospectusPeriod?.revenue}
                    onChange={(e) => onPeriodRevenueChange?.(period, e.value)}
                    onFocus={handleFocusToAdjustScroll}
                  />
                ) : (
                  <ReadOnlyDecimalNumber n={prospectusPeriod?.revenue} />
                )}
              </td>
            </Fragment>
          );
        })}
    </tr>
  );
};

export default BudgetTableSubactivityRow;
