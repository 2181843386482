import { useEffect, useState } from 'react';
import { fetchTerritoriesV2 } from 'services/api/territory';

const useTerritoriesOptions = () => {
  const [territoriesOptions, setTerritoriesOptions] = useState([]);

  // fetch territories options
  useEffect(() => {
    fetchTerritoriesV2()
      .then((territories) =>
        setTerritoriesOptions(territories.map((t) => ({ id: t.territoryId, description: t.name })))
      )
      .catch((err) => {
        // do nothing on failure
      });
  }, []);

  return { territoriesOptions };
};

export default useTerritoriesOptions;
