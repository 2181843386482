import { Card, CardBody, CardHeader } from 'reactstrap';
import { CustomerSummary } from './CustomerSummary';

export const CustomerSummaryCard = ({
  className,
  name,
  address,
  postalCode,
  taxCode,
  municipality,
  paymentTermName,
  vatNumber,
}) => (
  <Card className={className}>
    <CardHeader>
      Soggetto particolare selezionato: <b>{name}</b>
    </CardHeader>
    <CardBody className="p-0">
      <CustomerSummary
        address={address}
        postalCode={postalCode}
        taxCode={taxCode}
        municipality={municipality}
        paymentTermName={paymentTermName}
        vatNumber={vatNumber}
      />
    </CardBody>
  </Card>
);
