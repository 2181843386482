const searchSuppliers = (companyId, searchText = '', offset = 0, limit = 20) => {
  return fetchSuppliers({ companyId, searchText }, offset, limit);
};

// searchParams e.g. = { companyId: 'DI', searchText: 'ENG' }
const fetchSuppliers = async (searchFilters = {}, offset = 0, limit = 20) => {
  try {
    const response = await fetch(`/api/suppliers?${new URLSearchParams({ ...searchFilters, offset, limit })}`);

    const data = await response.json();

    const result = {
      ok: response.ok,
      status: response.status,
      data,
    };

    if (!response.ok) {
      result.message = data.detail || data.title || 'Si è verificato un errore';
    }

    return result;
  } catch (err) {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const fetchSupplierWithId = async (supplierId) => {
  try {
    const response = await fetch(`/api/suppliers/${supplierId}`);
    const data = await response.json();
    const result = {
      ok: response.ok,
      status: response.status,
      data,
    };

    if (!response.ok) {
      result.message = data.detail || data.title || 'Si è verificato un errore';
    }
    return result;
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

export { searchSuppliers, fetchSupplierWithId };
