const handleErrorResponse = (response) => {
    switch (response.status) {
      case 401:
        return {
          success: false,
          authenticated: false,
        };
      case 403:
        return {
          success: false,
          authenticated: true,
          authorized: false,
        };
        case 404:
          return {
            success: false,
            authenticated: true,
            authorized: true,
            message:"Il file richiesto non esiste o è stato rimosso"
          };
      case 500:
      case 504:
      default:
        return {
          success: false,
          message: 'Si è verificato un errore',
        };
    }
  };

  const fetchPurchaseOrderAttachments = async (purchaseOrderId) => {
    const response =  await fetch(`/api/purchase-order/${purchaseOrderId}/attachments`);
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
      return handleErrorResponse(response);
  };

  const insertPurchaseOrderAttachment = async (purchaseOrderId, postData) => {
    const response =  await fetch(`/api/purchase-order/${purchaseOrderId}/attachments`, { method: 'POST', body: postData });
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
      return handleErrorResponse(response);
  };

  const deletePurchaseOrderAttachment = async (purchaseOrderId, attachmentId) => {
    const response = await fetch(`/api/purchase-order/${purchaseOrderId}/attachments/${attachmentId}`, { method: 'DELETE' })
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true
      };
    }
    return handleErrorResponse(response);
  };

  const updatePurchaseOrderAttachment = async (purchaseOrderId, attachmentId, postData) => {
    
    const response =  await fetch(`/api/purchase-order/${purchaseOrderId}/attachments/update/${attachmentId}`, { method: 'PUT', body: postData });
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true
      };
    }
      return handleErrorResponse(response);
  };

  export {
    fetchPurchaseOrderAttachments,
    insertPurchaseOrderAttachment,
    updatePurchaseOrderAttachment,
    deletePurchaseOrderAttachment
}