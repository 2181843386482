import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ROUTES from 'routes';
import { getInvoicesWithFilters } from 'services/api/invoices';
import DataTable from 'components/shared/DataTable';
import Loader from 'components/layout/Loader';
import useFeedback from 'hooks/useFeedback';
import useAuth from 'hooks/useAuth';
import { formatISODate, formatNumber } from 'helpers/formatters';
import InvoiceStatusBadge from 'pages/supplier-invoices-management/components/purchase-invoice-status-badge';

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];
const SupplierInvoicesManagementIndexPage = () => {
  const { error } = useFeedback();
  const { company } = useAuth();
  const [sort, setSort] = useState({ column: '', direction: '' });
  // const [ search, setSearch ] = useState({ column: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(0);
  const [numRecords, setNumRecords] = useState(INITIAL_PAGE_SIZE);
  const [filters, setFilters] = useState({
    FilterFornitore: false,
    FilterFornitoreValue: '',
    FilterRagSoc: false,
    FilterRagSocValue: '',
    FilterPiva: false,
    FilterPivaValue: '',
    FilterStatus: false,
    FilterStatusValue: '',
    FilterDataFatturaFromStatus: false,
    FilterDataFatturaFromStatusValue: '',
    FilterDataFatturaToStatus: false,
    FilterDataFatturaToStatusValue: '',
    FilterDataRegistrazioneFromStatus: false,
    FilterDataRegistrazioneFromStatusValue: '',
    FilterDataRegistrazioneToStatus: false,
    FilterDataRegistrazioneToStatusValue: '',
  });
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();

  const headerDatagrid = [
    {
      heading: 'Nr. Fattura',
      value: 'fatturaFornitore',
      type: 'text',
      filterable: true,
      sortable: true,
      style: { maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
    },
    {
      heading: 'Data fattura',
      value: 'dataFattura',
      type: 'date',
      filterable: true,
      sortable: true,
      cellTemplate: (val) => {
        return formatISODate(val);
      },
    },
    {
      heading: 'Rag. soc. fornitore',
      value: 'ragioneSociale',
      type: 'text',
      filterable: true,
      sortable: true,
      options: null,
      style: { maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
    },
    {
      heading: 'P.IVA',
      value: 'piva',
      type: 'text',
      filterable: true,
      sortable: true,
      options: null,
    },
    {
      heading: 'Importo (€)',
      value: 'importo',
      type: 'text',
      filterable: false,
      sortable: true,
      options: null,
      cellTemplate: (val) => {
        return <span style={{ float: 'right' }}>{formatNumber(val)}</span>;
      },
    },
    {
      heading: 'Totale Associato',
      value: 'totAssociato',
      type: 'text',
      filterable: false,
      sortable: false,
      options: null,
      cellTemplate: (val) => {
        return <span style={{ float: 'right' }}>{formatNumber(val)}</span>;
      },
    },
    {
      heading: 'Data registrazione',
      value: 'dataRegistrazione',
      type: 'date',
      filterable: true,
      sortable: true,
      options: null,
      cellTemplate: (val) => {
        return formatISODate(val);
      },
    },
    {
      heading: 'Stato',
      value: 'status',
      type: 'select',
      filterable: true,
      sortable: true,
      // TODO: recuperare questi stati da database?!
      options: [
        { id: 0, description: 'Da gestire' },
        { id: 1, description: 'In attesa di approvazione' },
        { id: 2, description: 'Gestita' },
      ],
      cellTemplate: (statusName, invoice) => (
        <InvoiceStatusBadge statusId={invoice.status.id} statusName={statusName} />
      ),
    },
    {
      heading: '',
      value: 'hasAttachment',
      filterable: false,
      sortable: false,
      style: { textAlign: 'center' },
      cellTemplate: (hasAttachment, invoice) =>
        hasAttachment && (
          <a
            href={`/api/invoice/getattachment?invoiceId=${invoice.purchaseInvoiceId}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-file" />
          </a>
        ),
    },
    {
      heading: '',
      value: 'purchaseInvoiceId',
      type: 'text',
      filterable: false,
      sortable: false,
      options: null,
      style: { textAlign: 'center' },
      cellTemplate: (val, item) => {
        return (
          <NavLink to={ROUTES.SUPPLIER_INVOICES_MANAGEMENT.MANAGE.replace(':purchaseInvoiceId', val)} className="small">
            Visualizza
          </NavLink>
        );
      },
    },
  ];
  //Gestisce l'ordinamento
  const onHandleSort = (columnName) => {
    let newDirection = sort.direction === '' ? 'ASC' : sort.direction === 'ASC' ? 'DESC' : '';
    setSort({ column: columnName, direction: newDirection });
    setCurrentPage(0);
  };

  //Gestisce la ricerca
  const onHandleSearch = (value, field, type, newPage) => {
    switch (field) {
      case 'fatturaFornitore':
        setFilters({ ...filters, FilterFornitore: value !== '', FilterFornitoreValue: value });
        break;
      case 'ragioneSociale':
        setFilters({ ...filters, FilterRagSoc: value !== '', FilterRagSocValue: value });
        break;
      case 'piva':
        setFilters({ ...filters, FilterPiva: value !== '', FilterPivaValue: value });
        break;
      case 'status':
        setFilters({ ...filters, FilterStatus: value !== '', FilterStatusValue: value });
        break;
      case 'dataFatturaFrom':
        setFilters({ ...filters, FilterDataFatturaFromStatus: value !== '', FilterDataFatturaFromStatusValue: value });
        break;
      case 'dataFatturaTo':
        setFilters({ ...filters, FilterDataFatturaToStatus: value !== '', FilterDataFatturaToStatusValue: value });
        break;
      case 'dataRegistrazioneFrom':
        setFilters({
          ...filters,
          FilterDataRegistrazioneFromStatus: value !== '',
          FilterDataRegistrazioneFromStatusValue: value,
        });
        break;
      case 'dataRegistrazioneTo':
        setFilters({
          ...filters,
          FilterDataRegistrazioneToStatus: value !== '',
          FilterDataRegistrazioneToStatusValue: value,
        });
        break;
      default:
        setFilters({
          FilterFornitore: false,
          FilterFornitoreValue: '',
          FilterRagSoc: false,
          FilterRagSocValue: '',
          FilterPiva: false,
          FilterPivaValue: '',
          FilterStatus: false,
          FilterStatusValue: '',
          FilterDataFatturaFromStatus: false,
          FilterDataFatturaFromStatusValue: '',
          FilterDataFatturaToStatus: false,
          FilterDataFatturaToStatusValue: '',
          FilterDataRegistrazioneFromStatus: false,
          FilterDataRegistrazioneFromStatusValue: '',
          FilterDataRegistrazioneToStatus: false,
          FilterDataRegistrazioneToStatusValue: '',
        });
        break;
    }
    setCurrentPage(0);
  };

  //Gestisce il numero di record per pagina
  const onHandleRecordsChange = (num, newPage) => {
    setNumRecords(num);
    setCurrentPage(newPage);
  };

  //Gestisce il cambio di pagina
  const onHandlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    getInvoicesWithFilters(currentPage, numRecords, sort.direction, sort.column, filters, company.id)
      .then((result) => {
        if (result.success) {
          setResponse(result.data);
        } else {
          throw new Error('Si è verificato un problema durante il recupero dei dati');
        }
      })
      .catch(() => {
        error('Si è verificato un problema durante il recupero dei dati');
        setResponse();
      })
      .finally(() => setLoading(false));
  }, [numRecords, currentPage, sort.column, sort.direction, filters, error, company.id]);

  return (
    <div>
      {loading && <Loader />}
      <h2>Gestione fatture fornitori</h2>
      {response && (
        <DataTable
          responsive
          data={response.invoices}
          column={headerDatagrid}
          onHandleSort={onHandleSort}
          onHandleSearch={onHandleSearch}
          onHandlePageChange={onHandlePageChange}
          onHandleRecordsChange={onHandleRecordsChange}
          totRecords={response.totRecords}
          currentPage={currentPage}
          numRecords={numRecords}
          sort={sort}
          paginationValues={PAGES_SIZE}
        />
      )}
    </div>
  );
};

export default SupplierInvoicesManagementIndexPage;
