import { NavLink } from 'react-router-dom';
import DataTableIcon from './DataTableIcon';

const DataTableLink = ({ to, title, iconName, target, rel }) => (
  <NavLink to={to} title={title} target={target} rel={rel}>
    <DataTableIcon name={iconName} />
  </NavLink>
);

export default DataTableLink;
