import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { routeFactory } from 'routes';
import PaymentCertificatesTable from '../components/list';
import PurchaseOrderSummary from '../components/PurchaseOrderSummary';
import Loader from 'components/layout/Loader';
import { fetchPurchaseOrderPaymentCertificates } from 'services/api/purchase-orders';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { downloadRemoteFile } from 'helpers/api';
import useFeedback from 'hooks/useFeedback';

const fetchInitialData = (purchaseOrderId) => {
  return fetchPurchaseOrderPaymentCertificates(purchaseOrderId);
};

const PurchaseOrderPaymentCertificatesPage = () => {
  const [loading, setLoading] = useState(false);
  const [pageModel, setPageModel] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const { commissionId, purchaseOrderId } = useParams();
  const { hasPermission } = useCommissionPermissions();
  const { error } = useFeedback();

  const canDownload = hasPermission(PURCHASE_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.DOWNLOAD);

  const canCreate =
    hasPermission(PURCHASE_ORDER_PERMISSIONS.PAYMENT_CERTIFICATES.CREATE) &&
    pageModel &&
    !pageModel.isCommissionClosed &&
    (pageModel.amountRemainder > 0 ||
      pageModel.guaranteeWithholdingAmountRemainder > 0 ||
      pageModel.advanceWithholdingAmountRemainder > 0);

  useEffect(() => {
    setLoading(true);
    fetchInitialData(purchaseOrderId)
      .then((response) => {
        if (response.ok) {
          setPageModel(response.data);
          console.log(response.data)
        } else {
          setErrorMessage(response.errorMessage);
        }
      })
      .finally(() => setLoading(false));
  }, [purchaseOrderId]);

  const handleExportAsPdf = (paymentCertificateNumber) => {
    setLoading(true)
    downloadRemoteFile(`/api/purchase-orders/${purchaseOrderId}/payment-certificates/${paymentCertificateNumber}/export?commissionId=${commissionId}`)
    .catch((err) => error(err.message))
    .finally(() => setLoading(false))
  }

  return (
    <>
      {loading && <Loader />}
      {pageModel && (
        <>
          <h3 className="mb-4">Certificati di pagamento</h3>
          {/* Riepilogo Ordine */}
          <PurchaseOrderSummary order={pageModel} />
          <div className="mb-4">
            <h4 className="mb-3">Certificati emessi</h4>
            {pageModel.paymentCertificates.length > 0 ? (
              <PaymentCertificatesTable
                commissionId={commissionId}
                purchaseOrderId={purchaseOrderId}
                paymentCertificates={pageModel.paymentCertificates}
                guaranteeWithholdingTableHeaderText={pageModel.guaranteeWithholdingTableHeaderText}
                onExportAsPdf={handleExportAsPdf}
                canDownload={canDownload}
              />
            ) : (
              <p>Non sono ancora stati creati certificati di pagamento per quest'ordine.</p>
            )}
          </div>
          {canCreate && (
            <Row>
              <Col className="text-right">
                <NavLink
                  to={routeFactory.commissionManagement.purchaseOrders.paymentCertificates.create(
                    commissionId,
                    purchaseOrderId,
                    pageModel.paymentCertificates.length + 1
                  )}
                  className="btn btn-primary"
                >
                  Crea nuovo CDP
                </NavLink>
              </Col>
            </Row>
          )}
        </>
      )}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </>
  );
};

export default PurchaseOrderPaymentCertificatesPage;
