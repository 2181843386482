import useFeedback from 'hooks/useFeedback';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Loader from 'components/layout/Loader';
import { Col, Row, Table, Form, Input } from 'reactstrap';
import { createDefaultDeadline } from 'helpers/purchase-orders';
import { formatNumber } from 'helpers/formatters';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import { displayDate, displayMonthYear } from 'helpers/date';
import CustomInputNumber from 'components/shared/forms/CustomInputNumber';
import ErrorMessage from 'components/shared/feedbacks/ErrorMessage';
import { SaveButton } from 'components/buttons';
import { fetchPurchaseOrderDeadline, updatePurchaseOrderDeadline } from 'services/api/deadline';
import { fetchPaymentTerms } from 'services/api/paymentTerms';
import useUnsavedChanges from 'hooks/useUnsavedChanges';

const Headers = ({ creationDate, deliveryDate, amountToAllocate }) => (
  <>
    <div className="mb-1">Data creazione ordine: {displayDate(creationDate)}</div>
    <div className="mb-1">Data prevista consegna: {displayDate(deliveryDate)}</div>
    <div className="mb-1">
      Importo da allocare: {formatNumber(amountToAllocate)} {'€'}
    </div>
  </>
);

const PurchaseOrderDeadlinesPage = () => {
  const [loading, setLoading] = useState(false);
  const [deadline, setDeadline] = useState();
  const [paymentTermOptions, setPaymentTermOptions] = useState([]);

  const [errorMessage, setErrorMessage] = useState();

  const { purchaseOrderId } = useParams();
  const { hasPermission } = useCommissionPermissions();
  const { error, success } = useFeedback();
  const { notify: notifyUnsavedChanges, clear: clearUnsavedChanges } = useUnsavedChanges();

  const amountToAllocate = deadline?.amountToAllocate;

  const headers = deadline && {
    creationDate: deadline.creationDate,
    deliveryDate: deadline.deliveryDate,
    amountToAllocate: amountToAllocate,
  };

  const dataTable = deadline?.lines;

  const canEdit = (deadline?.editable ?? false) && hasPermission(PURCHASE_ORDER_PERMISSIONS.DEADLINES.EDIT);

  useEffect(() => {
    setLoading(true);

    Promise.all([fetchPurchaseOrderDeadline(purchaseOrderId), fetchPaymentTerms()])
      .then(([deadline, paymentTerms]) => {
        setPaymentTermOptions(paymentTerms);

        const { creationDate, deliveryDate, amountToAllocate, defaultPaymentTermId } = deadline;

        const deadlineLines =
          deadline.lines.length > 0
            ? deadline.lines.map((ln) => (ln.paymentTermId === null ? { ...ln, paymentTermId: '' } : ln))
            : createDefaultDeadline(creationDate, deliveryDate, amountToAllocate, defaultPaymentTermId);

        if (deadlineLines.length > 0) {
          setDeadline({
            ...deadline,
            lines: deadlineLines,
          });
        } else {
          setErrorMessage(
            'Non è possibile compilare uno scadenziario per questo ordine perché la data di creazione è inferiore alla data di consegna.'
          );
        }
      })
      .catch((error) => {
        console.error(error.message);
        setErrorMessage(error.message);
      })
      .finally(() => setLoading(false));
  }, [purchaseOrderId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let t = 0;
    dataTable.forEach((el) => {
      t += el.percentage;
    });

    if (t !== 100) {
      setErrorMessage('Il totale delle percentuali deve essere 100%');
      return;
    }

    setErrorMessage();
    setLoading(true);

    const postData = dataTable.map((data) => (data.paymentTermId !== '' ? data : { ...data, paymentTermId: null }));

    const lines = {
      deadlines: postData,
    };

    updatePurchaseOrderDeadline(purchaseOrderId, lines)
      .then(() => {
        clearUnsavedChanges();
        success('Lo scadenziario è stato aggiornato.');
      })
      .catch(() => error("Si è verificato un errore nell'aggiornamento dello scadenziario"))
      .finally(() => setLoading(false));
  };

  const setPeriodData = (periodIndex, overrides) => {
    notifyUnsavedChanges();
    setDeadline((deadline) => ({
      ...deadline,
      lines: deadline.lines.map((data, i) => (i !== periodIndex ? data : { ...data, ...overrides })),
    }));
  };

  return (
    <>
      {loading && <Loader />}
      {errorMessage && <ErrorMessage text={errorMessage} />}
      {headers && <Headers {...headers} />}
      {dataTable?.length > 0 && (
        <Row>
          <Col lg={8}>
            <Form onSubmit={handleSubmit}>
              <Table bordered responsive>
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <th>Mese/Anno</th>
                    <th>%</th>
                    <th>Importo</th>
                    <th>Condizioni di pagamento</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTable?.map((period, index) => (
                    <tr key={`${index}}`}>
                      {/* Period (month / year) */}
                      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                        <small>{displayMonthYear(period.month, period.year)}</small>
                      </th>
                      {/* Percentage */}
                      <td>
                        <CustomInputNumber
                          size="sm"
                          name={`deadline.${index}.percentage`}
                          value={period.percentage}
                          onChange={(e) => setPeriodData(index, { percentage: e.value })}
                          required
                          disabled={!canEdit}
                          min={0}
                          max={100}
                        />
                      </td>
                      {/* Amount */}
                      <td>
                        <CustomInputNumber
                          size="sm"
                          name={`deadline.${index}.amount`}
                          value={(period.percentage * amountToAllocate) / 100}
                          disabled={true}
                        />
                      </td>
                      {/* Payment term */}
                      <td>
                        <Input
                          bsSize="sm"
                          type="select"
                          name={`deadline.${index}.paymentTermId`}
                          value={period.paymentTermId}
                          onChange={(e) => setPeriodData(index, { paymentTermId: e.target.value })}
                          required={period.percentage > 0}
                          disabled={!canEdit}
                        >
                          <option value="">-- Seleziona</option>
                          {paymentTermOptions.map((paymentTerm) => (
                            <option key={`payment-term-${paymentTerm.id}`} value={paymentTerm.id}>
                              {paymentTerm.name}
                            </option>
                          ))}
                        </Input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {canEdit && <SaveButton />}
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PurchaseOrderDeadlinesPage;
