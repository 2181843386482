import CustomInput from 'components/shared/forms/CustomInput';
import { Button, Form } from 'reactstrap';

const RejectionNote = ({ salesOrderId, onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit?.(salesOrderId, e.target.getObjectData().rejectionNote !== '' ? e.target.getObjectData() : undefined);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CustomInput type="textarea" label="Nota" name="rejectionNote" placeholder="Inserisci una nota" />
      <Button color="primary">
        <i className="fa fa-save" /> Salva
      </Button>
    </Form>
  );
};

export default RejectionNote;