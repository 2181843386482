import { Outlet, useParams } from 'react-router';
import CommissionNavbar from './CommissionNavbar';
import { useEffect, useState } from 'react';
import Loader from 'components/layout/Loader';
import { fetchCommissionWithId } from 'services/api/commissions';
import CommissionContext from 'contexts/CommissionContext';
import { fetchCommissionPermissions } from 'services/api/commission-permissions';
import COMMISSION_MANAGEMENT_PERMISSIONS, {
  COMMISSION_PERMISSIONS,
  SALES_INVOICE_PERMISSIONS,
} from 'constants/commission-management-permissions';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ROUTES from 'routes';
import UnsavedChangesAwareNavLink from 'components/shared/navigation/UnsavedChangesAwareNavLink';

const mapCommissionToState = (commission) => {
  return {
    commissionId: commission.commissionId,
    companyId: commission.companyId,
    description: commission.description,
    startDate: new Date(Date.parse(commission.expectedStartDate)),
    endDate: new Date(Date.parse(commission.expectedEndDate)),
    customer: !!commission.customer && {
      name: commission.customer.displayName,
      address: commission.customer.address,
    },
    worksite: !!commission.worksite && {
      address: [
        commission.worksite.location,
        commission.worksite.address,
        `${commission.worksite.city} (${commission.worksite.municipality})`,
      ].filter((addressLine) => addressLine?.trim()),
    },
    isCommissionClosed: commission.isCommissionClosed,
  };
};

const mapPermissionsToState = (permissions) => {
  return {
    permissions: permissions.map((permission) => permission.id),
  };
};

const mapCommissionStateToContext = (commission) => {
  return {
    companyId: commission.companyId,
    isCommissionClosed: commission.isCommissionClosed,
    permissions: commission.permissions,
  };
};

const fetchInitialData = async (commissionId) => {
  const commissionPermissionsResponse = await fetchCommissionPermissions(commissionId);
  if (commissionPermissionsResponse.ok) {
    const permissions = commissionPermissionsResponse.data.map((permission) => permission.id);
    if (!permissions.includes(COMMISSION_PERMISSIONS.VIEW)) {
      return {
        ok: false,
        message: 'Non sei autorizzato a visualizzare questa commessa',
      };
    } else {
      const commissionResponse = await fetchCommissionWithId(commissionId);
      if (commissionResponse.success) {
        return {
          ok: true,
          data: {
            ...mapCommissionToState(commissionResponse.data),
            ...mapPermissionsToState(commissionPermissionsResponse.data),
          },
        };
      }
    }
  }

  return {
    ok: false,
    message: 'Si è verificato un errore durante il caricamento della commessa',
  };
};

const mapCommissionToNavbarTabsVisibilityProp = ({ permissions }) => {
  return {
    commission: true,
    documents: permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.DOCUMENT.VIEW),
    budget: permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.VIEW),
    approval: permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.BUDGET.APPROVE_REJECT),
    enabledUsers: permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.RESOURCE.VIEW),
    linkedCommissions: permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.COMMISSION.VIEW),
    purchaseOrders: permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.PURCHASE_ORDER.VIEW),
    salesOrders: permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.SALES_ORDER.VIEW),
    purchaseInvoices: permissions.includes(COMMISSION_MANAGEMENT_PERMISSIONS.PURCHASE_INVOICE.VIEW),
    salesInvoices: permissions.includes(SALES_INVOICE_PERMISSIONS.VIEW),
  };
};

const ORDER_SUMMARY_COLUMN_VALUE_STYLES = {
  container: {
    fontSize: '1rem',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};
/*
const OrderSummaryColumnHeader = ({text}) => {
	return <h6 className="mb-1">{text}</h6>;
};

const OrderSummaryColumnValue = ({value}) => {
  return (
    <div style={ORDER_SUMMARY_COLUMN_VALUE_STYLES.container} title={value} className="text-danger text-uppercase">
      {value}
    </div>
  );
};
*/

const OrderSummaryColumnHeader = ({ text }) => {
  return <h6 className="col-xl-auto mb-1 mb-xl-0">{text}</h6>;
};

const OrderSummaryColumnValue = ({ value }) => {
  return (
    <div
      style={ORDER_SUMMARY_COLUMN_VALUE_STYLES.container}
      title={value}
      className="col-xl-auto text-danger text-uppercase"
    >
      {value}
    </div>
  );
};

const CommissionSummary = ({ commissionSummary }) => (
  <Card tag="header" className="header-summary header-summary-commission mb-1 border border-danger">
    <CardHeader className="bg-danger py-1 px-2">
      <Row className="mx-n2">
        <Col xs={11} className="header-summary__title px-2">
          Commessa: <b>{commissionSummary.commissionId}</b> |{' '}
          <span title={commissionSummary.description}>{commissionSummary.description}</span>
        </Col>
        <Col xs={1} className="header-summary__close px-2">
          <UnsavedChangesAwareNavLink
            className=""
            to={ROUTES.COMMISSION_MANAGEMENT.INDEX}
            end
            title="Esci dalla commessa corrente e torna all'elenco commesse"
          >
            &times;
          </UnsavedChangesAwareNavLink>
        </Col>
      </Row>
    </CardHeader>
    <CardBody className="p-1">
      <Row>
        <Col xs={6} className="border-right border-danger text-center">
          <div className="row justify-content-center align-items-baseline">
            <OrderSummaryColumnHeader text="Cliente" />
            <OrderSummaryColumnValue value={commissionSummary.customer.name} />
          </div>
        </Col>
        <Col xs={6} className="text-center">
          <div className="row justify-content-center align-items-baseline">
            <OrderSummaryColumnHeader text="Indirizzo" />
            <OrderSummaryColumnValue value={commissionSummary.worksite.address.join(' - ')} />
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

const CommissionManagementContainer = () => {
  const [loading, setLoading] = useState(false);
  const [commission, setCommission] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { commissionId } = useParams();

  const doFetchCommission = (commissionId) => {
    setErrorMessage(false);
    setCommission(false);
    setLoading(true);
    fetchInitialData(commissionId)
      .then((response) => {
        if (response.ok) {
          setCommission(response.data);
        } else {
          setErrorMessage(response.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    doFetchCommission(commissionId);
  }, [commissionId]);

  const invalidate = () => {
    doFetchCommission(commissionId);
  };

  return (
    <>
      {loading && <Loader />}
      {commission && (
        <CommissionContext.Provider value={{ ...mapCommissionStateToContext(commission), invalidate }}>
          <CommissionSummary commissionSummary={commission} />
          <CommissionNavbar tabsVisibilityOverrides={mapCommissionToNavbarTabsVisibilityProp(commission)} />
          <div className="py-2">
            <Outlet />
          </div>
        </CommissionContext.Provider>
      )}
      {errorMessage && <p>{errorMessage}</p>}
    </>
  );
};

export default CommissionManagementContainer;
