const NOT_APPLICABLE = 0;
const NOT_INVOICEABLE = 1;
const INVOICEABLE = 2;

const GUARANTEE_WITHHOLDING_TYPES = Object.freeze({
  NOT_APPLICABLE,
  NOT_INVOICEABLE,
  INVOICEABLE,
});

export default GUARANTEE_WITHHOLDING_TYPES;
export {
  NOT_APPLICABLE,
  NOT_INVOICEABLE,
  INVOICEABLE,
};