import { Fragment } from 'react';
import BudgetTableActivityRow from 'components/commission-management/budget/BudgetTableActivityRow';
import BudgetTableSubactivityRow from 'components/commission-management/budget/BudgetTableSubactivityRow';
import { Button } from 'reactstrap';

const BudgetTableBody = ({
  activities,
  periods,
  collapsed,
  readonly,
  displaySubactivityCostError = true,
  onCostChange,
  onRevenueChange,
  onPeriodCostChange,
  onPeriodRevenueChange,
  onSubactivityDescriptionChange,
  onAddSubactivity,
  onRemoveSubactivity,
  onCompleteSubactivity,
  canCompleteSubactivity,
}) => {
  return (
    <tbody>
      {activities.map((activity) => (
        <Fragment key={activity.id}>
          <BudgetTableActivityRow activity={activity} periods={periods} collapsed={collapsed} />
          {activity.subactivities.map((subactivity, subactivityIndex) => (
            <BudgetTableSubactivityRow
              key={subactivityIndex}
              subactivity={subactivity}
              periods={periods}
              collapsed={collapsed}
              readonly={readonly}
              displayCostError={displaySubactivityCostError}
              onCostChange={(newCost) => onCostChange?.(activity.id, subactivityIndex, newCost)}
              onRevenueChange={(newRevenue) => onRevenueChange?.(activity.id, subactivityIndex, newRevenue)}
              onPeriodCostChange={(period, newCost) =>
                onPeriodCostChange?.(activity.id, subactivityIndex, period, newCost)
              }
              onPeriodRevenueChange={(period, newRevenue) =>
                onPeriodRevenueChange?.(activity.id, subactivityIndex, period, newRevenue)
              }
              onDescriptionChange={(newDescription) =>
                onSubactivityDescriptionChange?.(activity.id, subactivityIndex, newDescription)
              }
              onRemove={() => onRemoveSubactivity(activity.id, subactivityIndex)}
              canComplete={canCompleteSubactivity}
              onComplete={() => onCompleteSubactivity(activity.id, subactivityIndex)}
            />
          ))}
          {!readonly && activity.subactivityAdditionEnabled && (
            <tr>
              <td colSpan={1} className="text-center">
                <Button color="primary" block={true} size="sm" onClick={(e) => onAddSubactivity(activity.id)} title="Aggiungi un'attività">
                  Aggiungi
                </Button>
              </td>
              <td colSpan={2}></td>
              {[...Array(3 + (collapsed ? 0 : periods.length * 2) + 2).keys()].map((i) => (
                <td key={i}></td>
              ))}
            </tr>
          )}
        </Fragment>
      ))}
    </tbody>
  );
};

export default BudgetTableBody;
