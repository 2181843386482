import { useEffect, useState } from 'react';
import { fetchCurrenciesV2 } from 'services/api/currencies';

const makeCurrencyOption = (currency) => ({ id: currency.id, description: currency.name });

const useCurrenciesOptions = () => {
  const [currenciesOptions, setCurrenciesOptions] = useState([]);

  useEffect(() => {
    const cancellationTokenSource = new AbortController();
    fetchCurrenciesV2({ cancellationToken: cancellationTokenSource.signal })
      .then((currencies) => {
        setCurrenciesOptions(currencies.map(makeCurrencyOption));
      })
      .catch(() => {
        // do nothing for now
      });

    return () => cancellationTokenSource.abort();
  }, []);

  return { currenciesOptions };
};

export { useCurrenciesOptions };
