import FormInput from 'components/forms/FormInput';
import CustomInput from 'components/shared/forms/CustomInput';
import { ACCEPTED_DOCUMENT_TYPES } from 'config';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

const STYLES = {
    saveButton: {
        marginTop: '22px'
    },
    buttons:{
        marginLeft: '5px'
    }
};

const InailDocument = ({ onSubmit, onDelete, isInailUploaded, data, commissionId, documentType, inline, onShowModal, isFileRequired=true }) => {
    const [canSubmit, setCanSubmit] = useState(!isFileRequired);
    const [fileName, setFileName] = useState(data?.name);

    const colSpace = inline ? 12 : 2;

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        onSubmit?.(formData, e.target.getObjectData(), documentType);
    }

    const showModalHandler = () => {
        onShowModal?.(data.insuranceId)
      }

    const handleDelete = (e) => {
        e.preventDefault();
        setFileName();
        setCanSubmit(false);
        onDelete?.(data.insuranceId);
    }

    const handleUploadFile = (e) => {
        console.log(e.target.files[0])
        if (e.target.files[0]) {
            setCanSubmit(true);
            setFileName(e.target.files[0].name)
        }
        else {
            setCanSubmit(false)
            setFileName()
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
              {!inline && (<Col lg={colSpace}>
                    <br />
                    <span>Posizione INAIL</span>
                </Col>)}
                <Col lg={colSpace}>
                    <FormInput
                        type="text"
                        label="Numero protocol."
                        name="protocolNumber"
                        defaultValue={data?.protocolNumber}
                        disabled={isInailUploaded}
                    ></FormInput>
                </Col>
                <Col lg={colSpace}>
                    <FormInput
                        type="date"
                        label="Data protocollo"
                        min="2000-01-01"
                        max="9999-12-31"
                        name="startDate"
                        defaultValue={data?.startDate}
                        disabled={isInailUploaded}
                    ></FormInput>
                </Col>
                <Col lg={colSpace}>
                    <FormInput
                        type="date"
                        label="Data scadenza"
                        min="2000-01-01"
                        max="9999-12-31"
                        name="endDate"
                        defaultValue={data?.endDate}
                        disabled={isInailUploaded}
                    ></FormInput>
                </Col>
                {isInailUploaded ?
                    <Col lg={colSpace}>
                        <CustomInput
                            type="text"
                            label="File"
                            name="file"
                            defaultValue={fileName ? fileName : data?.name}
                            disabled={isInailUploaded}
                        ></CustomInput>
                    </Col> :
                    <Col lg={colSpace}>
                        <CustomInput
                            type="file"
                            accept={ACCEPTED_DOCUMENT_TYPES}
                            label="Allegato"
                            name="file"
                            onChange={handleUploadFile}
                            disabled={isInailUploaded}
                            required={isFileRequired}
                        ></CustomInput>
                    </Col>

                }
                {isInailUploaded ?
                    <Col lg={colSpace} style={STYLES.saveButton}>
                        <Button color="primary" value={document.insuranceId} onClick={showModalHandler}><i className="fa fa-pen" /></Button>
                        <Button color="danger" onClick={handleDelete} style={STYLES.buttons}><i className="fa fa-trash" /></Button>
                        <a style={STYLES.buttons} target="_blank" rel='noreferrer' href={`/api/commissions/${commissionId}/documents/insurance/download/${data?.insuranceId}`}>Visualizza</a>
                    </Col> :
                    <Col lg={colSpace} style={STYLES.saveButton}>
                        <Button disabled={!canSubmit} color="primary"><i className="fa fa-save" /></Button>
                    </Col>
                }
            </Row>
        </Form>
    );
};

export default InailDocument;