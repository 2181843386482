import PurchaseOrderHeaderForm from 'components/commission-management/purchase-order/PurchaseOrderHeaderForm';
import Loader from 'components/layout/Loader';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import {
  createOtherDeliveryPlaceOption,
  mapCommissionWorksiteToDeliveryPlace,
  mapSiteToDeliveryPlace,
} from 'helpers/purchase-orders';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import useFeedback from 'hooks/useFeedback';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchCommissionWithId } from 'services/api/commissions';
import { fetchDeliveryPlaces } from 'services/api/delivery-places';
import { fetchPaymentTermsV2 } from 'services/api/paymentTerms';
import {
  exportPurchaseOrderAsPdf,
  fetchPurchaseOrderWithId,
  updatePurchaseOrder,
  voidPurchaseOrder,
} from 'services/api/purchase-orders';
import { fetchSupplierWithId } from 'services/api/suppliers';
import useInvalidatePurchaseOrder from './shared/hooks/useInvalidatePurchaseOrder';
import useAuth from 'hooks/useAuth';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import { OTHER_DELIVERY_PLACE_OPTION_VALUE } from 'components/commission-management/purchase-order/PurchaseOrderHeaderForm/constants';

const mapMunicipality = ({ postalCode, localityLines, province, countryCode }) =>
  [postalCode, ...localityLines, province && `(${province})`, countryCode && `- ${countryCode}`]
    .filter((line) => line?.trim())
    .join(' ');

const mapSupplierToFrontEnd = (backEndSupplier) => ({
  id: backEndSupplier.id,
  businessName: backEndSupplier.businessName,
  code: backEndSupplier.code,
  address: backEndSupplier.address.streetLines.join(', '),
  municipality: mapMunicipality(backEndSupplier.address),
  vatNumber: backEndSupplier.vatNumber,
  taxCode: backEndSupplier.taxCode,
  durc: backEndSupplier.durc,
  paymentTerms: backEndSupplier.paymentTerm?.id,
});

const mapPurchaseOrderToFrontEnd = (purchaseOrder) => ({
  paymentTerms: {
    id: purchaseOrder.paymentTerm.id,
    modificationType: purchaseOrder.paymentTerm.modification?.typeId,
    note: purchaseOrder.paymentTerm.modification?.note,
  },
  // site: !purchaseOrder.deliveryPlaceId ? 0 : purchaseOrder.deliveryPlaceId,
  site:
    purchaseOrder.deliveryPlaceId > 0
      ? purchaseOrder.deliveryPlaceId
      : purchaseOrder.otherDeliveryPlace
      ? OTHER_DELIVERY_PLACE_OPTION_VALUE
      : 0,
  otherDeliveryPlace: purchaseOrder.otherDeliveryPlace,
  type: purchaseOrder.typeId,
  currency: {
    id: purchaseOrder.currency.id,
    exchangeRate: purchaseOrder.currency.exchangeRate
      ? {
          amount: purchaseOrder.currency.exchangeRate.amount,
          date: purchaseOrder.currency.exchangeRate.date,
        }
      : null,
  },
  shortDescription: purchaseOrder.shortDescription,
  deliveryDate: purchaseOrder.deliveryDate,
  guaranteeWithholding: purchaseOrder.guaranteeWithholding
    ? {
        id: purchaseOrder.guaranteeWithholding.id,
        value: purchaseOrder.guaranteeWithholding.percentage,
      }
    : null,
  editable: purchaseOrder.editable,
  settleable: purchaseOrder.settleable,
  voidable: purchaseOrder.voidable,
  exportedFileLanguageId: purchaseOrder.exportedFileLanguageId,
  exportableAsPdf: purchaseOrder.exportableAsPdf,
  ownerId: purchaseOrder.ownerId,
});

const fetchInitialResources = async (commissionId, purchaseOrderId) => {
  const [purchaseOrderResponse, commissionResponse, deliveryPlacesOtherThanWorksiteResponse, paymentTermsResponse] =
    await Promise.all([
      fetchPurchaseOrderWithId(commissionId, purchaseOrderId),
      fetchCommissionWithId(commissionId),
      fetchDeliveryPlaces(),
      fetchPaymentTermsV2(),
    ]);
  const purchaseOrder = mapPurchaseOrderToFrontEnd(purchaseOrderResponse.data);
  const supplierResponse = await fetchSupplierWithId(purchaseOrderResponse.data.supplierId);
  const supplier = mapSupplierToFrontEnd(supplierResponse.data);
  const worksite = mapCommissionWorksiteToDeliveryPlace(commissionResponse.data.worksite);
  const deliveryPlacesOtherThanWorksite = deliveryPlacesOtherThanWorksiteResponse.ok
    ? deliveryPlacesOtherThanWorksiteResponse.data
    : [];
  const deliveryPlaces = [
    worksite,
    ...deliveryPlacesOtherThanWorksite.map(mapSiteToDeliveryPlace),
    createOtherDeliveryPlaceOption(),
  ];
  const paymentTerms = paymentTermsResponse.success ? paymentTermsResponse.data : [];
  return {
    supplier,
    purchaseOrder,
    paymentTerms,
    deliveryPlaces,
  };
};

const PurchaseOrderHeaderPage = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialState, setInitialState] = useState();
  const [submitting, setSubmitting] = useState(false);

  const { commissionId, purchaseOrderId } = useParams();
  const { error, success } = useFeedback();
  const { hasPermission } = useCommissionPermissions();
  const invalidatePurchaseOrder = useInvalidatePurchaseOrder();
  const { userId } = useAuth();

  const { clear: clearUnsavedChanges } = useUnsavedChanges();

  const purchaseOrder = initialState?.purchaseOrder;

  // console.debug(`purchaseOrder.exportableAsPdf? ${purchaseOrder?.exportableAsPdf ?? false ? 'T' : 'F'}`);
  // console.debug(`Can export all? ${hasPermission(PURCHASE_ORDER_PERMISSIONS.EXPORT_PDF_ALL) ? 'T' : 'F'}`);
  // console.debug(`purchaseOrder without owner? ${purchaseOrder.ownerId === null} ? 'T' : 'F'`);
  // console.debug(`can export own? ${hasPermission(PURCHASE_ORDER_PERMISSIONS.EXPORT_PDF_OWN) ? 'T' : 'F'}`);
  // console.debug(`is owner? ${userId === purchaseOrder.ownerId ? 'T' : 'F'}`);

  const canExportAsPdf =
    (purchaseOrder?.exportableAsPdf ?? false) &&
    (hasPermission(PURCHASE_ORDER_PERMISSIONS.EXPORT_PDF_ALL) ||
      purchaseOrder.ownerId === null ||
      (hasPermission(PURCHASE_ORDER_PERMISSIONS.EXPORT_PDF_OWN) && userId === purchaseOrder.ownerId));

  const handleSubmit = async (formData) => {
    setSubmitting(true);
    const response = await updatePurchaseOrder(purchaseOrderId, formData);
    if (response.ok) {
      clearUnsavedChanges();
      success(response.message);
      invalidatePurchaseOrder();
    } else {
      error(response.message);
    }
    setSubmitting(false);
  };

  const handleVoid = async () => {
    const confirm = window.confirm('Sei davvero sicuro di voler annullare questo ordine?');
    if (confirm) {
      setSubmitting(true);
      const response = await voidPurchaseOrder(purchaseOrderId);
      if (response.ok) {
        clearUnsavedChanges();
        success('Ordine annullato con successo', 'Successo');
        setInitialState((state) => ({
          ...state,
          purchaseOrder: {
            ...state.purchaseOrder,
            editable: false,
            voidable: false,
            settleable: false,
            exportableAsPdf: false,
          },
        }));
        invalidatePurchaseOrder();
      } else {
        error(response.message);
      }
      setSubmitting(false);
    }
  };

  const handleExportAsPdf = () => {
    setSubmitting(true);
    exportPurchaseOrderAsPdf(purchaseOrderId, purchaseOrder.exportedFileLanguageId)
      .catch((err) => error(err.message))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    fetchInitialResources(commissionId, purchaseOrderId).then((data) => {
      console.log(data);
      setInitialState(data);
      setInitialLoading(false);
    });
  }, [commissionId, purchaseOrderId]);

  return (
    <>
      {(initialLoading || submitting) && <Loader />}
      {purchaseOrder && (
        <PurchaseOrderHeaderForm
          readonly={
            !(
              purchaseOrder.editable &&
              hasPermission(PURCHASE_ORDER_PERMISSIONS.CREATE_EDIT) &&
              userId === purchaseOrder.ownerId
            )
          }
          voidable={purchaseOrder.voidable && hasPermission(PURCHASE_ORDER_PERMISSIONS.VOID)}
          exportableAsPdf={canExportAsPdf}
          defaultValues={purchaseOrder}
          sites={initialState.deliveryPlaces}
          supplier={initialState.supplier}
          paymentTerms={initialState.paymentTerms}
          onSubmit={handleSubmit}
          onVoid={handleVoid}
          onExportAsPdf={handleExportAsPdf}
        />
      )}
    </>
  );
};

export default PurchaseOrderHeaderPage;
