import { Card, CardBody, CardHeader } from 'reactstrap';
import { CommissionSummaryRow } from './SalesOrderForm';

export const CommissionSummaryCard = ({ className, commissionId, customerName, description, cig, cup }) => (
  <Card className={className}>
    <CardHeader>Commessa</CardHeader>
    <CardBody>
      <CommissionSummaryRow label="Riferimento commessa" value={commissionId} className="mb-2" />
      <CommissionSummaryRow label="Cliente" value={customerName} className="mb-2" />
      <CommissionSummaryRow label="Descrizione" value={description} className={cig || cup ? 'mb-2' : undefined} />
      {cig && <CommissionSummaryRow label="CIG" value={cig} className={cup ? 'mb-2' : undefined} />}
      {cup && <CommissionSummaryRow label="CUP" value={cup} />}
    </CardBody>
  </Card>
);
