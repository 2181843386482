import CommissionContext from 'contexts/CommissionContext';
import { useCallback, useContext } from 'react';

const useCommissionPermissions = () => {
  const { permissions } = useContext(CommissionContext);

  const hasPermission = useCallback(
    (permissionId) => {
      return permissions.includes(permissionId);
    },
    [permissions]
  );

  return { permissions, hasPermission };
};

export default useCommissionPermissions;
