import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useCallback, useContext, useState } from "react";
import CustomInput from "components/shared/forms/CustomInput";
import { fetchCustomerWithId } from "services/api/customers";
import useAuth from "hooks/useAuth";
import RequiredFieldMarker from "components/shared/RequiredFieldMarker";
import GUARANTEE_WITHHOLDING_TYPES from "constants/guarantee-withholding-types";
import useFeedback from "hooks/useFeedback";
import AuthContext from "contexts/AuthContext";
import InputSearch from "components/shared/forms/InputSearch";

const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
    case 404:
      return {
        success: false,
        message: 'nessun dato trovato',
      };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

const useParseCustomResponse = () => {
  const { logout } = useContext(AuthContext);
  const { error } = useFeedback();

  const parseCustomResponse = useCallback(
    (response) => {
      if (response.success) {
        return { success: true, data: response.data };
      }

      if (response.authenticated === false) {
        logout();
      }

      if (response.authorized === false) {
        error('Non sei autorizzato a vedere questa pagina');
        return {
          success: false,
          message: 'Non sei autorizzato a vedere questa pagina',
        };
      }

      error(response.message);
      return { success: false, message: response.message };
    },
    [error, logout]
  );

  return parseCustomResponse;
};


const fetchWithData = async (api) => {
  const response = await fetch(`${api}`);
  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};



const mapCommissionCustomerToCustomerDetail = (customer) => {
  return (
    !!customer && {
      id: customer.id,
      name: customer.businessName,
      address: customer.address,
      vatNumber: customer.vatNumber,
      taxCode: customer.taxCode,
      municipality: customer.municipality,
      postalCode: customer.postalCode,
      paymentTerm: !!customer.paymentTermId && {
        id: customer.paymentTermId,
        description: customer.paymentTermDescription,
      },
      changed: false,
      displayName: customer.businessName + " - " + customer.address
    }
  );
};

const mapCustomerApiDataToCustomerDetail = (customer) => ({
  id: customer.id,
  name: customer.name,
  address: customer.address,
  vatNumber: customer.vatNumber,
  taxCode: customer.taxCode,
  municipality: customer.municipality,
  postalCode: customer.postalCode,
  paymentTerm: !!customer.paymentTerm && {
    id: customer.paymentTerm.id,
    description: customer.paymentTerm.description,
  },
  changed: true,
  displayName: customer.businessName + " - " + customer.address,
});

const CustomerDetailCard = ({ customerDetail }) => (
  <Card>
    <CardHeader>Cliente Selezionato</CardHeader>
    <CardBody>
      <CardText tag="div">Nome Azienda: {customerDetail.name}</CardText>
      <CardText tag="div">Indirizzo: {customerDetail.address}</CardText>
      {!!customerDetail.municipality && (
        <CardText tag="div"> Città: {customerDetail.municipality} </CardText>
      )}
      {!!customerDetail.postalCode && (
        <CardText tag="div"> CAP: {customerDetail.postalCode} </CardText>
      )}
      <CardText tag="div">
        {" "}
        PIVA: {customerDetail.vatNumber} - CF: {customerDetail.taxCode}
      </CardText>
      {customerDetail.paymentTerm && (
        <CardText tag="div">
          Modalità di pagamento: {customerDetail.paymentTerm.description}
        </CardText>
      )}
    </CardBody>
  </Card>
);

const mapStatusesApiToCommissionEdit = (statuses) => {
  return statuses.map((status) => (
    {
        commissionStatusId: status.commissionStatusId,
        name: status.name,
        isShowedOnlyWhenSelected: [8,9].includes(status.commissionStatusId) ? true : false
    }
  ))
}

const CommissionForm = ({
  commission,
  options,
  disabled = false,
  onSubmit
}) => {
  const [customer, setCustomer] = useState(
    !!commission.customer &&
      mapCommissionCustomerToCustomerDetail(commission.customer)
  );
  const statuses =
    mapStatusesApiToCommissionEdit(options.commissionStatusResponse.data)
  
  const parseCustomResponse = useParseCustomResponse();
  const {error} = useFeedback();
  const { company } = useAuth();
  const [guaranteeWithholdingId, setGuaranteeWithholdingId] = useState(
    commission.guaranteeWithholdingId
  );
  const [paymentTermId, setPaymentTermId] = useState(commission.paymentTermId);

  const paymentTermChanged =
    customer &&
    (customer.paymentTerm?.id ?? 0) !== paymentTermId &&
    !isNaN(paymentTermId);

  const handleOnCustomerChange = async (customerSelected) => {
    const customerApiData = await fetchCustomerWithId(customerSelected.value);
    setCustomer(mapCustomerApiDataToCustomerDetail(customerApiData));
    setPaymentTermId(customerApiData.paymentTerm?.id ?? 0);
  };

  const PaymentConditionHandler = (e) => {
    const { value } = e.target;
    setPaymentTermId(parseInt(value));
  };

  const filterCommissionStatuses = (currentStatus) => {
    return statuses.filter((x) => currentStatus ===  x.commissionStatusId || 
           x.isShowedOnlyWhenSelected === false);
  };

  const fetchDataHandler = (inputValue) => {
    const filters = {
      CompanyId: company.id,
      Search: inputValue
    };

    return fetchWithData(`/api/customers?${new URLSearchParams(filters)}`).then((response) => {
      const parsedResponse = parseCustomResponse(response);
      
      if (parsedResponse.success) {
        return parsedResponse.data.map(customer => ({
          id: customer.id, displayName: [customer.name, customer.address].filter(displayNameSegment => displayNameSegment).join(" - ")
        }));
      }
      else
      {
        error(`${parsedResponse.message}`, 'Errore')
        return []
      }
    });
  }
  // console.debug(customer)

  // const handleMaxStartDate = (e) => {
  //   console.log(e.target.value)
  //   e.target.value <= commission.expectedStartDate ? 
  // }

  return (
    <Form onSubmit={onSubmit}>
      <Row>
        {/* Prima metà */}
        <Col lg={6}>
          <Row>
            {!disabled && (
              <Col md={8} className="mb-3">
                <Label htmlFor="customer">
                  Associa cliente <RequiredFieldMarker />{" "}
                </Label>
                <InputSearch
                  searchable={true}
                  id="customer"
                  name="customerId"
                  placeholder={"Cerca un cliente..."}
                  valuePropertyName={"id"}
                  labelPropertyName={"displayName"}
                  onFetchData={fetchDataHandler}
                  onChange={handleOnCustomerChange}
                  noOptionsMessage={"Nessun cliente trovato..."}
                  disabled={disabled}
                  cacheOptions={true}
                  defaultValue={customer}
                  required
                />
              </Col>
            )}
          </Row>
          {customer && (
            <Row>
              <Col lg={12} className="mb-3">
                <CustomerDetailCard customerDetail={customer} />
              </Col>
            </Row>
          )}
          <Row>
            <Col className="mb-3">
              <Card>
                <CardHeader>Indirizzo Cantiere</CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <CustomInput
                        type="text"
                        label="Luogo cantiere"
                        name="worksiteLocation"
                        defaultValue={commission.worksiteLocation}
                        disabled={disabled}
                        required
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <CustomInput
                        type="text"
                        name="worksiteAddress"
                        defaultValue={commission.worksiteAddress}
                        disabled={disabled}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <CustomInput
                        type="text"
                        label="Città"
                        name="worksiteCity"
                        defaultValue={commission.worksiteCity}
                        disabled={disabled}
                        maxLength={100}
                        required
                      />
                    </Col>
                    <Col lg={2}>
                      <CustomInput
                        type="text"
                        label="Prov."
                        name="worksiteMunicipality"
                        defaultValue={commission.worksiteMunicipality}
                        disabled={disabled}
                        maxLength={20}
                        required
                      />
                    </Col>
                    <Col lg={3}>
                      <CustomInput
                        type="text"
                        label="CAP"
                        name="worksitePostalCode"
                        defaultValue={commission.worksitePostalCode}
                        disabled={disabled}
                        required
                        maxLength={20}
                      />
                    </Col>
                    <Col lg={3}>
                      <CustomInput
                        type="select"
                        label="Nazione"
                        name="worksiteCountryId"
                        defaultValue={commission.worksiteCountry}
                        disabled={disabled}
                        required
                      >
                        <option value="">--- Seleziona</option>
                        {options.countryResponse.data.map((country) => (
                          <option
                            key={`commission-country-${country.countryId}`}
                            value={country.countryId}
                          >
                            {country.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        {/* Seconda metà */}
        <Col lg={6}>
          <Row>
            <Col lg={6} xl={4}>
              <CustomInput
                type="select"
                label="Stato commessa"
                name="statusId"
                defaultValue={commission.statusId}
                disabled={disabled}
                required
              >
                {/* Da rivedere probabilmente: se il form è scarto lo status 8 e 9 ovvero commessa chiusa e riaperta */}
                <option value="">--- Seleziona</option>
                {!disabled
                  ? filterCommissionStatuses(commission.statusId)
                      .map((commissionStatus) => (
                        <option
                          key={`commission-status-${commissionStatus.commissionStatusId}`}
                          value={commissionStatus.commissionStatusId}
                        >
                          {commissionStatus.name}
                        </option>
                      ))
                  : 
                     options.commissionStatusResponse.data.map(
                      (commissionStatus) => (
                        <option
                          key={`commission-status-${commissionStatus.commissionStatusId}`}
                          value={commissionStatus.commissionStatusId}
                        >
                          {commissionStatus.name}
                        </option>
                      )
                    )}
              </CustomInput>
            </Col>
            <Col lg={6} xl={8}>
              <CustomInput
                type="textarea"
                label="Descrizione commessa"
                name="description"
                defaultValue={commission.description}
                disabled={disabled}
                required
                maxLength={180}
              />
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col md={6}>
              <CustomInput
                type="text"
                label="CIG"
                title="Il CIG è un codice alfanumerico di 10 caratteri"
                name="cig"
                defaultValue={commission.cig}
                pattern="[A-Za-z0-9]{10}"
                maxLength={10}
                disabled={disabled}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                type="text"
                label="CUP"
                title="Il CUP è un codice alfanumerico di 15 caratteri"
                name="cup"
                defaultValue={commission.cup}
                pattern="[A-Za-z0-9]{15}"
                maxLength={15}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col md={6}>
              <CustomInput
                type="text"
                label="Referente tecnico cliente"
                name="technicalReferent"
                defaultValue={commission.technicalReferent}
                disabled={disabled}
                maxLength={100}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                type="text"
                label="Referente amministrativo cliente"
                name="administrativeReferent"
                defaultValue={commission.administrativeReferent}
                disabled={disabled}
                maxLength={100}
              />
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col md={6} lg={3}>
              <CustomInput
                type="date"
                label="Data inizio commessa"
                name="expectedStartDate"
                min="2000-01-01"
                max={
                  !!commission.expectedStartDate
                    ? commission.expectedStartDate
                    : "9999-12-31"
                }
                defaultValue={commission.expectedStartDate}
                disabled={disabled}
                required
              />
            </Col>
            <Col md={6} lg={3}>
              <CustomInput
                type="date"
                label="Data fine commessa"
                name="expectedEndDate"
                min={
                  !!commission.expectedEndDate
                    ? commission.expectedEndDate
                    : "2000-01-01"
                }
                max="9999-12-31"
                defaultValue={commission.expectedEndDate} //'2023-02-03'
                disabled={disabled}
                required
              />
            </Col>
            <Col lg={6}>
              <CustomInput
                type="select"
                label="Territorio"
                name="territoryId"
                defaultValue={commission.territoryId}
                disabled={disabled}
                required
              >
                {options.territoryResponse.data.map((territory) => (
                  <option
                    key={territory.territoryId}
                    value={territory.territoryId}
                  >
                    {territory.name}
                  </option>
                ))}
              </CustomInput>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <CustomInput
                type="select"
                label="Ritenuta di garanzia"
                name="guaranteeWithholdingId"
                defaultValue={commission.guaranteeWithholdingId}
                onChange={(e) =>
                  setGuaranteeWithholdingId(parseInt(e.target.value))
                }
                disabled={disabled}
                required
              >
                <option value="">---Seleziona</option>
                <option value={GUARANTEE_WITHHOLDING_TYPES.NOT_APPLICABLE}>
                  Non applicabile
                </option>
                <option value={GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE}>
                  Non fatturabile
                </option>
                <option value={GUARANTEE_WITHHOLDING_TYPES.INVOICEABLE}>
                  Fatturabile
                </option>
              </CustomInput>
            </Col>
            {[
              GUARANTEE_WITHHOLDING_TYPES.NOT_INVOICEABLE,
              GUARANTEE_WITHHOLDING_TYPES.INVOICEABLE,
            ].includes(guaranteeWithholdingId) && (
              <Col md={3}>
                <CustomInput
                  label="%"
                  type="number"
                  step="0.01"
                  defaultValue={commission.guaranteeWithholdingPercentage}
                  name="guaranteeWithholdingPercentage"
                  disabled={disabled}
                />
              </Col>
            )}
          </Row>
          {customer && (
            <>
              <Row>
                <Col lg={6}>
                  <CustomInput
                    type="select"
                    label="Condizioni di pagamento"
                    name="paymentTermId"
                    value={paymentTermId}
                    onChange={PaymentConditionHandler}
                    disabled={disabled}
                    required
                  >
                    <option value="">--- Seleziona</option>
                    {options.paymentTermsResponse.data.map((paymentTerm) => (
                      <option
                        key={`payment-term-${paymentTerm.id}`}
                        value={paymentTerm.id}
                      >
                        {paymentTerm.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
                {paymentTermChanged && (
                  <Col lg={6}>
                    <label>&nbsp;</label>
                    <p className="text-danger">
                      ATTENZIONE: la modifica delle condizioni di pagamento
                      verrà valutata da amministrazione.
                    </p>
                  </Col>
                )}
              </Row>
              {paymentTermChanged && (
                <>
                  <Row>
                    <Col xl={4} className="my-2">
                      <label className="d-none d-xl-block">&nbsp;</label>
                      <Row>
                        <Col md={"auto"} xl={12}>
                          <FormGroup className="form-check">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="paymentTermModificationType"
                              id="temporarychange"
                              value="0"
                              defaultChecked={
                                customer.changed ||
                                commission.paymentTermModificationType !== 1
                              }
                              disabled={disabled}
                            />
                            <p
                              className="form-check-label"
                              htmlFor="temporarychange"
                            >
                              Modifica temporanea
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md={"auto"} xl={12}>
                          <FormGroup className="form-check">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="paymentTermModificationType"
                              id="structuralchange"
                              value="1"
                              defaultChecked={
                                !customer.changed &&
                                commission.paymentTermModificationType === 1
                              }
                              disabled={disabled}
                            />
                            <p
                              className="form-check-label"
                              htmlFor="structuralchange"
                            >
                              Modifica strutturale
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={8}>
                      <CustomInput
                        type="textarea"
                        label="Note per amministrazione"
                        id="note"
                        name="paymentTermModificationNote"
                        defaultValue={
                          customer.changed
                            ? ""
                            : commission.paymentTermModificationNote
                        }
                        disabled={disabled}
                      ></CustomInput>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      {/* Se disabled è vero vuol dire che siamo in dettaglio della commessa e non serve il bottone submit
          quindi non lo mostro
        */}
      {!disabled && (
        <Row>
          <Col md={6} xl={3} className="offset-lg-6">
            <Button block type="submit" color="primary">
              Salva
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default CommissionForm;
