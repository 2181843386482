const roundNumber = (number, decimalNumber) => {
  if (isNaN(decimalNumber) || decimalNumber < 0) {
    throw new Error('Invalid decimalNumber passed to the function roundNumber');
  }
  const pow = 10;
  const result = Math.round(number * Math.pow(pow, decimalNumber)) / Math.pow(pow, decimalNumber);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#:~:text=Math.round(x)%20is,x%20%2B%200.5)%20returns%200.
  if (Math.abs(result) === 0) {
    return 0;
  }

  return result;
};

export { roundNumber };
