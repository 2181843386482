import DataTableV2 from 'modules/DataTable';
import { useMemo } from 'react';
import { COLUMNS } from './configuration';

const PurchaseOrdersDataTable = ({
  items,
  itemsCount,
  currenciesOptions = [],
  orderStatusesOptions = [],
  overrides,
  context,
  pageSizes = [],
  ...otherProps
}) => {
  const tableColumns = useMemo(() => {
    return COLUMNS.map((col) => {
      const { columnName, ...columnOverride } = overrides?.find((o) => o.columnName === col.value) ?? {
        columnName: col.value,
      };
      switch (col.value) {
        case 'currencyName':
          columnOverride.options = currenciesOptions;
          break;
        case 'status':
          columnOverride.options = orderStatusesOptions;
          break;
        default:
          break;
      }
      return Object.keys(columnOverride).length > 0 ? { ...col, ...columnOverride } : col;
    });
  }, [overrides, currenciesOptions, orderStatusesOptions]);

  return (
    <DataTableV2
      fixedLayout
      responsive
      data={items}
      columns={tableColumns}
      size="sm"
      pageSizes={pageSizes}
      context={context}
      totalElements={itemsCount}
      {...otherProps}
    />
  );
};

export default PurchaseOrdersDataTable;
