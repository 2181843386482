import Sidebar from 'components/layout/Sidebar';
import TopMenu from 'components/layout/TopMenu';
import Footer from 'components/layout/Footer';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import RoutedBody from 'components/layout/RoutedBody';
import { useCallback, useEffect, useRef, useState } from 'react';
import AuthContext from 'contexts/AuthContext';
import Loader from 'components/layout/Loader';
import ROUTES from 'routes';
import FeedbackQueue from 'components/layout/FeedbackQueue';
import LoginPage from 'pages/LoginPage';
import { PrimeReactProvider } from 'primereact/api';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { fetchJson2 } from 'helpers/api';
import ErrorMessage from 'components/shared/feedbacks/ErrorMessage';
import UnsavedChangesProvider from 'components/shared/UnsavedChangesProvider';
import { fetchAlerts } from 'services/api/alerts';
import useFeedback from 'hooks/useFeedback';
import AlertsContext from 'contexts/AlertsContext';
import ConfirmSwitchCompanyProvider from 'components/shared/ConfirmSwitchCompanyProvider';

const AuthenticatedApp = () => {
  const [alerts, setAlerts] = useState([]);
  const [toggled, setToggle] = useState(true);
  const { error } = useFeedback();

  const doFetchAlerts = useCallback(() => {
    fetchAlerts()
      .then((alerts) => setAlerts(alerts))
      .catch(() => error("Si è verificato un errore durante l'aggiornamento degli avvisi"));
  }, [error]);

  useEffect(() => {
    doFetchAlerts();
  }, [doFetchAlerts]);

  const handleOnToggle = () => {
    setToggle((toggled) => !toggled);
  };

  return (
    <ConfirmSwitchCompanyProvider>
      <AlertsContext.Provider value={{ reloadAlerts: doFetchAlerts }}>
        <div id="wrapper">
          <Sidebar toggled={toggled} onToggle={handleOnToggle} />
          <div id="content-wrapper" className="d-flex flex-column px-0">
            <div id="content">
              <TopMenu alerts={alerts} onToggle={handleOnToggle} />
              <div className="container-fluid px-3">
                <RoutedBody />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </AlertsContext.Provider>
    </ConfirmSwitchCompanyProvider>
  );
};

const UnauthenticatedApp = () => {
  return (
    <Routes>
      <Route path={ROUTES.AUTH.LOGIN} Component={LoginPage} />
      <Route path="*" element={<Navigate to={ROUTES.AUTH.LOGIN} />} />
    </Routes>
  );
};

const fetchAuthenticatedProfile = () => {
  return fetchJson2('/api/auth');
};

const App = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { pathname, search } = useLocation();
  const initialPathname = useRef(pathname);
  const initialSearch = useRef(search);

  const handleLogout = useCallback(() => {
    setUser({ authenticated: false });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchAuthenticatedProfile()
      .then((profile) => {
        if (profile.authenticated) {
          setUser({ ...profile, company: profile.company || { id: 'XX' } });
        } else if (initialPathname.current.toLowerCase() !== ROUTES.AUTH.LOGIN) {
          const searchParams = new URLSearchParams({ returnUrl: initialPathname.current + initialSearch.current });
          window.location.replace(`/Account/Login?${searchParams}`);
        } else {
          setUser({ authenticated: false });
        }
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <PrimeReactProvider>
      <FeedbackQueue>
        <UnsavedChangesProvider>
          {loading && <Loader />}
          {user && (
            <AuthContext.Provider value={{ ...user, logout: handleLogout }}>
              {user.authenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
            </AuthContext.Provider>
          )}
          {errorMessage && (
            <div className="p-3">
              <ErrorMessage text={errorMessage} />
            </div>
          )}
        </UnsavedChangesProvider>
      </FeedbackQueue>
    </PrimeReactProvider>
  );
};

export default App;
