import { Routes, Route } from 'react-router-dom';

import ROUTES from 'routes';

import CommissionIndexPage from 'pages/CommissionManagement/commissions/index';
import Dashboard from 'pages/Dashboard';
import CommissionDocuments from 'pages/CommissionManagement/documents/CommissionDocuments';
import BudgetCrossroadsPage from 'pages/CommissionManagement/Budget/BudgetCrossroadsPage';
import BudgetVersionDetailsPage from 'pages/CommissionManagement/Budget/BudgetVersionDetailsPage';
import ApproveBudgetPage from 'pages/CommissionManagement/Budget/ApproveBudgetPage';
import EditBudgetVersionPage from 'pages/CommissionManagement/Budget/EditBudgetVersionPage';
import BudgetRevisionArchivePage from 'pages/CommissionManagement/Budget/BudgetRevisionArchivePage';
import SalesOrderListPage from 'pages/CommissionManagement/sales-order/SalesOrderListPage';
import CreateSalesOrderPage from 'pages/CommissionManagement/sales-order/SalesOrderCreatePage';
import SalesOrderHeaderPage from 'pages/CommissionManagement/sales-order/SalesOrderHeaderPage';
import SalesOrderBodyPage from 'pages/CommissionManagement/sales-order/SalesOrderBodyPage';
import SalesOrderAttachmentsPage from 'pages/CommissionManagement/sales-order/SalesOrderAttachmentsPage';
import SalesOrderPaymentCertificatesPage from 'pages/CommissionManagement/sales-order/payment-certificates/list';
import SalesOrderNewPaymentCertificatePage from 'pages/CommissionManagement/sales-order/payment-certificates/create';
import SalesOrderPaymentCertificateDetailPage from 'pages/CommissionManagement/sales-order/payment-certificates/detail';
import SalesOrderPaymentCertificateEditPage from 'pages/CommissionManagement/sales-order/payment-certificates/edit';
import SalesOrderInvoicesPage from 'pages/CommissionManagement/sales-order/SalesOrderInvoicesPage';
import PurchaseOrderIndexPage from 'pages/CommissionManagement/purchase-order/list';
import CreatePurchaseOrderPage, {
  ChooseSupplierStepPage,
  OrderDataStepPage,
} from 'pages/CommissionManagement/purchase-order/create';
import PurchaseOrderHeaderPage from 'pages/CommissionManagement/purchase-order/PurchaseOrderHeaderPage';
import PurchaseOrderBodyPage from 'pages/CommissionManagement/purchase-order/PurchaseOrderBodyPage';
import PurchaseOrderDeadlinesPage from 'pages/CommissionManagement/purchase-order/PurchaseOrderDeadlinesPage';
import PurchaseOrderAttachmentsPage from 'pages/CommissionManagement/purchase-order/PurchaseOrderAttachmentsPage';
import PurchaseOrderPaymentCertificatesPage from 'pages/CommissionManagement/purchase-order/payment-certificates/list';
import NewCommission from 'pages/CommissionManagement/commissions/NewCommission';
import NewCommissionData from 'pages/CommissionManagement/commissions/NewCommissionData';
import EditCommissionPage from 'pages/CommissionManagement/commissions/EditCommissionPage';
import DetailsCommission from 'pages/CommissionManagement/commissions/DetailsCommission';
import EnabledUserPage from 'pages/CommissionManagement/users/EnabledUserPage';
import LoginPage from 'pages/LoginPage';
import NotFoundPage from 'pages/NotFoundPage';
import CommissionManagementContainer from 'components/commission-management/shared/CommissionManagementContainer';
import LinkedCommissionsIndexPage from 'pages/CommissionManagement/linked-commissions/LinkedCommissionsIndexPage';
import PurchaseInvoiceIndexPage from 'pages/CommissionManagement/purchase-invoices/PurchaseInvoiceIndexPage';
import SalesInvoiceIndexPage from 'pages/CommissionManagement/sales-invoices/SalesInvoiceIndexPage';
import SupplierInvoicesManagementIndexPage from 'pages/supplier-invoices-management/index';
import ManageSupplierInvoicePage from 'pages/supplier-invoices-management/manage';
import PurchaseOrderNewPaymentCertificatePage from 'pages/CommissionManagement/purchase-order/payment-certificates/create';
import PurchaseOrderPaymentCertificateDetailPage from 'pages/CommissionManagement/purchase-order/payment-certificates/detail';
import PurchaseOrderPaymentCertificateEditPage from 'pages/CommissionManagement/purchase-order/payment-certificates/edit';
import PurchaseOrderManagementContainer from 'pages/CommissionManagement/purchase-order/shared/components/PurchaseOrderManagementContainer';
import SalesOrderManagementContainer from 'pages/CommissionManagement/sales-order/shared/components/SalesOrderManagementContainer';
import PurchaseOrderInvoicesPage from 'pages/CommissionManagement/purchase-order/purchase-invoices';
import ManageSalesInvoicePage from 'pages/sales-invoices-management/manage';
import SalesInvoicesManagementIndexPage from 'pages/sales-invoices-management/index';
import ExportSalesOrdersPage from 'pages/sales-orders-management/list';
import ManageDdtIndexPage from 'pages/ddt-management';
import SalesInvoicingRequestsIndexPage from 'pages/sales-invoicing-requests/index/index';
import PurchaseOrdersManagementIndexPage from 'pages/purchase-orders-management/list';
import ExportSalesInvoicesPage from 'pages/sales-invoices-consult/list';
import InvoiceConsultationIndexPage from 'pages/supplier-invoices-management/consultation';
import { memo } from 'react';

const RoutedBody = () => {
  return (
    <Routes>
      <Route path="/" Component={Dashboard} />
      <Route path={ROUTES.AUTH.LOGIN} Component={LoginPage} />

      <Route path={ROUTES.COMMISSION_MANAGEMENT.INDEX} Component={CommissionIndexPage} />
      <Route path={ROUTES.COMMISSION_MANAGEMENT.NEW} Component={NewCommission} />
      <Route path={ROUTES.COMMISSION_MANAGEMENT.NEW_DATA} Component={NewCommissionData} />

      <Route path="/commesse/:commissionId" Component={CommissionManagementContainer}>
        <Route path="commessa" Component={DetailsCommission} />
        <Route path="commessa/modifica" Component={EditCommissionPage} />
        <Route path="documenti" Component={CommissionDocuments} />
        <Route path="budget" Component={BudgetCrossroadsPage} />
        <Route path="budget/archivio" Component={BudgetRevisionArchivePage} />
        <Route path="budget/:version/modifica" Component={EditBudgetVersionPage} />
        <Route path="budget/:version" Component={BudgetVersionDetailsPage} />
        <Route path="approvazione-budget" Component={ApproveBudgetPage} />
        <Route path="utenti-abilitati" Component={EnabledUserPage} />
        <Route path="commesse-collegate" Component={LinkedCommissionsIndexPage} />
        <Route path="ordini-fornitori" Component={PurchaseOrderIndexPage} />
        <Route path="ordini-fornitori/crea" Component={CreatePurchaseOrderPage}>
          <Route path="step-1" Component={ChooseSupplierStepPage} />
          <Route path="step-2/:supplierId" Component={OrderDataStepPage} />
        </Route>
        <Route path="ordini-fornitori/:purchaseOrderId" Component={PurchaseOrderManagementContainer}>
          <Route path="generali" Component={PurchaseOrderHeaderPage} />
          <Route path="corpo" Component={PurchaseOrderBodyPage} />
          <Route path="scadenziario" Component={PurchaseOrderDeadlinesPage} />
          <Route path="allegati" Component={PurchaseOrderAttachmentsPage} />
          <Route path="certificati-di-pagamento">
            <Route index Component={PurchaseOrderPaymentCertificatesPage} />
            <Route path="crea/:paymentCertificateId" Component={PurchaseOrderNewPaymentCertificatePage} />
            <Route path="visualizza/:paymentCertificateId" Component={PurchaseOrderPaymentCertificateDetailPage} />
            <Route path="modifica/:paymentCertificateId" Component={PurchaseOrderPaymentCertificateEditPage} />
          </Route>
          <Route path="fatture" Component={PurchaseOrderInvoicesPage} />
        </Route>
        <Route path="ordini-clienti" Component={SalesOrderListPage} />
        <Route path="ordini-clienti/crea" Component={CreateSalesOrderPage} />
        <Route path="ordini-clienti/:salesOrderId" Component={SalesOrderManagementContainer}>
          <Route path="generali" Component={SalesOrderHeaderPage} />
          <Route path="corpo" Component={SalesOrderBodyPage} />
          <Route path="allegati" Component={SalesOrderAttachmentsPage} />
          <Route path="certificati-di-pagamento">
            <Route index Component={SalesOrderPaymentCertificatesPage} />
            <Route path="crea/:paymentCertificateId" Component={SalesOrderNewPaymentCertificatePage} />
            <Route path="visualizza/:paymentCertificateId" Component={SalesOrderPaymentCertificateDetailPage} />
            <Route path="modifica/:paymentCertificateId" Component={SalesOrderPaymentCertificateEditPage} />
          </Route>
          <Route path="fatture" Component={SalesOrderInvoicesPage} />
        </Route>
        <Route path="fatture-passive" Component={PurchaseInvoiceIndexPage} />
        <Route path="fatture-attive" Component={SalesInvoiceIndexPage} />
      </Route>
      <Route path="gestione-fatture-fornitori">
        <Route index Component={SupplierInvoicesManagementIndexPage} />
        <Route path=":purchaseInvoiceId" Component={ManageSupplierInvoicePage} />
      </Route>
      <Route path="gestione-fatture-clienti">
        <Route index Component={SalesInvoicesManagementIndexPage} />
        <Route path=":salesInvoiceId" Component={ManageSalesInvoicePage} />
      </Route>
      <Route path={ROUTES.EXPORT_INVOICEABLE_SALES_ORDERS.INDEX} Component={SalesInvoicingRequestsIndexPage} />
      <Route path={ROUTES.MANAGE_DDT} Component={ManageDdtIndexPage} />
      <Route path={ROUTES.SUPPLIER_INVOICES_MANAGEMENT.INVOICE_CONSULTATION} Component={InvoiceConsultationIndexPage} />
      <Route path={ROUTES.SALES_ORDERS_EXPORT.INDEX} Component={ExportSalesOrdersPage} />
      <Route path={ROUTES.PURCHASE_ORDERS_EXPORT.INDEX} Component={PurchaseOrdersManagementIndexPage} />
      <Route path={ROUTES.SALES_INVOICES_EXPORT.INDEX} Component={ExportSalesInvoicesPage} />
      <Route path="*" Component={NotFoundPage} />
    </Routes>
  );
};

export default memo(RoutedBody);
