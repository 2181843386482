import ReadOnlyDecimalNumber from 'components/utils/ReadOnlyDecimalNumber';
import { Fragment } from 'react';

import styles from 'components/commission-management/budget/BudgetTableStyles';
import { stringifyPeriod, calculateActivityTotal } from 'helpers/budget';

const BudgetTableActivityRow = ({ activity, periods, collapsed }) => {
  const activityMaxCost = calculateActivityTotal(activity, (s) => s.cost);
  const activityMaxRevenue = calculateActivityTotal(activity, (s) => s.revenue);
  return (
    <tr className={styles['budget-row--activity']}>
      <th className="text-center">
        {activity.id}
      </th>
      <th colSpan={2}></th>
      <th>{activity.description}</th>
      {/* <th>{activity.soaReference}</th> */}
      {/* <th>{activity.soa?.description}</th> */}
      <th className={styles['td--numeric']}>
        <ReadOnlyDecimalNumber n={activityMaxCost} />
      </th>
      <th className={styles['td--numeric']}>
        <ReadOnlyDecimalNumber n={activityMaxRevenue} />
      </th>
      {!collapsed &&
        periods.map((period) => (
          <Fragment key={stringifyPeriod(period)}>
            <th className={styles['td--numeric']}>{/* activity cost goes here */}</th>
            <th className={styles['td--numeric']}>{/* activity revenue goes here */}</th>
          </Fragment>
        ))}
      <th className={styles['td--numeric']}></th>
      <th className={styles['td--numeric']}></th>
    </tr>
  );
};

export default BudgetTableActivityRow;
