import { Col, Form, Row } from 'reactstrap';
import DeliveryPlaceFormSection from './DeliveryPlaceFormSection';
import PurchaseOrderFormSection from './PurchaseOrderFormSection';
import SupplierFormSectionData from './SupplierFormSectionData';
import { SaveButton, VoidOrderButton, ExportAsPdfButton } from 'components/buttons';
import useUnsavedChanges from 'hooks/useUnsavedChanges';

const PurchaseOrderHeaderForm = ({
  readonly = false,
  voidable = false,
  exportableAsPdf = false,
  defaultValues = {},
  supplier,
  sites,
  paymentTerms,
  onSubmit,
  onVoid,
  onExportAsPdf,
}) => {
  const { notify: notifyUnsavedChanges } = useUnsavedChanges();

  const handleSubmit = (e) => {
    e.preventDefault();
    const rawFormData = new FormData(e.target).toObject();

    const guaranteeWithholdingId = parseInt(rawFormData.guaranteeWithholdingType);

    const formData = {
      currencyId: rawFormData.currency,
      currencyExchangeRate:
        rawFormData.currency !== 'EUR'
          ? parseFloat(rawFormData.exchangeRateAmount.replace('.', '').replace(',', '.'))
          : undefined,
      currencyExchangeRateDate: rawFormData.exchangeRateDate,
      deliveryDate: rawFormData.deliveryDate,
      guaranteeWithholdingId,
      guaranteeWithholdingPercentage: guaranteeWithholdingId
        ? parseFloat(rawFormData.guaranteeWithholdingValue.replace('.', '').replace(',', '.'))
        : undefined,
      typeId: parseInt(rawFormData.typeId),
      paymentTermId: parseInt(rawFormData.paymentTerms),
      paymentTermModificationTypeId: parseInt(rawFormData.paymentTermsModificationType),
      paymentTermModificationNote: rawFormData.paymentTermsModificationNote,
      shortDescription: rawFormData.shortDescription,
      deliveryPlaceId: parseInt(rawFormData.site) <= 0 ? null : parseInt(rawFormData.site),
      exportedFileLanguageId: rawFormData.exportedFileLanguageId,
      otherDeliveryPlace: rawFormData.otherDeliveryPlace,
    };
    console.debug(formData);
    onSubmit(formData);
  };

  const handleOnFormChange = () => {
    notifyUnsavedChanges();
  };

  return (
    <Form onSubmit={handleSubmit} onChange={handleOnFormChange}>
      <Row>
        <Col xl={6}>
          <PurchaseOrderFormSection
            readonly={readonly}
            purchaseOrder={defaultValues}
            supplier={supplier}
            className="mb-1"
          />
        </Col>
        <Col xl={6}>
          <SupplierFormSectionData
            readonly={readonly}
            supplier={supplier}
            purchaseOrder={defaultValues}
            paymentTerms={paymentTerms}
          />
          <DeliveryPlaceFormSection
            readonly={readonly}
            deliveryPlaces={sites}
            initialValues={{ site: defaultValues.site, otherDeliveryPlace: defaultValues.otherDeliveryPlace }}
          />
        </Col>
      </Row>
      <div>
        {!readonly && <SaveButton className="mr-1" />}
        {exportableAsPdf && <ExportAsPdfButton onClick={onExportAsPdf} color="primary" className="mr-1" />}
        {voidable && <VoidOrderButton onClick={onVoid} className="mr-1" />}
      </div>
    </Form>
  );
};

export default PurchaseOrderHeaderForm;
