import ToastContainer from 'components/commission-management/shared/feedbacks/ToastContainer';
import FeedbackContext from 'contexts/FeedbackContext';
import { useCallback, useMemo, useState } from 'react';

const createRandomMessageId = () => {
  return (Date.now() + Math.random()).toString(36);
};

const FeedbackQueue = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const enqueueToast = useCallback(({ type, message, title }) => {
    const messageId = createRandomMessageId();
    setMessages((messages) => [{ id: messageId, type, title, message }, ...messages]);
  }, []);

  const handleOnToastRemove = useCallback((messageId) => {
    setMessages((messages) => messages.filter((message) => message.id !== messageId));
  }, []);

  const contextValue = useMemo(
    () => ({
      success: (message, title) => enqueueToast({ type: 'success', message, title }),
      warning: (message, title) => enqueueToast({ type: 'warning', message, title }),
      info: (message, title) => enqueueToast({ type: 'info', message, title }),
      error: (message, title) => enqueueToast({ type: 'error', message, title }),
    }),
    [enqueueToast]
  );

  return (
    <FeedbackContext.Provider value={contextValue}>
      <ToastContainer messages={messages} onToastRemove={handleOnToastRemove} />
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackQueue;
