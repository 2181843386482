const { createErrorResponse, createSuccessfullResponseWithData, fetchJson } = require('helpers/api');

/**
 * @param {{companyId: string, commissionId: string?, salesOrderId: string?, customerId: string?}} filters
 */
const fetchInvoiceableSalesOrders = async (filters) => {
  try {
    const response = await fetch(`/api/invoiceable-sales-orders?${new URLSearchParams(filters)}`);
    const responseBody = await response.json();
    if (response.ok) {
      return createSuccessfullResponseWithData(responseBody);
    } else {
      return createErrorResponse(responseBody.detail);
    }
  } catch (err) {
    console.error(err);
    return createErrorResponse();
  }
};

const fetchInvoiceableSalesOrderLines = async (salesOrderId) => {
  return fetchJson(`/api/invoiceable-sales-orders/${salesOrderId}/lines`);
};

export { fetchInvoiceableSalesOrders, fetchInvoiceableSalesOrderLines };
