import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

const PageTitle = ({ children }) => <h1 className="h3 mb-1 mb-sm-0 text-gray-800">{children}</h1>;

const PageButtonContainer = ({ children }) => <div>{children}</div>;

const PageButton = ({ onClick, text, iconName, primary = false }) => (
  <Button size="sm" color="primary" outline={!primary} onClick={onClick}>
    <i className={`fas fa-${iconName} fa-sm ${primary ? 'text-white-50' : ''} align-middle`}></i>{' '}
    <span className="align-middle">{text}</span>
  </Button>
);

const PageLink = ({ to, text, iconName, primary = false }) => (
  <NavLink to={to} className={`btn btn-sm ${primary ? 'btn-primary' : 'btn-outline-primary'}`}>
    <i className={`fas fa-${iconName} fa-sm ${primary ? 'text-white-50' : ''} align-middle`}></i>{' '}
    <span className="align-middle">{text}</span>
  </NavLink>
);

const PageHeader = ({ title, children }) => {
  return (
    <header className="d-sm-flex align-items-center justify-content-between mb-1">
      <PageTitle>{title}</PageTitle>
      {children}
    </header>
  );
};

export default PageHeader;
export { PageTitle, PageButton, PageButtonContainer, PageLink };
