import { format } from 'helpers/amount';
import { displayDate, displayDateTimeHourMinute } from 'helpers/date';
import { ColumnFilterOptionsBuilder, DataTableBuilder, DataTableButton, InfoColumnHeader } from 'modules/DataTable';
import { NavLink } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { routeFactory } from 'routes';

const STATUS_COLORS = {
  0: 'dark',
  1: 'warning',
  2: 'success',
  3: 'danger',
  4: 'primary',
  5: 'danger',
};

const STATUS_ICONS = {
  0: 'fa fa-pen-alt',
  1: 'fa fa-user-clock',
  2: 'fa fa-check',
  3: 'fa fa-pen-alt',
  4: 'fa fa-university',
  5: 'far fa-stop-circle',
};

const PurchaseOrderStatusBadge = ({ statusId, statusName }) => (
  <Badge color={STATUS_COLORS[statusId]} style={{ fontWeight: 400, borderRadius: '.2rem' }}>
    <i className={STATUS_ICONS[statusId]} /> {statusName}
  </Badge>
);

const BoolCheck = ({ check }) => <i className={`fas fa-check text-${check ? 'success' : 'secondary opacity-30'}`}></i>;

const ExportPdfIcon = () => <i className="fas fa-file-pdf"></i>;

const ExportPdfButton = ({ onClick, disabled, title }) => (
  <Button color="link" size="sm" className="mx-1 p-0" onClick={onClick} disabled={disabled} title={title}>
    <ExportPdfIcon />
  </Button>
);

const createCommissionHref = (commissionId) => routeFactory.commissionManagement.commissions.detail(commissionId);
const createPurchaseOrderHref = (commissionId, purchaseOrderId) =>
  routeFactory.commissionManagement.purchaseOrders.detail(commissionId, purchaseOrderId);

const createPurchaseOrderAttachmentHref = (commissionId, purchaseOrderId) =>
  routeFactory.commissionManagement.purchaseOrders.attachments(commissionId, purchaseOrderId);

const FICTITIOUS_ORDER_FILTER_OPTIONS = new ColumnFilterOptionsBuilder()
  .addOption(false, 'No')
  .addOption(true, 'Sì')
  .build();

const { columns } = new DataTableBuilder()
  .addColumn('purchaseOrderId', (config) => {
    config
      .heading('N° ordine')
      .filterable(true)
      .sortable(true)
      .cellTemplate((purchaseOrderId, { commissionId }) => (
        <NavLink to={createPurchaseOrderHref(commissionId, purchaseOrderId)}>{purchaseOrderId}</NavLink>
      ));
  })
  .addColumn('creationDate', (config) => {
    config
      .heading('Data')
      .type('date')
      .size('md-1')
      .filterable(true)
      .sortable(true)
      .horizontalAlignment('right')
      .cellTemplate((creationDate) => creationDate && displayDateTimeHourMinute(new Date(creationDate)));
  })
  .addColumn('deliveryDate', (config) => {
    config
      .heading('Consegna prevista')
      .type('date')
      .filterable(true)
      .sortable(true)
      .horizontalAlignment('right')
      .cellTemplate((deliveryDate) => deliveryDate && displayDate(new Date(deliveryDate)));
  })
  .addColumn('supplierCode', (config) => {
    config.heading('Cod. fornitore').filterable(true).sortable(true).horizontalAlignment('right');
  })
  .addColumn('supplierName', (config) => {
    config.heading('Rag. sociale fornitore').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('supplierVatNumber', (config) => {
    config.heading('P.IVA fornitore').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('supplierAddress', (config) => {
    config.heading('Indirizzo fornitore').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('amount', (config) => {
    config
      .heading('Totale imponibile')
      .type('number')
      .filterable(false)
      .sortable(true)
      .horizontalAlignment('right')
      .cellTemplate((amount) => format(amount));
  })
  .addColumn('currencyName', (config) => {
    config.size('sm').heading('Valuta').filterable(true).filterType('select').sortable(true);
  })
  .addColumn('paymentTermName', (config) => {
    config.heading('Condiz. di pagamento').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('commissionId', (config) => {
    config
      .heading('N° comm.')
      .filterable(true)
      .sortable(true)
      .cellTemplate((commissionId) => <NavLink to={createCommissionHref(commissionId)}>{commissionId}</NavLink>);
  })
  .addColumn('customerName', (config) => {
    config.heading('Rag. sociale cliente').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('commissionDescription', (config) => {
    config.heading('Descriz. comm.').filterable(true).truncate(true).tooltip(true);
  })
  .addColumn('status', (config) => {
    config
      .heading('Stato autorizz. ordine')
      .size('md-2')
      .filterable(true)
      .filterType('select')
      .sortable(true)
      .truncate(true)
      .tooltip(true)
      .tooltipTemplate((status) => status.description)
      .cellTemplate((status) => <PurchaseOrderStatusBadge statusId={status.id} statusName={status.description} />);
  })
  .addColumn('writtenByName', (config) => {
    config.heading('Redatto da').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('approvedByName', (config) => {
    config.heading('Approvato da').filterable(true).sortable(true).truncate(true).tooltip(true);
  })
  .addColumn('note', (config) => {
    config.heading('Note').filterable(true).sortable(false).truncate(true).tooltip(true);
  })
  .addColumn('isFictitiousOrder', (config) => {
    config
      .heading('Fuori ordine')
      .filterable(true)
      .filterType('select')
      .filterOptions(FICTITIOUS_ORDER_FILTER_OPTIONS)
      .sortable(true)
      .horizontalAlignment('center')
      .cellTemplate((isFictitiousOrder) => <BoolCheck check={isFictitiousOrder} />);
  })
  .addColumn('exportable', (config) => {
    config
      .heading(<InfoColumnHeader title="Anteprima ordine" />)
      .size('xxs')
      .horizontalAlignment('center')
      .cellTemplate((_, { purchaseOrderId, exportLanguageId, exportable }, { handleExportAsPdf }) => (
        <ExportPdfButton
          onClick={() => handleExportAsPdf(purchaseOrderId, exportLanguageId)}
          disabled={!exportable}
          title={exportable ? `Anteprima ordine ${purchaseOrderId}` : undefined}
        />
      ));
  })
  .addColumn('purchaseOrderId', (config) => {
    config
      .heading(<InfoColumnHeader title="Elenco doc. allegati" />)
      .size('xxs')
      .horizontalAlignment('center')
      .cellTemplate((purchaseOrderId, { commissionId }) => (
        <NavLink
          to={createPurchaseOrderAttachmentHref(commissionId, purchaseOrderId)}
          title={`Visualizza allegati ordine ${purchaseOrderId}`}
        >
          <i className="fa fa-paperclip"></i>
        </NavLink>
      ));
  })
  .addColumn('approvalRequestable', (config) => {
    config
      .heading(<InfoColumnHeader title="Richiedi approvazione ordine" />)
      .size('xxs')
      .horizontalAlignment('center')
      .cellTemplate((approvalRequestable, { purchaseOrderId }, { handleRequestApprovable }) => (
        <DataTableButton
          iconName="paper-plane"
          title={`Richiedi approvazione ordine ${purchaseOrderId}`}
          onClick={() => handleRequestApprovable(purchaseOrderId)}
          disabled={!approvalRequestable}
        />
      ));
  })
  .addColumn('approvable', (config) => {
    config
      .heading(<InfoColumnHeader title="Approva ordine" />)
      .size('xxs')
      .horizontalAlignment('center')
      .cellTemplate((approvable, { purchaseOrderId }, { handleApprove }) => (
        <DataTableButton
          iconName="thumbs-up"
          title={`Approva ordine ${purchaseOrderId}`}
          onClick={() => handleApprove(purchaseOrderId)}
          disabled={!approvable}
        />
      ));
  })
  .addColumn('changesRequestable', (config) => {
    config
      .heading(<InfoColumnHeader title="Richiedi modifiche ordine" />)
      .size('xxs')
      .horizontalAlignment('center')
      .cellTemplate((changesRequestable, { purchaseOrderId }, { handleRequestChangesModalShow }) => (
        <DataTableButton
          iconName="thumbs-down"
          title={`Richiedi modifiche ordine ${purchaseOrderId}`}
          onClick={() => handleRequestChangesModalShow(purchaseOrderId)}
          disabled={!changesRequestable}
        />
      ));
  })
  .build();

export { columns as COLUMNS };
