import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

HTMLFormElement.prototype.getObjectData = function () {
  const formData = new FormData(this);
  return formData.toObject();
};

HTMLFormElement.prototype.serializeToJson = function () {
  const formData = new FormData(this);
  return formData.toJson();
};

FormData.prototype.toObject = function () {
  const obj = {};
  for (var key of this.keys()) {
    obj[key] = this.get(key);
  }
  return obj;
};

FormData.prototype.toJson = function () {
  return JSON.stringify(this.toObject());
};

const root = ReactDOM.createRoot(document.getElementById('root'));

 root.render(
   <React.StrictMode>
     <BrowserRouter>
       <App />
     </BrowserRouter>
   </React.StrictMode>
 );

//root.render(
//  <BrowserRouter>
//    <App />
//  </BrowserRouter>
//);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
