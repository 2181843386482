const MAX_DATE_FILTER_TIMESTAMP = Date.parse('9999-12-31');
const MIN_DATE_FILTER_TIMESTAMP = 0;
const MIN_DATE_FILTER = new Date(MIN_DATE_FILTER_TIMESTAMP).toISOString().substring(0, 10);
const MAX_DATE_FILTER = new Date(MAX_DATE_FILTER_TIMESTAMP).toISOString().substring(0, 10);

const parseDateToFilter = (isoFormatDate) => {
  const unixTimestamp = Date.parse(isoFormatDate);
  // discard the filter if the date is invalid or if it is after the max allowed
  if (isNaN(unixTimestamp) || unixTimestamp > MAX_DATE_FILTER_TIMESTAMP) {
    return;
  }

  // return the date 01/01/1970 if the filter is before 01/01/1970
  if (unixTimestamp < MIN_DATE_FILTER_TIMESTAMP) {
    return MIN_DATE_FILTER;
  }

  return isoFormatDate;
};

const parseDateFromFilter = (isoFormatDate) => {
  const unixTimestamp = Date.parse(isoFormatDate);
  // discard the filter if the date is invalid or its year is before 01/01/1970
  if (isNaN(unixTimestamp) || unixTimestamp < MIN_DATE_FILTER_TIMESTAMP) {
    return;
  }
  if (unixTimestamp > MAX_DATE_FILTER_TIMESTAMP) {
    return MAX_DATE_FILTER;
  }
  return isoFormatDate;
};

const stripNullOrUndefinedProperties = (sourceObject) => {
  return Object.keys(sourceObject).reduce((cleanObject, propertyName) => {
    if (sourceObject[propertyName] !== null && sourceObject[propertyName] !== undefined) {
      cleanObject[propertyName] = sourceObject[propertyName];
    }
    return cleanObject;
  }, {});
};

export { parseDateFromFilter, parseDateToFilter, stripNullOrUndefinedProperties };
