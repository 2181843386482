import { parseDateFromFilter, parseDateToFilter } from 'helpers/filters';

const handleErrorResponse = (response) => {
    switch (response.status) {
      case 401:
        return {
          success: false,
          authenticated: false,
        };
      case 403:
        return {
          success: false,
          authenticated: true,
          authorized: false,
        };
      case 500:
      case 504:
      default:
        return {
          success: false,
          message: 'Si è verificato un errore',
        };
    }
  };

  // currentPage, numRecords,
  const fetchSalesInvoices = async (commissionId, filters, currentPage, numRecords ) => {
    const queryParams = {
      CurrentPage: currentPage,
      NumRecords:  numRecords,
      BusinessNameFilter: filters?.businessName,
      InvoiceStatusFilter: filters?.status?.id,
      DateInvoiceFromFilter: parseDateFromFilter(filters?.dateInvoiceFrom),
      DateInvoiceToFilter: parseDateToFilter(filters?.dateInvoiceTo),
      RegistrationDateFromFilter: parseDateFromFilter(filters?.registrationDateFrom),
      RegistrationDateToFilter: parseDateToFilter(filters?.registrationDateTo)
    };
  
    // rimuovere i parametri con valori undefined o null
    const validParams = {};
    for (const key in queryParams) {
      if (queryParams[key] !== undefined && queryParams[key] !== null) {
        validParams[key] = queryParams[key];
      }
    }
  
    const queryString = new URLSearchParams(validParams).toString();
    const response =  await fetch(`/api/commissions/${commissionId}/sales-invoices?${queryString}`);
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
      return handleErrorResponse(response);
  };

  const fetchSalesInvoiceStatus = async () => {
    const response =  await fetch(`/api/sales-invoices/status`);
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
      return handleErrorResponse(response);
  };

  const fetchSalesInvoiceOrderStatus = async () => {
    const response =  await fetch(`/api/sales-invoice-order/status`);
  
    if (response.ok) {
      return {
        success: true,
        authenticated: true,
        authorized: true,
        data: await response.json(),
      };
    }
      return handleErrorResponse(response);
  };

  export {fetchSalesInvoices, fetchSalesInvoiceStatus, fetchSalesInvoiceOrderStatus}