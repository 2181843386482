// -- HELPERS ----------------------------------------------------------
const createErrorResponse = (message = 'Si è verificato un errore') => {
  return {
    ok: false,
    message,
  };
};

const parseResponse = async (response) => {
  if (response.ok) {
    return {
      ok: true,
    };
  } else {
    const responseBody = await response.json();
    return createErrorResponse(responseBody.detail);
  }
};

// -- API CALL ----------------------------------------------------------
const approveInvoiceOrderAssociation = async (invoiceOrderAssociationId) => {
  console.debug(`approveInvoiceOrderAssociation(${invoiceOrderAssociationId})`);
  // return await new Promise((resolve) => setTimeout(() => resolve({ ok: true }), 1000));
  try {
    const response = await fetch(`/api/purchase-invoice-order-associations/${invoiceOrderAssociationId}/approve`, {
      method: 'PATCH',
    });
    return parseResponse(response);
  } catch {
    return createErrorResponse();
  }
};

const rejectInvoiceOrderAssociation = async (invoiceOrderAssociationId, rejectRequestData) => {
  console.debug(`rejectInvoiceOrderAssociation(${invoiceOrderAssociationId}, ${JSON.stringify(rejectRequestData)})`);
  // return await new Promise((resolve) => setTimeout(() => resolve({ ok: true }), 1000));
  try {
    const response = await fetch(`/api/purchase-invoice-order-associations/${invoiceOrderAssociationId}/reject`, {
      method: 'PATCH',
      body: JSON.stringify(rejectRequestData),
      headers: { 'Content-Type': 'application/json' },
    });
    return parseResponse(response);
  } catch {
    return createErrorResponse();
  }
};

const suspendInvoiceOrderAssociation = async (invoiceOrderAssociationId, suspendRequestData) => {
  console.debug(`suspendInvoiceOrderAssociation(${invoiceOrderAssociationId}, ${JSON.stringify(suspendRequestData)})`);
  try {
    const response = await fetch(`/api/purchase-invoice-order-associations/${invoiceOrderAssociationId}/suspend`, {
      method: 'PATCH',
      body: JSON.stringify(suspendRequestData),
      headers: { 'Content-Type': 'application/json' },
    });
    return parseResponse(response);
  } catch {
    return createErrorResponse();
  }
};

const resumeInvoiceOrderAssociation = async (invoiceOrderAssociationId) => {
  console.debug(`resumeInvoiceOrderAssociation(${invoiceOrderAssociationId})`);
  // return await new Promise((resolve) => setTimeout(() => resolve({ ok: true }), 1000));
  try {
    const response = await fetch(`/api/purchase-invoice-order-associations/${invoiceOrderAssociationId}/resume`, {
      method: 'PATCH',
    });
    return parseResponse(response);
  } catch {
    return createErrorResponse();
  }
};

export {
  approveInvoiceOrderAssociation,
  rejectInvoiceOrderAssociation,
  suspendInvoiceOrderAssociation,
  resumeInvoiceOrderAssociation,
};
