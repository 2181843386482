const { createPortal } = require('react-dom');

const Loader = () => {
  return createPortal(
    <div className="loader loader-default is-active"></div>,
    document.getElementById('loader')
  );
};

export default Loader;
