const handleErrorResponse = (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        authenticated: false,
      };
    case 403:
      return {
        success: false,
        authenticated: true,
        authorized: false,
      };
      case 409:
        return {
          success: false,
          authenticated: true,
          authorized: true,
          message: "L' elemento è già presente, aggiornare la pagina"
        };
    case 500:
    case 504:
    default:
      return {
        success: false,
        message: 'Si è verificato un errore',
      };
  }
};

const fetchContractsByCommissionId = async (commissionId) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/contracts`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const insertContract = async (commissionId, postData) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/contracts`, { method: 'POST', body: postData })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const updateContract = async (commissionId, contractId, postData) => {
  console.log(contractId)
  const response = await fetch(`/api/commissions/${commissionId}/documents/contract/${contractId}`, { method: 'PUT', body: postData })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      
    };
  }
  return handleErrorResponse(response);
};
const deleteContractApi = async (commissionId, id) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/contracts/${id}`, { method: 'DELETE' })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true
    };
  }
  return handleErrorResponse(response);
};

const fetchInsuranceByCommissionId = async (commissionId) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/insurance`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json()
    };
  }
  return handleErrorResponse(response);
};

const insertInsurance = async (commissionId, postData) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/insurance`, { method: 'POST', body: postData })


  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const updateInsurance = async (commissionId, insuranceId, postData) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/insurance/${insuranceId}`, { method: 'PUT', body: postData })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      
    };
  }
  return handleErrorResponse(response);
};

const deleteInsurance = async (commissionId, id) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/insurance/${id}`, { method: 'DELETE' })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true
    };
  }
  return handleErrorResponse(response);
};

const fetchOthersByCommissionId = async (commissionId) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/others`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const insertOther = async (commissionId, postData) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/others`, { method: 'POST', body: postData })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const updateOther = async (commissionId, otherId, postData) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/other/${otherId}`, { method: 'PUT', body: postData })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      
    };
  }
  return handleErrorResponse(response);
};

const deleteOther = async (commissionId, id) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/others/${id}`, { method: 'DELETE' })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true
    };
  }
  return handleErrorResponse(response);
};

const fetchFidejussioniByCommissionId = async (commissionId) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/fidejussioni`);

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const insertFidejussioni = async (commissionId, postData) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/fidejussioni`, { method: 'POST', body: postData })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      data: await response.json(),
    };
  }
  return handleErrorResponse(response);
};

const updateFidejussioni = async (commissionId, fidejussioniId, postData) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/fidejussioni/${fidejussioniId}`, { method: 'PUT', body: postData })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true,
      
    };
  }
  return handleErrorResponse(response);
};

const deleteFidejussioni = async (commissionId, id) => {
  const response = await fetch(`/api/commissions/${commissionId}/documents/fidejussioni/${id}`, { method: 'DELETE' })

  if (response.ok) {
    return {
      success: true,
      authenticated: true,
      authorized: true
    };
  }
  return handleErrorResponse(response);
};

export {
  fetchContractsByCommissionId,
  insertContract,
  updateContract,
  deleteContractApi,
  fetchInsuranceByCommissionId,
  insertInsurance,
  updateInsurance,
  deleteInsurance,
  fetchOthersByCommissionId,
  insertOther,
  updateOther,
  deleteOther,
  fetchFidejussioniByCommissionId,
  insertFidejussioni,
  updateFidejussioni,
  deleteFidejussioni
}