const mapApproveResponseToListItem = ({ itemUpdate }) => ({
  ...itemUpdate,
  status: { id: itemUpdate.status.id, description: itemUpdate.status.name },
});

const mapRequestChangeResponseToListItem = ({ itemUpdate }) => ({
  ...itemUpdate,
  status: { id: itemUpdate.status.id, description: itemUpdate.status.name },
});

const mapRequestApprovalResponseToListItem = ({ itemUpdate }) => ({
  ...itemUpdate,
  status: { id: itemUpdate.status.id, description: itemUpdate.status.name },
});

const mapPurchaseOrdersToDataTableItems = (purchaseOrders) =>
  purchaseOrders.map(({ orderStatusId, orderStatusName, ...o }) => ({
    ...o,
    deliveryDate: new Date(o.deliveryDate),
    creationDate: new Date(o.creationDate),
    status: { id: orderStatusId, description: orderStatusName },
  }));

export {
  mapApproveResponseToListItem,
  mapRequestChangeResponseToListItem,
  mapRequestApprovalResponseToListItem,
  mapPurchaseOrdersToDataTableItems,
};
