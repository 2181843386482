import { Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import CommissionGroup from '../CommissionGroup';
import OrderGroup from '../OrderGroup';
import { useCallback } from 'react';
import PaymentCertificateHeader from '../PaymentCertificateHeader';

const PaymentCertificateList = ({ children }) => {
  return <ListGroup>{children}</ListGroup>;
};

PaymentCertificateList.Item = ({ commissionId, salesOrderId, paymentCertificate, associable = true, onAssociate }) => {
  const handleAssociateButtonClick = () => {
    onAssociate(paymentCertificate);
  };
  return (
    <ListGroupItem className="p-2">
      <Row>
        <Col md={8} lg={10} className="d-flex flex-column justify-content-center">
          <PaymentCertificateHeader
            number={paymentCertificate.number}
            date={paymentCertificate.date}
            taxableAmount={paymentCertificate.taxableAmount}
            note={paymentCertificate.note}
            commissionId={commissionId}
            salesOrderId={salesOrderId}
          />
        </Col>
        <Col md={4} lg={2} className="text-right mt-1 mt-md-0 d-flex flex-column justify-content-center">
          <Button type="button" color="primary" size="sm" disabled={!associable} onClick={handleAssociateButtonClick}>
            Associa
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

const SearchResult = ({ result, onAssociate, paymentCertificatesAlreadyAssociated = {} }) => {
  const handleAssociate = (commissionId, salesOrderId) => {
    return (paymentCertificate) => {
      console.debug(result);
      const salesOrder = result.salesOrders.find((o) => o.id === salesOrderId);
      return onAssociate({
        commission: {
          id: result.commission.id,
          description: result.commission.description,
          customerDisplayName: result.commission.customerDisplayName,
        },
        salesOrder: {
          id: salesOrder.id,
          description: salesOrder.description,
          customerDisplayName: salesOrder.customerDisplayName,
        },
        paymentCertificate,
      });
    };
  };
  const isOrderPaymentCertificateAlreadyAssociated = useCallback(
    (orderId, paymentCertificateNumber) => {
      return paymentCertificatesAlreadyAssociated[orderId]?.includes(paymentCertificateNumber) ?? false;
    },
    [paymentCertificatesAlreadyAssociated]
  );
  return (
    <CommissionGroup commission={result.commission}>
      {result.salesOrders.map((order) => (
        <OrderGroup key={order.id} commissionId={result.commission.id} order={order}>
          <PaymentCertificateList>
            {order.paymentCertificates.map((paymentCertificate) => (
              <PaymentCertificateList.Item
                key={paymentCertificate.number}
                commissionId={result.commission.id}
                salesOrderId={order.id}
                paymentCertificate={paymentCertificate}
                associable={!isOrderPaymentCertificateAlreadyAssociated(order.id, paymentCertificate.number)}
                onAssociate={handleAssociate(result.commission.id, order.id)}
              />
            ))}
          </PaymentCertificateList>
        </OrderGroup>
      ))}
    </CommissionGroup>
  );
};

export default SearchResult;
