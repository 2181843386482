import { InputNumber } from 'primereact/inputnumber';

const BudgetTableNumberInput = ({ value, invalid, tooltip, ...otherProps }) => {
  const props = {
    value,
    locale: 'en-US',
    minFractionDigits: 2,
    maxFractionDigits: 2,
    style: { display: 'block' },
    inputClassName: 'p-inputtext-sm' + (invalid ? ' p-invalid' : ''),
    inputStyle: { padding: '.25rem .5rem', textAlign: 'right', width: '100%', display: 'block' },
    ...otherProps,
  };

  return <InputNumber {...props} tooltip={tooltip} />;
};

export default BudgetTableNumberInput;
