const CONTRACT_TYPE = "CONTRACT_TYPE";
const INSURANCE_TYPE = "INSURANCE_TYPE";
const OTHER_TYPE = "OTHER_TYPE";
const FIDEJUSSIONI_TYPE = "FIDEJUSSIONI_TYPE";

const SET_CONTRACTS_INITIALSTATE = "SET_CONTRACTS_INITIALSTATE";
const ADD_CONTRACT = "ADD_CONTRACT";
const EDIT_CONTRACT = "EDIT_CONTRACT";
const DELETE_CONTRACT = "DELETE_CONTRACT";
const CLEAR_CONTRACT_FORM = "CLEAR_CONTRACT_FORM";
const CLEAR_CONTRACT_ATTACHMENTS_FORM = "CLEAR_CONTRACT_ATTACHMENTS_FORM";
const CLEAR_CONTRACT_ADDENDUM_FORM = "CLEAR_CONTRACT_ADDENDUM_FORM";
const CLEAR_CONTRACTS_GENERIC_DOCS_FORM = "CLEAR_GENERIC_DOCS_FORM";

const SET_INSURANCE_INITIALSTATE = "SET_INSURANCE_INITIALSTATE";
const ADD_INSURANCE_DOC = "ADD_INSURANCE_DOC";
const UPDATE_INSURANCE_DOC = "UPDATE_INSURANCE_DOC";
const DELETE_INSURANCE = "DELETE_INSURANCE";
const SET_CONSTRUCTION_FUND = "SET_CONSTRUCTION_FUND";
const DELETE_CONSTRUCTION_FUND = "DELETE_CONSTRUCTION_FUND";
const SET_CAR_DOC = "SET_CAR_DOC";
const DELETE_CAR_DOC = "DELETE_CAR_DOC";

const SET_OTHER_DOCS_INITIALSTATE = "SET_OTHER_DOCS_INITIALSTATE"
const ADD_OTHER_DOCS = "ADD_OTHER_DOCS";
const UPDATE_OTHER_DOCS = "UPDATE_OTHER_DOCS"
const CLEAR_OTHER_DOCS_FORM = "CLEAR_OTHER_DOCS";
const DELETE_OTHER_DOCS = "DELETE_OTHER_DOCS_FORM";

const SET_FIDEJUSSIONI_INITIALSTATE = "SET_FIDEJUSSIONI_INITIALSTATE"
const ADD_FIDEJUSSIONI_DOC = "ADD_FIDEJUSSIONI_DOC";
const UPDATE_FIDEJUSSIONI_DOC = "UPDATE_FIDEJUSSIONI_DOC";
const CLEAR_FIDEJUSSIONI_FORM = "CLEAR_FIDEJUSSIONI_FORM";
const DELETE_FIDEJUSSIONI = "DELETE_FIDEJUSSIONI";

const LOADING = "LOADING";

const reduceDocuments = (state, action) => {
    //console.log(action.type);
    switch (action.type) {
        case CONTRACT_TYPE:
            return reduceContractDocuments(state, action);
        case INSURANCE_TYPE:
            return reduceInsuranceDocuments(state, action);
        case OTHER_TYPE:
            return reduceOtherDocuments(state, action);
        case FIDEJUSSIONI_TYPE:
            return reduceFidejussioniDocuments(state, action);
        case LOADING:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

const reduceContractDocuments = (state, action) => {
    //console.log(action);
    switch (action.name) {
        case SET_CONTRACTS_INITIALSTATE:
            return {...state, uploadedAllContractsType: action.payload.allContracts }
        case ADD_CONTRACT:
            return { ...state, uploadedAllContractsType:[...state.uploadedAllContractsType, action.payload]}
        case EDIT_CONTRACT:
            return {...state, uploadedAllContractsType: state.uploadedAllContractsType.map((contract) => {return contract.contractId === action.payload.contractId ? 
                {...contract, name: action.payload.name, note: action.payload.note} : contract})}
        case CLEAR_CONTRACT_FORM:
            const clearedState = { ...state };
            clearedState.formContractId = state.formContractId + 1;
            return clearedState;
        case DELETE_CONTRACT:
            return { ...state,uploadedAllContractsType: state.uploadedAllContractsType.filter((contract) => contract.contractId !== action.payload)}
        case CLEAR_CONTRACT_ATTACHMENTS_FORM:
            const clearedAttachmentsState = { ...state };
            clearedAttachmentsState.formAttachmentsId = state.formAttachmentsId + 1;
            return clearedAttachmentsState;
        case CLEAR_CONTRACT_ADDENDUM_FORM:
            const clearedAddendumState = { ...state };
            clearedAddendumState.formAddendumId = state.formAddendumId + 1;
            return clearedAddendumState;
        case CLEAR_CONTRACTS_GENERIC_DOCS_FORM:
            const clearedGenericDocsState = { ...state };
            clearedGenericDocsState.formGenericDocsId = state.formGenericDocsId + 1;
            return clearedGenericDocsState;
        default:
            return state;
    }
};

const reduceInsuranceDocuments = (state, action) => {
    switch (action.name) {
        case SET_INSURANCE_INITIALSTATE:
            return {...state,...state, uploadedAllInsuranceType: action.payload.insurances}
        case ADD_INSURANCE_DOC:
            return { ...state, uploadedAllInsuranceType:[...state.uploadedAllInsuranceType, action.payload] }
        case UPDATE_INSURANCE_DOC:
            return {...state, uploadedAllInsuranceType: state.uploadedAllInsuranceType.map((insurance) => { return insurance.insuranceId === action.payload.insuranceId ? 
                {...insurance, constructionFundNumber: action.payload.constructionFundNumber, endDate: action.payload.endDate, name: action.payload.name, 
                 policyNumber: action.payload.policyNumber, protocolNumber: action.payload.protocolNumber, startDate: action.payload.startDate} : insurance})}
            case DELETE_INSURANCE:
                return {...state, uploadedAllInsuranceType: state.uploadedAllInsuranceType.filter((x) => x.insuranceId !== action.payload)}
        case SET_CONSTRUCTION_FUND:
            return { ...state, uploadedConstructionFund: action.payload }
            case DELETE_CONSTRUCTION_FUND:
                return {...state, uploadedConstructionFund: undefined}
        case SET_CAR_DOC:
            return { ...state, uploadedCarDoc: action.payload }
            case DELETE_CAR_DOC:
                return {...state, uploadedCarDoc: undefined}
        default:
            return state;
    }
};

const reduceOtherDocuments = (state, action) => {
    //console.log(action);
    switch (action.name) {
        case SET_OTHER_DOCS_INITIALSTATE:
            return {...state, uploadedAllOthersType: action.payload.others}
        case ADD_OTHER_DOCS:
            return { ...state, uploadedAllOthersType: [...state.uploadedAllOthersType, action.payload] }
        case UPDATE_OTHER_DOCS:
            return {...state, uploadedAllOthersType: state.uploadedAllOthersType.map((other) => {return other.otherId === action.payload.otherId ? 
                {...other, policyType: action.payload.policyType, policyNumber: action.payload.policyNumber, endDate: action.payload.endDate, name: action.payload.name} : other})}     
        case CLEAR_OTHER_DOCS_FORM:
            const clearedOtherDocsState = { ...state };
            clearedOtherDocsState.formOtherDocsId = state.formOtherDocsId + 1;
            return clearedOtherDocsState;
        case DELETE_OTHER_DOCS:
            return { ...state, uploadedAllOthersType: state.uploadedAllOthersType.filter((otherDoc) => otherDoc.otherId !== action.payload) }
        default:
            return state;
    }
};

const reduceFidejussioniDocuments = (state, action) => {
    //console.log(action);
    switch (action.name) {
        case SET_FIDEJUSSIONI_INITIALSTATE:
            return {...state, uploadedAllFidejussioniType: action.payload.fidejussioni}
        case ADD_FIDEJUSSIONI_DOC:
            return { ...state, uploadedAllFidejussioniType: [...state.uploadedAllFidejussioniType, action.payload] }
        case UPDATE_FIDEJUSSIONI_DOC:
            return {...state, uploadedAllFidejussioniType: state.uploadedAllFidejussioniType.map((fidejussione) => {return fidejussione.fidejussioniId === action.payload.fidejussioniId ? 
                {...fidejussione, name: action.payload.name, amount: action.payload.amount, note: action.payload.note} : fidejussione})}
        case CLEAR_FIDEJUSSIONI_FORM:
            const clearedFidejussioniState = { ...state };
            clearedFidejussioniState.formFidejussioniId = state.formFidejussioniId + 1;
            return clearedFidejussioniState;
        case DELETE_FIDEJUSSIONI:
            return { ...state, uploadedAllFidejussioniType: state.uploadedAllFidejussioniType.filter((fidejussioniDoc) => fidejussioniDoc.fidejussioniId !== action.payload) }
        default:
            return state;
    }
};


const setContractsInitialState = (payload) =>{
    return { type: CONTRACT_TYPE, name: SET_CONTRACTS_INITIALSTATE, payload }
}

const setInsuranceInitialState = (payload) =>{
    return { type: INSURANCE_TYPE, name: SET_INSURANCE_INITIALSTATE, payload }
}

const setOtherDocsInitialState = (payload) =>{
    return { type: OTHER_TYPE, name: SET_OTHER_DOCS_INITIALSTATE, payload }
}

const setFidejussioniInitialState = (payload) =>{
    return { type: FIDEJUSSIONI_TYPE, name: SET_FIDEJUSSIONI_INITIALSTATE, payload }
}

const addContract = (payload) => {
    return { type: CONTRACT_TYPE, name: ADD_CONTRACT, payload }
}

const editContract = (payload) => {
    return { type: CONTRACT_TYPE, name: EDIT_CONTRACT, payload }
}

const deleteContract = (payload) => {
    return { type: CONTRACT_TYPE, name: DELETE_CONTRACT, payload }
}

const clearContractsForm = () => {
    return { type: CONTRACT_TYPE, name: CLEAR_CONTRACT_FORM }
}


const clearAttachmentsForm = () => {
    return { type: CONTRACT_TYPE, name: CLEAR_CONTRACT_ATTACHMENTS_FORM }
}

const clearAddendum = () => {
    return { type: CONTRACT_TYPE, name: CLEAR_CONTRACT_ADDENDUM_FORM }
}

const clearGenericDocs = () => {
    return { type: CONTRACT_TYPE, name: CLEAR_CONTRACTS_GENERIC_DOCS_FORM}
}

const addInsuranceDoc = (payload) => {
    return { type: INSURANCE_TYPE, name: ADD_INSURANCE_DOC, payload }
}

const updateInsuranceDoc = (payload) => {
    return { type: INSURANCE_TYPE, name: UPDATE_INSURANCE_DOC, payload }
}

const deleteInsuranceDoc = (payload) => {
    return { type: INSURANCE_TYPE, name: DELETE_INSURANCE, payload }
}

const setConstructionFund = (payload) => {
    return { type: INSURANCE_TYPE, name: SET_CONSTRUCTION_FUND, payload }
}
const deleteConstructionFund = (payload) => {
    return { type: INSURANCE_TYPE, name: DELETE_CONSTRUCTION_FUND, payload }
}

const setCarDoc = (payload) => {
    return { type: INSURANCE_TYPE, name: SET_CAR_DOC, payload }
}

const deleteCarDoc = (payload) => {
    return { type: INSURANCE_TYPE, name: DELETE_CAR_DOC, payload }
}

const addOtherDocs = (payload) => {
    return { type: OTHER_TYPE, name: ADD_OTHER_DOCS, payload }
}

const updateOtherDocs = (payload) => {
    return { type: OTHER_TYPE, name: UPDATE_OTHER_DOCS, payload }
}

const deleteOtherDocs = (payload) => {
    return { type: OTHER_TYPE, name: DELETE_OTHER_DOCS, payload }
}

const clearOtherDocsForm = (payload) => {
    return { type: OTHER_TYPE, name: CLEAR_OTHER_DOCS_FORM, payload }
}

const addFidejussioni = (payload) => {
    return { type: FIDEJUSSIONI_TYPE, name: ADD_FIDEJUSSIONI_DOC, payload }
}

const updateFidejussioniDoc = (payload) => {
    return { type: FIDEJUSSIONI_TYPE, name: UPDATE_FIDEJUSSIONI_DOC, payload }
}

const deleteFidejussioniDoc = (payload) => {
    return { type: FIDEJUSSIONI_TYPE, name: DELETE_FIDEJUSSIONI, payload }
}

const clearFidejussioniForm = (payload) => {
    return { type: FIDEJUSSIONI_TYPE, name: CLEAR_FIDEJUSSIONI_FORM, payload }
}

const isLoading = (payload) =>{
    return {type: LOADING, payload}
}

export {
    reduceDocuments,

    setContractsInitialState,
    addContract,
    editContract,
    deleteContract,
    clearContractsForm,
    clearAttachmentsForm,
    clearAddendum,
    clearGenericDocs,

    setInsuranceInitialState,
    addInsuranceDoc,
    updateInsuranceDoc,
    deleteInsuranceDoc,
    setConstructionFund,
    deleteConstructionFund,
    setCarDoc,
    deleteCarDoc,

    setOtherDocsInitialState,
    addOtherDocs,
    updateOtherDocs,
    deleteOtherDocs,
    clearOtherDocsForm,

    setFidejussioniInitialState,
    addFidejussioni,
    updateFidejussioniDoc,
    deleteFidejussioniDoc,
    clearFidejussioniForm,

    isLoading
};