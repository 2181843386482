const createEmptySuccessfulResponse = () => {
  return {
    ok: true,
  };
};

const createSuccessfullResponseWithData = (data) => {
  return {
    ok: true,
    data,
  };
};

const createErrorResponse = (errorMessage = 'Si è verificato un errore') => {
  return {
    ok: false,
    message: errorMessage,
  };
};

const DEFAULT_FETCH_JSON_OPTIONS = {
  mapper: (data) => data,
};

const fetchJson = async (url, options = {}) => {
  const overriddenOptions = { ...DEFAULT_FETCH_JSON_OPTIONS, ...options };
  try {
    const response = await fetch(url);
    const responseBody = await response.json();
    if (response.ok) {
      return createSuccessfullResponseWithData(overriddenOptions.mapper(responseBody));
    } else {
      return createErrorResponse(responseBody.detail);
    }
  } catch {
    return createErrorResponse();
  }
};

const patch = async (url) => {
  let handled = false;
  try {
    const response = await fetch(url, { method: 'PATCH' });
    if (!response.ok) {
      const data = await response.json();
      handled = true;
      throw new Error(data.detail || 'Si è verificato un errore');
    }
    return response;
  } catch (err) {
    if (handled) {
      throw err;
    }
    console.error(err);
    throw new Error('Si è verificato un errore');
  }
};

const fetchJson2 = async (url, cancellationToken) => {
  let handled = false;
  try {
    const response = await fetch(url, { signal: cancellationToken });
    const data = await response.json();
    if (response.ok) {
      return data;
    }
    handled = true;
    throw new Error(data.detail || 'Si è verificato un errore');
  } catch (err) {
    if (handled) {
      throw err;
    }
    // console.error(err);
    throw new Error('Si è verificato un errore');
  }
};

const fetchRemoteFile = async (url) => {
  let handled = false;
  try {
    const response = await fetch(url);
    if (response.ok) {
      const file = await response.blob();
      const contentDispositionHeaderValue = response.headers.get('Content-Disposition');
      //console.log(contentDispositionHeaderValue);
      const fileName = contentDispositionHeaderValue?.split(';')[1].split('=')[1];
      //console.log(fileName);
      return { file, fileName };
    }
    const data = await response.json();
    handled = true;
    throw new Error(data.detail || 'Si è verificato un errore');
  } catch (err) {
    if (handled) {
      throw err;
    }
    console.error(err);
    throw new Error('Si è verificato un errore');
  }
};

const downloadFile = (file, fileName) => {
  const fileTemporaryUrl = window.URL.createObjectURL(file);
  const temporaryAnchorTag = document.createElement('a');
  temporaryAnchorTag.setAttribute('href', fileTemporaryUrl);
  if (fileName) {
    temporaryAnchorTag.setAttribute('download', fileName);
  } else {
    temporaryAnchorTag.setAttribute('target', '_blank');
  }
  temporaryAnchorTag.click();
  window.URL.revokeObjectURL(fileTemporaryUrl);
};

const openFileInNewTab = (file) => {
  const fileTemporaryUrl = window.URL.createObjectURL(file);
  window.open(fileTemporaryUrl);
};

const downloadRemoteFile = async (url) => {
  return fetchRemoteFile(url).then(({ file, fileName }) => {
    downloadFile(file, fileName);
    return new Promise((resolve) => resolve());
  });
};

export {
  createErrorResponse,
  createEmptySuccessfulResponse,
  createSuccessfullResponseWithData,
  fetchJson,
  fetchJson2,
  fetchRemoteFile,
  downloadRemoteFile,
  downloadFile,
  patch,
  openFileInNewTab,
};
