import { useState } from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomCurrencyInput from './customCurrencyInput';
import { formatStringInFloat } from 'helpers/formatters';

const RowAttivita = (props) => {
  const [minMax, setMinMax] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [className, setClassName] = useState('');
  const [valueDisabled, setValueDisabled] = useState(props.Activity.selectedAttivita === -1 || props.Activity.selectedConto === -1);

  const selectedAttivitaChange = (event) => {
    props.OnChanged({
      purchaseInvoiceOrderLineId: props.Activity.purchaseInvoiceOrderLineId,
      Commission: props.Commission,
      PurchaseOrder: props.PurchaseOrder.name,
      selectedAttivita: parseInt(event.target.value),
      selectedConto: props.Activity.selectedConto,
      importo: props.Activity.importo !== undefined ? props.Activity.importo : 0,
    });
  };

  const getMinMax = (selectedConto) => {
    var selConto = props.elencoConti.filter((c) => c.id === selectedConto ? c : false );
    if(selConto[0].remainder < 0) {
        setMinMax({ min: undefined, max: 0 });
        setValueDisabled(false);
        return { min: undefined, max: 0 };
    }
    else if(selConto[0].remainder > 0) {
      setMinMax({ min: 0, max: undefined });
      setValueDisabled(false);
      return { min: 0, max: undefined };
    }
    else {
      console.log("not found");
      setValueDisabled(true);
      // console.log(props.elencoConti);
    }
  }

  const selectedContoChange = (event) => {
    let minMaxTemp = getMinMax(event.target.value);
    let tempImporto = formatStringInFloat(props.Activity.importo);
    setErrorMessage("");
    setClassName("");
    // se il value non è nel range devo resettarlo
    if((minMaxTemp.max !== undefined && tempImporto > minMaxTemp.max) || (minMaxTemp.min !== undefined && tempImporto < minMaxTemp.min)) {
      setErrorMessage(`${(minMaxTemp.max !== undefined && tempImporto > minMaxTemp.max) ? 'Il valore non può essere maggiore di ' + minMaxTemp.max : 'Il valore non può essere minore di ' + minMaxTemp.min}`);
      setClassName("is-invalid");
    }
    props.OnChanged({
      purchaseInvoiceOrderLineId: props.Activity.purchaseInvoiceOrderLineId,
      Commission: props.Commission,
      PurchaseOrder: props.PurchaseOrder.name,
      selectedAttivita: props.Activity.selectedAttivita,
      selectedConto: parseInt(event.target.value),
      importo: tempImporto
    });
  };

  const importoChange = (value, name, _values) => {
    setErrorMessage("");
    setClassName("");
    let minMaxTemp = getMinMax(props.Activity.selectedConto.toString());
    if (_values === undefined) return;
    // se il value non è nel range devo resettarlo
    if ((_values.float === undefined) || (minMaxTemp.max !== undefined && Number(_values.float) > minMaxTemp.max) || (minMaxTemp.min !== undefined && Number(_values.float) < minMaxTemp.min)) {
      setErrorMessage(`${(minMaxTemp.max !== undefined && Number(_values.float) > minMaxTemp.max) ? 'Il valore non può essere maggiore di ' + minMaxTemp.max : 'Il valore non può essere minore di ' + minMaxTemp.min}`);
      setClassName("is-invalid");
      // value = "0,00";
    }

    // aggiunto questo if perchè l'input mi triggera ogni volta che perdo il focus inutilmente
    if((value !== undefined ? value.replace(",00", "") : 0) !== props.Activity.importo.toString().replace(",00", "")) {
      props.OnChanged({
        purchaseInvoiceOrderLineId: props.Activity.purchaseInvoiceOrderLineId,
        Commission: props.Commission,
        PurchaseOrder: props.PurchaseOrder.name,
        selectedAttivita: props.Activity.selectedAttivita,
        selectedConto: props.Activity.selectedConto,
        importo: value !== undefined ? value : 0
      });
    }
  };

  const deleted = () => {
    props.OnDeleted({
      purchaseInvoiceOrderLineId: props.Activity.purchaseInvoiceOrderLineId,
      Commission: props.Commission,
      selectedAttivita: props.Activity.selectedAttivita,
      PurchaseOrder: props.PurchaseOrder.name,
    });
  };

  // const selectedReminder = () => {
  //   if(props.Activity.selectedConto) {
  //     var selConto = props.elencoConti.filter((c) => c.id === props.Activity.selectedConto ? c : false );
  //     if(selConto.length > 0) {
  //       return selConto.remainder;
  //     }
  //   }
  //   return false;
  // }
  return (
    // <Form style={{position: 'relative', background: props.background() }}>
    <Form style={{ position: 'relative', background: props.background(), padding: '1px 2px 5px 5px' }}>
      {props.isDeleted && (
        <Label
          style={{
            position: 'absolute',
            top: '5px',
            left: '5px',
            right: '5px',
            bottom: '5px',
            zIndex: '1',
            background: '#f5f5f5a3',
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '20px',
            color: '#e74a3b',
            fontWeight: 'bold',
          }}
        >
          Elemento cancellato (salva per rendere effettivo)
        </Label>
      )}
      <Row className="mt-1">
          {/* {props.Activity && JSON.stringify(props) } */}
          {/* {props.Disabled.toString() + "--" + valueDisabled.toString() } */}
        <Col sm="4">
          <Input
            bsSize="sm"
            disabled={props.Disabled}
            type="select"
            value={props.Activity.selectedAttivita}
            onChange={selectedAttivitaChange}
            required
            invalid={props.Activity.error !== '' && props.Activity.error != null}
          >
            {props.elencoAttivita?.map((el) => {
              return (
                <option key={el.id} value={el.id} disabled={el.disabled}>
                  {el.value}
                </option>
              );
            })}
          </Input>
          <FormFeedback tooltip>{props.Activity.error}</FormFeedback>
        </Col>
        <Col sm="4">
          <Input
            bsSize="sm"
            disabled={props.Disabled}
            type="select"
            value={props.Activity.selectedConto}
            onChange={selectedContoChange}
            required
            invalid={props.Activity.error !== '' && props.Activity.error != null}
          >
            {props.elencoConti?.map((e) => {
              return (
                <option key={e.id} value={e.id} disabled={e.disabled}>
                  {e.value}
                </option>
              );
            })}
          </Input>
        </Col>
        <Col sm="3">
          <FormGroup style={{ marginBottom: '0px'}}>
            <CustomCurrencyInput
              required
              min={minMax && minMax.min}
              max={minMax && minMax.max}
              value={props.Activity.importo !== undefined ? props.Activity.importo : "0"}
              intlConfig={ { locale: 'it-IT', currency: '' } }
              defaultValue="0"
              decimalsLimit={2}
              decimalScale={2}
              name='importo'
              onValueChange={importoChange}
              disabled={props.Disabled || valueDisabled}
              className={`form-control-sm form-control text-left ${className}`}
              style={{ padding: '.25rem .5rem', opacity: 0.9, textAlign: 'right', width: '100%' }} />
            {/* <CurrencyInput
              required
              min={minMax && minMax.min}
              max={minMax && minMax.max}
              value={props.Activity.importo !== undefined ? props.Activity.importo : "0"}
              // onValueChange={importoChange}
              intlConfig={ { locale: 'it-IT', currency: '' } }
              defaultValue="0"
              onKeyDown={onKeyDown}
              decimalsLimit={2}
              decimalScale={2}
              name='importo'
              onValueChange={importoChange}
              disabled={props.Disabled || valueDisabled}
              className={`form-control-sm form-control text-left ${className}`}
              style={{ padding: '.25rem .5rem', opacity: 0.9, textAlign: 'right', width: '100%' }} /> */}
            <div className="invalid-feedback" style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{errorMessage}</div>
          </FormGroup>
          {/* asdasdasd: { (props.Disabled).toString() } - { (valueDisabled).toString() } */}
          {/* <Input
            type='number'
            required
            min={minMax && minMax.min}
            max={minMax && minMax.max}
            value={props.Activity.importo}
            onChange={importoChange}
            disabled={props.Disabled || valueDisabled}
            className="form-control-sm form-control text-right"
            style={{ padding: '.25rem .5rem', opacity: 0.9, textAlign: 'right', width: '100%' }} /> */}
          {/* <InputNumber
            minFractionDigits={2}
            maxFractionDigits={2}
            required
            prefix="-"
            min={minMax && minMax.min}
            max={minMax && minMax.max}
            // min={props.Activity.remainder && props.Activity.remainder > 0 ? 0 : false}
            // max={props.Activity.remainder && props.Activity.remainder < 0 ? 0 : false}
            value={props.Activity.importo}
            // onChange={importoChange}
            onValueChange={importoChange}
            disabled={props.Disabled || valueDisabled}
            inputClassName="form-control-sm form-control text-right"
            inputStyle={{ padding: '.25rem .5rem', width: '100%', opacity: 0.9, textAlign: 'right' }}
            locale="en-US"
            style={{ width: '100%' }} /> */}
        </Col>
        <Col sm="0">
          <Button
            size="sm"
            disabled={props.Disabled}
            data-toggle="tooltip"
            data-placement="top"
            title="Elimina"
            color="danger"
            onClick={deleted}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </Col>
      </Row>
      <Label
        className="invalid-tooltip">
        {props.Activity.error}
      </Label>
    </Form>
  );
};

export default RowAttivita;
