import { format } from "helpers/amount";
import { formatISODate } from "helpers/formatters";
import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import ROUTES from "routes";
import useFetchExportSalesInvoices from "../hooks/useFetchExportSalesInvoices";
import useAuth from "hooks/useAuth";
import Loader from "components/layout/Loader";
import ErrorMessage from "components/shared/feedbacks/ErrorMessage";
import DataTable from "components/shared/DataTable";

import { downloadRemoteFile } from "helpers/api";
import useFeedback from "hooks/useFeedback";
import { Button } from "reactstrap";

const PageTitle = ({ text }) => (
  <h1 className="h3 mb-0 text-gray-800">{text}</h1>
);

const styles = {
    truncateText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  };


const DATA_TABLE_COLUMN = [
  {
    heading: "Nr. Fatt.",
    value: "invoiceNumber",
    type: "text",
    filterable: false,
    sortable: true,
    style: { ...styles.truncateText, maxWidth: "200px" },
  },
  {
    heading: "Data fattura",
    value: "emissionDate",
    type: "date",
    filterable: true,
    sortable: true,
    cellTemplate: (value) => formatISODate(value),
  },
  {
    heading: "Rag. soc. Cliente",
    value: "businessName",
    type: "text",
    filterable: true,
    sortable: true,
    style: { ...styles.truncateText, maxWidth: "200px" },
  },
  {
    heading: "Commessa",
    value: "commissionId",
    type: "text",
    filterable: true,
    sortable: true,
    cellTemplate: (val, item) => {
      return (
        <>
          <NavLink
            to={ROUTES.COMMISSION_MANAGEMENT.COMMISSIONS.DETAILS.replace(
              ":commissionId",
              val
            )}
          >
            {val}
          </NavLink>
          {' '} - {item.commissionDescription}
        </>
      );
    },
    style: { ...styles.truncateText, maxWidth: "200px" },
  },
  {
    heading: "Ordine/CDP",
    value: "salesOrderId",
    type: "text",
    filterable: true,
    sortable: true,
    cellTemplate: (val, item) => {
      return (
        <>
          <NavLink
            to={ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.PAYMENT_CERTIFICATES.DETAIL.replace(
              ":commissionId",
              item.commissionId
            ).replace(
              ":salesOrderId",
              val
            ).replace(
              ":paymentCertificateId",
              item.paymentCertificateId
            )}
          >
            {val} CDP nr. {item.paymentCertificateId}
          </NavLink>
        </>
      );
    },
    style: { ...styles.truncateText, maxWidth: "200px" },
  },
  {
    heading: "Tot. fattura",
    value: "invoiceAmount",
    type: "number",
    filterable: false,
    sortable: true,
    style: { textAlign: "right" },
    cellTemplate: (value) => format(value),
  },
  {
    heading: "Tot. associato",
    value: "associatedAmount",
    type: "number",
    filterable: false,
    sortable: true,
    style: { textAlign: "right" },
    cellTemplate: (value) => format(value),
  },
  {
    heading: "Data registrazione",
    value: "registrationDate",
    type: "date",
    filterable: false,
    sortable: true,
    cellTemplate: (value) => formatISODate(value),
  },

];

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];

const ExportSalesInvoicesPage = () => {
    const [sort, setSort] = useState({ column: "", direction: "" });
    const [numRecords, setNumRecords] = useState(INITIAL_PAGE_SIZE);
    const [downloadExcel, setDownloadExcel] = useState(false)
    const { company } = useAuth();
    const { error } = useFeedback();
    const {
      response,
      loading,
      errorMessage,
      refreshSalesInvoices,
    } = useFetchExportSalesInvoices(company.id, INITIAL_PAGE_SIZE);
    const [request, setRequest] = useState({
      companyId: company.id,
      numRecords: INITIAL_PAGE_SIZE,
      currentPage: 0,
    });

    const linkRequest = useMemo(() => {
        const data = {
          CompanyId: request.companyId,
          Sort: request.sort,
          SortColumn: request.sortColumn,
          CommissionFilter: request.commissionId,
          SalesOrderFilter: request.salesOrderId,
          BusinessNameFilter: request.businessName,
          EmissionDateFromFilter: request.emissionDateFrom,
          EmissionDateToFilter: request.emissionDateTo,
          RegistrationDateFromFilter: request.registrationDateFrom,
          RegistrationDateToFilter: request.registrationDateTo,
        };
        
        return new URLSearchParams(JSON.parse(JSON.stringify(data))).toString();
      }, [request]);

      const onHandleSort = (columnName) => {
        let sortDirection = "ASC";
        let sortColumn = undefined;
    
        if (columnName !== sort.column) {
          sortDirection = "ASC";
          sortColumn = columnName;
        } else {
          sortDirection = sort.direction === undefined ? "ASC" : sort.direction === "ASC" ? "DESC" : undefined;
          sortDirection === undefined? sortColumn = undefined : sortColumn = columnName
        }
        setSort({ column: sortColumn, direction: sortDirection });
    
        const requestToSend = {
          ...request,
          sort: sortDirection,
          sortColumn: sortColumn,
        };
    
        setRequest(requestToSend);
    
        refreshSalesInvoices(requestToSend);
      };

      const onHandleSearch = (value, field, type, newPage) => {
        const requestToSend = { ...request, currentPage: newPage };
    
        if (value === "") {
          delete requestToSend[field];
        } else {
          requestToSend[field] = type === "select" ? { id: value } : value;
        }

        console.log(requestToSend)
    
        setRequest(requestToSend);
        refreshSalesInvoices(requestToSend);
      };

      const onHandlePageChange = (newPage) => {
        const requestToSend = {
          ...request,
          currentPage: newPage,
        };
        setRequest(requestToSend);
        refreshSalesInvoices(requestToSend);
      };

      const onHandleRecordsChange = (num, page) => {
        setNumRecords(num);
    
        const requestToSend = {
          ...request,
          currentPage: page,
          numRecords: num,
        };
    
        setRequest(requestToSend);
        refreshSalesInvoices(requestToSend);
      };

      const handleDownload = () => {
        setDownloadExcel(true)
        downloadRemoteFile(`api/sales-invoices-export/export?${linkRequest}`)
        .catch((err) => error(err.message))
        .finally(() => setDownloadExcel(false))
      }

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-1">
        <PageTitle text="Consultazione fatture clienti" />
        {response && (
          <div>
            <Button
              className="shadow-sm"
              size="sm"
              color="primary"
              onClick={handleDownload}
            >
              <i className="fas fa-download fa-sm text-white-50"></i> Genera
              Excel
            </Button>
          </div>
        )}
      </div>
      {(loading || downloadExcel) && <Loader />}
      {errorMessage && <ErrorMessage text={errorMessage} />}
      {response && (
        <DataTable
          size={"sm"}
          responsive
          data={response.exportSalesInvoices}
          column={DATA_TABLE_COLUMN}
          resizable={false}
          onHandleSort={onHandleSort}
          onHandleSearch={onHandleSearch}
          onHandlePageChange={onHandlePageChange}
          onHandleRecordsChange={onHandleRecordsChange}
          totRecords={response.totRecords}
          currentPage={request.currentPage}
          pageSize={numRecords}
          sort={sort}
          pageSizes={PAGES_SIZE}
        />
      )}
    </>
  );
};

export default ExportSalesInvoicesPage;
