import { useState } from 'react';
import { Button } from 'reactstrap';

const RicercaItem = (props) => {
  const [hover, setHover] = useState(false);

  return (
    <div style={{ marginTop: '5px' }}>
      <div
        style={hover ? STYLES.tableHover : STYLES.table}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          style={{
            backgroundColor: '#ebe8e8',
            padding: '5px',
            marginRight: '5px',
            border: '1px solid #c2cbe5',
            borderRadius: '4px',
          }}
        >
          <div>
            Commessa:{' '}
            <span style={STYLES.bold}>
              {props.Commission.commissionId} - {props.Commission.description}
            </span>
          </div>
        </div>
        <div>
          {props.Commission.purchaseOrders?.map((p, index) => {
            return (
              <div
                key={p.paymentCertificateId + '-' + index}
                style={{
                  display: 'grid',
                  marginTop: '5px',
                  gridTemplateColumns: '1fr 1fr auto',
                  border: '1px solid #c2cbe5',
                  padding: '5px',
                  borderRadius: '4px',
                }}
              >
                <div style={STYLES.tableSm}>
                  <div>Ordine di acquisto: </div>
                  <span style={STYLES.bold}>{p.purchaseOrderDescription}</span>
                </div>
                <div style={STYLES.tableSm}>
                  <div>Descrizione: </div>
                  <span style={STYLES.bold}>{p.description}</span>
                </div>
                <div style={STYLES.tableSm}>
                  <div>Fornitore: </div>
                  <span style={STYLES.bold}>{p.businessName}</span>
                </div>
                <div style={STYLES.tableSm}>
                  <div>Pagamento: </div>
                  <span style={STYLES.bold}>{p.pauymentDescription}</span>
                </div>
                <div style={{ gridRowStart: '1', gridRowEnd: '3', gridColumnStart: '3' }}>
                  <Button
                    disabled={p.Disabled}
                    color="primary"
                    onClick={() => {
                      props.associa(p.purchaseOrderId, p.paymentCertificateId);
                    }}
                  >
                    Associa
                  </Button>
                </div>
              </div>
            );
          })}

          <div
            style={{
              background: '#facd91',
              display: 'grid',
              marginTop: '5px',
              marginBottom: '5px',
              gridTemplateColumns: '1fr 1fr auto',
              border: '1px solid #c2cbe5',
              padding: '5px',
              borderRadius: '4px',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>Fuori Ordine</div>
            <div style={{ gridRowStart: '1', gridRowEnd: '3', gridColumnStart: '3' }}>
              <Button disabled={props.Commission.fictitiousOrder} onClick={props.associaFuoriOrdine} color="primary">
                Associa
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const STYLES = {
  bold: {
    fontWeight: 'bold',
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
  },
  tableSm: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
  },
  tableHover: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    backgroundColor: '#9fdcef7d',
  },
  tableSmHover: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    backgroundColor: '#9fdcef7d',
  },
};

export default RicercaItem;
