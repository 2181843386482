import { Nav, NavItem, NavLink } from 'reactstrap';
import { TAB_ASSOCIATE, TAB_ASSOCIATED, TAB_DDT } from '../constants/navbar-tabs';

import styles from './navbar.module.css';

const Navbar = ({ activeTab, onTabChange, associateTabDisabled = false }) => {
  return (
    <Nav tabs>
      <NavItem>
        <NavLink
          className={`${styles['nav-link']}${associateTabDisabled ? ' ' + styles['nav-link--disabled'] : ''}${
            activeTab === TAB_ASSOCIATE ? ' active' : ''
          }`}
          onClick={() => onTabChange(TAB_ASSOCIATE)}
          disabled={associateTabDisabled}
        >
          ASSOCIA COMMESSA/ORDINE
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={`${styles['nav-link']}${activeTab === TAB_ASSOCIATED ? ' active' : ''}`}
          onClick={() => onTabChange(TAB_ASSOCIATED)}
        >
          COMMESSE/ORDINI ASSOCIATI
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={`${styles['nav-link']}${activeTab === TAB_DDT ? ' active' : ''}`}
          onClick={() => onTabChange(TAB_DDT)}
        >
          ASSOCIAZIONE DDT
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default Navbar;
