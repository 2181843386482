import Loader from "components/layout/Loader";
import DataTable from "components/shared/DataTable";
import useCommissionPermissions from "hooks/useCommissionPermissions";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import ROUTES from "routes";
import { SALES_ORDER_PERMISSIONS } from "constants/commission-management-permissions";
import useRetrieveData from "./hooks/useRetrieveData";
import { formatISODate } from "helpers/formatters";
import ErrorMessage from "components/shared/feedbacks/ErrorMessage";
import { useMemo, useState } from "react";
import { Badge } from "reactstrap";
import { format } from "helpers/amount";

const STATUS_COLORS = {
  0: "dark",
  1: "warning",
  2: "success",
  3: "danger",
  4: "primary",
  5: "danger",
};

const STATUS_ICONS = {
  0: "fa fa-pen-alt",
  1: "fa fa-user-clock",
  2: "fa fa-check",
  3: "fa fa-pen-alt",
  4: "fa fa-university",
  5: "fa fa-pause-circle",
};

const SalesOrderStatusBadge = ({ statusId, statusName }) => (
  <Badge color={STATUS_COLORS[statusId]}>
    <i className={STATUS_ICONS[statusId]} /> {statusName}
  </Badge>
);

const DATA_TABLE_COLUMN = [
  {
    heading: "Nr. Fatt.",
    value: "invoiceNumber",
    type: "text",
    filterable: false,
    sortable: false,
  },
  {
    heading: "Data fattura",
    value: "dateInvoice",
    type: "date",
    filterable: true,
    sortable: false,
    cellTemplate: (val) => {
      return formatISODate(val);
    },
  },
  {
    heading: "Cliente",
    value: "businessName",
    type: "text",
    filterable: true,
    sortable: true,
  },
  {
    heading: "P.IVA/C.F.",
    value: "vatNumberOrTaxCode",
    type: "text",
    filterable: false,
    sortable: false,
  },
  {
    heading: "Ordine-CDP",
    value: "orderCdp",
    type: "text",
    filterable: false,
    sortable: false,
    cellTemplate: (val, item, context) => {
      return context.permission ? (
        <NavLink
          to={ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.INVOICES.replace(
            ":commissionId",
            context.commissionId
          ).replace(":salesOrderId", item.salesOrderId)}
        >
          {val}
        </NavLink>
      ) : (
        <div>
          {val}
        </div>
      );
    },
  },
  {
    heading: "Tot. ordine",
    value: "orderAmount",
    type: "number",
    filterable: false,
    sortable: false,
    style: { textAlign: "right" },
    cellTemplate: (val) => {
      return format(val);
    },
  },
  {
    heading: "Tot. fattura",
    value: "invoiceAmount",
    type: "number",
    filterable: false,
    sortable: false,
    style: { textAlign: "right" },
    cellTemplate: (val, item) => {
      return format(val);
    },
  },
  {
    heading: "Tot. associato",
    value: "associatedAmount",
    type: "number",
    filterable: false,
    sortable: false,
    style: { textAlign: "right" },
    cellTemplate: (val, item) => {
      return format(val);
    },
  },
  {
    heading: "Data registrazione",
    value: "registrationDate",
    type: "date",
    filterable: true,
    sortable: true,
    cellTemplate: (val) => {
      return formatISODate(val);
    },
  },
  {
    heading: "Stato associazione",
    value: "status",
    type: "select",
    filterable: true,
    sortable: true,
    style: { width: "150px" },
    cellTemplate: (val, item) => (
     <SalesOrderStatusBadge
        statusId={item.status.id}
        statusName={item.status.description}
      />
    ),
  },
  // { heading: '', value: 'buttons', type: 'text', filterable: false, sortable: false },
];

const PAGES_SIZE = [20, 50, 100];
const INITIAL_PAGE_SIZE = PAGES_SIZE[0];

const SalesInvoiceIndexPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [numRecords, setNumRecords] = useState(INITIAL_PAGE_SIZE);
  const [searchTerms, setSearchTerms] = useState({});

  const { commissionId } = useParams();
  const { loading, response, invoiceStatuses,errorMessage, retrieveDataWithFilters } =
    useRetrieveData(commissionId, INITIAL_PAGE_SIZE);
  const { hasPermission } = useCommissionPermissions();

  const column = useMemo(() => {
    return DATA_TABLE_COLUMN.map((col) => (col.value !== 'status' ? col : { ...col, options: invoiceStatuses }));
  }, [invoiceStatuses]);

  const onHandleSearch = (value, field, type, newPage) => {
    const updatedSearchTerms = { ...searchTerms };
    if (value === "") {
      delete updatedSearchTerms[field];
    } else {
      updatedSearchTerms[field] = type === "select" ? { id: value } : value;
    }
    setSearchTerms(updatedSearchTerms);
    retrieveDataWithFilters(updatedSearchTerms, newPage, numRecords);
  };

  const onHandlePageChange = (newPage) => {
    setCurrentPage(newPage);
    retrieveDataWithFilters(searchTerms, newPage, numRecords);
  };

  const onHandleRecordsChange = (num, newPage) => {
    setCurrentPage(newPage);
    setNumRecords(num);
    retrieveDataWithFilters(searchTerms, newPage, num);
  };

  return (
    <>
      {loading && <Loader />}
        {/* <pre>{JSON.stringify(response, undefined, '\t')}</pre> */}
        {errorMessage && <ErrorMessage text={errorMessage} />}
        {response && (
          <DataTable
            responsive={true}
            data={response.salesInvoicesResponse}
            column={column}
            // onHandleSort={onHandleSort}
            onHandleSearch={onHandleSearch}
            onHandlePageChange={onHandlePageChange}
            onHandleRecordsChange={onHandleRecordsChange}
            totRecords={response.totRecords}
            currentPage={currentPage}
            pageSize={numRecords}
            context={{
              commissionId,
              permission: 
              hasPermission(
                SALES_ORDER_PERMISSIONS.INVOICES.VIEW
              ),
            }}
          />
        )}
      </>
  );
};

export default SalesInvoiceIndexPage;
