import { fetchResource, patchJson, putDataAsJson } from 'services/api';

const fetchBudgetRevisionArchive = (commissionId) => {
  return fetchResource(`/api/commissions/${commissionId}/budget-version/`);
};

const createBudgetVersion = async (commissionId, version) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}/budget-version/${version}`, {
      method: 'POST',
    });
    return {
      ok: response.ok,
      data: await response.json(),
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const updateBudgetVersion = (commissionId, version, data) => {
  return putDataAsJson(`/api/commissions/${commissionId}/budget-version/${version}`, data);
};

const fetchCurrentBudgetInfo = (commissionId) => {
  return fetchResource(`/api/commissions/${commissionId}/budget`);
};

const fetchBudgetVersion = (commissionId, version, includeActivities = false) => {
  return fetchResource(
    `/api/commissions/${commissionId}/budget-version/${version}?includeActivities=${includeActivities}`
  );
};

const fetchBudgetActivities = async (commissionId) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}/budget/approved-subactivities`);
    return {
      ok: response.ok,
      status: response.status,
      data: await response.json(),
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const requestApproval = async (commissionId, version, concurrencyStamp) => {
  const { status, ok, data } = await patchJson(
    `/api/commissions/${commissionId}/budget-version/${version}/request-approval`,
    { concurrencyStamp }
  );
  const requestApprovalResponse = { status, ok };
  //console.log(status, ok, data);
  if (ok) {
    requestApprovalResponse.updatedData = data;
  } else if (status === 400) {
    requestApprovalResponse.errors = data.errors[''];
  }
  return requestApprovalResponse;
};

const approveBudgetVersion = async (commissionId, versionId, requestData) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}/budget-version/${versionId}/approve`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });
    const responseData = await response.json();
    return {
      ok: response.ok,
      data: responseData,
      message: response.ok ? 'Il progetto è stato approvato' : responseData.detail || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const rejectBudgetVersion = async (commissionId, versionId, requestData) => {
  try {
    const response = await fetch(`/api/commissions/${commissionId}/budget-version/${versionId}/reject`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });
    const responseData = await response.json();
    return {
      ok: response.ok,
      data: responseData,
      message: response.ok ? 'Il progetto è stato rifiutato' : responseData.detail || 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

const completeApprovedSubactivity = async (commissionId, approvedSubactivityId) => {
  try {
    const response = await fetch(
      `/api/commissions/${commissionId}/budget/approved-subactivities/${approvedSubactivityId}/complete`,
      {
        method: 'PATCH',
      }
    );
    return {
      ok: response.ok,
      message: response.ok ? "L'attività è stata impostata come completata" : 'Si è verificato un errore',
    };
  } catch {
    return {
      ok: false,
      message: 'Si è verificato un errore',
    };
  }
};

export {
  createBudgetVersion,
  updateBudgetVersion,
  fetchCurrentBudgetInfo,
  fetchBudgetVersion,
  fetchBudgetRevisionArchive,
  fetchBudgetActivities,
  requestApproval,
  completeApprovedSubactivity,
  approveBudgetVersion,
  rejectBudgetVersion
};
