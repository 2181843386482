import Loader from 'components/layout/Loader';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import * as INVOICE_ASSOCIATION_STATUS from 'constants/invoice-association-statuses';
import { Button } from 'reactstrap';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { SALES_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';

import { fetchSalesOrderAssociatedInvoices, settleSalesOrder } from 'services/api/sales-order';
import SalesInvoiceAssociationList from '../sales-invoices/components/association-list';
import useSalesOrderData from './hooks/useSalesOrderData';
import { sum } from 'helpers/amount';

const SalesOrderInvoicesPage = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isOrderAlreadySettled, setIsOrderAlreadySettled] = useState(false);

  const { salesOrderId } = useParams();
  const { userId } = useAuth();
  const { error, success } = useFeedback();
  const { hasPermission } = useCommissionPermissions();
  const order = useSalesOrderData();

  const isOrderPaymentCompleted = useMemo(() => {
    const invoicedAmount =
      invoices?.reduce(
        (amount, invoiceAssociation) => sum(amount, invoiceAssociation.amountAssociatedWithInvoice),
        0
      ) ?? 0;
    console.debug('Invoiced amount: ' + invoicedAmount + ' / ' + order.totalAmount);
    return order.totalAmount === invoicedAmount;
  }, [invoices, order.totalAmount]);

  const isSettleOrderButtonVisible = useMemo(() => {
    return !isOrderAlreadySettled && hasPermission(SALES_ORDER_PERMISSIONS.SETTLE);
  }, [isOrderAlreadySettled, hasPermission]);

  const isSettleOrderButtonEnabled = useMemo(() => {
    return (
      invoices && invoices.every((i) => i.statusId === INVOICE_ASSOCIATION_STATUS.APPROVED) && isOrderPaymentCompleted
    );
  }, [isOrderPaymentCompleted, invoices]);

  useEffect(() => {
    setLoading(true);
    fetchSalesOrderAssociatedInvoices(salesOrderId)
      .then((response) => {
        if (response.ok) {
          setIsOrderAlreadySettled(response.data.isOrderAlreadySettled);
          setInvoices(response.data.items.map((item) => ({ ...item, actionsAvailable: item.approverId === userId })));
          console.log(response.data);
          setErrorMessage(false);
        } else {
          setInvoices(false);
          setErrorMessage(response.message);
          error(response.message, 'Errore');
        }
      })
      .finally(() => setLoading(false));
  }, [error, salesOrderId, userId]);

  const handleSettle = async () => {
    if (window.confirm(`Sei davvero sicuro di voler saldare questo ordine?`)) {
      setSubmitting(true);
      const response = await settleSalesOrder(salesOrderId);
      if (response.ok) {
        setIsOrderAlreadySettled(true);
        success("L'ordine è stato saldato");
      } else {
        error(response.message);
      }
      setSubmitting(false);
    }
  };

  return (
    <div>
      {(loading || submitting) && <Loader />}
      {invoices && (
        <>
          <SalesInvoiceAssociationList invoiceAssociations={invoices} />
          <div className="text-center">
            {isSettleOrderButtonVisible && (
              <Button
                type="button"
                color="primary"
                size="lg"
                onClick={handleSettle}
                disabled={!isSettleOrderButtonEnabled}
              >
                Salda ordine
              </Button>
            )}
          </div>
        </>
      )}
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
};

export default SalesOrderInvoicesPage;
