import { memo } from 'react';
import { Badge } from 'reactstrap';

const INVOICE_STATUS_BADGE_COLORS = {
  0: 'dark',
  1: 'warning', // OBSOLETE
  2: 'info',
  3: 'primary', // OBSOLETE
  4: 'success', // OBSOLETE
};

const INVOICE_STATUS_BADGE_ICONS = {
  0: 'fa fa-pen-alt',
  1: 'fa fa-exclamation-triangle', // OBSOLETE
  2: 'fa fa-check',
  3: 'fa fa-print', // OBSOLETE
  4: 'fa fa-university', // OBSOLETE
};

const INVOICE_STATUS_BADGE_SIZES = {
  lg: '1rem',
};

const InvoiceStatusBadge = ({ statusId, statusName, size = 'sm' }) => {
  const color = INVOICE_STATUS_BADGE_COLORS[statusId];
  const icon = INVOICE_STATUS_BADGE_ICONS[statusId];
  const fontSize = INVOICE_STATUS_BADGE_SIZES[size];
  return (
    <Badge color={color} style={fontSize && { fontSize }}>
      {icon && (
        <>
          <i className={icon} />{' '}
        </>
      )}
      {statusName}
    </Badge>
  );
};

export default memo(InvoiceStatusBadge);
