import ROUTES from 'routes';
import Loader from 'components/layout/Loader';
import { createSalesOrder } from 'services/api/sales-order';
import { useNavigate, useParams } from 'react-router';
import useFetchSalesOrderFormData from './hooks/useFetchSalesOrderFormData';
import useFeedback from 'hooks/useFeedback';
import useParseCustomResponse from './hooks/useParseCustomResponse';
import useEnterEditMode from 'hooks/forms/useEnterEditMode';
import SalesOrderForm from 'components/commission-management/sales-order/form';

const CreateSalesOrderPage = () => {
  const navigate = useNavigate();
  const { commissionId } = useParams();
  const { loading, response } = useFetchSalesOrderFormData(commissionId);
  // const { hasPermission } = useCommissionPermissions();
  const { error, success } = useFeedback();
  const parseCustomResponse = useParseCustomResponse();

  useEnterEditMode();

  const handleFormSubmit = (formData) => {
    console.log(formData);
    createSalesOrder({ ...formData, commissionId })
      .then((resp) => {
        const parsedResponse = parseCustomResponse(resp);
        if (parsedResponse.success) {
          success('Ordine creato con successo', 'Successo');
          const { salesOrderId } = parsedResponse.data;
          const redirectUrl = ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.HEADER.replace(
            ':commissionId',
            response.commissionData?.commissionId
          ).replace(':salesOrderId', salesOrderId);

          navigate(redirectUrl);
        }
      })
      .catch(() => error("Errore durante la creazione dell'ordine", 'Errore'));
  };

  return (
    <>
      {loading && response ? (
        <Loader />
      ) : (
        // <pre>{JSON.stringify(response, undefined, '\t')}</pre>
        <SalesOrderForm
          commission={response.commissionData}
          currencies={response.currenciesData}
          salesOrderData={response.salesOrderData}
          paymentTermsData={response.paymentTermsData.data}
          editable
          onSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};

export default CreateSalesOrderPage;
