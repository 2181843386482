import { fetchJson2 } from 'helpers/api';

const fetchPurchaseOrderDeadline = async (purchaseOrderId) => {
  const deadline = await fetchJson2(`/api/purchase-orders/${purchaseOrderId}/deadline`);
    return ({
        ...deadline,
        creationDate: new Date(deadline.creationDate),
        deliveryDate: new Date(deadline.deliveryDate),
    });
};

const updatePurchaseOrderDeadline = async (purchaseOrderId, postData) => {
  let handled = false;
  try {
    const response = await fetch(`/api/purchase-orders/${purchaseOrderId}/deadline`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || 'Si è verificato un errore');
    }
  } catch (err) {
    console.error(err);
    if (handled) {
      throw err;
    }
    throw new Error('Si è verificato un errore');
  }
};

export { updatePurchaseOrderDeadline, fetchPurchaseOrderDeadline };
