import CustomGroupBox from "components/shared/customGroupBox";

const PurchaseOrderAttachment = (props) => {

    return (<>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 100px', gap: '5px', marginBottom: '5px'}}>
            <select className="form-control asd-select" value={props.selectedPurchaseOrderAttachment === undefined ? "" : props.selectedPurchaseOrderAttachment.attachmentIdDisplayName } onChange={(p) => { props.setSelectedPurchaseOrderAttachment(props.purchaseOrderAttachments.filter((a) => a.attachmentIdDisplayName === p.target.value)[0]); }}>
                <option value="">Seleziona un documento</option>
                {props.purchaseOrderAttachments.map((p) => {
                        return <option key={p.attachmentId}>{p.attachmentIdDisplayName}</option>;
                    })}
            </select>
            <button className="btn btn-primary"
                disabled={props.selectedPurchaseOrderAttachment === undefined || props.selectedPurchaseOrderAttachment === ""}
                onClick={() => props.associaDDT()}>Associa</button>
        </div>
        <CustomGroupBox Title="DDT Associati">
            {props.associatedPurchaseOrderAttachments && props.associatedPurchaseOrderAttachments.map((a) => {
                return (<div key={a.attachmentId} className="attachment-item">
                    <button className="btn btn-danger"
                    onClick={() => props.disassociaDDT(a.attachmentId)}>Disassocia</button>
                    <span style={{ marginLeft: '5px' }}>Documento: {a.attachmentIdDisplayName} / Ordine: {a.purchaseOrderId}</span>
                </div>);
            })}
        </CustomGroupBox>
    </>)
};
export default PurchaseOrderAttachment;