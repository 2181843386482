import CustomInput from 'components/shared/forms/CustomInput';
import { ACCEPTED_DOCUMENT_TYPES } from 'config';

import { useState } from 'react';
import { Form, Row, Col, Button, Label } from 'reactstrap';

const UploadPurchaseOrderDocument = ({
  onSubmit,
  formId,
  formName,
  canEdit,
  documentTypes,
  document,
  inline = true,
  isFileRequired = true,
}) => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isDocumentTypeChosen, setIsDocumentTypeChosen] = useState(document?.documentTypeId);
  const colSpace = inline ? 2 : 12;
  const canSubmit = isDocumentTypeChosen && (!isFileRequired || isFileUploaded);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.getObjectData());
    const formData = new FormData(e.target);
    const docData = document
      ? { ...e.target.getObjectData(), attachmentId: document.attachmentId }
      : e.target.getObjectData();
    onSubmit?.(
      formData,
      docData,
      document ? documentTypes[document?.documentTypeId - 1] : documentTypes[docData.documentTypeId - 1]
    );
  };

  const handleUploadFile = (e) => {
    setIsFileUploaded(Boolean(e.target.files[0]));
  };

  const handleDocumentTypeChange = (e) => {
    setIsDocumentTypeChosen(e.target.value !== '');
  };

  return (
    <Form key={formName + formId} onSubmit={handleSubmit}>
      <Row>
        <Col lg={colSpace}>
          <CustomInput
            type="select"
            label="Tipo Documento"
            name="documentTypeId"
            onChange={handleDocumentTypeChange}
            defaultValue={document?.documentTypeId}
            disabled={!inline}
            required
          >
            <option value="">--- Seleziona</option>
            {documentTypes?.map((type) => (
              <option key={`document-type-${type.documentTypeId}`} value={type.documentTypeId}>
                {type.description}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col lg={colSpace}>
          <CustomInput
            type="file"
            accept={ACCEPTED_DOCUMENT_TYPES}
            label="Allega documento"
            name="file"
            onChange={handleUploadFile}
            required={isFileRequired}
          />
        </Col>
        <Col lg={colSpace}>
          <CustomInput
            type="text"
            label="Nr. Documento"
            name="documentNumber"
            defaultValue={document?.documentNumber}
          />
        </Col>
        <Col lg={colSpace}>
          <CustomInput type="date" label="Data" name="attachmentDate" defaultValue={document?.attachmentDate} />
        </Col>
        {inline && canEdit && (
          <Col lg={colSpace}>
            <Label className="d-block">&nbsp;</Label>
            <Button disabled={!canSubmit} title="Salva l'allegato" color="primary">
              <i className="fa fa-save" /> SALVA
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={colSpace} className={!inline ? '' : 'offset-lg-6'}>
          <CustomInput rows={4} type="textarea" label="Nota" name="note" defaultValue={document?.note} />
        </Col>
      </Row>
      {!inline && (
        <Button disabled={!canSubmit} title="Salva l'allegato" color="primary">
          <i className="fa fa-save" /> SALVA
        </Button>
      )}
    </Form>
  );
};

export default UploadPurchaseOrderDocument;
