import { memo } from 'react';
import { Badge } from 'reactstrap';

const COLORS = {
  0: 'dark',
  1: 'warning',
  2: 'success',
  4: 'danger',
};

const ICONS = {
  0: 'fa fa-pen-alt',
  1: 'fa fa-exclamation-triangle',
  2: 'fa fa-check',
  4: 'fa fa-pause',
};

const INVOICE_STATUS_BADGE_SIZES = {
  lg: '1rem',
};

const InvoiceAssociationStatusBadge = ({ statusId, statusName, size = 'sm' }) => {
  const color = COLORS[statusId];
  const icon = ICONS[statusId];
  const fontSize = INVOICE_STATUS_BADGE_SIZES[size];
  return (
    <Badge color={color} style={fontSize && { fontSize }}>
      {icon && (
        <>
          <i className={icon} />{' '}
        </>
      )}
      {statusName}
    </Badge>
  );
};

export default memo(InvoiceAssociationStatusBadge);