import UnsavedChangesAwareNavLink from 'components/shared/navigation/UnsavedChangesAwareNavLink';
import { formatNumber } from 'helpers/formatters';
import { roundNumber } from 'helpers/numbers';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ROUTES from 'routes';

const createSalesOrderIndexRoute = (commissionId) => {
  return ROUTES.COMMISSION_MANAGEMENT.SALES_ORDER.INDEX.replace(':commissionId', commissionId);
};

const ORDER_SUMMARY_COLUMN_VALUE_STYLES = {
  container: {
    fontSize: '1rem',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

const WarningMessage = ({ text }) => {
  return (
    <div className="alert alert-warning border border-warning mb-1">
      <i className="fa fa-exclamation-triangle" /> {text}
    </div>
  );
};

const OrderSummaryColumnHeader = ({ text }) => {
  return <h6 className="col-xl-auto mb-1 mb-xl-0">{text}</h6>;
};

const OrderSummaryColumnValue = ({ value }) => {
  return (
    <div style={ORDER_SUMMARY_COLUMN_VALUE_STYLES.container} title={value} className="col-xl-auto text-primary">
      {value}
    </div>
  );
};

const SalesOrderManagementSummary = ({
  commissionId,
  salesOrderId,
  orderDescription,
  customerName,
  totalAmount,
  rejectionNote,
  showRejectionNote,
}) => {
  return (
    <>
      <Card tag="header" className="header-summary header-summary-sales-order mb-1 border border-primary">
        <CardHeader className="bg-primary py-1 px-2">
          <Row className="mx-n2">
            <Col xs={11} className="header-summary__title px-2">
              Ordine cliente: <b>{salesOrderId}</b> | <span title={orderDescription}>{orderDescription}</span>
            </Col>
            <Col xs={1} className="header-summary__close px-2">
              <UnsavedChangesAwareNavLink
                to={createSalesOrderIndexRoute(commissionId)}
                end
                className={null}
                title="Esci dall'ordine corrente e torna all'elenco ordini"
              >
                &times;
              </UnsavedChangesAwareNavLink>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={6} className="border-right border-primary text-center">
              <div className="row justify-content-center align-items-baseline">
                <OrderSummaryColumnHeader text="Cliente" />
                <OrderSummaryColumnValue value={customerName} />
              </div>
            </Col>
            <Col xs={6} className="text-center">
              <div className="row justify-content-center align-items-baseline">
                <OrderSummaryColumnHeader text="Importo" />
                <OrderSummaryColumnValue value={`${formatNumber(roundNumber(totalAmount, 2))} €`} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {showRejectionNote && rejectionNote && <WarningMessage text={rejectionNote} />}
    </>
  );
};

export default SalesOrderManagementSummary;
