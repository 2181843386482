import Loader from 'components/layout/Loader';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { fetchPurchaseOrderAssociatedInvoices, settlePurchaseOrder } from 'services/api/purchase-orders';
import PurchaseInvoiceAssociationList from './components/association-list';
import useAuth from 'hooks/useAuth';
import useFeedback from 'hooks/useFeedback';
import {
  approveInvoiceOrderAssociation,
  rejectInvoiceOrderAssociation,
  resumeInvoiceOrderAssociation,
  suspendInvoiceOrderAssociation,
} from 'services/api/purchase-invoice-order-associations';
import {
  askForApprovalConfirm,
  askForRejectionConfirm,
  askForResumeConfirm,
  askForSuspensionConfirm,
  promptRejectionNote,
  promptSuspensionNote,
} from './helpers/action-prompting';
import * as INVOICE_ASSOCIATION_STATUS from 'constants/invoice-association-statuses';
import * as INVOICE_ASSOCIATION_LINE_STATUS from 'constants/invoice-association-line-statuses';
import { Button } from 'reactstrap';
import useCommissionPermissions from 'hooks/useCommissionPermissions';
import { PURCHASE_ORDER_PERMISSIONS } from 'constants/commission-management-permissions';
import useAlerts from 'hooks/useAlerts';

const PurchaseOrderInvoicesPage = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isOrderAlreadySettled, setIsOrderAlreadySettled] = useState(false);

  const { purchaseOrderId } = useParams();
  const { userId, fullName } = useAuth();
  const { error, success } = useFeedback();
  const { reloadAlerts } = useAlerts();
  const { hasPermission } = useCommissionPermissions();

  const isSettleOrderButtonVisible = useMemo(() => {
    return !isOrderAlreadySettled && hasPermission(PURCHASE_ORDER_PERMISSIONS.SETTLE);
  }, [hasPermission, isOrderAlreadySettled]);

  const isSettleOrderButtonEnabled = useMemo(() => {
    return invoices && invoices.every((i) => i.statusId === INVOICE_ASSOCIATION_STATUS.APPROVED);
  }, [invoices]);

  useEffect(() => {
    setLoading(true);
    fetchPurchaseOrderAssociatedInvoices(purchaseOrderId)
      .then((response) => {
        if (response.ok) {
          setIsOrderAlreadySettled(response.data.isOrderAlreadySettled);
          setInvoices(
            response.data.items.map((item) => ({
              ...item,
              actionsAvailable: item.approverId === userId,
            }))
          );
          setErrorMessage(false);
        } else {
          setInvoices(false);
          setErrorMessage(response.message);
        }
      })
      .finally(() => setLoading(false));
  }, [purchaseOrderId, userId]);

  const handleApprove = async (invoiceOrderAssociationId) => {
    console.debug(`Approving ${invoiceOrderAssociationId}`);

    const invoiceAssociation = invoices.find((item) => item.id === invoiceOrderAssociationId);

    if (!askForApprovalConfirm(invoiceAssociation)) {
      return;
    }

    setSubmitting(true);

    const response = await approveInvoiceOrderAssociation(invoiceOrderAssociationId);

    if (response.ok) {
      success(`Approvazione avvenuta con successo.`);
      reloadAlerts();
      setInvoices((invoices) => {
        const invoiceAssociationApprovedAmounts = {};
        for (let ln of invoiceAssociation.lines.filter(
          (ln) => ln.statusId === INVOICE_ASSOCIATION_LINE_STATUS.WAITING_FOR_APPROVAL
        )) {
          //console.debug(ln);
          invoiceAssociationApprovedAmounts[ln.lineId] =
            (invoiceAssociationApprovedAmounts[ln.lineId] ?? 0) + ln.invoiceAmount;
        }
        //console.debug(invoiceAssociationApprovedAmounts);

        return invoices.map((invoiceOrder) =>
          invoiceOrder.id === invoiceOrderAssociationId
            ? {
                ...invoiceOrder,
                statusId: INVOICE_ASSOCIATION_STATUS.APPROVED,
                lines: invoiceOrder.lines.map((ln) =>
                  invoiceAssociationApprovedAmounts[ln.lineId] !== undefined
                    ? {
                        ...ln,
                        statusId: INVOICE_ASSOCIATION_LINE_STATUS.APPROVED,
                        orderAmountRemainder: ln.orderAmountRemainder - invoiceAssociationApprovedAmounts[ln.lineId],
                      }
                    : ln
                ),
              }
            : invoiceOrder.lines.some((ln) => invoiceAssociationApprovedAmounts[ln.lineId] !== undefined)
            ? {
                ...invoiceOrder,
                lines: invoiceOrder.lines.map((ln) =>
                  invoiceAssociationApprovedAmounts[ln.lineId] !== undefined
                    ? {
                        ...ln,
                        orderAmountRemainder: ln.orderAmountRemainder - invoiceAssociationApprovedAmounts[ln.lineId],
                      }
                    : ln
                ),
              }
            : invoiceOrder
        );
      });
    } else {
      error(response.message);
    }

    setSubmitting(false);
  };

  const handleReject = async (invoiceOrderAssociationId) => {
    console.debug(`Rejecting ${invoiceOrderAssociationId}`);

    const invoiceAssociation = invoices.find((item) => item.id === invoiceOrderAssociationId);
    // console.debug(invoiceAssociation);

    const rejectionNotePromptResult = promptRejectionNote(invoiceAssociation);

    if (rejectionNotePromptResult.canceled || !askForRejectionConfirm(invoiceAssociation)) {
      return;
    }

    setSubmitting(true);

    const response = await rejectInvoiceOrderAssociation(invoiceOrderAssociationId, {
      note: rejectionNotePromptResult.enteredText,
    });

    if (response.ok) {
      success(`Restituzione al mittente avvenuta con successo.`);
      reloadAlerts();
      setInvoices((invoices) => invoices.filter((invoice) => invoice.id !== invoiceOrderAssociationId));
    } else {
      error(response.message);
    }

    setSubmitting(false);
  };

  const handleSuspend = async (invoiceOrderAssociationId) => {
    console.debug(`Suspending ${invoiceOrderAssociationId}`);
    const invoiceAssociation = invoices.find((item) => item.id === invoiceOrderAssociationId);

    const suspensionNotePromptResult = promptSuspensionNote(invoiceAssociation);

    if (suspensionNotePromptResult.canceled || !askForSuspensionConfirm(invoiceAssociation)) {
      return;
    }

    setSubmitting(true);

    const response = await suspendInvoiceOrderAssociation(invoiceOrderAssociationId, {
      note: suspensionNotePromptResult.enteredText,
    });

    if (response.ok) {
      success(`Sospensione avvenuta con successo.`);
      setInvoices((invoices) =>
        invoices.map((invoice) =>
          invoice.id !== invoiceOrderAssociationId
            ? invoice
            : {
                ...invoice,
                statusId: INVOICE_ASSOCIATION_STATUS.SUSPENDED,
                noteCount: invoice.noteCount++,
                notes: [
                  ...invoice.notes,
                  {
                    paymentCertificateId: invoice.paymentCertificateId,
                    text: suspensionNotePromptResult.enteredText,
                    displayName: fullName,
                    creationDate: new Date().toJSON(),
                  },
                ],
              }
        )
      );
    } else {
      error(response.message);
    }

    setSubmitting(false);
  };

  const handleResume = async (invoiceOrderAssociationId) => {
    // console.debug(`Resuming ${invoiceOrderAssociationId}`);

    const invoiceAssociation = invoices.find((item) => item.id === invoiceOrderAssociationId);

    if (!askForResumeConfirm(invoiceAssociation)) {
      return;
    }

    setSubmitting(true);

    const response = await resumeInvoiceOrderAssociation(invoiceOrderAssociationId);

    if (response.ok) {
      success(`Ripresa dell'approvazione avvenuta con successo.`);
      setInvoices((invoices) =>
        invoices.map((invoice) =>
          invoice.id !== invoiceOrderAssociationId
            ? invoice
            : {
                ...invoice,
                statusId: INVOICE_ASSOCIATION_STATUS.WAITING_FOR_APPROVAL,
              }
        )
      );
    }

    setSubmitting(false);
  };

  const handleSettle = async () => {
    if (window.confirm(`Sei davvero sicuro di voler saldare questo ordine?`)) {
      setSubmitting(true);
      const response = await settlePurchaseOrder(purchaseOrderId);
      if (response.ok) {
        setIsOrderAlreadySettled(true);
        success("L'ordine è stato saldato");
      } else {
        error(response.message);
      }
      setSubmitting(false);
    }
  };

  return (
    <div>
      {(loading || submitting) && <Loader />}
      {invoices && (
        <>
          <PurchaseInvoiceAssociationList
            invoiceAssociations={invoices}
            onApprove={handleApprove}
            onSuspend={handleSuspend}
            onResume={handleResume}
            onReject={handleReject}
          />
          <div className="text-center">
            {isSettleOrderButtonVisible && (
              <Button
                type="button"
                color="primary"
                size="lg"
                onClick={handleSettle}
                disabled={!isSettleOrderButtonEnabled}
              >
                Salda ordine
              </Button>
            )}
          </div>
        </>
      )}
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
};

export default PurchaseOrderInvoicesPage;
