import CarDocument from "./CarDocument";
import InailDocument from "./InailDocument";
import UploadConstructionFund from "./UploadConstructionFund";

const DOCUMENT_TYPE_ID = {
    inail: 5,
    constructionFund: 6,
    car: 7
  };


const UpdateInsuranceModal = ({insurance, commissionId, inline, onSubmit, isFileRequired=false}) => {

    const onEdit = (formData, data, documentType) => {
        onSubmit?.(formData, {...data, insuranceId: insurance.insuranceId}, documentType)
    }

    return (
      <>
        {insurance.documentTypeId === DOCUMENT_TYPE_ID.inail && (
          <InailDocument
            onSubmit={onEdit}
            isInailUploaded={false}
            data={insurance}
            inline={inline}
            commissionId={commissionId}
            isFileRequired={isFileRequired}
          />
        )}
        {insurance.documentTypeId === DOCUMENT_TYPE_ID.constructionFund && (
          <UploadConstructionFund
            onSubmit={onEdit}
            isConstructionFundUploaded={false}
            data={insurance}
            inline={inline}
            commissionId={commissionId}
            canSave={isFileRequired}
          />
        )}
        {insurance.documentTypeId === DOCUMENT_TYPE_ID.car && (
          <CarDocument
            onSubmit={onEdit}
            isCarUploaded={false}
            data={insurance}
            inline={inline}
            commissionId={commissionId}
            isFileRequired={isFileRequired}
          />
        )}
      </>
    );

}

export default UpdateInsuranceModal;